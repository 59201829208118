import clsx from "clsx";
import React, { Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Radio, { RadioProps } from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import style from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

function StyledRadio(props: RadioProps): JSX.Element {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

export function RadioButtons(props: props): JSX.Element {
  const { formElement, onChange, formId, formGroupId } = props;
  const classes = useStyles();
  const ErrorMessage = formElement.touched ? formElement.error : "";
  const Disabled = formElement.disabled || formElement.read_only;
  const RadioButtonDS = formElement.dataSource.map(
    (radio: any, index: number) => (
      <FormControlLabel
        key={index}
        disabled={Disabled}
        value={radio.value}
        control={<StyledRadio />}
        label={radio.label}
      />
    )
  );

  const handleChange = (event: any): void => {
    const Value = event.target.value;

    onChange({
      formId,
      formGroupId,
      formElement,
      value: Value,
    });
  };

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <RadioGroup
        value={formElement.value}
        aria-label={formElement.labelName}
        name={formElement.labelName}
        onChange={handleChange}
        className={classes.label}
      >
        {RadioButtonDS}
      </RadioGroup>
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={Disabled ? "" : ErrorMessage}
      />
    </Fragment>
  );
}
