import React from "react";
import _ from "lodash";
import { IconPlay } from "../../../../../../UI/Icons/Play";

type props = {
    row: any
    handleExecuteChange: any,
    tooltipText: any
};

export default function GridExecuteNow(props: props): React.JSX.Element {
    const { handleExecuteChange, tooltipText, row } = props;
    if ((_.isEqual(_.toLower(row.jobStatus), _.toLower("RUNNING")))
        || (_.isEqual(_.toLower(row.jobStatus), _.toLower("Pending")))
    ) {
        return (
            <>
                <IconPlay onClick={() => { handleExecuteChange(row.jobId); }} tooltipText={tooltipText} disabled={true} />
            </>
        );
    }
    return (
        <>
            <IconPlay onClick={() => { handleExecuteChange(row.jobId); }} tooltipText={tooltipText} disabled={false} />
        </>
    );
}