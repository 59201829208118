import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import style from "../style";
import { IconEdit } from "../../../../../UI/Icons";
import GridCell from "./GridCell";
import { IGridHeader } from "../../../Interfaces/Grid/IGridHeader";
import CheckboxComponent from "../CheckboxControl/CheckboxComponent";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  headers: IGridHeader[];
  row: any;
  onEdit: any;
  bulkEditMode: boolean | undefined;
  handleCheckChange?: any;
  selected?: any;
  allSelected?: boolean;
};

export const GridRow = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { headers, row, onEdit, bulkEditMode, handleCheckChange, selected, allSelected } = props;

  const TableCells = [];
  {
    bulkEditMode && TableCells.push(
      <td
        key={uuidv4()}
        className={classnames(classes.CheckBoxCell, classes.gridCheckboxCell)}
      >
        <CheckboxComponent checked={selected[row.id] || allSelected || row.isSelected} handleChange={(event: any) => handleCheckChange(event, row.id)} />
      </td>
    );
  }

  for (const header of headers) {
    if (header.visible) {
      TableCells.push(
        <GridCell key={uuidv4()} gridHeader={header} data={row[header.id]} status={row.status}/>
      );
    }
  }

  {
    !bulkEditMode && TableCells.push(
      <td
        key={uuidv4()}
        className={classnames(classes.gridCell, classes.gridActionCell)}
      >
        <IconEdit
          onClick={() => {
            onEdit(row.id, row);
          }}
          title="Edit"
          disabled={false}
        />
      </td>
    );
  }

  return (
    <tr
      className={classnames(classes.gridRow, {
        [classes.highlightNewRow]: false,
      })}
    >
      {TableCells}
    </tr>
  );
};
