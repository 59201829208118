import classNames from "classnames";
import React from "react";
import { IGridHeader } from "../../../../Forms/interface/Grid/IGridHeader";
export const getLoadingCells = (
  headers: IGridHeader[],
  classes: any,
  isGridAction: boolean
): React.JSX.Element[] => {
  const GridCells: React.JSX.Element[] = [];
  for (const header of headers) {
    if(!header.hide){
      GridCells.push(
        <td key={header.field
        }  className={classes.gridCell}>
          <span
            className={classNames(classes.gradient, classes.loadingCell)}
          ></span>
        </td>
      );
      }
  }
  if (isGridAction) {
    GridCells.push(
      <td
        key="grid-action"
        className={classNames(classes.gridCell, classes.gridActionCell)}
      >
        <span
          className={classNames(classes.gradient, classes.loadingCell)}
        ></span>
      </td>
    );
  }
  return GridCells;
};
