


const style: any = (): any => ({
	textContainer:{
		maxHeight:36,
		wordBreak: "break-all"
	},
	textContainerEllipse:{
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 2,
		wordBreak: "break-all"
	  },

});

export default style;