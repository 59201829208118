import COLORS from "../../UI/Theme/colors";

const style: any = (): any => ({
	contentWrapper: {
		padding: "20px 28px 28px 28px",
	},
	infoWrapper: {
		padding: 12,
	},
	content: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.WHITE,
		padding: 31,
	},
	userInfo:{
	  display:"flex",
	  flexDirection: "column",
	  color: COLORS.DARK_GRAY,
	  marginLeft: 8
	},
	userInfoName:{
	  fontSize:14,
	  marginBottom: 4,
	  fontWeight: 500,
	  display:"flex",
	  alignItems:"center"
	},
	userAccessContainer: {
		margin: "1rem 0"
	},
	userGroup: {
		fontSize: 12,
		fontWeight: 500,
		color: COLORS.GRAY,
		textTransform: "uppercase",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap" as const,
	},
	userAccount: {
		fontSize: 14,
		fontWeight: 600,
		color: COLORS.DARK_GRAY,
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap" as const,
	},
	userInfoEmail:{
	  fontSize:12,
	  marginLeft: 8
	},
	logo:{
	  width:"211.6px",
	  height:32
	},
	subtitle:{
	  fontSize:12,
	  color: COLORS.GRAY
	},
	hr:{
	  marginTop:20,
	  marginBottom: 21,
	  padding:"0 8px",
	  width:"100%",
	},
	hrTag:{
		borderTop: `1px solid ${COLORS.SHAINCOLOR}`,
		borderLeftColor:"transparent"
	},
	roleHeader:{
		fontSize: 16,
		color: COLORS.DARK_GRAY,
		fontWeight: 600
	}
});

export default style;