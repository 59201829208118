import React from "react";
import { FormControl } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import _ from "lodash";

type props = any;

// Toggle button functional component
function ToggleButtons(props: props): React.JSX.Element {
  const { classes, id, data, handleChange, value, exclusive, loading, disabled } = props;
  
  return (
    <div>
      <FormControl>
        <ToggleButtonGroup
          exclusive={exclusive}
          value={_.toLower(value)}
          onChange={(event, newValue) => handleChange(event, newValue, id)}
          aria-label="button"
          className={classes.toggleButtonGroup}
        >
          {data.map((button: any, i: number) => {

            return (

              <ToggleButton
                value={_.toLower(button.value)}
                key={`${button.label} - ${i}`}
                aria-label={`${button.label} + ${i}`}
                className={classes.toggleButton}
                style={{ opacity: loading ? 0.5 : 1 } }
                classes={{ selected: classes.selectedButton }}
                disabled={disabled}
              >
                {button.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </FormControl>
    </div>
  );
}

export default (withStyles(styles)(ToggleButtons));





