import React, { Component, Fragment } from "react";
import { SnackbarAlert } from "../UI/Snackbar";
import { errorHandlerAsync } from "../helpers/ErrorHandler.component";

// Snackbar HOC
type props = any;
type state = {
  message: string;
  open: boolean;
  variant: string;
};

export default (WrappedComponent: any): any => {
  class SnackbarHOC extends Component<props, state> {
    constructor(props: any) {
      super(props);
      this.state = {
        message: "",
        open: false,
        variant: "success",
      };
    }

    // Warning Alert
    warningAlert = errorHandlerAsync(async (message: any) => {
      // Close any existing alert
      await this.onOpenCloseAlert(false);

      return this.setState((prevState) => ({
        ...prevState,
        open: true,
        variant: "warning",
        message,
      }));
    });

    // Error Alert
    errorAlert = errorHandlerAsync(async (message: any) => {
      // Close any existing alert
      await this.onOpenCloseAlert(false);

      return this.setState((prevState) => ({
        ...prevState,
        open: true,
        variant: "error",
        message,
      }));
    });

    // Success Alert
    successAlert = errorHandlerAsync(async (message: any) => {
      // Close any existing alert
      await this.onOpenCloseAlert(false);

      return this.setState((prevState) => ({
        ...prevState,
        open: true,
        variant: "success",
        message,
      }));
    });

    // Info Alert
    infoAlert = errorHandlerAsync(async (message: any) => {
      // Close any existing alert
      await this.onOpenCloseAlert(false);

      return this.setState((prevState) => ({
        ...prevState,
        open: true,
        variant: "info",
        message,
      }));
    });

    onOpenCloseAlert = async (state: any): Promise<any> => {
      // Close any existing alert
      return this.setState((prevState) => ({
        ...prevState,
        open: state,
      }));
    };

    render(): React.JSX.Element {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            errorAlert={this.errorAlert}
            successAlert={this.successAlert}
            warningAlert={this.warningAlert}
            infoAlert={this.infoAlert}
            onOpenCloseAlert={this.onOpenCloseAlert}
          />
          <SnackbarAlert
            open={this.state.open}
            message={this.state.message}
            variant={this.state.variant}
            onOpenCloseAlert={this.onOpenCloseAlert}
          />
        </Fragment>
      );
    }
  }

  return SnackbarHOC;
};
