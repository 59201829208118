import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import * as _ from "lodash";
import style from "./style";
import { IFilter } from "../IFilter";
import { UIOutlineButton } from "../../../../../../Buttons";
import { IFormElement } from "../../../../../Forms/interface/IFormElement";
import { DataGridEditorSearch } from "./Search";
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  formElement: IFormElement;
  searchHandler: any;
  filter: IFilter;
  onAddForm: any;
  isValidForm: boolean;
  rowData: any;
};

export const DataGridEditorHeader = (props: props): JSX.Element => {
  const classes = useStyles();
  const {
    formElement,
    searchHandler,
    filter,
    onAddForm,
    isValidForm,
    rowData,
  } = props;
  const AllowedRows =
    formElement.dynamic_configuration.validations.allowedRows || null;
  let addFormButton = (
    <UIOutlineButton
      cssStyle={{
        marginLeft: "0.75rem",
      }}
      isOutline={true}
      disabled={!isValidForm}
      onClick={() => onAddForm()}
      btnText="Add"
    />
  );

  if (!_.isNull(AllowedRows) && !_.lt(rowData.length, AllowedRows)) {
    addFormButton = <></>;
  }

  return (
    <Grid
      container
      item
      lg={12}
      md={12}
      sm={12}
      xs={12}
      alignItems="center"
      justifyContent="space-between"
      className={classes.gridHeader}
    >
      <div className={classes.actionContainer}>
        <DataGridEditorSearch
          formElement={formElement}
          searchHandler={searchHandler}
          filter={filter}
        />
        {addFormButton}
      </div>
    </Grid>
  );
};
