import React from "react";
import { FormControl } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import { connect, useSelector } from "react-redux";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import _ from "lodash";

type props = any;

// Toggle button functional component
function ToggleButtons(props: props): JSX.Element {
  const { classes, id, data, handleChange, value, exclusive, isLifeCycle, toolList, disabled } = props;

  const filterState = useSelector((state: any) => state.filter);
  
  return (
    <div>
      <FormControl>
        <ToggleButtonGroup
          exclusive={exclusive}
          value={value}
          onChange={(event, newValue) => handleChange(event, newValue, id)}
          aria-label="button"
          className={classes.toggleButtonGroup}
        >
          {data.map((button: any, i: number) => {

            return (

              <ToggleButton
                value={button.value}
                key={`${button.label} - ${i}`}
                aria-label={`${button.label} + ${i}`}
                className={isLifeCycle ? classes.toggleButtonLifecycle : classes.toggleButton}
                style={{ opacity: toolList.loading || !_.isEmpty(filterState.filter.search) ? 0.5 : 1 } }
                classes={{ selected: classes.selectedButton }}
                disabled={disabled}
              >

                {button.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </FormControl>
    </div>
  );
}
const mapStateToProps = (state: any): any => ({
  bundle: state.initial.bundle,
  toolList: state.toolList,
  filterOptions: state.filterOptions.filterOptions,
});

export default connect<any, any, props>(
  mapStateToProps,
  null
)(withStyles(styles)(ToggleButtons));





