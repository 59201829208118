import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { Theme } from "@material-ui/core/styles";
// import MiddleEllipsis from "react-middle-ellipsis";

import { makeStyles, createStyles } from "@material-ui/core";
import EllipsisText from "react-text-overflow-middle-ellipsis";
import style from "./style";
import UITooltip from "../../../Tooltip";

type props = {
  title: string;
};
// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const TitleElement = (props: props): JSX.Element => {
  const classes = useStyles();
  const [hoverStatus, setHover] = useState(false);
  const state = useState(0);
  const textElementRef: any = useRef();
  const Title = (props.title || "").toString().trim();

  const compareSize = (): any => {
    state[1](Math.random());
    if (_.isNull(textElementRef) || _.isUndefined(textElementRef.current)) {
      return;
    }

    const compare = _.gt(
      textElementRef.current.scrollWidth,
      textElementRef.current.clientWidth
    );
    setHover(compare);
  };
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  return (
    <span>
      <UITooltip title={Title || ""} disableTooltip={!hoverStatus}>
        <span>
          <span ref={textElementRef} className={classes.ellipsisContainer}>
            {Title}
          </span>
          <EllipsisText text={Title} />
        </span>
      </UITooltip>
    </span>
  );
};
