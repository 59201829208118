import * as _ from "lodash";
import { Axios } from "../../../../../../api/service/Axios";
import { UtilHelper } from "../../../../../../UI/Elements/Forms/helper/Utils";
import { IOption } from "../../../../../../UI/Elements/Forms/interface/IOption";
import { ALL_OPTION_OBJ, ELEMENT_TYPES } from "../../../../configuration";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../../../Interfaces/Form/IForm";
import { IFormGroup } from "../../../../Interfaces/Form/IFormGroup";

import { MultiSelectHelper } from "./SelectInput/MultiSelect";
import { SingleSelectHelper } from "./SelectInput/SingleSelect";

export class NewFormHelper {
	private utilHelper: UtilHelper;
	private multiSelect: MultiSelectHelper;
	private singleSelect: SingleSelectHelper;

	constructor() {
		this.utilHelper = new UtilHelper();
		this.singleSelect = new SingleSelectHelper();
		this.multiSelect = new MultiSelectHelper();
	}
	public async buildNewForm(form: IForm, dataSources: any): Promise<IForm> {


		let Form = {
			...form,
			groups: form.groups.map((formGroup: IFormGroup) => {

				return {
					...formGroup,
					elements: formGroup.elements.map((formElement: IFormElement) => {
						const element = this.buildNewFormElement(formElement, dataSources);

						return {
							...element,
							isValid: element.validations.required ? element.isValid : true,
							touched: false,
							readOnly: !_.isUndefined(element.configuration.readOnly) ? element.configuration.readOnly.newForm : element.readOnly
						};
					}),
				};
			}),
		};
		Form = this.validateParentChildRelationship(Form);
		return Form;
	}

	public buildNewFormElement = (
		formElement: any,
		dataSources: any,
		isParentContainsValue?: any
	): IFormElement => {//NOSONAR

		let element: any = { ...formElement };
		const DefaultValue = formElement.configuration.defaultValue;

		const DATA_SOURCES: any = {};
		for (const key in dataSources) {//NOSONAR
			DATA_SOURCES[key] = dataSources[key].map((option: any) => ({ ...option }));
		}

		switch (formElement.type) {//NOSONAR
			case ELEMENT_TYPES.SELECT_INPUT: {//NOSONAR
				const DataSource = _.isUndefined(DATA_SOURCES[formElement.configuration.dataSourceKey]) ? [] : DATA_SOURCES[formElement.configuration.dataSourceKey];//NOSONAR

				if (Array.isArray(DataSource)) {//NOSONAR
					if (element.configuration.allowAll && !_.isEqual(DataSource.length, 0)) {//NOSONAR
						DataSource.unshift(ALL_OPTION_OBJ);
					}
				}

				element = {//NOSONAR
					...formElement,
					configuration: {
						...formElement.configuration,
						dataSource: isParentContainsValue ? formElement.configuration.dataSource : DataSource,//NOSONAR
					},
					value: !_.isNull(formElement.value) ? formElement.value : DefaultValue || null,//NOSONAR
				};
				break;
			}
			case ELEMENT_TYPES.SWITCH:
				element = {//NOSONAR
					...formElement,
					value: !_.isNull(formElement.value) ? formElement.value : DefaultValue || true,//NOSONAR
				};
				break;
			default:
				element = {//NOSONAR
					...formElement,
					value: !_.isNull(formElement.value) ? formElement.value : DefaultValue || "",//NOSONAR
				};
				break;
		}
		return {
			...element,
		};
	};

	/**
	   * Build dropdown API list
	   *
	   * @param {forms} IForm[].
	   * @param {formElement} IFormElement.
	   * @return {Element} IFormElement.
	   */
	buildAPIDropdownList = async (formElement: IFormElement)
		: Promise<IFormElement> => {

		let Element: IFormElement = { ...formElement };
		const {
			parentElements,allowAll,apiURL,isMulti
		} = Element.configuration;
		const isDisabled = !_.isEqual(parentElements.length, 0);

		let dataSource: IOption[] = [];

		if (_.isEqual(parentElements.length, 0) && !_.isUndefined(apiURL)) {
			try {
				const Result = await Axios.get(apiURL);
				dataSource = !_.isArray(Result.data) ? [] : Result.data;
				
				if (allowAll && !_.isEqual(dataSource.length, 0)) {
					dataSource.unshift(ALL_OPTION_OBJ);
				}

				Element.apiError = "";
			}
			catch (error: any) {
				dataSource = [];
				Element.apiError = this.utilHelper.getErrorMessage(error);
			}
		}

		Element.configuration.dataSource = dataSource;

		if (!_.isEqual(parentElements.length, 0)) {
			return { ...Element };
		}

		Element = isMulti ? this.multiSelect.getMultiDropdownValue(Element)
			: this.singleSelect.getSingleDropdownValue(Element);

		return {
			...Element,
			disabled: isDisabled,
			touched: !_.isNull(Element.value),
			value: Element.value,
		};
	}
	/**
* Validate parent and child relationship for date and select
*
* @param {forms} IForm[].
* @return {UpdatedForms} IForm[].
*/
	validateParentChildRelationship = (form: IForm): IForm => {
		let UpdatedForm = ({
			...form,
			groups: form.groups.map((group: IFormGroup) => ({
				...group,
				elements: group.elements.map((element: IFormElement) => ({ ...element }))
			}))
		});

		const FormGroups = UpdatedForm.groups;
		for (let iFormGroup = 0; iFormGroup < FormGroups.length; iFormGroup++) {
			const FormElements = FormGroups[iFormGroup].elements;

			for (let iFormElement = 0; iFormElement < FormElements.length; iFormElement++) {
				const FormElement = FormElements[iFormElement];

				const Element: IFormElement = { ...FormElement };
				const ParentElements = Element.configuration.parentElements;

				if (!_.isEqual(ParentElements.length, 0)) {
					Element.value = null;
					Element.touched = false;
					Element.disabled = true;
				}

				FormElements[iFormElement] = {
					...FormElements[iFormElement],
					...Element
				};
			}
			FormGroups[iFormGroup] = {
				...FormGroups[iFormGroup],
				elements: FormElements
			};
		}
		UpdatedForm = {
			...UpdatedForm,
			groups: FormGroups
		};
		return UpdatedForm;
	}
}
