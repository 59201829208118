import * as _ from "lodash";
import {
	GET_TOOL_LIST_REQUEST,
	GET_TOOL_LIST_SUCCESS,
	GET_TOOL_LIST_FAILED,
	RESET_TOOL_LIST,
	UPDATE_INITIAL_STATE,
	UPDATE_TOOL_LIST
} from "../types";
import {
	updateObject
} from "../../helpers/Utility";
import { IReduxToolList } from "./interfaces/IReduxToolList";

// Initial State for Tool List reducer
const initialState: IReduxToolList = {
	toolList: [],
	toolCount: 0,
	hasMore: false,
	loading: true,
	error: false,
	limit: 1000,
	loadMoreAfter: 60
};

// Reset state
const resetToolList = (state: any) : any => updateObject(state, {
	toolList: [],
	toolCount:0,
	hasMore: false,
	loading: true,
	error: false
});

// Update Initial state after check api API response get
export const updateInitialState = (state: any) : any => updateObject(state, {
	limit: 1000,
	loadMoreAfter: 60,
});

// Tool List request to server
const getToolListRequest = (state: any) : any => updateObject(state, {
	loading: true,
	hasMore: true,
	error: false
});

// Tool List response
const getToolListSuccess = (state: any, action: any) : any => updateObject(state, {
	toolList: [...state.toolList, ...action.payload.data],
	toolCount: action.payload.count,
	loading: false,
	hasMore: action.payload.data.length > 0 && !_.lt(action.payload.data.length, state.limit),
	error: false
});

// Server error
const getToolListFailed = (state: any) : any => updateObject(state, {
	loading: false,
	hasMore: false,
	error: true
});

const updateToolList = (state: any, action:any) : any =>
{
	const tools: any = state.toolList.filter((tool:any) => tool.tool_id !== action.payload.tool_id);
	return {
	...state,
	toolList: [...tools],
	toolCount: state.toolCount - 1
	};
};

// Tool List reducer
export default (state = initialState, action: any={}) : any => {
	let updatedState = {...state}; 
	switch (action.type) {
	case GET_TOOL_LIST_REQUEST:  
		updatedState = getToolListRequest(state);
		break;
	case GET_TOOL_LIST_SUCCESS: 
		updatedState = getToolListSuccess(state, action);
		break;
	case GET_TOOL_LIST_FAILED: 
		updatedState = getToolListFailed(state);
		break;
	case RESET_TOOL_LIST: 
		updatedState = resetToolList(state);
		break;
	case UPDATE_INITIAL_STATE: 
		updatedState = updateInitialState(state);
		break;
	
	case UPDATE_TOOL_LIST:
		updatedState = updateToolList(state,action);
	}
	return updatedState;
};