
import * as _ from "lodash";
import { ELEMENT_TYPES } from "../../configuration";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";

export class RequiredValidation {

	public validate(formElement: IFormElement, elementValue: any): string{

		let errorMessage = "";
		const ELEMENT_CONFIG = formElement.configuration;

    	const RequiredMessage = `${ELEMENT_CONFIG.title} is required.`;

    	switch(formElement.type){
    	// Text box
    	case ELEMENT_TYPES.TEXT_INPUT:
    		// Checkbox
    		/* falls through */
    	case ELEMENT_TYPES.CHECKBOX_INPUT:
    		// Radio
    		/* falls through*/
    	case ELEMENT_TYPES.RADIO_INPUT:
    		errorMessage = this.validateInputElements(elementValue, RequiredMessage);
    		break;
    		// Select
    	case ELEMENT_TYPES.SELECT_INPUT:{
			errorMessage = this.validateSelectInput(elementValue, RequiredMessage);
    		break;
    	}
	}
    	return errorMessage;
	}

	private validateInputElements(elementValue: any, requiredMessage: string): string{
		const ElementValueLen = (elementValue || "").toString().trim().length;
		return _.isEqual(ElementValueLen, 0) 
				? requiredMessage : "";
	}

	validateSelectInput = (elementValue: any, requiredMessage: string): string =>{
    	return !_.isNull(elementValue) && !_.isEqual((elementValue || []).length, 0)
    				? "" :requiredMessage;
	}

	validateHTMLInput = (elementValue: any, requiredMessage: string): string =>{
    	const HTMLRemoveRegex = /(<([^>]+)>)/ig;
		const ElementValueLen = (elementValue.replace(HTMLRemoveRegex, "") || "").toString().trim().length;

		return _.isEqual(ElementValueLen, 0) 
		? requiredMessage : "";
	}

	    /**
     * Check if the element value is empty or not
     *
     * @param {IFormElement} element.
     * @param {any} elementValue.
     * @return boolean.
     */
		 isEmptyElementValue = (element: IFormElement, elementValue:any): any=>{
			let isEmpty = true;
			switch(element.type){
			// Text box
			case ELEMENT_TYPES.TEXT_INPUT:
				// Checkbox
				/* falls through*/
			case ELEMENT_TYPES.CHECKBOX_INPUT:
				// Radio 
				/* falls through*/
			case ELEMENT_TYPES.RADIO_INPUT:
				isEmpty = _.isEqual((elementValue || "").toString().trim().length, 0);
				break;
				// Multiselect
			case ELEMENT_TYPES.SELECT_INPUT:
				if(element.configuration.isMulti){
					isEmpty = _.isNull(elementValue) || _.isEqual((elementValue || []).length, 0);
				}else{
					isEmpty = _.isNull(elementValue);
				}
				break;
			}
			return isEmpty;
		}
}
