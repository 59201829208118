


const style: any = (): any => ({
	textContainer: {
		maxHeight: 36,
		wordBreak: "break-all"
	},
	textContainerEllipse: {
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 2,
		wordBreak: "break-all"
	},
	ellipsisContainer: {
		whiteSpace: "nowrap",
		textOverflow: "ellipsis",
		visibility: "hidden",
		height: 0,
		maxHeight: 0,
		display: "block",
		overflow: "hidden",
	},
	middleTextEllipsis: {
		width: "100%",
		whiteSpace: "nowrap",
		display: "block",
		minHeight: 21,
	},
});

export default style;