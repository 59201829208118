import { catchError } from "../../../../helpers/ErrorHandler.component";
import * as _ from "lodash";
import { IOption } from "../../../../UI/Elements/Forms/interface/IOption";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { ALL_OPTION_VALUE, ELEMENT_TYPES } from "../../configuration";

// Utility helper
export class UtilHelper {
	/**
	 * Check if the element value is empty or not
	 *
	 * @param {IFormElement} element.
	 * @param {any} elementValue.
	 * @return boolean.
	 */
	isEmptyElementValue = (element: IFormElement, elementValue: any): any => {
		let is_Empty = true;
		switch (element.type) {
			// Text box
			case ELEMENT_TYPES.TEXT_INPUT:
			// Checkbox
			/* falls through*/
			case ELEMENT_TYPES.CHECKBOX_INPUT:
			// Radio 
			/* falls through*/
			case ELEMENT_TYPES.RADIO_INPUT:
				is_Empty = _.isEqual((elementValue || "").toString().trim().length, 0);

				break;
			// Multiselect
			case ELEMENT_TYPES.SELECT_INPUT:
				if (element.configuration.isMulti) {
					is_Empty = _.isNull(elementValue) ||
					           _.isEqual((elementValue || []).length, 0);
				} else {
					is_Empty = _.isNull(elementValue);
				}
				break;
		}
		return is_Empty;
	}
	
	/**
		* Find all selection in the dropdown
		*
		* @param {any} value.
		* @return boolean.
		*/
	public isAllSelected = (value: any): boolean => {
		const ElementValue = (value || []);
		const isAll = ElementValue.find((option: IOption) => _.isEqual(option.value, ALL_OPTION_VALUE));
		return !_.isUndefined(isAll);
	}

	/**
	 * Find value in Options datasource
	 *
	 * @param {IOption[]} dataSource.
	 * @param {any} value.
	 * @return number | null.
	 */
	findValueInDS = (dataSource: IOption[], value: any): any => {
		for (const ds of dataSource) {
			if (_.isEqual(ds.value, value)) {return ds;}
		}
		return null;
	}

	/**
	 * Extract error message from response
	 *
	 * @param {object} error.
	 * @return string.
	 */
	getErrorMessage(error: any): string {
		const DefaultError_Message = "Server error: Unable to fetch data.";
		
		if (!error.response) {
			return DefaultError_Message;
		}
		const errorMessage = catchError(error);

		return _.isEqual(
			(errorMessage || "").toString().trim().length,0)? DefaultError_Message: errorMessage;
	}

		/**
	 * Find if the value is empty
	 *
	 * @param {any} value.
	 * @return boolean.
	 */
		isEmptyValue = (value: any): any => {
			const Value = (value || "").toString().trim();
			return _.isEqual(Value.length, 0);
		}
		

	/**
	 * Get element value
	 *
	 * @param {IFormElement} element.
	 * @return boolean.
	 */
	getElementValue = (element: IFormElement): any => {
		if (this.isEmptyElementValue(element, element.value)) {
			return null;
		}
		if (_.isEqual(element.type, ELEMENT_TYPES.SELECT_INPUT)) {
			//Multi Select
			if (element.configuration.isMulti) {
				if (this.isAllSelected(element.value)) {
					return element.configuration.dataSource
						.filter((option: IOption) => !_.isEqual(option.value, ALL_OPTION_VALUE))
						.map((option: IOption) => option.value);
				}
				return element.value.map((option: IOption) => (option.value));
			}
			return element.value.value;
		}
		return element.value;
	}

}