import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import * as _ from "lodash";
import { useOktaAuth } from "@okta/okta-react";
import classnames from "classnames";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { UIOutlineButton } from "../../UI/Buttons";
import utilStyle from "../../UI/Dialogs/Confimation/style";
import style from "./style";

// Create styles
const useUtilStyles = makeStyles((theme: Theme) =>
  createStyles(utilStyle(theme))
);

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  onContinueSignedIn: any;
  onLogout: any;
  timeoutSeconds: number;
};

const TimeFormatter = (num: number): string | number => {
  return _.lt(num, 10) ? `0${num}` : num;
};

const SecondsToMinutes = (second: number): string => {
  const divisor_for_minutes = second % (60 * 60);
  const DisplaMinutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const DisplaySeconds = Math.ceil(divisor_for_seconds);
  return `${TimeFormatter(DisplaMinutes)}:${TimeFormatter(
    DisplaySeconds
  )} minutes`;
};

export const SessionTimeoutAlert = (props: props): React.JSX.Element => {
  const { onContinueSignedIn, onLogout } = props;
  const { oktaAuth } = useOktaAuth();
  const utilClasses = useUtilStyles();
  const classes = useStyles();
  const [seconds, setSeconds] = useState(props.timeoutSeconds);
  const [time, setTime] = useState(SecondsToMinutes(props.timeoutSeconds));

  useEffect(() => {
    const interval = setInterval(() => {
      if (_.gt(seconds, 0)) {
        setSeconds((sec: number) => sec - 1);
        setTime(SecondsToMinutes(seconds - 1));
      }
    }, 1000);
    if (_.isEqual(seconds, 0)) {
      oktaAuth.signOut();
    }

    return () => clearInterval(interval);
  }, [seconds]);

  return (
    <div className={classes.container}>
      <div className={utilClasses.overlay}></div>
      <div className={utilClasses.dialog}>
        <div className={utilClasses.dialogContainer}>
          <div className={utilClasses.dialogHeader}>Session Timeout</div>
          <div className={utilClasses.dialogBody}>
            Your session is about to expire in {time}.
            <div>Do you want to stay signed in?</div>
          </div>
          <div className={utilClasses.dialogActions}>
            <UIOutlineButton
              cssStyle={{
                marginRight: "0.75rem",
              }}
              isOutline={false}
              onClick={onLogout}
              btnText="Logout"
            />
            <Button
              variant="contained"
              color="primary"
              className={classnames({
                [classes.deleteButton]: false,
              })}
              onClick={onContinueSignedIn}
            >
              Keep me signed in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
