import * as _ from "lodash";
import ElementTypes from "../UI/ElementTypes";

class FormHelper {

    getLocalStorageState = (): any => {
    	const selectedFilter: any = localStorage.getItem("filterCategories");
    	return JSON.parse(selectedFilter);
    }

    getCheckedValue = (dropdownData: any, value: any): any  => {
    	return dropdownData.map((d: any) => {
    		const selectedValue = !_.isEmpty(value) && value.includes(d.value);
    		d.checked = selectedValue;
    		return d;
    	});
    }

    getAllCheckedValue = (values: any): any  => {
    	const FilterdValues = values.filter((value: any) => value.checked);
    	return FilterdValues.map((value: any) => value.value);
    }

	getCheckboxValues = (data: any): any =>{
		return data.map((value: any) => {
			const Array: any = data.filter((val: any) => !_.isEqual(val.value, 0));
			const IsAnyUnChecked = Array.some((val: any) => _.isEqual(val.checked, false));
			if (_.isEqual(value.value, 0) && !IsAnyUnChecked) {
				value.checked = true;
				return value;
			}

			if (!_.isEqual(value.value, 0) && !value.checked) {
				data[0].checked = false;
				return value;
			}

			return value;
		});
	}

    getUpdatedCheckboxValue = (data: any, event: any): any  => {
    	let checkboxValues: any = data;
    	if (!_.isNull(event) && _.isEqual(event.target.defaultValue, "0")) {
    		checkboxValues = data.map((value: any) => {
				value.checked = data[0].checked;
    			return value;
    		});
    	} else if (!_.isNull(event) && !_.isEqual(event.target.defaultValue, "0")) {
    		checkboxValues = this.getCheckboxValues(data);
    	} else if (_.isNull(event)) {
    		checkboxValues = data.map((value: any) => {
    			if (data[0].checked) {
    				value.checked = true;
    			}
    			return value;
    		});
    	}

    	return checkboxValues;
    }

    // Get Element value
    getElementValue = (form: any, inputIdentifier: any, event: any, newValue: any): any  => {

    	if (_.isEqual(form[inputIdentifier].elementType, ElementTypes.SWITCH)) {
    		return newValue;
    	}
    	else if (_.isEqual(form[inputIdentifier].elementType, ElementTypes.CHECKBOX)) {
    		return form[inputIdentifier].elementConfig.data.map((d: any) => {
    			if (_.isEqual(d.value.toString(), event.target.value)) {
    				d.checked = event.target.checked;

    			}
    			return d;
    		});
    	}
    	else if (_.isEqual(form[inputIdentifier].elementType, ElementTypes.TOGGLE_BUTTON)) {
    		if (_.isNull(newValue)) {
    			return form[inputIdentifier].value;
    		}
    		return newValue;
    	}
    };

    // Check input field validity
    checkInputValidity = (element: any, value: any): any  => {
    	let isValid = true;
    	const rules = { ...element.validation };

    	let errorMessage: any = "";

    	if (rules.required) {
    		errorMessage = !isValid && `${element.elementConfig.labelName} is required.`;

    		return {
    			errorMessage,
    			isValid
    		};
    	}

    	if (_.isEqual(element.elementType, ElementTypes.CHECKBOX)) {
    		isValid = (!_.isNull(value) || !_.isEmpty(value)) && value.some((data: any) => (_.isEqual(data.checked, true)));
    		errorMessage = !isValid && "Select at least one Platform";

    		return {
    			errorMessage,
    			isValid
    		};
    	}

    	return {
    		errorMessage,
    		isValid
    	};
    };


}

export default FormHelper;