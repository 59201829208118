import Colors from "../../../../../UI/Elements/utilstyle";
import GlobalColors from "../../../../../UI/Theme/colors";

export const CustomStyles = (isError: boolean, disabled: boolean, IsHighlightOptionError: boolean): any => ({
	indicatorsContainer: function (provided: any, state: any) {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		let fontColor = MenuIsOpen
		? Colors.ErrorColor
		: Colors.HoverColor;

		if(disabled){
			fontColor = Colors.DisabledFontColor;
		}

		let bgColor = isError && !disabled
		? Colors.ErrorBackgroundColor
		: provided.backgroundColor;

		if(IsHighlightOptionError){
			bgColor = GlobalColors.WHITE;
		}
		return {
			...provided,
			minHeight: 38,
			"& div:nth-of-type(1):not(.caret-select)": {
				color: fontColor,
				padding: 0,
				"&:hover": {
					color: fontColor,
				},
			},
			backgroundColor:bgColor,
		};
	},
	dropdownIndicator: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		let fontColor = MenuIsOpen
		? Colors.FocusColor
		: Colors.HoverColor;

		if(disabled){
			fontColor = Colors.DisabledColor;
		}
		return {
			...provided,
			transform: MenuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
			"&:hover": {
				color: fontColor,
			},
			color: fontColor,
		};
	},
	indicatorSeparator: (provided: any) => ({
		...provided,
		visibility: "hidden",
	}),
	multiValue: (provided: any, state: any) => {
		return {
			...provided,
			"& > div": {
				fontSize: "100%",
				color: state.data.highlightOption ? "#b82533" : "",
				backgroundColor: state.data.highlightOption ? "#fff2f2" : "",
			},
			"& > div:nth-of-type(2n)": {
				"&:hover": {
					backgroundColor: state.data.highlightOption
						? "#fff2f2"
						: provided.backgroundColor,
					color: Colors.ErrorColor,
				},
			},
		};
	},
	valueContainer: (provided: any) => {
		let bgColor = isError && !disabled ? Colors.ErrorBackgroundColor : "";
		if(IsHighlightOptionError){
			bgColor = GlobalColors.WHITE;
		}
		return  ({
			...provided,
			padding: 0,
			paddingTop:1,
			paddingLeft: 10,
			backgroundColor: bgColor,
		});
	},
	input: (provided: any) => ({
		...provided,
		margin: 0,
		padding: 0,
	}),
	placeholder: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		return {
			...provided,
			color: MenuIsOpen ? Colors.FocusColor : "#a9a9a9",
			fontSize:"0.875rem"
		};
	},
	control: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;

		let bgColor = isError
		? Colors.ErrorBackgroundColor
		: Colors.BackgroundColor;

		if(disabled){
			bgColor = Colors.DisabledBackgrounColor;
		}

		if(IsHighlightOptionError){
			bgColor = GlobalColors.WHITE;
		}

		let borderColor = "";

		if(disabled){
			borderColor = Colors.DisabledColor;
		} 
		else if(isError){
			borderColor = Colors.ErrorColor;
		}else{
			borderColor = MenuIsOpen? Colors.FocusColor : Colors.BorderColor;
		}

		let hoverBorderColor = "";

		if(isError && !disabled){
			hoverBorderColor = Colors.ErrorColor;
		} else{
			hoverBorderColor = MenuIsOpen? Colors.FocusColor : Colors.HoverColor;
		}

		return {
			...provided,
			minHeight: 40,
			fontSize:"0.875rem",
			boxShadow: "none",
			backgroundColor: bgColor,
			border: `1px solid ${borderColor} !important`,
			"&:hover": {
				border: `1px solid ${hoverBorderColor} !important`,
			},
			borderRadius: 2,
		};
	},
	option: (provided: any) => {
		return {
			...provided,
			"&:hover": {
				backgroundColor: `1px solid ${Colors.HoverColor}`,
			},
		};
	},
	singleValue: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";

		let fontColor = "";

		if(state.data.highlightOption){
			fontColor = "#b82533";
		} else if(MenuIsOpen){
			fontColor = Colors.FocusColor;
		} else{
			fontColor = disabled
			? Colors.DisabledFontColor
			: provided.color;
		}

		return {
			...provided,
			paddingTop:2,
			color: fontColor,
			opacity,
			transition,
		};
	},
	menu: (provided: any) => {
		return {
			...provided,
			margin: 0,
			fontSize:"0.875rem",
			padding: 0,
			zIndex:2
		};
	},
	container: (provided: any) => {
		return {
			...provided,
			width: "100%",
			minHeight:40,
			backgroundColor: GlobalColors.WHITE,
		};
	},
});
