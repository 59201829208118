import * as _ from "lodash";
import { IFileUpload } from "../../../interface/File/IFileUpload";
import { IGridHeader } from "../../../interface/Grid/IGridHeader";
import { IFormElement } from "../../../interface/IFormElement";
import { FileHelper } from "../../Utils/FileUtil";

// Validation helper for file
export class FileValidation{
	private fileHelper: FileHelper;

    constructor(){
		this.fileHelper = new FileHelper();
    }

	
    /**
     * Validation for grid file
     *
     * @param {IFormElement} formElement.
     * @param {File} file.
     * @return {boolean} true/false.
     */
    isValidFileForGrid = async (formElement: IFormElement, file: any): Promise<string> =>{
    	if(_.gte(file.size, parseInt(formElement.max_range))){
    		return `File size limit exceeded. Please upload an excel less than ${this.fileHelper.formatBytes(formElement.max_range)}.`;
    	}
    	if(!this.isValidExtension(file, "xlsx,xls")){
    		return "File type is incorrect. Try again with a XLSX or XLS file.";
    	}
    	return "";
    }

    /**
     * Check for file extension
     *
     * @param {File} file.
     * @return {boolean} true/false.
     */
    isValidExtension = (file: any, fileExtension: string): boolean =>{
    	const FileName = file.name;
    	const FileExtension = FileName.split(".").pop();
    	return !_.isEqual(fileExtension.indexOf(FileExtension), -1);
    }

	 /**
     * Check for files input text
     *
     * @param {File} file.
     * @return {boolean} true/false.
     */
	  isValidFileInputText = (files: IFileUpload[], formElement: IFormElement): boolean =>{
		let isValid = true;
		if(!_.isNull(formElement.customConfiguration)
		&& !_.isUndefined(formElement.customConfiguration.bucketUpload)
		&& !_.isUndefined(formElement.customConfiguration.bucketUpload.textBox)
		&& formElement.customConfiguration.bucketUpload.textBox?.required){
			files.forEach((file: IFileUpload) => {
				const TextInput = (file.fileInputText || "").toString().trim();
				isValid = !_.isEqual(TextInput.length, 0) && isValid;
			});
		}
		return isValid;
    }

    /**
     * Validate file size
     *
     * @param {IFormElement} formElement.
     * @param {number} fileSize.
     * @return {boolean}.
     */
    isValidFileSize = (formElement: IFormElement, fileSize: number): boolean =>{
    	
		if(_.isEmpty(formElement.max_range)){
			return true;
		}
		return _.lte(fileSize, formElement.max_range);
    }

	/**
     * Validate file extension
     *
     * @param {IFormElement} formElement.
     * @param {number} fileSize.
     * @return {boolean}.
     */
	 isValidFileExtension = (formElement: IFormElement, fileExtension: string): boolean =>{

		if(!_.isNull(formElement.allowed_file_type)){
			const Extension = (fileExtension || "").toLowerCase();
			const FileExtensions = formElement.allowed_file_type.split(",").map((ext: string)=> ext.toLowerCase());
			return !_.isEqual(FileExtensions.indexOf(`.${Extension}`), -1);
		}
		return true;
    }

    /**
     * Excel File schema validation
     *
     * @param {IFormElement} formElement.
     * @param {any} elementValue.
     * @return {string} errorMessages.
     */
     isValidExcelFileSchema = (formElement: IFormElement, excelHeaders:any): boolean =>{
		const HeaderData: IGridHeader[] = formElement.dynamic_configuration.headerData;
		const Headers = HeaderData.filter((header: IGridHeader) => !_.isEqual("id", _.toLower(_.trim((header.headerName))))).map((header: IGridHeader)=>{
			return _.toLower(_.trim(header.headerName));
		});

		const FileHeaders = excelHeaders.map((header: string)=>{
			return _.toLower(_.trim(header));
		});
	   
		return _.isEqual(_.sortBy(Headers), _.sortBy(FileHeaders));
     }

	 /**
     * Validate Grid data
     *
     * @param {IFormElement} formElement.
     * @return {string} errorMessages.
     */
	  isValidGrid = (headerData: IGridHeader[], gridData: any): boolean =>{
		let isValid = true;
		gridData.forEach((data: any) => {
			headerData
			.filter((header: IGridHeader) => !header.hide)
			.forEach((header: IGridHeader) => {
				if (header.config.required) {
				const Data = (data[header.field] || "").toString().trim();
				isValid = isValid && !_.isEqual(Data.length, 0);
				}
			});
		});
  		return isValid;
	}
}