import React, { useEffect, useState, Fragment } from "react";
import * as _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { getToolInfo, IsValidURL } from "./Helper";
import { ITool } from "../../../../UI/Elements/Forms/interface/ITool";
import {
  catchError,
  getErrorStatusCode,
} from "../../../../helpers/ErrorHandler.component";
import { AccessComponent } from "../../../../UI/Access";
import GeneralConfig from "../../../GeneralConfig";
import ContentExternalTool from "./ContentExternal";
import UnderMaintenanceTool from "./Maintenance";
import ReportTool from "./Report";
import { CircularProgress } from "../../../../UI/Progress";
import { InternalTool } from "./Internal";

interface IToolAccess {
  heading: string;
  description: string;
}

// Tool Type Component
const ToolComponent = (): React.JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = useState<any>(null);
  const [loading, setLoader] = useState<boolean>(true);
  const [toolAccess, setToolAccess] = useState<IToolAccess | null>(null);
  const bundle = useSelector((state: any) => state.initial.bundle);

  const [toolParams, setToolParams] = useState<any>(null);

  useEffect(() => {
    if (IsValidURL(location.search)) {
      setLoader(true);
      getToolInfo(location.search)
        .then((response: any) => {
          const Data: ITool = response.data;
          setData(Data);

          setToolParams({
            toolId: Data.tool_id,
            toolName: Data.tool_name,
            toolVersion: Data.tool_version,
          });
          setLoader(false);
        })
        .catch((error: any) => {
          const StatusCode = getErrorStatusCode(error);
          if (!_.isNull(StatusCode) && _.isEqual(StatusCode, 403)) {
            setToolAccess({
              heading: bundle.UNAUTH_TOOL_HEADING,
              description: bundle.UNAUTH_TOOL_DESCRIPTION,
            });
            setLoader(false);
            return;
          }

          if (!_.isNull(StatusCode) && _.isEqual(StatusCode, 404)) {
            setToolAccess({
              heading: bundle.NOT_EXISTS_TOOL_HEADING,
              description: bundle.NOT_EXISTS_TOOL_DESCRIPTION,
            });
            setLoader(false);
            return;
          }
          const errorMessage = catchError(error);
          setToolAccess({
            heading: bundle.TOOL_ERROR_HEADING,
            description: errorMessage,
          });
          setLoader(false);
        });
    } else {
      history.push("/");
    }
  }, []);

  if (loading) {
    return (
      <Fragment>
        <Helmet>
          <title>
            {_.isNull(toolParams) ? "Automations Portal" : toolParams.toolName}
          </title>
        </Helmet>
        <CircularProgress />
      </Fragment>
    );
  }
  if (!_.isNull(toolAccess)) {
    return (
      <AccessComponent
        heading={toolAccess.heading}
        description={toolAccess.description}
      />
    );
  }

  if (_.isNull(toolParams)) {
    history.push("/");
    return <span />;
  }

  if (_.isNull(data)) {
    return <span />;
  }

  const ToolState = data.tool_state.toLowerCase().trim();
  const ToolType = data.tool_type;
  let Component = <span />;

  if(data.under_maintenance){
    Component = <UnderMaintenanceTool {...{ data }}  />;
  }
  else if (
    _.isEqual(ToolState, GeneralConfig.CONTENT_BOX) ||
    _.isEqual(ToolState, GeneralConfig.EXTERNAL)
  ) {
    
    Component = <ContentExternalTool {...{ data }} />;
  } else if (_.isEqual(ToolState, GeneralConfig.INTERNAL)) {
    if (_.isEqual(ToolType, GeneralConfig.REPORT)) {
      
      Component = <ReportTool {...{ data }} />;
    } else {
      Component = <InternalTool toolFormData={data} toolParams={toolParams} />;
    }
  }

  return (
    <Fragment>
      <Helmet>
        <title>{data.tool_name}</title>
      </Helmet>
      {Component}
    </Fragment>
  );
};

export default ToolComponent;
