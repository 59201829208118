import Colors from "../../Theme/colors";

export default function style(): any {

    return ({
        button: {
            border: "1px solid rgb(208, 213, 218)",
            backgroundColor: Colors.WHITE,
            color: Colors.DARK_GRAY,
            marginLeft: "10px",
            marginRight: "4px",
            height: 38,
            fontSize: "14px",
            padding: "7px 12px 8px 12px",
            fontWeight: 500,
            "&:hover": {
                border: "1px solid #868e96",
                backgroundColor: Colors.WHITE,
            },
           
        },
        disabled: {
            opacity: "0.5",
            cursor: "default"
        },
        isSelected: {
            border: "1px solid #3a91e8 !important",
            borderRadius: 2,
            boxShadow: "0px 1px 4px 0px rgba(21, 118, 214, 0.15)",
            backgroundColor: "#f0f8ff !important",
        }
    });
}
