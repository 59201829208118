import * as _ from "lodash";
import { IOption } from "../../../../../../../../UI/Elements/Forms/interface/IOption";
import { ALL_OPTION_VALUE } from "../../../configuration";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";


export const isCloseMenuOnSelect = (formElement: IFormElement): boolean => {//NOSONAR
	const Value = _.isNull(formElement.value) || _.isUndefined(formElement.value) ? [] : formElement.value;//NOSONAR
	const {
		isMulti,
		dataSource,
		allowAll,
		selectionLimit
	} = formElement.configuration;


	const DataSource = dataSource || [];
	let closeMenuOnSelect = true;

	if (isMulti) {
		if (
			!_.isEqual(DataSource.length, Value.length + 1) ||//NOSONAR
			allowAll
		) {
			closeMenuOnSelect = false;
		}

		if (
			!_.isUndefined(selectionLimit) &&//NOSONAR
			!_.isNull(Value) &&//NOSONAR
			_.isArray(Value)
		) {
			const IsAll = Value.filter((option: IOption) =>
				_.isEqual(option.value, ALL_OPTION_VALUE)
			);

			if (allowAll) {
				const ExceptAll = DataSource.filter(
					(option: IOption) =>
						!_.isEqual(option.value, ALL_OPTION_VALUE)//NOSONAR
				);

				closeMenuOnSelect =
					!_.isEqual(ExceptAll.length, selectionLimit) &&//NOSONAR
					_.isEqual(Value.length + 1, selectionLimit);//NOSONAR
			} else {
				closeMenuOnSelect =
					_.isEqual(Value.length + 1, selectionLimit) &&//NOSONAR
					_.isEqual(IsAll.length, 0);//NOSONAR
			}
		}
	}
	return closeMenuOnSelect;
};


export const createOption = (label: string): IOption => ({
	label: label,
	value: label,
});