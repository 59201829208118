import React from "react";
import _ from "lodash";
import IconSchedule from "../../../../../../UI/Icons/Schedule";


type props = {
    row: any,
    onSchedule: any,
    title: any
};

export default function GridSchedule(props: props): React.JSX.Element {
    const { row, onSchedule, title } = props;
    if (
        (_.isEqual(_.toLower(row.jobStatus), _.toLower("Failed")) && _.isNull(row.jobScheduleInfo))
        ||  (_.isEqual(_.toLower(row.jobStatus), _.toLower("RUNNING"))) 
        ||  (_.isEqual(_.toLower(row.jobStatus), _.toLower("Pending")))
        ) {
        return (
            <>
                <IconSchedule onClick={() => onSchedule(row.jobId, row)} title={title} disabled={true} />
            </>
        );
    }
    return (
        <>
            <IconSchedule onClick={() => onSchedule(row.jobId, row)} title={title} disabled={false} />
        </>
    );
}