import { Theme } from "@material-ui/core/styles";

const style: any = (theme: Theme): any => ({
	iframContainer: {
		textAlign: "center" as const,
	},
	messageContainer: {
		textAlign: "center" as const,
	},
	container: {
		height: 92,
		alignItems: "center",
		padding: "32px 40px 24px",
		backgroundColor: "#FFFF"
	},
	title: {
		fontSize: 24,
		fontWeight: theme.typography.fontWeightBold
	},
	iframe: {
		height: "766px",
		width: "100%",
	}
});

export default style;