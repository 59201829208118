import * as _ from "lodash";
import { ELEMENT_TYPES, PLATFORM_TYPE } from "../configuration";
import { IGridFilter } from "../Interfaces/Filter/IGridFilter";
import { IFormElement } from "../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../Interfaces/Form/IForm";
import { IFormGroup } from "../Interfaces/Form/IFormGroup";
import { FilterFormHelper } from "../Layout/Form/Helpers/Forms/Filter";
import { UtilHelper } from "../Layout/Form/Helpers/Utility";

const getLabelValue = (dataValue: any, key: string): any => {

	return !_.isNull(dataValue) && (!_.isNull(dataValue[key]) && !_.isUndefined(dataValue[key]))
		? dataValue[key] : "";
};

const getData = (gridData: any): any => {

	return gridData || null;
};


export const formatGridData = (data: any[], tab: any, PlatformValue: any): any[] => {

	const findPlatform = tab?.platform.data.find((ele: any) => {
		return _.isEqual(ele.value, PlatformValue);
	});

	return data.map((grid: any) => {

		const Agency = getData(grid.agency);
		const Brand = getData(grid.brand);
		const Client = getData(grid.client);
		const Manager = getData(grid.manager);
		const Mapper = getData(grid.mapper);
		const Market = getData(grid.market);
		const Subagency = getData(grid.subagency);
		const Vertical = getData(grid.vertical);
		let payloaddata: any = {};

		const formData = {
			mappingManager: !_.isNull(Manager) ? {
				username: Manager.email,
				name: Manager.name
			} : {
				username: "",
				name: ""
			},
			agency: {
				agencyName: getLabelValue(Agency, "label"),
				agencyValue: getLabelValue(Agency, "value"),
				subagencyName: getLabelValue(Subagency, "label"),
				subAgencyValue: getLabelValue(Subagency, "value"),
			},
			market: {
				marketName: getLabelValue(Market, "label"),
				marketValue: getLabelValue(Market, "value"),
				marketCode: getLabelValue(Market, "code"),
			},
			client: {
				clientName: getLabelValue(Client, "label"),
				clientValue: getLabelValue(Client, "value"),
				brandName: getLabelValue(Brand, "label"),
				brandValue: getLabelValue(Brand, "value"),
			},
			vertical: {
				verticalName: getLabelValue(Vertical, "label"),
				verticalValue: getLabelValue(Vertical, "value")
			},
			mappedby: (_.isNull(Mapper.email) && _.isNull(Mapper.name))
				?
				{
					name: "-NA-",
					mappedDate: grid.modifieddate || "",
					email: "Not Available",
				}
				:
				{
					name: getLabelValue(Mapper, "name"),
					mappedDate: grid.modifieddate || "",
					email: getLabelValue(Mapper, "email"),
				},
			includeInAdoptionStats: _.isEqual(grid.includeinadoptionstats, 1) ? 1 : 0
		};

		switch (findPlatform?.label) {
			case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
				payloaddata = {
					...formData,
					mappingmetaid: grid.mappingmetaid,
					account: {
						adaccountName: grid.adaccountname || "",
						adaccountId: grid.adaccountid || ""
					},
					manager: {
						businessmanagerName: grid.businessmanagername || "",
						businessmanagerId: grid.businessmanagerid || ""
					},
				};
				break;

			case PLATFORM_TYPE.GOOGLE_ADS:
				payloaddata = {
					...formData,
					mappingGoogleAdsId: grid.mappinggoogleadsid,
					account: {
						accountName: grid.accountname || "",
						accountId: grid.accountid || ""
					},
					manager: {
						managerName: grid.managername || "",
						managerId: grid.managerid || ""
					}
				};
				break;
			default:
				payloaddata = {};
		}
		return payloaddata;
	});
};

function getDropdownValue(label: any, value: any): any {

	const Value = value || null;
	const Label = label || null;

	if (_.isNull(Value)) {
		return null;
	}
	return {
		label: Label,
		value: Value,
	};
}

export const buildFormData = (rowData: any, tab: any, PlatformValue: number): any => {
	const findPlatform = tab?.platform.data.find((ele: any) => {
		return _.isEqual(ele.value, PlatformValue);
	});

	const formData = {
		agency: getDropdownValue(rowData.agency.agencyName, rowData.agency.agencyValue),
		subagency: getDropdownValue(rowData.agency.subagencyName, rowData.agency.subAgencyValue),
		market: getDropdownValue(rowData.market.marketName, rowData.market.marketValue),
		client: getDropdownValue(rowData.client.clientName, rowData.client.clientValue),
		brand: getDropdownValue(rowData.client.brandName, rowData.client.brandValue),
		vertical: rowData.vertical,
		includeInAdoptionStats: rowData.includeInAdoptionStats,
		mappedBy: rowData.mappedby.name,
		date: rowData.date,
		status: rowData.status,
		mappingManager: rowData.mappingManager.email,
		account: rowData.account,
		manager: rowData.manager
	};

	let data: any = {};
	switch (findPlatform?.label) {
		case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
			data = {
				...formData,
				mappingmetaid: rowData.mappingmetaid
			};
			break;
		case PLATFORM_TYPE.GOOGLE_ADS:
			data = {
				...formData,
				mappinggoogleadsid: rowData.mappingGoogleAdsId
			};
			break;
		default:
			data = {};
	}
	return data;
};

export const buildSubmitFormData = (form: IForm, formData: any, platformId: any, tab: any): any => {
	const findPlatform = tab?.platform.data.find((ele: any) => {
		return _.isEqual(ele.value, platformId);
	});

	const FormData: any = {};
	form.groups.forEach((formGroup: IFormGroup) => {

		formGroup.elements.forEach((formElement: IFormElement) => {
			FormData[formElement.id] = formElement.value;
		});
	});

	const formdata = {
		platformid: platformId,
		type: "review",
		agencyid: FormData.agency.value,
		subagencyid: !_.isNull(FormData.subagency) ? FormData.subagency.value : "",
		verticalid: !_.isNull(FormData.vertical) ? FormData.vertical.value : "",
		clientid: FormData.client.value,
		brandid: !_.isNull(FormData.brand) ? FormData.brand.value : "",
		marketid: FormData.market.value,
		includeinadoptionstats: FormData.includeInAdoptionStats ? 1 : 0,
	};

	let data: any = {};
	switch (findPlatform?.label) {
		case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
			data = {
				...formdata,
				mappingmetaid: formData.mappingmetaid,
				businessmanagerid: FormData.manager.businessmanagerId,
				businessmanagername: FormData.manager.businessmanagerName,
				adaccountname: FormData.account.adaccountName,
				adaccountid: FormData.account.adaccountId
			};
			break;
		case PLATFORM_TYPE.GOOGLE_ADS:
			data = {
				...formdata,
				mappinggoogleadsid: formData.mappinggoogleadsid,
				managerid: FormData.manager.managerId,
				managername: FormData.manager.managerName,
				accountname: FormData.account.accountName,
				accountid: FormData.account.accountId,
			};
			break;
		default:
			data = null;
	}
	return data;
};

const getSelectSubmitData = (formElement: IFormElement): any => {
	const utilHelper = new UtilHelper();
	const filterHelper = new FilterFormHelper();
	if (formElement.configuration.isMulti
		&& !_.isNull(formElement.value)) {
		return utilHelper.isAllSelected(formElement.value) ? [] : filterHelper.getMultiSelectValue(formElement);
	}
	return _.isNull(formElement.value) ? null : formElement.value;
};


export const getFilterSubmitData = (gridFilter: IGridFilter, searchText: string, platformValue: number): any => {
	const FilterData: any = {
		search: (searchText || "").toString().trim(),
		pageSize: gridFilter.pageSize,
		pageNumber: gridFilter.pageNumber + 1,
		order: gridFilter.order,
		orderBy: gridFilter.orderBy
	};

	gridFilter.sidebarFilter!.form.groups.forEach((formGroup: IFormGroup) => {

		formGroup.elements.forEach((formElement: IFormElement) => {
			if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT) || _.isEqual(formElement.type, ELEMENT_TYPES.ASYNC_SELECT)) {
				FilterData[formElement.id] = getSelectSubmitData(formElement);
			}
			else {
				FilterData[formElement.id] = formElement.value;
			}
		});
	});

	return {
		platformid: platformValue || 0,
		agencies: FilterData.agencies,
		mappingManagers: FilterData.mappingManager,
		subAgencies: FilterData.subAgencies,
		verticals: FilterData.verticals,
		clients: FilterData.clients,
		brands: FilterData.brands,
		markets: FilterData.markets,
		mappedBy: FilterData.mappedby,
		includedInStatsDashboard: FilterData.includeInAdoptionStats,
		search: searchText
	};
};