import React from "react";
import { Tooltip } from "@material-ui/core";
import * as _ from "lodash";

// Props type
type props = {
  title: any;
  children: any;
  disableTooltip?: boolean;
};

// UITooltip Component
export const UITooltip = (props: props): React.JSX.Element => {
  const { title, disableTooltip } = props;

  if (_.isEqual(title.length, 0)) {
    return <span />;
  }

  return (
    <Tooltip
      title={title}
      interactive
      disableHoverListener={disableTooltip}
      placement={"top"}
    >
      {props.children}
    </Tooltip>
  );
};

// Default Props
UITooltip.defaultProps = {
  title: "",
};

export default UITooltip;
