import React from "react";
import Select, { components } from "react-select";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CustomStyles, style } from "./style";
import { IFormElement } from "../../../Interface/FormElement/IFormElement";
import UILabel from "../ChildElements/Label";
import { UIErrorOrNoteMessage } from "../ChildElements/ErrorOrNote";
import { IOption } from "../../../Interface/IOption";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  formElement: IFormElement;
  onChange: any;
  formId: string;
  formGroupId: string;
};

export const isCloseMenuOnSelect = (formElement: IFormElement): boolean =>{
  const {
    isMulti,
    dataSource
  } = formElement.configuration;

  if(!isMulti){
    return true;
  }

	const Value = !_.isArray(formElement.value) ? [] : formElement.value;
	const DataSource = dataSource || [];
	return _.isEqual(DataSource.length, Value.length + 1);
};

export const UISelect = (props: props): React.JSX.Element => {
  const { formElement, onChange, formId, formGroupId } = props;
  const classes = useStyles();
  const Disabled = formElement.disabled;
  let errorMessage = formElement.touched ? formElement.errorMessage : "";
  errorMessage = Disabled ? "" : errorMessage;

  const IsError = !_.isEqual(errorMessage.length, 0);
  const DataSource = _.orderBy(formElement.configuration.dataSource || [], [(option: IOption) => option.label.toLowerCase()], ["asc"]);

  return (
    <div className={classes.width100}>
      <UILabel formElement={formElement} />
      <div>
        <span className={classes.width100}>
          <Select
            id={formElement.id}
            closeMenuOnSelect={isCloseMenuOnSelect(formElement)}
            isMulti={formElement.configuration.isMulti}
            isClearable={!formElement.validations.required}
            value={formElement.value}
            isDisabled={Disabled}
            components={{
              DropdownIndicator: (data: any) => {
                return (
                  <components.DropdownIndicator
                    {...data}
                    className="caret-select"
                  />
                );
              },
            }}
            onBlur={(): void => {
              onChange({
                formId,
                formGroupId,
                formElement,
                value: formElement.value,
              });
            }}
            styles={CustomStyles(IsError, Disabled, false)}
            onChange={(selectedOption: any) => {
              return onChange({
                formId,
                formGroupId,
                formElement,
                value: selectedOption,
              });
            }}
            options={DataSource}
            placeholder={formElement.configuration.placeholder}
          />
        </span>
        <UIErrorOrNoteMessage
          formElement={formElement}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
};
