import React from "react";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../Interfaces/Form/IForm";
import { FormElement } from "./Element";
import style from "./style";
import { IFormGroup } from "../../Interfaces/Form/IFormGroup";
import { Grid } from "@material-ui/core";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

type props = {
  form: IForm;
  onChange: any;
  onCreateOption: any;
};

export const FormBuilder = (props: props): React.JSX.Element => {
  const { form, onChange, onCreateOption } = props;
  const classes = useStyles();

  const FormGroups = form.groups.map((formGroup: IFormGroup) => {
    const GroupTitle = (formGroup.title || "").toString().trim();

    return (
      <Grid
        className={classes.formGroup}
        container
        xl={12}
        lg={12}
        md={12}
        xs={12}
        item
        key={formGroup.id}
      >
        {!_.isEqual(GroupTitle.length, 0) && (
          <header className={classes.sectionTitle}>{formGroup.title}</header>
        )}
        {formGroup.elements
          .filter((formElement: IFormElement) => formElement.visible)
          .map((formElement: IFormElement) => (
            <FormElement
              formElement={formElement}
              form={form}
              onChange={onChange}
              key={formElement.id}
              onCreateOption={onCreateOption} formId={0} formGroupId={0} />
          ))}
        </Grid>
    );
  });

  return <>{FormGroups}</>;
};
