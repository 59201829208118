import React, { useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import classNames from "classnames";
import style from "./style";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { IconEdit } from "../../../../../../UI/Icons/Edit";
import { IconViewDetail } from "../../../../../../UI/Icons/ViewDetail";
import { IconClone } from "../../../../../../UI/Icons/Clone";
import _ from "lodash";


const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
    onClick: any;
    title: any;
    onDetailClick: any;
    jobId: any;
    onClone: any;
    onEdit: any;
    persistButton?: any;
    allValue?:any;
    jobName: string;
};

// More Button Component
export const IconMore = (props: props): React.JSX.Element => {
    const classes = useStyles();
    const { onClick, title, onDetailClick, jobId, onClone, onEdit, persistButton, allValue, jobName} = props;
    const [open, setOpen] = React.useState(false);
    const anchorRef: any = React.useRef(null);
    const handleToggle = (): void => {
        onClick();
        setOpen(!open);
    };

    const handleClose = (event: any): void => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const viewDetail=(event:any): void => {
        onDetailClick(jobId, jobName);
        handleClose(event);
    };

    const cloneClick=(event:any):void=>
    {
        onClone(jobId, jobName);
        handleClose(event);
    };

    const editClick=(event:any):void=>
    {
        onEdit(jobId,jobName);
        handleClose(event);
    };

    function handleListKeyDown(event: any): void {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }

    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef?.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <Tooltip title={title} placement="top">
                <IconButton
                    size={"small"}
                    className={classNames(classes.moreIcon)}
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <MoreHorizIcon style={{ color: "#6A737C" }} />
                </IconButton>
            </Tooltip>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.menuGroup}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            boxShadow:
                                "0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)",
                            transformOrigin: placement === "bottom" ? "center top" : "center left"
                        }}
                    >
                        <Paper className={classes.menuGroup}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} className={classes.menuList}>
                                    <MenuItem onClick={() => { viewDetail(jobId); }} className={classes.menuItem}><IconViewDetail onClick={""} /><span className={classes.menuListText}>View Detail</span></MenuItem>
                                    {!_.isEmpty(persistButton) &&
                                        (_.isEqual(allValue, "All"))
                                        ?
                                        <>
                                            <MenuItem onClick={() => { cloneClick(jobId); }} className={classes.menuItem}><IconClone onClick={""} /><span className={classes.menuListText}>Clone</span></MenuItem>
                                            <MenuItem onClick={() => { editClick(jobId); }} className={classes.menuItem}><IconEdit isSmall={true} onClick={""} disabled={false} /><span className={classes.menuListText}>Edit</span></MenuItem>

                                        </>
                                        :
                                        (persistButton.map((value: string) => (
                                            <>
                                                {(_.isEqual(value, "Clone")) && (<MenuItem onClick={() => { cloneClick(jobId); }} className={classes.menuItem}><IconClone onClick={""} /><span className={classes.menuListText}>Clone</span></MenuItem>)}
                                                {(_.isEqual(value, "Edit")) && (<MenuItem onClick={() => { editClick(jobId); }} className={classes.menuItem}><IconEdit isSmall={true} onClick={""} disabled={false} /><span className={classes.menuListText}>Edit</span></MenuItem>)}
                                            </>
                                        )))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>


    );
};

export default IconMore;
