import { createContext } from "react";

const FilterOptionContext = createContext({
  stateFilter: {
    channel_id: 1,
    tooltype_id: 0,
    lifecycle_id: 0,
    toollabel_id: 0,
    include_beta: 0,
    platform_id: [0],
    search: "",
    order_by: "asc",
    include_favorite: 0
  },
  updateSelectedFilter: (data: any) => {
    console.log(data);
  },
});

export default FilterOptionContext;
