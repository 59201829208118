import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "../style";
import { formatGridCell } from "./Helper";
import { IGridHeader } from "../../../Interfaces/Grid/IGridHeader";
import classNames from "classnames";
import { TEXT_ALIGNMENT } from "../../../configuration";


const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  data: any;
  gridHeader: IGridHeader;
  status: string;
};

export default function GridCell(props: props): React.JSX.Element {
  const classes = useStyles();
  const { gridHeader, data , status } = props;
 

  let cssStyle = {};
  if (!_.isUndefined(gridHeader.width)) {
    cssStyle = {
      width: gridHeader.width,
    };
  }

  return (
    <td style={cssStyle} className={classes.gridCell}>
      <span
        className={classNames(
          {
            [classes.centerAlign]: _.isEqual(
              TEXT_ALIGNMENT.CENTER,
              gridHeader.alignment
            ),
          },
          {
            [classes.leftAlign]: _.isEqual(
              TEXT_ALIGNMENT.LEFT,
              gridHeader.alignment
            ),
          },
          {
            [classes.rightAlign]: _.isEqual(
              TEXT_ALIGNMENT.RIGHT,
              gridHeader.alignment
            ),
          }
        )}
      >
        {formatGridCell(gridHeader, data, status)}
      </span>
    </td>
  );
}
