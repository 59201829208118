import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  title: string;
};

// Filter Icon functional component
export const IconFilter = (props: props): JSX.Element => {
  const classes = useStyles();
  const { title } = props;

  return (
    <Tooltip title={title}>
      <div aria-label={title} className={classes.filterIcon}>
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Branding/Icons/Filter"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="ri-equalizer-fill">
              <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
              <path
                d="M4.11333333,12 C4.39559955,11.2000262 5.15168858,10.665033 6,10.665033 C6.84831142,10.665033 7.60440045,11.2000262 7.88666667,12 L14.6666667,12 L14.6666667,13.3333333 L7.88666667,13.3333333 C7.60440045,14.1333071 6.84831142,14.6683004 6,14.6683004 C5.15168858,14.6683004 4.39559955,14.1333071 4.11333333,13.3333333 L1.33333333,13.3333333 L1.33333333,12 L4.11333333,12 L4.11333333,12 Z M8.11333333,7.33333333 C8.39559955,6.53335955 9.15168858,5.9983663 10,5.9983663 C10.8483114,5.9983663 11.6044005,6.53335955 11.8866667,7.33333333 L14.6666667,7.33333333 L14.6666667,8.66666667 L11.8866667,8.66666667 C11.6044005,9.46664045 10.8483114,10.0016337 10,10.0016337 C9.15168858,10.0016337 8.39559955,9.46664045 8.11333333,8.66666667 L1.33333333,8.66666667 L1.33333333,7.33333333 L8.11333333,7.33333333 L8.11333333,7.33333333 Z M4.11333333,2.66666667 C4.39559955,1.86669288 5.15168858,1.33169963 6,1.33169963 C6.84831142,1.33169963 7.60440045,1.86669288 7.88666667,2.66666667 L14.6666667,2.66666667 L14.6666667,4 L7.88666667,4 C7.60440045,4.79997378 6.84831142,5.33496703 6,5.33496703 C5.15168858,5.33496703 4.39559955,4.79997378 4.11333333,4 L1.33333333,4 L1.33333333,2.66666667 L4.11333333,2.66666667 Z"
                id="Shape"
                fill="#6A737C"
              ></path>
            </g>
          </g>
        </svg>
      </div>
    </Tooltip>
  );
};
