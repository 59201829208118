const style: any = (): any => ({
	tileContainer: {
		width: "100%",
		padding: "0px 28px 28px 28px",
		margin: 0
	},
	noDataAvailable:{
		color: "#6a737c",
		fontSize: 16,
		fontWeight: 600,
		marginRight: 6,
		textAlign: "center",
		padding: 12,
		height: 94,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width:"100%",
		backgroundColor:"#ffffff"
	},
});

export default style;