import Colors from "../../../../../../utilstyle";
import GlobalColors from "../../../../../../../Theme/colors";

const style: any = (): any => ({
	inputContainer:{
		display:"flex",
		flex:1,
		width:"100%",
		height: 40,
		backgroundColor: GlobalColors.WHITE,
	},
	input: {
		backgroundColor: GlobalColors.WHITE,
		border: `1px solid ${Colors.BorderColor}`,
		borderRadius: 2,
		width:"100%",
		fontSize:"0.875rem",
		padding: "7px 12px",
		"box-sizing": "border-box",
		"&::placeholder":{
			fontStyle:"italic"
		}
	},
	inputElement:{
		"&:hover":{
			border: `1px solid ${Colors.HoverColor}`,
		},
		"&:focus":{
			border: `1px solid ${Colors.FocusColor}`,
		},
        
	},
});

export default style;