import React, { Fragment } from "react";
import Switch from "@material-ui/core/Switch";
import classNames from "classnames";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IFormElement } from "../../Forms/interface/IFormElement";
import style from "./style";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

export const UISwitch = (props: props): JSX.Element => {
  const classes = useStyles();
  const { formElement, onChange, formId, formGroupId } = props;
  const Disabled = formElement.disabled || formElement.read_only;
  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        disabled={Disabled || formElement.read_only}
        checked={formElement.value}
        onChange={(event: any): void => {
          const Value = event.target.checked;
          onChange({ formId, formGroupId, formElement, value: Value });
        }}
        className={classNames(
          (formElement.value || "").toString() && classes.isActive,
          {
            [classes.disabled]: Disabled,
          }
        )}
      />
      <UIErrorOrNoteMessage formElement={formElement} errorMessage={""} />
    </Fragment>
  );
};
