import React from "react";
import { Drawer } from "@material-ui/core";
import { SidebarFormComponent } from "./Form";

type props = {
  toggleDrawer: any;
  btnSaveText: string;
  formTitle: string;
  onSave: any;
  onCancel: any;
  btnCancelText: string;
  formData: any;
  isFilterForm: boolean;
  open: boolean;
  resetText?: string;
  onReset?: any;
  width?: number;
};

export const SidebarComponent: any = (props: props): React.JSX.Element => {
  const {
    formData,
    formTitle,
    btnCancelText,
    btnSaveText,
    onCancel,
    onSave,
    width,
    open,
    resetText,
    onReset,
    isFilterForm,
  } = props;

  return (
    <Drawer anchor="right" open={open}>
      {open && (
        <SidebarFormComponent
        formData={formData}
        formTitle={formTitle}
        btnCancelText={btnCancelText}
        btnSaveText={btnSaveText}
        onCancel={onCancel}
        onSave={onSave}
        isFilterForm={isFilterForm}
        width={width}
        onReset={onReset}
        resetText={resetText}
        />
      )}
    </Drawer>
  );
};
