import React,{useState, useEffect} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Button, Grid } from "@material-ui/core";
import * as _ from "lodash";
import { FormBuilder } from "../../builder";
import { ReviewForm } from "./ReviewForm";
import style from "./style";
import { ToolInfo } from "../../../../../../components/Toolkit/Tool/Type/Layout";
import { ITool } from "../../../interface/ITool";
import { IForm } from "../../../interface/IForm";
import { UIOutlineButton } from "../../../../../Buttons";
import AddIcon from "@material-ui/icons/Add";
import { MULTI_FORM_VERSION, UI_BTN_ACTIONS } from "../../../../config";
import { EditForm } from "../../../helper/Forms/EditForm";
import { IconTrash } from "../../../../../Icons";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  tool: ITool;
  onChange: any;
  onSubmit: any;
  onValidateForms: any;
  handleNextBack: any;
  handleReset: any;
  activeStep: number;
  handleNewForm: any;
  goToStep: any;
  handleBackButton: any;
  loading: boolean;
  ishavingParent: any;
};

export const StepperForm = (props: props): JSX.Element => {
  const {
    tool,
    onChange,
    onValidateForms,
    handleNextBack,
    handleReset,
    activeStep,
    onSubmit,
    handleNewForm,
    goToStep,
    handleBackButton,
    loading,
    ishavingParent
  } = props;
  const classes = useStyles();

  const propsToToolInfo = {
    data: { ...tool },
  };
  const Forms = tool.forms.filter((form: IForm) => form.isVisible);
  const [showAdd, setShowAdd] = useState<boolean>(true);
  const [showRemove, setShowRemove] = useState<boolean>(false);
  let nextButtonCount=activeStep;
  let disabledBackButton = _.isEqual(activeStep, 0);

 useEffect(()=>{
  const isMultiFormAvailable = Forms.filter(form => !_.isEqual(form.id % 1,0));
    !_.isEmpty(isMultiFormAvailable) ? setShowAdd(false): setShowAdd(true);
 },[activeStep]);
  
  const isValidActiveStep = (step: number):boolean =>{
    return _.lt(step,Forms.length); 
  };

  const isButtonDisabled = (): boolean => {
    return !Forms[activeStep]?.isValid 
    || (isValidActiveStep(activeStep + 1) ? !Forms[activeStep + 1]?.isValid : false) 
    || !Forms[nextButtonCount]?.isValid 
    || (isValidActiveStep(activeStep + 1) ? !Forms[nextButtonCount + 1]?.isValid : false) 
    || Forms[activeStep].loading;
  };

  const buildForm = (step: any): any => {
    return <FormBuilder
      form={Forms[step]}
      onValidateForms={onValidateForms}
      onChange={onChange}
      loading={loading}
      ishavingParent={ishavingParent}
    />;
  };

  const buildFormForMulti = (currentStep: any, submitBtnText:any, showFooterCTA: boolean, showRemoveButton = false): any =>{
    const editForm: EditForm = new EditForm();
    
    if(showRemoveButton){
      nextButtonCount = nextButtonCount + 2;
    }
    return (
      <>
        <Grid item direction="column" xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}>
          <Grid item className={classes.infoWrapper}>
            {showRemoveButton &&
              <div className={classes.additionalFormLine}></div>
            }
            <div className={classes.toolContent}>
              {editForm.showAddForm(Forms) && showAdd &&
              <div>
                <div className={classes.addButtonWrapper} 
                  onClick={() => {
                      const DynamicElement = {
                        formId: Forms[activeStep].id, dynamicElement: { action: UI_BTN_ACTIONS.ADD }
                      };
                      setShowAdd(false);
                      setShowRemove(true);
                      nextButtonCount = nextButtonCount + 2;
                      return onChange(DynamicElement);
                    }}>
                  <AddIcon />
                  <div className={classes.addButton} >
                    {`Add ${Forms[activeStep].title}`}
                    </div>
                </div>
                </div>
              }
              {showRemoveButton && 
                showRemove && 
                Forms.filter(x => x.id.toString().startsWith(Forms[activeStep].id.toString())).length > 1 &&
                <div className={classes.deleteButtonContainer}>
                  <div className={classes.deleteButtonWrapper}
                    onClick={() => {
                      const DynamicElement = {
                        formId: Forms[activeStep].id, dynamicElement: { action: UI_BTN_ACTIONS.DELETE }
                      };
                      setShowAdd(true);
                      setShowRemove(false);
                      nextButtonCount = nextButtonCount - 2;
                      return onChange(DynamicElement);
                    }}>
                    <IconTrash
                      title=""
                      disabled={false}
                      onClick={() => { }}
                    />
                    <div className={classes.deleteButton}>
                      {`Delete ${Forms[activeStep].title}`}
                    </div>
                  </div>
                </div>
              }
              {buildForm(currentStep)}
            </div>
          </Grid>
          <Grid item className={classes.infoWrapper}>
            <div className={classes.toolContent}>
              {buildForm(currentStep + 1)}
              {showFooterCTA &&
                <div>
                  <UIOutlineButton
                    cssStyle={{
                      marginRight: "0.75rem",
                    }}
                    disabled={disabledBackButton}
                    isOutline={true}
                    onClick={() => handleNextBack(showRemoveButton ? nextButtonCount - 4 : nextButtonCount - 2)}
                    btnText="Back"
                  />
                  {_.isEqual(activeStep, Forms.length) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        onSubmit(tool.forms, (isError: boolean) => {
                          if (isError) {
                            return;
                          }
                          return handleNextBack(2);
                        })
                      }
                      className={classes.button}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleNextBack(isValidActiveStep(activeStep + 1) ? nextButtonCount + 1 : nextButtonCount)}
                      className={classes.button}
                      disabled={isButtonDisabled()}
                    >
                      {submitBtnText}
                    </Button>
                  )}
                  <UIOutlineButton
                    cssStyle={{
                      marginLeft: 4,
                    }}
                    isOutline={true}
                    onClick={() => {
                      handleBackButton({
                        action: "Cancel",
                      });
                    }}
                    btnText="Cancel"
                  />
                </div>
              }
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  const getToolInfo = (): any => {
    return (<Grid
      container
      item
      xs={12}
      sm={12}
      md={4}
      lg={4}
      xl={4}
      justifyContent="center"
      className={classes.infoWrapper}
    >
      <ToolInfo {...propsToToolInfo} />
    </Grid>);
  };

  if (_.lt(activeStep, Forms.length)) {
    disabledBackButton = disabledBackButton || Forms[activeStep].loading;
  }
  let formData;
  if (_.isEqual(activeStep, Forms.length + 1)) {
    formData = (
      <div className={classes.toolContent}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleNewForm();
            return handleReset();
          }}
        >
          New Form
        </Button>
        <UIOutlineButton
          cssStyle={{
            marginLeft: "0.75rem",
          }}
          isOutline={true}
          onClick={() => {
            handleBackButton({
              action: "Go back",
            });
          }}
          btnText="Go back"
        />
      </div>
    );
  } else {
    let formStep;
    if (_.isEqual(activeStep, Forms.length)) {
      formStep = <ReviewForm forms={Forms} goToStep={goToStep} />;
    } else {
      if(!Forms[activeStep]?.isMulti){
      formStep = (
        buildForm(activeStep)
      );
      }
    }
    let submitBtnTxt;
    if (_.isEqual(activeStep, Forms.length)) {
      submitBtnTxt = "Submit";
    } else {
      submitBtnTxt = "Next";
    }

    if(Forms[activeStep]?.isMulti){
      //To check more than 2 forms are added in current stepper
      const isMultiFormEnabled = _.gt(Forms.filter(x => x.id.toString().startsWith(Forms[activeStep].id.toString())).length,1);
      formData = (
        <>
          {buildFormForMulti(activeStep, submitBtnTxt, !isMultiFormEnabled)}
          {getToolInfo()}
          {isMultiFormEnabled && 
            buildFormForMulti(Forms.map(function (x) { return x.id; }).indexOf(Forms[activeStep].id + MULTI_FORM_VERSION), submitBtnTxt, isMultiFormEnabled, true)
          }
        </>
      );
    }else{
    formData = (
      <Grid
            container
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className={classes.infoWrapper}
          >
      <div className={classes.toolContent}>
        {formStep}

        <div>
          <UIOutlineButton
            cssStyle={{
              marginRight: "0.75rem",
            }}
            disabled={disabledBackButton}
            isOutline={true}
            onClick={() => {
              if(tool.forms[tool.forms.length -1].parentId){
                return handleNextBack(- (nextButtonCount - 1));
              }else{
              handleNextBack(-1);}}}
            btnText="Back"
          />
          {_.isEqual(activeStep, Forms.length) ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                onSubmit(tool.forms, (isError: boolean) => {
                  if (isError) {
                    return;
                  }
                  return handleNextBack(1);
                })
              }
              className={classes.button}
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => { 
                handleNextBack(1);
                if(_.isEmpty(tool.defaultForms)){
                  tool.defaultForms = tool.forms;
                }
              }}
              className={classes.button}
              disabled={!Forms[activeStep].isValid || Forms[activeStep].loading}
            >
              {submitBtnTxt}
            </Button>
          )}
          <UIOutlineButton
            cssStyle={{
              marginLeft: 4,
            }}
            isOutline={true}
            onClick={() => {
              handleBackButton({
                action: "Cancel",
              });
            }}
            btnText="Cancel"
          />
        </div>
      </div>
      </Grid>
    );
          }
  }
  
  return (
    <Grid container className={classes.toolContentWrapper}>
        <>
          {formData}
          {/* Avoiding toolInfo for multiForm because it is already rendred inside isMulti check  */}
          {!Forms[activeStep]?.isMulti && getToolInfo()}
        </>

    </Grid>
  );
};
