import React from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import UITooltip from "../../../Elements/Tooltip";
import style from "./style";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
type props = {
  tooltip: string;
  text: string;
};

export const TextTooltipElement = (props: props): React.JSX.Element => {
  const { text, tooltip } = props;
  const classes = useStyles();
  return (
    <UITooltip title={`${text} - ${tooltip}`}>
      <div>
        <div className={classes.textContainer}>{text}</div>
      </div>
    </UITooltip>
  );
};
