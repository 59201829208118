import React, { Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import * as _ from "lodash";
import styles from "./style";
import classNames from "classnames";
import { Tooltip } from "@material-ui/core";

type props = {
  classes?: any;
  bundle?: any;
  status: string;
};

const StatusIndicator = (props: props): React.JSX.Element => {
  const { classes, status } = props;
  const STATUS_ID = _.isNull(status) ? "" : status.toLowerCase().replace(" ", "");
  return (
    <Fragment>
      <Tooltip title="Jobs can’t be scheduled for this tool" placement="top">
        <div className={classNames(classes[STATUS_ID], classes.text)}>
          {status}
        </div>
      </Tooltip>
    </Fragment>
  );
};

export default withStyles(styles)(StatusIndicator);
