import React, { useRef, useState } from "react";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, LinearProgress } from "@material-ui/core";
import classnames from "classnames";
import parentStyle from "../../../../../UI/Elements/FormElements/UploadFile/BucketUpload/style";
import { GoogleUtilHelper } from "../../../../../UI/Elements/Forms/helper/Utils/FileUtil/GoogleUtil";
import { UIBucketUploadAlert } from "../../../../../UI/Elements/FormElements/UploadFile/BucketUpload/Dialog";
import { UIFileError } from "../../../../../UI/Error/File";
import UILabel from "../ChildElements/Label";
import {
  IDialog,
  IDialogType,
} from "../../../../../UI/Elements/FormElements/UploadFile/BucketUpload/IDialog";
import { UIOutlineButton } from "../../../../../UI/Buttons";

import JSXHelper from "./Helper";
import Utility from "./Utility";
import { IFormElement } from "../../../Interface/FormElement/IFormElement";
import { IFileUpload } from "../../../Interface/File/IFileUpload";
import { IFileUploadResponse } from "../../../Interface/File/IFileUploadResponse";
import style from "./style";

const useParentStyles = makeStyles(() => createStyles(parentStyle()));
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: string;
  formGroupId: string;
};

// UI Bucket Upload for Grid
export const UIImageUpload = (props: props): React.JSX.Element => {
  const parentClasses = useParentStyles();
  const classes = useStyles();

  const { formElement } = props;

  const errorMessage = formElement.touched ? formElement.errorMessage : "";
  const ImageURL =
    _.isUndefined(formElement.imageUrl) || _.isNull(formElement.imageUrl)
      ? null
      : formElement.imageUrl;

  const fileInputRef: any = useRef(null);
  const [dialog, setDialog] = useState<IDialog>({
    open: false,
    type: "replace",
    file: null,
  });

  const [inputFiles, setInputFiles] = useState<{
    acceptedFiles: IFileUpload[];
    rejectedFiles: IFileUpload[];
  }>({ acceptedFiles: [], rejectedFiles: [] });

  const [activeFile, setActiveFile] = useState<any>(null);

  const startUpload = async (fileInfo: IFileUpload): Promise<any> => {
    const googleUtil = new GoogleUtilHelper();
    let uploadSessionURI: any = null;

    try {
      const { file, uniqueFileName, abortController } = fileInfo;
      const AccessToken: any = await googleUtil.getSAAccessToken();

      if (_.isNull(AccessToken)) {
        return {
          error: true,
          publicURL: null,
          uploadSessionURI: null,
        };
      }

      const FileExtension: any = file.name.split(".").pop();

      const BUCKET_URL: any =
        process.env.REACT_APP_PORTAL_FILE_UPLOAD_GOOGLE_BUCKET;

      uploadSessionURI = await googleUtil.getUploadSessionURI(
        BUCKET_URL,
        AccessToken,
        uniqueFileName,
        FileExtension
      );

      if (_.isNull(uploadSessionURI)) {
        return {
          error: true,
          publicURL: null,
          uploadSessionURI: null,
        };
      }

      await Utility.uploadFileToBucket(
        fileInfo.id,
        abortController,
        uploadSessionURI,
        file,
        setActiveFile
      );

      return await Utility.generatePublicURL(uniqueFileName, uploadSessionURI);
    } catch (err) {
      return {
        error: true,
        publicURL: null,
        uploadSessionURI,
      };
    }
  };

  const updateInputFiles = (isLoading: boolean, data: any): void => {
    setInputFiles((prevState) => {
      const UpdatedData = {
        ...prevState,
        ...data,
      };
      ///Handle change
      Utility.handleOnChange(props, UpdatedData, isLoading);
      return UpdatedData;
    });
  };

  const handleFileInputChange = async (event: any): Promise<void> => {
    const Files = Array.from(event.target.files);

    if (_.isEqual(Files.length, 0)) {
      return;
    }
    setActiveFile(null);

    const { acceptedFiles, rejectedFiles } = Utility.getFilesData(
      Files,
      formElement
    );

    const isValidForUpload = !_.isEqual(acceptedFiles.length, 0);

    updateInputFiles(isValidForUpload, {
      acceptedFiles,
      rejectedFiles,
    });
    if (isValidForUpload) {
      const DATA_FILES = await getValidUploadFiles(acceptedFiles);
      updateInputFiles(false, {
        acceptedFiles: DATA_FILES,
      });
    }
  };

  const getValidUploadFiles = async (acceptedFiles: any): Promise<any> => {
    const DATA_FILES = acceptedFiles.map((file: IFileUpload) => ({
      ...file,
    }));
    for (let iFile = 0; iFile < DATA_FILES.length; iFile++) {
      const File: IFileUpload = DATA_FILES[iFile];
      if (File.isValidForUpload) {
        const UploadResult: IFileUploadResponse = await startUpload(File);

        DATA_FILES[iFile] = {
          ...DATA_FILES[iFile],
          publicURL: !_.isNull(UploadResult) ? UploadResult.publicURL : null,
          status: UploadResult.error ? "error" : "success",
          uploadSessionURI: !_.isNull(UploadResult)
            ? UploadResult.uploadSessionURI
            : null,
          progress: 100,
        };
        setActiveFile(null);
      }
    }
    return DATA_FILES;
  };

  const onOpenDialog = (
    file: IFileUpload | null = null,
    type: IDialogType = "replace"
  ): void => {
    setDialog({
      open: true,
      type,
      file,
    });
  };

  const onReplaceFiles = (): void => {
    setDialog({
      open: false,
      type: "replace",
      file: null,
    });
    fileInputRef.current.click();
  };

  const onDeleteFile = (): void => {
    if (
      _.isNull(dialog?.file) &&
      _.isEmpty(inputFiles.acceptedFiles) &&
      _.isEmpty(inputFiles.rejectedFiles)
    ) {
      updateInputFiles(false, {
        acceptedFiles: [],
      });
      setDialog({
        open: false,
        type: "replace",
        file: null,
      });
      return;
    }

    const FilesData = [].filter(
      (file: IFileUpload) => !_.isEqual(file.id, dialog?.file?.id)
    );

    updateInputFiles(false, {
      acceptedFiles: FilesData,
    });

    const googleUtil = new GoogleUtilHelper();
    const UniqueFileName: any = dialog?.file?.uniqueFileName;
    const BUCKET_NAME: any =
      process.env.REACT_APP_PORTAL_FILE_UPLOAD_GOOGLE_BUCKET;
    googleUtil.deleteFileFromBucket(UniqueFileName, BUCKET_NAME);

    setDialog({
      open: false,
      type: "replace",
      file: null,
    });
  };

  const onCancel = (): void => {
    const UploadSessionURI = _.isNull(activeFile)
      ? null
      : activeFile?.uploadSessionURI;

    if (!_.isNull(UploadSessionURI)) {
      dialog?.file?.abortController?.abort();

      setActiveFile(null);
    }
    setDialog({
      open: false,
      type: "replace",
      file: null,
    });
  };

  const uploadSingleFile = async (
    file: IFileUpload,
    bytesRange: any = null,
    contentLength = 0,
    isNewUpload = true
  ): Promise<void> => {
    const FilesData = inputFiles.acceptedFiles.map((data: any) => ({
      ...data,
    }));

    const FileData: IFileUpload = FilesData.find((fileData: IFileUpload) =>
      _.isEqual(fileData.id, file.id)
    );
    if (!_.isUndefined(FileData)) {
      const abortController = new AbortController();
      FileData.abortController = abortController;

      updateInputFiles(true, {
        acceptedFiles: FilesData,
      });

      if (isNewUpload) {
        const Result = await startUpload(FileData);
        updateFile(Result, FileData);
        setActiveFile(null);
        return;
      }
      const UploadResult = await Utility.resumeFileUpload(
        FileData,
        bytesRange,
        contentLength,
        setActiveFile
      );

      updateFile(UploadResult, FileData);
      setActiveFile(null);
    }
  };

  const updateFile = (uploadResult: any, file: any): void => {
    const FileData = inputFiles.acceptedFiles.map((data: any) => ({ ...data }));
    const FileDataIndex = FileData.findIndex((fileData: IFileUpload) =>
      _.isEqual(fileData.id, file.id)
    );

    FileData[FileDataIndex] = {
      ...FileData[FileDataIndex],
      publicURL: !_.isNull(uploadResult) ? uploadResult.publicURL : null,
      status: uploadResult.error ? "error" : "success",
      uploadSessionURI: !_.isNull(uploadResult)
        ? uploadResult.uploadSessionURI
        : null,
      progress: 100,
    };
    updateInputFiles(false, {
      acceptedFiles: FileData,
    });
  };

  const onRetry = async (file: IFileUpload): Promise<void> => {
    const googleUtil = new GoogleUtilHelper();
    if (!_.isNull(file.uploadSessionURI)) {
      const fileUploadResult = await googleUtil.isFileUploadSuccessful(
        file?.uploadSessionURI,
        file.size
      );

      if (fileUploadResult.error) {
        uploadSingleFile(file);
        return;
      }

      if (_.isEqual(fileUploadResult.status, 200)) {
        const UploadResult = await Utility.generatePublicURL(
          file.uniqueFileName,
          file.uploadSessionURI
        );
        updateFile(UploadResult, file);
        return;
      }

      if (_.isEqual(fileUploadResult.status, 308)) {
        const { contentLength, range } = fileUploadResult;

        uploadSingleFile(file, range, contentLength, false);
        return;
      }
    }
    uploadSingleFile(file);
  };

  const UploadLoader = !_.isNull(activeFile);

  const ACCEPTED_FILES = inputFiles.acceptedFiles;

  const REJECTED_FILES = inputFiles.rejectedFiles;

  const RenderAcceptedFiles = JSXHelper.renderAcceptedFiles(
    ACCEPTED_FILES,
    activeFile,
    onOpenDialog,
    onRetry,
    UploadLoader
  );

  const onDeleteErrorFile = (file: IFileUpload): void => {
    const FilesData = inputFiles.rejectedFiles.map((data: any) => ({
      ...data,
    }));
    const UpdatedFiles = FilesData.filter(
      (fileUpload: IFileUpload) => !_.isEqual(fileUpload.id, file.id)
    );

    updateInputFiles(UploadLoader, {
      rejectedFiles: UpdatedFiles,
    });
  };

  const RenderRejectedFiles = JSXHelper.renderRejectedFiles(
    REJECTED_FILES,
    onDeleteErrorFile
  );

  const ImageThumbNail = !_.isNull(ImageURL) ? (
    <>
      {_.isEmpty(ACCEPTED_FILES) && (
        <div className={classes.imageUrlContainer}>
          <div
            className={classes.imageUrlDelete}
            onClick={() => onOpenDialog(null, "delete")}
          >
            Delete
          </div>
        </div>
      )}
      <img
        className={classes.imagePreview}
        src={ImageURL}
        alt={formElement.configuration.title}
      />
    </>
  ) : (
    <></>
  );

  return (
    <Grid container item lg={12} md={12} sm={12} xs={12}>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <UILabel formElement={formElement} />
        </Grid>
        <Grid container alignItems="center" item lg={9} md={9} sm={12} xs={12}>
          <input
            type="file"
            onChange={handleFileInputChange}
            onClick={() => (fileInputRef.current.value = null)}
            ref={fileInputRef}
            style={{ display: "none" }}
            multiple={false}
            accept={formElement.configuration.file?.allowedFileTypes}
          />
          <UIOutlineButton
            isOutline={true}
            onClick={(): void => {
              if (_.isEmpty(ACCEPTED_FILES) && _.isNull(ImageURL)) {
                fileInputRef.current.click();
                return;
              }
              onOpenDialog(null, "replace");
            }}
            disabled={UploadLoader}
            cssStyle={{
              textTransform: "none",
              fontWeight: 600,
            }}
            btnText="Upload File"
          />
        </Grid>
        <Grid container direction="column" item lg={5} md={6} sm={12} xs={12}>
          {UploadLoader && (
            <div className={parentClasses.linearProgress}>
              <LinearProgress
                variant="determinate"
                value={_.isNull(activeFile) ? 0 : activeFile.progress}
              />
            </div>
          )}
          <div
            className={classnames({
              [parentClasses.fileContainerMargin]:
                !UploadLoader && !_.isEqual(ACCEPTED_FILES.length, 0),
            })}
          ></div>
          {RenderAcceptedFiles}
          {RenderRejectedFiles}

          <UIFileError note={""} errorMessage={errorMessage} />
          {ImageThumbNail}
        </Grid>
      </Grid>

      {dialog.open && (
        <UIBucketUploadAlert
          fileTypeName="image"
          onClose={() =>
            setDialog({
              open: false,
              type: "replace",
              file: null,
            })
          }
          onSubmit={(): void => {
            if (_.isEqual(dialog.type, "delete")) {
              onDeleteFile();
              return;
            }
            if (_.isEqual(dialog.type, "replace")) {
              onReplaceFiles();
              return;
            }
            onCancel();
          }}
          files={ACCEPTED_FILES}
          type={dialog.type}
        />
      )}
    </Grid>
  );
};
