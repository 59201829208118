import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import style from "./style";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  heading: string;
  description: string;
};

// AccessComponent component
export const AccessComponent = (props: props): JSX.Element => {
  const { heading, description } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.subContainer}>
        <div className={classes.heading}>{heading}</div>
        <div
          className={classes.description}
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></div>
      </div>
    </div>
  );
};
