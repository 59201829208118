import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import classnames from "classnames";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
    onClick?: any;
    btnText: string;
    disabled?: boolean;
    cssStyle?: any;
    isSelected?: any;
    
};


export const UIButton = (props: props): JSX.Element => {
    const classes = useStyles();
    const { onClick, btnText, cssStyle, disabled, isSelected} = props;
    
  
    return (
    
        <Button
            color="primary"
            onClick={onClick}
            style={cssStyle}
            className={classnames(
                { [classes.isSelected]: isSelected },
                {
                    [classes.disabled]: disabled,
                },
                classes.button,

            )}
            disabled={disabled}
        >
            {btnText}
        </Button>
    );
};


