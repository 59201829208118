import React, { useState } from "react";

import { ITool } from "../../interface/ITool";
import { ISignout } from "../../interface/ISignout";
import { StepperForm } from "./Form";
import { StepperHeader } from "./Stepper";
import { ToolHeader } from "../../../../../components/Toolkit/Tool/Type/Layout";
import { Container } from "@material-ui/core";

type props = {
  tool: ITool;
  onChange: any;
  onSubmit: any;
  onValidateForms: any;
  handleNewForm: any;
  backBtnTitle: string;
  handleBackButton: any;
  signoutConfig: ISignout;
  loading: boolean;
  ishavingParent:any;
};



export const MultiStepperForm = (props: props): JSX.Element => {
  const {
    tool,
    onChange,
    onSubmit,
    onValidateForms,
    handleNewForm,
    backBtnTitle,
    handleBackButton,
    loading,
    signoutConfig,
    ishavingParent
  } = props;
  const [activeStep, setActiveStep] = useState(0);

  const handleNextBack = (step: number): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + step);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const goToStep = (step: number): void => {
    setActiveStep(() => step);
  };

  const handleReset = (): void => {
    setActiveStep(0);
  };
  const ToolInfo = {
    data: { ...tool },
  };

  return (
    <div>
      <ToolHeader
        backBtnTitle={backBtnTitle}
        handleBackButton={handleBackButton}
        signoutConfig={signoutConfig}
        {...ToolInfo}
        stepper={<StepperHeader forms={tool.forms} activeStep={activeStep} />}
      />
      <Container maxWidth="lg">
        <StepperForm
          tool={tool}
          onChange={onChange}
          goToStep={goToStep}
          onValidateForms={onValidateForms}
          onSubmit={onSubmit}
          handleNewForm={handleNewForm}
          handleNextBack={handleNextBack}
          handleReset={handleReset}
          activeStep={activeStep}
          handleBackButton={handleBackButton}
          loading={loading}
          ishavingParent={ishavingParent}
        />
      </Container>
    </div>
  );
};
