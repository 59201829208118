import React, { useState } from "react";
import { TablePagination, IconButton, Grid } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import _ from "lodash";
import classNames from "classnames";
import GridHeader from "./GridHeader";
import style from "./style";
import StatusIndicator from "../../../../../../../UI/StatusIndicator";
import { IconMore } from "../../DetailMenu/More";
import UITooltip from "../../../../../../../UI/Tooltip";
import { useSelector } from "react-redux";
import { MiddleTextEllipsis } from "../../Layout/Elements/Text/MiddleEllipsis";
import GridDownload from "../GridDownload";
import GridSchedule from "../GridSchedule";
import GridExecuteNow from "../GridExecuteNow";



const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
    data: any;
    handleChangePage: any;
    filters: any;
    createSortHandler: any;
    loading: boolean;
    isSchedule: number;
    handleExecuteChange: any;
    onSchedule: any
    onDetailClick: any;
    handleDownloadChange: any;
    onClone: any;
    onEdit: any;
    UpdatedGrid: any,
    expiryDateCheck: any
    toolData: any
};

interface IRowData {
    job: string;
    state: any;
    lastExecutionDate: any;
    nextExecutionDate: any;
    jobId: any;
    jobStatus: any;
    lastExecutionTime: any;
    nextExecutionTime: any;
    failureReason: any;
    reportDetails: any;
    toolData: any;
}

export default function GridTable(props: props): React.JSX.Element {
    const classes = useStyles();
    const {
        toolData,
        handleChangePage,
        filters,
        createSortHandler,
        loading,
        isSchedule,
        handleExecuteChange,
        onSchedule,
        onDetailClick,
        handleDownloadChange,
        onClone,
        onEdit,
        UpdatedGrid,
        expiryDateCheck
    } = props;

    const [showActionId, setShowActionId] = useState(-1);
    const BUNDLE = useSelector((state: any) => state.initial.bundle);

    if (_.isEmpty(UpdatedGrid)) {
        return (
            <div className={classes.nojobMessage}>
                <div className={classes.nojobMessageContainer}>
                    <div style={{ paddingTop: "45px" }}>No job execution available for selected criteria</div>
                </div>
            </div>
        );
    }
    const allValue = toolData.persist_button.find((element: any) => {
        return _.isEqual(element, "All");
    });
    return (
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.tableContainer}>
            <div className={classes.tableWrapper}>
                <Table className={classes.table}>
                    <GridHeader
                        order={filters.order}
                        orderBy={filters.orderBy}
                        createSortHandler={createSortHandler}
                        isSchedule={isSchedule}
                    />
                    <TableBody>
                        {!_.isUndefined(UpdatedGrid) &&
                            !loading &&
                            UpdatedGrid.slice(
                                filters.page_number * filters.page_size
                                , ((filters.page_number + 1) * filters.page_size)
                            ).map((row: IRowData, index: number) => (//NOSONAR
                                <TableRow key={index} className={classes.tableRow}
                                    onMouseEnter={() => {//NOSONAR
                                        setShowActionId(index);
                                    }}
                                    onMouseLeave={() => setShowActionId(-1)}
                                >
                                    <TableCell align="left" className={classNames(classes.tableColumn, classes.jobColumn)}>
                                        <div style={{ width: "450px" }}>
                                            <MiddleTextEllipsis title={row.job} showTooltip={true} />
                                        </div>
                                        <span className={classes.childCellContent}>
                                            {row.jobId}
                                        </span>
                                    </TableCell>

                                    <TableCell align="left" className={classNames(classes.tableColumn, classes.lastExecutionColumn)}>
                                        <span className={classes.tableCellContent}>
                                            {_.isNull(row.lastExecutionDate) ?//NOSONAR
                                                <span></span> :
                                                (<span>{row.lastExecutionDate}</span>)
                                            }
                                        </span>
                                        <span className={classNames(
                                            classes.tableCellContent,
                                            classes.executedTime
                                        )}>
                                            {row.lastExecutionTime}
                                        </span>
                                    </TableCell>


                                    <TableCell align="left" className={classNames(classes.tableColumn, classes.jobStatusColumn)}>
                                        {_.isEqual(_.toLower(row.jobStatus), _.toLower("Failed")) ? //NOSONAR
                                        (<UITooltip title={{ "Failure Reason": row.failureReason }} isMulti={true} copy={true} >
                                            <span className={classNames(classes.tableCellContent)}>
                                                <StatusIndicator status={row.jobStatus} />
                                            </span>
                                        </UITooltip>) : (<span className={classNames(classes.tableCellContent, classes.jobStatus)}>
                                            <StatusIndicator status={row.jobStatus} />
                                        </span>)}
                                    </TableCell>

                                    {(_.isEqual(isSchedule, 1)) && (<TableCell align="left" className={classes.tableColumn}>
                                        <span className={classes.tableCellContent}>
                                            {_.isNull(row.nextExecutionDate) ?//NOSONAR
                                                <StatusIndicator status={row.state} /> ://NOSONAR
                                                (<>
                                                    <span>{row.nextExecutionDate}</span>
                                                    <span className={classNames(
                                                        classes.tableCellContent,
                                                        classes.executedTime
                                                    )}>
                                                        {row.nextExecutionTime}
                                                    </span>
                                                </>
                                                )
                                            }
                                        </span>
                                    </TableCell>)}

                                    {!_.isEmpty(toolData.persist_button) &&//NOSONAR
                                        <TableCell className={classes.tableColumn} >
                                            <div className={classes.iconContainer}>
                                                <div className={classes.iconDiv}>
                                                    {(_.isEqual(isSchedule, 1)) &&//NOSONAR
                                                     (<div>
                                                        {_.isEqual(index, showActionId) ? //NOSONAR
                                                          <GridSchedule onSchedule={onSchedule} row={row} title={BUNDLE.SCHEDULE_BTN_TITLE} /> : <IconButton size={"small"} className={classes.iconBtn}> </IconButton>}
                                                    </div>)}
                                                    {
                                                        (_.isEqual(allValue, "All")) ?//NOSONAR
                                                            <>
                                                                <div className={classes.iconSpace}> 
                                                                    {_.isEqual(index, showActionId) ? //NOSONAR
                                                                    <GridExecuteNow handleExecuteChange={handleExecuteChange} tooltipText={BUNDLE.EXECUTE_TOOLTIP} row={row} /> : <IconButton size={"small"} className={classes.iconBtn}> </IconButton>}
                                                                </div>
                                                                <div className={classes.iconSpace}> 
                                                                    {_.isEqual(index, showActionId) ? //NOSONAR
                                                                     <GridDownload row={row} handleDownloadChange={handleDownloadChange} expiryDateCheck={expiryDateCheck} /> : <IconButton size={"small"} className={classes.iconBtn}> </IconButton>}
                                                                </div>
                                                            </>
                                                            :
                                                            toolData.persist_button.map((value: string) => ( //NOSONAR
                                                                <>
                                                                    {(_.isEqual(value, "Execute Now")) && //NOSONAR
                                                                        (<div className={classes.iconSpace}>
                                                                            {_.isEqual(index, showActionId) ? <GridExecuteNow handleExecuteChange={handleExecuteChange} tooltipText={BUNDLE.EXECUTE_TOOLTIP} row={row} /> : <IconButton size={"small"} className={classes.iconBtn}> </IconButton>}
                                                                        </div>)
                                                                    }
                                                                    {(_.isEqual(value, "Download")) && //NOSONAR
                                                                        (<div className={classes.iconSpace}>
                                                                            {_.isEqual(index, showActionId) ? <GridDownload row={row} handleDownloadChange={handleDownloadChange} expiryDateCheck={expiryDateCheck} /> : <IconButton size={"small"} className={classes.iconBtn}> </IconButton>}
                                                                        </div>)
                                                                    }
                                                                </>))
                                                    }

                                                </div>
                                                <div className={classes.iconSpace}>
                                                    <IconMore onDetailClick={onDetailClick} allValue={allValue} onClone={onClone} onEdit={onEdit} jobId={row.jobId} jobName={row.job} persistButton={toolData.persist_button} onClick={() => { ""; }} title={BUNDLE.MORE_OPTIONS} />
                                                </div>
                                            </div>
                                        </TableCell>
                                    }
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>

            </div>
            <TablePagination
                component="div"
                count={UpdatedGrid.length}
                labelRowsPerPage=""
                rowsPerPage={filters.page_size}
                page={filters.page_number}
                onPageChange={(offset, page) => handleChangePage(offset, page)}
            />
        </Grid>
    );
}

