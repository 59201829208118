import * as _ from "lodash";
import { Axios } from "../../../../api/service/Axios";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IFormElementUpdate } from "../../Interface/FormElement/IFormElementUpdate";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { IOption } from "../../Interface/IOption";
import { UtilHelper } from "../Utility";

// Form element helper class
export class FormElementHelper {

    private utilHelper: UtilHelper;
    constructor(){
        this.utilHelper = new UtilHelper();
    }

    /**
     * Find dependents elements based on parent element
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return any.
     */
    findDependentElements = (forms: IForm[], element: IFormElementUpdate): any => {

        let updatedForms: any = forms.map((form: IForm) => ({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup) => ({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement) => {
                    let isVisible = formElement.visible;

                    if (!_.isEqual(formElement.configuration.dependsOn.indexOf(element.formElement.id), -1)) {
                        let elementValue = this.getElementValue({
                            ...element.formElement,
                            value: element.value
                        });
                        elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase();
                        const Dependentvalues = _.toLower(formElement.configuration.dependentValue || "").toString().trim();
                        if (_.isEqual(elementValue.length, 0)) {
                            isVisible = false;
                        } else {
                            isVisible = !_.isEqual(Dependentvalues.indexOf(elementValue), -1);
                        }
                    }

                    return {
                        ...formElement,
                        visible: isVisible
                    };
                })
            }))
        }));

        updatedForms = this.findDependentParentElements(updatedForms);

        return updatedForms;
    }

    /**
     * Disabled elements based on parent element value
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return any.
     */
    findDisabledElements = (forms: IForm[], element: IFormElementUpdate): any => {

        return forms.map((form: IForm) => ({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup) => ({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement) => {
                    let isDisabled = formElement.disabled;
                    let value = formElement.value;
                    let isValid = formElement.isValid;

                    if (!_.isEqual(formElement.configuration.disabledBasedOn.indexOf(element.formElement.id), -1)) {
                        const ElementValue = this.getElementValue({
                            ...element.formElement,
                            value: element.value
                        });

                        const DisabledValue = formElement.configuration.disabledBasedOnValue;

                        isDisabled = _.isEqual(DisabledValue, ElementValue);
                        value = isDisabled ? null : value;
                        isValid =  isDisabled ? true : false;
                    }

                    return {
                        ...formElement,
                        disabled: isDisabled,
                        value,
                        isValid
                    };
                })
            }))
        }));
    }
    /**
    * Get element value
    *
    * @param {IFormElement} element.
    * @return boolean.
    */
    getElementValue = (element: IFormElement): any => {
        if (this.isEmptyElementValue(element, element.value)) {
            return null;
        }
        let elementValue = element.value;

        if (_.isEqual(element.type, FORM_ELEMENT_TYPES.SELECT_INPUT)) {
            if (element.configuration.isMulti) {
                elementValue = element.value.map((ele: IOption) => (ele.value));
            } else {
                // Single Select
                elementValue = element.value.value;
            }
        }
        return elementValue;
    }

    /**
     * Find dependents elements based on parent element
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return any.
     */
    findDependentParentElements = (forms: IForm[]): any => {

        const Forms = forms;

        for (let iForm = 0; iForm < Forms.length; iForm++) {
            const Groups = Forms[iForm].groups;
            for (let iFormGroup = 0; iFormGroup < Groups.length; iFormGroup++) {
                const Elements = Groups[iFormGroup].elements;
                for (let iFormElement = 0; iFormElement < Elements.length; iFormElement++) {
                    const formElement = Elements[iFormElement];
                    let isVisible = formElement.visible;

                    const DependsOn: string[] = formElement.configuration.dependsOn;
                   
                    if (!_.isEmpty(DependsOn)) {
                        DependsOn.forEach((elementId: string) => {
                            const FormElement = this.findElementbyId(forms, elementId);
        
                            if (FormElement.visible) {
                                let elementValue = this.getElementValue({
                                    ...FormElement,
                                    value: FormElement.value
                                });

                                elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase();
                            
                                const Dependentvalues = _.toLower(formElement.configuration.dependentValue || "").toString().trim();
                                
                                if (_.isEqual(elementValue.length, 0)) {
                                    isVisible = false;
                                } else {
                                    isVisible = !_.isEqual(Dependentvalues.indexOf(elementValue), -1);
                                }
                            }else {
                                isVisible = FormElement.visible;
                            }
                        });

                    }
                    Elements[iFormElement] = {
                        ...Elements[iFormElement],
                        visible: isVisible
                    };
                }
            }
        }

        return Forms;
    }

    /**
 * Find disabled elements based on parent element
 *
 * @param {IForm[]} forms.
 * @param {IElementUpdate} element.
 * @return any.
 */
    findDisabledChildElements = (forms: IForm[]): any => {

        const Forms = forms;

        for (let iForm = 0; iForm < Forms.length; iForm++) {
            const Groups = Forms[iForm].groups;
            for (let iFormGroup = 0; iFormGroup < Groups.length; iFormGroup++) {
                const Elements = Groups[iFormGroup].elements;
                for (let iFormElement = 0; iFormElement < Elements.length; iFormElement++) {
                    const formElement = Elements[iFormElement];

                    let isDisabled = formElement.disabled;
                    let value = formElement.value;
                    let isValid = formElement.isValid;
                    const disabledBasedOn: string[] = formElement.configuration.disabledBasedOn;

                    if (!_.isEmpty(disabledBasedOn)) {
                        disabledBasedOn.forEach((elementId: string) => {
                            const FormElement = this.findElementbyId(forms, elementId);

                            const ElementValue = this.getElementValue({
                                ...FormElement,
                                value: FormElement.value
                            });

                            const DisabledValue = formElement.configuration.disabledBasedOnValue;
                       
                            isDisabled = _.isEqual(DisabledValue, ElementValue);
                            value = isDisabled ? null : value;
                            isValid = isDisabled ? true : ElementValue;
                        });
                    }
                    Elements[iFormElement] = {
                        ...Elements[iFormElement],
                        value,
                        disabled: isDisabled,
                        isValid
                    };
                }
            }
        }

        return Forms;
    }

    /**
* Find an element by element id
*
* @param {IForm[]} forms.
* @param {number} elementId.
* @return {IFormElement} formElement.
*/
    findElementbyId = (forms: IForm[], elementId: string): any => {
        for (const form of forms) {
            for (const formGroup of form.groups) {
                for (const formElement of formGroup.elements) {
                    if (_.isEqual(formElement.id, elementId)) {
                        return formElement;
                    }
                }
            }
        }
    }

    /**
* Check if the element value is empty or not
*
* @param {IFormElement} element.
* @param {any} elementValue.
* @return boolean.
*/
    isEmptyElementValue = (element: IFormElement, elementValue: any): any => {
        let isEmpty = true;
        switch (element.type) {
            // Text box
            case FORM_ELEMENT_TYPES.TEXT_INPUT:
            // Checkbox
            /* falls through*/

            // Textarea
            /* falls through*/
            case FORM_ELEMENT_TYPES.CHECKBOX_INPUT:
            // HTML Editor
            /* falls through*/

            // Radio 
            /* falls through*/
            case FORM_ELEMENT_TYPES.RADIO_INPUT:
                isEmpty = _.isEqual((elementValue || "").toString().trim().length, 0);
                break;
            // Multiselect
            case FORM_ELEMENT_TYPES.SELECT_INPUT:
                if (element.configuration.isMulti) {
                    isEmpty = _.isNull(elementValue) || _.isEqual((elementValue || []).length, 0);
                } else {
                    isEmpty = _.isNull(elementValue);
                }
                break;
            //File input
            case FORM_ELEMENT_TYPES.SWITCH:
                isEmpty = _.isNull(elementValue) || _.isUndefined(elementValue);
                break;
        }
        return isEmpty;
    }

    /**
     * User Profile Helper
     *
     * @param {IFormElement} userData.
     * @return {string} errorMessages.
     */
	getUserInfo(userData:any): any {
        const UserInfo = userData.userInfo;

        let agencyName = null;
        let subAgencyName = null;
        if(!_.isNull(UserInfo.agencyName)){
            agencyName = `${UserInfo.agencyName} (Agency)`;
        }
        if(!_.isNull(UserInfo.subAgencyName)){
            subAgencyName = `${UserInfo.subAgencyName} (Sub-agency)`;
        }
		return {
            agencyName,
            subAgencyName
        };
	}

     /**
   * Create parents value string for Get request
   *
   * @param {IForm[]} forms.
   * @param {number[]} parentElements.
   * @return string.
   */
  getParentValuesForGETRequest = (
    form: IForm,
    parentElements: string[]
  ): string => {
    const ParentValues: any = [];

    form.groups.forEach((formGroup: IFormGroup) => {
      formGroup.elements.forEach((formElement: IFormElement) => {
        if (!_.isEqual(parentElements.indexOf(formElement.id), -1)) {
          const Key = formElement.configuration.apiKey || formElement.id;

          if (
            formElement.configuration.isMulti &&
            _.isEqual(formElement.type, FORM_ELEMENT_TYPES.SELECT_INPUT)
          ) {
            const Values = this.utilHelper.getElementValue(formElement);

            for (const value of Values) {
              ParentValues.push(`${Key}=${encodeURIComponent(value)}`);
            }
          } else {
            ParentValues.push(
              `${Key}=${encodeURIComponent(
                this.utilHelper.getElementValue(formElement)
              )}`
            );
          }
        }
      });
    });

    return ParentValues.join("&");
  };
    /**
   * Generate API data based on request methods
   *
   * @param {IForm[]} forms.
   * @param {number[]} parentElements.
   * @return any.
   */
  getAPIData = async (form: IForm, formElement: IFormElement): Promise<any> => {
    const ParentElements = formElement.configuration.parentElements;

    const GETParams = this.getParentValuesForGETRequest(form, ParentElements);
    const QueryParams = _.isEqual(GETParams.length, 0) ? "" : `?${GETParams}`;
    const Data = await Axios.get(
      `${formElement.configuration.apiURL}${QueryParams}`
    );
    return Data.data;
  };

    /**
   * Find if any of the element parent value is empty
   *
   * @param {IForm[]} forms.
   * @param {id} element.
   * @return IFormElement | null.
   */
  isAnyParentEmpty = (form: IForm, parentElements: string[]): boolean => {
    let isEmpty = false;
    for (const formGroup of form.groups) {
      for (const formElement of formGroup.elements) {
        if (!_.isEqual(parentElements.indexOf(formElement.id), -1)) {
          if (
            this.utilHelper.isEmptyElementValue(formElement, formElement.value)
          ) {
            isEmpty = true;
          }
        }
      }
    }
    return isEmpty;
  };
}