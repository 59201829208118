import React from "react";
import * as _ from "lodash";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import style from "./style";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { TwoLineEllipsis } from "../Text/TwoLineEllipsis";
import { UIAccountDataPlaceholder } from "./Placeholder";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Props type
type props = {
  formElement: IFormElement;
};

// UIDisplayAccount Component
export const UIDisplayAccount = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { formElement } = props;
  let title = "";
  let subtitle = "";

  const ElementValue = formElement.value || {};

  if (!_.isUndefined(formElement.readOnlyOptions)) {
    title = ElementValue[formElement.readOnlyOptions.keys.title];
    subtitle = ElementValue[formElement.readOnlyOptions.keys.subtitle];
  }

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <TwoLineEllipsis text={title} tooltip={title} label="" />
      </div>
      <div className={classes.subtitle}>{subtitle}</div>
      {formElement.showNotification && (
        <div className={classes.notification}>
          {formElement.notification?.mappedByMessage} Any changes will overwrite
          the current mapping.
          <br />
          <br />
          <TwoLineEllipsis
            label={formElement.notification?.label}
            tooltip={formElement.notification?.value}
            text={formElement.notification?.value}
          />
          <UIAccountDataPlaceholder
            label="Agency"
            value={formElement.notification?.agencyName}
          />
          <UIAccountDataPlaceholder
            label="Market"
            value={formElement.notification?.marketName}
          />
          <UIAccountDataPlaceholder
            label="Mapped Date"
            value={formElement.notification?.mappedDate}
          />
          <span
            dangerouslySetInnerHTML={{
              __html: formElement.notification?.agencyMarketPlaceholder || "",
            }}
          ></span>
        </div>
      )}
    </div>
  );
};
