import * as _ from "lodash";
import { IGridFilter } from "../Interfaces/Filter/IGridFilter";
import { IFormElement } from "../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../Interfaces/Form/IForm";
import { IFormGroup } from "../Interfaces/Form/IFormGroup";

export const formatGridData = (data: any[], filter: IGridFilter): any[] => {
 
	return  _.orderBy(data.map((grid: any) => {
      return {
        id: grid.mappingChannelId,
        channelName: grid.channelName,
        comment: grid.comment,
        mappingManagers: grid.mappingManagers.map(
          (manager: any) => manager.managerName
        ),
      };
    }), [filter.orderBy], [filter.order]);
  };

export const buildFormData = (response: any): any =>{

	const FormData: any = {};

	FormData.channel = {
		label: response.channel.channelName,
		value: response.channel.channelId
	};

	FormData.comment = response.comment;

	const Managers = response.mappingManagers || [];

	FormData.mappingManagers = Managers.map((option: any)=>{
		return ({
			label: option.managerName,
			value: option.managerId
		});
	});

	return FormData;
};
  

export const buildSubmitFormData = (form: IForm): any =>{

	const FormData: any = {};

	form.groups.forEach((formGroup: IFormGroup)=>{

		formGroup.elements.forEach((formElement: IFormElement)=>{
			FormData[formElement.id] = formElement.value;
		});
	});

	const UpdatedFormData: any = {};

	UpdatedFormData.channelId = FormData.channel.value;

	UpdatedFormData.comment = FormData.comment;

	const Managers = FormData.mappingManagers || [];
	UpdatedFormData.mappingManagers = Managers.map((option: any)=>{
		return ({
			managerId: option.value,
			managerName: option.label
		});
	});
	return UpdatedFormData;
};