import React, { useContext, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import {
  Container, Grid, Tab, Tabs, Input,
  IconButton,
  InputAdornment
} from "@material-ui/core";
import style from "./style";
import CloseIcon from "@material-ui/icons/Clear";
import { IconSearch } from "../../../UI/Icons";
import FilterOptionContext from "../../Context/FilterOptionContext";
import FormHelper from "../../../helpers/FormHelper";
import { updateFilterState, updateSortLabel } from "../../../store/actions/Automation/FilterState";

const AntTabs = withStyles({
  root: {
    minHeight: 56,
  },
  indicator: {
    backgroundColor: "#1576d6",
    height: 4,
  },
})(Tabs);

interface IProps {
  handleTabChange: any;
  value: any;
  classes: any;
  setFilterCounter?: any;
}

// Channel tab list component
function TabListComponent(props: IProps): React.JSX.Element {
  const { handleTabChange, value , classes } = props;
  
  const REDUX_STATE = useSelector((state: any) => ({
    FILTER_STATE: state.filter,
    BUNDLE: state.initial.bundle,
    FILTER_OPTIONS: state.filterOptions.filterOptions,
    TOOL_LIST: state.toolList,
  }));

  const TOOL_LIST: any = REDUX_STATE.TOOL_LIST.toolList || [];
  const TOOL_COUNT = REDUX_STATE.TOOL_LIST.toolCount;
  const formHelper = new FormHelper();
  const filter = formHelper.getLocalStorageState();
  const [search, setSearch] = useState({
    text: filter.search || "",
    typingTimeout: 0,
  });

  const { stateFilter, updateSelectedFilter } = useContext(FilterOptionContext);
  const dispatch = useDispatch();

  //Search bar Handle Change
  const handleChange = (event: any, isClearClick: boolean): any => {
    const SearchText = isClearClick ? "" : event.target.value;
    if (search.typingTimeout) {
      clearTimeout(search.typingTimeout);
    }

    setSearch((prevState: any) => ({
      ...prevState,
      text: SearchText,
    }));

    if (
      (_.gte(SearchText.trim().length, 3) ||
        _.lt(SearchText.trim().length, 1)) &&
      !_.isEqual(filter.search.trim(), SearchText.trim())
    ) {
      const DATA = {
        channel_id: 0,
        include_beta: REDUX_STATE.FILTER_STATE.filter.include_beta,
        include_favorite: 0,
        lifecycle_id: 0,
        order_by: "asc",
        platform_id: [0],
        search: SearchText.trim(),
        toollabel_id: 0,
        tooltype_id: 0
      };

      const SELECTED_OPTION = { ...DATA };

      if (_.isEqual(event.code, "Enter")) {
        localStorage.setItem(
          "filterCategories",
          JSON.stringify(SELECTED_OPTION)
        );
        updateSelectedFilter(SearchText.trim());
      } else {
        if (isClearClick) {
          const RESET_STATE: any = { ...stateFilter, ...DATA };
          localStorage.setItem("filterCategories", JSON.stringify(RESET_STATE));
          dispatch(updateFilterState(DATA));
          updateSelectedFilter(DATA);
          dispatch(updateSortLabel(DATA.order_by));
        }
        else {
          setSearch((prevState: any) => ({
            ...prevState,
            typingTimeout: setTimeout(() => {
              localStorage.setItem(
                "filterCategories",
                JSON.stringify(SELECTED_OPTION)
              );
              dispatch(updateFilterState(DATA));
              dispatch(updateSortLabel(DATA.order_by));
              updateSelectedFilter(DATA);
            }, 1000),
          }));
        }
      }
    }
  };


  return (
    <div style={{ backgroundColor: "#FFFF" }}>
      <Container maxWidth="lg">
        <Grid container className={classes.tabsContainer} alignItems="center">
          <Grid item sm={12} md={12} lg={12} className={classes.title}>
            {REDUX_STATE.BUNDLE.TOOL_LIST_PAGE_TITLE}
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginBottom: "34px" }}>
            <span className={classes.toolBoxCountText}>
              {!REDUX_STATE.TOOL_LIST.loading && !_.isUndefined(TOOL_LIST) && `${TOOL_COUNT} `}{" "}
              {REDUX_STATE.BUNDLE.TOOL_COUNT_TEXT}  {REDUX_STATE.FILTER_STATE.sortLabel === "Recently Used" && "(Unused Tools are not included)"}
            </span>
          </Grid>
          {!_.isUndefined(REDUX_STATE.FILTER_OPTIONS.channels) &&
            !_.isEqual(REDUX_STATE.FILTER_OPTIONS.channels.length, 0) ? (
            <Grid container justifyContent="space-between">
              <Grid item>
                <AntTabs
                  value={value}
                  onChange={(event: React.ChangeEvent<unknown>, newValue: any) =>
                  handleTabChange(event, newValue)
                  }
                >

                  {REDUX_STATE.FILTER_OPTIONS.channels.map((tab: any, index: number) => {
                    return (
                      <Tab
                        key={index}
                        className={classNames(classes.tabs, classes.tabText)}
                        value={tab.value}
                        label={tab.label}
                        disabled={REDUX_STATE.TOOL_LIST.loading || !_.isEmpty(REDUX_STATE.FILTER_STATE.filter.search)}
                      />
                    );
                  })}
                </AntTabs>
              </Grid>
              <Grid item>
                <div className={classes.search}>
                  <div
                    className={classes.searchIcon}
                    style={{ opacity: REDUX_STATE.TOOL_LIST.loading ? 0.5 : 1 }}
                  >
                    <IconSearch title={""} />
                  </div>
                  <Input
                    type="input"
                    onKeyDown={(event) => {
                      if (_.isEqual(event.code, "Enter")) {
                        handleChange(event, false);
                      }
                    }}
                    title="Please enter at least 3 characters."
                    placeholder="Search by Tool Name"
                    className={classNames(classes.inputInput, classes.inputElement)}
                    inputProps={{ "aria-label": "search" }}
                    onChange={(event) => handleChange(event, false)}
                    value={search.text}
                    disabled={REDUX_STATE.TOOL_LIST.loading}
                    disableUnderline={true}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          style={{
                            visibility:
                            REDUX_STATE.TOOL_LIST.loading || _.isEqual(search.text, "")
                                ? "hidden"
                                : "visible",
                          }}
                          size={"small"}
                          onClick={(e) => {
                            return handleChange(e, true);
                          }}
                        >
                          <CloseIcon
                            fontSize="small"
                            className={classes.closeButton}
                          />
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </div>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
        </Grid>


      </Container>
    </div>
  );
}

export default (withStyles(style)(TabListComponent));
