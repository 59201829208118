

const style: any = (): any => ({
	switchContainer:{
		height: 38,
		display: "flex",
		alignItems: "center",
		paddingBottom: "0.5rem",
		marginRight: 15
	}
});

export default style;