import React from "react";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import { Container, Theme } from "@material-ui/core";
import classNames from "classnames";
import { BackButton } from "../../../../UI/Buttons/BackButton/Index";
import { useHistory } from "react-router-dom";
import { MapperHeader } from "../../Mapper/Grid/MapperHeader";
import { SIGNOUT_MAPPER } from "../../../../api/paths";
import { Axios } from "../../../../api/service/Axios";
import { UIOutlineButton } from "../../../../UI/Buttons";
import { NOTIFICATION_TYPE } from "../../configuration";
import { catchError } from "../../Utility/ErrorMessage";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Defined input props types
type props = {
  pageTitle: string;
  customStyle?: any;
  tabComponent?: any;
  backButtonLabel?: any;
  mapperHeaderData?: any;
  recordCount?: any;
  loading?: any;
  bulkEditMode?: boolean;
  exitBulkEditMode?: any;
  bulkEditBackButtonLabel?: any;
  platform?: any;
  setAlert?: any;
  errormsg?: any;
  setInitLoader?: any;
};

//Page Header Component
export const PageHeader = (props: props): React.JSX.Element => {
  const {
    pageTitle,
    tabComponent,
    customStyle,
    backButtonLabel,
    mapperHeaderData,
    recordCount,
    loading,
    bulkEditMode,
    exitBulkEditMode,
    bulkEditBackButtonLabel,
    platform,
    setAlert,
    errormsg,
    setInitLoader
  } = props;
  const classes = useStyles();
  const history = useHistory();

  const onClick = (): void => {
    history.push("/mapping/mapper");
  };

  const toolSignout=async():Promise<any> =>{
    setInitLoader(true);
    try {
      await Axios.remove(SIGNOUT_MAPPER(platform.platformId));
      onClick();
    }catch(err){
      const errorMessage = catchError(err, errormsg);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setInitLoader(false);
    }
  };
  const recordsCount = loading ? "Records" : `${recordCount} Records`;

  return (
    <div className={classes.container}>
      <Container maxWidth="lg">
        <div
          style={
            backButtonLabel
              ? {
                marginTop: "-20px",
                paddingBottom: _.isNull(mapperHeaderData) ? 20 : 0,
              }
              : {
                ...customStyle,
                paddingBottom: _.isNull(mapperHeaderData) ? 20 : 0,
              }
          }
          className={classNames(classes.header, {
            [classes.ifTab]: !_.isUndefined(tabComponent),
          })}
        >
         {backButtonLabel && !bulkEditMode && (
            <BackButton btnText={backButtonLabel} onClick={onClick} />
          )}
          {bulkEditMode && (
            <BackButton btnText={bulkEditBackButtonLabel} onClick={exitBulkEditMode} />
          )}
           <div className={classes.signoutDiv}>
             <div className={classes.signoutTitle} >{pageTitle}</div>
             {_.isEqual(platform?.platformId, 1) && backButtonLabel && (<div className={classes.signoutChildTitle}>
             <UIOutlineButton
                      disabled={false}
                      isOutline={true}
                      onClick={() => toolSignout()}
                      btnText="Sign Out"
                      isSignoutButton={true}
                      tooltip={platform?.userInfo}
                    />
             </div>  )}
            </div>
          <div className={classes.title}>{pageTitle}</div>
          {!_.isEqual(recordCount, "none") ? (
            <div className={classes.toolBoxCountText}>{recordsCount}</div>
          ) : null}

          {backButtonLabel && !_.isNull(mapperHeaderData) && (
            <MapperHeader mapperHeaderData={mapperHeaderData} />
          )}
          {tabComponent}
        </div>
      </Container>

    </div>
  );
};
