import { Theme } from "@material-ui/core";
import COLOR from "../../../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	mapped: {
		backgroundColor: "#f0fcf5",
		color: COLOR.GREEN,
	},
	"mapped*": {
		backgroundColor: "#f3f5f7",
		color: "#242c34",
	},
	inactive: {
		backgroundColor: "#fff2f2",
		color: "#b82533",
	},
	unmapped: {
		color: "#9b2b02",
		backgroundColor: "#fff5e2",
	},
	text: {
		display: "inline",
		padding: "3px 8px 2px 8px",
		fontSize: 10,
		fontWeight: theme.typography.fontWeightBold,
		textTransform: "uppercase" as const,
	},
});

export default style;