import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Container,
  Box

} from "@material-ui/core";
import { UIButton } from "../../../UI/Buttons/UIButton";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import ScrollToTop from "react-scroll-to-top";
import style from "./style";
import InfiniteScroll from "react-infinite-scroll-component";
import LandingPageLoad from "../../../UI/loadingPages/LandingPageLoad.component";
import FilterOptionCompnent from "../Filter";
import SortButton from "../../../UI/Buttons/Sort/Index";
import { ToolTile } from "./Tile";
import { IToolkey } from "../../../UI/Elements/Forms/interface/IToolKey";
import ToggleButtons from "../../../UI/ToggleButton";

type props = {
  getFilteredData: any;
};

type inputProps = {
  filterCount: number;
  setFilterCounter: any;
  handleLifeCycleChange: any;
  handleNewButtonValueChange: any;
  handleSortChange: any;
  value: any;
  handleFavoriteButtonValueChange: any;
};

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Tool Lists wrapper component
function ToolsListComponent(props: props & inputProps): React.JSX.Element {
  const { filterCount, setFilterCounter, handleLifeCycleChange, handleNewButtonValueChange, value, handleSortChange, handleFavoriteButtonValueChange } = props;
  const classes = useStyles();
  const REDUX_STATE = useSelector((state: any) => ({
    FILTER_STATE: state.filter,
    BUNDLE: state.initial.bundle,
    FILTER_OPTIONS: state.filterOptions.filterOptions,
    TOOL_LIST: state.toolList,
    IS_EXTERNAL: state.initial
  }));

  const TOOL_LIST: any = REDUX_STATE.TOOL_LIST.toolList || [];
  
  const onLoadMoreButtonClick = (): any => {
    return props.getFilteredData(false);
  };


  // Data props to Life cycle Toggle Buttons
  const toolLifeCycleData = {
    data: REDUX_STATE.FILTER_OPTIONS.toollifecycle
  };

  // Data props to Sorting Buttons
  const SortingData = {
    data: REDUX_STATE.FILTER_OPTIONS.sorting
  };


  // Fetch Data On Page Scroll
  const fetchDataOnScroll = (): any => {
    return (
      <InfiniteScroll
        dataLength={TOOL_LIST.length}
        next={() => props.getFilteredData(false)}
        hasMore={
          !REDUX_STATE.TOOL_LIST.loading &&
            _.isEqual(TOOL_LIST.length % REDUX_STATE.TOOL_LIST.loadMoreAfter, 0)
            ? false
            : REDUX_STATE.TOOL_LIST.hasMore
        }
        loader={<LandingPageLoad />}
      >
        <Grid
          container
          className={classes.toolBoxWrapper}
          spacing={3}
          direction="row"
        >
          {!REDUX_STATE.TOOL_LIST.loading &&
            !_.isUndefined(TOOL_LIST) &&
            _.isEqual(TOOL_LIST.length, 0) ? (
            <Grid
              container
              item
              sm={12}
              md={12}
              lg={12}
              justifyContent="center"
              alignItems="center"
              className={classes.noFoundContainer}
            >
              <div className={classes.noFoundMessage}>
                {REDUX_STATE.BUNDLE.NO_TOOL_FOUND_MEG}{" "}
              </div>
            </Grid>
          ) : (
            !_.isUndefined(TOOL_LIST) &&
            !_.isEmpty(TOOL_LIST) &&
            TOOL_LIST.map((Toolkeys: IToolkey, index: number) => {
              const parameters = {
                Toolkey: Toolkeys,
                index: index,
              };
              return <ToolTile key={index} {...parameters} />;
            })
          )}

          {!REDUX_STATE.TOOL_LIST.loading &&
            !_.isEmpty(TOOL_LIST) &&
            _.isEqual(TOOL_LIST.length % REDUX_STATE.TOOL_LIST.loadMoreAfter, 0) &&
            REDUX_STATE.TOOL_LIST.hasMore && (
              <Button
                className={classes.loadMoreTools}
                onClick={() => onLoadMoreButtonClick()}
              >
                {REDUX_STATE.BUNDLE.SHOW_MORE_TOOL_TEXT}
              </Button>
            )}
        </Grid>
      </InfiniteScroll>
    );
  };

  return (
    <Container maxWidth="lg">
      <ScrollToTop
        className={classes.scrollTop}
        component={<span>{REDUX_STATE.BUNDLE.SCROLL_TO_TOP_TITLE}</span>}
        top={350}
        smooth
      />

      <Grid className={classes.root}>
        <Box display="flex" justifyContent="space-between">
          <Box
            flexGrow={2}
            justifyContent="flex-start"
            style={{ maxWidth: "60%" }}
          >
            <div className={classes.lifecycle}>
              <ToggleButtons
                {...toolLifeCycleData}
                handleChange={handleLifeCycleChange}
                value={value}
                valid={true}
                exclusive={true}
                touched={true}
                disabled={
                  REDUX_STATE.TOOL_LIST.loading ||
                  !_.isEmpty(REDUX_STATE.FILTER_STATE.filter.search)
                }
                isLifeCycle={true}
              />
            </div>
          </Box>
          <Box>
            <Box display="flex">
              <UIButton
                onClick={handleNewButtonValueChange}
                btnText={REDUX_STATE.BUNDLE.NEW_BUTTON_TITLE}
                disabled={
                  REDUX_STATE.TOOL_LIST.loading ||
                  !_.isEmpty(REDUX_STATE.FILTER_STATE.filter.search)
                }
                isSelected={
                  REDUX_STATE.FILTER_STATE.filter.toollabel_id == 2
                    ? true
                    : false
                }
              />
              <UIButton
                btnText={REDUX_STATE.BUNDLE.FAVORITE_BUTTON_TITLE}
                disabled={
                  REDUX_STATE.TOOL_LIST.loading ||
                  !_.isEmpty(REDUX_STATE.FILTER_STATE.filter.search)
                }
                onClick={handleFavoriteButtonValueChange}
                isSelected={
                  REDUX_STATE.FILTER_STATE.filter.include_favorite
                    ? true
                    : false
                }
              />

              {!REDUX_STATE.IS_EXTERNAL.isExternalUser && (
                <FilterOptionCompnent
                  setFilterCounter={setFilterCounter}
                  filterCount={filterCount}
                />
              )}
              <SortButton {...SortingData} handleChange={handleSortChange} />
            </Box>
          </Box>
        </Box>
      </Grid>

      {fetchDataOnScroll()}
    </Container>
  );
}

export default (ToolsListComponent);