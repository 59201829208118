import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton } from "@material-ui/core";
import classNames from "classnames";
import style from "./style";
import * as _ from "lodash";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onClick: any;
  title?: any;
  disabled: boolean;
  isSmall?: boolean;
};

// Edit Icon functional component
export const IconEdit = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onClick, title, disabled, isSmall } = props;

  return (
    <Tooltip title={!_.isEmpty(title) ? title : ""} onClick={onClick} placement={"top"}>
      <IconButton
        aria-label={title}
        size={"small"}
        className={isSmall ? classNames(classes.editIcon) : classNames(classes.iconButton, classes.disabled)}
        disabled={disabled}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12.9 6.858l4.242 4.243L7.242 21H3v-4.243l9.9-9.9zm1.414-1.414l2.121-2.122a1 1 0 0 1 1.414 0l2.829 2.829a1 1 0 0 1 0 1.414l-2.122 2.121-4.242-4.242z" />
        </svg>
      </IconButton>
    </Tooltip>
  );
};
