import { IDropdownPlaceholder } from "../../Interfaces/Page/IDropdownPlaceholder";

export const DropdownPlaceHolder: IDropdownPlaceholder[] = [
    {
        id: "Google Ads",
        value: "Select a Manager Account"
    },
    {
        id: "Facebook Business Manager",
        value: "Select a Business Manager Account"
    },
];