import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	root: {
		flexGrow: 1,
	},
	toolHeader: {
		display: "block",
		padding: "0px 40px 24px 40px",
		minHeight: 92,
		backgroundColor: COLORS.WHITE,
		alignItems: "center",
	},
	stepperToolHeader: {
		display: "block",
		backgroundColor: COLORS.WHITE,
		alignItems: "center",
	},
	toolContentWrapper: {
		padding: "20px 28px 28px 28px",
	},
	toolName: {
		alignItems: "center",
		fontSize: 24,
		fontWeight: theme.typography.fontWeightBold,
		display: "flex",
		marginTop: -15
	},
	version: {
		marginLeft: 8,
		marginRight: 12,
		fontSize: 18,
		fontWeight: theme.typography.fontWeightMedium,
	},
	launchToolButton: {
		position: "absolute" as const,
		right: 40,
		top: 132,
	},
	toolContent: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.WHITE,
		padding: 31,
	},
	maintenance:{
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		width: "100%",
		height: "100%",
	},
	maintenanceHeading:{
		fontSize: 48,
		fontWeight: 500
	},
	maintenanceDescription:{
		fontSize: 20,
		fontWeight: 400,
		marginTop: 20
	},
	maintenanceContact:{
		fontSize: 16,
		marginTop: 16,
		color: COLORS.DARK_BLUE
	},
	toolLogo: {
		marginBottom: 16,
	},
	toolInfo: {
		display: "inline-flex",
		color: COLORS.DARK_GRAY,
		fontWeight: theme.typography.fontWeightBold,
		marginBottom: 16,
		fontSize: "1rem",
		textTransform: "capitalize"
	},
	longDescription: {
		color: COLORS.GRAY,
		fontWeight: "normal" as const,
		fontSize: "0.875rem",
		lineHeight: 1.5,
		textAlign: "initial" as const,
		width: 556
	},
	buttonContainer: {
		justifyContent: "flex-start",
		marginTop: 20,
	},
	iframContainer: {
		textAlign: "center" as const,
	},
	iframe: {
		border: 0,
		height: "766px",
		width: "100%",
	},
	infoWrapper: {
		padding: 12,
	},
	contentBoxInfoWrapper: {
		width: "100%",
	},
	moreInfoLink: {
		color: COLORS.DARK_BLUE,
		fontSize: "0.875rem",
		fontWeight: theme.typography.fontWeightMedium,
		cursor: "pointer",
		marginTop: 16,
	},
	infoLink: {
		"&:hover": {
			textDecoration: "underline",
		},
	},
	createdBy: {
		color: COLORS.GRAY,
		fontSize: 12,
		marginTop: 30,
	},
	container: {
		minHeight: 220,
		backgroundColor: "#ffffff",
		paddingLeft: 40,
		position: "relative"
	},
	thumbnailImage: {
		width: "100%",
		minWidth: "100%",
		boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);"
	},
	toolsInfo: {
		color: COLORS.GRAY,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: 10,
		marginTop: "8px",
		alignSelf: "end",
		display: "-webkit-box",
		textTransform: "uppercase" as const,
		overflow: "hidden",
		textOverflow: "ellipsis",
		"-webkit-line-clamp": 1,
		"-webkit-box-orient": "vertical",
	},
	toolDescr: {
		marginTop: "18.6px",
		fontSize: "12px",
		color: COLORS.GRAY,
		lineHeight: 1.5,
		display: "-webkit-box",
		wordBreak: "break-all",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	adHocStyle: {
		paddingLeft: "12px"
	},

	backButtonDiv: {
		paddingTop: "12.5px",
		paddingBottom: "16px"
	},
	signoutButtonDiv:{
		position:"absolute",
		right:"40px",
		bottom: "24px"
	},
	backButton: {
		width: "54px",
		height: "18px",
		margin: "0 0 8px",
		fontSize: "12px",
		fontWeight: 500,
		fontStretch: "normal",
		fontStyle: "normal",
		lineHeight: 1.5,
		letterSpacing: "normal",
		color: "#005fbe",
	},

});

export default style;