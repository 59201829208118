import * as _ from "lodash";
import { Axios } from "./../../../../api/service/Axios";
import { IOption } from "./../../../../UI/Elements/Forms/interface/IOption";
import { ALL_OPTION_OBJ, ELEMENT_TYPES } from "../../configuration";
import { IFormElement } from "./../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "./../../Interfaces/Form/IForm";
import { IFormGroup } from "./../../Interfaces/Form/IFormGroup";


export class NewFormHelper {
	public async buildNewForm(form_data: IForm, dataSources: any): Promise<IForm> {
		let Form_validated = {
			...form_data,
			groups: form_data.groups.map((formGroup: IFormGroup) => {
				return {
					...formGroup,
					elements: formGroup.elements.map((form_Element: IFormElement) => {
						const element_new = this.buildNewFormElement(form_Element, dataSources);
						return {
							...element_new,
							readOnly: !_.isUndefined(element_new.configuration.readOnly) ? element_new.configuration.readOnly.newForm : element_new.readOnly,
							touched: false,
							isValid: element_new.validations.required ? element_new.isValid : true,
						};
					}),
				};
			}),
		};
		Form_validated = this.validateParentChildRelationship(Form_validated);
		return Form_validated;
	}

	public buildNewFormElement = (formElement_item: IFormElement,dataSources: any
	): IFormElement => {
		const DATA_SOURCES: any = {};
		let element: any = { ...formElement_item };
		const Default_value = formElement_item.configuration.defaultValue;

		for (const key in dataSources) {
			DATA_SOURCES[key] = dataSources[key].map((option: any) => ({ ...option }));
		}
		switch (formElement_item.type) {
			case ELEMENT_TYPES.SWITCH:
				element = {
					...formElement_item,
					value: Default_value || true,
				};
				break;
			case ELEMENT_TYPES.SELECT_INPUT: {
				const DataSource = _.isUndefined(DATA_SOURCES[formElement_item.configuration.dataSourceKey]) ? [] : DATA_SOURCES[formElement_item.configuration.dataSourceKey];

				if (element.configuration.allowAll) {
					DataSource.unshift(ALL_OPTION_OBJ);
				}
				element = {
					...formElement_item,
					value: Default_value || null,
					configuration: {
						...formElement_item.configuration,
						dataSource: DataSource,
					},
				};
				break;
			}
			
			default:
				element = {...formElement_item,value: Default_value || ""};
				break;
		}
		return {...element,};
	};

	/**
	   * Build dropdown API list
	   *
	   * @param {forms} IForm[].
	   * @param {formElement} IFormElement.
	   * @return {Element} IFormElement.
	   */
	buildAPIDropdownList = async (formElement: IFormElement)
		: Promise<IFormElement> => {
		const Element: IFormElement = { ...formElement };
		const {
			parentElements,allowAll,apiURL,} = Element.configuration;
		const is_Disabled = !_.isEqual(parentElements.length, 0);

		let dataSource: IOption[] = [];

		if (_.isEqual(parentElements.length, 0)
			&& !_.isUndefined(apiURL)) {
			try {
				const Data_info = await Axios.get(apiURL);
				dataSource = !_.isArray(Data_info.data) ? [] : Data_info.data;
				if (allowAll && !_.isEqual(dataSource.length, 0)) {
					dataSource.unshift(ALL_OPTION_OBJ);
				}

				Element.apiError = "";
			}
			catch (error: any) {
				dataSource = [];
			}
		}
		Element.configuration.dataSource = dataSource;
		if (!_.isEqual(parentElements.length, 0)) {
			return { ...Element };
		}
		return {
			...Element,disabled: is_Disabled,touched: !_.isNull(Element.value),value: Element.value,
		};
	}
	/**
* Validate parent and child relationship for date and select
*
* @param {forms} IForm[].
* @return {UpdatedForms} IForm[].
*/
	validateParentChildRelationship = (form: IForm): IForm => {
		let UpdatedForm = ({...form,
			groups: form.groups.map((group: IFormGroup) => ({
				...group,elements: group.elements.map((element: IFormElement) => ({ ...element }))
			}))
		});

		const FormsGroup = UpdatedForm.groups;
		for (let iFormGroup = 0; iFormGroup < FormsGroup.length; iFormGroup++) {
			const FormElements = FormsGroup[iFormGroup].elements;
			for (let iFormElement = 0; iFormElement < FormElements.length; iFormElement++) {
				const FormElement = FormElements[iFormElement];
				const Form_element: IFormElement = { ...FormElement };
				const ParentElements = Form_element.configuration.parentElements;

				if (!_.isEqual(ParentElements.length, 0)) {
					Form_element.disabled = true;
					Form_element.touched = false;
					Form_element.value = null;
				}

				FormElements[iFormElement] = {...FormElements[iFormElement],...Form_element };
			}
			FormsGroup[iFormGroup] = {
				...FormsGroup[iFormGroup],
				elements: FormElements
			};
		}
		UpdatedForm = {...UpdatedForm,groups: FormsGroup};
		return UpdatedForm;
	}
}
