import Colors from "../Theme/colors";

export default function style():any{

	return ({
		button:{
			padding:"0 1rem",
		},
		outline: {
			border: "1px solid #868e96",
		},
		outlineButton: {
			color: Colors.DARK_GRAY,
		},
		disabled:{
			opacity:"0.5",
			cursor:"default"
		},
		signoutButton:{
			paddingLeft:"13px"
		},
	  });
}
