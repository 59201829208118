
export default (): any => ({
	dynamicElementContainer:{
		display: "flex",
		alignItems: "center",
	},
	width100:{
		width: "100%",
	},
	sectionTitle:{
		fontSize:"1rem",
		height:24,
		fontWeight:600,
		marginBottom: "1rem"
	},
	addSectionBtn:{
		color: "#005fbe",
		fontWeight: 600,
		fontSize: "0.875rem",
		cursor:"pointer"
	},
	deleteSectionBtn:{
		marginBottom:"1.5rem",
		marginLeft: 5
	},
	placeholder:{ width: 66, height: 30 }
});