import React from "react";
import _ from "lodash";
import { IconDownload } from "../../../../../../UI/Icons/Download";


type props = {
    row: any,
    handleDownloadChange: any,
    expiryDateCheck: any
};

export default function GridDownload(props: props): React.JSX.Element {
    const { row, handleDownloadChange, expiryDateCheck } = props;
    if (_.isNull(row.reportDetails)) {

        return (
            <>
                <IconDownload smallIcon={false} disabled={true} title={"File Does Not Exist"} />
            </>
        );
    }
    if (_.isNull(row.reportDetails.bucket_name) || _.isNull(row.reportDetails.file_name) || _.isNull(row.reportDetails.expiry) || !expiryDateCheck(row.reportDetails.expiry)) {
        return (
            <>
                <IconDownload smallIcon={false} disabled={true} title={"File Does Not Exist"} />
            </>
        );
    }
    return (
        <>
            <IconDownload onClick={() => { handleDownloadChange(row.reportDetails.file_name, row.reportDetails.bucket_name, row.reportDetails.expiry); }} smallIcon={false} disabled={false} title={"Download Latest Execution Result"} />
        </>
    );
}