import React from "react";
import { Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { useHistory } from "react-router-dom";
import config from "./auth.config";

// Okta Authentication Configuration
const oktaAuth = new OktaAuth(config.oidc);

export function OktaAuthComponent(props: any): React.JSX.Element {
  const history = useHistory();

  const triggerLogin = (): any => {
    // Redirect to the /login page that has a CustomLoginComponent
    history.push("/login");
  };

  const restoreOriginalUri = async (
    _oktaAuth: any,
    originalUri: any
  ): Promise<void> => {
    history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
  };
  const customAuthHandler = async (): Promise<void> => {
    const previousAuthState = oktaAuth.authStateManager.getPreviousAuthState();
    if (!previousAuthState || !previousAuthState.isAuthenticated) {
      // App initialization stage
      triggerLogin();
    }
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      {props.children}
    </Security>
  );
}
