import * as _ from "lodash";
import {
    IForm
} from "../../../../interface/IForm";
import { IFormElement } from "../../../../interface/IFormElement";
import { IFormGroup } from "../../../../interface/IFormGroup";
import { UtilHelper } from "../../../Utils";
import { DateUtil } from "../../../Utils/Date";
import { UI_ELEMENT_TYPES } from "../../../../../config";
import { SelectElement } from "../SelectInput";
import { RadioCheckboxElement } from "../RadioCheckbox";
import { BuildFormValidationHelper } from "../Validation";
import {
    FormElementHelper
} from "../../../Utils/Element";
import { DateDaysElement } from "../DateDaysPicker";
import { ThumbnailElement } from "../Thumbnail";

// Create form 
export class BuildUpdateFormHelper{
    private utilHelper: UtilHelper;
    private dateUtil: DateUtil;
    private selectElement: SelectElement;
    private radioCheckboxElement: RadioCheckboxElement;
    private buildFormValidationHelper: BuildFormValidationHelper;
    private dateDaysHelper: DateDaysElement;
    private formElementHelper: FormElementHelper;
    private thumbnailElement: ThumbnailElement;

    constructor(){
        this.buildFormValidationHelper = new BuildFormValidationHelper();
        this.utilHelper = new UtilHelper();
        this.dateUtil = new DateUtil();
        this.selectElement = new SelectElement();
        this.formElementHelper = new FormElementHelper();
        this.radioCheckboxElement = new RadioCheckboxElement();
        this.dateDaysHelper = new DateDaysElement();    
        this.thumbnailElement = new ThumbnailElement();
    }
/**
     * Get ordered forms
     *
     * @param {IForm[]} form array.
     * @return {IForm[]} form array.
     */
    getOrderedForms = (forms: IForm[]): IForm[] =>{
        return _.orderBy(forms, ["display_order"], ["asc"])
                .map((form: IForm)=> ({
                    ...form,
                    isVisible: true,
                    groups: _.orderBy(form.groups, ["display_order"], ["asc"])
                        .map((group: IFormGroup)=> ({
                            ...group,
                            isVisible: true,
                            elements: _.orderBy(group.elements, ["display_order"], ["asc"] )
                                .map((element: IFormElement) => ({...element, apiError:"", error:""}))
                        }))
                }));
    }


    /**
     * Use to build initial form for UI.
     *
     * @param {IForm[]} form array.
     * @return {IForm[]} form array.
     */
     form = async (forms: IForm[]): Promise<any[]> => {

         try {
            const OrderedForms:IForm[] = this.getOrderedForms(forms);

             const Forms: IForm[] = [];
             for(const form of OrderedForms){
                 const FormGroups = [];
                 for(const formGroup of form.groups){
                     const Elements = [];

                     for(const formElement of formGroup.elements){
                         const ElementValue = (formElement.value || "").toString().trim();
                         let Element:IFormElement = {
                             ...formElement,
                             disabled: _.isEqual(formElement.read_only, 1),
                             error: "",
                             read_only: _.isEqual(formElement.read_only, 1) || _.isEqual(formElement.readonly_on_edit_form, 1)
                         };

                         const elementHelper = new FormElementHelper();
                         const ChildElements = elementHelper.findChildElementsForDataSet(OrderedForms, formElement.id);
                        
                         if (_.isNull(formElement.is_dataset) && _.isEmpty(ChildElements)) {
                            Element = await this.formIsDataSetNull(Element, formElement, ElementValue);
                         }
                        
                         Elements.push({
                             ...Element,
                             dynamic_fields_values: [],
                             width: _.isNull(Element.width) ? 100 : Element.width,
                             is_visible: formElement.is_visible ? _.isNull(Element.form_element_auth) : false,
                             touched: false,
                             read_only: _.isEqual(formElement.read_only, 1) || _.isEqual(formElement.readonly_on_edit_form, 1),
                             hasChildElement: this.formElementHelper.hasChildElements(forms, Element.id)
                         });
                        
                     }

                     FormGroups.push({
                         ...formGroup,
                         elements: Elements,
                     });
                 }
                 Forms.push({
                     ...form,
                     groups: FormGroups,
                 });
             }
   
             const UpdatedForms: IForm[] = await this.buildFormValidationHelper.validateParentChildRelationship(Forms);
             return await this.buildFormValidationHelper.validateBuildForm(UpdatedForms);
         }
         catch(error:any){
            console.log(error);
             return [];
         }
     }

      /**
     * if dateset (Update flag) is empty
     *
     * @param {IFormElement} element.
     * @param {IFormElement} formElement.
     * @param {IFormElement} elementValue.
     * @return {IFormElement} Element.
     */
     formIsDataSetNull = async (element: IFormElement, formElement: IFormElement, elementValue: any): Promise<IFormElement> =>{
        let Element = {...element};

            switch(formElement.type_id){
            // Text input
            case UI_ELEMENT_TYPES.TEXT_INPUT:
               Element = this.formTextInput(Element, formElement, elementValue);
                break;
            // Text input
            case UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD:
                Element = this.formImageTextInput(Element, formElement);
                break;
            case UI_ELEMENT_TYPES.CHECKBOX_INPUT:
            case UI_ELEMENT_TYPES.RADIO_INPUT:
                Element = {
                    ...Element,
                    ...this.radioCheckboxElement.buildRadioOrCheckbox(formElement)
                };
                break;
                // Switch
            case UI_ELEMENT_TYPES.SWITCH_INPUT:{
                   
                const ElValue = 
                       !_.isEqual((elementValue || "").length, 0) ? 
                           _.isEqual(elementValue.toLowerCase(),
                               "true") : false;

                Element = {
                    ...Element,
                    value: ElValue,
                    touched: false
                };
                break;
            }
            // Thumbnail list
            case UI_ELEMENT_TYPES.THUMBNAIL: {
                const UpdatedElement = await this.thumbnailElement.generateThumbnail(
                  formElement
                );

                Element = {
                  ...UpdatedElement,
                };
                break;
              }
                // Select list
            case UI_ELEMENT_TYPES.GRID:
            case UI_ELEMENT_TYPES.SELECT_INPUT:{
                const UpdatedElement = await this.selectElement.generateSelect(Element);

                Element = {
                    ...UpdatedElement,
                };
                break;
            }
                // Datepicker
            case UI_ELEMENT_TYPES.DATETIME_INPUT:{
                Element = this.formDatePicker(Element, formElement, elementValue);
                break;
            }
                // Daterange input 
            case UI_ELEMENT_TYPES.DATERANGE_INPUT:{
               Element = this.dateDaysHelper.dateRangePicker(Element, formElement);
                break;
                }
            }
        return Element;
     }

     /**
     * Use to build initial form for UI.
     *
     * @param {IForm[]} form array.
     * @return {IForm[]} form array.
     */
     formTextInput = (element: IFormElement, formElement: IFormElement, elementValue: any): IFormElement =>{
        let Element = {...element};
        if(formElement.is_number){
            const IsValidNumber = this.utilHelper.getValidNumber(elementValue, formElement);
               
            Element = {
                ...Element,
                touched: !_.isNull(IsValidNumber),
                value: IsValidNumber
            };
        }
        else{
            const ElementVal = _.isEqual((formElement.value || "").toString().trim().length, 0) 
                ? "" : formElement.value;
            Element = {
                ...Element,
                value: ElementVal,
                touched: !_.isEqual(elementValue.length, 0)
            };
        }
        return Element;
     }

       /**
   * Build image text input for new form
   *
   * @param {element} IFormElement.
   * @param {formElement} IFormElement.
   * @return {Element} IFormElement.
   */
        formImageTextInput = (
            element: IFormElement,
            formElement: IFormElement
          ): IFormElement => {
            let Element = { ...element };
    
            const ElementVal = !this.utilHelper.validateObjectProperties(formElement.value, [
                "file",
                "text",
            ])
                ? {
                    file: null,
                    text: "",
                    touched:{
                        text: false,
                        file: false
                    }
                }
                : {
                    ...formElement.value,
                    touched:{
                        text: false,
                        file: false
                    }
                };
              Element = {
                ...Element,
                value: ElementVal,
                touched: true
              };
            return Element;
          };

     /**
     * Date Picker for update form
     *
     * @param {IFormElement} element.
     * @param {IFormElement} formElement.
     * @param {IFormElement} elementValue.
     * @return {IFormElement} Element.
     */
     formDatePicker = (element: IFormElement,formElement: IFormElement, elementValue: any): IFormElement =>{
        let Element = {...element};
        let DateValue = this.dateUtil.formatDateValue(elementValue, formElement);
        let MinDate = this.dateUtil.formatDateMinMaxValue(Element.min_range, formElement);
        let MaxDate = this.dateUtil.formatDateMinMaxValue(Element.max_range, formElement);
           
        DateValue = this.dateUtil.isDateBetweenStartAndEnd(formElement, 
            DateValue, MinDate, MaxDate) ? DateValue : null;

        if(Element.is_number){
            const IsValidNumber = this.utilHelper.getValidNumber(elementValue, formElement);
            DateValue = IsValidNumber;
            MinDate = this.utilHelper.getValidNumber(Element.min_range, formElement);
            MaxDate = this.utilHelper.getValidNumber(Element.max_range, formElement);
        }

        Element = {
            ...Element,
            value: DateValue,
            min_range: MinDate,
            max_range: MaxDate,
            touched: !_.isNull(DateValue)
        };
        return Element;
     }
}
