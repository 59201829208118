import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

import * as _ from "lodash";
import style from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";
import PreviewGrid from "./Preview";
import { DataGridDialog } from "./Dialog";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  errorMessage: string;
};

// UIDataGrid Component
export const UIDataGrid = (props: props): JSX.Element => {
  const { formElement, onChange, errorMessage } = props;
  const [showDataGrid, setShowDataGrid] = useState(false);

  const classes = useStyles();

  if (_.isEqual(formElement.dynamic_fields_values.length, 0)) {
    return <span />;
  }

  return (
    <div className={classes.container}>
      {showDataGrid && (
        <DataGridDialog
          formElement={formElement}
          onCancel={() => setShowDataGrid(false)}
          onSaveChanges={(gridData: any) => {
            const UpdatedGridData = gridData.map((data: any) =>
              _.omit(data, ["isNewRow"])
            );

            onChange(UpdatedGridData);
            setShowDataGrid(false);
          }}
        />
      )}
      {!_.isEqual(formElement.dynamic_fields_values.length, 0) && (
        <PreviewGrid
          formElement={formElement}
          onEditGrid={() => setShowDataGrid(true)}
          enableEdit={true}
          errorMessage={errorMessage}
        />
      )}
    </div>
  );
};
