import React,{ useState } from "react";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import classNames from "classnames";
import { Input, IconButton, InputAdornment } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import commonStyle from "../sharedStyle";
import { IFormElement } from "../../Forms/interface/IFormElement";

// Create styles
const useCommonStyles = makeStyles(() =>
	createStyles(commonStyle())
);

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
  onValidateForms: any;
};

// UIPasswordInput Component
export const UIPasswordInput = (props: props): JSX.Element => {
	const { formElement, onChange, formId, formGroupId, onValidateForms } = props;

	const commonClasses = useCommonStyles();
	const [focus, setFocus] = useState<boolean>(false);

	const [visibility, setVisibility] = useState(false);
	const Disabled = formElement.disabled || formElement.read_only;
	const ErrorMessage = formElement.touched ? formElement.error : "";

	return (
		<div
			style={{
				width: `${formElement.width}%`,
			}}
		>
			<UILabel formElement={formElement} />
			<Input
				type={visibility ? "text" : "password"}
				disabled={Disabled}
				onFocus={() => setFocus(true)}
				onBlur={() => {
					setFocus(false);
					return onValidateForms({
						formId,
						formGroupId,
						formElement,
						value: formElement.value,
					});
				}}
				id={`${formElement.id}`}
				placeholder={formElement.placeholder}
				className={classNames(commonClasses.input, {
					[commonClasses.disabled]: Disabled,
					[commonClasses.inputElement]:
            !Disabled && _.isEqual(ErrorMessage.length, 0),
					[commonClasses.error]: !_.isEqual(ErrorMessage.length, 0),
					[commonClasses.elementFocus]:
            focus && !Disabled && _.isEqual(ErrorMessage.length, 0),
				})}
				value={formElement.value}
				onChange={(event: any) => {
					const Value = event.target.value;
					return onChange({ formId, formGroupId, formElement, value: Value });
				}}
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							className={classNames(
								commonClasses.iconButton,
								{
									[commonClasses.iconFocus]:
                    focus && !Disabled && _.isEqual(ErrorMessage.length, 0),
								},
								{ [commonClasses.disabledIcon]: Disabled }
							)}
							aria-label="toggle password visibility"
							onClick={() => setVisibility((visible: boolean) => !visible)}
							onMouseDown={() => setVisibility((visible: boolean) => !visible)}
							edge="end"
						>
							{visibility ? (
								<Visibility fontSize={"small"} />
							) : (
								<VisibilityOff fontSize={"small"} />
							)}
						</IconButton>
					</InputAdornment>
				}
			/>
			<UIErrorOrNoteMessage
				formElement={formElement}
				errorMessage={Disabled ? "" : ErrorMessage}
			/>
		</div>
	);
};

// Default Props
UIPasswordInput.defaultProps = {
	formElement: {},
};
