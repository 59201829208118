const style: any = (): any => ({
    error:{
        minHeight: "1.25rem",
        marginTop:"0.25rem",
        color: "#b82533",
        fontSize: "0.75rem",
        width:"100%",
        "word-break":"break-all",
        "font-weight":"normal"
    },
    note:{
        minHeight: "1.25rem",
        marginTop:"0.25rem",
        fontSize:"0.75rem",
        color:"#6a737c",
        "word-break":"break-all",
        "font-weight":"normal",
        width:"100%",
        "& ul, ol":{
            paddingLeft: "1.5rem",
            marginTop: "0.25rem"
        },
        "& a":{
            color:"#005fbe",
            textDecoration:"none"
        }
    },
	noteErrorMargin:{
		marginBottom:"1.5rem"
	}
});

export default style;