import Colors from "../../../../../UI/Elements/utilstyle";

const sharedStyle: any = (): any => ({
	container: {
		height: 36,
		width: "100%",
	},
	input: {
		height: 36,
		backgroundColor: Colors.BackgroundColor,
		border: `1px solid ${Colors.BorderColor}`,
		borderRadius: 2,
		width: "100%",
		fontSize: "0.875rem",
		padding: "7px 12px",
		"box-sizing": "border-box",
	},
	iconButton: {
		padding: 0,
		margin: 0,
		color: Colors.HoverColor
	},
	inputElement: {
		"&:hover": {
			border: `1px solid ${Colors.HoverColor}`,
		}
	},
	elementFocus: {
		border: `1px solid ${Colors.FocusColor} !important`,
	},
	iconFocus: {
		color: Colors.FocusColor
	},
	iconHover: {
		color: `${Colors.HoverColor} !important`,
	},
	error: {
		border: `1px solid ${Colors.ErrorColor} !important`,
		backgroundColor: Colors.ErrorBackgroundColor
	},
	disabled: {
		backgroundColor: "#FBFCFD",
		border: "1px solid rgb(208, 213, 218)",
		opacity: 0.5,
		color: "rgb(169,169,169) !important",
	},
	disabledIcon: {
		color: "rgb(134, 142, 150)",
		cursor: "default"
	},
	formGroupTitle: {
		fontSize: "1rem",
		fontWeight: 600,
		marginBottom: "1rem"
	},
	elementLabel: {
		minHeight: 21,
		color: "rgb(36, 44, 52)",
		fontSize: "0.875rem",
		"word-break": "break-all",
		"font-weight": "bold",
		display: "flex",
		alignItems: "center",
		paddingBottom: "0.5rem"
	},
});

export default sharedStyle;