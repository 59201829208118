import {
  GOOGLEADS_GRID_HEADERS,
  getGoogleAdsFormConfig_mapper,
  getGoogleAdsBulkEditFormConfig,
} from "../Configuration/GoogleAds";
import {
  META_GRID_HEADERS,
  getMetaFormConfig,
  getMetaBulkEditFormConfig,
} from "../Configuration/Meta";
import * as _ from "lodash";
import { PLATFORM_TYPE } from "../../configuration";
import { searchPlaceHolder } from "../Configuration/SearchPlaceholder";
import { DropdownPlaceHolder } from "../Configuration/DropdownPlaceholder";

export const getGridHeader = (platformName: any): any => {
  let data: any = {};
  switch (platformName) {
    case PLATFORM_TYPE.GOOGLE_ADS:
      data = GOOGLEADS_GRID_HEADERS;
      break;
    case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
      data = META_GRID_HEADERS;
      break;
    default:
      data = [];
  }
  return data;
};

export const getEditFormConfiguration = (
  platformName: any,
  bulkEditMode: any
): any => {
  let data: any = {};
  switch (platformName) {
    case PLATFORM_TYPE.GOOGLE_ADS:
      if (bulkEditMode) {
        data = getGoogleAdsBulkEditFormConfig({});
      } else {
        data = getGoogleAdsFormConfig_mapper({});
      }

      break;
    case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
      if (bulkEditMode) {
        data = getMetaBulkEditFormConfig({});
      } else {
        data = getMetaFormConfig({});
      }
      break;
    default:
      data = getGoogleAdsFormConfig_mapper({});
      break;
  }
  return data;
};

export const getChildAccountsConfig = (option: any, platformName: any): any => {
  let data = {};
  switch (platformName) {
    case PLATFORM_TYPE.GOOGLE_ADS:
      data = {
        accountId: option.account_id,
        accountName: option.account_name,
      };
      break;
    case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
      data = {
        adaccountId: option.account_id,
        adaccountName: option.account_name,
      };
      break;
    default:
      data = {};
  }
  return data;
};

//Get Search PlaceHolder on the basis of platform name.
export const getSearchPlaceHolder = (platformName: any): any => {
  const findPlaceHolder = searchPlaceHolder.find((ele: any) => {
    return _.isEqual(ele.id, platformName);
  });

  if (_.isUndefined(findPlaceHolder)) {
    return "Search";
  } else {
    return findPlaceHolder.value;
  }
};

//Get Dropdown PlaceHolder on the basis of platform name.
export const getDropdownPlaceHolder = (platformName: any): any => {
  const findPlaceHolder = DropdownPlaceHolder.find((ele: any) => {
    return _.isEqual(ele.id, platformName);
  });

  if (_.isUndefined(findPlaceHolder)) {
    return "Select Manager Account";
  } else {
    return findPlaceHolder.value;
  }
};
