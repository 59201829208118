import React, { useEffect, useRef, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import classnames from "classnames";
import style from "./style";
import { TableSortIcons } from "../../../../UI/Icons";
import { IGridHeader } from "../../Interfaces/Grid/IGridHeader";
import { SORT_ORDER, TEXT_ALIGNMENT } from "../../configuration";
import UITooltip from "../../../../UI/Elements/FormElements/Child/Tooltip";
import CheckboxComponent from "./CheckboxControl/CheckboxComponent";


const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  orderBy: string;
  order: any;
  sortHandler: any;
  headers: IGridHeader[];
  loading: boolean;
  bulkEditMode: boolean;
  handleAllChange?: void;
  isAllSelected?: boolean;
  allSelected?: boolean;
  isIndeterminate?: boolean;
};


const getCSSStyle = (header: IGridHeader): any => {
  let cssStyle = {};

  if (!_.isUndefined(header.width)) {
    cssStyle = {
      width: header.width,
    };
  }
  return cssStyle;
};

const getHeaderIcon = (
  header: IGridHeader,
  classes: any,
  order: string,
  orderBy: string
): React.JSX.Element => {
  let iconPlaceholder = header.disableSorting ? (
    <></>
  ) : (
    <span className={classes.sortingIcon}>{TableSortIcons.DEFAULT}</span>
  );
  if (_.isEqual(orderBy, header.sortingKey) && !header.disableSorting) {
    iconPlaceholder = (
      <span className={classes.sortingIcon}>
        {_.isEqual(order, SORT_ORDER.DESC)
          ? TableSortIcons.UP
          : TableSortIcons.DOWN}
      </span>
    );
  }

  if (!_.isUndefined(header.tooltip)) {
    iconPlaceholder = <UITooltip title={header.tooltip || ""} />;
  }
  return iconPlaceholder;
};

const onHeaderClick = (props: props, header: IGridHeader): void => {
  const { orderBy, order, sortHandler, loading } = props;
  if (header.disableSorting || loading) {
    return;
  }
  let Order = SORT_ORDER.ASC;
  if (_.isEqual(header.sortingKey, orderBy)) {
    Order = _.isEqual(order, SORT_ORDER.ASC) ? SORT_ORDER.DESC : SORT_ORDER.ASC;
  }
  sortHandler(header.sortingKey, Order, header);
};

// create table head cells
const createTableHeadCells = (props: props, classes: any): React.JSX.Element[] => {
  const { orderBy, order, headers, loading, bulkEditMode, handleAllChange, isAllSelected, allSelected, isIndeterminate } = props;
  const TableCells: React.JSX.Element[] = [];

  const CheckBoxHead = (): any => {
    if (loading) {
      return (
        <th
          key="grid-action"
          className={classnames(classes.gridCell, classes.gridActionCell)}
        >
          <span className={classnames(classes.gradient, classes.loadingCell)}></span>
        </th>
      );
    }
    else {
      return (
        <th
          key="grid-action"
          className={classnames(
            classes.gridHeaderContainer,
            classes.gridActionHeader
          )}>
          <CheckboxComponent checked={isAllSelected || allSelected} isIndeterminate={isIndeterminate} handleChange={handleAllChange} name="header" />
        </th>
      );
    }
  };
  {
    bulkEditMode && TableCells.push(
      <CheckBoxHead />
    );
  }
  for (const header of headers) {
    if (header.visible) {
      TableCells.push(
        <th
          key={header.id}
          onClick={() => onHeaderClick(props, header)}
          style={getCSSStyle(header)}
          className={classnames(
            classes.gridHeaderContainer,
            {
              [classes.centerAlign]: _.isEqual(
                TEXT_ALIGNMENT.CENTER,
                header.alignment
              ),
            },
            {
              [classes.leftAlign]: _.isEqual(
                TEXT_ALIGNMENT.LEFT,
                header.alignment
              ),
            },
            {
              [classes.rightAlign]: _.isEqual(
                TEXT_ALIGNMENT.RIGHT,
                header.alignment
              ),
            }
          )}
        >
          {
            loading ? (
              <div className={classnames(classes.gradient, classes.loadingCell)}>
              </div>
            ) : (
              <div
                className={classnames(classes.gridHeaderName, {
                  [classes.cursorPointer]: !header.disableSorting,
                })}
              >
                {header.title}
                {getHeaderIcon(header, classes, order, orderBy)}
              </div>
            )
          }

        </th>
      );
    }
  }

  const Head = (): any => {
    if (loading) {
      return (
        <th
          key="grid-action"
          className={classnames(classes.gridCell, classes.gridActionCell)}
        >
          <span className={classnames(classes.gradient, classes.loadingCell)}></span>
        </th>
      );
    }
    else {
      return (
        <th
          key="grid-action"
          className={classnames(
            classes.gridHeaderContainer,
            classes.gridActionHeader
          )}
        >
          Action
        </th>
      );
    }
  };

  {
    !bulkEditMode && TableCells.push(
      <Head />
    );
  }
  return TableCells;
};


export default function GridHeader(props: any): React.JSX.Element {
  const [touched, setTouched] = useState(false);
  const theadRef: any = useRef();
  const classes = useStyles();
  const onScroll = (): void => {
    if (_.isNull(theadRef) || _.isUndefined(theadRef.current)) {
      return;
    }
    const element = theadRef.current.getBoundingClientRect();

    setTouched(_.isEqual(element.top - 56, 0));
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => { document.removeEventListener("scroll", onScroll);};
  }, []);
  const Table_Cells = createTableHeadCells(props, classes);

  if (_.isEmpty(props.headers)) { return <></>;}

  return (
    <thead
      ref={theadRef}
      className={classnames(classes.gridHeader, {
        [classes.gridHeaderBoxShadow]: touched,
      })}
    >
      <tr>{Table_Cells}</tr>
    </thead>
  );
}
