
import { IFormElement } from "../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../Interfaces/Form/IForm";
import { IFormGroup } from "../Interfaces/Form/IFormGroup";

export const formatGridData = (data: any[]): any[] => {
    return data.map((grid: any) => {
      return {
        id: grid.mappingassignmentsid,
        platform: grid.platformname,
        region: grid.regionname,
        markets: grid.markets.map(
			(market: any) => market.marketname
		),
        agencies: grid.agencies.map(
			(agency: any) => agency.agencyname
		),
        instructions: grid.instructions,
		channelManager:{
			name:grid.manager.managerName,
			email:grid.manager.email
		},
		mappers: grid.mappingmapper.map(
			(mapper: any) => mapper.mappername
		  ),
      };
    });
  };

export const buildFormData = (response: any): any =>{

	const FormData: any = {};

	FormData.platform = {
		label: response.platform.platformname,
		value: response.platform.platformid
	};

	FormData.region = {
		label: response.region.regionname,
		value: response.region.regionid
	};

	FormData.instructions = response.instructions;

	const Mappers = response.mappingmapper || [];
	FormData.mappers = Mappers.map((mapper: any)=>{
		return ({
			label: mapper.mappername,
			value: mapper.mapperid
		});
	});

	const Markets = response.markets || [];

	FormData.markets = Markets.map((market: any)=>{
		return ({
			label: market.marketname,
			value: market.marketid
		});
	});

	const Agencies = response.agencies || [];

	FormData.agencies = Agencies.map((agency: any)=>{
		return ({
			label: agency.agencyname,
			value: agency.agencyid
		});
	});

	return FormData;
};
  

export const buildSubmitFormData = (form: IForm, channelId: number): any =>{

	const FormData: any = {};

	form.groups.forEach((formGroup: IFormGroup)=>{

		formGroup.elements.forEach((formElement: IFormElement)=>{
			FormData[formElement.id] = formElement.value;
		});
	});

	const UpdatedFormData: any = {
		mappingchannelid:channelId
	};

	UpdatedFormData.platformid = FormData.platform.value;
	UpdatedFormData.regionid = FormData.region.value;

	UpdatedFormData.instructions = FormData.instructions;

	const Mappers = FormData.mappers || [];
	UpdatedFormData.mappingmapper = Mappers.map((option: any)=>{
		return ({
			mapperid: option.value
		});
	});

	const Markets = FormData.markets || [];
	UpdatedFormData.markets = Markets.map((option: any)=>{
		return ({
			marketid: option.value
		});
	});

	const Agencies = FormData.agencies || [];
	UpdatedFormData.agencies = Agencies.map((option: any)=> {
		return ({
			agencyid: option.value
		});
	});
	return UpdatedFormData;
};