import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { UILabel } from "../../Child/Label";
import { UIBucketUploadAlert } from "./Dialog";
import style from "./style";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import { UIOutlineButton } from "../../../../Buttons/Types/Outline";
import { IFileUpload } from "../../../Forms/interface/File/IFileUpload";
import { IDialog, IDialogType } from "./IDialog";
import { GoogleUtilHelper } from "../../../Forms/helper/Utils/FileUtil/GoogleUtil";
import Utility from "./Utility";
import JSXHelper from "./Helper";
import { IFileUploadResponse } from "./IFileUploadResponse";
import { UIFileError } from "../../../../Error/File";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { FileHelper } from "../../../Forms/helper/Utils/FileUtil";
import Button from "@material-ui/core/Button";
import SnackbarAlert from "../../../../../UI/Snackbar/SnackbarAlert.component";
import IconDownload from "../../../../../UI/Icons/Download";



const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

type ESnackbarAlert = "success" | "error" | "info" | "warning";

// UI Bucket Upload for Grid
export const UIBucketUpload = (props: props): React.JSX.Element => {
  const { formElement } = props;
  const classes = useStyles();
  const fileInputRef: any = useRef(null);
  const fileHelper = new FileHelper();

  let AllowedFileType = (formElement.allowed_file_type || "").toUpperCase();
  let allowedFileSize = "";
  let allowedFileType = "";

  if (!_.isNull(formElement.max_range) && !formElement.load_data_to_grid) {
    const MaxSize: any = formElement.max_range;
    allowedFileSize = ` UNDER ${fileHelper.formatBytes(MaxSize)}`;
  }

  if (!_.isEqual(AllowedFileType.length, 0)) {
    AllowedFileType = AllowedFileType.split(",").join(", ");
    allowedFileType = `${AllowedFileType}`;
  }
  const [dialog, setDialog] = useState<IDialog>({
    open: false,
    type: "replace",
    file: null,
  });
  const [singleUpload, setSingleUpload] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  const [inputFiles, setInputFiles] = useState<{
    acceptedFiles: IFileUpload[];
    rejectedFiles: IFileUpload[];
  }>(formElement.value || { acceptedFiles: [], rejectedFiles: [] });
  const [allProgressData, setAllProgressData] = useState<Array<{ isretry: boolean, progress: number, fileId: string, uploadSessionURI: string }>>([]);
  let errorMessage = "";

  // Set Snackbar Alerts
  const setAlert = (message: string, variant: ESnackbarAlert): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };



  useEffect(() => {
    const content = window.addEventListener;
    content("dragover", function (e) {
      e.preventDefault();
    }, false);
    content("drop", function (e) {
      e.preventDefault();
    }, false);
  }, ["dragover", "drop"]);

  const dragOver = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragEnter = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const dragLeave = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
  };

  const fileDrop = (e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;
    if (!UploadLoader) {
      if (_.isEqual(formElement.is_multi, 0) && _.gt(files.length, 1)) {
        const Message = "Only one file is allowed to upload. Please upload the file again.";
        setAlert(Message, "error");
        return;
      }
      if (!_.isEmpty(ACCEPTED_FILES) && _.isEqual(formElement.is_multi, 0) && !singleUpload) {
        onOpenDialog(null, "replace");
        return;
      }
      fileUploadFunc(files);
      setSingleUpload(false);
    }
  };

  if (formElement.apiError) {
    errorMessage = formElement.apiError;
  } else {
    errorMessage = formElement.touched ? formElement.error : "";
  }



  const startUploadresume = async (fileInfo: IFileUpload): Promise<any> => {
    const googleUtil = new GoogleUtilHelper();
    let uploadSessionURI: any = null;
    try {
      const { file, uniqueFileName, abortController } = fileInfo;
      const AccessToken: any = await googleUtil.getSAAccessToken();
      if (_.isNull(AccessToken)) {
        return {
          error: true,
          signedURL: null,
          uploadSessionURI: null,
        };
      }
      const FileExtension: any = file.name.split(".").pop();
      const BUCKET_NAME: any =
        process.env.REACT_APP_EXT_TOOL_FILE_UPLOAD_GOOGLE_BUCKET;
      uploadSessionURI = await googleUtil.getUploadSessionURI(
        BUCKET_NAME,
        AccessToken,
        uniqueFileName,
        FileExtension
      );
      if (_.isNull(uploadSessionURI)) {
        return {
          error: true,
          signedURL: null,
          uploadSessionURI: null,
        };
      }
      setAllProgressDataset(0, fileInfo.id, uploadSessionURI);
      await Utility.uploadFileToBucketParalley(
        fileInfo,
        abortController,
        uploadSessionURI,
        file,
        setAllProgressDataset
      );
      return await Utility.generateSignedURL(uniqueFileName, uploadSessionURI);
    } catch (err) {
      return {
        error: true,
        signedURL: null,
        uploadSessionURI,
      };
    }
  };

  const updateInputFiles = (isLoading: boolean, data: any): void => {
    setInputFiles((prevState) => {
      const UpdatedData = {
        ...prevState,
        ...data,
      };
      Utility.handleOnChange(props, UpdatedData, isLoading);
      return UpdatedData;
    });
  };

  const fileUploadFunc = async (Files: any): Promise<void> => {
    const uploadedFiles = inputFiles.acceptedFiles;
    const uploadedrejectedFiles = inputFiles.rejectedFiles;
    if (_.gt(Files.length, formElement.selection_limit) && !_.isEqual(formElement.is_multi, 0)) {

      const isFileLimitExceeded = true;
      updateInputFiles(false, {
        isFileLimitExceeded,
      });
      return;
    }
    if (_.gt((Files.length + inputFiles.acceptedFiles.length), formElement.selection_limit) && !_.isEmpty(inputFiles.acceptedFiles) && !_.isEqual(formElement.is_multi, 0)) {
      const Message = `${formElement.selection_limit} files are allowed to upload. Please upload the files again.`;
      setAlert(Message, "error");
      return;
    }
    if (_.isEqual(Files.length, 0)) {
      return;
    }

    setAllProgressData([]);

    const { acceptedFiles, rejectedFiles, isFileLimitExceeded } = Utility.getFilesData(formElement, Files);

    if (!_.isEqual(formElement.is_multi, 0)) {
      uploadedFiles.forEach((file: any) => {
        acceptedFiles.push(file);
      });
    }
    if (!_.isEqual(formElement.is_multi, 0)) {
      uploadedrejectedFiles.forEach((file: any) => {
        rejectedFiles.push(file);
      });
    }
    const isValidForUpload = !_.isEqual(acceptedFiles.length, 0) && acceptedFiles.filter((item: any) => item.status === "pending");
    updateInputFiles(isValidForUpload.length > 0 ? true : false, {
      acceptedFiles,
      rejectedFiles,
      isFileLimitExceeded,
    });
    upLoadFilesParallely(acceptedFiles);
  };

  const handleFileInputChange = async (event: any): Promise<void> => {
    const Files = Array.from(event.target.files);
    fileUploadFunc(Files);
  };

  const upLoadFilesParallely = async (acceptedFiles: any): Promise<any> => {
    const DATA_FILES: any = [];
    const uploadedFiles: any = [];
    acceptedFiles.map((file: any) => {
      if (_.isEqual(file.status, "success")) {
        uploadedFiles.push(file);
      }
      else {
        DATA_FILES.push(file);
        const File: IFileUpload = file;

        if (File.isValidForUpload) {
          setAllProgressData((prevData) => [...prevData, { isretry: false, progress: 0, fileId: File.id, uploadSessionURI: "" }]);
        }
      }
    });
    if (DATA_FILES.length > 0) {
      const googleUtil = new GoogleUtilHelper();
      const AccessToken: any = await googleUtil.getSAAccessToken();
      if (_.isNull(AccessToken)) {
        const d = DATA_FILES.map((item: any) => {
          return { ...item, error: true, signedURL: null, uploadSessionURI: null, status: "error" };
        });
        uploadedFiles.map((file: any) => {
          d.push(file);
        });
        setInputFiles((prevState) => {
          const UpdatedData = {
            rejectedFiles: prevState.rejectedFiles, acceptedFiles: d
          };
          Utility.handleOnChange(props, UpdatedData, false);
          return UpdatedData;
        });
        setAllProgressData([]);
        return true;
      }
      const Promiseall: any = [];
      if (!_.isNull(AccessToken)) {
        for (let iFile = 0; iFile < DATA_FILES.length; iFile++) {
          const newPromise = new Promise((resolve) => {
            (async () => {
              const File: IFileUpload = DATA_FILES[iFile];
              let uploadSessionURI: any = null;
              const { file, uniqueFileName, abortController } = File;
              try {
                if (File.isValidForUpload) {
                  const FileExtension: any = file.name.split(".").pop();
                  const BUCKET_NAME: any =
                    process.env.REACT_APP_EXT_TOOL_FILE_UPLOAD_GOOGLE_BUCKET;
                  uploadSessionURI = await googleUtil.getUploadSessionURI(
                    BUCKET_NAME,
                    AccessToken,
                    uniqueFileName,
                    FileExtension
                  );
                  if (_.isNull(uploadSessionURI)) {
                    DATA_FILES[iFile] = {
                      ...DATA_FILES[iFile],
                      error: true,
                      signedURL: null,
                      uploadSessionURI: null,
                    };
                    resolve(DATA_FILES[iFile]);
                  }
                }
                setAllProgressDataset(0, File.id, uploadSessionURI);
                await Utility.uploadFileToBucketParalley(
                  File,
                  abortController,
                  uploadSessionURI,
                  file,
                  setAllProgressDataset
                );
                const UploadResult: IFileUploadResponse = await Utility.generateSignedURL(uniqueFileName, uploadSessionURI);
                DATA_FILES[iFile] = {
                  ...DATA_FILES[iFile],
                  signedURL: !_.isNull(UploadResult) ? UploadResult.signedURL : null,
                  status: UploadResult.error ? "error" : "success",
                  uploadSessionURI: !_.isNull(UploadResult)
                    ? UploadResult.uploadSessionURI
                    : null,
                  progress: 100,
                };
                setInputFiles((prevState) => {
                  const d = prevState.acceptedFiles.map((item) => {
                    if (item.id === DATA_FILES[iFile].id) {
                      return DATA_FILES[iFile];
                    } else { return item; }
                  });
                  const UpdatedData = {
                    rejectedFiles: prevState.rejectedFiles, acceptedFiles: d
                  };
                  Utility.handleOnChange(props, UpdatedData, true);
                  resolve(DATA_FILES[iFile]);
                  return UpdatedData;
                });
                setAllProgressData((prev) =>
                  prev.filter((item) => (!item.isretry) && (item.fileId !== DATA_FILES[iFile].id)));

              } catch (error) {
                const UploadResult: IFileUploadResponse = await Utility.generateSignedURL(uniqueFileName, uploadSessionURI);
                DATA_FILES[iFile] = {
                  ...DATA_FILES[iFile],
                  signedURL: !_.isNull(UploadResult) ? UploadResult.signedURL : null,
                  status: "error",
                  uploadSessionURI: uploadSessionURI,
                  progress: 100,
                };
                setInputFiles((prevState) => {
                  const d = prevState.acceptedFiles.map((item) => {
                    if (item.id === DATA_FILES[iFile].id) {
                      return DATA_FILES[iFile];
                    } else { return item; }
                  });
                  const UpdatedData = {
                    rejectedFiles: prevState.rejectedFiles, acceptedFiles: d
                  };
                  Utility.handleOnChange(props, UpdatedData, true);
                  resolve(DATA_FILES[iFile]);
                  return UpdatedData;
                });
                setAllProgressData((prev) =>
                  prev.filter((item) => (!item.isretry) && (item.fileId !== DATA_FILES[iFile].id)));

              }
            })();
          });
          Promiseall.push(newPromise);
        }
        Promise.all(Promiseall).then((result: any) => {
          let allFiles: any = [];
          allFiles = result;
          uploadedFiles.map((file: any) => {
            allFiles.push(file);
          });
          const UpdatedData = {
            rejectedFiles: !_.isEqual(formElement.is_multi, 0) ? inputFiles.rejectedFiles : [],
            acceptedFiles: allFiles
          };
          Utility.handleOnChange(props, UpdatedData, false);
        }).catch((Error: any) => {
          console.log(Error);
        });
      }
    }
  };

  const setAllProgressDataset = (progress: number, fileId: string, uploadSessionURI: string): void => {
    setAllProgressData((prevData) => {
      const d: any = prevData.map((item: { progress: number, fileId: string }) => {
        if (fileId === item.fileId) {
          return { ...item, progress: progress, uploadSessionURI: uploadSessionURI };
        } else { return item; }
      });
      return d;
    });
  };

  const onOpenDialog = (
    file: IFileUpload | null = null,
    type: IDialogType = "replace"
  ): void => {
    setDialog({
      open: true,
      type,
      file,
    });
  };

  const onReplaceFiles = (): void => {
    setDialog({
      open: false,
      type: "replace",
      file: null,
    });
    setSingleUpload(true);
    fileInputRef.current.click();
  };

  const onDeleteFile = (): void => {
    const FilesData = (
      !_.isNull(formElement.value) && _.isArray(formElement.value.acceptedFiles)
        ? formElement.value.acceptedFiles
        : []
    ).filter((file: IFileUpload) => !_.isEqual(file.id, dialog?.file?.id));
    const isFileLimitExceeded = false;
    updateInputFiles(false, {
      acceptedFiles: FilesData,
      isFileLimitExceeded
    });
    const googleUtil = new GoogleUtilHelper();
    const UniqueFileName: any = dialog?.file?.uniqueFileName;
    const BUCKET_NAME: any =
      process.env.REACT_APP_EXT_TOOL_FILE_UPLOAD_GOOGLE_BUCKET;
    googleUtil.deleteFileFromBucket(UniqueFileName, BUCKET_NAME);
    setDialog({
      open: false,
      type: "replace",
      file: null,
    });
  };

  const onCancel = (): void => {
    const getid: any = allProgressData.filter((item) => item.fileId === dialog?.file?.id);
    const UploadSessionURI = _.isNull(allProgressData) ? null : getid[0].uploadSessionURI;
    if (!_.isNull(UploadSessionURI)) {
      dialog?.file?.abortController.abort();
      setAllProgressData((prev) =>
        prev.filter((item) => item.fileId !== dialog?.file?.id));
    }
    setDialog({
      open: false,
      type: "replace",
      file: null,
    });
  };

  const uploadSingleFile = async (
    file: IFileUpload,
    bytesRange: any = null,
    contentLength = 0,
    isNewUpload = true
  ): Promise<void> => {
    const FilesData = inputFiles.acceptedFiles.map((data: any) => ({ ...data, }));
    const FileData: IFileUpload = FilesData.find((fileData: IFileUpload) =>
      _.isEqual(fileData.id, file.id)
    );
    if (!_.isUndefined(FileData)) {
      const abortController = new AbortController();
      FileData.abortController = abortController;
      updateInputFiles(true, {
        acceptedFiles: FilesData,
      });
      if (isNewUpload) {
        const Result = await startUploadresume(FileData);
        updateFile(Result, FileData);
        return;
      }
      setAllProgressDataset(0, file.id, file.uploadSessionURI || "");
      const UploadResult = await Utility.resumeFileUpload(
        FileData,
        bytesRange,
        contentLength,
        setAllProgressDataset
      );
      updateFile(UploadResult, FileData);
    }
  };

  const updateFile = (uploadResult: any, file: any): void => {
    const FileData = inputFiles.acceptedFiles.map((data: any) => ({ ...data }));
    const FileDataIndex = FileData.findIndex((fileData: IFileUpload) => _.isEqual(fileData.id, file.id));
    FileData[FileDataIndex] = {
      ...FileData[FileDataIndex],
      signedURL: !_.isNull(uploadResult) ? uploadResult.signedURL : null,
      status: uploadResult.error ? "error" : "success",
      uploadSessionURI: !_.isNull(uploadResult)
        ? uploadResult.uploadSessionURI
        : null,
      progress: 100,
    };
    setAllProgressData((prev) => prev.filter((item) => item.fileId !== file.id));
    updateInputFiles(false, {
      acceptedFiles: FileData,
    });
  };

  const onRetry = async (file: IFileUpload): Promise<void> => {
    setAllProgressData((prevData) => [...prevData, { isretry: true, progress: 0, fileId: file.id, uploadSessionURI: "" }]);
    const googleUtil = new GoogleUtilHelper();
    if (!_.isNull(file.uploadSessionURI)) {
      const fileUploadResult = await googleUtil.isFileUploadSuccessful(
        file.uploadSessionURI,
        file.size
      );
      if (fileUploadResult.error) {
        uploadSingleFile(file);
        return;
      }
      if (_.isEqual(fileUploadResult.status, 200)) {
        const UploadResult = await Utility.generateSignedURL(
          file.uniqueFileName,
          file.uploadSessionURI
        );
        updateFile(UploadResult, file);
        return;
      }
      if (_.isEqual(fileUploadResult.status, 308)) {
        const { contentLength, range } = fileUploadResult;
        uploadSingleFile(file, range, contentLength, false);
        return;
      }
    }
    uploadSingleFile(file);
  };

  const UploadLoader = _.isNull(formElement.value)
    ? false
    : formElement.loading;
  const ACCEPTED_FILES = inputFiles.acceptedFiles;
  const REJECTED_FILES = inputFiles.rejectedFiles;

  const onInputChange = (
    dataKey: "acceptedFiles" | "rejectedFiles",
    fileData: any,
    file: IFileUpload,
    updatedData: any
  ): void => {
    const UpdatedFiles = fileData.map((data: any) => {
      if (_.isEqual(data.id, file.id)) {
        return {
          ...data,
          ...updatedData,
        };
      }
      return {
        ...data,
      };
    });
    updateInputFiles(UploadLoader, {
      [dataKey]: UpdatedFiles,
    });
  };

  const RenderAcceptedFiles = JSXHelper.renderAcceptedFiles(
    ACCEPTED_FILES,
    onOpenDialog,
    onRetry,
    UploadLoader,
    formElement,
    onInputChange,
    allProgressData
  );

  const onDeleteErrorFile = (file: IFileUpload): void => {
    const FilesData = inputFiles.rejectedFiles.map((data: any) => ({
      ...data,
    }));
    const UpdatedFiles = FilesData.filter(
      (fileUpload: IFileUpload) => !_.isEqual(fileUpload.id, file.id)
    );

    updateInputFiles(UploadLoader, {
      rejectedFiles: UpdatedFiles,
    });
  };

  const RenderRejectedFiles = JSXHelper.renderRejectedFiles(
    REJECTED_FILES,
    onDeleteErrorFile,
    formElement,
    onInputChange
  );

  const ColumnWidth = Utility.isTextBoxConfigEmpty(formElement) ? 5 : 12;
  const CustomHeaders = JSXHelper.getHeaders(formElement, classes);
  const Note = (formElement.note || "").toString().trim();
  return (
    <Grid container item lg={12} md={12} sm={12} xs={12} key={props.formElement.id}>
      <Grid container>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <UILabel formElement={formElement} />
          <div className={classes.uploadFileTemplate}>
            {!_.isNull(formElement.template_url) && (
              <a
                target="_blank"
                rel="noreferrer"
                className={classes.templateLink}
                href={formElement.template_url}
              >
                <span className={classes.downloadIcon}>
                  <IconDownload smallIcon={false} isFileUpload={true} />
                </span> {formElement.template_label}
              </a>
            )}
          </div>
        </Grid>

        <Grid container alignItems="center" item lg={9} md={9} sm={12} xs={12}>
          <div className={(_.isEqual(inputFiles.acceptedFiles.length, formElement.selection_limit) &&
            !_.isEqual(formElement.is_multi, 0) && !UploadLoader) ? classes.disableDiv : classes.fileUploadDiv}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            <div className={classes.uploadContentDiv}>
              <InsertDriveFileIcon className={classes.fileInsertIcon} />
              <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                <Button
                  disabled={UploadLoader}
                  onClick={(): void => {
                    if (!_.isEmpty(ACCEPTED_FILES) && _.isEqual(formElement.is_multi, 0)) {
                      onOpenDialog(null, "replace");
                      return;
                    }
                    fileInputRef.current.click();
                  }}
                  className={classes.fileSpan}>Choose a file</Button>to upload or drag it here</p>
              <p className={classes.allowedFileType}>{allowedFileType}{allowedFileSize}</p>
            </div>

          </div>
          <input
            type="file"
            onChange={handleFileInputChange}
            onClick={() => (fileInputRef.current.value = null)}
            ref={fileInputRef}
            style={{ display: "none" }}
            multiple={formElement.is_multi}
            accept={formElement.allowed_file_type}
          />
          <br />
          <UIOutlineButton
            isOutline={true}
            onClick={(): void => {
              if (_.isEmpty(ACCEPTED_FILES)) {
                fileInputRef.current.click();
                return;
              }
              onOpenDialog(null, "replace");
            }}
            disabled={UploadLoader}
            cssStyle={{
              textTransform: "none",
              fontWeight: 600,
              display: "none"
            }}
            btnText="Upload File"
          />

        </Grid>
        {!_.isEmpty(Note) && (
          <Grid
            container
            direction="column"
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
          >
            <div
              className={classes.note}
              dangerouslySetInnerHTML={{
                __html: formElement.note,
              }}
            ></div>
          </Grid>
        )}

        <Grid
          container
          direction="column"
          item
          lg={ColumnWidth}
          md={ColumnWidth}
          sm={12}
          xs={12}
        >
          {!UploadLoader && CustomHeaders}
          {RenderAcceptedFiles}
          {RenderRejectedFiles}
          <UIFileError note="" errorMessage={errorMessage} />
        </Grid>
      </Grid>

      {dialog.open && (
        <UIBucketUploadAlert
          onClose={() =>
            setDialog({
              open: false,
              type: "replace",
              file: null,
            })
          }
          onSubmit={(): void => {
            if (_.isEqual(dialog.type, "delete")) {
              onDeleteFile();
              return;
            }
            if (_.isEqual(dialog.type, "replace")) {
              onReplaceFiles();
              return;
            }
            onCancel();
          }}
          files={ACCEPTED_FILES}
          type={dialog.type}
        />
      )}
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        onOpenCloseAlert={handleCloseAlert}
      />
    </Grid>
  );
};
