import GlobalColors from "../../../../UI/Theme/colors";

import Colors from "../../../../UI/Elements/utilstyle";


const style: any = (): any => ({
	inputContainer:{
		display:"flex",
		width:"100%",
		flex:1,
		backgroundColor: GlobalColors.WHITE,
		minHeight: 40,
	},
	
	inputElement:{
		
		"&:focus":{
			border: `1px solid ${Colors.FocusColor}`,
		},
		"&:hover":{
			border: `1px solid ${Colors.HoverColor}`,
		},
        
	},
	input: {
		"box-sizing": "border-box",
		padding: "7px 12px",
		backgroundColor: GlobalColors.WHITE,
		borderRadius: 2,
		width:"100%",
		fontSize:"0.875rem",
		border: `1px solid ${Colors.BorderColor}`,
	},
});

export default style;