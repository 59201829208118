import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import style from "./style";
import { IconClose } from "../../../../../../../UI/Icons";
import { UIOutlineButton } from "../../../../../../../UI/Buttons";
import * as _ from "lodash";
import { ReviewForm } from "../../Layout/ReviewForm";
import { IForm } from "../../../../../../../UI/Elements/Forms/interface/IForm";



// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
    formTitle: string;
    btnCancelText: string;
    onCancel: any;
    width?: number;
    formData: IForm[];
    jobId: number;
    jobName:string;
};

export const ViewDetailsSidebar: any = (props: props): React.JSX.Element => {
    const {
        formTitle,
        btnCancelText,
        onCancel,
        width,
        formData,
        jobId,
        jobName
    } = props;
    const classes = useStyles();
    return (
        <>
            <div
                className={classes.sideBarContainer}
                style={{
                    width: width || 450,
                }}
                role="presentation">
                <div className={classes.sidebarHeader}>
                    <div className={classes.sideBarTitle}>{formTitle}</div>
                    <IconClose onClick={onCancel} title="" />
                </div>

                {!_.isEmpty(formData) ? <ReviewForm forms={formData} jobId={jobId} jobName={jobName}  /> : null}
            </div>
            <div
                className={classes.actionContainer}
                style={{
                    width: width || 424,
                }}
            >
                <UIOutlineButton
                    isOutline={true}
                    onClick={onCancel}
                    btnText={btnCancelText}
                    cssStyle={{
                        marginRight: "0.75rem",
                    }}
                />

            </div>

        </>
    );
};
