import classNames from "classnames";
import React from "react";
import * as _ from "lodash";
import { IGridHeader } from "../../../Interfaces/Grid/IGridHeader";

export const getLoadingCells = (
  headers: IGridHeader[],
  classes: any,
  isGridAction: boolean
): React.JSX.Element[] => {
  const GridCells: React.JSX.Element[] = [];
  for (const header of headers) {
    if (header.visible) {
      let cssStyle = {};

      if (!_.isUndefined(header.width)) {
        cssStyle = {
          width: header.width,
        };
      }

      GridCells.push(
        <td key={header.id} style={cssStyle} className={classes.gridCell}>
          <span
            className={classNames(classes.gradient, classes.loadingCell)}
          ></span>
        </td>
      );
    }
  }
  if (isGridAction) {
    GridCells.push(
      <td
        key="grid-action"
        className={classNames(classes.gridCell, classes.gridActionCell)}
      >
        <span
          className={classNames(classes.gradient, classes.loadingCell)}
        ></span>
      </td>
    );
  }
  return GridCells;
};
