import * as _ from "lodash";
import { RequiredValidation } from "./Required";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { IFormElementUpdate } from "../../Interface/FormElement/IFormElementUpdate";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElementValue } from "../../../../UI/Elements/Forms/interface/IFormElementValue";
import { SingleSelectHelper } from "../Forms/SelectInput/SingleSelect";
import { MultiSelectHelper } from "../Forms/SelectInput/MultiSelect";

// Validation helper for forms
export class ValidationHelper {
	private requiredValidation: RequiredValidation;
	private singleSelectHelper: SingleSelectHelper;
	private multiSelectHelper: MultiSelectHelper;

	constructor() {
		this.requiredValidation = new RequiredValidation();
		this.singleSelectHelper = new SingleSelectHelper();
		this.multiSelectHelper = new MultiSelectHelper();
    }

    /**
     * Validate forms
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @param {boolean} isDelayed.
     * @return {string} errorMessages.
     */
     isValidForms = (forms: IForm[]) : IForm[] =>{
     	return  forms.map((form: IForm)=> {
     		let formIsValid = true;
			let elementInProgress = false;
			let formIsVisible = false;
			return ({
				...form,
				groups: form.groups.map((group: IFormGroup) => {
					let groupIsVisible = false;
					const Group: IFormGroup = ({
						...group,
						elements: group.elements.map((formElement: IFormElement) => {
							let isElementValid = formElement.isValid;
						
							if (!formElement.visible) {
								isElementValid = true;
							}
							elementInProgress = elementInProgress || formElement.loading;

     						formIsValid = formIsValid && isElementValid;

     						groupIsVisible = groupIsVisible || formElement.visible;
                            
     						return {
								...formElement
							};
     					}),
     					isVisible: groupIsVisible
     				});
     				formIsVisible = formIsVisible || groupIsVisible;

     				return Group;
     			}),
     			isVisible: formIsVisible,
     			isValid: formIsValid,
				loading: elementInProgress
     		});
     	});
     }

    /**
     * Validate element
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @param {boolean} isDelayed.
     * @return {string} errorMessages.
     */
    isValidElement = (forms: IForm[], element?: IFormElementUpdate) : IForm[] =>{

    	const Forms: IForm[] = [];
    	for(const form of forms){
    		const Groups: IFormGroup[] = [];
    		for(const formGroup of form.groups){
    			const Elements: IFormElement[] = [];
    			for(const formElement of formGroup.elements){
    				const FormElement: IFormElement = {...formElement};
    				if(_.isEqual(FormElement.id, element?.formElement.id)){
    					const errorMessage = this.validate(FormElement, element?.value);
    					const isValid = _.isEqual((errorMessage || "").length, 0);

    					Elements.push({
    						...FormElement,
    						touched:true,
    						errorMessage,
    						isValid
    					});
    				}else{
    					Elements.push({
    						...FormElement
    					});
    				}
    			}
    			Groups.push({
    				...formGroup,
    				elements: Elements
    			});
    		}
    		Forms.push({
    			...form,
    			groups: Groups
    		});
    	}
    	return Forms;
    }

	/**
	 * Validate form element
	 *
	 * @param {IFormElement} formElement.
	 * @param {any} elementValue.
	 * @return {string} errorMessages.
	 */
	validate = (formElement: IFormElement, elementValue: any): string => {
		let errorMessage = "";

		const IsEmptyField = this.requiredValidation.isEmptyElementValue(formElement, elementValue);

		// If the field is empty and not required
		if (IsEmptyField && !formElement.validations.required) {

			return errorMessage;
		}

		// Required field validation
		if (formElement.validations.required) {
			errorMessage = this.requiredValidation.validate(formElement,
				elementValue);

			if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
		}

		return errorMessage;
	}

	    /**
     * Check if the current and previous element value are same or not
     *
     * @param {IFormElement} formElement.
     * @param {IFormElementValue} element.
     * @return {boolean}
     */
		isPreviousAndCurrentValueAreSame = (formElement: IFormElement, element: IFormElementValue): boolean =>{
			if(_.isEqual(formElement.type, FORM_ELEMENT_TYPES.SELECT_INPUT)
			&& formElement.configuration.isMulti){
				const PrevValue = this.multiSelectHelper.getMultiSelectValues(element.previousValue || []);
				const CurrentValue = this.multiSelectHelper.getMultiSelectValues(element.currentValue || []);
		
				return _.isEqual(PrevValue,CurrentValue);
			}

	
			let previousValue  = element.previousValue;
			let currentValue =  element.currentValue;
	
			if(_.isEqual(formElement.type, FORM_ELEMENT_TYPES.SELECT_INPUT)
			&& !formElement.configuration.isMulti){
				previousValue = _.isNull(previousValue) ? null: previousValue.value;
				currentValue = _.isNull(currentValue) ? null :  currentValue.value;
			}
		
			previousValue = (previousValue || "").toString().trim();
			currentValue = (currentValue || "").toString().trim();
	
			return _.isEqual(currentValue, previousValue);
		}
}