import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import { IForm } from "../../Interface/IForm";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { FORM_ELEMENT_TYPES } from "../ElementTypes";
import { UITextInput } from "./ToolInput";
import { UISwitch } from "./Switch";
import { UISelect } from "./SelectInput";
import style from "./style";
import UIRadioInput from "./RadioInput";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));


// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  onValidateForms: any;
  formId: string;
  formGroupId: string;
  form: IForm;
};

// UIInput Component
export const UIInput = (props: props): React.JSX.Element => {
  const { formId, formGroupId, onChange, formElement } = props;
  const GridColumns: any = formElement.configuration.gridColumns;
  let Element;
  const classes = useStyles();

  switch (formElement.type) {
    case FORM_ELEMENT_TYPES.TEXT_INPUT:
      Element = (
        <UITextInput
          formId={formId}
          formGroupId={formGroupId}
          onChange={onChange}
          formElement={formElement}
        />
      );
      break;
    case FORM_ELEMENT_TYPES.SWITCH:
      Element = (
        <UISwitch
          formId={formId}
          formGroupId={formGroupId}
          onChange={onChange}
          formElement={formElement}
        />
      );
      break;
    case FORM_ELEMENT_TYPES.RADIO_INPUT:
      Element = (
        <UIRadioInput
          formId={formId}
          formGroupId={formGroupId}
          onChange={onChange}
          formElement={formElement}
        />
      );
      break;
    case FORM_ELEMENT_TYPES.SELECT_INPUT:
      Element = (
        <UISelect
          formId={formId}
          formGroupId={formGroupId}
          onChange={onChange}
          formElement={formElement}
        />
      );
      break;

    default:
      Element = <span />;
  }

  const InputElement = (<Grid container item xl={GridColumns} lg={GridColumns} md={6} xs={12}>
  <Box
    style={{
      width: "100%",
      marginLeft: formElement.configuration.inlineElement ? -8 : 8
    }}
    sx={{
      mr: 1,
      ml: 1,
    }}
  >
    {Element}
  </Box>
</Grid>);

  if(!formElement.configuration.isFullWidth){
    return InputElement;
  }

  return (
    <div
      className={classes.fullWidth}
    >
      {InputElement}
    </div>
  );
};
