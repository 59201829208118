import React from "react";
import { Drawer, makeStyles } from "@material-ui/core";
import { SidebarFormComponent } from "./Form";
import { IScheduleSelectedJobInfo } from "../../Interfaces/IScheduleSelectedJobInfo";

type props = {
  toggleDrawer: any;
  formTitle: string;
  btnCancelText: string;
  btnSaveText: string;
  onSave: any;
  open: boolean;
  onCancel: any;
  isFilterForm: boolean;
  formData: any;
  resetText?: string;
  width?: number;
  onReset?: any;
  selectedScheudledInfo: IScheduleSelectedJobInfo;
};

const useStyles = makeStyles({
  paper: {
      overflowX: "hidden"
  }
});

export const SidebarComponent: any = (props: props): React.JSX.Element => {
  const {
    formData,
    formTitle,
    btnCancelText,
    btnSaveText,
    onCancel,
    onSave,
    width,
    isFilterForm,
    selectedScheudledInfo,
    open,
  } = props;
  const classes = useStyles();

  return (
    <Drawer anchor="right" open={open} classes={{ paper: classes.paper }}>
      {open && (
        <SidebarFormComponent
        formData={formData}
        formTitle={formTitle}
        btnCancelText={btnCancelText}
        btnSaveText={btnSaveText}
        onCancel={onCancel}
        onSave={onSave}
        isFilterForm={isFilterForm}
        width={width}
        selectedScheudledInfo = {selectedScheudledInfo}
        />
      )}
    </Drawer>
  );
};
