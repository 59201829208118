import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import PreviewToolImage from "../../../../assets/tools/ToolPreview.png";
import styles from "./style";
import { withErrorHandler, withSnackbar } from "../../../../HOC";
import { ToolHeader, ToolInfo } from "./Layout";

type props = any;

// External/Content tool box page component
const ContentExternalTool = (props: props): React.JSX.Element => {
  const { classes, data } = props;
  const history = useHistory();

  // Combined parameters to pass ToolList component as props.
  const propsToToolInfo = {
    data: data,
    classes: classes,
  };

  const ThumbnailImagePath = data.thumbnail_path || "";

  const ThumbnailImage = _.isEqual(ThumbnailImagePath.length, 0)
    ? PreviewToolImage
    : data.thumbnail_path;

  return (
    <Fragment>
      {!_.isUndefined(data) && !_.isEmpty(data) && (
        <Fragment>
          <ToolHeader
            handleBackButton={() => {
              history.push("/");
            }}
            {...propsToToolInfo}
          />
          <Container maxWidth="lg">
            <Grid container className={classes.toolContentWrapper}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className={classes.infoWrapper}
              >
                <div className={classes.toolContent}>
                  <img
                    src={ThumbnailImage}
                    className={classes.thumbnailImage}
                  ></img>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                justifyContent="center"
                className={classes.infoWrapper}
              >
                <ToolInfo {...propsToToolInfo} />
              </Grid>
            </Grid>
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(
  withErrorHandler(withRouter(withSnackbar(ContentExternalTool)))
);
