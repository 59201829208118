import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Grid,
  Input,
  IconButton,
  InputAdornment,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Clear";
import classNames from "classnames";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import style from "./style";
import { IconFilter, IconSearch } from "../../../../../UI/Icons";
import ToggleButton from "../Toggle Button";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  recordsCount: number;
  loading: boolean;
  searchPlaceholder: string;
  handleSearchChange: any;
  searchValue: string;
  filterCount: number;
  onOpenFilter: any;
  hideFilter: boolean;
  customComponent?: any;
  showCustomComponent?: boolean;
  tabSelectedId?: any;
  toggleValue?: any;
  toggleHandleChange?: any;
  platformData?: any;
  leftContainerCols: number;
  statusDropdown?: any;
  middleContainerCols?: number;
  statusDataSource?: any;
  isMapper?: boolean;
};

export const SearchFilter = (props: props): React.JSX.Element => {//NOSONAR
  const classes = useStyles();
  const {
    toggleHandleChange,
    toggleValue,
    loading,
    searchPlaceholder,
    filterCount,
    handleSearchChange,
    searchValue,
    onOpenFilter,
    customComponent,
    showCustomComponent,
    hideFilter,
    platformData,
    leftContainerCols,
    middleContainerCols,
    statusDropdown,
    statusDataSource,
    isMapper
  } = props;
  let LeftContainerCols: any = "";
  let MiddleContainerCols: any = "";
  let RightContainerCols: any = "";

  if (isMapper) {//NOSONAR
    LeftContainerCols = leftContainerCols || 6;//NOSONAR
    MiddleContainerCols = middleContainerCols || 2;//NOSONAR
    RightContainerCols = 12 - LeftContainerCols - MiddleContainerCols;//NOSONAR
  }
  else {//NOSONAR
    LeftContainerCols = leftContainerCols || 6;//NOSONAR
    RightContainerCols = 12 - LeftContainerCols;//NOSONAR
  }

  const [search, setSearch] = useState({//NOSONAR
    text: searchValue || "",//NOSONAR
    typingTimeout: 0,
  });

  useEffect(() => {
    setSearch((prevState: any) => ({
      ...prevState,
      text: searchValue,
    }));
  }, [searchValue]);
  const bundle = useSelector((state: any) => state.initial.bundle);

  const handleChange = (event: any, isClearClick: boolean): any => {
    const SearchText = isClearClick ? "" : event.target.value;
    if (search.typingTimeout) {
      clearTimeout(search.typingTimeout);
    }
    setSearch((prevState: any) => ({
      ...prevState,
      text: SearchText,
    }));

    const SearchTrimText = (SearchText || "").toString().trim();

    if (
      (_.gte(SearchTrimText.length, 3) || _.lt(SearchTrimText.length, 1)) &&
      !_.isEqual(searchValue.trim(), SearchText.trim())
    ) {
      if (_.isEqual(event.code, "Enter")) {
        handleSearchChange(SearchTrimText);
      } else {
        setSearch((prevState: any) => ({
          ...prevState,
          typingTimeout: setTimeout(() => {
            handleSearchChange(SearchTrimText);
          }, 1000),
        }));
      }
    }
  };

  let component = toggleValue ? (
    <ToggleButton
      exclusive={true}
      data={platformData}
      handleChange={toggleHandleChange}
      value={toggleValue}
      loading={loading}
    />
  ) : (
    ""
  );

  if (showCustomComponent) {
    component = customComponent;
  }

  return (
    <Grid container className={classes.root} alignItems="center">
      <Grid
        item
        xs={12}
        sm={LeftContainerCols}
        md={LeftContainerCols}
        lg={LeftContainerCols}
      >
        {component}
      </Grid>

      {!hideFilter && isMapper &&
        <Grid
          item
          xs={12}
          sm={MiddleContainerCols}
          md={MiddleContainerCols}
          lg={MiddleContainerCols}
        >
          <div className={classes.statusDropdownStyle}
            style={{ opacity: _.isEqual(statusDataSource?.length, 0) || loading ? 0.7 : 1 }}
          >
            {statusDropdown}
          </div>
        </Grid>
      }

      {!hideFilter && (
        <Grid
          container
          item
          xs={12}
          sm={RightContainerCols}
          md={RightContainerCols}
          lg={RightContainerCols}
          style={{ justifyContent: "flex-end" }}
        >
          <div className={classes.search}>
            <div
              className={classes.searchIcon}
              style={{ opacity: loading ? 0.5 : 1 }}
            >
              <IconSearch title={""} />
            </div>
            <Input
              type="input"
              onKeyDown={(event) => {
                if (_.isEqual(event.code, "Enter")) {
                  handleChange(event, false);
                }
              }}
              title="Please enter at least 3 characters."
              placeholder={searchPlaceholder}
              onChange={(event) => handleChange(event, false)}
              value={search.text}
              className={classNames(classes.inputInput, classes.inputElement)}
              inputProps={{ "aria-label": "search" }}
              disabled={loading}
              disableUnderline={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    style={{
                      visibility:
                        loading || _.isEqual(searchValue, "")
                          ? "hidden"
                          : "visible",
                    }}
                    size={"small"}
                    onClick={(e) => {
                      return handleChange(e, true);
                    }}
                  >
                    <CloseIcon
                      fontSize="small"
                      className={classes.closeButton}
                    />
                  </IconButton>
                </InputAdornment>
              }
            />
          </div>
          <div style={{ paddingLeft: 12 }}>
            <Button
              className={classes.filterButton}
              onClick={onOpenFilter}
              disabled={loading}
            >
              <div style={{ opacity: loading ? 0.5 : 1 }}>
                <IconFilter title="" />
              </div>
              {bundle.FILTER_BUTTON_TITLE}
              {!_.isEqual(filterCount, 0) && (
                <>
                  <div className={classes.filterDot}>&middot;</div>
                  <div>{filterCount}</div>
                </>
              )}
            </Button>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
