import React from "react";
import * as _ from "lodash";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Select, { components } from "react-select";
import { CustomStyles } from "../../../Layout/Elements/Dropdown/style";
import style from "../style";
import { SearchFilter } from "../../../Layout/Header/SearchFilter";
import { IOption } from "../../../../../UI/Elements/Forms/interface/IOption";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  handleSearchChange: any;
  loading: boolean;
  searchValue: string;
  onOpenFilter: any;
  searchPlaceholder: string;
  onAccountChange: any;
  recordsCount: number;
  filterCount: number;
  accountDataSource: IOption[];
  statusDataSource: IOption[];
  accountValue: any;
  dropdownPlaceholder: any;
  statusValue: any;
  onStatusChange: any;
};

//Mapping Mapper Grid Header Component
export const MapperGridHeader = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const {
    handleSearchChange,
    loading,
    searchValue,
    onOpenFilter,
    searchPlaceholder,
    onAccountChange,
    recordsCount,
    filterCount,
    accountDataSource,
    statusDataSource,
    accountValue,
    dropdownPlaceholder,
    statusValue,
    onStatusChange
  } = props;

  return (
    <div className={classes.headerContainer}>
      <SearchFilter
        filterCount={filterCount}
        handleSearchChange={handleSearchChange}
        loading={loading}
        onOpenFilter={onOpenFilter}
        searchPlaceholder={searchPlaceholder}
        searchValue={searchValue}
        recordsCount={recordsCount}
        showCustomComponent={true}
        hideFilter={_.isNull(accountValue)}
        leftContainerCols={4}
        middleContainerCols={2}
        isMapper={true}
        statusDataSource={statusDataSource}
        customComponent={
          <Select
            isClearable={false}
            value={accountValue}
            isDisabled={loading}
            components={{
              DropdownIndicator: (data: any) => {
                return (
                  <components.DropdownIndicator
                    {...data}
                    className="caret-select"
                  />
                );
              },
            }}
            styles={CustomStyles(false, false, false)}
            onChange={(selectedOption: any) => {
              onAccountChange(selectedOption);
            }}
            options={accountDataSource}
            placeholder={dropdownPlaceholder}
          />
        }
        statusDropdown={
          <Select
            isClearable={false}
            value={statusValue}
            isDisabled={loading || _.isEqual(statusDataSource.length, 0)}
            components={{
              DropdownIndicator: (data: any) => {//NOSONAR
                return (
                  <components.DropdownIndicator
                    {...data}
                    className="caret-select"
                  />
                );
              },
            }}
            styles={CustomStyles(false, false, false)}
            onChange={(selectedOption: any) => {
              onStatusChange(selectedOption);
            }}
            options={statusDataSource}
            placeholder="Bulk Edit"
          />
        }

      />
    </div>
  );
};
