import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GridTable } from "../Layout/Grid";
import { FORM_TITLE, NOTIFICATION_TYPE, SORT_ORDER } from "../configuration";
import { MappingPage } from "../Layout/Page";
import COLORS from "../../../UI/Theme/colors";
import {
  getFilterFormConfig,
  GRID_HEADERS,
  getFormConfig,
} from "./configuration";

import { SidebarComponent } from "../Layout/Grid/Sidebar";
import { EditFormHelper, NewFormHelper } from "../Layout/Form/Helpers/Forms";
import style from "./style";
import { CircularProgress } from "../../../UI/Progress";
import { IGridFilter } from "../Interfaces/Filter/IGridFilter";
import { SnackbarAlert } from "../../../UI/Snackbar";
import { TabListComponent } from "../Layout/Tab";
import { SearchFilter } from "../Layout/Header/SearchFilter";
import { FilterFormHelper } from "../Layout/Form/Helpers/Forms/Filter";
import { IForm } from "../Interfaces/Form/IForm";
import { ISidebar } from "../Interfaces/Filter/IGridSidebar";
import { UIOutlineButton } from "../../../UI/Buttons";
import { Axios } from "../../../api/service/Axios";
import {
  CREATE_MAPPING_MANAGER,
  GET_MAPPING_CHANNEL_FOR_MANAGER,
  GET_MAPPING_MANAGER_BY_ID,
  GET_MAPPING_MANAGER_DS,
  GET_MAPPING_MANAGER_GRID_DATA,
  UPDATE_MAPPING_MANAGER_RECORD_BY_ID,
} from "../../../api/APIPaths/mapping";
import { catchError, catchSuccessMessage } from "../Utility/ErrorMessage";
import * as ManagerHelper from "./Helper";
import { ITab } from "../Interfaces/Page/ITab";
import { IGridData } from "../Interfaces/Grid/IGridData";
import { IOption } from "../../../UI/Elements/Forms/interface/IOption";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

const DefaultFilter = {
  order: SORT_ORDER.ASC,
  orderBy: "platform",
  pageNumber: 0,
  pageSize: 20,
  filterCount: 0,
};

//Mapping Manager Component
const MappingManager = (): React.JSX.Element => {
  const classes = useStyles();
  const [gridData, setGridData] = useState<IGridData>({
    count: 0,
    data: [],
  });
  const [filter, setFilter] = useState<IGridFilter>({
    sidebarFilter: null,
    ...DefaultFilter,
  });
  const [sidebar, setSidebar] = useState<ISidebar | null>(null);

  const [tab, setTab] = useState<ITab | null>(null);
  const bundle = useSelector((state: any) => state.initial.bundle);

  const [initLoader, setInitLoader] = useState<boolean>(true);
  const [formFilter, setFormFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [gridLoader, setGridLoader] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  const getFilterPayload = (gridFilter: IGridFilter, search: string): any => {
    const filterHelper = new FilterFormHelper();
    return filterHelper.getFilterSubmitData(gridFilter, search || "");
  };

  const getGridData = async (
    gridFilter: IGridFilter,
    selectedTabId: number
  ): Promise<any> => {
    try {
      const FilterData = getFilterPayload(gridFilter, gridFilter.search || "");
      const RESPONSE = await Axios.post(GET_MAPPING_MANAGER_GRID_DATA, {
        ...FilterData,
        channelId: selectedTabId,
      });

      const UPDATED_GRID_DATA = ManagerHelper.formatGridData(
        RESPONSE.data.data || []
      );

      setGridData({
        data: UPDATED_GRID_DATA,
        count: RESPONSE.data.count,
      });
    } catch (err: any) {
      const errorMessage = catchError(
        err,
        bundle.MAPPING_MANAGER_INITIAL_DATA_ERROR_MSG
      );
      setGridData({
        data: [],
        count: 0,
      });
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
    }
  };

  const setAlert = (message: string, variant: NOTIFICATION_TYPE): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const getDefaultFilterForm = async (dataSources: any): Promise<any> => {
    const filterFormHelper = new FilterFormHelper();
    return filterFormHelper.buildFilterForm(
      getFilterFormConfig({}),
      dataSources
    );
  };

  const setFilterDatasource = async (dataSources: any): Promise<any> => {
    const filterFormHelper = new FilterFormHelper();
    return filterFormHelper.buildFilterForm(
      formFilter,
      dataSources
    );
  };

  const getFilterData = async (dataSources: any): Promise<IGridFilter> => {
    const form = await getDefaultFilterForm(dataSources);

    const SidebarForm = {
      ...filter.sidebarFilter,
      dataSources,
      form,
      title: FORM_TITLE.NEW,
      open: false,
    };

    setFilter((prevState: any) => ({
      ...prevState,
      sidebarFilter: SidebarForm,
    }));

    return {
      ...filter,
      sidebarFilter: SidebarForm,
    };
  };

  const getDataSource = async (): Promise<any> => {
    const DataSource = await Axios.get(GET_MAPPING_MANAGER_DS);
    return DataSource.data;
  };

  const getInitData = async (
    dataSources: any,
    gridFilter: IGridFilter,
    selectedTabId: number
  ): Promise<void> => {
    const newFormHelper = new NewFormHelper();
    const form = newFormHelper.buildNewForm(getFormConfig({}), dataSources);
    setSidebar((prevState: any): any => ({
      ...prevState,
      dataSources,
      form,
      title: FORM_TITLE.NEW,
      open: false,
    }));

    await getGridData(gridFilter, selectedTabId);
  };

  const getTabData = async (): Promise<number> => {
    const TabData = await Axios.get(GET_MAPPING_CHANNEL_FOR_MANAGER);
    const SelectedTabId = _.isEmpty(TabData.data) ? 0 : TabData.data[0].value;
    setTab({
      dataSource: TabData.data,
      selectedId: SelectedTabId,
      platform: {
        data: [],
        value: 0,
      },
    });

    return SelectedTabId;
  };

  useEffect(() => {
    const getInitialData = async (): Promise<void> => {
      setInitLoader(true);
      try {
        const DataSources = await getDataSource();
        const GridFilter: IGridFilter = await getFilterData(DataSources);
        const SelectedTabId = await getTabData();

        await getInitData(DataSources, GridFilter, SelectedTabId);

        setInitLoader(false);
      } catch (err: any) {
        const errorMessage = catchError(
          err,
          bundle.MAPPING_MANAGER_INITIAL_DATA_ERROR_MSG
        );
        setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        setInitLoader(false);
      }
    };
    getInitialData();
  }, []);

  const sortHandler = async (
    orderBy: any,
    order: SORT_ORDER
  ): Promise<void> => {
    try {
      setGridLoader(true);

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        order,
        orderBy,
      }));

      const Filter: any = {
        ...filter,
        order,
        orderBy,
      };

      await getGridData(Filter, tab?.selectedId || 0);
      setGridLoader(false);
    } catch (err: any) {
      const errorMessage = catchError(err, "");
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setGridLoader(false);
    }
  };

  const onSearchChange = async (value: string): Promise<void> => {
    try {
      setLoading(true);

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        search: value,
        pageNumber: 0,
      }));

      const Filter: any = {
        ...filter,
        search: value,
        pageNumber: 0,
      };

      await getGridData(Filter, tab?.selectedId || 0);
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, "");
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const setFiltercall =():void=>{
    setFilter((prevState: any) => ({
      ...prevState,
      pageNumber: 0,
      filterCount: 0,
    }));
  };

  const onReset = async (): Promise<void> => {
    try {
      setLoading(true);
      const Filter = await getFilterData(sidebar?.dataSources);
      setFiltercall();

      await getGridData(Filter, tab?.selectedId);
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onCancel = async (): Promise<void> => {
    if (_.gt(filter.filterCount, 0)) {

      const form = await setFilterDatasource(
        sidebar?.dataSources,
      );
      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        sidebarFilter: {
          ...prevState.sidebarFilter,
          form: form,
          open: false,
        },
      }));
    }
    else {
      await getFilterData(sidebar?.dataSources);
      setFiltercall();
    }

  };

  const onApply = async (form: IForm): Promise<void> => {
    try {
      setLoading(true);
      const filterHelper = new FilterFormHelper();
      const Filter: any = {
        ...filter,
        sidebarFilter: {
          ...filter.sidebarFilter,
          form,
        },
        pageNumber: 0,
      };

      const FilterCount = filterHelper.getFilterCount(Filter);

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        filterCount: FilterCount,
        sidebarFilter: {
          ...prevState.sidebarFilter,
          open: false,
          form,
        },
        pageNumber: 0,
      }));
      setFormFilter(form);

      await getGridData(Filter, tab?.selectedId || 0);
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(
        err,
        bundle.MAPPING_ADMIN_CREATE_ERROR_MSG
      );
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onSave = async (form: IForm): Promise<void> => {
    if (_.isNull(sidebar)) {
      return;
    }
    try {
      const SelectedTabId: number = tab?.selectedId || 0;
      const FormData = ManagerHelper.buildSubmitFormData(form, SelectedTabId);
      setLoading(true);

      if (_.isEqual(sidebar.title, FORM_TITLE.NEW)) {
        try {
          const Response = await Axios.post(CREATE_MAPPING_MANAGER, FormData);
          setSidebar((prevState: any): any => ({
            ...prevState,
            open: false,
          }));
          await getGridData(filter, SelectedTabId);

          const SuccessMessage = catchSuccessMessage(
            Response,
            bundle.MAPPING_MANAGER_CREATE_SUCCESS_MSG
          );
          setAlert(
            SuccessMessage,
            Response.data.warning
              ? NOTIFICATION_TYPE.WARNING
              : NOTIFICATION_TYPE.SUCCESS
          );
        } catch (err: any) {
          const errorMessage = catchError(
            err,
            bundle.MAPPING_MANAGER_CREATE_ERROR_MSG
          );
          setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        }
      } else {
        try {
          const MappingUserId: any = sidebar.id;
          const Response = await Axios.put(
            UPDATE_MAPPING_MANAGER_RECORD_BY_ID(MappingUserId),
            FormData
          );
          setSidebar((prevState: any): any => ({
            ...prevState,
            open: false,
          }));
          await getGridData(filter, SelectedTabId);

          const SuccessMessage = catchSuccessMessage(
            Response,
            bundle.MAPPING_MANAGER_UPDATE_SUCCESS_MSG
          );

          setAlert(
            SuccessMessage,
            Response.data.warning
              ? NOTIFICATION_TYPE.WARNING
              : NOTIFICATION_TYPE.SUCCESS
          );
        } catch (err: any) {
          const errorMessage = catchError(
            err,
            bundle.MAPPING_MANAGER_UPDATE_ERROR_MSG
          );
          setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        }
      }

      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, "Error occured");
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const setNewForm = async (): Promise<void> => {
    const newFormHelper = new NewFormHelper();
    const SelectedChannel: any = tab?.dataSource.find((option: IOption) =>
      _.isEqual(option.value, tab.selectedId)
    );

    const form = await newFormHelper.buildNewForm(getFormConfig({}), {
      ...sidebar!.dataSources,
      platforms: _.isUndefined(SelectedChannel)
        ? []
        : SelectedChannel.platforms,
    });

    setSidebar((prevState: any): any => {
      return {
        ...prevState,
        form,
        title: FORM_TITLE.NEW,
        open: true,
      };
    });
  };

  const onTabChange = async (selectedTabValue: any): Promise<void> => {
    try {
      setGridLoader(true);
      const Filter: IGridFilter = {
        ...filter,
        pageNumber: 0,
      };

      setFilter((prevState: any) => ({
        ...prevState,
        pageNumber: 0,
      }));
      setTab((prevState: any) => ({
        ...prevState,
        selectedId: selectedTabValue,
      }));

      await getGridData(Filter, selectedTabValue);
      setGridLoader(false);
    } catch (err: any) {
      const errorMessage = catchError(
        err,
        bundle.MAPPING_ADMIN_CREATE_ERROR_MSG
      );
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setGridLoader(false);
    }
  };

  const onPageChange = async (offset: any, pageNumber: any): Promise<void> => {
    try {
      setGridLoader(true);

      const Filter: IGridFilter = {
        ...filter,
        pageNumber,
      };

      setFilter((prevState: any) => ({
        ...prevState,
        pageNumber,
      }));

      await getGridData(Filter, tab?.selectedId || 0);
      setGridLoader(false);
    } catch (err: any) {
      const errorMessage = catchError(
        err,
        bundle.MAPPING_ADMIN_CREATE_ERROR_MSG
      );
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setGridLoader(false);
    }
  };

  const onEdit = async (id: number): Promise<void> => {
    setLoading(true);

    Axios.get(GET_MAPPING_MANAGER_BY_ID(id))
      .then(async (response: any) => {
        const editFormHelper = new EditFormHelper();
        const FormData = ManagerHelper.buildFormData(response.data);
        const SelectedChannel: any = tab?.dataSource.find((option: IOption) =>
          _.isEqual(option.value, tab.selectedId)
        );
        const form = await editFormHelper.buildEditForm(
          getFormConfig({}),
          {
            ...sidebar!.dataSources,
            platforms: _.isUndefined(SelectedChannel)
              ? []
              : SelectedChannel.platforms,
          },
          FormData
        );

        setSidebar((prevState: ISidebar | null): any => {
          return {
            ...prevState,
            title: FORM_TITLE.EDIT,
            id,
            open: true,
            form,
          };
        });
        setLoading(false);
      })
      .catch((err: string) => {
        const errorMessage = catchError(
          err,
          bundle.MAPPING_MANAGER_GET_CHANNEL_ERROR_MSG
        );
        setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        setLoading(false);
      });
  };

  if (initLoader) {
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title>{bundle.MAPPING_MANAGER_PAGE_TITLE}</title>
      </Helmet>

      <MappingPage
        pageTitle={bundle.MAPPING_MANAGER_PAGE_TITLE}
        recordCount={gridData.count}
        loading={gridLoader}
        hideContent={false}
        tabComponent={
          _.isNull(tab) ? (
            <span />
          ) : (
            <TabListComponent
              handleTabChange={(selectedTabValue: any) => {
                if (!_.isEqual(tab.selectedId, selectedTabValue)) {
                  onTabChange(selectedTabValue);
                }
              }}
              loading={gridLoader}
              pageTitle="Mapping Manager"
              tabData={tab.dataSource}
              value={tab.selectedId}
            />
          )
        }
        enableContentBgColor={true}
        header={
          <div className={classes.headerContainer}>
            <SearchFilter
              filterCount={
                _.isUndefined(filter.filterCount) ? 0 : filter.filterCount
              }
              handleSearchChange={(value: string): void => {
                onSearchChange(value);
              }}
              hideFilter={false}
              loading={gridLoader}
              onOpenFilter={() => {
                setFilter((prevState: IGridFilter): any => ({
                  ...prevState,
                  sidebarFilter: {
                    ...prevState.sidebarFilter,
                    open: true,
                  },
                }));
              }}
              leftContainerCols={4}
              middleContainerCols={0}
              searchPlaceholder={"Search by Instructions"}
              searchValue={filter.search || ""}
              recordsCount={gridData.count}
            />
            <UIOutlineButton
              cssStyle={{
                backgroundColor: COLORS.WHITE,
                marginLeft: 12,
              }}
              isOutline={true}
              disabled={gridLoader}
              onClick={() => setNewForm()}
              btnText="Add"
            />
          </div>
        }
        footer={
          _.isEqual(gridData.data, 0) || gridLoader ? (
            <span />
          ) : (
            <div className={classes.footerContainer}>
              <TablePagination
                component="div"
                count={gridData.count}
                labelRowsPerPage=""
                rowsPerPage={filter.pageSize}
                page={filter.pageNumber}
                onPageChange={onPageChange}
              />
            </div>
          )
        }
      >
        <GridTable
          filter={filter}
          onEdit={onEdit}
          headers={GRID_HEADERS}
          gridData={gridData.data}
          sortHandler={sortHandler}
          loading={gridLoader}
          isGridAction={true}
          loadHeader={false}
        />
        {!_.isNull(filter.sidebarFilter) && (
          <SidebarComponent
            width={424}
            formTitle={"Filters"}
            btnSaveText="Apply"
            btnCancelText="Cancel"
            onSave={onApply}
            onReset={onReset}
            onCancel={onCancel}
            formData={filter.sidebarFilter!.form}
            open={filter.sidebarFilter!.open}
            isFilterForm={true}
          />
        )}
        {!_.isNull(sidebar) && (
          <SidebarComponent
            width={624}
            formTitle={sidebar.title}
            btnSaveText="Save"
            btnCancelText="Cancel"
            onSave={onSave}
            onCancel={() => {
              setSidebar((prevState: ISidebar | null): any => ({
                ...prevState,
                open: false,
              }));
            }}
            formData={sidebar.form}
            open={sidebar.open}
            isFilterForm={false}
          />
        )}
        {loading && <CircularProgress />}
        <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
        />
      </MappingPage>
    </>
  );
};

export default MappingManager;
