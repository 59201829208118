

// Colors

const HoverColor = "rgb(134, 142, 150)";

const FocusColor = "rgb(58, 145, 232)";

const BorderColor = "rgb(208, 213, 218)";

const BackgroundColor = "rgba(255, 255, 255, 0)";

const DisabledColor = "rgb(208, 213, 218)";
const DisabledFontColor = "#000000";

const ErrorColor = "rgb(230, 93, 99)";

const ErrorBackgroundColor = "#FFF2F2";

const DisabledBackgrounColor = "#FBFCFD";

export default {
	HoverColor,
	FocusColor,
	DisabledColor,
	DisabledFontColor,
	ErrorColor,
	ErrorBackgroundColor,
	DisabledBackgrounColor,
	BorderColor,
	BackgroundColor
};