import React, { useEffect } from "react";
import { CircularProgress } from "../../UI/Progress";
import { useHistory } from "react-router-dom";
const TiktokPoupHandler = (): React.JSX.Element => {

    const history = useHistory();
    useEffect(()=>{
        const QueryParams = new URLSearchParams(location.search);
        const AuthCode: any  = QueryParams.get("auth_code");
        const State: any  = QueryParams.get("state");
        const CustomQueryParams = decodeURIComponent(State);
        localStorage.setItem("TIKTOK_AUTH_CODE", AuthCode);
        history.push(`/tool${CustomQueryParams}`);
    }, []);

    return <CircularProgress />;
};

export default TiktokPoupHandler;