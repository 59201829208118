import React from "react";
import { errorHandlerSync } from "../helpers/ErrorHandler.component";
import Input from "./index";

// Create Input form
export const createInputForm = (
  form: any,
  inputHandler: any
): JSX.Element[] => {
  const FORM_ELEMENTS = [];
  for (const key in form) {
    FORM_ELEMENTS.push({
      id: key,
      config: form[key],
    });
  }
 
  return FORM_ELEMENTS.map((formElement) => (
    <Input
      key={formElement.id}
      handleChange={errorHandlerSync((event: any, newValue: any) =>
        inputHandler(event, newValue, formElement.id)
      )}
      form={form}
      fieldConfig={formElement.config}
    />
  ));
};
