import React from "react";
import Snackbar from "./SnackbarAlert.component";

export const SnackbarAlert = Snackbar;
export const SuccessAlert = (props: any): JSX.Element => (
  <SnackbarAlert {...props} variant="success" />
);
export const ErrorAlert = (props: any): JSX.Element => (
  <SnackbarAlert {...props} variant="error" />
);
export const InfoAlert = (props: any): JSX.Element => (
  <SnackbarAlert {...props} variant="info" />
);
export const WarningAlert = (props: any): JSX.Element => (
  <SnackbarAlert {...props} variant="warning" />
);
