import COLORS from "../../../../../UI/Theme/colors";
import {Theme} from "@material-ui/core"; 

const style: any = (theme:Theme): any => ({
    gridContainer: {
        padding: "20px 0 20px 0",
        margin:"auto",     
        
    },
    container: {
        width:"100%",
        display: "flex",
        justifyContent: "space-between",

    },
    instructions: {
		fontSize: 12,
		fontWeight: theme.typography.fontWeightRegular,
		color: COLORS.GRAY,
	},
    multiDataWidth: {
        minWidth: "40px",
        maxWidth: "210px",
    },
    regionDataWidth:{
        minWidth: "40px",
        maxWidth: "100px",
    }
});

export default style;