

import GlobalColors from "../../../colors";
import Colors from "../../../../../../../../UI/Elements/utilstyle";
export const CustomStyles = (is_Error: boolean, disabled: boolean, IsHighlightOptionError: boolean): any => ({
	indicatorsContainer: function (provided: any, state: any) {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		let fontColor = MenuIsOpen	? Colors.ErrorColor: Colors.HoverColor;

		if (disabled) {
			fontColor = Colors.DisabledFontColor;
		}

		let bgColor = is_Error && !disabled	? Colors.ErrorBackgroundColor: provided.backgroundColor;

		if (IsHighlightOptionError) {
			bgColor = GlobalColors.WHITE;
		}
		return {
			...provided,
			backgroundColor: bgColor,
			minHeight: 38,
			"& div:nth-of-type(1):not(.caret-select)": {
				padding: 0,
				color: fontColor,
				"&:hover": {
					color: fontColor,
				},
			},
		};
	},
	dropdownIndicator: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		let fontColor = MenuIsOpen	? Colors.FocusColor	: Colors.HoverColor;

		if (disabled) {
			fontColor = Colors.DisabledColor;
		}
		return {
			...provided,
			"&:hover": {
				color: fontColor,
			},
			color: fontColor,
			transform: MenuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
		};
	},
	indicatorSeparator: (provided: any) => ({
		...provided,
		visibility: "hidden",
	}),
	multiValue: (provided: any, state: any) => {
		return {
			...provided,
			"& > div": {
				color: state.data.highlightOption ? "#b82533" : "",
				backgroundColor: state.data.highlightOption ? "#fff2f2" : "",
				fontSize: "100%",
			},
			"& > div:nth-of-type(2n)": {
				"&:hover": {
					color: Colors.ErrorColor,
					backgroundColor: state.data.highlightOption? "#fff2f2": provided.backgroundColor,
				},
			},
		};
	},
	valueContainer: (provided: any) => {
		let bgColor = is_Error && !disabled ? Colors.ErrorBackgroundColor : "";
		if (IsHighlightOptionError) {
			bgColor = GlobalColors.WHITE;
		}
		return ({
			...provided,
			paddingLeft: 10,
			paddingTop: 1,
			padding: 0,
			backgroundColor: bgColor,
		});
	},
	input: (provided: any) => ({
		...provided,
		margin: 0,
		padding: 0,
	}),
	placeholder: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		return {
			...provided,
			fontSize: "0.875rem",
			color: MenuIsOpen ? Colors.FocusColor : "#a9a9a9",
		};
	},
	control: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;

		let bgColor = is_Error	? Colors.ErrorBackgroundColor: Colors.BackgroundColor;

		if (disabled) {
			bgColor = Colors.DisabledBackgrounColor;
		}

		if (IsHighlightOptionError) {
			bgColor = GlobalColors.WHITE;
		}

		let borderColor = "";

		if (disabled) {
			borderColor = Colors.DisabledColor;
		}
		else if (is_Error) {
			borderColor = Colors.ErrorColor;
		} else {
			borderColor = MenuIsOpen ? Colors.FocusColor : Colors.BorderColor;
		}

		let hoverBorderColor = "";

		if (is_Error && !disabled) {
			hoverBorderColor = Colors.ErrorColor;
		} else {
			hoverBorderColor = MenuIsOpen ? Colors.FocusColor : Colors.HoverColor;
		}

		return {
			...provided,
			fontSize: "0.875rem",
			width: "140px !important",
			minHeight: 40,
			boxShadow: "none",
			border: `1px solid ${borderColor} !important`,
			borderRadius: 2,
			backgroundColor: bgColor,
			"&:hover": {
				border: `1px solid ${hoverBorderColor} !important`,
			},
		};
	},
	option: (provided: any) => {
		return {
			...provided,
			"&:hover": {
				backgroundColor: `1px solid ${Colors.HoverColor}`,
			},
		};
	},
	singleValue: (provided: any, state: any) => {
		const transition = "opacity 300ms";
		const opacity = state.isDisabled ? 0.5 : 1;
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		let fontColor = "";

		if (state.data.highlightOption) {
			fontColor = "#b82533";
		} else if (MenuIsOpen) {
			fontColor = Colors.FocusColor;
		} else {
			fontColor = disabled ? Colors.DisabledFontColor : provided.color;
		}

		return {
			...provided,
			transition,
			paddingTop: 2,
			opacity,
			color: fontColor,
		};
	},
	menu: (provided: any) => {
		return {
			...provided,
			fontSize: "0.875rem",
			margin: 0,
			zIndex: 2,
			padding: 0,
		};
	},
	container: (provided: any) => {
		return {
			...provided,
			width: "140px !important",
			backgroundColor: GlobalColors.WHITE,
			minHeight: 40
		};
	},
});
