import React, { useEffect, useState } from "react";
import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import * as _ from "lodash";
import classNames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CustomStyles, style } from "./style";
import { createOption, getErrorMessage, isCloseMenuOnSelect } from "./Utility";
import { IOption } from "../../Forms/interface/IOption";
import { IFormElement } from "../../Forms/interface/IFormElement";
// import FormHelper from "../../../../helpers/FormHelper";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import { EditForm } from "../../Forms/helper/Forms/EditForm";

type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

const useStyles = makeStyles(() => createStyles(style()));

export const UICreatableSelect = (props: props): JSX.Element => {
  const { formElement, formId, formGroupId, onChange } = props;
  const classes = useStyles();
  const ElementValue = formElement.value || null;
  const [selectValue, setSelectValue] = useState(ElementValue);

  const errorMessage = getErrorMessage(formElement);
  const IsError = !_.isEqual(errorMessage.length, 0);
  const Disabled = formElement.disabled || formElement.read_only;

  const IsHighlightOptionError = !_.isEqual(
    (formElement.highlight_option_error || "").length,
    0
  );

  useEffect(() => {
    setSelectValue(ElementValue);
  }, [JSON.stringify(ElementValue)]);

  const onSelectChange = (selectOption: any): void => {
    const editFormHelper = new EditForm();
    const SelectValue = editFormHelper.updateSelectValue(
      formElement,
      selectOption
    );
    setSelectValue(SelectValue);
    onChange({
      formId,
      formGroupId,
      formElement,
      value: SelectValue,
    });
  };

  return (
    <div
      className={classNames({
        [classes.sortingContainer]: formElement.enable_sorting_option,
      })}
    >
      {!formElement.enable_sorting_option ? (
        <UILabel formElement={formElement} />
      ) : (
        <div className={classes.sortingLabel}>
          <UILabel formElement={formElement} />
          <div className={classes.defaultHeightPlaceHolder}></div>
        </div>
      )}
      <div
        className={classNames({
          [classes.width100]: formElement.enable_sorting_option,
        })}
      >
        <span className={classes.width100}>
          <CreatableSelect
            closeMenuOnSelect={isCloseMenuOnSelect(formElement)}
            isClearable={!formElement.is_required}
            isDisabled={Disabled}
            onChange={(selectedOption: any) => {
              return onSelectChange(selectedOption);
            }}
            isMulti={formElement.is_multi}
            onCreateOption={(inputValue: string) => {
              const InputValue = (inputValue || "")
                .toString()
                .trim()
                .toLowerCase();

              if (_.isEmpty(InputValue)) {
                return;
              }
              const IsExists = formElement.dataSource.find(
                (option: IOption) => {
                  const OptionValue = (option.value || "")
                    .toString()
                    .trim()
                    .toLowerCase();

                  return _.isEqual(OptionValue, InputValue);
                }
              );

              if (_.isUndefined(IsExists)) {
                const NewOption = createOption(inputValue);

                let elementValue: any = NewOption;

                if (formElement.is_multi) {
                  elementValue = _.isNull(ElementValue)
                    ? []
                    : [...ElementValue, NewOption];
                }
                onChange({
                  formId,
                  formGroupId,
                  formElement,
                  value: elementValue,
                  newOption: NewOption,
                });
              }
            }}
            onBlur={(): void => {
              return onSelectChange(selectValue);
            }}
            placeholder={formElement.placeholder}
            options={
              _.isArray(formElement.dataSource) ? formElement.dataSource : []
            }
            value={selectValue}
            styles={CustomStyles(IsError, Disabled, IsHighlightOptionError)}
            components={{
              DropdownIndicator: (data: any) => {
                return (
                  <components.DropdownIndicator
                    {...data}
                    className="caret-select"
                  />
                );
              },
            }}
          />
        </span>
        <UIErrorOrNoteMessage
          formElement={formElement}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
};
