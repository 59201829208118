import COLORS from "../../Theme/colors";

const style: any = (): any => {
	return {
		fileErrorContainer:{
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			marginBottom:"0.5rem",
			flex:1,
			marginTop:8,
		},
		filInfo:{
			display:"flex",
			alignItems:"center",
			whiteSpace:"nowrap",
			flex:1,
		},
		fileIcon:{
			display:"flex",
			alignItems:"center",
			width:20,
		},
		fileName:{
			fontSize:"0.875rem",
			color: COLORS.DARK_GRAY,
		},
		note:{
			minHeight: "1.25rem",
			marginTop:"0.25rem",
			fontSize:"0.75rem",
			color:"#6a737c",
			"word-break":"break-all",
			"font-weight":"normal",
			width:"100%",
			"& ul, ol":{
				paddingLeft: "1.5rem",
				marginTop: "0.25rem"
			},
			"& a":{
				color:"#005fbe",
				textDecoration:"none"
			}
		}
	};
};

export default style;
