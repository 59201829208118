import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../../UI/Theme/colors";

const style: any = (theme: Theme) => ({
	table: {
		width: "100%",
		tableLayout:"fixed"
	},
	tableWrapper: {
		backgroundColor: COLORS.WHITE,
		marginTop:"0.5rem"
	},
	headerTitle:{
		fontSize:"0.75rem",
		color:COLORS.GRAY,
		fontWeight:"bold"
	},
	tableRow: {
		padding: "14px 40px 14px 0px",
		"&:hover":{
			backgroundColor:"#F0F8FF"
		}
	},
	tableCell: {
		padding: "14px 0px 13px",
		fontSize: "0.875rem",
		textOverflow: "ellipsis",
		overflow:"hidden",
		whiteSpace:"nowrap",
		"&:not(:first-child)":{
			padding:"14px 10px 13px",
		}
	},
	tableCellContent:{
		fontSize:"0.875rem",
		color:COLORS.DARK_GRAY,
		fontWeight: "normal",
		opacity:"0.9"
	},
	gradient: {
		backgroundImage: "linear-gradient(90deg, #F3F5F7 0px, #F7F8FA 40px, #F3F5F7 80px)",
		backgroundSize: 600,
		animation: "shine-lines 1.6s infinite linear",
		backgroundColor: COLORS.BASECOLOR
	},
	headers: {
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
		color: COLORS.GRAY,
		padding: "11px 0px",
		borderBottom:"2px solid rgba(224, 224, 224, 1)",
		"&:not(:first-child)":{
			padding:"11px 10px",
		}
	},
	previewHeaderContainer:{
		display:"flex",
		alignItems:"center",
		justifyContent: "space-between"
	},
	previewHeader:{
		display:"flex",
		alignItems:"center",
	},
	previewLabel:{
		fontSize: "1rem",
		color: COLORS.BLACK,
		fontWeight: "bold",
		display:"flex",
		alignItems:"center",
	},
	previewIcon:{
		marginLeft:"0.25rem"
	},
	previewLinks:{
		fontSize:"0.875rem",
		color: COLORS.DARK_BLUE,
		fontWeight:600,
		cursor:"pointer",
		marginBottom:"0.5rem"
	},
	editLink:{
		marginLeft:"1rem"
	},
	errorMessage:{
		backgroundColor: COLORS.LIGHT_RED,
		width: 266,
    	fontSize: "0.75rem",
		marginTop: 8,
		padding: "10px 0",
		display:"flex",
		alignItems:"center"
	},
	errorIcon:{
		margin: "0 12px",
		display:"flex",
		alignItems:"center"
	},
	noDataAvailable: {
		color: "#6a737c",
		fontSize: 16,
		fontWeight: 600,
		marginRight: 6,
		textAlign: "center",
		padding: 12,
		height: 94,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%"
	}
});

export default style;
