import {
	GET_FILTER_OPTIONS_REQUEST,
	GET_FILTER_OPTIONS_SUCCESS,
	GET_FILTER_OPTIONS_FAILED
} from "../types";
import {
	updateObject
} from "../../helpers/Utility";
import { IReduxFilter } from "./interfaces/IReduxFilter";

// Initial State for Filter Options reducer
const initialState: IReduxFilter = {
	filterOptions: [],
	loading: true,
	error: false
};

// Filter Options request to server
const getFilterOptionsRequest = (state: any) : any => updateObject(state, {
	loading: true,
	error: false
});

// Get server response
const getFilterOptionsSuccess = (state: any, action: any) : any => updateObject(state, {
	filterOptions: action.payload,
	loading: false,
	error: false
});

// Server error
const getFilterOptionsFailed = (state: any) : any => updateObject(state, {
	filterOptions:{
		type: [],
		status:[],
		channels:[],
		platforms:[]
	},
	loading: false,
	error: true
});

// Filter Options reducer
export default (state = initialState, action: any={}) : any => {
	let updatedState = {
		...state
	};
	switch (action.type) {
	case GET_FILTER_OPTIONS_REQUEST:  
		updatedState = getFilterOptionsRequest(state);
		break;
	case GET_FILTER_OPTIONS_SUCCESS: 
		updatedState = getFilterOptionsSuccess(state, action);
		break;
	case GET_FILTER_OPTIONS_FAILED: 
		updatedState =getFilterOptionsFailed(state);
		break;
	}
	return updatedState;
};