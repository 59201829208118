import React, { Fragment, useState, useEffect, useRef } from "react";
import { CircularProgress, Input } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import classNames from "classnames";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import style from "./style";
import commonStyle from "../sharedStyle";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UIBucketUploadAlert } from "../UploadFile/BucketUpload/Dialog";
import { IDialog } from "../UploadFile/BucketUpload/IDialog";
import Utility from "./Utility";
import { IFileUpload } from "./Interface/IFileUpload";
import { IFileUploadResponse } from "./Interface/IFileUploadResponse";
import { GoogleUtilHelper } from "../../Forms/helper/Utils/FileUtil/GoogleUtil";
import { ValidationHelper } from "../../Forms/helper/Forms/Validation";
import { UIOutlineButton } from "../../../Buttons";
import { ImageTextHelper } from "../../Forms/helper/Forms/BuildForm/ImageText";
import { UIImageTextReadOnlyField } from "./ReadOnlyInput";
import { FileHelper } from "../../Forms/helper/Utils/FileUtil";
import { IconPlaceholer } from "./IconPlaceholder";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));
const validationHelper = new ValidationHelper();
const fileHelper = new FileHelper();

const BUCKET_NAME: string =
  process.env.REACT_APP_TOOL_PUBLIC_FILES_UPLOAD_GOOGLE_BUCKET || "";

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  onValidateForms: any;
  formGroupId: number;
};

// UIImageTextField Component
export const UIImageTextField = (props: props): JSX.Element => {
  const { formElement, onValidateForms, formId, formGroupId, onChange } = props;
  const Value = formElement.value || "";
  const [dialog, setDialog] = useState<IDialog>({
    open: false,
    type: "replace",
    file: null,
  });

  const fileInputRef: any = useRef(null);
  const [input, setInput] = useState(Value.text);

  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const UploadLoader = _.isNull(formElement.value)
    ? false
    : formElement.loading;
  let Disabled = formElement.disabled || formElement.read_only;

  let Props = {};

  if (formElement.is_multi) {
    Props = {
      ...Props,
      rows: 5,
      multiline: true,
    };
  }

  //   Component initial call
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!_.isEqual(Value.text, input)) {
        onChange({
          formId,
          formGroupId,
          formElement,
          value: {
            ...formElement.value,
            text: input,
            touched: {
              ...formElement.value.touched,
              text: true,
            },
          },
        });
      }
    }, 600);
    return () => clearTimeout(timeoutId);
  }, [input]);

  //   Update text box value based on parent props change
  useEffect(() => {
    setInput(Value.text);
  }, [JSON.stringify(Value)]);

  if (formElement.hideBasedOnParent) {
    return <></>;
  }
  if (Disabled) {
    return <UIImageTextReadOnlyField formElement={formElement} />;
  }
  Disabled = UploadLoader;

  const updateInputData = (isLoading: boolean, data: any): void => {
    Utility.handleOnChange(
      props,
      {
        text: input,
        file: data,
        touched: {
          ...formElement.value.touched,
          file: true,
        },
      },
      isLoading
    );
  };

  const startUpload = async (fileInfo: IFileUpload): Promise<any> => {
    const googleUtil = new GoogleUtilHelper();
    let uploadSessionURI: any = null;

    try {
      const { file, uniqueFileName } = fileInfo;
      const AccessToken: any = await googleUtil.getSAAccessToken();

      if (_.isNull(AccessToken)) {
        return {
          error: true,
          publicURL: null,
          uploadSessionURI: null,
        };
      }

      const FileExtension: any = file.name.split(".").pop();

      uploadSessionURI = await googleUtil.getUploadSessionURI(
        BUCKET_NAME,
        AccessToken,
        uniqueFileName,
        FileExtension
      );

      if (_.isNull(uploadSessionURI)) {
        return {
          error: true,
          publicURL: null,
          uploadSessionURI: null,
        };
      }

      await Utility.uploadFileToBucket(uploadSessionURI, file);

      return await Utility.generatePublicURL(
        uniqueFileName,
        uploadSessionURI,
        BUCKET_NAME
      );
    } catch (err) {
      return {
        error: true,
        publicURL: null,
        uploadSessionURI,
      };
    }
  };

  const getValidUploadFile = async (file: IFileUpload): Promise<any> => {
    let dataFile = null;

    if (file.isValidForUpload) {
      const UploadResult: IFileUploadResponse = await startUpload(file);

      dataFile = {
        ...file,
        publicURL: !_.isNull(UploadResult) ? UploadResult.publicURL : null,
        status: UploadResult.error ? "error" : "success",
        uploadSessionURI: !_.isNull(UploadResult)
          ? UploadResult.uploadSessionURI
          : null,
      };
    }
    return dataFile;
  };

  const handleFileInputChange = async (event: any): Promise<void> => {
    const Files = Array.from(event.target.files);

    if (_.isEqual(Files.length, 0)) {
      return;
    }
    const File: IFileUpload = Utility.validateFile(formElement, Files[0]);

    updateInputData(File.isValidForUpload, File);
    if (File.isValidForUpload) {
      const DATA_FILE = await getValidUploadFile(File);
      updateInputData(false, DATA_FILE);
    }
  };

  const deleteFile = (file: IFileUpload | null): void => {
    if (_.isNull(file)) {
      return;
    }
    const googleUtil = new GoogleUtilHelper();
    googleUtil.deleteFileFromBucket(file.uniqueFileName, BUCKET_NAME);
  };

  const onDialogSubmit = (): void => {
    if (_.isEqual(dialog.type, "delete")) {
      const TextInput = formElement.value.text || "";
      Utility.handleOnChange(
        props,
        {
          ...ImageTextHelper.DEFAULT_VALUE,
          text: TextInput,
        },
        false
      );
    } else {
      fileInputRef.current.click();
    }
    deleteFile(dialog?.file);
    setDialog((prevState) => ({
      ...prevState,
      open: false,
      file: null,
    }));
  };

  const notImageFile = (): boolean => {
    if (!_.isNull(formElement.value)) {
      return _.isNull(formElement.value.file);
    }
    return true;
  };

  const IfNotImageFile = notImageFile();
  let imageUrl = null;
  if (!IfNotImageFile) {
    imageUrl = !_.isNull(formElement.value.file.publicURL)
      ? formElement.value.file.publicURL
      : null;
  }

  const isValidImageInput = (errorTypes: string[]): any => {
    let obj = {
      file: true,
      text: true,
    };
    if (!_.isEqual(errorTypes.indexOf("both"), -1)) {
      obj = {
        file: false,
        text: false,
      };
    }
    if (!_.isEqual(errorTypes.indexOf("file"), -1)) {
      obj = {
        ...obj,
        file: false,
      };
    }
    if (!_.isEqual(errorTypes.indexOf("text"), -1)) {
      obj = {
        ...obj,
        text: false,
      };
    }
    return obj;
  };

  const onRetry = async (): Promise<void> => {
    const File = formElement.value.file;
    updateInputData(File.isValidForUpload, {
      ...File,
      status: null,
      publicURL: null,
    });
    const DATA_FILE = await getValidUploadFile({
      ...File,
      status: null,
      publicURL: null,
    });
    updateInputData(false, DATA_FILE);
  };

  const Touched = formElement.value.touched;
  const { ErrorTypes, error } = validationHelper.validateImageInput(
    formElement,
    formElement.value
  );
  const IsValidImageInput = isValidImageInput(ErrorTypes);

  let errorMessage = "";

  if (formElement.apiError) {
    errorMessage = formElement.apiError;
  } else {
    errorMessage = formElement.touched ? error : "";
  }

  if (Disabled && _.isEmpty(formElement.apiError)) {
    errorMessage = "";
  }

  let fileHasError = false;
  let isValidImage = false;

  if (!_.isNull(formElement.value)) {
    const File = formElement.value.file;
    fileHasError = _.isNull(File) ? false : _.isEqual(File.status, "error");
  }

  if (!_.isNull(formElement.value)) {
    const File = formElement.value.file;
    isValidImage = _.isNull(File)
      ? false
      : File.isValidForUpload && !_.isEqual(File.status, "error");
  }

  let fileSize = fileHelper.getMaxFileSize(formElement);
  fileSize = _.isNull(fileSize) ? "" : `UNDER ${fileSize}`;
  let fileType = fileHelper.getRequiredFileType(formElement);
  fileType = _.isNull(fileType) ? "" : fileType;

  let imagePlaceholder = <IconPlaceholer />;
  if (!_.isNull(imageUrl) && !UploadLoader && !fileHasError) {
    imagePlaceholder = <img src={imageUrl} className={classes.icon} />;
  }

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <div className={classes.container}>
        <div className={classes.thumbnail}>
          <input
            type="file"
            onChange={handleFileInputChange}
            onClick={() => (fileInputRef.current.value = null)}
            ref={fileInputRef}
            style={{ display: "none" }}
            multiple={false}
            accept={formElement.allowed_file_type}
          />
          <div className={classes.imageContainer}>
            {UploadLoader && (
              <div className={classes.loaderContainer}>
                <CircularProgress className={classes.loader} />
              </div>
            )}
            {imagePlaceholder}
          </div>
        </div>
        <div className={classes.fileSelection}>
          <div className={classes.uploadBtnContainer}>
            <UIOutlineButton
              isOutline={true}
              onClick={() => {
                if (UploadLoader || Disabled) {
                  return;
                }
                if (
                  !IfNotImageFile &&
                  !fileHasError &&
                  formElement.value.file.isValidForUpload
                ) {
                  setDialog({
                    open: true,
                    type: "replace",
                    file: formElement.value.file,
                  });
                  return;
                }
                onChange({
                  formId,
                  formGroupId,
                  formElement,
                  value: {
                    ...formElement.value,
                    text: input,
                    touched: {
                      ...formElement.value.touched,
                      file: true,
                    },
                  },
                });
                fileInputRef.current.click();
              }}
              cssStyle={{
                fontWeight: 600,
              }}
              disabled={UploadLoader}
              btnText={"Upload"}
            />
            {!IfNotImageFile && !fileHasError && isValidImage && !UploadLoader && (
              <div
                className={classes.fileAction}
                onClick={() => {
                  if (
                    !IfNotImageFile &&
                    !fileHasError &&
                    formElement.value.file.isValidForUpload
                  ) {
                    setDialog({
                      open: true,
                      type: "delete",
                      file: formElement.value.file,
                    });
                    return;
                  }
                }}
              >
                Delete
              </div>
            )}
            {fileHasError && (
              <div className={classes.fileAction} onClick={onRetry}>
                Retry
              </div>
            )}
          </div>
          <div className={classes.note}>
            {fileType}&nbsp;{fileSize}
          </div>
        </div>
      </div>
      <div>
        <Input
          type="text"
          {...Props}
          disabled={Disabled}
          id={`${formElement.id}`}
          className={classes.inputContainer}
          style={{
            height: formElement.is_multi ? "auto" : 36,
          }}
          onBlur={() =>
            onValidateForms({
              formId,
              formGroupId,
              formElement,
              value: {
                ...formElement.value,
                text: input,
                touched: {
                  ...formElement.value.touched,
                  text: true,
                },
              },
            })
          }
          value={input}
          placeholder={formElement.placeholder}
          onChange={(event: any): void => {
            const InputValue = event.target.value;
            setInput(InputValue);
          }}
          inputProps={{
            style: {
              height: formElement.is_multi ? "auto" : 36,
            },
            className: classNames(classes.input, {
              [commonClasses.disabled]: Disabled,
              [classes.inputElement]:
                !Disabled && _.isEqual(errorMessage.length, 0),
              [commonClasses.error]:
                !_.isEqual(errorMessage.length, 0) &&
                Touched.text &&
                !IsValidImageInput.text,
            }),
          }}
        />
      </div>
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={errorMessage}
      />
      {dialog.open && (
        <UIBucketUploadAlert
          fileTypeName="image"
          onClose={() =>
            setDialog({
              open: false,
              type: "replace",
              file: null,
            })
          }
          onSubmit={(): void => {
            onDialogSubmit();
          }}
          files={IfNotImageFile ? [] : [formElement.value.file]}
          type={dialog.type}
        />
      )}
    </Fragment>
  );
};

// Default Props
UIImageTextField.defaultProps = {
  formElement: {},
};
