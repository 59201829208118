
const style: any = (): any => ({
	headerContainer: {
		display: "flex",
		width: "100%"
	},
	footerContainer: {
		display: "flex",
		flexDirection: "row-reverse",
		width: "100%"
	},

});

export default style;