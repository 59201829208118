import React from "react";
import classNames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { style } from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
    formElement: IFormElement;
};

export const UILoadingCells = (props: props): JSX.Element => {
    const { formElement } = props;
    const errorMessage = "";
    const classes = useStyles();

    const Element = {
        ...formElement,
        note: ""
    };

    return (
        <div
            className={classNames({
                [classes.sortingContainer]: formElement.enable_sorting_option,
            })}
        >
            <span className={classNames(classes.gradient, classes.loadingCellLabel)}> </span>
            <div
                className={classNames({
                    [classes.width100]: formElement.enable_sorting_option,
                })}
            >
                <span className={classes.width100}>
                    <span className={classNames(classes.gradient, classes.loadingCellSelect)}> </span>
                </span>
                <UIErrorOrNoteMessage
                    formElement={Element}
                    errorMessage={errorMessage}
                />
            </div>

        </div>
    );
};

// Default Props
UILoadingCells.defaultProps = {
    formElement: {},
};
