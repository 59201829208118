import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { UITooltip } from "../Tooltip";
import style from "../../../../../../UI/Elements/FormElements/Child/Label/style";
import commonStyle from "../../../../../../UI/Elements/FormElements/sharedStyle";

import { IFormElement } from "../../../../Interface/FormElement/IFormElement";
import { FileHelper } from "../../../../../../UI/Elements/Forms/helper/Utils/FileUtil";
import { FORM_ELEMENT_TYPES } from "../../../ElementTypes";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const utilStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  formElement: IFormElement;
};

const fileHelper = new FileHelper();

// UILabel Component
export const UILabel = (props: props): React.JSX.Element => {
  const { formElement } = props;
  const classes = useStyles();
  const utilClasses = utilStyles();

  let allowedFileSize = "";
  let allowedFileType = "";

  if (!_.isUndefined(formElement.configuration.file)) {
    const MaxSize: any = formElement.configuration.file.maxSize;
    allowedFileSize = `Allowed file size: ${fileHelper.formatBytes(MaxSize)}`;
  }

  if (
    !_.isUndefined(formElement.configuration.file) &&
    !_.isEqual(formElement.configuration.file.allowedFileTypes.length, 0)
  ) {
    allowedFileType = `Allowed file type: ${formElement.configuration.file.allowedFileTypes}`;
  }

  return (
    <label htmlFor={`${formElement.id}`} className={utilClasses.elementLabel}>
      {formElement.configuration.title}
      <UITooltip title={formElement.configuration.tooltip || ""} />
      {!formElement.validations.required &&
        !formElement.configuration.hideOptionalText && (
          <span className={classes.optional}>Optional</span>
        )}

      {_.isEqual(formElement.type, FORM_ELEMENT_TYPES.FILE_UPLOAD) &&
        (!_.isEmpty(allowedFileSize) || !_.isEmpty(allowedFileType)) && (
          <span className={classes.note}>{`${allowedFileType}${
            _.isEmpty(allowedFileType) ? "" : ", "
          }${allowedFileSize}`}</span>
        )}
    </label>
  );
};

export default UILabel;
