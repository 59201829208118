import React, { MouseEventHandler } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Button } from "@material-ui/core";
import { UIOutlineButton } from "../../../../Buttons";
import style from "./style";
import { IFormElement } from "../../../Forms/interface/IFormElement";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onCancel: MouseEventHandler;
  formElement: IFormElement;
  dataGrid: any;
  onSaveChanges: any;
  isValidGrid: boolean;
  forwardRef: any;
};

export const DataGridDialogHead = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onSaveChanges, onCancel, dataGrid, isValidGrid, forwardRef } = props;

  return (
    <div className={classes.dialogHeaderContainer} ref={forwardRef}>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        alignItems="center"
        justifyContent="space-between"
        className={classes.dialogHeader}
      >
        <h1 className={classes.heading}>Manual Inputs</h1>
        <div className={classes.toolContent}>
          <UIOutlineButton
            cssStyle={{
              marginRight: "0.75rem",
            }}
            isOutline={false}
            onClick={onCancel}
            btnText="Cancel"
          />
          <Button
            variant="contained"
            color="primary"
            disabled={!isValidGrid}
            onClick={() => onSaveChanges(dataGrid.rowData)}
          >
            Save Changes
          </Button>
        </div>
      </Grid>
    </div>
  );
};
