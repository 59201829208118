import { Axios } from "./service/Axios";
import * as _ from "lodash";
import { addError, removeError } from "../store/actions/Error";

// GET Call API Service
export const getResource = (
  dispatch: any,
  apiPath: string,
  successFunc: any,
  errorFunc: any
): any => {
  // Remove existing error
  dispatch(removeError());

  return Axios
    .get(apiPath)
    .then((response) => {
      return successFunc(response);
    })
    .catch((error) => {
      errorFunc(error);

      // Message is not undefined
      if (!_.isUndefined(error.response)) {
        if (!_.isUndefined(error.response.data.message)) {
          return dispatch(addError(error.response.data.message));
        }
        return dispatch(addError(error.message));
      }

      // Show Error (Error Message)
      return dispatch(addError(error.message));
    });
};
