import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import {
  Grid,
  Container,
  Theme,
  Button,
  Box,
  Tooltip
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Header } from "./Header";
import { USER_PROFILE_GET_DATA } from "../../api/paths";
import { Axios } from "../../api/service/Axios";
import { IForm } from "./Interface/IForm";
import { FormBuilder } from "./Form";
import style from "./style";
import { UIOutlineButton } from "../../UI/Buttons";
import { IFormElementUpdate } from "./Interface/FormElement/IFormElementUpdate";
import {
  EditFormHelper,
} from "./Helpers/Forms";
import { ValidationHelper } from "./Helpers/Validations";
import { CircularProgress } from "../../UI/Progress";
import { SubmitFormHelper } from "./Helpers/Forms/Submit";
import SnackbarAlert from "../../UI/Snackbar/SnackbarAlert.component";
import { catchError } from "../Mapping/Utility/ErrorMessage";
import { FormElementHelper } from "./Helpers/Forms/Element";
import { useHistory } from "react-router-dom";
import { ProfileForms } from "./Form/Configuration/Form";
import { SingleLineEllipsisText } from "../../UI/LineText/SingleLineEllipsis";
import { ParentChildElementUtil } from "./Helpers/Forms/ParentChildElement";
import { IFormElementValue } from "./Interface/FormElement/IFormElementValue";
import { FORM_ELEMENT_TYPES } from "../Admin/Form/ElementTypes";
import { FormHelper } from "./Helpers/Forms/Helper";
import { UIHR } from "./Form/Elements/ChildElements/HR";
import { UIUserInfo } from "./Form/Elements/Profile";
const useStyles:any = makeStyles((theme: Theme) => createStyles(style(theme)));

type ESnackbarAlert = "success" | "error" | "info" | "warning";


const UserProfileComponent = (): React.JSX.Element => {
  const [pageData, setPageData] = useState<any>(null);
  const [initLoader, setInitLoader] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingError, setLoadingError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });
  const initial = useSelector((state: any) => state.initial);

  const classes = useStyles();
  const bundle = useSelector((state: any) => state.initial.bundle);
  const UserInfo = useSelector((state: any) => state.initial.userInfo);
  const history = useHistory();
  const isExternalUser = initial.isExternalUser;
  if (isExternalUser) {
    history.push("/");
  }


  const setAlert = (message: string, variant: ESnackbarAlert): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const setForm = async (dataSources: any): Promise<void> => {
    const datasource = dataSources.dataSource;
    const userProfile = dataSources.userInfo;
    const formHelper = new EditFormHelper();
    const validationHelper = new ValidationHelper();

    let _forms = await formHelper.buildEditForm(
      datasource,
      userProfile,
      UserInfo,
      ProfileForms
    );
    const formElementHelper = new FormElementHelper();
    _forms = formElementHelper.findDependentParentElements(_forms);
    _forms = formElementHelper.findDisabledChildElements(_forms);
    _forms = validationHelper.isValidForms(_forms);

    setPageData((prevState: any) => ({
      ...prevState,
      ...dataSources,
      forms: _forms
    }));
  };

  const getDataSources = async (callback: any): Promise<void> => {
    await Axios.get(USER_PROFILE_GET_DATA)
      .then(async (response: any) => {
        await setForm(response.data);
        callback(false);
      })
      .catch((err) => {
        const errorMessage = catchError(err, bundle.ADMIN_TOOLS_ERROR_MSG);
        setLoadingError(errorMessage);
        callback(true);
      });
  };

  useEffect(() => {
    getDataSources(() => {
      setInitLoader(false);
    });
  }, []);

  if (initLoader) {
    return <CircularProgress />;
  }

  const ParentChildElementValidation = async (
    updatedForms: any,
    element: any
  ): Promise<any> => {
    const validationHelper: ValidationHelper = new ValidationHelper();
    const parentChildEle: ParentChildElementUtil = new ParentChildElementUtil();
    setLoading(true);

    // Call API if any child is associated
    let UpdatedForms: any = await parentChildEle.findParentElements(
      updatedForms,
      element
    );

    setPageData((prevState: any)=>({
      ...prevState,
      forms: UpdatedForms
    }));

    UpdatedForms = await parentChildEle.findChildElements(UpdatedForms, element);
    
    setPageData((prevState: any)=>({
      ...prevState,
      forms: UpdatedForms
    }));
    const ValidatedForms = validationHelper.isValidForms(UpdatedForms);

    setPageData((prevState: any)=>({
      ...prevState,
      forms: ValidatedForms
    }));

    setLoading(false);

    return ValidatedForms;
  };

  const onChange = async (element: IFormElementUpdate): Promise<void> => {
    const editFormHelper = new EditFormHelper();
 
    const ElementValue: IFormElementValue = {
      currentValue: element.value,
      previousValue: element.formElement.value,
    };
    const validationHelper = new ValidationHelper();
    const formElementHelper = new FormElementHelper();
    
    let UpdatedForms: IForm[] = editFormHelper.updateForms(
      pageData.forms,
      element,
      ElementValue
    );
    UpdatedForms = formElementHelper.findDependentElements(
      UpdatedForms,
      element
    );

    UpdatedForms = formElementHelper.findDisabledElements(
      UpdatedForms,
      element
    );
    UpdatedForms = validationHelper.isValidElement(UpdatedForms, element);
    UpdatedForms = validationHelper.isValidForms(UpdatedForms);

    setPageData((prevState: any)=>({
      ...prevState,
      forms: UpdatedForms
    }));

    const formHelper = new FormHelper();
    if (
      _.isEqual(element.formElement.type, FORM_ELEMENT_TYPES.SELECT_INPUT) &&
      !validationHelper.isPreviousAndCurrentValueAreSame(
        element.formElement,
        ElementValue
      ) &&
      formHelper.ifHasChildElements(UpdatedForms, element.formElement)
    ) {
      UpdatedForms = await ParentChildElementValidation(UpdatedForms, element);
      UpdatedForms = validationHelper.isValidForms(UpdatedForms);
      setPageData((prevState: any)=>({
        ...prevState,
        forms: UpdatedForms
      }));
    }
  };

  const onSaveSuccess = async (alertMessage: string): Promise<void> => {
    await getDataSources(() => {
      setAlert(alertMessage, "success");
      setLoading(false);
    });
  };

  const onSaveError = async (alertMessage: string): Promise<void> => {
    setAlert(alertMessage, "error");
    setLoading(false);
  };

  const onSave = async (): Promise<void> => {

    const helper = new SubmitFormHelper();
    const formData = helper.getSubmitForm(pageData.forms);
    setLoading(true);
    try {
      await helper.saveProfile(UserInfo.userId, formData);
      await onSaveSuccess(bundle.USER_PROFILE_SUCCESS_MSG);
    } catch (err: any) {
      const errorMessage = catchError(
        err,
        bundle.USER_PROFILE_ERROR_MSG
      );
      await onSaveError(errorMessage);
    }

  };



  let forms = <span />;
  let disableSaveButton = false;
  if (!_.isNull(pageData) && !_.isEqual(pageData.forms.length, 0)) {
    disableSaveButton = !pageData.forms[0].isValid;
    const FORMS = pageData.forms.map((form: IForm) => {
      return <FormBuilder form={form} onChange={onChange} key={form.id} />;
    });
    forms = (
      <>
        <Grid container justifyContent="space-between">
          {FORMS}
        </Grid>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Button
            disabled={disableSaveButton}
            variant="contained"
            color="primary"
            onClick={onSave}
            id="Save"
          >
            Save
          </Button>
          <UIOutlineButton
            cssStyle={{
              marginLeft: "0.75rem",
            }}
            isOutline={true}
            onClick={(): void=>{
              history.push("/");
            }}
            id="Cancel"
            btnText="Cancel"
          />
        </Box>
      </>
    );
  }

  const UserGroups = [];

  if (initial.isAuthenticated) {
    const userGroupMembership = _.groupBy(
      initial.userInfo.userGroupMembership,
      "name"
    );
    let userAccounts: any = [];
    for (const key in userGroupMembership) {
      const Accounts = userGroupMembership[key].map(
        (group: any) => group.account_name
      );
      userAccounts.push({
        accounts: Accounts,
        accountsText: Accounts.join(","),
        assignedGroups: userGroupMembership[key].map(
          (group: any) => ({
            name: group.name,
            description: group.description
          })
        ),
      });
    }

    userAccounts = _.groupBy(userAccounts, "accountsText");

    // Render tooltip
    const getTooltip = (title: string, description: string): JSX.Element =>{
      const Description = (description || "").toString().trim();
      if(_.isEmpty(Description)){
        return (<div key={title} className={classes.userGroup}>
          {title}
        </div>);
      }
      return (
        <div key={title} className={classes.userGroup}>
          <Tooltip title={description} placement={"top"}>
            <span>{title}</span>
          </Tooltip>
        </div>
      );
    };

    for (const key in userAccounts) {
      const Accounts = _.sortBy(_.uniq(key.split(","))).map(
        (userAccount: any) => (
          <div key={userAccount} className={classes.userAccount}>
            <SingleLineEllipsisText text={userAccount} />
          </div>
        )
      );
      let assignedGroups: any = [];
      userAccounts[key].forEach((account: any) => {
        _.uniq(account.assignedGroups).forEach((userGroup: any) => {
          assignedGroups.push(userGroup);
        });
      });

      assignedGroups = _.sortBy(assignedGroups, ["name"]);

      UserGroups.push(
        <div key={key} className={classes.userAccessContainer}>
        {Accounts}
          {assignedGroups.map((userGroup: any) => getTooltip(userGroup.name, userGroup.description))}
        </div>
      );
    }

    const DBGroups: any[] = [];
    const UserDBGroups = _.sortBy(initial.userInfo.dbGroups || [], ["name"]);
    UserDBGroups.forEach((dbGroup: any) => {
      DBGroups.push(getTooltip(dbGroup.name, dbGroup.description));
    });

    if (!_.isEmpty(UserDBGroups)) {
      UserGroups.push(
        <div
          key={"automations-portal-database-groups"}
          className={classes.userAccessContainer}
        >
          <div className={classes.userAccount}>
            <SingleLineEllipsisText text={"AP Database Groups"} />
          </div>
          {DBGroups}
        </div>
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>{bundle.MENU_USER_PROFILE_TITLE}</title>
      </Helmet>
      <Header name={bundle.USER_PROFILE_PAGE_TITLE} data-testid="UserProfileComponentTest" />
      <Container maxWidth="lg">
            <Grid container className={classes.contentWrapper}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={8}
                lg={9}
                xl={9}
                className={classes.infoWrapper}
              >
                {loading && <CircularProgress />}
                <div className={classes.content}>
                <UIUserInfo customStyle={{
                  marginTop: 0
                }}/>
                <UIHR />
                {
                !_.isNull(loadingError) && <div className={classes.note} dangerouslySetInnerHTML={{
                  __html: `Error: ${loadingError}`
                }}>
                </div>
              }
                  {forms}
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={4}
                lg={3}
                xl={3}
                justifyContent="center"
                className={classes.infoWrapper}
              >
                <div className={classes.content}>
                <div className={classes.roleHeader}>Roles</div>
                <div className={classes.userGroupsContainer}>{UserGroups}</div>
              </div>
            </Grid>
            </Grid>
            <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
        />
          </Container>
    </>
  );
};

export default UserProfileComponent;


