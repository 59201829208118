import * as _ from "lodash";
import { IElementUpdate } from "../../../interface/IElementUpdate";
import { IForm } from "../../../interface/IForm";
import { IFormElement } from "../../../interface/IFormElement";
import {
    ValidationHelper
} from "../Validation";
import {
    FormElementHelper
} from "../../Utils/Element";
import { IOption } from "../../../interface/IOption";
import {
	UI_DYNAMIC_CONFIG_ELEMENT_TYPES,
	UI_ELEMENT_TYPES,
	UI_BTN_ACTIONS,
	UI_DYNAMIC_ELE_TYPES,
	ALL_OPTION_VALUE,
	ALL_OPTION_OBJ,
	ADD_FORM_ELEMENTS,
	MULTI_FORM_VERSION,
} from "../../../../config";
import { DateUtil } from "../../Utils/Date";
import { IFormElementValue } from "../../../interface/IFormElementValue";
import { EditSelectElement } from "./Select";
import { DateValidation } from "../Validation/Date";
import { IFormGroup } from "../../../interface/IFormGroup";

// Edit form class
export class EditForm {

    private validationHelper: ValidationHelper;
    private elementHelper: FormElementHelper;
    private dateUtil: DateUtil;
    private editSelectElement: EditSelectElement;
    private dateValidation: DateValidation;

    constructor() {
        this.validationHelper = new ValidationHelper();
        this.elementHelper = new FormElementHelper();
        this.dateUtil = new DateUtil();
        this.editSelectElement = new EditSelectElement();
        this.dateValidation = new DateValidation();
    }

    /**
     * Update the form
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @param {boolean} isDelayed DEFAULT false. - Validate the field when use stop typing
     * @return {IForm[]} forms.
     */
    public updateForms = async (forms: IForm[], element: IElementUpdate, elementValue: IFormElementValue): Promise<IForm[]> => {
        const Forms: IForm[] = [];

        for (const form of forms) {
            const FormGroups = [];
            for (const formGroup of form.groups) {
                const FormElementArray = [];
                for (const formElement of formGroup.elements) {
                     const Element = await this.updateElement(formElement, element, elementValue);
                    FormElementArray.push(Element);
                }
                FormGroups.push({
                    ...formGroup,
                    elements: FormElementArray,
                });
            }

            Forms.push({
                ...form,
                groups: FormGroups
            });
        }

        return Forms;
    }

    private updatebyAddingElement = async (formElement: IFormElement, element: IFormElement, elementValue: IFormElementValue): Promise<any> => {
        const currentelement = {...formElement};
        if (_.isEqual(currentelement.type_id, UI_ELEMENT_TYPES.ADD_BUTTON)){
                const dynamicConfig =_.isString(currentelement.add_dynamic_elements)?JSON.parse(currentelement.add_dynamic_elements):currentelement.add_dynamic_elements;
                const parentModified:any = [];
                const deleteId:any={id:null,values:[]};
                if(!_.isEqual(formElement.id % 1, 0) && _.isEqual(dynamicConfig.sourceId % 1, 0)){
                    dynamicConfig.sourceId +=MULTI_FORM_VERSION;
                }
                const configElementsArray:IFormElement[] = dynamicConfig.elements.map((configElement:IFormElement,index:number,allitem:IFormElement[])=>{
                    const getnewId = ((_.isEqual(formElement.id % 1, 0) ? 150 : 1000) + (configElement.id + Number(elementValue)) + ((index + 1) / 10));

                    if (dynamicConfig.deleteToShow.id === configElement.id) {
                        deleteId["id"] = getnewId;
                        deleteId["values"].push(getnewId);
                    } else {
                        deleteId.values.push(getnewId);
                    }

                    for(const parent of dynamicConfig.parentsToModify){
                        if(parent.id === configElement.id){
                            parentModified.push({id:getnewId});
                        }
                    }
                    const l:number = allitem.length-1;
                    return {...configElement,id:getnewId,
                                            display_order:((Number(allitem[l].display_order))+((index+1)/10))};
                });
                let newconfigElementsArray:IFormElement[]=[];
                for(let count=0; count < dynamicConfig.parentsToModify.length; count++ ){
                    newconfigElementsArray = configElementsArray.map((configElement:IFormElement)=>{
                        const configElementnew ={...configElement};
                        if(deleteId.id === configElement.id ){
                            configElementnew.deletebyIds = deleteId.values;
                        }
                        if(configElementnew.parent_elements.length > 0){
                            const updateparentconfig = configElementnew.parent_elements.map((elementid:number)=>{
                              if(dynamicConfig.parentsToModify[count].id === elementid){
                                    return parentModified[count].id;
                              }else{
                               return  elementid;
                              }
                            });
                            return {...configElementnew,parent_elements:updateparentconfig};
                        }else{
                            return {...configElementnew};
                        }
                    });
                }
            
                return {...dynamicConfig,elements:newconfigElementsArray,parentsToModify:parentModified,deleteToShow:deleteId};
        }else{
            return [];
        }

        
    }
    public addFormElements = async (forms: IForm[], newElements: any): Promise<IForm[]> => {
    const Forms: IForm[] = [];
    for (const form of forms) {
        const FormGroups = [];
        for (const formGroup of form.groups) {
            const FormElementArray = []; // Copy existing elements;
            for (const formElement of formGroup.elements) {
                const ElementArray:any = _.isEqual(newElements?.formElement?.id,formElement.id) && await this.updatebyAddingElement(formElement, newElements, newElements.value);
                if(ElementArray?.elements?.length > 0){
                    for(const ele of ElementArray.elements){
                        FormElementArray.push(ele);
                    }
                    const ele = {...formElement};
                    ele.add_dynamic_elements = ElementArray;
                    ele.value = newElements.value;
                    FormElementArray.push(ele);
                }else{
                    FormElementArray.push(formElement);
                }
               
            }

            FormGroups.push({
                ...formGroup,
                elements: FormElementArray,
            });
        }

        Forms.push({
            ...form,
            groups: FormGroups
        });
    }

    return Forms;
}

    
    
	//Display multi form for Element
	public showAddForm = (forms: IForm[]):any => {
    	for(const form of forms){
    		for(const formGroup of form.groups){
    			for(const formElement of formGroup.elements){
					if(ADD_FORM_ELEMENTS.includes(formElement.value?.value) && forms.filter(x => x.id%1 ===0)){
						return true;
					}
    			}
    		}
    	}
    	return false;
    }

    public isValidParentElement = (parentElementId: any, forms: any): any => {
        if (parentElementId) {
            let isValidParent = false;
            for (const form of forms) {
                for (const formGroup of form?.groups) {
                    for (const formElement of formGroup?.elements) {
                        if (_.isEqual(formElement.id, (parentElementId + MULTI_FORM_VERSION))) {
                            isValidParent = true;
                        }
                    }
                }
            }
            return isValidParent;
        }
    }

	public mapParentElements = (forms: IForm[], parentElements: number[]):any =>{
			return parentElements.map(x => {
				for(const form of forms){
                    for (const group of form.groups) {
                        if (group.elements.some(y => y.id == (x + MULTI_FORM_VERSION))) {
                            const index = parentElements.indexOf(x);
                            if (this.isValidParentElement(x, forms)) {
                                parentElements[index] = x + MULTI_FORM_VERSION;
                            }
                        }
                    }
                }
				}
		);
	}

    public mapDynamicElements = (elements: any, forms: any):any =>{
        for(const element in elements){
            if(elements[element]?.id){
                elements[element].id += MULTI_FORM_VERSION;
            }
            else if(element.includes("Id")){
                elements[element]+=MULTI_FORM_VERSION;
            }
            else if(Array.isArray(elements[element])){
                for(const ele of elements[element]){
                    ele.id+=MULTI_FORM_VERSION;
                    if(!_.isEqual(ele.depends_on,null)){
                    ele.depends_on +=MULTI_FORM_VERSION; 
                    } 
                    if(Array.isArray(ele.parent_elements)){
                        for(let i=0; i<ele.parent_elements.length; i++){
                            if (this.isValidParentElement(ele.parent_elements[i], forms)) {
                            ele.parent_elements[i]=ele.parent_elements[i]+MULTI_FORM_VERSION;
                            }
                        }
                    }
                }
            }
        }
    }

    public checkDependsOnElement = (dependsOnElementId: any, form: any): any => {
        if (dependsOnElementId) {
            let isFoundDepends = false;
            for (const formGroup of form[0]?.groups) {
                for (const formElement of formGroup?.elements) {
                    if (_.isEqual(formElement.id, (dependsOnElementId + MULTI_FORM_VERSION))) {
                        isFoundDepends = true;
                    }
                }
            }
            return isFoundDepends;
        }
    }

	//Update the forms array with additional form with empty values
	public updateFormsForAdd = async (tool: any, currentFormId: any):  Promise<IForm[]> =>{
		const Forms:IForm[] = [];
		const { forms, defaultForms } = tool;
		for(const form of forms){
		Forms.push(form);	
    	}
		const initialFormList:IForm[] = _.cloneDeep(defaultForms);
		const additionalForms : IForm[]=[];
		for(const form of initialFormList){
			if(_.isEqual(form.id, currentFormId) || _.isEqual(form.parentId,currentFormId)){
				additionalForms.push(form);
			}
		}
		let displayOrder = Forms[Forms.length - 1].display_order;

		for(const newForm of additionalForms){
			newForm.display_order = displayOrder + 1;
			newForm.id =newForm.id + MULTI_FORM_VERSION; 
			if(_.isEqual(newForm.parentId,null)){
				newForm.parentId = currentFormId;
			}
			newForm.title=newForm.title + " 2";
			newForm.isVisible = true;
    		for(const formGroup of newForm.groups){
    			for(const formElement of formGroup.elements){
                    if(_.isEqual(formElement.id%1,0)) {
                        formElement.id = formElement.id+MULTI_FORM_VERSION;
                    }
					for(const formGroup1 of newForm.groups){
						if(formGroup1.elements.find(x => _.isEqual(x.id,(formElement.depends_on+MULTI_FORM_VERSION))) || this.checkDependsOnElement(formElement?.depends_on, additionalForms.filter(x => x.id !== newForm.id))){
							formElement.depends_on = formElement.depends_on + MULTI_FORM_VERSION;
                        }
						this.mapParentElements(additionalForms, formElement.parent_elements);
					}
                    if(!_.isEqual(formElement?.parent_compare_element_id,null)){
                        formElement.parent_compare_element_id=formElement?.parent_compare_element_id+MULTI_FORM_VERSION;
                        }
                    if(!_.isEqual(formElement.add_dynamic_elements,null)){
                        this.mapDynamicElements(formElement.add_dynamic_elements, additionalForms);
                    }
    			}
    		}
			Forms.push(newForm);
			displayOrder++;
		}
		return Forms;
	}

	//Update forms array by removing specific additional forms
	public updateFormsForRemove = async (forms: IForm[]):  Promise<IForm[]> =>{
        return forms.filter( x => _.isEqual(x.id %1,0));
	}

    /**
     * Update the element
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @param {boolean} isDelayed DEFAULT false.
     * @return {IForm[]} forms.
     */
    private updateElement = async (formElement: IFormElement, element: IElementUpdate, elementValue: IFormElementValue): Promise<IFormElement> => {
        let updatedElement = {
            ...formElement,
        };
        if (_.isEqual(formElement.id, element.formElement.id)) {
            let value = element.value;

            // DatePicker
            if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DATETIME_INPUT)
                && !formElement.is_number) {
                value = this.dateUtil.formatDateOrTime(value, formElement);
                formElement.dateInput = element.formElement.dateInput;
            }
            else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.FILE_INPUT)) {
                if (!_.isNull(formElement.dynamic_configuration)
                    && formElement.load_data_to_grid) {
                    if (!_.isNull(element.dynamicElement)
                        && !_.isUndefined(element.dynamicElement)) {
                        formElement.error = "";
                        if (_.isEqual(element.dynamicElement.action, UI_BTN_ACTIONS.EDIT)) {
                            const dynamicFieldValues = element.dynamicElement.value;
                            const { defaultSort } = formElement.dynamic_configuration;
                            const DefaultOrderBy = !_.isNull(
                                defaultSort
                            )
                                ? defaultSort
                                : null;
                            const DefaultOrder = !_.isNull(DefaultOrderBy) ? "asc" : null;
                            formElement.dynamic_fields_values = !_.isNull(DefaultOrderBy) && !_.isNull(DefaultOrder)
                                ? _.orderBy(dynamicFieldValues, [DefaultOrderBy], [DefaultOrder]) : dynamicFieldValues;
                        }
                        else if (_.isEqual(element.dynamicElement.action, UI_BTN_ACTIONS.DELETE)) {
                            formElement.dynamic_fields_values = [];
                        } else if (_.isEqual(element.dynamicElement.action, UI_BTN_ACTIONS.UPLOAD)) {
                            if (!_.isEmpty(element.dynamicElement.errorMessage)) {
                                formElement.error = element.dynamicElement.errorMessage;
                                formElement.dynamic_fields_values = [];
                            } else {
                                const dynamicFieldValues = element.dynamicElement.value;
                                const { defaultSort } = formElement.dynamic_configuration;
                                const DefaultOrderBy = !_.isNull(
                                    defaultSort
                                )
                                    ? defaultSort
                                    : null;
                                const DefaultOrder = !_.isNull(DefaultOrderBy) ? "asc" : null;
                                formElement.dynamic_fields_values = !_.isNull(DefaultOrderBy) && !_.isNull(DefaultOrder)
                                    ? _.orderBy(dynamicFieldValues, [DefaultOrderBy], [DefaultOrder]) : dynamicFieldValues;
                            }

                        }
                    }
                }
            }
            // Select 
            else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)) {
                value = this.editSelectElement.updateSelectValue(formElement, element);


                if (!_.isNull(element.value)) {
                    if (formElement.is_multi) {
                        const UpdatedValue = value;
                        const Values = UpdatedValue.filter((option: IOption) => option.highlightOption);
                        formElement.highlight_option_error = _.isEqual(Values.length, 0) ? "" : "Please remove options in red as they no longer exist.";
                    } else {
                        formElement.highlight_option_error = !element.value.highlightOption ? "" : "Please remove the option as it is no longer exist.";
                    }
                } else {
                    formElement.highlight_option_error = "";
                }
                if (!_.isNull(formElement.dynamic_configuration)
                    && _.isEqual(formElement.dynamic_configuration.elementType, UI_DYNAMIC_CONFIG_ELEMENT_TYPES.METRICS)) {

                    let dynamicElements = formElement.dynamic_fields_values.map((ele: any) => ({ ...ele }));

                    if (_.isEqual(element.dynamicElement.action, UI_BTN_ACTIONS.ADD)) {
                        const DynamicElement = formElement.dynamic_element.children[dynamicElements.length];
                        dynamicElements.push({ ...DynamicElement, value: null, touched: false });
                    }
                    else if (_.isEqual(element.dynamicElement.action, UI_BTN_ACTIONS.EDIT)) {

                        const DynamicElement = dynamicElements[element.dynamicElement.index];
                        if (_.isNull(element.dynamicElement.value)) {
                            DynamicElement.value = null;
                            DynamicElement.sortby = null;
                        }
                        else if (!_.isEqual(element.dynamicElement.value.value, _.isNull(element.formElement.value) ? null : element.formElement.value.value)) {

                            dynamicElements = formElement.dynamic_fields_values.map((ele: any) => ({ ...ele }));
                            let sortByValue = _.isEqual(element.dynamicElement.type, UI_DYNAMIC_ELE_TYPES.SORT) ? element.sortByValue.value : null;

                            if (!_.isNull(element.dynamicElement.value)
                                && _.isEqual(element.dynamicElement.type, UI_DYNAMIC_ELE_TYPES.ELEMENT)) {

                                const DynamicElements = (formElement.dynamic_element.children || []).filter((ele: any) => !_.isNull(ele.value));

                                const Value = DynamicElements.find((ele: any) => _.isEqual(ele.value.value, element.dynamicElement.value.value));

                                sortByValue = Value.sortby;
                            }

                            DynamicElement.value = _.isNull(element.dynamicElement.value) ? null : element.dynamicElement.value;
                            DynamicElement.sortby = sortByValue;
                        }


                        dynamicElements[element.dynamicElement.index] = { ...DynamicElement, touched: true };
                    } else if (_.isEqual(element.dynamicElement.action, UI_BTN_ACTIONS.DELETE)) {
                        dynamicElements = dynamicElements.filter((val: any, index: number) => !_.isEqual(index, element.dynamicElement.index));
                    }

                    value = { label: "dummy", value: "dummy" };
                    let isValid = true;

                    for (const dynamiceEle of dynamicElements) {
                        if (_.isNull(dynamiceEle.sortby) || (_.isNull(dynamiceEle.value) && dynamiceEle.required)) {
                            isValid = false;
                        }
                    }

                    formElement.dynamic_fields_values = dynamicElements;

                    value = isValid ? value : null;
                }

                if (formElement.is_multi && !_.isNull(formElement.selection_limit)
                    && !_.isNull(value)) {
                    const DataSource = (formElement.dataSource || []).map((option: IOption) => ({ ...option, isDisabled: false }));


                    const ValLen = formElement.allow_all ? value.length - 1 : value.length;

                    if (_.gte(ValLen, formElement.selection_limit)) {
                        formElement.dataSource = formElement.dataSource.map((option: IOption) => ({ ...option }))
                            .map((option: IOption) => ({ ...option, isDisabled: true }));
                    } else {
                        formElement.dataSource = DataSource;
                    }

                    if (formElement.is_multi && !_.isNull(formElement.selection_limit)
                        && !_.isNull(value) && _.isArray(value)) {
                        formElement = this.elementHelper.updateSelectionLimit(formElement, value);
                    }
                }
            }

            let touched = true;

            if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DATERANGE_INPUT)) {
                touched = this.dateValidation.isValidTouchForDateRange(element.value);
            }
            updatedElement = {
                ...formElement,
                touched,
                value,
                loading: element.loading,
                input_event_value: element.formElement.input_event_value
            };
        }
        else if ((_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
            || _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.CHIP)
            || _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DOWNLOAD))
            && !this.validationHelper.isPreviousAndCurrentValueAreSame(
                element.formElement,
                elementValue
            )
            && !_.isEqual(formElement.parent_elements.indexOf(element.formElement.id), -1)) {
            let errorMessage = "";
            if (formElement.is_required) {
                errorMessage = await this.validationHelper.validate(formElement,
                    null);
            }
            updatedElement = {
                ...formElement,
                value: _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DOWNLOAD) ? null : "",
                touched: false,
                error: errorMessage,
                isValid: _.isEqual(errorMessage.length, 0),
            };
        }
        else if ((_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)
            || _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.THUMBNAIL))
            && !this.validationHelper.isPreviousAndCurrentValueAreSame(
                element.formElement,
                elementValue
            )
            && !_.isEqual(formElement.parent_elements.indexOf(element.formElement.id), -1)) {

            let errorMessage = "";
            if (formElement.is_required) {
                errorMessage = await this.validationHelper.validate(formElement,
                    null);
            }
            updatedElement = {
                ...formElement,
                value: null,
                dynamic_fields_values: [],
                touched: false,
                error: errorMessage,
                dataSource: [],
                hideBasedOnParent: true,
                is_visible: formElement.is_visible,
                isValid: _.isEqual(errorMessage.length, 0),
            };
        }
        else if ((_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.GRID))
            && !this.validationHelper.isPreviousAndCurrentValueAreSame(
                element.formElement,
                elementValue
            )
            && !_.isEqual(formElement.parent_elements.indexOf(element.formElement.id), -1)) {
            let errorMessage = "";
            let validflag = true;
            if (formElement.is_required) {
                errorMessage = await this.validationHelper.validate(formElement,
                    null);
                validflag = formElement.dataSource.length > 0 ? true : false;
            }

            updatedElement = {
                ...formElement,
                value: null,
                dynamic_fields_values: [],
                touched: false,
                error: formElement.dataSource.length > 0 ? "" : errorMessage,
                hideBasedOnParent: true,
                is_visible: formElement.is_visible,
                isValid: validflag
            };
        }
        return {
            ...updatedElement
        };
    }

        /**
         * Update creatable select value
         *
         * @param {IFormElement} formElement.
         * @param {any} element.
         * @return {any} value.
         */
        updateSelectValue = (formElement: IFormElement, value: any): any => {

            const {
                is_multi,
                allow_all
            } = formElement;

            if (is_multi && allow_all) {
                const Value = value || [];

                const IsAllOptionSelected = (Value).findIndex(
                    (option: IOption) => {
                        return _.isEqual(option.value, ALL_OPTION_VALUE);
                    }
                );

                if (
                    _.isEqual(IsAllOptionSelected, Value.length - 1) &&
                    _.gt(Value.length, 1)
                ) {
                    return [ALL_OPTION_OBJ];
                } else if (
                    !_.isEqual(IsAllOptionSelected, -1) &&
                    _.gt(Value.length, 1)
                ) {
                    return Value.filter(
                        (option: IOption) => !_.isEqual(option.value, ALL_OPTION_VALUE)
                    );
                }
            }
            return value;
        }

      /**
     * Update creatable select data source
     *
     * @param {IForm[]} forms.
     * @param {IFormElement} element.
     * @param {IOption} option.
     * @return {IForm[]} forms.
     */
      public async updateElementDataSource(forms: IForm[], element: IElementUpdate): Promise<IForm[]> {
        try {
            const Forms: IForm[] = [];
            for (const form of forms) {
                const FormGroups: IFormGroup[] = [];
                for (const formGroup of form.groups) {
                    const Elements: IFormElement[] = [];
                    for (const formElement of formGroup.elements) {
                        if (_.isEqual(formElement.id, element.formElement.id)
                            && _.isEqual(UI_ELEMENT_TYPES.SELECT_INPUT, element.formElement.type_id)) {

                            let updatedDataSource: any = [...formElement.dataSource,
                            element.newOption];
                            updatedDataSource = _.orderBy(updatedDataSource, [(op: IOption) => op.label.toLowerCase()], ["asc"]);

                            Elements.push({
                                ...formElement,
                                dataSource: updatedDataSource,
                            });
                        } else {
                            Elements.push({
                                ...formElement
                            });
                        }

                    }
                    FormGroups.push({
                        ...formGroup,
                        elements: Elements,
                    });
                }
                Forms.push({
                    ...form,
                    groups: FormGroups,
                });
            }
            return Forms;
        }
        catch {
            return [];
        }
    }
}