import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { UITooltip } from "../Tooltip";
import style from "../../../../../../UI/Elements/FormElements/Child/Label/style";
import commonStyle from "../../../../../../UI/Elements/FormElements/sharedStyle";

import { IFormElement } from "../../../../Interface/FormElement/IFormElement";
import classNames from "classnames";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const utilStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  formElement: IFormElement;
};

// UILabel Component
export const UILabel = (props: props): React.JSX.Element => {
  const { formElement } = props;
  const classes = useStyles();
  const utilClasses = utilStyles();

  if(formElement.configuration.hideLabel) {
    return <div className={classNames(
      classes.hideLabel, {
        [classes.titleContainer] : formElement.configuration.removeLabelHeight
      }
    )}></div>;
  }
  
  return (
    <label htmlFor={`${formElement.id}`} className={utilClasses.elementLabel}>
      {formElement.configuration.hideLabel ? "" :  formElement.configuration.title}
      <UITooltip title={formElement.configuration.tooltip || ""} />
      {!formElement.validations.required &&
        !formElement.configuration.hideOptionalText && (
          <span className={classes.optional}>Optional</span>
        )}

    </label>
  );
};

export default UILabel;
