
import COLORS from "../../../../UI/Theme/colors";
const style: any = (): any => ({
	headerContainer: {
		display: "flex",
		width: "100%"
	},
	footerContainer: {
		display: "flex",
		flexDirection: "row-reverse",
		width: "100%"
	},
	actionContainer: {
		position: "absolute" as const,
		height: 72,
		padding: "0px 40px",
		display: "flex",
		alignItems: "center" as const,
		justifyContent: "flex-end",
		backgroundColor: COLORS.WHITE
	},
	btnContent: {
		marginRight: "9px"
	},
	toolBoxCountText: {
		fontSize: 14,
		paddingTop: 21,
		letterSpacing: "normal",
		color: COLORS.GRAY,
		paddingBottom: 0
	},
	Container: {
		marginBottom: "-20px"
	}

});

export default style;