import COLORS from "../../../../../../UI/Theme/colors";

const style: any = () => ({
	loadingCell: {
		height: 21,
		width: "100%",
		borderRadius: 2,
		display: "inline-block"
	},
	loadingGridRow: {
		padding: "1rem 0px 1rem 0px",
	},
	gradient: {
		backgroundImage: "linear-gradient(90deg, #F3F5F7 0px, #F7F8FA 40px, #F3F5F7 80px)",
		backgroundSize: 600,
		animation: "shine-lines 1.6s infinite linear",
		backgroundColor: COLORS.BASECOLOR
	},
	gridCell: {
		minWidth: 0,
		padding: "0.5rem 0px 0.5rem 1rem",
		minHeight: 48,
		verticalAlign: "top"
	},
	gridHeader: {
		height: 40,
		borderBottom: "1px solid rgba(224, 224, 224, 1)",
		position: "sticky",
		top: 56,
		backgroundColor: COLORS.WHITE,
		zIndex: 1
	},
	loadingHeader: {
		position: "absolute",
		top: 5,
	}
});

export default style;
