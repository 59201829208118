
const style:any = (): any => ({
    sectionTitle:{
        fontSize:"1rem",
        height:24,
        fontWeight:600,
        marginBottom: "1rem"
    },
    formGroup:{
        marginBottom: "1.125rem"
    },
});

export default style;

