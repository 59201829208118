import { Theme } from "@material-ui/core/styles";

const style:any = (theme: Theme): any => ({
	root: {
		width: 48,
		height: 24,
		padding: 0,
		display: "flex",
		borderRadius: 18,
		border: `1px solid ${theme.palette.grey[500]}`,
	},
	isActive: {
		border: "1px solid #3a91e8 !important",
	},
	disabled:{
		opacity:"0.5"
	},
	switchBase: {
		padding: 3,
		color: theme.palette.grey[500],
		"&$checked": {
			transform: "translateX(24px)",
			color: "#3a91e8",
			"& + $track": {
				opacity: 1,
				backgroundColor: "#f0f8ff !important",
			},
		},
	},
	thumb: {
		width: 16,
		height: 16,
		boxShadow: "none",
	},
	track: {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: theme.palette.common.white,
	},
	checked: {},
	focusVisible: {},
});

export default style;


