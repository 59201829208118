import React from "react";
import Switch from "@material-ui/core/Switch";
import classNames from "classnames";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import style from "../../../../../../../../UI/Elements/FormElements/Switch/style";
import switchStyle from "./style";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { UILabel } from "../Child/Label";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));
const switchStyles = makeStyles((theme: Theme) =>
  createStyles(switchStyle(theme))
);

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
};

export const UISwitch = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const switchClasses = switchStyles();
  const { formElement, onChange } = props;
  const Disabled = formElement.disabled;
  const [checked, setChecked] = React.useState(formElement.value);

  return (
    <div className={switchClasses.switchContainer}>

      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        disabled={formElement.disabled}
        checked={checked}
        onChange={(event: any): void => {
          const Value = event.target.checked;
          setChecked(event.target.checked);
          onChange(event, Value);
        }}
        className={classNames(
          (formElement.value || "").toString() && classes.isActive,
          {
            [classes.disabled]: Disabled,
          }
        )}
      />
      <div style={{ marginLeft: "10px", marginTop: "10px" }}>
        <UILabel formElement={formElement} />
      </div>
    </div>
  );
};
