import * as _ from "lodash";

export function catchErrors(error: any, defaultErrorMessage: string): any {
    if (!_.isUndefined(error.response.data.message)) {
        return error.response.data.message;
    }
    return defaultErrorMessage;
}


