import * as _ from "lodash";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { IOption } from "../../Interface/IOption";
import { ValidationHelper } from "../Validations";

import { IFormElementUpdate } from "../../Interface/FormElement/IFormElementUpdate";
import { NewFormHelper } from "./New";
import { PORTAL_TOOL_CONTENT_USER_DISABLED_FIELD } from "../../Form/Configuration/UserRoleFieldMapping";
import { USER_PERMISSIONS_CODES } from "../../../../Layout/Routes/Permissions/UserPermissions";
import { UserPermissionHelper } from "../../../../Layout/Routes/Permissions/Helper";

// Edit form class
export class EditFormHelper {
	private newFormHelper: NewFormHelper;
	private userPermissionHelper: UserPermissionHelper;
	constructor() {
		this.newFormHelper = new NewFormHelper();
		this.userPermissionHelper = new UserPermissionHelper();
	}

	/**
	 * Update the form
	 *
	 * @param {IForm[]} forms.
	 * @param {IElementUpdate} element.
	 * @param {boolean} isDelayed DEFAULT false. - Validate the field when use stop typing
	 * @return {IForm[]} forms.
	 */
	public updateForms = (forms: IForm[], element: IFormElementUpdate): IForm[] => {
		const Forms: IForm[] = [];

		for (const form of forms) {
			const FormGroups = [];
			for (const formGroup of form.groups) {
				const FormElementArray = [];

				for (const formElement of formGroup.elements) {
					const Element = this.updateElement(formElement, element);
					FormElementArray.push(Element);
				}
				FormGroups.push({
					...formGroup,
					elements: FormElementArray,
				});
			}
			Forms.push({
				...form,
				groups: FormGroups
			});
		}
		return Forms;
	}

	/**
	 * Update the element
	 *
	 * @param {IFormElement} formElement.
	 * @param {IElementUpdate} element.
	 * @param {boolean} isDelayed DEFAULT false.
	 * @return {IForm[]} forms.
	 */
	private updateElement = (formElement: IFormElement, element: IFormElementUpdate): IFormElement => {
		let updatedElement = {
			...formElement,
		};


		if (_.isEqual(formElement.id, element.formElement.id)) {
			const value = element.value;

			if (_.isEqual(formElement.type, FORM_ELEMENT_TYPES.FILE_UPLOAD)) {
				updatedElement = {
					...updatedElement,
					imageUrl: this.getImageURL(value)
				};
			}

			updatedElement = {
				...updatedElement,
				touched: true,
				value,
				loading: element.loading
			};
		}
		return {
			...updatedElement
		};
	}

	private getImageURL = (value: any): null | string => {

		const ElementValue = (value || "").toString().trim();

		if (!_.isEmpty(ElementValue)) {
			return _.isEmpty(value.acceptedFiles) ? null : value.acceptedFiles[0].publicURL;
		}
		return null;
	}

	public buildEditForm(dataSources: any, formData: any, user: string, formsConfig: IForm[]): IForm[] {
		let EditForm: IForm[] = this.newFormHelper.buildNewForm(dataSources, formsConfig)
		.map((form: IForm)=>({
			...form,
			groups: form.groups.map((formGroup: IFormGroup) =>({
				...formGroup,
				elements: formGroup.elements.map((formElement: IFormElement)=> {
					const Element = this.buildEditFormElement(formElement, formData);
					const validateHelper = new ValidationHelper();
					const ErrorMessage = validateHelper.validate(formElement, Element.value);

					return {
						...Element,
						errorMessage: ErrorMessage,
						isValid: _.isEqual(ErrorMessage.length, 0),
						touched: false,
						disabled: this.isDisabledElementBasedonUserRole(formElement, user)
					};
				})
			}))
		}));
	
		const validationHelper = new ValidationHelper();

		EditForm = validationHelper.isValidForms(EditForm);
		return EditForm;
	}


	private isDisabledElementBasedonUserRole = (formElement: IFormElement, user: any): boolean => {
		if (formElement.disabled) {
			return true;
		}

		if (!this.userPermissionHelper.isAllowedPermission(
			user,
			USER_PERMISSIONS_CODES.AP_MANAGE_TOOL_CONFIG
		)) {
			return !_.isUndefined(PORTAL_TOOL_CONTENT_USER_DISABLED_FIELD[formElement.id]);
		}
		return formElement.disabled;
	}

	public buildEditFormElement = (
		formElement: IFormElement,
		formData: any
	): IFormElement => {
		let element;
		const ElementVisibility = formElement.configuration.visibility;
		switch (formElement.type) {
			case FORM_ELEMENT_TYPES.SELECT_INPUT: {
				element = {
					...formElement,
					value: this.getSelectValue(formElement, formData),
				};
				break;
			}
			case FORM_ELEMENT_TYPES.SWITCH:
				element = {
					...formElement,
					value: _.isEqual(formData[formElement.id], 1),
				};
				break;
			case FORM_ELEMENT_TYPES.FILE_UPLOAD: {
				const ImageURL: any = (formData[formElement.id] || "").toString().trim();

				element = {
					...formElement,
					imageUrl: _.isEmpty(ImageURL) ? null : ImageURL
				};
				break;
			}
			default:
				element = {
					...formElement,
					value: formData[formElement.id],
				};
				break;
		}
		return {
			...element,
			visible: _.isUndefined(ElementVisibility) ? element.visible : ElementVisibility.editForm
		};
	};

	private getSelectValue = (formElement: IFormElement, formData: any): any => {
		if (_.isNull(formData[formElement.id])) {
			return null;
		}
		if( _.isEmpty(formData[formElement.id]) && _.isEqual(formElement.id,"ToolPersistButtons")){
			return [];
		}
		if (!_.isUndefined(formElement.configuration.selectMetadata)
			&& formElement.configuration.isMulti) {
			const OptionValueKey: any = formElement.configuration.selectMetadata?.value;
			return formData[formElement.id].map((valOption: any) => {
				return formElement.configuration.dataSource.find((option: IOption) =>
					_.isEqual(valOption[OptionValueKey], option.value));
			});
		}
		return formElement.configuration.dataSource.find((option: IOption) =>
			_.isEqual(option.value, formData[formElement.id]));
	}
}