import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { UserPermissionHelper } from "../Layout/Routes/Permissions/Helper";

// Handles component URL authorization
export default (WrappedComponent: any): any => {
  class WithSecureRouteHOC extends Component<any> {
    private userPermissionHelper: UserPermissionHelper;
    constructor(props: any) {
      super(props);
      this.userPermissionHelper = new UserPermissionHelper();
    }
    render(): React.JSX.Element {
      if (
        this.userPermissionHelper.isUserAllowedRouteAccess(
          this.props.userInfo,
          this.props.location.pathname
        )
      ) {
        return <WrappedComponent {...this.props} />;
      }
      return <Redirect to="/" />;
    }
  }
  const mapStateToProps = (state: any): any => ({
    bundle: state.initial.bundle,
    userInfo: state.initial.userInfo,
  });
  return connect(mapStateToProps, null)(withRouter(WithSecureRouteHOC));
};
