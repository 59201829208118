import Colors from "../../../../Theme/colors";

const style: any = () => ({
	gridContainer:{
		position:"fixed",
		top:0,
		left:0,
		bottom:0,
		right:0,
		zIndex:9999,
		backgroundColor: Colors.WHITE,
		padding: "0 7.625rem",
		width:"100vw",
		height:"100vh",
		overflow:"auto",
		paddingTop: 96,
		paddingBottom:40
	},
	boxShadow:{
		boxShadow:"0px 1px 3px 0px rgb(0 0 0 / 10%), 0px 1px 2px 0px rgb(0 0 0 / 4%)"
	},
	heading:{
		marginLeft:20
	},
	dialogHeaderContainer:{
		position: "fixed",
		width: "100%",
		top: 0,
		left: 0,
		zIndex: 1,
		padding: "0 7.625rem",
	},
	dialogHeader :{
		backgroundColor: Colors.WHITE,
	},
	closeIcon:{
		cursor:"pointer"
	},
});

export default style;
