import React, { useEffect, useState, Fragment } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Clear";
import { Helmet } from "react-helmet";
import { SnackbarAlert } from "../../UI/Snackbar";
import Download from "./Download";
import GridTable from "./Grid/index";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Input,
  InputAdornment,
} from "@material-ui/core";
import style from "./style";
import { IconSearch } from "../../UI/Icons";
import classNames from "classnames";
import { Axios } from "../../api/service/Axios";
import { catchError } from "../../helpers/ErrorHandler.component";
import { GET_DATASOURCE_INITIAL_DATA, GET_TOOL_EXECUTION_HISTORY, GENERATE_GOOGLE_SIGNED_URL, FILE_DOWNLOAD_API_URL } from "../../api/paths";
import { CircularProgress } from "../../UI/Progress";
import _ from "lodash";
import { CALENDER_OPTIONS, FORM_TITLE, SORT_ORDER } from "./configuration";
import { getHistoryFilterFormConfig } from "./FilterConfig";
import { IForm } from "./Interfaces/Form/IForm";
import { FilterFormHelper } from "./Helpers/Forms/Filter";
import { SidebarComponent } from "./Layout/Sidebar";
import moment from "moment";
import { SearchFilter } from "./Layout/SearchFilter";
import Select, { components } from "react-select";
import { CustomStyles } from "../Admin/Form/Elements/SelectInput/style";
import { UIDateRangePicker } from "./Layout/Elements/DateRange";
import * as HistoryHelper from "./Helpers/Helper";
import { IFilters } from "./Interfaces/Filter/IFilters";


const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

interface IRowData {
  executionDate: any;
  toolName: string;
  jobName: string;
  status: string;
  statusMessage: any;
}

function strToLowerCase(input: any): any {
  return (input || "").toLowerCase();
}

function History(): React.JSX.Element {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [circularLoading, setCircularLoading] = useState(false);
  const [dataSource, setDataSource] = useState();
  const [selectedOption, setSelectedOption] = useState({ label: "Last 3 Months", value: 90 });
  const [isFilter, setIsFilter] = useState(false);
  const [executionData, setExecutionData] = useState({ data: [], count: 0 });
  const [startDate, setStartDate] = useState(moment(new Date(), "YYYY/MM/DD", true).subtract(90, "d").format("YYYY/MM/DD"));
  const [endDate, setEndDate] = useState(moment(new Date()).format("YYYY/MM/DD"));
  const [isCustom, setIsCustom] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const [data, setData] = useState({
    data: [],
    count: 0,
  });
  const [filters, setFilters] = useState<IFilters>({
    page_number: 0,
    record_limit: 20,
    search: {
      text: "",
      typingTimeout: 0,
    },
    sidebarFilter: null,
    order_by: "executionendtime",
    order: "desc",
    startDate: startDate,
    endDate: endDate,
    toolName: [],
    jobName: [],
    status: [],
  });

  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });


  const errorAlert = (message: string): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant: "error",
      message,
    });
  };

  const successAlert = (message: string): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant: "success",
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  // Search handler
  const onSearchChange = async (event: any): Promise<void> => {
    const SEARCH_TEXT = event.target.value || "";

    if (filters.search.typingTimeout) {
      clearTimeout(filters.search.typingTimeout);
    }
    setFilters((prevState: any) => ({
      ...prevState,
      search: {
        ...prevState.search,
        text: SEARCH_TEXT,
      },
    }));
    if (_.isEqual(SEARCH_TEXT.trim(), "")) {
      if (_.isEmpty(filters.toolName) && _.isEmpty(filters.jobName) && _.isEmpty(filters.status)) {
        setIsFilter(false);
        setExecutionData({ data: data.data, count: data.count });
      }
      filterAllData(filters.sidebarFilter?.form, SEARCH_TEXT);
    }
    else {
      if (
        (_.gte(SEARCH_TEXT.trim().length, 3) || _.lt(SEARCH_TEXT.trim(), 1))) {
        if (_.isEqual(event.code, "Enter")) {
          setIsFilter(true);
          filterAllData(filters.sidebarFilter?.form, SEARCH_TEXT);
        } else {
          setFilters((prevState: any) => ({
            ...prevState,
            search: {
              ...prevState.search,
              text: SEARCH_TEXT,
              typingTimeout: setTimeout(() => {
                setIsFilter(true);
                filterAllData(filters.sidebarFilter?.form, SEARCH_TEXT);
              }, 500),
            },
            page_number: 0,
          }));
        }
      }
    }
  };

  // Handles table page change
  const handleChangePage = (offset: number, page: number): void => {
    setFilters((prevState: any) => ({
      ...prevState,
      page_number: page,
    }));
  };

  const sortData = (orderByField: string): void => {
    let filterOnData: any = data.data;
    if (_.isEqual(orderByField, "executionDate")) {
      executionData.data.sort((a: any, b: any) => _.isEqual(filters.order, SORT_ORDER.ASC) ? (new Date(a["executionDate"] + " " + a["executionTime"]) < new Date(b["executionDate"] + " " + b["executionTime"]) ? 1 : (new Date(b["executionDate"] + " " + b["executionTime"]) < new Date(a["executionDate"] + " " + a["executionTime"])) ? -1 : 0) :  //NOSONAR
        (new Date(a["executionDate"] + " " + a["executionTime"]) > new Date(b["executionDate"] + " " + b["executionTime"]) ? 1 : (new Date(b["executionDate"] + " " + b["executionTime"]) > new Date(a["executionDate"] + " " + a["executionTime"])) ? -1 : 0)); //NOSONAR
    } else {
      if (isFilter) {
        filterOnData = executionData.data;
      }
      const sortedData: any = _.isEqual(filters.order, SORT_ORDER.ASC) ? _.orderBy(
        filterOnData,
        [
          (x: any) => {
            return x[orderByField] ? x[orderByField].toLowerCase() : "";
          },
        ],
        ["desc"]
      ) : _.orderBy(
        filterOnData,
        [
          (x: any) => {
            return x[orderByField] ? x[orderByField].toLowerCase() : "";
          },
        ],
        ["asc"]
      );
      setExecutionData({ data: sortedData, count: sortedData.length });
    }
  };

  const createSortHandler = (headerKey: string): void => {
    const orderBy = headerKey;
    let order = SORT_ORDER.DESC;

    if (_.isEqual(filters.order, SORT_ORDER.DESC)) {
      order = SORT_ORDER.ASC;
    }

    setFilters((prevState: any) => ({
      ...prevState,
      order_by: orderBy,
      order: order,
    }));

    switch (orderBy.trim()) {
      case "executionendtime":
        sortData("executionDate");
        break;
      case "toolid__toolname":
        sortData("toolName");
        break;
      case "jobname":
        sortData("jobName");
        break;
      case "statusid__statusname":
        sortData("status");
        break;
      case "statusmessage":
        sortData("statusMessage");
        break;
    }


  };

  // expiry date check
  const expiryDateCheck_hist = (expirytime: any): any => {
    const now_hist = moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    return (moment(now_hist).isBefore(expirytime));
  };
  // download grid
  const onDownloadHandler_hist = async (fileName: any, bucketName: any, expirytime: any): Promise<void> => {

    setCircularLoading(true);
    if (expiryDateCheck_hist(expirytime)) {
      try {
        const signedUrl_hist = await Axios.post(GENERATE_GOOGLE_SIGNED_URL, {
          "blob_name": fileName,
          "bucket_name": bucketName
        });

        if (signedUrl_hist) {
          window.location.href = `${process.env.REACT_APP_BASE_URL
            }${FILE_DOWNLOAD_API_URL}?filename=${fileName}&fileurl=${encodeURIComponent(
              signedUrl_hist.data
            )}`;
        }
        setCircularLoading(false);
      } catch (err) {
        const errorMessage_hist = catchError(err);
        errorAlert(errorMessage_hist);
        setCircularLoading(false);
      }
    } else {
      const errorMessage_hist = "File does not exist";
      errorAlert(errorMessage_hist);
      setCircularLoading(false);
    }
  };


  //get History data with filters
  const getHistoryData = (
    executedOn: any,
    callback: any
  ): void => {
    setLoading(true);
    Axios.get(
      `${GET_TOOL_EXECUTION_HISTORY}?start_date=${encodeURIComponent(
        moment(executedOn.start_date).format("YYYY-MM-DD")
      )}&end_date=${encodeURIComponent(
        moment(executedOn.end_date).format("YYYY-MM-DD")
      )}`
    )
      .then((response: any) => {
        const Data: any = response.data;
        Data.data.sort((a: any, b: any) => (new Date(a["executionDate"] + " " + a["executionTime"]) < new Date(b["executionDate"] + " " + b["executionTime"]) ? 1 : (new Date(b["executionDate"] + " " + b["executionTime"]) < new Date(a["executionDate"] + " " + a["executionTime"])) ? -1 : 0)); //NOSONAR nested ternry operator
        setData({
          data: Data.data,
          count: Data.count,
        });
        setExecutionData({
          data: Data.data,
          count: Data.count,
        });
        setLoading(false);
        callback();
      })
      .catch((error: any) => {
        const errorMessage = catchError(error);
        errorAlert(errorMessage);
        setLoading(false);
        callback();
      });
  };

  let totalExecutions: any = "";
  if (!loading) {
    totalExecutions = !_.isUndefined(executionData) ? executionData.count : 0;
  }

  const getDefaultFilterForm = async (dataSources: any): Promise<any> => {
    const filterFormHelper = new FilterFormHelper();

    return filterFormHelper.buildFilterForm(
      getHistoryFilterFormConfig({}),
      dataSources
    );
  };

  const getFilterData = async (dataSources: any): Promise<any> => {
    const form = await getDefaultFilterForm(dataSources);
    return {
      ...filters.sidebarFilter,
      dataSources,
      form,
      title: FORM_TITLE.NEW,
      open: false,
    };
  };

  const getDatasourceData = async (start: any, end: any): Promise<void> => {
    const InitData = await Axios.get(`${GET_DATASOURCE_INITIAL_DATA}?start_date=${encodeURIComponent(
      moment(start).format("YYYY-MM-DD")
    )}&end_date=${encodeURIComponent(
      moment(end).format("YYYY-MM-DD")
    )}`);
    setDataSource(InitData.data);
    const SidebarForm = await getFilterData(
      InitData.data || {}
    );
    setFilters((prevState: any) => ({
      ...prevState,
      filterCount: 0,
      sidebarFilter: SidebarForm,
    }));
  };

  useEffect(() => {
    setInitialLoading(true);
    getHistoryData(
      { start_date: moment(new Date(), "YYYY-MM-DD", true).subtract(3, "months").format("YYYY-MM-DD"), end_date: moment(new Date(), "YYYY-MM-DD", true).format("YYYY-MM-DD") },
      () => {
        setInitialLoading(false);
      }
    );
    getDatasourceData(startDate, endDate);
  }, []);

  useEffect(() => {
    if (isCustom) {
      setSelectedOption({ label: "Custom Date", value: 1 });
    }
  }, [isCustom]);

  const onReset = async (): Promise<void> => {
    try {
      setIsFilter(false);
      setLoading(true);
      const SidebarForm = await getFilterData(
        dataSource || {}
      );
      if (!_.isEqual(filters.search.text, "")) {
        filterAllData(SidebarForm?.form, filters.search.text);
      } else {
        setExecutionData({ data: data.data, count: data.count });
      }
      setFilters((prevState: any) => ({
        ...prevState,
        page_number: 0,
        record_limit: 20,
        filterCount: 0,
        sidebarFilter: SidebarForm,
        startDate: startDate,
        endDate: endDate,
        toolName: [],
        jobName: [],
        status: [],
      }));
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const applyFilterData = (filterData: any, filterName: any, firstFilterValue: any, secondFilterValue: any): any => {
    if (!_.isEqual(filterName.length, 0)) {
      filterData = filterData.filter((x: any) => {
        return filterName.some((y: any) => {
          return _.isEqual(y, (x[firstFilterValue] + " - " + x[secondFilterValue]));
        });
      });
    }
    return filterData;
  };

  const filterAllData = (form: any, search: any): void => {
    const filterHelper = new FilterFormHelper();
    const Filter: any = {
      ...filters,
      sidebarFilter: {
        ...filters.sidebarFilter,
        form,
      },
    };
    const FilterData = HistoryHelper.getFilterSubmitData(Filter);
    const FilterCount = filterHelper.getFilterCount(
      Filter
    );
    if (_.isEqual(FilterData.filterToolName.length, 0) && _.isEqual(FilterData.filterJobName.length, 0) && _.isEqual(FilterData.filterStatus.length, 0) && _.isEqual(search, "")) {
      setExecutionData({ data: data.data, count: data.count });
    }
    else {
      setIsFilter(true);
      let filterData: any = data.data;
      filterData = applyFilterData(filterData, FilterData.filterToolName, "toolId", "version");
      filterData = applyFilterData(filterData, FilterData.filterJobName, "jobName", "jobId");

      if (!_.isEqual(FilterData.filterStatus.length, 0)) {
        filterData = filterData.filter((x: any) => {
          return FilterData.filterStatus.some((y: any) => {
            return _.isEqual(y.toString(), x.statusId.toString());
          });
        });
      }
      filterData = filterData.filter((row: IRowData) => {
        const SearchText: string = strToLowerCase(search);
        const toolName: string = strToLowerCase(row.toolName);
        const messsage: string = strToLowerCase(row.statusMessage);
        return !_.isEqual(toolName.indexOf(SearchText), -1) || !_.isEqual(messsage.indexOf(SearchText), -1);
      });
      setExecutionData({ data: filterData, count: filterData?.length });
    }
    setFilters((prevState: any) => ({
      ...prevState,
      filterCount: FilterCount,
      sidebarFilter: {
        ...prevState.sidebarFilter,
        open: false,
        form,
      },
      page_number: 0,
      startDate: startDate,
      endDate: endDate,
      toolName: FilterData.filterToolName,
      jobName: FilterData.filterJobName,
      status: FilterData.filterStatus
    }));
  };

  const onApply = async (form: IForm): Promise<void> => {
    try {
      setLoading(true);
      filterAllData(form, filters.search.text);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const newStartDatedata = (val:any):any=>{
    if(_.gt(val, 15)){
        return moment(new Date(), "YYYY/MM/DD", true).subtract((val / 30), "months").format("YYYY/MM/DD") ;
    }else{
      return moment(new Date(), "YYYY/MM/DD").subtract(val, "d").format("YYYY/MM/DD");
    }

  };
  const handleChange = async (selection: any): Promise<void> => {
    _.isEqual(selection.value, 1) ? setIsCustom(true) : setIsCustom(false);
    setSelectedOption(selection);
    setIsSelectOpen(false);
    let newEndDate;
    let newStartDate;
    if (!_.isEqual(selection.value, 1)) {
      newEndDate = moment(new Date(), "YYYY/MM/DD", true).format("YYYY/MM/DD");
      newStartDate= newStartDatedata(selection?.value);
      if (!(_.isEqual(startDate, newStartDate) && _.isEqual(endDate, newEndDate))) {
        setFilters((prevState: any) => ({
          ...prevState,
          filterCount: 0
        }));
        getHistoryData(
          { start_date: newStartDate, end_date: newEndDate },
          () => {
            console.log("History callback");
          }
        );
        getDatasourceData(newStartDate, newEndDate);
        setIsFilter(false);
      }
    }
    else {
      newEndDate = endDate;
      newStartDate = startDate;
      setExecutionData({ data: isFilter ? executionData.data : data.data, count: isFilter ? executionData.count : data.count });
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setFilters((prevState: IFilters): any => ({
      ...prevState,
      page_number: 0,
      record_limit: 20,
      search: {
        text: "",
        typingTimeout: 0,
      },
      order_by: "executionendtime",
      order: "desc",
      startDate: startDate,
      endDate: endDate,
      toolName: [],
      jobName: [],
      status: [],
    }));
  };

  const handleDateChange = async (selectedDate: any): Promise<void> => {
    setIsSelectOpen(false);
    const newEndDate: any = moment(selectedDate.end_date, "YYYY/MM/DD", true).format("YYYY/MM/DD");
    const newStartDate: any = moment(selectedDate.start_date, "YYYY/MM/DD", true).format("YYYY/MM/DD");
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setIsFilter(false);
    if (!(_.isEqual(startDate, newStartDate) && _.isEqual(endDate, newEndDate))) {
      setIsCustom(true);
      setFilters((prevState: any) => ({
        ...prevState,
        page_number: 0,
        filterCount: 0
      }));
      getHistoryData(
        { start_date: selectedDate.start_date, end_date: selectedDate.end_date },
        () => {
          console.log("History callback");
        }
      );
      getDatasourceData(newStartDate, newEndDate);
    }
  };

  const handleSelectOpen = (status: boolean): any => {
    setIsSelectOpen(status);
  };

  return (
    <Fragment>
      <Helmet>
        <title>Tool Execution History</title>
      </Helmet>
      <div style={{ backgroundColor: "#FFFF", height: "auto", paddingTop: "10px" }}>
        <Container maxWidth="lg">
          <Box display="flex" className={classes.container}>
            <Box flexGrow={1}>
              <div className={classes.title}>
                {"History"}
              </div>
              <div className={classes.toolRecordCountText}>{`${totalExecutions} Executions`}</div>
            </Box>

            <Box style={{ marginTop: "10px" }}>
              {(!initialLoading && !_.isEqual(parseInt(totalExecutions), 0)) && (
                <Download
                  errorAlert={errorAlert}
                  successAlert={successAlert}
                  gridData={executionData.data}
                />
              )}
            </Box>
          </Box>
        </Container>
      </div>

      {!initialLoading && (
        <Container maxWidth="lg">
          <Grid container className={classes.root} alignItems="center">
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ justifyContent: "flex-end", marginBottom: "2px" }}
              spacing={2}
            >
              <div className={classes.search}>
                <div
                  className={classes.searchIcon}
                  style={{ opacity: loading ? 0.5 : 1 }}
                >
                  <IconSearch title={""} />
                </div>
                <Input
                  type="input"
                  onKeyDown={(event) => {
                    if (_.isEqual(event.code, "Enter")) {
                      onSearchChange(event);
                    }
                  }}
                  title="Please enter at least 3 characters."
                  placeholder="Search by Message or Tool Name"
                  onChange={(event) => onSearchChange(event)}
                  value={filters.search.text}
                  className={classNames(
                    classes.inputInput,
                    classes.inputElement
                  )}
                  inputProps={{ "aria-label": "search" }}
                  disabled={loading}
                  disableUnderline={true}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        style={{
                          visibility:
                            loading || _.isEqual(filters.search.text, "")
                              ? "hidden"
                              : "visible",
                        }}
                        size={"small"}
                        onClick={(event) => {
                          return onSearchChange(event);
                        }}
                      >
                        <CloseIcon
                          fontSize="small"
                          className={classes.closeButton}
                        />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>

              <div style={{ paddingLeft: 12, paddingRight: 12 }}>
                <SearchFilter
                  filterCount={_.isUndefined(filters.filterCount) ? 0 : filters.filterCount}
                  handleSearchChange={(value: string): void => {
                    onSearchChange(value);
                  }}
                  loading={false}
                  recordsCount={data.count}
                  onOpenFilter={() => {
                    setFilters((prevState: IFilters): any => ({
                      ...prevState,
                      sidebarFilter: {
                        ...prevState.sidebarFilter,
                        open: true,
                      },
                    }));
                  }}
                  searchValue={filters.search || ""}
                  hideFilter={false} searchPlaceholder={""} />
              </div>

              <div style={{ width: "auto", backgroundColor: "white", minWidth: "165px", zIndex: 2 }}>
                <Select
                  value={selectedOption}
                  components={{
                    DropdownIndicator: (optionData: any) => {
                      return (
                        <components.DropdownIndicator
                          {...optionData}
                          className="caret-select"
                        />
                      );
                    },
                  }}
                  styles={CustomStyles(false, false, false)}
                  onChange={handleChange}
                  onMenuOpen={() => {
                    handleSelectOpen(true);
                  }}
                  options={CALENDER_OPTIONS}
                  placeholder={"Select Option"}
                >
                </Select>
              </div>
              <div style={{ paddingLeft: "10px", zIndex: 2 }}>
                {selectedOption && <UIDateRangePicker
                  handleDateChange={handleDateChange}
                  selectedStartDate={startDate}
                  selectedEndDate={endDate}
                  selection={selectedOption}
                  isSelectOpen={isSelectOpen}
                  handleSelectOpen={handleSelectOpen}
                />}
              </div>
            </Grid>
          </Grid>

          {!_.isNull(filters.sidebarFilter) && (
            <SidebarComponent
              width={424}
              formTitle={"Filters"}
              btnSaveText="Apply"
              btnCancelText="Cancel"
              resetText="Reset"
              onSave={onApply}
              onReset={onReset}
              onCancel={() => {
                setFilters((prevState: IFilters): any => ({
                  ...prevState,
                  sidebarFilter: {
                    ...prevState.sidebarFilter,
                    open: false,
                  },
                }));
              }}
              formData={filters?.sidebarFilter!.form}
              open={filters?.sidebarFilter!.open}
              isFilterForm={true}
            />
          )}
          <GridTable
            data={executionData.data}
            totalCount={executionData.count}
            handleChangePage={handleChangePage}
            filters={filters}
            createSortHandler={createSortHandler}
            loading={loading}
            onDownloadHandler={onDownloadHandler_hist}
            expiryDateCheck={expiryDateCheck_hist}
          />

        </Container>
      )}
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        onOpenCloseAlert={handleCloseAlert}
      />
      {(loading || circularLoading) && <CircularProgress />}
    </Fragment>
  );
}

export default History;
