import { IReduxFilterState } from "./interfaces/IReduxFilterState";
import { UPDATE_SORTLABEL, UPDATE_STATE } from "../types/FilterState";

const Initstate: IReduxFilterState = {
	filter: {
		channel_id: 0,
		include_beta: 0,
		include_favorite: 0,
		lifecycle_id: 0,
		order_by: "asc",
		platform_id: [0, 3, 4, 2, 6, 1],
		search: "",
		toollabel_id: 0,
		tooltype_id: 0,
	},
	sortLabel: "Alphabet A to Z"
};

export default (state = Initstate, action: any = {}): any => {
	if (action.type === UPDATE_STATE) {
		return {
			...state,
			filter: action.payload
		};
	}
	else if (action.type === UPDATE_SORTLABEL) {
		if (action.payload === "asc") {
			return {
				...state,
				sortLabel: "Alphabet A to Z"
			};
		}
		else if (action.payload === "desc") {
			return {
				...state,
				sortLabel: "Alphabet Z to A"
			};
		}
		else {
			return {
				...state,
				sortLabel: "Recently Used"
			};
		}
	}
	else {
		return state;
	}
};