import * as _ from "lodash";

// Validation helper for Email
export class EmailValidation{

    /**
     * Validate email format
     *
     * @param {any} elementValue.
     * @return {string} errorMessages.
     */
    validate = (elementValue:any): string => {
    	const EmailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    	const ElementValue = (elementValue ||"").toString().trim().replace(/,/gi, ";");

    	const Emails = ElementValue.toString().split(";");
	
    	if(_.isEqual(ElementValue.toString().replace(/;/gi, "").trim().length, 0)){
    		return `${(elementValue ||"").toString().trim()} is not a valid email address.`;
    	}

    	const EmailAddresses = [];
    	for(const email of Emails){
    		const EmailAddress = (email || "").toString().trim();
    		const isValidEmail = EmailRegx.test(EmailAddress);

    		if(!isValidEmail && !_.isEqual(EmailAddress.length, 0)){
    			return `${EmailAddress} is not a valid email address.`;
    		}
    		else{
    			if(!_.isEqual(EmailAddresses.indexOf(EmailAddress), -1)
                && !_.isEqual(EmailAddress.length, 0)){
    				return `${EmailAddress} is duplicate email address.`;
    			}
    			else{
    				EmailAddresses.push(EmailAddress);
    			}
    		}
    	}
    	return "";
    }
}