const style: any = (): any => ({
    multiTooltip: {
        display: "flex",
        flexDirection: "row",
        gap: "0.8rem",
        wordBreak: "break-word"
    },
    label: {
        marginBottom: "8px",
    },
    value: {
        marginBottom: "9px",
        wordBreak: "break-word",
        overflow: "hidden",
        minHeight:"20px"
    }
});

export default style;