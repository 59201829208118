
import { GET_MARKETS_BY_REGION } from "../../../api/APIPaths/mapping";
import { TEXT_ALIGNMENT , ELEMENT_TYPES, ALL_OPTION_OBJ, GRID_CELL_ELEMENT } from "../configuration";

import { IFormElement } from "../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../Interfaces/Form/IForm";
import { IGridHeader } from "../Interfaces/Grid/IGridHeader";

export const getFormConfig = (formData: any): IForm =>{
	
	const ID: IFormElement  = {
		id:"id",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.ID,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		value: formData.id || "",
		visible: false,
		configuration:{
			dataSource: [],
			isMulti: false,
			isVisible: false,
			multiRows: 2,
			placeholder: "ID",
			title: "ID",
			defaultValue: null,
			dataSourceKey: "",
			allowAll:false,
			parentElements:[]
		},
		readOnly: true,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	const Platform: IFormElement  = {
		id:"platform",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.platform || null,
		configuration:{
			dataSourceKey:"platforms",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Platform",
			title: "Platform",
			defaultValue: null,
			allowAll:false,
			parentElements:[],
			readOnly:{
				newForm: false,
				editForm: true
			},
		},
		readOnly: true,
		readOnlyOptions:{
			title:"",
			subtitle:"",
			keys:{
				title:"label",
				subtitle:null
			}
		},
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	
	const Region: IFormElement  = {
		id:"region",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.region || null,
		configuration:{
			dataSourceKey:"regions",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Region",
			title: "Region",
			defaultValue: null,
			allowAll:false,
			apiKey:"region_id",
			parentElements:[]
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	const Market: IFormElement  = {
		id:"markets",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.market || null,
		configuration:{
			dataSourceKey:"markets",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Market(s)",
			title: "Market(s)",
			defaultValue: null,
			allowAll:false,
			parentElements:["region"],
			apiURL:GET_MARKETS_BY_REGION
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	const Agency: IFormElement  = {
		id:"agencies",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.agency || null,
		configuration:{
			dataSourceKey:"agencies",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Agency(s)",
			title: "Agency(s)",
			defaultValue: null,
			allowAll:false,
			parentElements:[]
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	const Mappers: IFormElement  = {
		id:"mappers",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.mappers || null,
		configuration:{
			dataSourceKey:"portalUsers",
			dataSource:  [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Mapper(s)",
			title: "Mapper(s)",
			defaultValue: null,
			allowAll:false,
			parentElements:[]
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

		const Instructions: IFormElement  = {
			id:"instructions",
			disabled: false,
			isValid: false,
			touched: false,
			type: ELEMENT_TYPES.TEXT_INPUT,
			validations:{
				isEmail: false,
				isNumber: false,
				required: true,
				len:{
					max: 300
				}
			},
			visible: true,
			errorMessage:"",
			value: formData.instructions || "",
			configuration:{
				dataSource: [],
				isMulti: true,
				isVisible: true,
				multiRows: 2,
				placeholder: "Instructions",
				title: "Instructions",
				defaultValue: null,
				dataSourceKey: "",
				allowAll:false,
				parentElements:[]
			},
			readOnly: false,
			apiError:"",
			highlightRemovedOptionError:"",
			highlightRemovedOption: false
		};
	
	return {
		id:"admin",
		title:"Mapping Manager",
		isValid: false,
		isVisible: true,
		groups: [{
			id:"group1",
			elements: [ID, Platform, Region, Market, Agency, Mappers, Instructions],
			isVisible: true,
			title:""
		}]
	};
};

export const getFilterFormConfig = (formData: any): IForm =>{

	const Platform: IFormElement  = {
		id:"platforms",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.platform || null,
		configuration:{
			dataSourceKey:"platforms",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Platform(s)",
			title: "Platform(s)",
			defaultValue: [ALL_OPTION_OBJ],
			allowAll:true,
			parentElements:[],
			apiURL:""
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	
	const Region: IFormElement  = {
		id:"regions",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.region || null,
		configuration:{
			dataSourceKey:"regions",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Region(s)",
			title: "Region(s)",
			defaultValue:[ALL_OPTION_OBJ],
			allowAll:true,
			parentElements:[],
			apiKey:"region_id"
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	const Market: IFormElement  = {
		id:"markets",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.market || null,
		configuration:{
			dataSourceKey:"markets",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Market(s)",
			title: "Market(s)",
			defaultValue: [ALL_OPTION_OBJ],
			allowAll:true,
			parentElements:["regions"],
			apiURL:GET_MARKETS_BY_REGION
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	const Agency: IFormElement  = {
		id:"agencies",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.agency || null,
		configuration:{
			dataSourceKey:"agencies",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Agency(s)",
			title: "Agency(s)",
			defaultValue:[ALL_OPTION_OBJ],
			allowAll:true,
			parentElements:[]
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	const Mappers: IFormElement  = {
		id:"mappers",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.mappers || null,
		configuration:{
			dataSourceKey:"portalUsers",
			dataSource:  [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Mapper(s)",
			title: "Mapper(s)",
			defaultValue: [ALL_OPTION_OBJ],
			allowAll:true,
			parentElements:[]
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};
	
	return {
		id:"mappingManagerFilter",
		title:"",
		isValid: true,
		isVisible: true,
		groups: [{
			id:"group1",
			elements: [Platform, Region, Market, Agency, Mappers],
			isVisible: true,
			title:""
		}]
	};
};

export const GRID_HEADERS: IGridHeader[] = [{
	defaultSortBy: false,
	id:"id",
	visible: false,
	title:"",
	type: ELEMENT_TYPES.ID,
	isMulti: false,
	sortingKey:"id",
	disableSorting: true,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},{
	defaultSortBy: true,
	id:"platform",
	visible: true,
	title:"Platform",
	type: ELEMENT_TYPES.SELECT_INPUT,
	isMulti: false,
	width:150,
	sortingKey:"platform",
	disableSorting: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},{
	defaultSortBy: true,
	id:"region",
	visible: true,
	title:"Region",
	type: ELEMENT_TYPES.SELECT_INPUT,
	isMulti: false,
	width:90,
	sortingKey:"region",
	disableSorting: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},{
	defaultSortBy: true,
	id:"markets",
	visible: true,
	title:"Market(s)",
	type: ELEMENT_TYPES.SELECT_INPUT,
	isMulti: true,
	sortingKey:"market",
	disableSorting: true,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: true,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},{
	defaultSortBy: true,
	id:"agencies",
	visible: true,
	title:"Agency(s)",
	type: ELEMENT_TYPES.SELECT_INPUT,
	isMulti: true,
	sortingKey:"agency",
	disableSorting: true,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: true,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},{
	defaultSortBy: true,
	id:"mappers",
	visible: true,
	title:"Mapper(s)",
	type: ELEMENT_TYPES.SELECT_INPUT,
	isMulti: true,
	sortingKey:"mappers",
	disableSorting: true,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: true,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},{
	defaultSortBy: true,
	id:"channelManager",
	visible: true,
	title:"Last Updated By",
	type: ELEMENT_TYPES.TEXT_INPUT,
	isMulti: false,
	sortingKey:"modifiedby",
	disableSorting: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	width:145,
	enableTooltip:{
		tooltipKey:"email",
		displayKey:"name"
	},
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},{
	defaultSortBy: true,
	id:"instructions",
	visible: true,
	title:"Instructions",
	type: ELEMENT_TYPES.TEXT_INPUT,
	isMulti: false,
	sortingKey:"instructions",
	disableSorting: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TEXT
}
];