import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { Button, Grid } from "@material-ui/core";
import { withRouter } from "react-router";
import { useSelector } from "react-redux";
import styles from "./style";
import { withErrorHandler, withSnackbar } from "../../../../HOC";
import StatusIndicator from "../../../../UI/StatusIndicator";

type props = any;

// Report tool box page component
const ReportTool = (props: props): React.JSX.Element => {
  const { classes, data } = props;
  const [random, setRandom] = useState(data.tool_url);

  const bundle = useSelector((state: any) => state.initial.bundle);
  const ReloadURI = (): any => {
    return setRandom(random + 1);
  };

  return (
    <Fragment>
      {!_.isUndefined(data) && !_.isEmpty(data) && (
        <Fragment>
          <Grid container className={classes.toolHeader}>
            <Grid container item alignItems="center">
              <Grid
                item
                xs={10}
                sm={10}
                md={10}
                lg={10}
                xl={10}
                className={classes.toolName}
              >
                {data.tool_name}
                <span className={classes.version}>{data.tool_version}</span>
                <StatusIndicator status={data.tool_label} />
              </Grid>
              <Grid
                container
                item
                xs={2}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                justifyContent="flex-end"
              >
                <Button
                  variant="outlined"
                  size="medium"
                  style={{
                    border: "1px solid rgb(134, 142, 150)",
                  }}
                  onClick={() => ReloadURI()}
                >
                  {bundle.RELOAD_BUTTON_TITLE}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            spacing={0}
            className={classes.iframContainer}
          >
            <iframe
              key={random}
              src={data.tool_url}
              className={classes.iframe}
            ></iframe>
          </Grid>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(
  withErrorHandler(withRouter(withSnackbar(ReportTool)))
);
