import * as _ from "lodash";
import { Axios } from "../../../../../../../api/service/Axios";
import { UI_ELEMENT_API_METHODS } from "../../../../../config";
import { IFormElement } from "../../../../interface/IFormElement";
import {
	IOption
} from "../../../../interface/IOption";
import { UtilHelper } from "../../../Utils";

// Single Select Element Helper 
export class SingleSelectHelper{
    private utilHelper: UtilHelper;
	constructor(){
    	this.utilHelper = new UtilHelper();
    }

	ifSingleSelectInputUndefined = (formElement: IFormElement, elementValue: any) : IFormElement =>{
		const Element = {...formElement};
		const Option:IOption = formElement.dataSource.find((option: IOption)=> 
    				_.isEqual((option.value || "").toString().trim(), (elementValue || "").toString()))!;
					
				Element.value =_.isUndefined(Option) ?  null : Option;      
    			if(formElement.highlight_removed_option){

    				const DataSourceOptions = formElement.dataSource.map((option:any) => option.value.toString().trim());
                    
    				const RemovedOptionIndex:number = DataSourceOptions.indexOf((elementValue || "").toString().trim());
                  
    				if(_.isEqual(RemovedOptionIndex, -1)){
    					Element.value = _.isArray(elementValue) ? null : {label: elementValue, value: elementValue, highlightOption: true};
    				}
    			}
				Element.touched = true;
			return Element;
	}

	ifSingleSelectInputNotUndefined = (formElement: IFormElement, elementValue: any): IFormElement =>{
		const Element = {...formElement};
		const Option:IOption = formElement.dataSource.find((option: IOption)=> 
    				_.isEqual((option.value || "").toString().trim(), (elementValue.value || "").toString()))!;
        
				Element.value = _.isUndefined(Option) ?  null : Option;
    			if(formElement.highlight_removed_option){

    				const DataSourceOptions = formElement.dataSource.map((option:any) => option.value.toString().trim());
                    
    				const RemovedOptionIndex:number = DataSourceOptions.indexOf((elementValue.value || "").toString().trim());
                   
    				if(_.isEqual(RemovedOptionIndex, -1)){
    					Element.value = _.isArray(elementValue) ? null : {...elementValue, highlightOption: true};
    				}
    			}
				Element.touched = true;
		return Element;
	}

    /**
     * Get single-select value
     *
     * @param {formElement} IFormElement.
     * @return {formElement} IFormElement.
     */
    getSingleDropdownValue = (formElement: IFormElement) : IFormElement =>{
        
    	let Element = {...formElement};
    	if(_.isNull(formElement.value)){
    		Element.value = null;
    		return Element;
    	}

		const FormElementValue = (formElement.value || "").toString().trim();

    	const elementValue = _.isUndefined(formElement.value) ? 
    		FormElementValue : formElement.value;
	
    	if(!_.isEqual(FormElementValue.length, 0) && _.isArray(formElement.dataSource)){
    		if(_.isUndefined(elementValue.value)){
    			Element = this.ifSingleSelectInputUndefined(formElement, elementValue);
    		}
    		else{
    			Element = this.ifSingleSelectInputNotUndefined(formElement, elementValue);
    		}
    		return Element;
    	}
    	Element.value = !_.isNull(Element.value) ? Element.value : null;
    	return Element;
    }

	/**
     * Set single-select value using API datasource
     *
     * @param {formElement} IFormElement.
     * @return {formElement} IFormElement.
     */
    getSingleAPIDropdownValue = async (formElement: IFormElement) : Promise<IFormElement> =>{
        
    	let Element: IFormElement = {...formElement};

		let value:IOption | null = null;
		try{
			let Data:any;
			if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.GET)){
				Data = await Axios.get(formElement.api_value_url);
			}
			else if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.POST)){
				Data = await Axios.post(formElement.api_value_url, {});
			}
			value = !_.isObject(Data.data) ? null : Data.data;
			
			Element.apiError = "";
		}
		catch(error:any){
			value = null;
			Element.apiError = this.utilHelper.getErrorMessage(error);
		}
		Element = this.getSingleSelectAPIValue(formElement, value);
    	return Element;
    }

	getSingleSelectAPIValue = (formElement: IFormElement, option: IOption | null): IFormElement=>{
		const Option = !_.isObject(option) ? null : option;
		let Element = {...formElement};
		if(_.isNull(Option)){
			Element.value = null;
		}else if(this.isValidOption(Element.dataSource, Option)){
			Element = this.ifSingleSelectInputNotUndefined(Element, Option);
		}
		else{
			Element.value =  null;
		}
		Element.valueDataSource = option;
		return Element;
	}

	/**
     * Check if option is present in the datasource
     *
     * @param {dataSource} IOption[]
     * @param {option} IOption
     * @return boolean.
     */
	isValidOption = (dataSource: IOption[], option: IOption): boolean => {

		if(!_.isObject(option)){
			return false;
		}

		if(_.isUndefined(option.value)){
			return false;
		}

		const Value = dataSource.findIndex((op: IOption)=> op.value, option.value);

		return !_.isEqual(Value, -1);
	}
}
