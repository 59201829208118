
const style: any = (): any => ({
    button:{
        width:120,
		padding: 5,
		borderRadius: 0,
		opacity:1,
		color: "rgba(0,0,0,0.9)",
    },
	container:{
		border: "1px solid #d0d5da"
	},
	unSelectedOption:{
		opacity:0.6
	},
	mainContainer:{
		marginLeft:8,
		marginBottom:20
	}
});

export default style;