import React, { Fragment } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "./style";
import UITooltip from "../../Elements/Tooltip";
import classNames from "classnames";
import { statusIndicator } from "../../../Mapper/Configuration/GoogleAds";


type props = {
  status: string;
};

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const StatusIndicator = (props: props): React.JSX.Element => {
  const { status } = props;
  const classes = useStyles();
  const StatusText = _.isUndefined(status) ? "" : status.toLowerCase();
  
  const Title : string = statusIndicator(status);

  return (
    <Fragment>

      <UITooltip title={Title}>
        <div className={classNames(classes[StatusText], classes.text)}>
          {StatusText}
        </div>
      </UITooltip>
    </Fragment >
  );
};
