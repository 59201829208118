

const style: any = (): any => ({ 
    error:{
        minHeight: "1.25rem",
        marginTop:"0.25rem",
        color: "#b82533",
        fontSize: "0.75rem",
        width:"100%",
        "word-break":"break-word",
        "font-weight":"normal",
        marginBottom:"0.5rem"
    },
    note:{
        minHeight: "1.25rem",
        marginTop:"0.25rem",
        fontSize:"0.75rem",
        color:"#6a737c",
        "word-break":"break-word",
        "font-weight":"normal",
        width:"100%",
        "& ul, ol":{
            paddingLeft: "1.5rem",
            marginTop: "0.25rem"
        },
        "& a":{
            color:"#005fbe",
            textDecoration:"none"
        },
        marginBottom:"0.5rem"
    },});

export default style;