import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../UI/Theme/colors";

const style: any = (theme: Theme) => ({
	gridTable: {
		tableLayout: "fixed",
		width: "100%",
		borderCollapse: "collapse",
		position: "relative"
	},
	gradient: {
		backgroundImage: "linear-gradient(90deg, #F3F5F7 0px, #F7F8FA 40px, #F3F5F7 80px)",
		backgroundSize: 600,
		animation: "shine-lines 1.6s infinite linear",
		backgroundColor: COLORS.BASECOLOR
	},
	headers: {
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
		color: "#6a737c",
		padding: "11px 20px",
	},
	statusMessage: {
		wordBreak: "break-all"
	},
	sortingIcons: {
		padding: "0px 5px",
		verticalAlign: "middle"
	},
	gridHeaderContainer: {
		height: 40,
		padding: "5px 0 4px 16px"
	},
	gridHeader: {
		borderBottom: "1px solid rgba(224, 224, 224, 1)",
		position: "sticky",
		top: 56,
		backgroundColor: COLORS.WHITE,
		zIndex: 1
	},
	gridHeaderBoxShadow: {
		boxShadow: "0 2px 2px -2px #b3b3b3"
	},
	gridHeaderName: {
		fontSize: "0.75rem",
		color: COLORS.GRAY,
		fontWeight: 600,
		display: "flex",
		alignItems: "center"
	},
	cursorPointer: {
		cursor: "pointer",
	},
	gridCell: {
		minWidth: 0,
		padding: "0.5rem 0px 0.5rem 1rem",
		minHeight: 48,
		verticalAlign: "top",
	},
	CheckBoxCell: {
		minWidth: 0,
		padding: "0.5rem 0px 0.5rem 1.1rem",
		minHeight: 48,
		verticalAlign: "top",
	},
	gridEmptyCell: {
		border: `1px solid ${COLORS.ERROR}`,
		width: "100%",
		height: 40,
		borderRadius: 2
	},
	gridCellText: {
		fontSize: "0.875rem",
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		minWidth: 0,
		paddingLeft: 13,
		paddingTop: 2
	},
	gridCellInput: {
		fontSize: "0.875rem",
		width: "100%"
	},
	gridActionHeader: {
		width: 76,
		paddingRight: 20,
		fontSize: "0.75rem",
		color: COLORS.GRAY,
		fontWeight: 600,
		textAlign: "center"
	},
	gridActionCell: {
		width: 76,
		fontSize: "0.75rem",
		color: COLORS.GRAY,
		fontWeight: 600,
		textAlign: "center"
	},
	gridCheckboxCell: {
		width: 76,
		fontSize: "0.75rem",
		color: COLORS.GRAY,
		fontWeight: 600,
		textAlign: "left",
	},
	gridRow: {
		"&:hover": {
			backgroundColor: COLORS.BASECOLOR,
		},
	},
	sortingIcon: {
		padding: "0px 5px",
		verticalAlign: "middle"
	},
	deleteIcon: {
		visibility: "hidden"
	},
	highlightNewRow: {
		animation: `$animHighlightNewRow 1500ms ${theme.transitions.easing.easeInOut}`
	},
	"@keyframes animHighlightNewRow": {
		"0%": {
			backgroundColor: COLORS.YELLOW_COLOR,
		},
		"100%": {
			backgroundColor: COLORS.WHITE,
		}
	},
	noDataAvailable: {
		color: "#6a737c",
		fontSize: 16,
		fontWeight: 600,
		marginRight: 6,
		textAlign: "center",
		padding: 12,
		height: 94,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%"
	},
	centerAlign: {
		display: "flex",
		justifyContent: "center"
	},
	leftAlign: {
		display: "flex",
		justifyContent: "flex-start"
	},
	rightAlign: {
		display: "flex",
		justifyContent: "flex-end"
	},
	loadingCell: {
		height: 21,
		width: "100%",
		borderRadius: 2,
		display: "inline-block"
	},
	iconCheckbox: {
		borderRadius: "2px",
		border: "1px solid rgba(58,145,232, 1)",
		opacity: 1,
		backgroundColor: "rgba(255,255,255, 0)",
		width: "20px",
		height: "20px"
	},
	cancelText: {
		color: "black",
		fontWeight: "bolder"
	}
});

export default style;
