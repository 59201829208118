import React, { MouseEventHandler } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableContainer, TableRow } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import classnames from "classnames";
import GridHead from "./PreviewHeaderset";
import GridCell from "./PreviewBodyset";
import style from "./style";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import { IGridHeader } from "../../../Forms/interface/Grid/IGridHeader";
import { ExcelHelper } from "../../../Forms/helper/Utils/FileUtil/Excel";
import { UI_GRID_CELL_EDITTOR_TYPES } from "../../../config";
import { IOption } from "../../../Forms/interface/IOption";
import GridLoaderset from "./GridLoaderset";
import UILabel from "../../Child/Label";
import { UIErrorOrNoteMessage } from "../../Child/ErrorOrNote";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  formElement: IFormElement;
  enableEdit?: boolean;
  onEditGrid?: MouseEventHandler;
  errorMessage?: string;
  loading?:boolean;
  editHideFromReview?:boolean;
  ishavingParent?:any;
};

export default function PreviewGridset(props: props): React.JSX.Element {
  const classes = useStyles();
  const { formElement, enableEdit, errorMessage ,editHideFromReview,ishavingParent} = props;
  const loadshow = !_.isEqual(formElement.parent_elements.indexOf(ishavingParent), -1);
  if(_.isEqual(formElement?.dataSource?.length,0) && !_.isNull(formElement?.value) ){
    if(_.isEqual(formElement?.value?.length,0)){
      formElement.dataSource= formElement?.value;
    }
   }else{
    if(!_.isEqual(formElement?.dataSource?.length,0)){
      formElement.value =formElement?.dataSource;
    }
   }
   
  const DynamicGridData = formElement.dataSource || [];
  const HeaderData: IGridHeader[] =
    (formElement.customConfiguration?.grid?.headerData) || [];
  const GridData = _.slice(DynamicGridData, 0, 5);

  const TblBody = GridData.map((row: any, index: number) => {
    const TblCells = HeaderData.filter(
      (header: IGridHeader) => !header.hide
    ).map((header: any, i: number) => {
      let elementValue = row[header.field];
      if (_.isEqual(header.type, UI_GRID_CELL_EDITTOR_TYPES.DROPDOWN)) {
        const ElementValue = (elementValue || "").toString().trim();
        const DropDownValue: any = (
          _.isArray(header?.dataSource?.data) ? header?.dataSource?.data : []
        ).find((option: IOption) =>
          _.isEqual(_.toLower(option.value), _.toLower(ElementValue))
        );

        elementValue = _.isUndefined(DropDownValue) ? "" : elementValue;
      }

      return <GridCell key={i} data={elementValue} />;
    });
    return (
      <TableRow key={index} className={classes.tableRow}>
        {TblCells}
      </TableRow>
    );
  });

  const ExportToExcel = (): void => {
    const excelHelper = new ExcelHelper();
    const UpdatedSheetData = excelHelper.generateExcelDataCustomConfig(
      formElement.dataSource,
      formElement
    );
    const fileName = formElement.customConfiguration?.grid?.exportOptions?.fileName;
    const sheetName = formElement.customConfiguration?.grid?.exportOptions?.sheetName;
    const FileName = `${fileName}${moment(new Date()).format(
      "yyyy_MM_DD_HH_mm"
    )}`;
    excelHelper.export(
      UpdatedSheetData,
      FileName || "data",
      sheetName || "datasheet"
    );
  };

 const showOnParentCall = !_.isEqual(formElement.parent_elements.length,0) && !_.isUndefined(formElement?.isParentCalled) && formElement?.isParentCalled  ? true : (_.isEqual(formElement.parent_elements.length,0) ? true :false);

  return (
    <>
    <TableContainer>
   <div className={classes.previewHeaderContainer}>
    <div className={classes.previewHeader}>
          <div className={classes.previewLabel}>
          {editHideFromReview && <UILabel formElement={formElement} />}
            </div>
           {!formElement.read_only && showOnParentCall && editHideFromReview  &&<div
              className={classnames(classes.previewLinks, classes.editLink)}
              onClick={props.onEditGrid}
            >
              Edit
            </div>}
        </div>
        {enableEdit  && <div className={classes.previewLinks} onClick={() => ExportToExcel()}>
          Download
        </div>}
      </div>
      <div className={classes.tableWrapper}>
       
        <Table className={classes.table}>
          <GridHead
            headers={formElement.customConfiguration?.grid?.headerData || []}
          />
          {loadshow ? (
             <GridLoaderset
             loadHeader={false}
             rows={5}
             headers={formElement.customConfiguration?.grid?.headerData || []}
             isGridAction={false}
           />
          ) : (<TableBody>{TblBody}</TableBody>)}
        </Table>
        {!loadshow && _.isEmpty(GridData) && 
            <div className={classes.noDataAvailable}>
                { <div>No data available for selected criteria</div>}
            </div>
        }
      </div>
    </TableContainer>
    
    <UIErrorOrNoteMessage
    formElement={formElement}
    errorMessage={  errorMessage ? errorMessage : ""}
  />
  </>
  );
}
