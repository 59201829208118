import * as _ from "lodash";
import { IFormElement } from "../../../interface/IFormElement";
import { UtilHelper } from "../../Utils";
import { RadioCheckboxUtil } from "../../Utils/RadioCheckbox";
import { UI_ELEMENT_TYPES } from "../../../../config";

// Radio and Checkbox element
export class RadioCheckboxElement{
    private radioCheckboxUtil: RadioCheckboxUtil;

    constructor(){
    	this.radioCheckboxUtil = new RadioCheckboxUtil();
    }

    /**
     * Created radio and checkbox element list
     *
     * @param {formElement} IFormElement.
     * @return IFormElement
     */
     buildRadioOrCheckbox = (formElement: IFormElement) :IFormElement =>{
     	const ListNames = (formElement.list_names || "").split(",");
     	const ListValues = (formElement.list_values || "").split(",");

     	if(!_.isEqual(ListNames.length, ListValues.length)) return {
     		...formElement,
     		value: null,
     		dataSource: []
     	};

     	const DataSource= [];
     	for(let i=0;i<ListNames.length;i++){
     		DataSource.push({
     			label: (ListNames[i] || "").toString().trim(),
     			value: (ListValues[i] || "").toString().trim()
     		});
     	}

     	if(formElement.allow_all && _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.CHECKBOX_INPUT)){
     		DataSource.unshift(UtilHelper.allOptionLblVal);
     	}

     	let elementValue = "";

     	if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.CHECKBOX_INPUT)){
     		elementValue = this.radioCheckboxUtil.getCheckboxValues(DataSource,formElement.value);
     	}
     	else if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.RADIO_INPUT)){
     		elementValue = this.radioCheckboxUtil.getRadioValue(DataSource, formElement.value);
     	}
        
     	const isDisabled = !_.isEqual(formElement.parent_elements.length, 0) || _.isEqual(formElement.disabled, 1);

     	return {
     		...formElement,
     		disabled: isDisabled,
     		dataSource: DataSource, 
     		value: elementValue
     	};
     }

}
