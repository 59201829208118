import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	header: {
		display: "block",
		padding: "32px 28px 24px 28px",
		minHeight: 92,
		backgroundColor: COLORS.WHITE,
		alignItems: "center",
	},
	title: {
		alignItems: "center",
		fontSize: 24,
		fontWeight: theme.typography.fontWeightBold,
		display: "flex",
	},
	container: {
		backgroundColor: COLORS.WHITE
	},
	ifTab: {
		paddingBottom: 0
	},
	toolBoxCountText: {
		fontSize: 14,
		paddingTop: 8,
		letterSpacing: "normal",
		color: COLORS.GRAY,
	},
	signoutDiv:{
		position:"relative"
	},
	signoutTitle:{
		position:"absolute",
		left:0,
		alignItems: "center",
		fontSize: 24,
		fontWeight: theme.typography.fontWeightBold,
		display: "flex",
	},
	signoutChildTitle:{
		position:"absolute",
		right:0,
		alignItems: "center",
		fontSize: 24,
		fontWeight: theme.typography.fontWeightBold,
		display: "flex",
	}
});

export default style;