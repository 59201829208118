
const style: any = (): any => ({
	root: {
		"&:hover": {
			backgroundColor: "transparent",
		},
	},
	icon: {
		borderRadius: "50%",
		width: 18,
		height: 18,
		boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
		backgroundColor: "#f5f8fa",
		backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		"$root.Mui-focusVisible &": {
			outline: "2px auto rgba(19,124,189,.6)",
			outlineOffset: 2,
		},
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5",
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)",
		},
	},
	checkedIcon: {
		height: 18,
		width: 18,
		backgroundColor: "rgb(58, 145, 232)",
		backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			width: 18,
			height: 18,
			backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
			content: "\"\"",
		},
		"input:disabled ~ &": {
			backgroundColor: "#137cbd !important",
			opacity:"0.5"
		},
		"input:hover ~ &": {
			backgroundColor: "#137cbd",
		},
	},
	label: {
		display: "inline-flex",
	},
});

export default style;
