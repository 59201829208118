import React, { useEffect, useState } from "react";
import { IFormElementValidation } from "../../Interfaces/Form/FormElement/IFormElementValidation";
import { Input } from "@material-ui/core";
import style from "./style";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import commonStyle from "../../../../UI/Elements/FormElements/sharedStyle";
import { IFormElementConfig } from "../../Interfaces/Form/FormElement/IFormElementConfig";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import classNames from "classnames";

// Create styles
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  errorMessage: string;
  onChange: any;
  formElement: IFormElement;
};

// UIInput Component
export const UITextInput = (props: props): React.JSX.Element => {
  const { formElement, onChange, errorMessage } = props;
  const Value = formElement.value || "";
  const classes = useStyles();
  const [input, setInput] = useState(Value);
  const Disabled = formElement.readOnly;
  const commonClasses = useCommonStyles();
  
  const ElementValidation: IFormElementValidation = formElement.validations;
  const ElementConfiguration: IFormElementConfig = formElement.configuration;

  const isError = !_.isEmpty(errorMessage);
  let Props = {};


  if (ElementConfiguration.isMulti) {
    Props = {
      ...Props,
      multiline: true,
      rows: 5,
    };
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!_.isEqual(Value, input)) {
        onChange(input);
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [input]);

  useEffect(() => {
    setInput(Value);
  }, [Value]);

  return (
    <Input
    {...Props}
    type="text"
    className={classes.inputContainer}
    id={`id-${formElement.id}`}
    required={ElementValidation.required}
    value={input}
    placeholder={ElementConfiguration.placeholder}
    onBlur={(): void => {
      return onChange(formElement.value);
    }}
    onChange={(event: any) => {
        const InputValue = event.target.value;
        setInput(InputValue);
      }}
      inputProps={{
        style: {
          height: ElementConfiguration.isMulti ? "auto" : 40,
        },
        autoComplete: "off",
        className: classNames(classes.input, {
          [classes.inputElement]: !Disabled && !isError,
          [commonClasses.error]: isError,
          [commonClasses.disabled]: Disabled,
        }),
      }}
    />
  );
};
