import * as _ from "lodash";
import moment from "moment";
import { DateUtil } from "../../Forms/helper/Utils/Date";
import { IFormElement } from "../../Forms/interface/IFormElement";


export const getPropsAndDateFormat = (formElement: IFormElement): any =>{
	let Props: any = {};

	if (!_.isNull(formElement.min_range)) {
	  Props = {
		...Props,
		minDate: new Date(formElement.min_range),
	  };
	}
	if (!_.isNull(formElement.max_range)) {
	  Props = {
		...Props,
		maxDate: new Date(formElement.max_range),
	  };
	}

	const dateFormat = formElement.show_time ? DateUtil.dateTimeFormat : DateUtil.dateFormat;
  
	return {
		props: Props,
		dateFormat
	};
};

const getMomentDate = (date: any, formElement: IFormElement): Date =>{
	const dateFormat = formElement.show_time
      ? DateUtil.momentDateTimeFormat
      : DateUtil.momentDateFormat;
	const LocalDateTime: any = moment(date, dateFormat, true).local();
	return new Date(LocalDateTime);
};

export const getStartAndEndDateRange = (formElement: IFormElement): any =>{
	if(_.isNull(formElement.value)){
		return [null, null];
	}
	const [startDate, endDate] = formElement.value;

	if(_.isNull(startDate) && _.isNull(endDate)){
		return [null, null];
	}

	if(!_.isNull(startDate) && _.isNull(endDate)){
		const StartDate: any = getMomentDate(startDate, formElement);
		return [new Date(StartDate), null];
	}

	if(_.isNull(startDate) && !_.isNull(endDate)){
		const EndDate: any = getMomentDate(endDate, formElement);
		return [null, EndDate];
	}

	if(!_.isNull(startDate) && !_.isNull(endDate)){
		const StartDate: any = getMomentDate(startDate, formElement);
		const EndDate: any = getMomentDate(endDate, formElement);
		return [StartDate, EndDate];
	}
	
	return [null, null];
};