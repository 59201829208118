import COLORS from "../../colors";

import { Theme } from "@material-ui/core/styles";
const style: any = (theme: Theme): any => ({
	sideBarContainer: {
		height: "calc(100% - 72px)",
		overflow: "auto",
		padding: "32px 40px 0px 40px",
	},
	sideBarTitle: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: 20,
	},
	sidebarHeader: {
		alignItems: "center",
		display: "flex",
		marginBottom: 20,
		justifyContent: "space-between",
	},
	
	filterButton: {
		backgroundColor: COLORS.WHITE,
		border: "1px solid rgb(208, 213, 218)",
		padding: "7px 12px 8px 12px",
		color: COLORS.DARK_GRAY,
		fontWeight: theme.typography.fontWeightMedium,
		"&:hover": {
			backgroundColor: COLORS.WHITE,
			border: "1px solid #868e96",
		}
	},
	
	actionContainer: {
		position: "absolute" as const,
		padding: "0px 40px",
		height: 72,
		display: "flex",
		bottom: 0,
		alignItems: "center" as const,
		backgroundColor: COLORS.WHITE,
		justifyContent: "flex-end",
	},
	resetBtn: {
		position: "absolute" as const,
		bottom: 31,
		top: 20,
		left: 34,
		height: "21px",
		paddingLeft: 0,
		width: "39px",
		fontSize: "14px",
		fontWeight: "500",
		margin: "8px 244px 7px 0",
		fontStyle: "normal",
		lineHeight: "1.5",
		fontStretch: "normal",
		color: "#005fbe",
		letterSpacing: "normal",
	},
	filterDot: {
		fontSize: "2.2rem",
		paddingBottom: "0.1rem",
		margin: "0 0.2rem",
	},
});

export default style;