import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	container:{
		marginBottom:"1.5rem"
	},
	title: {
		fontSize:"1rem",
		color: COLORS.DARK_GRAY,
		fontWeight: theme.typography.fontWeightBold,
		marginBottom:8
	},
	subtitle: {
		fontSize:"0.75rem",
		color: COLORS.GRAY,
		fontWeight: theme.typography.fontWeightMedium
	},
	notification:{
		fontSize: 12,
		alignItems: "center",
		marginTop: 24,
		width:"100%",
		color: COLORS.GRAY,
		backgroundColor:COLORS.SHAINCOLOR,
		padding:"12px 13px 11px 13px",
		fontWeight: theme.typography.fontWeightMedium
	},
	placeholderContainer:{
		display:"flex"
	},
	placeholderLabel:{
		"&::after":{
			content:" : "
		}
	}
});

export default style;