import React, { Fragment } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { IFormElement } from "../../../Interface/FormElement/IFormElement";
import UILabel from "../ChildElements/Label";
import { UIErrorOrNoteMessage } from "../ChildElements/ErrorOrNote";

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: string;
  formGroupId: string;
};

// UIHTMLInput Component
export const UIHTMLEditor = (props: props): React.JSX.Element => {
  const { formElement, formId, formGroupId, onChange } = props;
  const errorMessage = formElement.touched ? formElement.errorMessage : "";

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <CKEditor
        editor={ClassicEditor}
        data={formElement.value}
        config={{
          toolbar: [
            "bold",
            "italic",
            "link",
            "numberedList",
            "bulletedList",
            "|",
            "undo",
            "redo",
          ],
        }}
        onChange={(event: any, editor: any): void => {
          const data = editor.getData();
          onChange({ formId, formGroupId, formElement, value: data });
        }}
        onBlur={(): void => {
          onChange({
            formId,
            formGroupId,
            formElement,
            value: formElement.value,
          });
        }}
      />
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={errorMessage}
      />
    </Fragment>
  );
};
