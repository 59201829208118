import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";

type props = {
  title: string;
};

// Search Icon functional component
export const IconSearch = (props: props): JSX.Element => {
  const { title } = props;

  return (
    <Tooltip title={title}>
      <IconButton aria-label={title} size={"small"}>
        <svg
          width="16px"
          height="16px"
          viewBox="0 0 16 16"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g
            id="Branding/Icons/Search-line"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="search-line">
              <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
              <path
                d="M12.0206667,11.078 L14.876,13.9326667 L13.9326667,14.876 L11.078,12.0206667 C10.0158255,12.8721474 8.69466738,13.3352701 7.33333333,13.3333333 C4.02133333,13.3333333 1.33333333,10.6453333 1.33333333,7.33333333 C1.33333333,4.02133333 4.02133333,1.33333333 7.33333333,1.33333333 C10.6453333,1.33333333 13.3333333,4.02133333 13.3333333,7.33333333 C13.3352701,8.69466738 12.8721474,10.0158255 12.0206667,11.078 Z M10.6833333,10.5833333 C11.5294086,9.7132571 12.0019113,8.54695285 12.0000058,7.33333333 C12.0000058,4.75466667 9.91133333,2.66666667 7.33333333,2.66666667 C4.75466667,2.66666667 2.66666667,4.75466667 2.66666667,7.33333333 C2.66666667,9.91133333 4.75466667,12.0000058 7.33333333,12.0000058 C8.54695285,12.0019113 9.7132571,11.5294086 10.5833333,10.6833333 L10.6833333,10.5833333 L10.6833333,10.5833333 Z"
                id="Shape"
                fill="#6A737C"
              ></path>
            </g>
          </g>
        </svg>
      </IconButton>
    </Tooltip>
  );
};
