
import _ from "lodash";
import { USER_PROFILE_SAVE_DATA } from "../../../../api/paths";
import { Axios } from "../../../../api/service/Axios";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { IOption } from "../../Interface/IOption";

export class SubmitFormHelper {

	public getSubmitForm(forms: IForm[]): any {
		const FormData: any = {};
		forms.forEach((form: IForm) => {
			form.groups.forEach((formGroup: IFormGroup) => {

				formGroup.elements.forEach((formElement: IFormElement) => {
					FormData[formElement.id] = formElement.value;
				});
			});
		});

		const payload = {
			agencyid: FormData.agencyid.value,
			subagencyid: FormData.subagencyid.value,
			officelocation: FormData.officeLocation.value,
			worksforactivation: FormData.workForActivation ? 1 : 0,
			worksforhub: FormData.workForHub ? 1 : 0,
			hublocation: _.isNull(FormData.hubLocation) ? null : FormData.hubLocation.value,
			isactive: 1,
			deactivationdate: null,
			UserChannelsMapping: _.isNull(FormData.channel) ? [] : this.getSelectValue(FormData.channel, "channelid")
		};
		return payload;
	}

	private getSelectValue = (value: any, labelKey: string): any => {
		return value.map((option: IOption) => ({
			[labelKey]: option.value
		}));
	}

	public saveProfile = (userId: number, formData: any): Promise<void> => {
		return Axios.put(USER_PROFILE_SAVE_DATA(userId), formData);
	}
}
