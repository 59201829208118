import * as _ from "lodash";
import XLSX from "xlsx";
import { v4 as uuid4 } from "uuid";
import { IFormElement } from "../../../interface/IFormElement";
import { IGridHeader } from "../../../interface/Grid/IGridHeader";
import { UI_GRID_CELL_EDITTOR_TYPES } from "../../../../config";
import { IOption } from "../../../interface/IOption";

// Excel file Helper
export class ExcelHelper{

    /**
     * Read Excel File
     *
     * @param {IOption[]} dataSource.
     * @param {string} value.
     * @return string.
     */
    readExcelFile = (file: any): any =>{

    	return new Promise<any> ((resolve, reject)=>{
    		const reader = new FileReader();
    		reader.onload = (event:any)=>{
    			try{
    				const data = event.target.result;
    				const Workbook = XLSX.read(data, {type: "binary"});
    				const SheetName = Workbook.SheetNames[0];
    				const SheetData = XLSX.utils.sheet_to_json(Workbook.Sheets[SheetName], {
    					header:0,
    					defval:""
    				});
    				resolve(SheetData);
    			}
    			catch(error: any){
    				reject("Unable to read the selected file");
    			}
    		};
    		reader.onerror = ()=>{
    			reject("Unable to read the selected file");
    		};
    		reader.readAsBinaryString(file);
    	});
        
    }

    getHeaders = (sheet: any): any[] =>{
    	let header=0;
 
    	const hdr=[];
    	const o: any = {};
    	if (sheet == null || sheet["!ref"] == null) return [];
    	const range = o.range !== undefined ? o.range : sheet["!ref"];
    	let r: any;
    	if (o.header === 1) header = 1;
    	else if (o.header === "A") header = 2;
    	else if (Array.isArray(o.header)) header = 3;
    	switch (typeof range) {
    	case "string":
    		r = this.safe_decode_range(range);
    		break;
    	case "number":
    		r = this.safe_decode_range(sheet["!ref"]);
    		r.s.r = range;
    		break;
    	default:
    		r = range;
    	}

    	const rr = XLSX.utils.encode_row(r.s.r);
    	const cols = new Array(r.e.c - r.s.c + 1);
    	for (let C = r.s.c; C <= r.e.c; ++C) {
    		cols[C] = XLSX.utils.encode_col(C);
    		const val = sheet[cols[C] + rr];
    		switch (header) {
    		case 1:
    			hdr.push(C);
    			break;
    		case 2:
    			hdr.push(cols[C]);
    			break;
    		case 3:
    			hdr.push(o.header[C - r.s.c]);
    			break;
    		default:
    			if (val === undefined) continue;
    			hdr.push(XLSX.utils.format_cell(val));
    		}
    	}
    	return hdr;
    }

    safe_decode_range = (range: any): any => {
    	const o = {s:{c:0,r:0},e:{c:0,r:0}};
    	let idx = 0, i = 0, cc = 0;
    	const len = range.length;
    	for(idx = 0; i < len; ++i) {
    		if((cc=range.charCodeAt(i)-64) < 1 || cc > 26) break;
    		idx = 26*idx + cc;
    	}
    	o.s.c = --idx;
    
    	for(idx = 0; i < len; ++i) {
    		if((cc=range.charCodeAt(i)-48) < 0 || cc > 9) break;
    		idx = 10*idx + cc;
    	}
    	o.s.r = --idx;
    
    	if(i === len || range.charCodeAt(++i) === 58) { o.e.c=o.s.c; o.e.r=o.s.r; return o; }
    
    	for(idx = 0; i != len; ++i) {
    		if((cc=range.charCodeAt(i)-64) < 1 || cc > 26) break;
    		idx = 26*idx + cc;
    	}
    	o.e.c = --idx;
    
    	for(idx = 0; i != len; ++i) {
    		if((cc=range.charCodeAt(i)-48) < 0 || cc > 9) break;
    		idx = 10*idx + cc;
    	}
    	o.e.r = --idx;
    	return o;
    }

    /**
     * Generate Grid data based on selected file
     *
     * @param {File} file.
     * @param {IFormElement} formElement.
     * @return {Array} Object.
     */
    generateGridData = async (fileData: any, formElement: IFormElement): Promise<any> =>{
    	const HeaderDefs: any = {};
    	formElement.dynamic_configuration.headerData.map((header: IGridHeader)=>{
    		HeaderDefs[header.headerName] = header.field;
    	});
    	return fileData.map((data: any)=>{
    		const GridRow: any  = {};
    		_.each(data, (value, key)=>{
    			let objKey = _.trim(key);
    			objKey = HeaderDefs[objKey] || objKey;
    			GridRow[objKey] = value;
    		});
    		return ({
    			id: uuid4(),
    			...GridRow
    		});
    	}).map((data: any) => {
			const Data: any = {};
			formElement.dynamic_configuration.headerData
			  .map((header: IGridHeader) => {
				let elementValue = data[header.field];
				  if (_.isEqual(header.type, UI_GRID_CELL_EDITTOR_TYPES.DROPDOWN)) {
					const ElementValue = (elementValue || "").toString().trim();
			        const d = header?.dataSource?.data;
					const DropDownValue: any = (_.isArray(d) ? d : []).find((option: IOption) =>
					  _.isEqual(_.toLower(option.value), _.toLower(ElementValue))
					);
					elementValue = _.isUndefined(DropDownValue) ? "" : elementValue;
				  }
				  Data[header.field] = elementValue;
			  });
		
			return Data;
		});
    }

    /**
     * Generate Excel data based on gri data
     *
     * @param {any} gridData.
     * @param {IFormElement} formElement.
     * @return {Array} Object.
     */
     generateExcelData = (gridData: any, formElement: IFormElement): Promise<any> =>{
     	const HeaderDefs: any = {};
     	formElement.dynamic_configuration.headerData.map((header: IGridHeader)=>{
     		HeaderDefs[header.field] = header.headerName;
     	});
     	return gridData.map((data: any)=>{
     		const GridRow: any  = _.omit({...data}, ["id"]);
     		const UpdateRow: any = {};
     		_.each(GridRow, (value, key)=>{
     			let objKey = _.trim(key);
     			objKey = HeaderDefs[objKey] || objKey;
     			UpdateRow[objKey] = value;
     		});
     		return ({
     			...UpdateRow
     		});
     	});
     }

    /**
     * Export data to Excel
     *
     * @param {any} sheetData.
     * @param {string} fileName.
     * @param {string} sheetName.
     * @return {Array} Object.
     */
    export = (sheetData: any, fileName: string, sheetName: string): any =>{
    	const worksheet = XLSX.utils.json_to_sheet(sheetData);
        
    	const workbook: XLSX.WorkBook = {
    		Sheets: {
    			[sheetName]: worksheet,
    		},
    		SheetNames: [sheetName],
    	};
        
    	const excelBuffer = XLSX.write(workbook, {
    		bookType: "xlsx",
    		type: "array",
    	});
        
    	const ExcelData = new Blob([excelBuffer], {
    		type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    	});
        
    	const link = document.createElement("a");
    	link.href = URL.createObjectURL(ExcelData);
    	link.download = fileName;
    	link.style.display = "none";
    	link.click();
    	link.remove();
    }

	
	 /**
     * Generate Excel data based on gri data
     *
     * @param {any} gridData.
     * @param {IFormElement} formElement.
     * @return {Array} Object.
     */
     generateExcelDataCustomConfig = (gridData: any, formElement: IFormElement): Promise<any> =>{
		const HeaderDefs: any = {};
		const headData:any = formElement.customConfiguration?.grid?.headerData;
		headData.map((header: IGridHeader)=>{
			HeaderDefs[header.field] = header.headerName;
		});
		return gridData.map((data: any)=>{
			const GridRow: any  = _.omit({...data}, ["id","isNewRow"]);
			const UpdateRow: any = {};
			_.each(GridRow, (value, key)=>{
				let objKey = _.trim(key);
				objKey = HeaderDefs[objKey] || objKey;
				UpdateRow[objKey] = value;
			});
			return ({
				...UpdateRow
			});
		});
	}
	
}