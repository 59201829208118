import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	cardContainer: {
		display: "block",
		width: "100%",
		height: 343, 
		padding: 32,
		cursor: "pointer",
		transition: "boxShadow 1s",
		boxShadow: "0px 1px 3px rgb(0 0 0 / 7%)",
		"&:hover": {
			boxShadow: "0px 4px 6px -1px rgb(0 0 0  / 10%), 0px 2px 4px -1px rgb(0 0 0  / 6%)"
		}
	},
	loading:{
		opacity:"0.5"
	},
	gradient : {
		backgroundImage: "linear-gradient(90deg, #F3F5F7 0px, #F7F8FA 40px, #F3F5F7 80px)",
		backgroundSize: 600,
		animation: "shine-lines 1.6s infinite linear",
		backgroundColor: COLORS.BASECOLOR
	},
	toolLogo: {
		height: 40,
		width: "auto",
	},
	platformName: {
		color: COLORS.DARK_GRAY,

		fontWeight: theme.typography.fontWeightBold,
		fontSize: 18,
		wordBreak: "break-all",
		marginTop:20
	},
	instructions: {
		fontSize: 12,
		fontWeight: theme.typography.fontWeightRegular,
		color: COLORS.GRAY,
		marginTop:8,
		marginBottom: 24,
		textAlign:"justify"
	},
	detailContainer:{
		marginBottom:16
	},
});

export default style;