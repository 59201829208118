import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip} from "@material-ui/core";
import classNames from "classnames";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
    onClick: any;
    title: any;
    disabled:boolean;
};

// ScheduleButton Component
export const IconSchedule = (props: props): JSX.Element => {
    const classes = useStyles();
    const { onClick, title,disabled } = props;

    return (
        <Tooltip title={title} placement="top">
        <IconButton
            size={"small"}
            className={classNames(classes.iconButtonMedium)}
            onClick={
                onClick
            }
            disabled={disabled}
        >
            <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                className={classNames(disabled && classes.disabledDownloadIcon)}
                >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm1-10V7h-2v7h6v-2h-4z" fill="rgba(106,115,124,1)" />
            </svg>
        </IconButton>
        </Tooltip>
    );
};

export default IconSchedule;
