
import COLORS from "../../../../../../UI/Theme/colors";

const style: any = (): any => ({
	  container:{
		marginTop:16,
		marginBottom: 16,
		padding:"0 8px",
		width:"100%"
	  },
	  hr:{
		  borderTop: `1px solid ${COLORS.SHAINCOLOR}`,
		  borderLeftColor:"transparent"
	  },
});

export default style;