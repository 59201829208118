import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import style from "./style";
import FacebookLogin from "react-facebook-login";
import { Facebook } from "@material-ui/icons";
import classNames from "classnames";
import { IconSuccess } from "../../../../Icons";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  onFbResponse: any;
  scope: any;
  fields: any;
  appid: any;
  AuthGroup: any
};

export const FacebookAuth = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onFbResponse, fields, scope, appid, AuthGroup } = props;
  const visibllity = (!_.isEmpty(AuthGroup.value) && !_.isNull(AuthGroup.value)) ? "visible" : "hidden";

  return (
    <div className={classes.facebookContainer}>
      <div>
        <FacebookLogin
          appId={appid}
          autoLoad={false}
          fields={fields}
          callback={onFbResponse}
          scope={scope}
          textButton="Log in With Facebook"
          cssClass={classNames(classes.button, "MuiButtonBase-root")}
          icon={<Facebook className={classes.facebookIcon} />}
          isDisabled={!_.isEmpty(AuthGroup.value) && !_.isNull(AuthGroup.value)}
        />
      </div>
      <div className={classes.SuccessIconDiv} style={{ visibility: visibllity }}>
        <IconSuccess />
      </div>
    </div>
  );
};
