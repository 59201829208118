import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as _ from "lodash";
import classNames from "classnames";
import { Grid, Tab, Tabs, withStyles } from "@material-ui/core";
import style from "./style";

const AntTabs = withStyles({
  root: {
    minHeight: 56,
  },
  indicator: {
    backgroundColor: "#1576d6",
    height: 4,
  },
})(Tabs);

interface IProps {
  handleTabChange: any;
  value: any;
  tabData: any;
  loading: boolean;
  pageTitle: string;
}

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Tab list component
export const TabListComponent = (props: IProps): React.JSX.Element => {
  const classes = useStyles();
  const { handleTabChange, value, loading, tabData } = props;
  
  return (
    <Grid container className={classes.tabsContainer} alignItems="center">
      {!_.isEqual(tabData.length, 0) ? (
        <Grid item sm={12} md={12} lg={12}>
          <AntTabs
            value={value}
            onChange={(event: React.ChangeEvent<unknown>, newValue: any) =>
              handleTabChange(newValue)
            }
          >
            {tabData.map((tab: any, index: number) => {
              return (
                <Tab
                  key={index}
                  className={classNames(classes.tabs, classes.tabText)}
                  value={tab.value}
                  label={tab.label}
                  disabled={loading}
                />
              );
            })}
          </AntTabs>
        </Grid>
      ) : (
        ""
      )}
    </Grid>
  );
};
