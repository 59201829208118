import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import style from "./style";
import * as _ from "lodash";

const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
    title?: any;
};

// downloadButton Component
export const VideoIcon = (props: props): JSX.Element => {
    const classes = useStyles();
    const { title } = props;

    return (
        <Tooltip title={!_.isEmpty(title) ? title : ""} placement="top">
            <span>
                <IconButton
                    size={"small"}
                    className={classes.iconDownload}
                >
                    <svg xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18"
                        height="18">
                        <path d="M2 3.9934C2 3.44476 2.45531 3 2.9918 3H21.0082C21.556 3 22 3.44495 22 3.9934V20.0066C22 20.5552 21.5447 21 21.0082 21H2.9918C2.44405 21 2 20.5551 2 20.0066V3.9934ZM4 5V7H6V5H4ZM18 5V7H20V5H18ZM4 9V11H6V9H4ZM18 9V11H20V9H18ZM4 13V15H6V13H4ZM18 13V15H20V13H18ZM4 17V19H6V17H4ZM18 17V19H20V17H18Z"
                            fill="rgba(106,115,124,1)">
                        </path>
                    </svg>
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default VideoIcon;
