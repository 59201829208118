const style:any = (): any => ({
    button:{
        color: "#ffffff",
        padding: "7px 16px",
        fontSize: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5,
        backgroundColor:"#1877F2",
        height:50,
        width:"250px",
		fontWeight:"bold",
        marginBottom: "20px",
    },
    facebookIcon:{
        fontSize:"2rem",
        fontWeight:"bold",
		marginRight:10
    },
    SuccessIconDiv: {
        paddingLeft: "24px", 
        marginTop: "10px", 
    },
    facebookContainer: {
        display: "flex"
    }
});

export default style;
