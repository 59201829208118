import React from "react";
import Select, { components } from "react-select";
import * as _ from "lodash";
import { CustomStyles } from "./style";
import { IOption } from "../../../../../../../Forms/interface/IOption";

type IProps = {
  dataSource: any[];
  placeholder: string;
  field: string;
  onFormChange: any;
  config: {
    required: boolean;
  };
  elementValue: IOption | null;
  isError: boolean;
};

export const UISelect = (props: IProps): JSX.Element => {
  const {
    dataSource,
    placeholder,
    field,
    onFormChange,
    config,
    elementValue,
    isError,
  } = props;

  return (
    <Select
      closeMenuOnSelect={true}
      isClearable={!config.required}
      value={elementValue}
      isDisabled={false}
      components={{
        DropdownIndicator: (data: any) => {
          return (
            <components.DropdownIndicator {...data} className="caret-select" />
          );
        },
      }}
      styles={CustomStyles(isError, false)}
      onChange={(selectedOption: any) => {
        const Value =
          !_.isNull(selectedOption) || !_.isUndefined(selectedOption)
            ? selectedOption.value
            : null;
        return onFormChange(field, Value);
      }}
      options={_.isArray(dataSource) ? dataSource : []}
      placeholder={placeholder}
    />
  );
};
