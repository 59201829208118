import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CircularProgress } from "../../UI/Progress";

export const TiktokCallback = (): React.JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    const QueryParams = new URLSearchParams(location.search);
    const AuthCode: any = QueryParams.get("auth_code");

    localStorage.setItem("TIKTOK_AUTH_CODE", AuthCode);
    window.opener.postMessage({ redirect: location.search }, window.location.origin);
    window.close();
  }, []);
  return <CircularProgress />;
};
