import React from "react";
import * as _ from "lodash";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import style from "./style";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Props type
type props = {
  title: string;
};

// MediaType Component
export const MediaType = (props: props): JSX.Element => {
  const { title } = props;
  const classes = useStyles();
  const mediaType = (title || "").toString().trim().toUpperCase();

  if (_.isEmpty(mediaType)) {
    return <></>;
  }

  return <div className={classes.mediaType}>{mediaType}</div>;
};

export default MediaType;
