import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	filterButton: {
		border: "1px solid rgb(208, 213, 218)",
		backgroundColor: COLORS.WHITE,
		color: COLORS.DARK_GRAY,
		padding: "7px 12px 8px 12px",
		fontWeight: theme.typography.fontWeightMedium,
		"&:hover": {
			border: "1px solid #868e96",
			backgroundColor: COLORS.WHITE,
		},
		minWidth: 106,
		justifyContent: "flex-start"
	},
	filterDot: {
		fontSize: "2.2rem",
		margin: "0 0.2rem",
		paddingBottom: "0.1rem"
	},
	search: {
		position: "relative" as const,
		borderRadius: 2,
		backgroundColor: COLORS.WHITE,
	},
	searchIcon: {
		padding: "0px 13px",
		height: "100%",
		position: "absolute" as const,
		pointerEvents: "none" as const,
		display: "flex",
		alignItems: "center" as const,
		justifyContent: "center" as const,
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		maxWidth: "100%",
		fontSize: 14,
		height: 36,
		width: 396,
		fontWeight: "normal" as const,
		backgroundColor: "rgba(255, 255, 255, 0)",
		border: "1px solid rgb(208, 213, 218)",
		borderRadius: 2,
		"box-sizing": "border-box",
	},
	inputElement: {
		"&:hover": {
			border: "1px solid rgb(134, 142, 150)",
		},
		"&.Mui-focused": {
			border: "1px solid rgb(58, 145, 232)",
		},
		"&.Mui-disabled": {
			border: "1px solid rgb(208, 213, 218)",
		}
	},
	toolBoxCountText: {
		fontSize: 18,
		fontWeight: theme.typography.fontWeightBold,
	},
	statusDropdownStyle: {
		width: "130px",
		paddingLeft: "8px"
	}
});

export default style;