import axios from "axios";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { IFileUploadResponse } from "./IFileUploadResponse";
import { GoogleUtilHelper } from "../../../Forms/helper/Utils/FileUtil/GoogleUtil";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import { FileValidation } from "../../../Forms/helper/Forms/Validation/File";
import { IFile } from "../../../Forms/interface/File/IFile";
import { FileHelper } from "../../../Forms/helper/Utils/FileUtil";
import { IFileUpload } from "../../../Forms/interface/File/IFileUpload";

const uploadFileToBucket = async (fileId: any, abortController: AbortController, uploadSessionURI: string, file: any, setActiveFile: any, contentLength = 0): Promise<void> => {
	await axios.put(uploadSessionURI, file, {
		signal: abortController.signal,
		headers: {
			"X-Upload-Content-Length": contentLength,
			"Content-Range": `bytes 0-${file.size - 1}/${file.size}`,
		},
		onUploadProgress: (progressEvent: any) => {
			const { loaded, total } = progressEvent;
			const progress: any = Math.floor((loaded * 100) / total);

			setActiveFile({
				progress,
				id: fileId,
				uploadSessionURI: uploadSessionURI,
			});
		},
	});
};

const resumeFileToBucket = async (fileId: any, abortController: AbortController, bytesRange: any, uploadSessionURI: string, file: any, setAllProgressDataset: any, contentLength = 0): Promise<void> => {
	const { start, end } = bytesRange;
	const updatedFile = file.slice(start, start + end);
	await axios.put(uploadSessionURI, updatedFile, {
		signal: abortController.signal,
		headers: {
			"X-Upload-Content-Length": contentLength,
			"Content-Range": `bytes ${start}-${end}/${file.size}`,
		},
		onUploadProgress: (progressEvent: any) => {
			const { loaded } = progressEvent;
			const TotalLoaded = loaded + start;
			const progress: any = Math.floor((TotalLoaded * 100) / file.size);
			setAllProgressDataset(progress, fileId, uploadSessionURI);
		},
	});
};

const generateSignedURL = async (bucketFileName: string, uploadSessionURI: string): Promise<IFileUploadResponse> => {
	const googleUtil = new GoogleUtilHelper();
	const SignedURL: any = await googleUtil.getSignedURL(bucketFileName);

	return {
		error: _.isNull(SignedURL),
		signedURL: SignedURL,
		uploadSessionURI,
	};
};


const handleOnChange = (props: any, files: any, loading: boolean): void => {
	const { formElement, onChange, formId, formGroupId } = props;
	onChange({
		formId,
		formGroupId,
		formElement,
		value: files,
		loading,
	});
};

const getFiles = (formElement: IFormElement): any => {
	if (!_.isNull(formElement.value)) {
		const { acceptedFiles, rejectedFiles } = formElement.value;
		return {
			acceptedFiles: _.isEmpty(acceptedFiles) ? [] : acceptedFiles,
			rejectedFiles: _.isEmpty(rejectedFiles) ? [] : rejectedFiles,
		};
	}
	return {
		acceptedFiles: [],
		rejectedFiles: []
	};
};

const splitFiles = (fileData: IFileUpload[]): any => {
	const acceptedFiles: IFileUpload[] = [];
	const rejectedFiles: IFileUpload[] = [];

	fileData.forEach((file: IFileUpload) => {

		if (file.isValidForUpload) {
			acceptedFiles.push(file);
		} else {
			rejectedFiles.push(file);
		}
	});

	return {
		acceptedFiles,
		rejectedFiles
	};
};

const isValidForUpload = (files: IFileUpload[]): boolean => {
	const ValidFiles = files.filter((file: IFileUpload) => file.isValidForUpload);
	return !_.isEqual(ValidFiles.length, 0);
};

const getFilesData = (formElement: IFormElement, files: any): any => {
	const fileValidation = new FileValidation();
	const Files: IFileUpload[] = [];

	for (const file of files) {
		const File: IFile = file;
		const FileName: any = File.name.split(".").slice(0, -1).join(".");
		const FileExtension: any = File.name.split(".").pop();
		const UniqueFileName = `Automations-Portal-${uuidv4()}.${FileExtension}`;
		const abortController = new AbortController();
		const fileHelper = new FileHelper();
		const isValidFile = fileValidation.isValidFileExtension(formElement, FileExtension) && fileValidation.isValidFileSize(formElement, File.size);
		Files.push({
			id: uuidv4(),
			displayName: `${fileHelper.breakFileName(FileName)}.${FileExtension}`,
			displaySize: fileHelper.formatBytes(File.size),
			file: File,
			uniqueFileName: UniqueFileName,
			abortController: abortController,
			isValidForUpload: isValidFile,
			name: File.name,
			size: File.size,
			type: File.type,
			progress: 0,
			status: isValidFile ? "pending" : "error",
			uploadSessionURI: null,
			signedURL: null,
			fileInputText: "",
			touched: false,
			extensionType: FileExtension
		});
	}

	if (!_.isNull(formElement.selection_limit) && _.gt(Files.length, formElement.selection_limit)) {
		return {
			rejectedFiles: Files,
			acceptedFiles: [],
			isFileLimitExceeded: true
		};
	}
	return {
		rejectedFiles: Files.filter((file: IFileUpload) => !file.isValidForUpload),
		acceptedFiles: Files.filter((file: IFileUpload) => file.isValidForUpload),
		isFileLimitExceeded: false
	};
};

const resumeFileUpload = async (fileInfo: IFileUpload, bytesRange: string, contentLength: number, setAllProgressDataset: any): Promise<any> => {
	const UploadSessionURI: any = fileInfo.uploadSessionURI;
	try {
		const { file, uniqueFileName, abortController } = fileInfo;
		await resumeFileToBucket(
			fileInfo.id,
			abortController,
			bytesRange,
			UploadSessionURI,
			file,
			setAllProgressDataset,
			contentLength
		);

		return await generateSignedURL(uniqueFileName, UploadSessionURI);
	} catch (err) {
		return {
			error: true,
			signedURL: null,
			uploadSessionURI: UploadSessionURI,
		};
	}
};

const isTextBoxConfigEmpty = (formElement: IFormElement): boolean => {
	if (_.isNull(formElement.customConfiguration)) {
		return true;
	}
	if (_.isUndefined(formElement.customConfiguration.bucketUpload)) {
		return true;
	}
	if (_.isUndefined(formElement.customConfiguration.bucketUpload.textBox)) {
		return true;
	}
	return false;
};

const uploadFileToBucketParalley = async (File: any, abortController: AbortController, uploadSessionURI: string, file: any, setAllProgressDataset: any, contentLength = 0): Promise<any> => {
	await axios.put(uploadSessionURI, file, {
		signal: abortController.signal,
		headers: {
			"X-Upload-Content-Length": contentLength,
			"Content-Range": `bytes 0-${file.size - 1}/${file.size}`,
		},
		onUploadProgress: (progressEvent: any) => {
			const { loaded, total } = progressEvent;
			const progress: any = Math.floor((loaded * 100) / total);
			setAllProgressDataset(progress, File.id, uploadSessionURI);
		}
	});
};

export default {
	uploadFileToBucket,
	generateSignedURL,
	handleOnChange,
	getFiles,
	getFilesData,
	splitFiles,
	isValidForUpload,
	resumeFileUpload,
	isTextBoxConfigEmpty,
	uploadFileToBucketParalley
};