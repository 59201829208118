import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { withRouter } from "react-router";
import { Container, Grid } from "@material-ui/core";
import styles from "./style";
import { withErrorHandler, withSnackbar } from "../../../../HOC";
import { ToolHeader, ToolInfo } from "./Layout";

type props = any;

// Under Maintenance tool box page component
const UnderMaintenanceTool = (props: props): React.JSX.Element => {
  const { classes, data } = props;
  const history = useHistory();

  // Combined parameters to pass ToolList component as props.
  const propsToToolInfo = {
    data: {
        ...data,
        tool_label : data.under_maintenance ? "MAINTENANCE": data.tool_label
    },
    classes: classes,
  };

  return (
    <Fragment>
      {!_.isUndefined(data) && !_.isEmpty(data) && (
        <Fragment>
          <ToolHeader handleBackButton={()=>{
            history.push("/");
          }} {...propsToToolInfo} />
          <Container maxWidth="lg">
            <Grid container className={classes.toolContentWrapper}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={8}
                className={classes.infoWrapper}
              >
                <div className={classes.toolContent}>
                 <div className={classes.maintenance}>
                 <div className={classes.maintenanceHeading}>
                  Maintenance
                  </div>
                  <div className={classes.maintenanceDescription}>
                  The tool is under maintenance, please come back hours later.
                  </div>
                  <a className={classes.maintenanceContact} href="mailTo:gdt-automations-support@groupm.tech">
                  Contact support. 
                  </a>
                 </div>
                </div>
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                justifyContent="center"
                className={classes.infoWrapper}
              >
                <ToolInfo {...propsToToolInfo} />
              </Grid>
            </Grid>
          </Container>
        </Fragment>
      )}
    </Fragment>
  );
};

export default withStyles(styles)(
  withErrorHandler(withRouter(withSnackbar(UnderMaintenanceTool)))
);
