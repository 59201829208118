import React, { useEffect, useState } from "react";
import { Input } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as _ from "lodash";
import style from "./style";
import commonStyle from "../../../../UI/Elements/FormElements/sharedStyle";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IFormElementConfig } from "../../Interfaces/Form/FormElement/IFormElementConfig";
import { IFormElementValidation } from "../../Interfaces/Form/FormElement/IFormElementValidation";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  onChange: any;
  formElement: IFormElement;
  errorMessage: string;
};


// UIInput Component
export const UITextInput = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { formElement, onChange, errorMessage } = props;
  const Value = formElement.value || "";
  const [input, setInput] = useState(Value);
  const commonClasses = useCommonStyles();
  const Disabled = formElement.readOnly || formElement.disabled;
  const ElementConfiguration: IFormElementConfig = formElement.configuration;

  const ElementValidation: IFormElementValidation = formElement.validations;

  const isError = !_.isEmpty(errorMessage);

  let Props = {};

  if (ElementConfiguration.isMulti) {
    Props = {
      ...Props,
      rows: 5,
      multiline: true,
    };
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!_.isEqual(Value, input)) {
        onChange(input);
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [input]);

  useEffect(() => {
    setInput(Value);
  }, [Value]);

  return (
    <Input
      type="text"
      {...Props}
      id={`id-${formElement.id}`}
      className={classes.inputContainer}
      value={input}
      required={ElementValidation.required}
      placeholder={ElementConfiguration.placeholder}
      onChange={(event: any) => {
        const InputValue = event.target.value;
        setInput(InputValue);
      }}
      onBlur={(): void => {
        return onChange(formElement.value);
      }}
      disabled={Disabled}
      inputProps={{
        autoComplete: "off",
        style: {
          height: ElementConfiguration.isMulti ? "auto" : 40,
        },
        className: classNames(classes.input, {
          [commonClasses.disabled]: Disabled,
          [classes.inputElement]: !Disabled && !isError,
          [commonClasses.error]: isError,
        }),
      }}
    />
  );
};
