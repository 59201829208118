import Colors from "../../utilstyle";

const style: any = (): any => {
	return {
		toggleButton: {
			padding: "7px 17px",
			marginLeft: "0px !important",
			borderRadius: 0,
			backgroundColor: "#FFFF",
			height: 36,
			color: "#242c34 !important",
			fontSize: 14,
			fontWeight: "normal" as const,
			textTransform: "none" as const,
			border: "1px solid #d0d5da",
		},
		toggleButtonGroup: {
			display: "inline-block",
			borderRadius: 2,
			paddingBottom: 16,
		},
		selectedButton: {
			border: "1px solid #3a91e8 !important",
			borderRadius: 2,
			boxShadow: "0px 1px 4px 0px rgba(21, 118, 214, 0.15)",
			backgroundColor: "#f0f8ff !important",
		},
		container:{
			width: "100%",
		},
		input: {
			backgroundColor: Colors.BackgroundColor,
			border: `1px solid ${Colors.BorderColor}`,
			borderRadius: 2,
			width:"100%",
			fontSize:"0.875rem",
			padding: "7px 12px",
			"box-sizing": "border-box",
		},
		inputElement:{
			"&:hover":{
				border: `1px solid ${Colors.HoverColor}`,
			},
			"&:focus":{
				border: `1px solid ${Colors.FocusColor}`,
			}
		},
	};
};

export default style;