import COLORS from "../../../../../UI/Theme/colors";

const style: any = (): any => ({
  container: {
    marginBottom: "1.5rem",
  },
  title: {
    fontSize: "0.875rem",
    color: COLORS.DARK_GRAY,
    minHeight: 20,
  },
  subtitle: {
    fontSize: "0.75rem",
    color: COLORS.GRAY,
    fontWeight: 500,
  },
});

export default style;
