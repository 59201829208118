import React, { Fragment, useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { List, AppBar } from "@material-ui/core";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import { useLocation, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import style from "./style";
import UserInfoComponent from "../UserInfo";
import GroupMNexusLogo from "./../../assets/icons/GroupMNexusLogo.png";
import { IconFeedback, IconHelp } from "../../UI/Icons";
import NavListItems from "./NavListItems";
import { MenuComponent } from "./Menu";
import { GAEvent } from "../../helpers/GA";
import {
  GOOGLE_ANALYSTICS_ACTIONS,
  GOOGLE_ANALYSTICS_CATEGORIES,
} from "../../helpers/GA/GoogleAnalysticsUtils";
import { UserPermissionHelper } from "../Routes/Permissions/Helper";
import { IUserPermission } from "../Routes/Permissions/IUserPermission";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const SiteHeader = (): React.JSX.Element => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [selectedIndex, setSelectedIndex] = useState(1);
  const [pageTitle, setPageTitle] = useState("Automations Portal");

  const initial = useSelector((state: any) => state.initial);

  const handleListItemClick = (event: any, index: any, title: string): any => {
    setPageTitle(title);
    setSelectedIndex(index);
  };

  const isExternalUser = initial.isExternalUser;
  const UserPermissions: IUserPermission[] = initial.isAuthenticated
    ? initial.userInfo.routePermissions
    : [];

  useEffect(() => {
    const userPermissionHelper = new UserPermissionHelper();
    const RouteIndex: number = userPermissionHelper.getCurrentRouteIndex(
      UserPermissions,
      location.pathname
    );

    setSelectedIndex(RouteIndex);
  }, [location.pathname]);

  if (initial.loading) {
    return <span />;
  }

  const BUNDLE_DATA = initial.bundle || {};

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div
        onClick={(event) => {
          handleListItemClick(event, 1, BUNDLE_DATA.MENU_TOOLKIT_TITLE);
          GAEvent(
            GOOGLE_ANALYSTICS_CATEGORIES.NAVIGATION,
            GOOGLE_ANALYSTICS_ACTIONS.LINK_CLICKED,
            "GroupM Logo"
          );
          history.push("/");
        }}
        className={classes.headerLogo}
      >
        <img alt="GroupM" src={GroupMNexusLogo} height="24" />
      </div>
      <div className={classes.headerTitle}>
        <div>{BUNDLE_DATA.SITE_TITLE}</div>
      </div>

      <div className={classes.listContainer}>
        <List component="nav" className={classes.navContainer}>
          {UserPermissions.map(
            (userPermission: IUserPermission, navIndex: number) => {
              if (userPermission.allowedAccess) {
                if (!_.isEmpty(userPermission.children)) {
                  return (
                    <MenuComponent
                      key={userPermission.title}
                      title={userPermission.title}
                      indexValue={navIndex}
                      selectedIndex={selectedIndex}
                      handleListItemClick={(
                        event: any,
                        index: any,
                        linkTitle: string
                      ) => {
                        handleListItemClick(event, index, linkTitle);
                      }}
                      childRoutes={userPermission.children}
                    />
                  );
                }
                return (
                  <NavListItems
                    key={userPermission.title}
                    handleListItemClick={(event: any, index: any) => {
                      handleListItemClick(event, index, userPermission.gaTitle);
                    }}
                    selectedIndex={selectedIndex}
                    indexValue={navIndex}
                    name={userPermission.title}
                    history={userPermission.url}
                    navName={userPermission.title}
                  />
                );
              }
              return <Fragment key={userPermission.title}></Fragment>;
            }
          )}
        </List>
        <div className={classes.rightMenuWrapper}>
          {initial.isAuthenticated && !isExternalUser && (
            <a
              href={BUNDLE_DATA.USER_GUIDE_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                GAEvent(
                  GOOGLE_ANALYSTICS_CATEGORIES.NAVIGATION,
                  GOOGLE_ANALYSTICS_ACTIONS.LINK_CLICKED,
                  "Help"
                );
              }}
            >
              <IconHelp title="Help" />
            </a>
          )}

          {initial.isAuthenticated && !isExternalUser &&(
            <a
              href={BUNDLE_DATA.SURVEY_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                GAEvent(
                  GOOGLE_ANALYSTICS_CATEGORIES.NAVIGATION,
                  GOOGLE_ANALYSTICS_ACTIONS.LINK_CLICKED,
                  BUNDLE_DATA.SURVEY_TOOLTIP
                );
              }}
            >
              <IconFeedback title={BUNDLE_DATA.SURVEY_TOOLTIP} />
            </a>
          )}

          <UserInfoComponent />
        </div>
      </div>
    </AppBar>
  );
};
