import moment from "moment";
import * as _ from "lodash";
import {
	setHours,
	setMinutes
} from "date-fns";
import { IFormElement } from "../../interface/IFormElement";

// Date and time Util
export class DateUtil{

    public static momentDateFormat = "YYYY-MM-DD";
    public static momentDateTimeFormat = "YYYY-MM-DD h:mm A";
    
    public static dateFormat = "yyyy-MM-dd";
    public static dateTimeFormat = "yyyy-MM-dd hh:mm aa";

	public static ISODateFormat = "YYYY-MM-DD";
    public static ISODateTimeFormat = "YYYY-MM-DD HH:mm";

    /**
     * Format date or time and check validity
     *
     * @param {any} date.
     * @param {IFormElement} formElement.
     * @return {Date | null} InputDate.
     */
    formatDateOrTime = (date: any, formElement: IFormElement): any=>{
    	const dateFormat = formElement.show_time ? DateUtil.momentDateTimeFormat : DateUtil.momentDateFormat;

    	return this.isValidDate(formElement, date)
		? moment(date, dateFormat, true).local().format(dateFormat) : null;
    }

    /**
     * Format date or time value
     *
     * @param {any} date.
     * @param {IFormElement} formElement.
     * @return {Date | null} InputDate.
     */
     formatDateValue = (date: any, formElement: IFormElement): any=>{
     	const Value = (date || "").toString().trim();
     	if(_.isEqual(Value.length, 0)){
     		return null;
     	}

     	const dateFormat = formElement.show_time ? DateUtil.momentDateTimeFormat
     		: DateUtil.momentDateFormat;

     	if(formElement.is_default_value){
     		const DateValue = moment().add(parseInt(date), "days").format(dateFormat);

     		return this.isValidDate(formElement, DateValue)
			 ?  moment(DateValue, dateFormat, true).format(dateFormat) : null;
     	}

     	return this.isValidDate(formElement, date)
		 ? moment(date, dateFormat, true).format(dateFormat) : null;
     }

	 /**
     * Format date or time value for Submit
     *
     * @param {any} date.
     * @param {IFormElement} formElement.
     * @return {Date | null} InputDate.
     */
	  formatDateValueFormSubmit = (date: any, formElement: IFormElement): any=>{
		const Value = (date || "").toString().trim();
		if(_.isEqual(Value.length, 0)){
			return null;
		}

		const dateFormat = formElement.show_time ? DateUtil.momentDateTimeFormat
			: DateUtil.momentDateFormat;
		return this.isValidDate(formElement, date)
		? moment(date, dateFormat, true).format(dateFormat) : null;
	}

     /**
     * Format date or time value
     *
     * @param {any} date.
     * @param {IFormElement} formElement.
     * @return {Date | null} InputDate.
     */
     formatDateMinMaxValue = (date: any, formElement: IFormElement): any=>{
     	const Value = (date || "").toString().trim();
     	if(_.isEqual(Value.length, 0)){
     		return null;
     	}

     	const dateFormat = formElement.show_time ? DateUtil.momentDateTimeFormat
     		: DateUtil.momentDateFormat;

     	const DateValue = moment().add(parseInt(date), "days").startOf("day").format(dateFormat);

		const ISODateFormat = formElement.show_time ? DateUtil.ISODateTimeFormat
		: DateUtil.ISODateFormat;

     	return this.isValidDate(formElement, DateValue)
		 ? moment().add(parseInt(date), "days").startOf("day").format(ISODateFormat) : null;
     }

    /**
     * Format date or time
     *
     * @param {any} date.
     * @param {string} dateFormat.
     * @return {Date} Formatted date.
     */
    formatDate = (date: any, dateFormat: string): any =>{
    	return moment(date, dateFormat, true).format(dateFormat);
    }

    /**
     * Check if the date is valid format or not
     *
     * @param {any} date.
     * @param {string} dateFormat.
     * @return {Date} Formatted date.
     */
    isValidDate = (formElement: IFormElement, date: any): any =>{
    	const dateFormat = formElement.show_time ? DateUtil.momentDateTimeFormat
    		: DateUtil.momentDateFormat;
    	const InputDate = moment(date, dateFormat, true);
	
    	return InputDate.isValid();
    }

    /**
     * Compare the two dates
     *
     * @param {any} start.
     * @param {any} end.
     * @param {IFormElement} formElement.
     * @return {boolean} If the End date is not less than start date.
     */
    compareDates = (formElement: IFormElement, start: any, end: any): any =>{
    	const dateFormat = formElement.show_time ? DateUtil.momentDateTimeFormat 
    		: DateUtil.momentDateFormat;
		const ISODateFormat = formElement.show_time ? DateUtil.ISODateTimeFormat
			: DateUtil.ISODateFormat;
		const StartDate = moment(start, ISODateFormat).format(dateFormat);
    	const EndDate = moment(end,ISODateFormat).format(dateFormat);
	
    	return this.compareStartAndEndDate(StartDate, EndDate, dateFormat);
    }

    /**
     * Compare the two days numbers
     *
     * @param {any} start.
     * @param {any} end.
     * @param {IFormElement} formElement.
     * @return {boolean} If the End date is not less than start date.
     */
     compareDays = (start: any, end: any): any =>{
     	return _.gte(+start, +end);
     }

    /**
     * Compare the two dates with the given format
     *
     * @param {any} start.
     * @param {any} end.
     * @param {string} dateFormat.
     * @return {boolean} If the End date is not less than start date.
     */
    compareStartAndEndDate = (start: any, end: any, dateFormat: string): any =>{
    	const StartDate = moment(start, dateFormat, true).local();
    	const EndDate = moment(end, dateFormat, true).local();
    
    	const Diff = EndDate.diff(StartDate);
		
    	return !_.lt(Diff, 0);
    }   

    /**
     * Check if the given date is between start and end date
     *
     * @param {IFormElement} formElement.
     * @param {any} date.
     * @param {any} start.
     * @param {any} end.
     * @return {boolean}
     */
    isDateBetweenStartAndEnd = (formElement: IFormElement, date: any, start: any, end: any): any =>{
    	const dateFormat = formElement.show_time ? DateUtil.ISODateTimeFormat 
    		: DateUtil.ISODateFormat;

		const DateValue = moment(date, dateFormat).format(dateFormat);

    	if(!_.isNull(start) && !_.isNull(end)){
    		return this.compareStartAndEndDate(start, DateValue, dateFormat)
            && this.compareStartAndEndDate(DateValue, end, dateFormat);
    	}

    	if(!_.isNull(start) && _.isNull(end)){
    		return this.compareStartAndEndDate(start, DateValue, dateFormat);
    	}
    	return this.compareStartAndEndDate(DateValue, end, dateFormat);
    }

    /**
     * Get min and max time
     *
     * @param {IFormElement} formElement.
     * @param {any} date.
     * @return {any}
     */
    getMinMaxTime = (date: any, formElement: IFormElement): any =>{
    	const InputDate = this.formatDateOrTime(date,formElement);
    	return this.setTimeForDateRange(InputDate);
    }

    /**
     * Get min time
     *
     * @param {IFormElement} formElement.
     * @param {any} date.
     * @return {any}
     */
    getMinTimeForDate = (formElement: IFormElement): any =>{

    	if(_.isNull(formElement.value)){
    		return this.getMinTime();
    	}
    	const InputDate = this.formatDate(formElement.value,DateUtil.momentDateFormat);
    	const MinDate = this.formatDate(formElement.min_range, DateUtil.momentDateFormat);

    	if(moment(InputDate).isSame(MinDate)){
    		return this.setTimeForDateRange(formElement.min_range); 
    	}

    	return this.getMinTime();
    }

    getMaxTimeForDate = (formElement: IFormElement): any =>{
    	if(_.isNull(formElement.value)){
    		return this.getMaxTime();
    	}
    	const InputDate = this.formatDate(formElement.value,DateUtil.momentDateFormat);
    	const MaxDate = this.formatDate(formElement.max_range, DateUtil.momentDateFormat);
    
    	if(moment(InputDate).isSame(MaxDate)){
    		return this.setTimeForDateRange(formElement.max_range); 
    	}
    	return this.getMaxTime();
    }

    /**
     * Return time range for date picker
     *
     * @param {any} date.
     * @return {string} time format
     */
    setTimeForDateRange = (date: any): any =>{
    	return setHours(setMinutes(new Date(), this.getMinutes(date)), this.getHours(date));
    }

    /**
     * Get max time i.e 23:59
     *
     * @return {hh:mm} time
     */
    getMaxTime = (): any =>{
    	return setHours(setMinutes(new Date(), 59), 23);
    }

    /**
     * Get min time i.e 00:00
     *
     * @return {hh:mm} time
     */
    getMinTime = (): any =>{
    	return setHours(setMinutes(new Date(), 0), 0);
    }

    /**
     * Get hours in 24 hours format
     *
     * @param {any} date.
     * @return {HH} time
     */
    getHours = (date: any):number =>{
    	return parseInt(moment(date).format("HH"));
    }

    /**
     * Get minutes in 24 hours format
     *
     * @param {any} date.
     * @return {mm} time
     */
    getMinutes = (date: any):number =>{
    	return parseInt(moment(date).format("mm"));
    }

    /**
     * Get min and max for the daterange picker
     *
     * @param {IFormElement} formElement.
     * @param {any} dateRange.
     * @return {[start, end] | null} daterange array
     */
    getDateRangeValue = (formElement: IFormElement, dateRange: any): any =>{

    	const Element = {...formElement, show_time: false};
    	const MinDate = this.formatDateMinMaxValue(
			Element.min_range,
			formElement
		  );
		  const MaxDate = this.formatDateMinMaxValue(
			Element.max_range,
			formElement
		  );
                                
    	const DateRange = (dateRange || "").toString().trim();
    	const Range = !_.isEqual(DateRange.length, 0) ? 
    		DateRange.split("-") : [];

    	if(!_.isEqual(Range.length, 2)){
    		return null;
    	}
    	const UpdatedRange = Range.map((date: string) => (date || "").toString().trim());
      
		let startDate = this.formatDateValue(new Date(UpdatedRange[0]), formElement);
		let endDate = this.formatDateValue(new Date(UpdatedRange[1]), formElement);

		startDate = this.isDateBetweenStartAndEnd(
			formElement,
			startDate,
			MinDate,
			MaxDate
		  )
			? startDate
			: null;

			endDate = this.isDateBetweenStartAndEnd(
				formElement,
				endDate,
				MinDate,
				MaxDate
			  )
				? endDate
				: null;

    	if(_.isNull(startDate) || _.isNull(endDate)){
    		return null;

    	}
    	return [new Date(startDate), new Date(endDate)];
    }
}