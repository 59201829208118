import colors from "../../../../Theme/colors";

const style: any = (): any => ({
    button: {
        backgroundColor: "#4285F4 !important",
        height: 50,
        width: 250,
        borderRadius: 5,
        color: `${colors.WHITE} !important`,
        fontSize: "1rem !important",
        marginBottom: "20px",
        "& > div": {
            padding: "0px !important",
            marginLeft:"-28px",
            width: 50,
            height: "100%",
            paddingTop: "16px !important",
            borderRadius: "0px !important",
        },
        "& > span": {
            fontWeight: "bold !important"
        },
    },
    successIconDiv : {
        paddingLeft: "24px", 
        marginTop: "10px"
    },
    googleContainer : {
        display: "flex", 
        flexWrap: "nowrap" 
    }
});

export default style;
