import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import classNames from "classnames";
import style from "./style";
import VisibilityIcon from "@material-ui/icons/Visibility";

const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
    onClick: any;
};

// PlayButton Component
export const IconViewDetail = (props: props): JSX.Element => {
    const classes = useStyles();
    const { onClick } = props;

    return (
        <IconButton
            size={"small"}
            className={classNames(classes.viewDetailIcon)}
            onClick={
                onClick
            }
        >
            <VisibilityIcon style={{ color: "#6A737C", width: "18px", height: "18px" }} />
        </IconButton>

    );
};

export default IconViewDetail;
