
const style: any = (): any => ({
	gridForm: {
		display: "flex",
		alignItems: "center",
		flex: 1,
		height: 40,
		padding:"5px 0 4px 20px"
	},
	gridFormContainer: {
		display: "flex",
		alignItems: "center",
		flex: 1,
		height: 49,
		backgroundColor:"#f8f8f8"
	},
	gridActionCell:{
		flex:"0 50px",
		paddingRight:20
	},
});

export default style;
