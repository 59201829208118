

export enum GOOGLE_ANALYSTICS_ACTIONS {
	CLICK="click",
	LINK_CLICKED="link_clicked",
	TOOL_SUBMIT="tool_submit",
	BUTTON_CLICKED="button_clicked"
}

export enum GOOGLE_ANALYSTICS_CATEGORIES {
	NAVIGATION="navigation",
}