import axios from "axios";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { IFileUploadResponse } from "./Interface/IFileUploadResponse";
import { GoogleUtilHelper } from "../../Forms/helper/Utils/FileUtil/GoogleUtil";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { FileValidation } from "../../Forms/helper/Forms/Validation/File";
import { FileHelper } from "../../Forms/helper/Utils/FileUtil";
import { IFileUpload } from "./Interface/IFileUpload";

const uploadFileToBucket = async (uploadSessionURI: string, file: any, contentLength=0): Promise<void> =>{

    await axios.put(uploadSessionURI, file, {
        headers: {
          "X-Upload-Content-Length": contentLength,
          "Content-Range": `bytes 0-${file.size -1}/${file.size}`,
        },
      });
};


const handleOnChange = (props: any, data: any, loading: boolean): void =>{
    const { formElement, onChange, formId, formGroupId } = props;
    onChange({
        formId,
        formGroupId,
        formElement,
        value: data,
        loading,
      });
};


const validateFile = (formElement: IFormElement, file: any): IFileUpload =>{
    const fileValidation = new FileValidation();
        const FileName: any = file.name.split(".").slice(0, -1).join(".");
        const FileExtension: any = file.name.split(".").pop();
  
        const UniqueFileName = `Automations-Portal-ImageText-${uuidv4()}.${FileExtension}`;
    
        const fileHelper = new FileHelper();
        const isValidFile = fileValidation.isValidFileExtension(formElement, FileExtension) && fileValidation.isValidFileSize(formElement, file.size);
  
        return  {
            id: uuidv4(),
            displayName: `${fileHelper.breakFileName(FileName)}.${FileExtension}`,
            displaySize: fileHelper.formatBytes(file.size),
            file: file,
            uniqueFileName: UniqueFileName,
            isValidForUpload: isValidFile,
            name: file.name,
            size: file.size,
            type: file.type,
            status: "pending",
            uploadSessionURI: null,
            publicURL: null,
          };
};


const generatePublicURL = async (bucketFileName: string, uploadSessionURI: string, bucketName: string): Promise<IFileUploadResponse> =>{
    const googleUtil = new GoogleUtilHelper();
    const PublicURL: any = await googleUtil.getBlobPublicURL(bucketFileName,bucketName);

      return {
        error: _.isNull(PublicURL),
        publicURL: PublicURL,
        uploadSessionURI,
      };
};


export default {
    uploadFileToBucket,
    handleOnChange,
    validateFile,
    generatePublicURL,
};