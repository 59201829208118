import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  TablePagination,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import * as _ from "lodash";
import style from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import classNames from "classnames";
import { TitleElement } from "./Title";
import MediaType from "./MediaType";
import { IconPlaceholer } from "./IconPlaceholder";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));
const DATA_PER_PAGE = 8;

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

// UIThumbnail Component
export const UIThumbnail = (props: props): JSX.Element => {
  const { formElement, onChange, formId, formGroupId } = props;
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);
  const Disabled = formElement.disabled || formElement.read_only;
  let errorMessage = "";

  if (formElement.apiError) {
    errorMessage = formElement.apiError;
  } else {
    errorMessage = formElement.touched ? formElement.error : "";
  }

  if (Disabled && _.isEmpty(formElement.apiError)) {
    errorMessage = "";
  }
  const DATA_SOURCE: any[] = formElement.dataSource || [];

  const handleChangePage = (offset: any, pageNumber: number): void => {
    setPage(pageNumber);
  };

  if (formElement.hideBasedOnParent) {
    return <></>;
  }

  if (_.isEmpty(DATA_SOURCE)) {
    return (
      <>
        <UILabel formElement={formElement} />
        <div className={classes.noDataAvailable}>
          <div>No data available</div>
        </div>
        <UIErrorOrNoteMessage
          formElement={formElement}
          errorMessage={errorMessage}
        />
      </>
    );
  }

  const getCustomStyle = (element: IFormElement): any => {
    if (!_.isNull(element.customConfiguration)) {
      return element.customConfiguration.thumbnail?.imageStyle;
    }
    return {};
  };

  const CustomThumbnailStyle = getCustomStyle(formElement);

  const ELEMENT_VALUE_ID = _.isNull(formElement.value)
    ? null
    : formElement.value.id;

  let showPagination = !_.isEmpty(DATA_SOURCE);
  if (_.lte(DATA_SOURCE.length, DATA_PER_PAGE)) {
    showPagination = false;
  }

  const handleChange = (data: any): void => {
    if (formElement.disabled) {
      return;
    }
    onChange({
      formId,
      formGroupId,
      formElement,
      value: data,
    });
  };

  return (
    <>
      <UILabel formElement={formElement} />
      <div className={classes.container}>
        <div className={classes.containerData}>
          <Grid container className={classes.root} spacing={2}>
            {DATA_SOURCE.slice(
              page * DATA_PER_PAGE,
              page * DATA_PER_PAGE + DATA_PER_PAGE
            ).map((data: any) => {
              const Title: string = data.title || "";
              const Subtitle: string = data.subtitle || "";
              const MediaTypeStr: string = data.mediaType || "";
              let thumbnailImage = (data.thumbnail || "").toString().trim();

              thumbnailImage = _.isEmpty(thumbnailImage) ? (
                <IconPlaceholer
                  style={{
                    ...CustomThumbnailStyle,
                  }}
                />
              ) : (
                <img
                  src={data.thumbnail}
                  className={classes.cardImage}
                  style={{
                    ...CustomThumbnailStyle,
                  }}
                  width="100%"
                  height="180"
                />
              );
              return (
                <Grid key={data.id} item xs={6} lg={3} md={4} xl={3} sm={4}>
                  <div
                    className={classNames(classes.card, {
                      [classes.defaultCursor]: formElement.disabled,
                    })}
                    onClick={() => handleChange(data)}
                  >
                    {thumbnailImage}
                    <div className={classes.cardContainer}>
                      {!formElement.disabled && (
                        <div className={classes.checkboxContainer}>
                          <FormControlLabel
                            key={data.id}
                            value={data.id}
                            className={classes.checkboxControl}
                            control={
                              <Checkbox
                                onChange={() => handleChange(data)}
                                name=""
                                checked={_.isEqual(data.id, ELEMENT_VALUE_ID)}
                                className={classes.checkboxRoot}
                                disableRipple
                                disabled={Disabled}
                                color="primary"
                                checkedIcon={
                                  <span
                                    className={classNames(
                                      classes.checkboxIcon,
                                      classes.checkedIcon
                                    )}
                                  />
                                }
                                icon={<span className={classes.checkboxIcon} />}
                                inputProps={{
                                  "aria-label": "decorative checkbox",
                                }}
                              />
                            }
                            label=""
                            labelPlacement="end"
                          />
                        </div>
                      )}
                      <div
                        style={{
                          width: formElement.disabled ? "100%" : "90%",
                        }}
                      >
                        <div className={classes.cardTitle}>
                          <TitleElement title={Title} />
                        </div>
                        <div className={classes.cardSubTitle}>
                          <div className={classes.subtitleText}>{Subtitle}</div>
                          <MediaType title={MediaTypeStr} />
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </div>
        {showPagination && (
          <TablePagination
            component="div"
            count={DATA_SOURCE.length}
            labelRowsPerPage=""
            rowsPerPage={DATA_PER_PAGE}
            page={page}
            onPageChange={(offset, pageNumber) =>
              handleChangePage(offset, pageNumber)
            }
          />
        )}
      </div>
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={errorMessage}
      />
    </>
  );
};

export default UIThumbnail;
