import React, { Fragment, useEffect, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Input, InputAdornment } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import * as _ from "lodash";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import commonStyle from "../sharedStyle";
import style from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UtilHelper } from "../../Forms/helper/Utils";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
  onValidateForms: any;
};

const utilHelper = new UtilHelper();

// UINumberInput Component
export const UINumberInput = (props: props): JSX.Element => {
  const { formElement, onChange, formId, formGroupId, onValidateForms } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const Value = formElement.value || "";
  const [focus, setFocus] = useState<boolean>(false);
  const Disabled = formElement.disabled || formElement.read_only;
  const ErrorMessage = formElement.touched ? formElement.error : "";
  const [input, setInput] = useState(Value);

  const onClickUpDown = (inputValue: string, inc: number): void => {
    const IsNumber = utilHelper.getValidNumber(Value, formElement);
    if (Disabled || !IsNumber) {
      return;
    }
    const UpdatedValue = _.isNaN(parseInt(inputValue))
      ? inc
      : parseInt(inputValue) + inc;
    if (_.lt(UpdatedValue, 0)) {
      return;
    }
    setInput(UpdatedValue.toString());
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!_.isEqual(Value, input)) {
        const IsNumber = utilHelper.getValidNumber(Value, formElement);
        if (!IsNumber && !_.isEqual(Value.length, 0)) {
          return;
        }
        onChange({ formId, formGroupId, formElement, value: input });
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [input]);

  useEffect(() => {
    setInput(Value);
  }, [Value]);

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <Input
        type="text"
        disabled={Disabled}
        id={`${formElement.id}`}
        placeholder={formElement.placeholder}
        className={classNames(commonClasses.input, {
          [commonClasses.disabled]: Disabled,
          [commonClasses.inputElement]:
            !Disabled && _.isEqual(ErrorMessage.length, 0),
          [commonClasses.error]: !_.isEqual(ErrorMessage.length, 0),
          [commonClasses.elementFocus]:
            focus && !Disabled && _.isEqual(ErrorMessage.length, 0),
        })}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setFocus(false);
          return onValidateForms({
            formId,
            formGroupId,
            formElement,
            value: input,
          });
        }}
        value={_.isNaN(parseInt(input)) ? "" : input}
        onChange={(event: any): void => {
          const InputValue = event.target.value || "";
          const IsNumber = utilHelper.getValidNumber(InputValue, formElement);
          if (_.isNull(IsNumber) && !_.isEqual(InputValue.length, 0)) {
            return;
          }
          setInput(InputValue);
        }}
        endAdornment={
          <InputAdornment className={classes.inputAdornment} position="end">
            <KeyboardArrowUp
              onClick={() => onClickUpDown(formElement.value, 1)}
              className={classNames(
                classes.arrowUp,
                {
                  [commonClasses.iconFocus]:
                    focus && !Disabled && _.isEqual(ErrorMessage.length, 0),
                },
                { [commonClasses.disabledIcon]: Disabled }
              )}
              fontSize={"small"}
            />
            <KeyboardArrowDown
              onClick={() => onClickUpDown(formElement.value, -1)}
              className={classNames(
                classes.arrowDown,
                {
                  [commonClasses.iconFocus]:
                    focus && !Disabled && _.isEqual(ErrorMessage.length, 0),
                },
                { [commonClasses.disabledIcon]: Disabled }
              )}
              fontSize={"small"}
            />
          </InputAdornment>
        }
      />
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={Disabled ? "" : ErrorMessage}
      />
    </Fragment>
  );
};

// Default Props
UINumberInput.defaultProps = {
  formElement: {},
};
