import { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import React, { useEffect, useState } from "react";
import { CustomStyles } from "./style";
import { EditFormHelper } from "../../Form/Helpers/Forms";
import { IOption } from "../../../../../UI/Elements/Forms/interface/IOption";
import { IFormElementConfig } from "../../../Interfaces/Form/FormElement/IFormElementConfig";
import * as _ from "lodash";
import { IFormElementValidation } from "../../../Interfaces/Form/FormElement/IFormElementValidation";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../../Interfaces/Form/IForm";
import { FormHelper } from "../../Form/Helpers/Forms/Helper";
import { createOption, isCloseMenuOnSelect } from "./Utility";


type IProps = {
  value: IOption | null;
  onCreateOption: any;
  formElement: IFormElement;
  form: IForm;
  onChange: any;
  errorMessage: string;
};

const form_Helper = new FormHelper();

export const UICreatableSelect = (props: IProps): React.JSX.Element => {
  const { onChange, value, formElement, errorMessage, form, onCreateOption } =  props;
  const [selectValue, setSelectValue] = useState(value);
  useEffect(() => {
    setSelectValue(value);
  }, [JSON.stringify(value)]);

  const is_Error = !_.isEqual(errorMessage.length, 0);
  const Element_Configuration: IFormElementConfig = formElement.configuration;
  const Element_Validation: IFormElementValidation = formElement.validations;
  const Disabled = formElement.readOnly || formElement.disabled;
  const HasChildElements: boolean = form_Helper.ifHasChildElements(form,formElement);
  const Is_HighlightOptionError = !_.isEqual(
    (formElement.highlightRemovedOptionError || "").length,
    0
  );
  const onSelectChange = (selectOption: any): void => {
    const editForm_Helper = new EditFormHelper();
    const SelectValue = editForm_Helper.updateSelectValue(
      formElement,selectOption
    );
    setSelectValue(SelectValue);
    if (
      !HasChildElements ||
      (HasChildElements && 
      !Element_Configuration.isMulti)
    ) { onChange(SelectValue); }
  };

  return (
    <CreatableSelect
    closeMenuOnSelect={isCloseMenuOnSelect(formElement)}
    isClearable={!Element_Validation.required}
    onChange={(selectedOption: any) => {
      return onSelectChange(selectedOption);
    }}
    isDisabled={Disabled}
      onCreateOption={(inputValue: string) => {
        const InputValue = (inputValue || "").toString().trim().toLowerCase();
        if (_.isEmpty(InputValue)) {
          return;
        }
        const Is_Exists = Element_Configuration.dataSource.find(
          (option: IOption) => {
            const OptionValue = (option.value || "")
              .toString()
              .trim()
              .toLowerCase();

            return _.isEqual(OptionValue, InputValue);
          }
        );

        if (_.isUndefined(Is_Exists)) {
          const NewOption = createOption(inputValue);
          onCreateOption(NewOption);
        }
      }}
      onBlur={(): void => {
        return onSelectChange(selectValue);
      }}
      placeholder={Element_Configuration.placeholder}
      options={
        _.isArray(Element_Configuration.dataSource)
          ? Element_Configuration.dataSource
          : []
      }
      value={selectValue}
      styles={CustomStyles(is_Error, Disabled, Is_HighlightOptionError)}
      components={{
        DropdownIndicator: (data: any) => {
          return (
            <components.DropdownIndicator {...data} className="caret-select" />
          );
        },
      }}
    />
  );
};
