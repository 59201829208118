import { Theme } from "@material-ui/core/styles";
import COLORS from "../../UI/Theme/colors";


const style: any = (theme: Theme) => ({
	root: {
		width: "100%",
		flexGrow: 1,
		padding: "32px 32px 20px",
	},
	container: {
		height: 92,
		alignItems: "center",
		padding: "32px 40px 24px",
		backgroundColor: "#FFFF",
	},
	title: {
		fontSize: 24,
		fontWeight: theme.typography.fontWeightBold,
	},
	toolBoxCountText: {
		fontSize: 18,
		fontWeight: theme.typography.fontWeightBold,
	},
	noHistoryMessage: {
		color: "#6a737c",
		fontSize: 16,
		fontWeight: 600,
		marginRight: 6,
		textAlign: "center",
		padding: "0px 40px 40px",
		height: 94,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		paddingTop: "65px"
	},
	noHistoryMessageContainer: {
		paddingLeft: 12,
		paddingRight: 12,
		backgroundColor: "#ffffff",
		height: "120px",
		width: "100%",
	},
	search: {
		position: "relative" as const,
		borderRadius: 2,
		backgroundColor: COLORS.WHITE,
		marginLeft: 8,
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "auto",
		},
		height: "max-content",
	},
	searchIcon: {
		padding: "0px 13px",
		height: "100%",
		position: "absolute" as const,
		pointerEvents: "none" as const,
		display: "flex",
		alignItems: "center" as const,
		justifyContent: "center" as const,
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		maxWidth: "100%",
		fontSize: 14,
		height: 40,
		width: 396,
		fontWeight: "normal" as const,
		backgroundColor: "rgba(255, 255, 255, 0)",
		border: "1px solid rgb(208, 213, 218)",
		borderRadius: 2,
		"box-sizing": "border-box",
	},
	inputElement: {
		"&:hover": {
			border: "1px solid rgb(134, 142, 150)",
		},
		"&.Mui-focused": {
			border: "1px solid rgb(58, 145, 232)",
		},
		"&.Mui-disabled": {
			border: "1px solid rgb(208, 213, 218)",
		},
	},
	noFoundContainer: {
		width: "100%",
		height: 94,
		padding: "32px 32px 40px",
	},
	noFoundMessage: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: 16,
		marginRight: 6,
		color: COLORS.GRAY,
	},
	closeButton: {
		height: 16,
		width: 16,
	},
	filterButton: {
		border: "1px solid rgb(208, 213, 218)",
		backgroundColor: COLORS.WHITE,
		color: COLORS.DARK_GRAY,
		padding: "7px 12px 8px 12px",
		fontWeight: theme.typography.fontWeightMedium,
		"&:hover": {
			border: "1px solid #868e96",
			backgroundColor: COLORS.WHITE,
		},
		minWidth: 106,
		justifyContent: "flex-start"
	},
	headerContainer: {
		display: "flex",
		width: "100%"
	},
	toolRecordCountText: {
		fontSize: 14,
		paddingTop: 5,
		paddingBottom: 30,
		letterSpacing: "normal",
		color: COLORS.GRAY,
		fontWeight: "normal"
	},
});

export default style;
