import React, { useEffect, useRef, useState } from "react";
import ShowMoreText from "react-show-more-text";
import * as _ from "lodash";
import classNames from "classnames";
import style from "./style";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));
type props = {data: any;};

//Show or Hide items
export const ShowMoreComponent = (props: props): React.JSX.Element => {
  const cellRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const ResizeEL = (): void => {
      const CellElement = cellRef.current;
      const scrollHeight = CellElement?.scrollHeight;
      setExpanded(_.lt(42, scrollHeight));
    };
    window.addEventListener("resize", ResizeEL);
    ResizeEL();

    return () => {
      window.removeEventListener("resize", ResizeEL);
    };
  }, []);

  return (
    <div ref={cellRef}>
      <ShowMoreText
        more="Show"
        lines={2}
        className={classes.content}
        less="Hide"
        expanded={expanded}
        anchorClass={classNames(classes.link)}
        truncatedEndingComponent={"... "}
      >
        <div
        dangerouslySetInnerHTML={{
          __html: props.data,
        }}
        >
        </div>
      </ShowMoreText>
    </div>
  );
};
