enum ELEMENT_TYPES {
	ID = 1,
	SELECT_INPUT = 2,
	TEXT_INPUT = 3,
	SWITCH = 4,
	RADIO_INPUT = 5,
	CHECKBOX_INPUT = 6,
	DISPLAY_TEXT = 7,
	TOGGLE_INPUT = 8,
	DATE_PICKER = 9
}



enum PAGE_ACCESS_TYPE {
	NOT_FOUND = 2,
	UNAUTHORIZED = 1,
}
enum SORT_ORDER {
	ASC = "asc",
	DESC = "desc",
}

enum TEXT_ALIGNMENT {
	CENTER = 1,
	DEFAULT = 0,
	RIGHT = 1,
	LEFT = 1,
}

enum FORM_TITLE {
	EDIT = "Edit",
	NEW = "New",
}

enum AUTH_APP_NAME {
	FACEBOOK = "facebook",
	GOOGLE = "google",
}

enum GRID_CELL_ELEMENT {
	DEFAULT = "default",
	TWO_LINER_TITLE_SUBTITLE = "two-liner-title-subtitle",
	TWO_LINER_TEXT = "two-liner-text",
}

enum NOTIFICATION_TYPE {
	ERROR = "error",
	WARNING = "warning",
	INFO = "info",
	SUCCESS = "success",
}

const ALL_OPTION_VALUE = "all";
const ALL_OPTION_LABEL = "All";

const ALL_OPTION_OBJ = {
	value: ALL_OPTION_VALUE,
	label: ALL_OPTION_LABEL,
};


const DAY_OF_MONTH = (): any[] => {
	const arr = [];
	for (let i = 1; i <= 28; i++) {
		arr.push({
			label: i,
			value: i,
		});
	}
	return arr;
};

const TIME = (): any[] => {
	const arr = [];
	let num = "";
		for (let i = 0; i <= 23; i++) {
			if(i<=9){
				const j = 0;
				num = `${j}${i}:00`;
			}
			else{
				num = `${i}:00`;
			}
			arr.push({
				value: num,
				label: num,
			});
		}
	return arr;
};

export {
	SORT_ORDER,
	TEXT_ALIGNMENT,
	ELEMENT_TYPES,
	GRID_CELL_ELEMENT,
	PAGE_ACCESS_TYPE,
	AUTH_APP_NAME,
	NOTIFICATION_TYPE,
	DAY_OF_MONTH,
	TIME,
	FORM_TITLE,
	ALL_OPTION_VALUE,
	ALL_OPTION_LABEL,
	ALL_OPTION_OBJ,
};


