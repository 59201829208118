import * as _ from "lodash";
import { IFormElement } from "../../../interface/IFormElement";

// Validation helper for URL
export class URLValidation{

	/**
     * Validate URL
     *
     * @param {IFormElement} element.
     * @param {any} elementValue.
     * @return {string} errorMessages.
     */
	validate(formElement: IFormElement, elementValue: any): string {
		const result = elementValue.match(/(?:^|\s)((https?:\/\/)?(?:localhost|[\w-]+(?:\.[\w-]+)+)(:\d+)?(\/\S*)?)/g);
		return !_.isNull(result) ? ""  :`${formElement.labelName} is not a valid URL.`;
	}
}