import React from "react";
import {
	Radio,
	RadioGroup,
	FormControlLabel,
	FormControl
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import UILabel from "../ChildElements/Label";
import { UIErrorOrNoteMessage } from "../ChildElements/ErrorOrNote";

// Radio input type component

type props = any;

const UIRadioInput = (props: props): React.JSX.Element => {
	const { formElement, onChange, formId, formGroupId, classes } = props;
	const [value, setValue] = React.useState(formElement.value);

	const handleChange = (event: any): any => {
		setValue(event.target.value);
		const Value = event.target.value;
		onChange({ formId, formGroupId, formElement, value: Value });
	};

	const elements = formElement.configuration.dataSource.map((d: any) =>
		<FormControlLabel
			disabled={props.disabled}
			key={d.value}
			value={d.value}
			control={<Radio color="primary" />}
			label={d.label}
		/>);

	return (
		<>
			<UILabel formElement={formElement} />
			<FormControl component="fieldset">
				<RadioGroup
					name={formId}
					value={value}
					className={classes.formGroup}
					onChange={handleChange} id={formElement.id}>
					{elements}
				</RadioGroup>
			</FormControl>
			<UIErrorOrNoteMessage formElement={formElement} errorMessage={""} />
		</>
	);
};

export default withStyles(styles)(UIRadioInput);