import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, Grid } from "@material-ui/core";
import styles from "./style";
import { withErrorHandler } from "../../HOC";
import classNames from "classnames";

const LandingPageLoad = (props: any): JSX.Element => {
  const { classes } = props;
  const boxes = 3;

  const LoadingBox = (): JSX.Element[] => {
    const LoadingBoxes = [];
    for (let i = 0; i < boxes; i++) {
      LoadingBoxes.push(
        <Grid
          key={i}
          container
          item
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          justifyContent="center"
        >
          <Card className={classes.cardContainer}>
            <div
              className={classNames(classes.loadAvatar, classes.gradient)}
            ></div>
            <div
              className={classNames(classes.loadTitle, classes.gradient)}
            ></div>
            <div
              className={classNames(classes.loadVersion, classes.gradient)}
            ></div>
            <div
              className={classNames(classes.loadDescription, classes.gradient)}
            ></div>
          </Card>
        </Grid>
      );
    }

    return LoadingBoxes;
  };

  return (
    <Grid
      container
      spacing={3}
      className={classes.toolBaxWrapper}
      direction="row"
    >
      {LoadingBox()}
    </Grid>
  );
};

export default withStyles(styles)(withErrorHandler(LandingPageLoad));
