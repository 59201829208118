import React, { useEffect } from "react";
import {
    Button,
    Tooltip
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IconArrowDown } from "../../Icons";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import style from "./style";
import { useSelector, useDispatch } from "react-redux";
import { updateFilterState, updateSortLabel } from "../../../store/actions/Automation/FilterState";

type props = {
    filterOptions?: any;
    handleChange: any;
    data: any;
};

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

const SortButton = (props: props): JSX.Element => {
    const { handleChange, data } = props;
    const classes = useStyles();
    const REDUX_STATE = useSelector((state: any) => ({
        FILTER_STATE: state.filter,
        BUNDLE: state.initial.bundle,
        TOOL_LIST: state.toolList,
    }));

    const dispatch = useDispatch();

    const sortButton = data.map((button: any, index: any) => {
        return (
            <Tooltip
                title={button.label === "Recently Used" ? REDUX_STATE.BUNDLE.RECENCY_TOOLTIP : ""}
                placement="bottom-start">
                <MenuItem className={classes.menuItems}
                    key={index}
                    onClick={(e) => handleClose(e, button.label, button.value)}>
                    {button.label}
                </MenuItem>
            </Tooltip>
        );
    });

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    const handleToggle = (): void => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<EventTarget>, label: any, value: any): void => {
        event.preventDefault();

        const Data = {
            ...REDUX_STATE.FILTER_STATE.filter,
            order_by: value
        };

        handleChange(event, value);
        dispatch(updateSortLabel(value));
        dispatch(updateFilterState(Data));

        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };
    const handleClose1 = (event: React.MouseEvent<EventTarget>): void => {

        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event: React.KeyboardEvent): void {
        if (event.key === "Tab") {
            event.preventDefault();
            setOpen(false);
        }
    }
    const prevOpen = React.useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);
    return (
        <>
            <Tooltip
                title={REDUX_STATE.FILTER_STATE.sortLabel === "Recently Used" ? REDUX_STATE.BUNDLE.RECENCY_TOOLTIP : ""}
                placement="bottom-start">
                <Button
                    className={classes.sortButton}
                    disabled={REDUX_STATE.TOOL_LIST.loading}
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <div style={{ opacity: REDUX_STATE.TOOL_LIST.loading ? 0.5 : 1 }}>
                        <IconArrowDown title="" />
                    </div>
                    {REDUX_STATE.FILTER_STATE.sortLabel === "Recently Used" ? REDUX_STATE.BUNDLE.SORT_FILTER_BUTTON_TITLE : REDUX_STATE.BUNDLE.SORT_BUTTON_TITLE} {REDUX_STATE.FILTER_STATE.sortLabel}
                </Button>
            </Tooltip>

            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.sortGroup}>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            boxShadow:
                                "0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)",
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                        }}
                    >
                        <Paper className={classes.sortGroup}>
                            <ClickAwayListener onClickAway={handleClose1}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown} className={classes.menuList}>
                                    {sortButton}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>

        </>
    );
};

export default SortButton;
