import { Theme } from "@material-ui/core/styles";
import COLORS from "../../Theme/colors";

const style: any = (theme: Theme): any => ({

    sortButton: {
        border: "1px solid rgb(208, 213, 218)",
        backgroundColor: COLORS.WHITE,
        color: COLORS.DARK_GRAY,
        marginLeft: "8px",
        height: 38,
        fontSize: "14px",
        minWidth: "190px",
        fontWeight: theme.typography.fontWeightMedium,
        "&:hover": {
            border: "1px solid #868e96",
            backgroundColor: COLORS.WHITE,
        }
    },
    sortGroup: {
        zIndex: 3,
        minWidth: "190px"
    },
    menuList: {
        paddingTop: 12,
        paddingBottom: 12,
        marginTop: 1,
        minWidth: 102
    },
    selectedMenu: {
        color: "#242c34",
        fontWeight: 600,
        backgroundColor: "#FFFFFF"
    },
    menuItems: {
        padding: "6px 16px 7px 16px",
        fontSize: 14,
        color: COLORS.DARK_GRAY,
        "&:hover": {
            color: `${COLORS.DARK_BLUE} !important`,
            backgroundColor: "#f7f8fa !important"
        },
        minHeight: 34
    },

});

export default style;