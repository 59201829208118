import Colors from "../Theme/colors";

const style: any = (): any => ({
    iconButton: {
        height: 36,
        width: 36
    },
    iconButtonMedium: {
        height: 24,
        width: 24
    },
    tooltip: {
        display: "inline-block",
    },
    closeIcon: {
        color: Colors.BORDER_BUTTON_COLOR
    },
    copyIcon: {
        color: Colors.WHITE,
        height: 18,
        width: 18,
    },
    viewDetailIcon: {
        color: Colors.WHITE,
    },
    cloneIcon: {
        color: Colors.WHITE,
    },
    editIcon: {
        color: "#6A737C",
        opacity: 0.6
    },
    iconDownload: {
        height: 24,
        width: 24
    },
    iconImage: {
        height: 24,
        width: 24,
    },
    iconDownloadSmall: {
        height: 18,
        width: 18
    },
    filterIcon: {
        paddingTop: 5,
        paddingRight: 8
    },
    disabled: {
        opacity: 0.5
    },
    sortIcon: {
        paddingTop: 5,
        paddingRight: 8,

    },
    menuGroup: {
        zIndex: 3,
        minWidth: "147px",
        height: "120px",
        marginRight: "50px",
    },
    menuList: {
        marginTop: "10px"
    },
    menuItem: {
        fontSize: "14px",
        color: "#242C34",
        fontWeight: 400
    },
    disabledDownloadIcon: {
        color: Colors.LIGHT_GRAY,
        opacity: 0.5,
        cursor: "default",
        PointerEvent: "none"
    },

});

export default style;