import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import * as _ from "lodash";

// Handles user profile redirection
export default (WrappedComponent: any): any => {
    class WithRequiredInfoProfile extends Component<any> {
      constructor(props: any) {
        super(props);
      }
      render(): React.JSX.Element {
        if (!_.isNull(this.props.userInfo) && _.isEqual(this.props.userInfo.isValidProfile, 1)) {
            return <Redirect to="/" />;
        }
        return <WrappedComponent {...this.props} />;
      }
    }
    const mapStateToProps = (state: any): any => ({
      bundle: state.initial.bundle,
      userInfo: state.initial.userInfo,
    });
    return connect(mapStateToProps, null)(withRouter(WithRequiredInfoProfile));
  };
  