import * as _ from "lodash";
import {
	IForm
} from "../../../interface/IForm";
import { IFormElement } from "../../../interface/IFormElement";
import { IFormGroup } from "../../../interface/IFormGroup";
import { IFormElementAuth } from "../../../interface/IFormElementAuth";
import { Axios } from "../../../../../../api/service/Axios";
import { GET_TIKTOK_ACCESS_TOKEN_URL } from "../../../../../../api/paths";
import { catchError } from "../../../../../../helpers/ErrorHandler.component";

// Auth Element
export class AuthElement {

	/**
	 * Find if any auth token is empty
	 *
	 * @param {IFormElementAuth[]} auth element array.
	 * @return {Boolean} true or false
	 */
	isAnyAuthGroupIsNull = (authGroups: IFormElementAuth[] | undefined): boolean => {
		const AuthGroups = authGroups || [];
		for (let iAuthGroup = 0; iAuthGroup < AuthGroups.length; iAuthGroup++) {
			if (_.isNull(AuthGroups[iAuthGroup].value) || _.isEmpty(AuthGroups[iAuthGroup].value)) {
				return true;
			}
		}
		return false;
	}

	/**
	 * Find if any auth token has error
	 *
	 * @param {IFormElementAuth[]} auth element array.
	 * @return {Boolean} true or false
	 */
	isAnyAuthGroupHasError = (authGroups: IFormElementAuth[]): string => {
		const AuthGroups = authGroups || [];
		for (let iAuthGroup = 0; iAuthGroup < AuthGroups.length; iAuthGroup++) {
			if (!_.isEmpty(AuthGroups[iAuthGroup].errorMessage)) {
				return AuthGroups[iAuthGroup].errorMessage;
			}
		}
		return "";
	}

	updateFormsWithToken = (forms: any, typeId: number, token: string): IForm[] => {
		return forms.map((form: IForm) => ({
			...form,
			groups: form.groups.map((group: IFormGroup) => ({
				...group,
				elements: group.elements.map((element: IFormElement) => {
					let isVisible = element.is_visible;
					let elementValue = element.value;
					if (!element.is_visible) {
						return ({
							...element
						});
					}
					if (!_.isNull(element.form_element_auth)) {
						elementValue = _.isEqual(element.form_element_auth.type_id, typeId) ?
							token : element.value;
						isVisible = _.isEqual(element.form_element_auth.type_id, typeId) ?
							false : element.is_visible;
					}

					return ({
						...element,
						is_visible: isVisible,
						value: elementValue,
					});
				})
			}))
		}));
	}
	/**
	 * Get Auth Information
	 *
	 * @param {IForm[]} form array.
	 * @return {IFormElementAuth[]} form array.
	 */
	getAuthGroups = async (forms: IForm[],
		toolId: any): Promise<{
			authGroups: IFormElementAuth[],
			forms: IForm[]
		}> => {
		const FormAuthGroups: IFormElementAuth[] = [];
		const Forms: IForm[] = [];

		for (const form of forms) {

			const FormGroups: IFormGroup[] = [];
			for (const formGroup of form.groups) {
				const FormElements: IFormElement[] = [];
				for (const formElement of formGroup.elements) {
					if (!_.isNull(formElement.form_element_auth)) {
						let elementValue = formElement.value;
						const AuthType = _.toLower(formElement.form_element_auth.type || "");
						const TiktokAuthCode = localStorage.getItem("TIKTOK_AUTH_CODE");
						let errorMessage = "";
						if (_.isEqual(AuthType, "tiktok") && !_.isNull(TiktokAuthCode)) {
							try {
								localStorage.removeItem("TIKTOK_AUTH_CODE");

								try {
									const Response = await Axios.post(GET_TIKTOK_ACCESS_TOKEN_URL, {
										authorizationCode: TiktokAuthCode,
										typeId: formElement.form_element_auth.type_id,
										toolId
									});
									elementValue = Response.data.data.access_token;
								}
								catch (err) {
									errorMessage = catchError(err);
									elementValue = null;
								}
							}
							catch {
								elementValue = formElement.value;
							}
						}

						FormAuthGroups.push({
							type_id: formElement.form_element_auth.type_id,
							type: formElement.form_element_auth.type,
							app_id: formElement.form_element_auth.app_id,
							fields: formElement.form_element_auth.fields,
							scope: formElement.form_element_auth.scope,
							value: elementValue,
							token_api: formElement.form_element_auth.token_api,
							in_header: formElement.form_element_auth.in_header,
							redirecturl: formElement.form_element_auth.redirecturl,
							rid: formElement.form_element_auth.rid,
							display_order: formElement.display_order,
							errorMessage
						});

						FormElements.push({
							...formElement,
							value: elementValue
						});
					}
					else {
						FormElements.push({
							...formElement
						});
					}
				}

				FormGroups.push({
					...formGroup,
					elements: FormElements
				});
			}

			Forms.push({
				...form,
				groups: FormGroups
			});
		}

		return {
			authGroups: _.orderBy(FormAuthGroups, ["display_order"], ["asc"]),
			forms: Forms
		};
	}
	/**
		 * Find auth element i.e google or facebook element
		 *
		 * @param {IFormElement} element.
		 * @param {IFormElementAuth[]} auth groups.
		 * @return {boolean} isExists.
		 */
	ifAuthElementExists = (authGroups: IFormElementAuth[], element: IFormElement): boolean => {
		let isExists = false;
		for (const authGroup of authGroups) {
			if (!_.isEqual(authGroup.app_id,
				element.form_element_auth.app_id)
				&& !_.isEqual(authGroup.type_id,
					element.form_element_auth.type_id)) {
				isExists = true;
			}
		}
		return isExists;
	}
}


