import Colors from "../../utilstyle";

const style: any = ():any => ({
	input: {
		height: 36,
		width:240,
		backgroundColor: Colors.BackgroundColor,
		border: `1px solid ${Colors.BorderColor}`,
		borderRadius: 2,
		fontSize:"0.875rem",
		padding: "7px 12px",
		"box-sizing": "border-box",
	},
	inputElement:{
		border: `1px solid ${Colors.BorderColor} !important`,
		"&:hover": {
			border: `1px solid ${Colors.HoverColor} !important`,
		},
		"&.Mui-focused": {
			border: `1px solid ${Colors.FocusColor} !important`,
			color: Colors.FocusColor,
			"& > div > button > span > svg": {
				fill: Colors.FocusColor,
			},
		},
	},
});

export default style;
