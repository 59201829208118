import Colors from "../../../../../UI/Elements/utilstyle";
import colors from "../../../colors";

const style: any = (): any => ({
	input: {
		height: 40,
		width: "100%",
		backgroundColor: Colors.BackgroundColor,
		border: `1px solid ${Colors.BorderColor}`,
		borderRadius: 2,
		fontSize: "0.875rem",
		padding: "7px 12px",
		"box-sizing": "border-box",
		color: "black !important"
	},
	inputElement: {
		border: `1px solid ${Colors.BorderColor} !important`,
		"&:hover": {
			border: `1px solid ${Colors.HoverColor} !important`,
		},
		"&.Mui-focused": {
			border: `1px solid ${Colors.FocusColor} !important`,
			color: Colors.FocusColor,
			"& > div > button > span > svg": {
				fill: Colors.FocusColor,
			},
		},
	},
	selectCursor: {
		"&:hover": {
			border: "1px solid #868e96",
			backgroundColor: colors.WHITE,
		},
	}
});

export default style;
