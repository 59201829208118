import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import { MiddleTextEllipsis } from "../../Elements/Text/MiddleEllipsis";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
	chip: string;
};

// Chip Component
export const Chip = (props: props): React.JSX.Element => {
	const { chip } = props;
	const classes = useStyles();

	return <div className={classes.chip}>
		<div className={classes.chipSize}>
			<MiddleTextEllipsis title={chip} showTooltip={true} />
		</div>
	</div>;

};

export default Chip;
