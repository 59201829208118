import React from "react";
import * as _ from "lodash";
import { IFormElement } from "../../Forms/interface/IFormElement";
import UILabel from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import { FILE_DOWNLOAD_API_URL } from "../../../../api/paths";
import { UIOutlineButton } from "../../../Buttons";

// Props type
type props = {
  formElement: IFormElement;
};

function isInfoMessage(formElement: IFormElement): boolean {
  if (_.isNull(formElement.value)) {
    return false;
  }
  if (!_.isObject(formElement.value)) {
    return false;
  }
  const ElementValue: any = formElement.value;
  if (_.isUndefined(ElementValue.info)) {
    return false;
  }
  return true;
}

const IsNotValidDownload = (formElement: IFormElement): boolean =>
  _.isNull(formElement.value) ||
  _.isEmpty(formElement.value) ||
  isInfoMessage(formElement);

// UIDownload Component
export const UIDownload = (props: props): JSX.Element => {
  const { formElement } = props;

  let errorMessage = formElement.touched ? formElement.error : "";
  let disabled = formElement.disabled || formElement.read_only;
  if (!_.isEmpty(formElement.apiError)) {
    errorMessage = formElement.apiError;
    disabled = false;
  }

  let linkLabel = "";

  if (!_.isNull(formElement.value)) {
    linkLabel = formElement.value.title || "";
  }
  const onLinkClick = (): void => {
    if (IsNotValidDownload(formElement)) {
      return;
    }
    const LinkUrl = formElement.value.url || "";
    const FileName = formElement.value.fileName || "";
    window.location.href = `${
      process.env.REACT_APP_BASE_URL
    }${FILE_DOWNLOAD_API_URL}?filename=${FileName}&fileurl=${encodeURIComponent(
      LinkUrl
    )}`;
  };

  return (
    <>
      <UILabel formElement={formElement} />
      <UIOutlineButton
        isOutline={true}
        onClick={onLinkClick}
        disabled={IsNotValidDownload(formElement)}
        cssStyle={{
          textTransform: "none",
          fontWeight: 600,
        }}
        btnText={linkLabel || "Download"}
      />
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={disabled ? "" : errorMessage}
      />
    </>
  );
};

export default UIDownload;
