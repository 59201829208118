import React from "react";
import { Drawer, makeStyles } from "@material-ui/core";
import { ViewDetailsSidebar } from "./ViewDetailsSidebar";
import { IForm } from "../../../../../../../UI/Elements/Forms/interface/IForm";

type props = {
    formTitle: string;
    toggleDrawer: any;
    btnCancelText: string;
    onCancel: any;
    open: boolean;
    width?: number;
    formData: IForm[];
    jobId: number;
    jobName: number;
};

const useStyles = makeStyles({
    paper: {
        overflowX: "hidden"
    }
});

export const ViewDetail: any = (props: props): React.JSX.Element => {
    const {
        formTitle,
        btnCancelText,
        onCancel,
        open,
        width,
        formData,
        jobId,
        jobName
    } = props;
    const classes = useStyles();


    return (
        <Drawer anchor="right" open={open} classes={{ paper: classes.paper }} >
            {open && (
                <ViewDetailsSidebar
                    formTitle={formTitle}
                    btnCancelText={btnCancelText}
                    onCancel={onCancel}
                    width={width}
                    jobId={jobId}
                    jobName={jobName}
                    formData={formData}
                />
            )}
        </Drawer>
    );
};
