import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	root: {
		width: "100%",
		flexGrow: 1,
		padding: "32px 40px 12px",
	},
	rootName: {
		flexGrow: 1
	},
	tabsContainer: {
		height: 185,
		padding: "32px 40px 24px",
		backgroundColor: COLORS.WHITE
	},
	title: {
		fontSize: 24,
		fontWeight: theme.typography.fontWeightBold,
		marginBottom: "8px"

	},
	tabs: {
		textTransform: "none" as const,
		minWidth: "auto",
		fontSize: 16,
		fontWeight: theme.typography.fontWeightMedium,
		color: COLORS.GRAY,
		marginRight: 30,
		"&:hover": {
			color: COLORS.DARK_GRAY,
			opacity: 1
		},
		"&selected": {
			color: COLORS.DARK_GRAY,
		},
		"&:focus": {
			color: COLORS.DARK_GRAY,
		},
	},
	tabText: {
		"&.Mui-selected": {
			color: COLORS.DARK_GRAY,
		},
	},
	toolBoxWrapper: {
		width: "100%",
		padding: "0px 28px 28px 28px",
		margin: 0
	},
	search: {
		position: "relative" as const,
		borderRadius: 2,
		backgroundColor: COLORS.WHITE,
		marginLeft: 8,
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "auto",
		}
	},
	searchIcon: {
		padding: "0px 13px",
		height: "100%",
		position: "absolute" as const,
		pointerEvents: "none" as const,
		display: "flex",
		alignItems: "center" as const,
		justifyContent: "center" as const,
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		maxWidth: "100%",
		fontSize: 14,
		height: 36,
		width: 396,
		fontWeight: "normal" as const,
		backgroundColor: "rgba(255, 255, 255, 0)",
		border: "1px solid rgb(208, 213, 218)",
		borderRadius: 2,
		"box-sizing": "border-box",
	},
	inputElement: {
		"&:hover": {
			border: "1px solid rgb(134, 142, 150)",
		},
		"&.Mui-focused": {
			border: "1px solid rgb(58, 145, 232)",
		},
		"&.Mui-disabled": {
			border: "1px solid rgb(208, 213, 218)",
		}
	},
	toolBoxCountText: {
		fontSize: 14,
		lineHeight: 1.5,
		letterSpacing: "normal",
		color: COLORS.GRAY,


	},
	noFoundContainer: {
		width: "100%",
		height: 94,
		padding: "32px 32px 40px"
	},
	noFoundMessage: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: 16,
		marginRight: 6,
		color: COLORS.GRAY
	},
	loadMoreTools: {
		border: "1px solid rgb(134, 142, 150)",
		height: 48,
		width: 201,
		background: COLORS.WHITE,
		margin: "32px auto"
	},
	scrollTop: {
		position: "fixed" as const,
		left: "50%",
		top: 72,
		zIndex: 1000,
		fontSize: 14,
		minWidth: 64,
		color: COLORS.WHITE,
		width: "auto",
		border: 0,
		fontWeight: theme.typography.fontWeightMedium,
		backgroundColor: "#1576d6",
		padding: "12px 10px",
		cursor: "pointer",
		borderRadius: 2,
		"&:hover": {
			backgroundColor: "rgb(14, 82, 149)"
		}
	},
	closeButton: {
		height: 16,
		width: 16
	},

});

export default style;