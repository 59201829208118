import React from "react";
import { Input } from "@material-ui/core";
import { v4 as uuid4 } from "uuid";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import classNames from "classnames";
import style from "./style";
import commonStyle from "../../../Elements/FormElements/sharedStyle";
import { IFormElement } from "../../../Elements/Forms/interface/IFormElement";
import { IFileUpload } from "../../../Elements/Forms/interface/File/IFileUpload";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  onChange: any;
  file: IFileUpload;
  formElement: IFormElement | null;
  disabled: boolean;
};

const isConfigEmpty = (formElement: IFormElement): boolean => {
  if (_.isNull(formElement.customConfiguration)) {
    return true;
  }
  if (_.isUndefined(formElement.customConfiguration.bucketUpload)) {
    return true;
  }
  if (_.isUndefined(formElement.customConfiguration.bucketUpload.textBox)) {
    return true;
  }
  return false;
};

// UIFileInput Component
export const UIFileInput = (props: props): JSX.Element => {
  const { onChange, file, formElement, disabled } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();

  if (_.isNull(formElement)) {
    return <></>;
  }

  if (isConfigEmpty(formElement)) {
    return <></>;
  }

  const Value = file.fileInputText || "";

  const Placeholder: string =
    formElement.customConfiguration.bucketUpload?.textBox?.placeholder || "";
  const IsError: any = formElement.customConfiguration.bucketUpload?.textBox
    ?.required
    ? _.isEqual(Value.toString().trim().length, 0) && file.touched
    : false;

  return (
    <Input
      type="text"
      disabled={disabled}
      id={uuid4()}
      className={classes.container}
      value={Value}
      placeholder={Placeholder}
      onChange={(event: any): void => {
        const InputValue = event.target.value;
        onChange({
          fileInputText: InputValue,
          touched: true,
        });
      }}
      inputProps={{
        style: {
          height: 36,
        },
        className: classNames(classes.input, {
          [commonClasses.disabled]: disabled,
          [classes.inputElement]: !disabled && !IsError,
          [commonClasses.error]: IsError,
        }),
        onBlur: () => {
          onChange({
            fileInputText: Value,
            touched: true,
          });
        },
      }}
    />
  );
};
