import React, { useEffect, useRef } from "react";
import { useOktaAuth } from "@okta/okta-react";
import OktaSignIn from "@okta/okta-signin-widget";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import style from "./style";
import config from "../../auth/auth.config";
import GroupMNexusLogo from "./../../assets/icons/GroupMNexusLogo.png";
import GroupMBG from "./../../assets/GroupMBG.png";
import "./style.css";
import { Footer } from "./Footer";
import { useSelector } from "react-redux";

// CSS Styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Login Widget
const Login = ({ setCorsErrorModalOpen }: any): any => {
  const { oktaAuth, authState } = useOktaAuth();
  const widgetRef: any = useRef();
  const initial = useSelector((state: any) => state.initial);

  const classes = useStyles();

  const queryParams: any = new URLSearchParams(window.location.search);
  const otp: any = queryParams.get("otp");
  const state: any = queryParams.get("state");

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }

    const { issuer, clientId, redirectUri, scopes, useInteractionCode }: any =
      config.oidc;
    const WidgetConfig: any = {
      baseUrl: issuer.split("/oauth2")[0],
      clientId,
      redirectUri,
      language: "en",
      authParams: {
        issuer,
        scopes,
      },
      i18n: {
        en: {
          "primaryauth.title": process.env.REACT_APP_OKTA_LOGIN_HEADER_TITLE,
          "primaryauth.username.tooltip":
            process.env.REACT_APP_OKTA_LOGIN_USERNAME_TOOLTIP,
        },
      },
      useInteractionCodeFlow: useInteractionCode,
      state,
      otp,
      logo: GroupMNexusLogo,
    };
    const widget: any = new OktaSignIn(WidgetConfig);

    widget.renderEl(
      { el: widgetRef.current },
      (res: any) => {
        oktaAuth.handleLoginRedirect(res.tokens);
      },
      (error: any) => {
        throw error;
      }
    );

    const isCorsError = (err: any): any =>
      err.name === "AuthApiError" && !err.statusCode;

    widget.on("afterError", (_context: any, error: any) => {
      if (isCorsError(error)) {
        setCorsErrorModalOpen(true);
      }
    });

    return () => widget.remove();
  }, [oktaAuth]);

  if (authState?.isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <div className={initial.isEmbed ? classes.loginContainerembed : classes.loginContainer}>
      <img src={GroupMBG} className={classes.bgImage} />
      <div ref={widgetRef} />
      <Footer />
    </div>
  );
};

export default Login;
