import { Theme } from "@material-ui/core/styles";

const style: any = (theme: Theme): any => ({
	form: {
		backgroundColor: "#ffffff",
		padding: "2rem",
		width: "60%",
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	infoWrapper: {
		padding: 12,
	},
	contentBoxInfoWrapper: {
		width: "100%",
	},
	toolContentWrapper: {
		padding: "20px 28px 28px 28px",
	},
	toolContent: {
		width: "100%",
		backgroundColor: "#FFFF",
		padding: 31,
	},
});

export default style;
