import { components } from "react-select";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { CustomStyles } from "./style";
import { createOption, isCloseMenuOnSelect } from "./Utility";
import { IOption } from "../../../../../UI/Elements/Forms/interface/IOption";
import { EditFormHelper } from "../../../Helpers/Forms/Edit";
import { FormHelper } from "../../../Helpers/Forms/Helper";
import { IFormElementValidation } from "../../../Interfaces/Form/FormElement/IFormElementValidation";
import { IForm } from "../../../Interfaces/Form/IForm";
import { IFormElementConfig } from "../../../Interfaces/Form/FormElement/IFormElementConfig";

type IProps = {
  value: IOption | null;
  onChange: any;
  errorMessage: string;
  formElement: IFormElement;
  onCreateOption: any;
  form: IForm;
};

const formHelper = new FormHelper();

export const UICreatableSelect = (props: IProps): React.JSX.Element => {
  const { onChange, 
    value,
     formElement,
      errorMessage,
       form, 
       onCreateOption } =
    props;
    
    const [selectValue, setSelectValue] = useState(value);
    const ElementConfiguration: IFormElementConfig = formElement.configuration;
    const Disabled = formElement.readOnly || formElement.disabled;
    const isError = !_.isEqual(errorMessage.length, 0);
  const ElementValidation: IFormElementValidation = formElement.validations;
  const HasChildElements: boolean = formHelper.ifHasChildElements(form,formElement);

  const IsHighlightOptionError = !_.isEqual(
    (formElement.highlightRemovedOptionError || "").length,
    0
  );

  useEffect(() => {
    setSelectValue(value);
  }, [JSON.stringify(value)]);

  const onSelectChange = (selectOption: any): void => {
    const editFormHelper = new EditFormHelper();
    const SelectValue = editFormHelper.updateSelectValue_hist(
      formElement,
      selectOption
    );
    setSelectValue(SelectValue);
    if ( !HasChildElements || (HasChildElements && !ElementConfiguration.isMulti)) {
      onChange(SelectValue);
    }
  };

  return (
    <CreatableSelect
    onChange={(selectedOption: any) => {
      return onSelectChange(selectedOption);
    }}
    isClearable={!ElementValidation.required}
    isDisabled={Disabled}
    closeMenuOnSelect={isCloseMenuOnSelect(formElement)}
      
      onCreateOption={(inputValue: string) => {
        const InputValue = (inputValue || "").toString().trim().toLowerCase();

        if (_.isEmpty(InputValue)) {
          return;
        }
        const IsExists = ElementConfiguration.dataSource.find(
          (option: IOption) => {
            const OptionValue = (option.value || "")
              .toString()
              .trim()
              .toLowerCase();

            return _.isEqual(OptionValue, InputValue);
          }
        );

        if (_.isUndefined(IsExists)) {
          const NewOption = createOption(inputValue);
          onCreateOption(NewOption);
        }
      }}
      onBlur={(): void => {
        return onSelectChange(selectValue);
      }}
      value={selectValue}
      styles={CustomStyles(isError, Disabled, IsHighlightOptionError)}
      components={{
        DropdownIndicator: (data: any) => {
          return (
            <components.DropdownIndicator {...data} className="caret-select" />
          );
        },
      }}
      placeholder={ElementConfiguration.placeholder}
      options={
        _.isArray(ElementConfiguration.dataSource)
          ? ElementConfiguration.dataSource
          : []
      }
     
      
    />
  );
};
