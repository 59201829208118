import React, { Fragment, useState, useEffect } from "react";
import { Input } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import classNames from "classnames";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import style from "./style";
import commonStyle from "../sharedStyle";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UIOutlineButton } from "../../../Buttons";
import { IconTrash } from "../../../Icons/Delete";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  onValidateForms: any;
  formGroupId: number;
};

// UIInput Component
export const UITextInput = (props: props): JSX.Element => {
  const { formElement, onValidateForms, formId, formGroupId, onChange } = props;
  const Value = formElement.value || "";
  const [input, setInput] = useState(Value);
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const Disabled = formElement.disabled || formElement.read_only;
  let errorMessage = "";

  if (formElement.apiError) {
    errorMessage = formElement.apiError;
  } else {
    errorMessage = formElement.touched ? formElement.error : "";
  }

  if (Disabled && _.isEmpty(formElement.apiError)) {
    errorMessage = "";
  }

  let Props = {};

  if (formElement.is_multi) {
    Props = {
      ...Props,
      rows: 5,
      multiline: true,
    };
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!_.isEqual(Value, input)) {
        onChange({ formId, formGroupId, formElement, value: input });
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [input]);

  useEffect(() => {
    setInput(Value);
  }, [Value]);

  const LoadButtonText: string = _.isNull(formElement.load_data_button_label)
    ? "Load Data"
    : formElement.load_data_button_label;

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <div className={classes.inputTextButtonContainer}>
        <Input
          type="text"
          {...Props}
          disabled={Disabled}
          id={`${formElement.id}`}
          className={classes.container}
          style={{
            height: formElement.is_multi ? "auto" : 36,
          }}
          value={input}
          placeholder={formElement.placeholder}
          onChange={(event: any): void => {
            const InputValue = event.target.value;
            setInput(InputValue);
          }}
          inputProps={{
            style: {
              height: formElement.is_multi ? "auto" : 36,
            },
            className: classNames(classes.input, {
              [commonClasses.disabled]: Disabled,
              [classes.inputElement]:
                !Disabled && _.isEqual(errorMessage.length, 0),
              [commonClasses.error]: !_.isEqual(errorMessage.length, 0),
            }),
            onBlur: () => {
              onValidateForms({
                formId,
                formGroupId,
                formElement,
                value: input,
                isLoadDataButtonClicked: false,
              });
            },
          }}
        />
        {formElement.hasChildElement && (
          <div className={classes.dataLoadButton}>
            <UIOutlineButton
              isOutline={true}
              onClick={() => {
                onChange({
                  formId,
                  formGroupId,
                  formElement,
                  value: input,
                  isLoadDataButtonClicked: true,
                });
              }}
              disabled={!_.isEqual(errorMessage.length, 0)}
              btnText={LoadButtonText}
            />
          </div>
        )}
        {formElement?.deletebyIds && _.gte(formElement?.deletebyIds?.length, 0) && <><span style={{ marginLeft: "7px" }}> <IconTrash onClick={() => {
          onChange({
            formId,
            formGroupId,
            formElement,
            value: input,
            isLoadDataButtonClicked: true,
            deleteElements: true
          });
        }} title="Delete" disabled={false} isFileUpload={false} /></span><strong style={{ marginRight: "51px" }}>Delete</strong></>}
      </div>

      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={errorMessage}
      />
    </Fragment>
  );
};

// Default Props
UITextInput.defaultProps = {
  formElement: {},
};
