import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import style from "./style";
import UITooltip from "../../../Layout/Elements/Tooltip";

type props = {
  text: any;
  tooltip: any;
  label: any;
};

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const TwoLineEllipsis = (props: props): React.JSX.Element => {
  const { tooltip, text, label } = props;
  const [overflow, setOverflow] = useState(false);
  const classes = useStyles();
  const cellRef = useRef<HTMLTableCellElement>(null);

  useEffect(() => {
    const ResizeEL = (): void => {
      const CellElement = cellRef.current;
      const scrollHeight = CellElement?.scrollHeight;
      const clientHeight = CellElement?.clientHeight;

      setOverflow(_.gt(scrollHeight, clientHeight));
    };
    window.addEventListener("resize", ResizeEL);
    ResizeEL();

    return () => {
      window.removeEventListener("resize", ResizeEL);
    };
  }, [text]);

  if (overflow) {
    return (
      <div className={classes.textContainerEllipse} ref={cellRef}>
        {_.isEmpty(label) ? "" : `${label} : `}
        <UITooltip title={tooltip || ""}>
          <span>{text}</span>
        </UITooltip>
      </div>
    );
  }

  return (
    <div className={classes.textContainer} ref={cellRef}>
      <span
        dangerouslySetInnerHTML={{
          __html: _.isEmpty(label) ? "" : `${label}&nbsp;:&nbsp;`,
        }}
      ></span>
      {text}
    </div>
  );
};
