import COLORS from "../Theme/colors";

const style: any = (): any => {
	return {
		inputTooltipLabel: {
			padding: 0,
			marginTop: -2,
			marginLeft: 6
		},
		invalidInput: {
			color: COLORS.RED
		},
		required: {
			color: COLORS.RED
		},
		labelContainer: {
			fontWeight: 500,
		}
	};
};

export default style;