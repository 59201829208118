import COLORS from "../../../../../UI/Theme/colors";

const style:any = (): any => ({
	fileUploader:{
		color: COLORS.BLACK,
		width:"16px !important",
		height:"16px !important",
		position:"absolute"
	},
	
	fileIcon:{
		display:"flex",
		alignItems:"center",
		width:20,
		position:"relative"
	},
	imagePreview:{
		width:"100%",
		boxShadow:"0px 1px 3px 0px rgb(0 0 0 / 10%), 0px 1px 2px 0px rgb(0 0 0 / 4%)"
	},
	imageUrlContainer:{
		minHeight: "1.25rem",
		marginBottom: "0.5rem",
		display: "flex",
		justifyContent: "flex-end"
	},
	imageUrlDelete:{
		fontSize:"0.75rem",
		color: COLORS.DARK_BLUE,
		cursor:"pointer",
		marginLeft: 20
	},
});

export default style;