import * as _ from "lodash";
import { IFileInput } from "../../../interface/File/IFileInput";
import { IFormElement } from "../../../interface/IFormElement";



// File Helper
export class FileHelper{
    /**
     * Check if the file input is empty
     *
     * @param {File | null} value.
     * @return boolean.
     */
    isFileInputEmpty = (value: IFileInput | null): boolean => {

    	if(_.isNull(value) || _.isUndefined(value)){
    		return true;
    	}

    	if(_.isEmpty(value.acceptedFiles)){
    		return true;
    	}
    	return false;
    }

	    /**
     * Get formatted bytes
     *
     * @param {File | null} value.
     * @return boolean.
     */
		formatBytes(bytes: number, decimals = 2): any {
			if (bytes === 0) return "0 Bytes";
		
			const k = 1024;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
		
			const i = Math.floor(Math.log(bytes) / Math.log(k));
		
			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
		}

	/**
     * Break filename
     *
     * @param {string} fileName.
     * @return string.
     */
	 breakFileName = (fileName: string): string => {
    	return _.gt(fileName.length, 35) ? 
		`${fileName.substring(0, 10)}...${fileName.substring(fileName.length - 10, fileName.length) }` : fileName;
    }

	/**
     * Get max file size
     *
     * @param {IFormElement} formElement.
     * @return null or number.
     */
	getMaxFileSize = (formElement: IFormElement): any  =>{
		const fileHelper = new FileHelper();
		if (!_.isNull(formElement.max_range) && !formElement.load_data_to_grid) {
		  const MaxSize: any = formElement.max_range;
		  return fileHelper.formatBytes(MaxSize);
		}
		return null;
	};
	
	/**
     * Get required file type
     *
     * @param {IFormElement} formElement.
     * @return null or string.
     */
	getRequiredFileType = (formElement: IFormElement): any =>{
		let allowedFileType = (formElement.allowed_file_type || "").toUpperCase();
		if (!_.isEqual(allowedFileType.length, 0)) {
		  allowedFileType =  allowedFileType.split(",").join(", ");
		  allowedFileType = allowedFileType.replace(/\./g, "");
		  return allowedFileType;
		}
		return null;
	};
}
