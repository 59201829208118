import * as _ from "lodash";
import { IFormElement } from "../../Interface/FormElement/IFormElement";

// Validation helper for Regular Expression
export class RegexValidation{

    /**
     * Validate with regular expression
     *
     * @param {IFormElement} element.
     * @return {string} errorMessage.
     */
    validate = (formElement: IFormElement, elementValue: any) : string =>{

		if(_.isUndefined(formElement.validations.regex)){
			return "";
		}

		const RegexPattern: any = formElement.validations.regex.pattern;

    	const RegExpression = new RegExp(RegexPattern);

    	let errorMessage = "";
        
    	if(!RegExpression.test(elementValue)){
    		errorMessage = formElement.validations.regex.message; 
    	}
    	return errorMessage;
    }
}