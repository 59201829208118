import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import { Container, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

//Tool Header Component
export const Header: any = (props: any) => {
  const classes = useStyles();

  return (
    <div style={{ backgroundColor: "#FFFF" }}>
      <Container maxWidth="lg">
        <div className={classes.toolHeader}>
          <div className={classes.toolName}>{props.name}</div>
        </div>
      </Container>
    </div>
  );
};
