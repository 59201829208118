import React from "react";
import * as _ from "lodash";
import { ELEMENT_TYPES, GRID_CELL_ELEMENT } from "../../../configuration";
import { IGridHeader } from "../../../Interfaces/Grid/IGridHeader";
import { SwitchElement } from "../CellDesigns/Switch";
import { ShowMoreComponent } from "../../Elements/ShowMore";
import { TextTooltipElement } from "../CellDesigns/TextTooltip";
import { StatusIndicator } from "../../Elements/StatusIndicator";
import { TitleSubtitleTextElement } from "../CellDesigns/Text/TitleSubtitle";
import { SingleLinerTextElement } from "../CellDesigns/Text/SingleLine";
import { TwoLinerTextElement } from "../CellDesigns/Text/TwoLine";

function textElement(data: string, gridHeader: IGridHeader): React.JSX.Element {

  if (gridHeader.statusIndicator) {
    return <StatusIndicator status={data} />;
  }
  if (!_.isUndefined(gridHeader.enableTooltip)) {
    const DisplayKey: any = gridHeader.enableTooltip.displayKey;
    const TooltipKey: any = gridHeader.enableTooltip.tooltipKey;
    return (
      <TextTooltipElement text={data[DisplayKey]} tooltip={data[TooltipKey]} />
    );
  }

  if (
    _.isEqual(
      gridHeader.cellStyle,
      GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE
    ) &&
    !_.isUndefined(gridHeader.cellTwoLiner) && _.isEqual(gridHeader.cellTwoLinerTooltip, true)
  ) {
    const TitleKey: any = gridHeader.cellTwoLiner.titleKey;
    const SubtitleKey: any = gridHeader.cellTwoLiner.subtitleKey;
    const tooltip: any = gridHeader.cellTwoLiner.tooltip;

    return (
      <TitleSubtitleTextElement
        title={data[TitleKey]}
        subtitle={data[SubtitleKey]}
        tooltip={data[tooltip]}
      />
    );
  }

  if (
    _.isEqual(
      gridHeader.cellStyle,
      GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE
    ) &&
    !_.isUndefined(gridHeader.cellTwoLiner)
  ) {
    const TitleKey: any = gridHeader.cellTwoLiner.titleKey;
    const SubtitleKey: any = gridHeader.cellTwoLiner.subtitleKey;

    return (
      <TitleSubtitleTextElement
        title={data[TitleKey]}
        subtitle={data[SubtitleKey]}
      />
    );
  }

  if (_.isEqual(gridHeader.cellStyle, GRID_CELL_ELEMENT.TWO_LINER_TEXT)) {
    return <TwoLinerTextElement data={data} />;
  }

  return <SingleLinerTextElement data={data} />;
}

export const formatGridCell = (
  gridHeader: IGridHeader,
  data: any,
  status: string
): React.JSX.Element => {
  let element;

  switch (gridHeader.type) {
    case ELEMENT_TYPES.SELECT_INPUT: {
      if (gridHeader.isMulti) {
        const Data = _.isArray(data)
          ? data.map((value: string) => value).join(", ")
          : [].join(", ");
        element = <ShowMoreComponent data={Data} />;
        break;
      }
      element = _.isNull(data) ? <></> : <SingleLinerTextElement data={data} />;

      break;
    }
    case ELEMENT_TYPES.TEXT_INPUT: {
      element = textElement(data, gridHeader);
      break;
    }
    case ELEMENT_TYPES.SWITCH: {
      element = <SwitchElement data={data}  status={status}/>;
      break;
    }
    default: {
      element = <SingleLinerTextElement data={data} />;
    }
  }
  return element;
};
