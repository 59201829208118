import React from "react";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { UITextInput } from "../../Elements/TextInput";
import { UISelect } from "../../Elements/Dropdown";
import { UICreatableSelect } from "../../Elements/Dropdown/Creatable";
import { ELEMENT_TYPES } from "../../../configuration";
import { UIErrorOrNoteMessage } from "../../Elements/Child/ErrorOrNote";
import { UILabel } from "../../Elements/Child/Label";
import { UISwitch } from "../../Elements/Switch";

import style from "./style";
import { IForm } from "../../../Interfaces/Form/IForm";
import { UIDisplayText } from "../../Elements/DisplayText/displayText";
import { UIDisplayAccount } from "../../Elements/Account/account";
import { UIAsyncSelect } from "../../Elements/Dropdown/Async";

const getErrorMessage = (formElement: IFormElement): string => {
  let errorMessage = "";

  if (formElement.apiError) {
    errorMessage = formElement.apiError;
  } else {
    errorMessage = formElement.touched ? formElement.errorMessage : "";
  }
  if (formElement.disabled || formElement.readOnly) {
    errorMessage = "";
  } else {
    const HighlightOptionError = formElement.highlightRemovedOptionError;
    errorMessage = !_.isEqual(HighlightOptionError.length, 0)
      ? HighlightOptionError
      : errorMessage;
  }
  return errorMessage;
};
// Create styles
const useStyles = makeStyles(() => createStyles(style()));

type props = {
  formElement: IFormElement;
  onChange: any;
  onCreateOption: any;
  form: IForm;
};

export const FormElement = (props: props): React.JSX.Element => {
  const { formElement, onChange, form } = props;
  const classes = useStyles();

  const ErrorMessage = getErrorMessage(formElement);
  const Note = (formElement.configuration.note || "").toString().trim();

  if (
    formElement.readOnly &&
    !_.isEqual(formElement.type, ELEMENT_TYPES.DISPLAY_ACCOUNT)
  ) {
    return (
      <>
        <UILabel formElement={formElement} />
        <UIDisplayText formElement={formElement} />
      </>
    );
  }

  if (
    formElement.readOnly &&
    _.isEqual(formElement.type, ELEMENT_TYPES.DISPLAY_ACCOUNT)
  ) {
    return <UIDisplayAccount formElement={formElement} />;
  }

  const onElementChange = (value: any): void => {
    onChange({
      formElement: formElement,
      value,
    });
  };

  const onCreateOption = (option: any): void => {
    props.onCreateOption(formElement, option);
  };

  let element = <></>;
  switch (formElement.type) {
    case ELEMENT_TYPES.SELECT_INPUT: {
      if (formElement.configuration.isCreatableSelect) {
        element = (
          <UICreatableSelect
            value={formElement.value}
            onChange={onElementChange}
            formElement={formElement}
            errorMessage={ErrorMessage}
            onCreateOption={onCreateOption}
            form={form}
          />
        );
        break;
      } else if (formElement.configuration.isAsyncSelect) {
        element = (
          <UIAsyncSelect
            value={formElement.value}
            onChange={onElementChange}
            formElement={formElement}
            errorMessage={ErrorMessage}
            form={form}
            note={Note}
          />
        );
        break;
      }
      element = (
        <UISelect
          value={formElement.value}
          onChange={onElementChange}
          formElement={formElement}
          errorMessage={ErrorMessage}
          form={form}
        />
      );
      break;
    }
    case ELEMENT_TYPES.TEXT_INPUT: {
      element = (
        <>
          <UITextInput
            formElement={formElement}
            errorMessage={ErrorMessage}
            onChange={onElementChange}
          />
          <UIErrorOrNoteMessage note={Note} errorMessage={ErrorMessage} />
        </>

      );
      break;
    }
    case ELEMENT_TYPES.SWITCH: {
      element = (
        <>
          <div className={classes.switchContainer}>
            <UISwitch formElement={formElement} onChange={onElementChange} />
            <UILabel formElement={formElement} />
          </div>
          <UIErrorOrNoteMessage note={Note} errorMessage={ErrorMessage} />
        </>
      );
      return element;
    }
  }

  return (
    <>
      <UILabel formElement={formElement} />
      {element}
      {_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT) &&
        !formElement.configuration.isAsyncSelect && (
          <UIErrorOrNoteMessage note={Note} errorMessage={ErrorMessage} />
        )}
    </>
  );
};
