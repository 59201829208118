import * as _ from "lodash";
import { IElementUpdate } from "../../../interface/IElementUpdate";
import { IFormElement } from "../../../interface/IFormElement";
import { UtilHelper } from "../../Utils";
import { IOption } from "../../../interface/IOption";

// Edit Select Element
export class EditSelectElement{

    /**
     * Update select value
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return value.
     */
    public updateSelectValue = (formElement: IFormElement,element: IElementUpdate): any =>{

    	if (formElement.is_multi && formElement.allow_all) {
    		const IsAllOptionSelected = (element.value || []).findIndex(
    			(option: IOption) => {
    				return _.isEqual(option.value, UtilHelper.allOptionValue);
    			}
    		);
      
    		if (
    			_.isEqual(IsAllOptionSelected, element.value.length - 1) &&
              _.gt(element.value.length, 1)
    		) {
    			return [UtilHelper.allOptionLblVal];
    		} else if (
    			!_.isEqual(IsAllOptionSelected, -1) &&
              _.gt(element.value.length, 1)
    		) {
    			return (element.value || []).filter(
    				(option: IOption) => !_.isEqual(option.value, UtilHelper.allOptionValue)
    			);
    		}
    		return element.value;
    	}
    	return element.value;
    }

}