import * as _ from "lodash";
import { IOption } from "../../interface/IOption";
import { DateUtil } from "./Date";
import { UtilHelper } from "./index";

// Radio and checkbox Utility
export class RadioCheckboxUtil{
    public dateUtil: DateUtil;

    constructor(){
    	this.dateUtil = new DateUtil();
    }

    /**
     * Get checkbox list
     *
     * @param {IOption[]} dataSource.
     * @param {string} value.
     * @return string.
     */
    getCheckboxValues = (dataSource: IOption[], value: any): string =>{

    	const ElementValue = (value || "").toString().trim(); 
    	if(_.isEqual(ElementValue.length, 0)){
    		return value;
    	}

    	const Values = value.split(",").map((val: any)=> (val ||"").toString().trim());
        
    	if(!_.isEqual(Values.indexOf(UtilHelper.allOptionValue), -1)){
    		return dataSource.map((option: IOption) => option.value).join(",");
    	}
    	const UpdatedDS = dataSource.filter((option: any)=> !_.isEqual(option.value, UtilHelper.allOptionValue));

    	if(_.isEqual(UpdatedDS.length, Values.length)){
    		return dataSource.map((option: IOption) => option.value).join(",");
    	}

    	let updatedValues = "";

    	for(const ds of dataSource){
    		const DSValue = (ds.value || "").toString().trim();
    		if(!_.isEqual(Values.indexOf(DSValue), -1)){
    			updatedValues += `${DSValue},`;
    		}
    	}
        
    	if(_.isEqual(updatedValues.lastIndexOf(","), updatedValues.length - 1)){
    		updatedValues = updatedValues.replace(/,\s*$/, "");
    	}
    	return updatedValues;
    }

     /**
     * Get radio list
     *
     * @param {IOption[]} dataSource.
     * @param {string} value.
     * @return string.
     */
     getRadioValue = (dataSource: IOption[], value: string): string =>{
     	const ElementValue = (value || "").toString().trim(); 
     	if(_.isEqual(ElementValue.length, 0)){
     		return value;
     	}

     	for(const ds of dataSource){
     		const DSValue = (ds.value || "").toString().trim();
     		if(_.isEqual(ElementValue,DSValue)){
     			return DSValue;
     		}
     	}
     	return "";
     }

}