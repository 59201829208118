import { IFormGroup } from "../../Interface/IFormGroup";
import {
	Agency,
	SubAgency,
	Channels,
	OfficeLocation,
	HubLocation,
	WorkForActivation,
	WorkForHub
} from "./FormElements";


export const ProfileGroup: IFormGroup = {
	id: "profilegroup",
	elements: [
		Agency,
		SubAgency,
		OfficeLocation,
		WorkForActivation,
		Channels,
		WorkForHub,
		HubLocation,
	],
	isVisible: true,
	title: ""
};