import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import classNames from "classnames";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  onClick: any;
};

// CopyButton Component
export const IconClone = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onClick } = props;

  return (
      <IconButton
        size={"small"}
        className={classNames(classes.cloneIcon)}
        onClick={
          onClick
        }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M7 6V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-3v3c0 .552-.45 1-1.007 1H4.007A1.001 1.001 0 0 1 3 21l.003-14c0-.552.45-1 1.007-1H7zm2 0h8v10h2V4H9v2z" fill="rgba(106,115,124,1)"/>
        </svg>
      </IconButton>
  );
};

export default IconClone;
