
// Get Mapping Admin DataSource
export const GET_ALL_MAPPING_ADMIN_DS = "api/mapping/admin/datasource/";

// Get Mapping Channel Grid data
export const GET_MAPPING_CHANNEL_DATA = "api/mapping/admin/";

// Get Mapping Channel by UD
export const GET_MAPPING_CHANNEL_BY_ID = (id: number): string => `api/mapping/admin/${id}/`;

// Update Mapping Channel by ID
export const UPDATE_MAPPING_CHANNEL_BY_ID = (id: number): string => `api/mapping/admin/${id}/`;

// Create Mapping Channel
export const CREATE_MAPPING_CHANNEL = "api/mapping/admin/";

// Get Mapping Manager datasource
export const GET_MAPPING_MANAGER_DS = "api/mapping/manager/datasource/";

// Get Markets by region
export const GET_MARKETS_BY_REGION = "/api/mapping/manager/market/";





// Create Mapping Manager 
export const CREATE_MAPPING_MANAGER = "api/mapping/manager/";

// Get Mapping Channel by UD
export const GET_MAPPING_MANAGER_BY_ID = (id: number): string => `api/mapping/manager/${id}/`;

// Update Mapping Manager Record by ID
export const UPDATE_MAPPING_MANAGER_RECORD_BY_ID = (id: number): string => `api/mapping/manager/${id}/`;

// Create Mapping Mapper
export const CREATE_MAPPING_MANAGER_RECORD = "api/manager/admin/";

// Get GRID DATA for Mapping Mapper
export const GET_MAPPING_MANAGER_GRID_DATA = "/api/mapping/manager/filter/";

export const GET_MAPPING_CHANNEL_FOR_MANAGER ="api/mapping/manager/manager-channels/";

// Mapper - Insert google or facebook token
export const INSERT_GOOGLE_FB_TOKEN = "api/mapping/mapper/auth-app-login/";

// Mapper - Get Tile Data
export const GET_MAPPER_DATA = "api/mapping/mapper/";

// Mapper Grid - Initial Data
export const GET_MAPPER_GRID_INIT_DATA = (assignmentId: number): string => `api/mapping/v2/mapper-grid/init-data/?assignmentId=${assignmentId}`;

// Mapper Grid - Get Grid Data
export const GET_MAPPER_GRID_DATA = "api/mapping/v2/mapper-grid/data/";


// Mapping Review - Init Data
export const GET_MAPPING_REVIEW_INIT_DATA = "api/mapping/v2/review/init-data/";

export const GET_MAPPER_REVIEW_GRID_DATA = "api/mapping/v2/review/data/";

// Mapping Mapper update
export const UPDATE_MAPPER_GRID_DATA = "api/mapping/v2/mapper/update/";
// Mapping Review update
export const UPDATE_REVIEW_GRID_DATA = "api/mapping/v2/review/update/";

// Mapping Review - Get Subagency by agency
export const GET_SUBAGENCIES_BY_AGENCY = "/api/mapping/v2/subagency/";

// Mapping Review - Get vertical by brand
export const GET_VERTICAL_BY_BRAND = "/api/mapping/v2/vertical/";

// Mapping Review - Get Brand  by client
export const GET_BRANDS_BY_CLIENT = "/api/mapping/v2/brand/";

// Mapping Review - Get Platform Data
export const GET_PLATFORM_DATA = (id: number): string => `api/mapping/v2/review/platform/?channel_id=${id}`;
