const BUTTON = "button";
const SWITCH = "switch";
const TOGGLE_BUTTON = "toggleButton";
const CHECKBOX = "checkbox";

export default {
	BUTTON,
	SWITCH,
	TOGGLE_BUTTON,
	CHECKBOX,
};
