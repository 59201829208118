import * as _ from "lodash";
import { Axios } from "../../../../../../../api/service/Axios";
import { IFormElement } from "../../../../interface/IFormElement";
import { UtilHelper } from "../../../Utils";

// Image Text Element Helper
export class ImageTextHelper {
  private utilHelper: UtilHelper;
  public static DEFAULT_VALUE = {
	file: null,
	text: "",
	touched:{
		text: false,
		file: false
	}
}

  constructor() {
    this.utilHelper = new UtilHelper();
  }

  /**
     * Validate image text element
     *
     * @param {forms} IForm[].
     * @param {formElement} IFormElement.
     * @return {element} IFormElement.
     */
   generateImageTextElement = async (formElement: IFormElement): Promise<IFormElement> =>{

	let element = {...formElement};
	// API call
	if(!this.utilHelper.isEmptyValue(formElement.api_url)){
		element = await this.buildImageTextElement(formElement);

	}else{
		const ElementVal = !this.utilHelper.validateObjectProperties(formElement.value, [
			"file",
			"text",
		])
			? {
				file: null,
				text: "",
				touched:{
					text: false,
					file: false
				}
			}
			: {
				...formElement.value,
				touched:{
					text: false,
					file: false
				}
			};
			element = {
			...element,
			value: ElementVal,
		};
	}
	return element;
}

 /**
     * Build image text element
     *
     * @param {formElement} IFormElement.
     * @return {Element} IFormElement.
     */
  buildImageTextElement = async (formElement: IFormElement)
  :Promise<IFormElement> =>{

	  const Element = {...formElement};
	  const isDisabled = !_.isEqual(Element.parent_elements.length, 0);

	  if(_.isEqual(Element.parent_elements.length, 0)){
		  try{
			  let Data:any;
			  if(_.isEqual(formElement.form_element_methodid, 1)){
				  Data = await Axios.get(formElement.api_url);
			  }
			  else if(_.isEqual(formElement.form_element_methodid, 2)){
				  Data = await Axios.post(formElement.api_url, {});
			  }

			  Data = Data.data;

			  Element.value = this.utilHelper.validateObjectProperties(Data, [
				"imageUrl",
				"text",
				]) ? {
					file: {
						publicURL: Data.imageUrl,
						isValidForUpload: true
					},
					text: Data.text,
					touched:{
						text: false,
						file: false
					}
				} : ImageTextHelper.DEFAULT_VALUE;
		
			  Element.apiError = "";
		  }
		  catch(error:any){
			  Element.apiError = this.utilHelper.getErrorMessage(error);
		  }
	  }

	  if(!_.isEqual(Element.parent_elements.length, 0)){
		  return {...Element};
	  }

	  return {
		  ...Element,
		  disabled: isDisabled,
		  touched: !_.isNull(Element.value),
		  value: Element.value,
	  };
  }

  /**
   * Get value from API data
   *
   * @param {data} IFormElement.
   * @return {formElement} IFormElement.
   */
  getAPIDataValue = (data: any): any =>{
	return this.utilHelper.validateObjectProperties(data, [
		"imageUrl",
		"text",
	]) ? {
		file: {
			publicURL: data.imageUrl,
			isValidForUpload: true
		},
		text: data.text,
		touched:{
			text: false,
			file: false
		}
	} : ImageTextHelper.DEFAULT_VALUE;
  }
  /**
   * Get image text element value
   *
   * @param {formElement} IFormElement.
   * @return {formElement} IFormElement.
   */
   getElementValue = (formElement: IFormElement): IFormElement => {
    const Element = { ...formElement };
    if (_.isNull(formElement.value)) {
      Element.value = null;
      return Element;
    }

    Element.value = this.getAPIDataValue(formElement.dataSource);

    return Element;
  };
}
