import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	cardContainer: {
		display: "grid",
		width: 558,
		zIndex: 1,
		height: 244,
		padding: 32,
		cursor: "pointer",
		position: "relative",
		transition: "boxShadow 1s",
		boxShadow: "0px 1px 3px rgb(0 0 0 / 7%)",
		"&:hover": {
			boxShadow: "0px 4px 6px -1px rgb(0 0 0  / 10%), 0px 2px 4px -1px rgb(0 0 0  / 6%)"
		}
	},
	disabledTool:{
		cursor: "default",
		opacity: "0.6"
	},
	gradient: {
		backgroundImage: "linear-gradient(90deg, #F3F5F7 0px, #F7F8FA 40px, #F3F5F7 80px)",
		backgroundSize: 600,
		animation: "shine-lines 1.6s infinite linear",
		backgroundColor: COLORS.BASECOLOR
	},
	toolLogo: {
		height: 40,
		width: "auto",
	},
	toolTitle: {
		color: COLORS.DARK_GRAY,
		lineHeight: 1.3,

	},
	name: {
		fontWeight: theme.typography.fontWeightBold,
		fontSize: 18,
		wordBreak: "break-word",
	},
	toolVersion: {
		fontSize: 14,
		fontWeight: theme.typography.fontWeightMedium,
		marginLeft: 10
	},
	toolInfo: {
		color: COLORS.GRAY,
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: 10,
		alignSelf: "end",
		display: "-webkit-box",
		textTransform: "uppercase" as const,
		overflow: "hidden",
		textOverflow: "ellipsis",
		"-webkit-line-clamp": 1,
		"-webkit-box-orient": "vertical",
	},
	toolDescription: {
		display: "-webkit-box",
		color: COLORS.GRAY,
		fontWeight: "normal" as const,
		fontSize: 12,
		height: 54,
		lineHeight: "18px",
		wordBreak: "break-word",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"-webkit-line-clamp": 3,
		"-webkit-box-orient": "vertical",
	},
	logContainer: {
		height: 50
	},
	cont: {
		display: "flex",
		justifyContent: "space-between"

	},
	starIcon: {
		width: "20px",
		height: "20px",
		display: "-webkit-box",
		position: "absolute",
		right: "32px",
		bottom: "32px",
		textOverflow: "ellipsis",
		"-webkit-line-clamp": 1,
		"-webkit-box-orient": "vertical",
		color: COLORS.GRAY,
	},
	highLightText: {
		backgroundColor: COLORS.HIGHLIGHT_TEXT_COLOR,
		mixBlendMode: "multiply",
	}

});

export default style;