import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GridTable } from "../Layout/Grid";
import { FORM_TITLE, NOTIFICATION_TYPE, SORT_ORDER } from "../configuration";
import { MappingPage } from "../Layout/Page";
import {
  GRID_GOOGLEADS_HEADERS,
  getFilterFormConfig,
} from "./Configuration/GoogleAds";
import {
  getGridHeader,
  getEditFormConfiguration,
  getSearchPlaceHolder,
} from "./getConfiguration";
import { SidebarComponent } from "../Layout/Grid/Sidebar";
import style from "./style";
import { CircularProgress } from "../../../UI/Progress";
import { IGridFilter } from "../Interfaces/Filter/IGridFilter";
import { SnackbarAlert } from "../../../UI/Snackbar";
import { FilterFormHelper } from "../Layout/Form/Helpers/Forms/Filter";
import { IForm } from "../Interfaces/Form/IForm";
import { ISidebar } from "../Interfaces/Filter/IGridSidebar";
import { catchError, catchSuccessMessage } from "../Utility/ErrorMessage";
import * as ReviewHelper from "./Helper";
import { IGridData } from "../Interfaces/Grid/IGridData";
import { Axios } from "../../../api/service/Axios";
import {
  GET_MAPPER_REVIEW_GRID_DATA,
  GET_MAPPING_REVIEW_INIT_DATA,
  UPDATE_REVIEW_GRID_DATA,
  GET_PLATFORM_DATA,
} from "../../../api/APIPaths/mapping";
import { EditFormHelper } from "../Layout/Form/Helpers/Forms";
import { IGridHeader } from "../Interfaces/Grid/IGridHeader";
import { SearchFilter } from "../Layout/Header/SearchFilter";
import { TabListComponent } from "../Layout/Tab";
import { ITab } from "../Interfaces/Page/ITab";
import { AxiosResponse } from "axios";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

const DEFAULT_PLATFORM_VALUE = 0;

const DefaultFilter = {
  order: SORT_ORDER.ASC,
  orderBy: "manager",
  pageNumber: 0,
  pageSize: 20,
  filterCount: 0,
  header: GRID_GOOGLEADS_HEADERS[1],
};

//Mapping Review Component
const MapperReview = (): React.JSX.Element => {
  const classes = useStyles();
  const [gridData, setGridData] = useState<IGridData>({
    count: 0,
    data: [],
  });
  const [initError, setInitError] = useState<boolean>(false);
  const [filter, setFilter] = useState<IGridFilter>({
    sidebarFilter: null,
    ...DefaultFilter,
  });
  const [sidebar, setSidebar] = useState<ISidebar | null>(null);
  const bundle = useSelector((state: any) => state.initial.bundle);
  const [tab, setTab] = useState<ITab | null>(null);
  const [initLoader, setInitLoader] = useState<boolean>(true);
  const [formFilter, setFormFilter] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [gridLoader, setGridLoader] = useState<boolean>(false);

  const [snackbarreview, setSnackbarreview] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  const getFilterPayload = (
    gridFilter: IGridFilter,
    search: string,
    SelectedPlatformValue: number
  ): any => {
    return ReviewHelper.getFilterSubmitData(
      gridFilter,
      search || "",
      SelectedPlatformValue
    );
  };

  const getGridData = async (
    gridFilter: IGridFilter,
    tabData: any
  ): Promise<any> => {
    setGridLoader(true);
    try {
      const FilterData = getFilterPayload(
        gridFilter,
        gridFilter.search || "",
        gridFilter.selectedPlatformValue
      );

      const GRID_DATA = await Axios.post(
        GET_MAPPER_REVIEW_GRID_DATA,
        FilterData
      );

      let updatedGridData = ReviewHelper.formatGridData(
        GRID_DATA.data,
        tabData,
        gridFilter.selectedPlatformValue
      );

      const SelectedHeader: any = gridFilter?.header;

      updatedGridData = sortData_review(
        gridFilter.orderBy,
        gridFilter.order,
        SelectedHeader,
        updatedGridData
      );

      setGridData({
        data: updatedGridData,
        count: updatedGridData.length,
      });
      setGridLoader(false);
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setGridData({
        data: [],
        count: 0,
      });
      setGridLoader(false);
    }
  };

  const setAlert = (message: string, variant: NOTIFICATION_TYPE): void => {
    setSnackbarreview({
      ...snackbarreview,
      message,
      variant,
      open: true,
    });
  };

  const getDefaultFilterForm_review = async (dataSources: any): Promise<any> => {
    const filterFormHelper_review = new FilterFormHelper();
    return filterFormHelper_review.buildFilterForm(
      getFilterFormConfig({}),
      dataSources
    );
  };

  const handleCloseAlert = (): void => {
    return setSnackbarreview({
      ...snackbarreview,
      open: false,
    });
  };

 

  const setFilterDatasource_review = async (dataSources: any): Promise<any> => {
    const filterFormHelper_review = new FilterFormHelper();
    return filterFormHelper_review.buildFilterForm(
      formFilter,
      dataSources
    );
  };

  const getFilterData = async (
    dataSources: any,
    selectedPlatformValue: any
  ): Promise<any> => {
    const form = await getDefaultFilterForm_review(dataSources);
    const SidebarForm = {
      ...filter.sidebarFilter,
      dataSources,
      form,
      title: FORM_TITLE.NEW,
      open: false,
    };

    setFilter((prevState: any) => ({
      ...prevState,
      sidebarFilter: SidebarForm,
      selectedPlatformValue,
    }));

    return {
      ...filter,
      sidebarFilter: SidebarForm,
      selectedPlatformValue,
    };
  };

  const getFilterConfig = (data: any, selectedPlatformValue: any): any => {
    return getFilterData(data || {}, selectedPlatformValue);
  };

  const setPlatform = async (
    InitData: any,
    TabData: any,
    SelectedTabValue: any,
    platform: any
  ): Promise<void> => {
    const SelectedPlatformValue = _.isEmpty(platform.data)
      ? DEFAULT_PLATFORM_VALUE
      : platform.data[0].value;

    const Filter = await getFilterConfig(InitData.data, SelectedPlatformValue);

    const Tab: ITab = {
      dataSource: InitData.data.assignedChannels || [],
      selectedId: SelectedTabValue,
      platform: {
        data: platform.data,
        value: SelectedPlatformValue,
      },
    };

    setTab(Tab);

    setSidebar((prevState: any) => ({
      ...prevState,
      dataSources: InitData.data || {},
    }));

    if (!_.isEqual(SelectedPlatformValue, 0)) {
      await getGridData(
        {
          ...Filter,
          selectedPlatformValue: SelectedPlatformValue,
        },
        Tab
      );
    } else {
      setGridData({
        data: [],
        count: 0,
      });
    }
  };

  useEffect(() => {
    const getInitialData = async (): Promise<void> => {
      setInitLoader(true);
      try {
        const InitData = await Axios.get(GET_MAPPING_REVIEW_INIT_DATA);
        const TabData = InitData.data.assignedChannels || [];
        const SelectedTabValue = _.isEmpty(TabData) ? 0 : TabData[0].value;
        const platform: AxiosResponse = await Axios.get(
          GET_PLATFORM_DATA(SelectedTabValue)
        );

        setPlatform(InitData, TabData, SelectedTabValue, platform);
        setInitLoader(false);
      } catch (err: any) {
        const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
        setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        setInitError(true);
        setInitLoader(false);
      }
    };
    getInitialData();
  }, []);

  const sortToLowerCase_review = (record: any, orderby: any, Sortbykey: any): any => {
    const sortValue_review = record[orderby][Sortbykey] || "";
    return sortValue_review.toLowerCase();
  };

  const sortData_review = (
    orderBy_review: any,
    order_review: SORT_ORDER,
    header_review: IGridHeader,
    data_review: any
  ): any => {
    let updatedGridData: any[] = [];

    if (!_.isUndefined(header_review.enableTooltip)) {
      const SortByKey: any = header_review.enableTooltip.displayKey;
      updatedGridData = _.orderBy(
        data_review,
        (record) => {
          return sortToLowerCase_review(record, orderBy_review, SortByKey);
        },
        [order_review]
      );
    } else if (!_.isUndefined(header_review.cellTwoLiner)) {
      const SortByKey: any = header_review.cellTwoLiner.titleKey;
      updatedGridData = _.orderBy(
        data_review,
        (record) => {
          return sortToLowerCase_review(record, orderBy_review, SortByKey);
        },
        [order_review]
      );
    } else {
      updatedGridData = _.orderBy(
        data_review,
        [
          (record) => {
            const sortValue = record[orderBy_review] || "";
            return sortValue.toLowerCase();
          },
        ],
        [order_review]
      );
    }

    return updatedGridData;
  };

  const sortHandler_review = (
    orderBy_review: any,
    order_review: SORT_ORDER,
    header_review: IGridHeader
  ): void => {
    const UpdatedGridData: any[] = sortData_review(
      orderBy_review,
      order_review,
      header_review,
      gridData.data
    );

    setFilter((prevState: IGridFilter) => ({
      ...prevState,
      order_review,
      orderBy_review,
      header_review,
    }));
    setGridData((prevState: any) => ({
      ...prevState,
      data: UpdatedGridData,
    }));
  };

  const onSearchChange = async (value: string): Promise<void> => {
    try {
      setLoading(true);

      const selectedPlatformValue: number =
        tab?.platform.value || DEFAULT_PLATFORM_VALUE;

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        search: value,
        pageNumber: 0,
        selectedPlatformValue,
      }));

      const Filter: any = {
        ...filter,
        search: value,
        pageNumber: 0,
        selectedPlatformValue,
      };

      if (!_.isEqual(selectedPlatformValue, 0)) {
        await getGridData(Filter, tab);
      }
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const setFilterCall=(selectedPlatformValue:any):void=>{
    setFilter((prevState: any) => ({
      ...prevState,
      pageNumber: 0,
      filterCount: 0,
      selectedPlatformValue,
    }));
  };
  const onCancel = async (): Promise<void> => {

    if (_.gt(filter.filterCount, 0)) {
      const form = await setFilterDatasource_review(
        sidebar?.dataSources,
      );

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        sidebarFilter: {
          ...prevState.sidebarFilter,
          dataSources: sidebar?.dataSources,
          form: form,
          open: false,
        },
      }));
    }
    else {
      const selectedPlatformValue: number =
        tab?.platform.value || DEFAULT_PLATFORM_VALUE;

      await getFilterConfig(
        sidebar?.dataSources,
        selectedPlatformValue
      );

      setFilterCall(selectedPlatformValue);
    }
  };

  const onReset = async (): Promise<void> => {
    try {
      setLoading(true);

      const selectedPlatformValue: number =
        tab?.platform.value || DEFAULT_PLATFORM_VALUE;

      const Filter = await getFilterConfig(
        sidebar?.dataSources,
        selectedPlatformValue
      );

      setFilterCall(selectedPlatformValue);
      
      if (!_.isEqual(selectedPlatformValue, 0)) {
        await getGridData(Filter, tab);
      }
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onApply = async (form: IForm): Promise<void> => {

    try {
      const selectedPlatformValue: number =
        tab?.platform.value || DEFAULT_PLATFORM_VALUE;
      setLoading(true);
      const filterHelper = new FilterFormHelper();
      const Filter: any = {
        ...filter,
        sidebarFilter: {
          ...filter.sidebarFilter,
          form,
        },
        pageNumber: 0,
        selectedPlatformValue,
      };

      const FilterCount = filterHelper.getFilterCount(Filter);

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        filterCount: FilterCount,
        sidebarFilter: {
          ...prevState.sidebarFilter,
          open: false,
          form,
        },
        pageNumber: 0,
        selectedPlatformValue,
      }));
      setFormFilter(form);

      if (!_.isEqual(selectedPlatformValue, 0)) {
        await getGridData(Filter, tab);
      }
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onSave = async (form: IForm): Promise<void> => {
    const selectedPlatformValue: number =
      tab?.platform.value || DEFAULT_PLATFORM_VALUE;

    if (_.isNull(sidebar)) {
      return;
    }
    const FormData = ReviewHelper.buildSubmitFormData(
      form,
      sidebar?.formData,
      selectedPlatformValue,
      tab
    );

    setLoading(true);

    try {
      const Response = await Axios.post(UPDATE_REVIEW_GRID_DATA, FormData);

      setSidebar((prevState: any): any => ({
        ...prevState,
        open: false,
      }));

      const Filter: any = {
        ...filter,
        selectedPlatformValue,
      };

      await getGridData(Filter, tab);
      const SuccessMessage_review = catchSuccessMessage(
        Response,
        bundle.MAPPER_GRID_INSERT_UPDATE_SUCCESS_MSG
      );
      setAlert(
        SuccessMessage_review,
        Response.data.warning  ? NOTIFICATION_TYPE.WARNING  : NOTIFICATION_TYPE.SUCCESS
      );
      setLoading(false);
    } catch (err) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onPageChange = async (offset: any, pageNumber: any): Promise<void> => {
    setFilter((prevState: any) => ({
      ...prevState,
      pageNumber,
    }));
  };

  const onEdit = async (id: number, rowData: any): Promise<void> => {
    setLoading(true);
    const selectedPlatformValue: number =
      tab?.platform.value || DEFAULT_PLATFORM_VALUE;
    const editFormHelper = new EditFormHelper();
    const FormData: any = ReviewHelper.buildFormData(
      rowData,
      tab,
      selectedPlatformValue
    );
    const getForm: any = getEditFormConfiguration(tab);
    try {
      const form = await editFormHelper.buildEditForm(
        getForm,
        sidebar!.dataSources,
        FormData
      );

      setSidebar((prevState: ISidebar | null): any => {
        return {
          ...prevState,
          title: FORM_TITLE.EDIT,
          id,
          open: true,
          form,
          formData: FormData,
        };
      });
      setLoading(false);
    }
    catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onTabChange = async (selectedTabValue: any): Promise<void> => {
    try {
      setGridLoader(true);

      setFilter((prevState: any) => ({
        ...prevState,
        pageNumber: 0,
        selectedTabValue,
      }));

      setTab((prevState: any) => ({
        ...prevState,
        selectedId: selectedTabValue,
        platform: {
          ...prevState.platform,
          data: [],
          value: DEFAULT_PLATFORM_VALUE,
        },
      }));

      const platform = await Axios.get(GET_PLATFORM_DATA(selectedTabValue));

      if (!_.isEmpty(platform.data)) {
        const selectedPlatformValue = platform.data[0].value;

        const tabVal = {
          platform: {
            data: platform.data,
            value: selectedPlatformValue
          }
        };

        const header = getGridHeader(tabVal);
        const Filter: IGridFilter = {
          ...filter,
          header: header[1],
          pageNumber: 0,
          selectedPlatformValue,
        };

        const Tab: any = {
          ...tab,
          selectedId: selectedTabValue,
          platform: {
            ...tab?.platform,
            data: platform.data,
            value: selectedPlatformValue,
          },
        };
        setTab((prevState: any) => ({
          ...prevState,
          ...Tab,
        }));

        await getGridData(Filter, Tab);
      } else {
        setGridData({
          data: [],
          count: 0,
        });
      }
      setGridLoader(false);
    } catch (err: any) {
      const errorMessage = catchError(
        err,
        bundle.MAPPING_ADMIN_CREATE_ERROR_MSG
      );
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setGridData({
        data: [],
        count: 0,
      });
      setGridLoader(false);
    }
  };

  if (initLoader) {
    return <CircularProgress />;
  }

  const toggleHandleChange = async (
    event: any,
    newValue: any
  ): Promise<void> => {
    try {
      setGridLoader(true);

      if (!_.isNull(newValue)) {
        setTab((prevState: any) => ({
          ...prevState,
          platform: {
            ...prevState.platform,
            value: newValue,
          },
        }));

        const tabValue = tab?.selectedId;

        const selectedPlatformValue = newValue;
        const tabVal = {
          platform: {
            data: tab?.platform.data,
            value: newValue
          }
        };
        const header = getGridHeader(tabVal);
        const Filter: IGridFilter = {
          ...filter,
          header: header[1],
          pageNumber: 0,
          selectedPlatformValue,
        };

        setFilter((prevState: any) => ({
          ...prevState,
          pageNumber: 0,
          tabValue,
        }));
        setTab((prevState: any) => ({
          ...prevState,
          selectedId: tabValue,
          platform: {
            ...prevState.platform,
            value: selectedPlatformValue,
          },
        }));

        await getGridData(Filter, tab);
      }
      setGridLoader(false);
    } catch (err: any) {
      const errorMessage = catchError(
        err,
        bundle.MAPPING_ADMIN_CREATE_ERROR_MSG
      );
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setGridLoader(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{bundle.MAPPING_REVIEW_PAGE_TITLE}</title>
      </Helmet>

      <MappingPage
        pageTitle={bundle.MAPPING_REVIEW_PAGE_TITLE}
        recordCount={gridData.count}
        loading={gridLoader}
        tabComponent={
          _.isNull(tab) ? (
            <span />
          ) : (
            <TabListComponent
              handleTabChange={(selectedTabValue: any) => {
                if (!_.isEqual(tab.selectedId, selectedTabValue)) {
                  onTabChange(selectedTabValue);
                }
              }}
              loading={gridLoader}
              pageTitle="Mapping Manager"
              tabData={tab.dataSource}
              value={tab.selectedId}
            />
          )
        }
        enableContentBgColor={!_.isNull(tab)}
        hideContent={_.isNull(tab)}
        header={
          !initError && (
            <div className={classes.headerContainer}>
              <SearchFilter
                platformData={tab?.platform.data}
                toggleHandleChange={toggleHandleChange}
                toggleValue={tab?.platform.value}
                tabSelectedId={tab?.selectedId}
                leftContainerCols={6}
                isMapper={false}
                filterCount={
                  _.isUndefined(filter.filterCount) ? 0 : filter.filterCount
                }
                handleSearchChange={(value: string): void => {
                  onSearchChange(value);
                }}
                loading={gridLoader}
                onOpenFilter={() => {
                  setFilter((prevState: IGridFilter): any => ({
                    ...prevState,
                    sidebarFilter: {
                      ...prevState.sidebarFilter,
                      open: true,
                    },
                  }));
                }}
                searchPlaceholder={getSearchPlaceHolder(tab)}
                searchValue={filter.search || ""}
                recordsCount={gridData.count}
                hideFilter={false}
              />
            </div>
          )
        }
        footer={
          _.isEqual(gridData.data, 0) || gridLoader || _.isNull(tab) ? (
            <span />
          ) : (
            <div className={classes.footerContainer}>
              <TablePagination
                component="div"
                count={gridData.count}
                labelRowsPerPage=""
                rowsPerPage={filter.pageSize}
                page={filter.pageNumber}
                onPageChange={onPageChange}
              />
            </div>
          )
        }
      >
        {!_.isNull(tab) && (
          <GridTable
          gridData={gridData.data.slice(
            filter.pageNumber * filter.pageSize,
            filter.pageNumber * filter.pageSize + filter.pageSize
          )}
            filter={filter}
            headers={getGridHeader(tab)}
            onEdit={onEdit}
            
            loading={gridLoader}
            sortHandler={sortHandler_review}
            loadHeader={false}
            isGridAction={true}
          />
        )}

        {!_.isNull(filter.sidebarFilter) && (
          <SidebarComponent
          formTitle={"Filters"}
          width={424}
            btnSaveText="Apply"
            onSave={onApply}
            btnCancelText="Cancel"
            onReset={onReset}
            formData={filter.sidebarFilter!.form}
            onCancel={onCancel}
            isFilterForm={true}
            open={filter.sidebarFilter!.open}
          />
        )}
        {!_.isNull(sidebar) && (
          <SidebarComponent
          formTitle={sidebar.title}
          width={624}
            btnSaveText="Save"
            onSave={onSave}
            btnCancelText="Cancel"
           
            formData={sidebar.form}
            isFilterForm={false}
            open={sidebar.open}
            onCancel={() => {
              setSidebar((prevState: ISidebar | null): any => ({
                ...prevState,
                open: false,
              }));
            }}
          />
        )}
      </MappingPage>
      {loading && <CircularProgress />}
      <SnackbarAlert
        variant={snackbarreview.variant}
        message={snackbarreview.message}
        onOpenCloseAlert={handleCloseAlert}
        open={snackbarreview.open}
      />
    </>
  );
};

export default MapperReview;
