import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import classnames from "classnames";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import style from "./style";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
  errorMessage: string;
};

function isInfoMessage(formElement: IFormElement): boolean {
  if (_.isNull(formElement.value)) {
    return false;
  }
  if (!_.isObject(formElement.value)) {
    return false;
  }
  const ElementValue: any = formElement.value;
  if (_.isUndefined(ElementValue.info)) {
    return false;
  }
  return true;
}

// UIErrorOrNoteMessage Component
export const UIErrorOrNoteMessage = (props: props): JSX.Element => {
  const { errorMessage, formElement } = props;
  const classes = useStyles();
  const Error = (errorMessage || "").toString().trim();
  const Note = (formElement.note || "").toString().trim();

  const Notes = (): any => {
    return (
      <div
        className={classnames(classes.note, {
          [classes.noteErrorMargin]: !_.isEmpty(Note),
        })}
        dangerouslySetInnerHTML={{
          __html: Note,
        }}
      ></div>
    );
  };

  if (_.isEqual(Error.length, 0) && !isInfoMessage(formElement)) {
    return <Notes />;
  }
  if (_.isEqual(Error.length, 0) && isInfoMessage(formElement)) {
    return (
      <div className={classnames(classes.infoContainer)}>
        <div className={classes.info}>
          <div className={classes.infoIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="14"
              height="14"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                fill="#3a91e8"
                d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"
              />
            </svg>
          </div>
          <div className={classes.message}>
            {formElement.value.message || "Info"}
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        className={classnames(classes.error, {
          [classes.noteErrorMargin]: !_.isEmpty(Error),
        })}
      >
        {Error}
      </div>
      <Notes />
    </>
  );
};
