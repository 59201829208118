import { IToolList } from "../store/reducers/interfaces/IToolList";


// Get Initial data when site loads
export const GET_INITIAL_DATA = "api/init/";

// Get Initial data when site loads
export const GET_DATASOURCE_INITIAL_DATA = "api/datasource/";

// Get Filter Options Path
export const GET_FILTER_OPTIONS_URL = "api/tools/filters/";

// Get All Tools with selected filter criteria
export const GET_TOOL_LIST = (
  ToolListParameters: IToolList
): string =>
  `api/tools/?channel_id=${ToolListParameters.channel_id}&tooltype_id=${ToolListParameters.tooltype_id}&lifecycle_id=${ToolListParameters.lifecycle_id}&toollabel_id=${ToolListParameters.toollabel_id}&include_beta=${ToolListParameters.include_beta}&platform_id=${ToolListParameters.platform_id}&search=${encodeURIComponent(ToolListParameters.search)}&page_number=${ToolListParameters.page_number}&record_limit=${ToolListParameters.record_limit}&order_by=${ToolListParameters.order_by}&include_favorite=${ToolListParameters.include_favorite}`;

// Get Tool Page Details
export const GET_TOOL = (id: number, tool_name: string, tool_version: string): string => {
  const TOOL_NAME = (tool_name || "").toString();
  const TOOL_VERSION = (tool_version || "").toString();
  const TOOL_ID = (id || "").toString();

  return `api/tool/?id=${encodeURIComponent(TOOL_ID)}&name=${encodeURIComponent(TOOL_NAME)}&version=${encodeURIComponent(TOOL_VERSION)}`;
};

//User Profile API calls
//get all data
export const USER_PROFILE_GET_DATA =
  "/api/userdata/";

//save data 
export const USER_PROFILE_SAVE_DATA = (user_id: number): string =>
  `api/userdata/update/${user_id}/`;

// admin portal API calls
// ALL APIS Togather tables and tools
export const ADMIN_GET_ALL_TOOLS =
  "api/admin/tools/";

// individual tools
export const ADMIN_GET_TOOL_PAGE = (tool_id: number): string =>
  `api/admin/tools/${tool_id}/`;

// Schedule clear tool
export const ADMIN_SCHEDULE_TOOL_UPDATE = (tool_id: number): string =>
  `${process.env.REACT_APP_SHARED_SERVICE_URL}shared/v1/job/update_admin_executions/?toolid=${tool_id}`;

// Upload image to bucket
export const GOOGLE_BUCKET_PUBLIC_FILE_URL = (fileName: string, bucketName: string): string =>
  `api/bucket/public-file/?file_name=${fileName}&bucket_name=${bucketName}`;

//Get Job API
export const GET_JOB_API = (tool_id: number, user_id: number): string =>
  `${process.env.REACT_APP_SHARED_SERVICE_URL}shared/v1/job/get/?tool_id=${tool_id}&user_id=${user_id}`;

// Update Schedule Info
export const UPDATE_SCHEDULE_INFO = (jobId: number, isScheduleRequest: boolean): string =>
  `${process.env.REACT_APP_SHARED_SERVICE_URL}shared/v1/job/update_job_execution/?isScheduleRequest=${isScheduleRequest}&jobid=${jobId}`;

//Add Tool to Favorite
export const ADD_FAVORITE_URL = "api/favorite-tools/";

//Remove Tool from Favorite
export const REMOVE_FAVORITE_URL = (tool_id: number): string =>
  `api/favorite-tools/delete/${tool_id}/`;

// Insert and update tool execution history
export const GET_TOOL_EXECUTION_HISTORY = "api/history/";
export const INSERT_TOOL_EXECUTION_URL = "api/history/insert/";
export const UPDATE_TOOL_EXECUTION_URL = "api/history/update/";
export const INTERNAL_TOOL_GRID_UPDATE_URL = (apiEndpoint:string,job_id: number): string => `${apiEndpoint}?jobid=${job_id}`;

// File download API
export const FILE_DOWNLOAD_API_URL = "api/download-file/";

// tool signout API
export const TOOL_SIGNOUT = (id: number): string => {
  const TOOL_ID = (id || "").toString();

  return `api/tool/signout/?tool_id=${encodeURIComponent(TOOL_ID)}`;
};

// tool signout button user Info
export const SIGNOUT_BUTTON_USER_INFO = "api/tool/signout-button-user-info";
export const SIGNOUT_BUTTON_USER_INFO_MAPPER = "api/tool/signout-button-user-info-mapper";

// Log API
export const LOG_INFO_URL = "api/log/";

// Google Access Token
export const GOOGLE_ACCESS_TOKEN_URL = "api/google/access-token/";

// Generate Signed URL
export const GENERATE_GOOGLE_SIGNED_URL = "api/google/signed-url/";

// Google Refresh Token URL
export const GET_GOOGLE_REFRESH_TOKEN_URL = "api/google/refresh-token/";


// Tiktok Access Token URL
export const GET_TIKTOK_ACCESS_TOKEN_URL = "api/tiktok/access-token/";

// Submit to external tool API
export const EXTERNAL_TOOL_SUBMIT_URL = "api/tools/submit/";

export const INTERNAL_TOOL_EXECUTE_URL = `${process.env.REACT_APP_SHARED_SERVICE_URL}shared/v1/job/insert/`;

export const SIGNOUT_MAPPER = (appid: number): string => {
  const PLATFORM_ID = (appid || "").toString();
  return `api/tool/signout/?platform_id=${encodeURIComponent(PLATFORM_ID)}`;
};