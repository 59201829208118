
const style: any = (): any => ({
	
	textContainerEllipse: {
		textOverflow: "ellipsis",
		overflow: "hidden",
		WebkitBoxOrient: "vertical",
		display: "-webkit-box",
		WebkitLineClamp: 2,
		wordBreak: "break-all"
	},
	middleTextEllipsis: {
		width: "100%",
		display: "block",
		whiteSpace: "nowrap",
		minHeight: 21,
	},
	ellipsisContainer: {
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		visibility: "hidden",
		maxHeight: 0,
		height: 0,
		overflow: "hidden",
		display: "block",
	},
	textContainer: {
		wordBreak: "break-all",
		maxHeight: 36,
	},
	
});

export default style;