import COLORS from "../../../../../Theme/colors";

const style:any = (): any => ({
	container:{
		position: "fixed",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 9999,
	},
	overlay:{
		position: "fixed",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: "rgb(243, 245, 247)",
		opacity:"0.5",
		zIndex:1,
	},
	dialog:{
		minHeight:619,
		width:792,
		opacity:1,
		backgroundColor:COLORS.WHITE,
		zIndex:2,
		boxShadow:"0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
		position: "absolute",
		top: "50%",
		right: "50%",
		transform: "translate(50%, -50%)"
	},
	dialogContainer:{
		margin:"32px 40px 12px"
	},
	deleteButton:{
		backgroundColor: `${COLORS.RED} !important`,
	},
	dialogBody:{
		fontSize:"0.875rem",
		minHeight:50,
		marginTop:20
	},
	dialogHeader:{
		fontSize:"1.25rem",
		fontWeight:"bold"
	},
	dialogActions:{
		width:"100%",
		height:72,
		padding: 12,
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end"
	},
	closeButton:{
		position:"absolute",
		top:24,
		right:24,
		cursor:"pointer"
	},
	image: 
	{ 
		maxWidth: "100%", 
		maxHeight: 447,
		display:"block",
		margin:"auto",
		marginTop:"30px"
	},
});


export default style;