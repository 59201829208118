import React from "react";
import Select, { components } from "react-select";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CustomStyles, style } from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import { getSortOrderDS } from "../../../ElementDS";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

export const UISortingDropdown = (props: props): JSX.Element => {
  const { formElement, onChange, formId, formGroupId } = props;
  const ErrorMessage = formElement.error || "";
  const IsError = !_.isEqual(ErrorMessage.length, 0);
  const classes = useStyles();

  return (
    <div className={classes.sortingContainer}>
      <Select
        className={classes.width100}
        value={formElement.value}
        isDisabled={formElement.disabled}
        components={{
          DropdownIndicator: (data: any) => {
            return (
              <components.DropdownIndicator
                {...data}
                className="caret-select"
              />
            );
          },
        }}
        styles={CustomStyles(IsError, formElement.disabled)}
        onChange={(selectedOption: any) => {
          return onChange({
            formId,
            formGroupId,
            formElement,
            value: selectedOption,
          });
        }}
        options={getSortOrderDS()}
        placeholder={formElement.placeholder}
      />
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={formElement.disabled ? "" : ErrorMessage}
      />
    </div>
  );
};

// Default Props
UISortingDropdown.defaultProps = {
  formElement: {},
};
