import React, { Fragment, useState } from "react";
import classnames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "../style";
import { IFileUpload } from "../../Elements/Forms/interface/File/IFileUpload";
import { UIFileInput } from "./TextInput";
import { IFormElement } from "../../Elements/Forms/interface/IFormElement";
import { Tooltip } from "@material-ui/core";
import { PreviewDialog } from "../../Elements/FormElements/UploadFile/BucketUpload/PreviewDialog";
import { IconTrash } from "../../Icons";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  file: IFileUpload;
  onDelete: any;
  onRetry?: any;
  onCancel?: any;
  status: string;
  loading: boolean;
  isError?: boolean;
  isPending: boolean;
  isUploading: boolean;
  fileIcon: any;
  showRetryOption: boolean;
  formElement: IFormElement | null;
  onInputChange: any;
  isRejectFile: boolean;
  showTextBox: boolean;
};

const getFileName = (fileName: string): JSX.Element => {
  const FileName = (fileName || "").toString().trim();
  if (_.gt(FileName.length, 15)) {
    const Text = `${FileName.substring(0, 5)}...${FileName.substring(
      FileName.length - 10,
      FileName.length
    )}`;
    return (
      <Tooltip title={FileName} placement={"top"}>
        <span>{Text}</span>
      </Tooltip>
    );
  }
  return <>{FileName}</>;
};

// Display File
export const UIFileComponent = (props: props): JSX.Element => {
  const classes = useStyles();
  const {
    file,
    onDelete,
    onRetry,
    loading,
    status,
    onCancel,
    isError,
    isPending,
    isUploading,
    fileIcon,
    showRetryOption,
    formElement,
    onInputChange,
    isRejectFile,
    showTextBox,
  } = props;
  const fileExtensionType = file.extensionType;
  const [showPreview, setShowPreview] = useState(false);

  const onPreview = (): void => {
    setShowPreview(true);
  };

  const checkForFileExtensionType = (): boolean => {
    if (_.isEqual(_.toLower(fileExtensionType), "mp4") || _.isEqual(_.toLower(fileExtensionType), "png") || _.isEqual(_.toLower(fileExtensionType), "jpg") || _.isEqual(_.toLower(fileExtensionType), "mov") || _.isEqual(_.toLower(fileExtensionType), "gif")) {
      return true;
    }
    else {
      return false;
    }
  };

  const fileCheck = checkForFileExtensionType();

  return (
    <Fragment>
      <div className={classnames(classes.uploadBucketfileContainer)}>
        <div className={classes.uploadBucketFileInfo}>
          <div
            className={classnames(classes.fileIcon, {
              [classes.fileLoaderContainer]: _.isEqual(status, "uploading"),
            })}
          >
            <span className={classes.fileIconStyle}>{fileIcon}</span>
          </div>
          <div className={classes.bucketUploadfileName}>
            {getFileName(file.displayName)}
          </div>
          <div className={classes.fileSize}>{file.displaySize}</div>
        </div>
        {!isPending && !isError && showTextBox && !loading && (
          <div className={classes.fileUploadText}>
            <UIFileInput
              onChange={(data: any) => {
                onInputChange(data);
              }}
              formElement={formElement}
              file={file}
              disabled={isRejectFile}
            />
          </div>
        )}

        <div className={classes.fileActionContainer}>
          {showRetryOption && isError && !loading && (
            <div className={classes.fileAction} onClick={onRetry}>
              Retry
            </div>
          )}
          {!isError && !isPending && _.isEqual(fileCheck, true) && !loading && (
            <div className={classnames(classes.fileAction)} onClick={onPreview}>
              Preview
            </div>
          )}
          {!isPending && !loading && (
            <div className={classnames(classes.fileAction)} >
              <IconTrash onClick={onDelete} title="Delete" disabled={false} isFileUpload={true}/>
            </div>
          )}
          {isUploading && (
            <div className={classnames(classes.fileAction)} onClick={onCancel}>
              Cancel
            </div>
          )}
        </div>
      </div>
      {showPreview ? (
        <PreviewDialog
          heading="Preview"
          bodyUrl={file.signedURL}
          extensionType={_.toLower(file.extensionType)}
          onClose={() => {
            setShowPreview(false);
          }}
        />
      ) : null}
    </Fragment>
  );
};
