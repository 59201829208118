import React, { Fragment, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Select, { components } from "react-select";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { Input } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { IForm } from "../../Forms/interface/IForm";
import UILabel from "../Child/Label";
import classNames from "classnames";
import style from "./style";
import commonStyle from "../sharedStyle";
import { CustomStyles } from "../Select/style";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import * as _ from "lodash";
import { FormElementHelper } from "../../Forms/helper/Utils/Element";
import { getErrorMessage, getElementValue } from "./Utility";

type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  onValidateForms: any;
  formGroupId: number;
  form: IForm;
};

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));

// Toggle button functional component
function DatasetField(props: props): JSX.Element {
  const { formElement, onChange, onValidateForms, formId, formGroupId, form } =
    props;
  const Value = formElement.value || "";
  const classes = useStyles();
  const [input, setInput] = useState(Value);
  const commonClasses = useCommonStyles();
  const errorMessage = getErrorMessage(formElement);

  const elementHelper = new FormElementHelper();
  const ParentElements = formElement.parent_elements;
  let IsParentEmpty = false;
  const IsDDValueEmpty = _.isEmpty(formElement.dataSource);
  const [selectedOption, setSelectedOption] = useState(1);

  const Data = [
    { label: formElement.updateRecordLabel, value: 0 },
    { label: formElement.newRecordLabel, value: 1 }
  ];

  let Props = {};

  if (formElement.is_multi) {
    Props = {
      ...Props,
      rows: 4,
      multiline: true,
    };
  }

  if (!_.isEqual(ParentElements.length, 0)) {
    IsParentEmpty = elementHelper.isAnyParentEmptyForDataSet(
      form,
      ParentElements
    );
  }

  const handleChange = (selectedValue: any): void => {
    if (_.isNull(selectedValue)) {
      setSelectedOption(selectedOption);
    } else {
      setSelectedOption(selectedValue);
      onChange(
        {
          formId,
          formGroupId,
          formElement,
          value: selectedOption,
        },
        selectedValue
      );
    }
  };

  useEffect(() => {
    if (IsParentEmpty || IsDDValueEmpty) {
      setSelectedOption(1);
    }
  }, []);

  useEffect(() => {
    if (IsDDValueEmpty) {
      setSelectedOption(1);
    } else {
      setSelectedOption(0);
    }
  }, [IsDDValueEmpty]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!_.isEqual(Value, input)) {
        onChange({ formId, formGroupId, formElement, value: input });
      }
    }, 300);
    return () => clearTimeout(timeoutId);
  }, [input]);

  useEffect(() => {
    const elementValue = getElementValue(formElement);
    setInput(elementValue);
  }, [Value]);

  return (
    <div
      style={{
        width: `${formElement.width}%`,
      }}
    >
      <UILabel formElement={formElement} />
      <ToggleButtonGroup
        value={IsParentEmpty || IsDDValueEmpty ? 1 : selectedOption}
        exclusive={true}
        onChange={(event: any, selectedValue: any) => {
          return handleChange(selectedValue);
        }}
        aria-label="button"
        className={classes.toggleButtonGroup}
      >
        {Data.map((button: any, index: number) => {
          return (
            <ToggleButton
              value={button.value}
              key={`${button.label} - ${index}`}
              aria-label={`${button.label} + ${index}`}
              className={classes.toggleButton}
              classes={{ selected: classes.selectedButton }}
              disabled={IsParentEmpty || IsDDValueEmpty}
              style={{
                opacity:
                  (IsParentEmpty || IsDDValueEmpty) &&
                  _.isEqual(button.value, 0)
                    ? 0.5
                    : 1,
              }}
            >
              {button.label}
            </ToggleButton>
          );
        })}
      </ToggleButtonGroup>

      {!(IsParentEmpty || IsDDValueEmpty) && _.isEqual(selectedOption, 0) ? (
        <Fragment>
          <span>
            <Select
              isMulti={formElement.is_multi}
              isClearable={!formElement.is_required}
              value={formElement.value}
              isDisabled={formElement.disabled}
              components={{
                DropdownIndicator: (data: any) => {
                  return (
                    <components.DropdownIndicator
                      {...data}
                      className="caret-select"
                    />
                  );
                },
              }}
              styles={CustomStyles(
                !_.isEqual(errorMessage.length, 0),
                formElement.disabled,
                false
              )}
              onChange={(option: any) => {
                return onChange(
                  {
                    formId,
                    formGroupId,
                    formElement,
                    value: option,
                  },
                  0
                );
              }}
              options={formElement.dataSource}
              placeholder={formElement.placeholder}
            />
          </span>
          <UIErrorOrNoteMessage
            formElement={formElement}
            errorMessage={errorMessage}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Input
            type="text"
            {...Props}
            disabled={false}
            id={`${formElement.id}`}
            className={classes.container}
            style={{
              height: formElement.is_multi ? "auto" : 36,
            }}
            onBlur={() =>
              onValidateForms({
                formId,
                formGroupId,
                formElement,
                value: input,
              })
            }
            value={input}
            placeholder={formElement.placeholder}
            onChange={(event: any) => {
              const InputValue = event.target.value;
              setInput(InputValue);
            }}
            inputProps={{
              style: {
                height: formElement.is_multi ? "auto" : 36,
              },
              className: classNames(classes.input, {
                [commonClasses.error]: !_.isEqual(errorMessage.length, 0),
              }),
            }}
          />
          <UIErrorOrNoteMessage
            formElement={formElement}
            errorMessage={errorMessage}
          />
        </Fragment>
      )}
    </div>
  );
}

export default DatasetField;
