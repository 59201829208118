import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import * as _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import style from "./style";

type props = {
  classes?: any;
  label: string;
  tooltip: string;
  required: boolean;
};

// Input label with tooltip
function InputLabelTooltip(props: props): JSX.Element {
	const { label, tooltip, classes, required } = props;

	if (_.isEqual(tooltip.trim().length, 0)) {
		return (
			<div className={classes.labelContainer}>
				{" "}
				{label}
				<span className={classes.required}>{required && " *"}</span>
			</div>
		);
	}

	return (
		<div className={classes.labelContainer}>
			{label}
			<Tooltip title={tooltip}>
				<IconButton
					size="small"
					className={classes.inputTooltipLabel}
					aria-label={label}
				>
					<svg width="14" height="14" viewBox="0 0 48 48">
						<path fill="none" d="M0 0h48v48h-48z" />
						<path
							fill="#6a737c"
							d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"
						/>
					</svg>
				</IconButton>
			</Tooltip>
			<span className={classes.required}>{required && " *"}</span>
		</div>
	);
}

export default withStyles(style, { withTheme: true })(InputLabelTooltip);
