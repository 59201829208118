import React from "react";
import { Placeholder } from "./Placeholder";


type props = {
  title: string;
  subtitle: string;
  tooltip?: string;
};

export const TitleSubtitleTextElement = (props: props): React.JSX.Element => {
  const { title, subtitle,tooltip } = props;
  const Title = (title || "").toString().trim();
  const SubTitle = (subtitle || "").toString().trim();

  return (
    <div>
        <Placeholder 
            containerClass=""
            text={Title}
            tooltip={tooltip}
       />
       <Placeholder 
           containerClass="subtitle"
           text={SubTitle}
      />
    </div>
  );
};
