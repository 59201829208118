import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "./style";
import classnames from "classnames";
import { IFormElement } from "../../../../../../Forms/interface/IFormElement";
import { IGridHeader } from "../../../../../../Forms/interface/Grid/IGridHeader";
import { IconTrash } from "../../../../../../../Icons";
import { UITextInput } from "../Elements/TextInput";
import { UISelect } from "../Elements/Dropdown";
import { UI_GRID_CELL_EDITTOR_TYPES } from "../../../../../../config";
import { IOption } from "../../../../../../Forms/interface/IOption";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  formElement: IFormElement;
  onAddFormChange: any;
  onClearForm: any;
  form: any;
};

export const DataGridAddForm = (props: props): JSX.Element => {
  const classes = useStyles();
  const { formElement, onAddFormChange, form, onClearForm } = props;

  const FormElements = formElement.dynamic_configuration.headerData
    .filter((header: IGridHeader) => !header.hide)
    .map((header: IGridHeader) => {
      let element = <span />;
      if (_.isEqual(header.type, UI_GRID_CELL_EDITTOR_TYPES.TEXT)) {
        const Element = form[header.field];
        const ElementValue = (Element.value || "").toString().trim();
        const IsError =
          header.config.required &&
          Element.touched &&
          _.isEqual(ElementValue, "");

        element = (
          <UITextInput
            placeholder={header.placeholder}
            field={header.field}
            element={Element}
            isError={IsError}
            onFormChange={onAddFormChange}
          />
        );
      }

      if (_.isEqual(header.type, UI_GRID_CELL_EDITTOR_TYPES.DROPDOWN)) {
        const Element = form[header.field];
        const ElementValue = (Element.value || "").toString().trim();
        const IsError =
          header.config.required &&
          Element.touched &&
          _.isEqual(ElementValue.length, 0);

        const Option: IOption | null = _.isEqual(ElementValue.length, 0)
          ? null
          : { label: ElementValue, value: ElementValue };
        element = (
          <UISelect
            dataSource={header?.dataSource?.data || []}
            placeholder={header.placeholder}
            field={header.field}
            elementValue={Option}
            onFormChange={onAddFormChange}
            isError={IsError}
            config={header.config}
          />
        );
      }
      return (
        <div key={header.field} className={classes.gridForm}>
          {element}
        </div>
      );
    });
  FormElements.push(
    <div
      key="grid-form"
      className={classnames(classes.gridForm, classes.gridActionCell)}
    >
      <IconTrash
        title={"Clear"}
        onClick={() => onClearForm()}
        disabled={false}
      ></IconTrash>
    </div>
  );
  return <div className={classes.gridFormContainer}>{FormElements}</div>;
};
