import {
	getResource
} from "../../../api/services";
import {
	GET_FILTER_OPTIONS_REQUEST,
	GET_FILTER_OPTIONS_SUCCESS,
	GET_FILTER_OPTIONS_FAILED
} from "../../types";
import {
	GET_FILTER_OPTIONS_URL
} from "../../../api/paths";
import * as _ from "lodash";

// Filter Options request initiate
const getFilterOptionsRequest = (): any => ({ type: GET_FILTER_OPTIONS_REQUEST });

// Filter Options request successful
const getFilterOptionsSuccess = (data: any): any => ({ type: GET_FILTER_OPTIONS_SUCCESS, payload: data });

// Filter Options request failed
const getFilterOptionsFailed = (): any => ({ type: GET_FILTER_OPTIONS_FAILED });

// Get Filter Options
export const getFilterOptions = (): any => (dispatch: any, getState: any) => {
	dispatch(getFilterOptionsRequest());

	const ReduxState = getState();
	const filterOptions = ReduxState.filterOptions.filterOptions;

	if (_.isEqual(filterOptions.length, 0)) {
		getResource(dispatch, GET_FILTER_OPTIONS_URL, (response: any) =>
			dispatch(getFilterOptionsSuccess(response.data)),
		() => dispatch(getFilterOptionsFailed())
		);
		return;
	}

	return dispatch(getFilterOptionsSuccess(filterOptions));
};