import React from "react";
import * as _ from "lodash";
import { UIDynamicSelect } from "../Dynamic";
import { UI_DYNAMIC_CONFIG_ELEMENT_TYPES } from "../../config";
import { UISelect } from "../Select";
import { UICreatableSelect } from "../Select/Creatable";

export const getSelectElement = (props: any): JSX.Element => {
  const { formId, formGroupId, onChange, formElement} = props;
  if (
    !_.isNull(formElement.dynamic_configuration) &&
    _.isEqual(
      formElement.dynamic_configuration.elementType,
      UI_DYNAMIC_CONFIG_ELEMENT_TYPES.METRICS
    )
  ) {
    return (
      <UIDynamicSelect
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  } else if (formElement.is_creatable_select) {
    return (
      <UICreatableSelect
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  } else {
    return (
      <UISelect
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  }
};
