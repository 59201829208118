
import { IOption } from "../../../../UI/Elements/Forms/interface/IOption";
import { TEXT_ALIGNMENT, ELEMENT_TYPES, GRID_CELL_ELEMENT } from "../../configuration";
import {
	GET_SUBAGENCIES_BY_AGENCY, GET_BRANDS_BY_CLIENT,
	GET_VERTICAL_BY_BRAND
} from "../../../../api/APIPaths/mapping";

import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../Interfaces/Form/IForm";
import { IGridHeader } from "../../Interfaces/Grid/IGridHeader";

export const getMetaFormConfig = (formData: any): IForm => {

	const ManagerAccount: IFormElement = {
		id: "manager",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.TEXT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.manager || null,
		configuration: {
			dataSourceKey: "",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "",
			title: "Business Manager Account",
			defaultValue: [],
			allowAll: false,
			parentElements: [],
			apiURL: "",
			readOnly: {
				editForm: true,
				newForm: false
			},
		},
		readOnly: true,
		readOnlyOptions: {
			title: "",
			subtitle: "",
			keys: {
				title: "businessmanagerName",
				subtitle: "businessmanagerId",
			}
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Account: IFormElement = {
		id: "account",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.TEXT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.account || null,
		configuration: {
			dataSourceKey: "",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "",
			title: "Ad Account",
			defaultValue: [],
			allowAll: false,
			parentElements: [],
			apiURL: "",
			readOnly: {
				editForm: true,
				newForm: false
			}
		},
		readOnly: true,
		readOnlyOptions: {
			title: "",
			subtitle: "",
			keys: {
				title: "adaccountName",
				subtitle: "adaccountId",
			}
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};


	const Agency: IFormElement = {
		id: "agency",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.agency || null,
		configuration: {
			dataSourceKey: "agencies",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Agency",
			title: "Agency",
			defaultValue: [],
			allowAll: false,
			parentElements: [],
			apiKey: "agency_id",
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const SubAgency: IFormElement = {
		id: "subagency",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.subagency || null,
		configuration: {
			dataSourceKey: "subagencies",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select SubAgency",
			title: "SubAgency",
			defaultValue: null,
			allowAll: false,
			parentElements: ["agency"],
			apiURL: GET_SUBAGENCIES_BY_AGENCY
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Market: IFormElement = {
		id: "market",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.market || null,
		configuration: {
			dataSourceKey: "markets",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Market",
			title: "Market",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Client: IFormElement = {
		id: "client",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.client || null,
		configuration: {
			dataSourceKey: "clients",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Client",
			title: "Client",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
			apiKey: "client_id",
			isCreatableSelect: false,
			isAsyncSelect: true,
			asyncRequestKey: "client_name",
			apiURL: "api/mapping/v2/client/",
			tooltip: "This field displays 2500 clients by default. To search a client, type the client's name and select from the dropdown.",
			note: "For adding new clients please contact to <a href='mailTo:gdt-automations-support@groupm.tech'>gdt-automations-support@groupm.tech</a>"
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Brand: IFormElement = {
		id: "brand",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.brand || null,
		configuration: {
			dataSourceKey: "brands",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Brand",
			title: "Brand",
			defaultValue: null,
			allowAll: false,
			parentElements: ["client"],
			apiURL: GET_BRANDS_BY_CLIENT,
			apiKey: "brand_id",
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Vertical: IFormElement = {
		id: "vertical",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.vertical || null,
		configuration: {
			dataSourceKey: "verticals",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Vertical",
			title: "Vertical",
			defaultValue: [],
			allowAll: false,
			parentElements: ["brand"],
			apiURL: GET_VERTICAL_BY_BRAND,
			readOnly: {
				editForm: true,
				newForm: true
			},
			selectValueIfEmpty: {
				valueLabelKey: "None",
				value: null,
			}
		},
		readOnly: true,
		readOnlyOptions: {
			title: "",
			subtitle: "",
			keys: {
				title: "label",
				subtitle: "",
			}
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const IncludeInAdoptionStats: IFormElement = {
		id: "includeInAdoptionStats",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SWITCH,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		visible: true,
		errorMessage: "",
		value: formData.includeInAdoptionStats || 1,
		configuration: {
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "",
			title: "Include in Adoption Statistics as part of Usage Dashboard",
			defaultValue: 1,
			dataSourceKey: "",
			allowAll: false,
			parentElements: []
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	return {
		id: "mappingMapperForm",
		title: "",
		isValid: true,
		isVisible: true,
		groups: [{
			id: "mappingMapperFormGroup",
			elements: [ManagerAccount, Account, Agency, SubAgency, Market, Client, Brand, Vertical, IncludeInAdoptionStats],
			isVisible: true,
			title: ""
		}]
	};
};


export const META_GRID_HEADERS: IGridHeader[] = [{
	defaultSortBy: false,
	id: "id",
	visible: false,
	title: "",
	type: ELEMENT_TYPES.ID,
	isMulti: false,
	sortingKey: "id",
	disableSorting: true,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
}, {
	id: "manager",
	defaultSortBy: true,
	title: "Business Manager Account",
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "manager",
	isMulti: false,
	disableSorting: false,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellTwoLiner: {
		subtitleKey: "businessmanagerId",
		titleKey: "businessmanagerName",
	}
}, {
	alignment: TEXT_ALIGNMENT.DEFAULT,
	type: ELEMENT_TYPES.TEXT_INPUT,
	defaultSortBy: true,
	visible: true,
	isMulti: false,
	title: "Ad Account",
	sortingKey: "account",
	disableSorting: false,
	showHideText: false,
	id: "account",
	cellTwoLiner: {
		titleKey: "adaccountName",
		subtitleKey: "adaccountId"
	},
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	
},
{
	id: "agency",
	title: "Agency / SubAgency",
	visible: true,
	defaultSortBy: true,
	isMulti: false,
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "agency",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	disableSorting: false,
	cellTwoLiner: {
		subtitleKey: "subagencyName",
		titleKey: "agencyName",
	}
}, {
	id: "market",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	isMulti: false,
	cellTwoLiner: {
		subtitleKey: "marketCode",
		titleKey: "marketName",
	},
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	title: "Market",
	disableSorting: false,
	sortingKey: "market",
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
}, {
	defaultSortBy: true,
	cellTwoLiner: {
		titleKey: "clientName",
		subtitleKey: "brandName"
	},
	id: "client",
	type: ELEMENT_TYPES.TEXT_INPUT,
	title: "Client / Brand",
	isMulti: true,
	disableSorting: false,
	sortingKey: "client",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	showHideText: true,
	visible: true,
	
},
{
	defaultSortBy: true,
	visible: true,
	title: "Vertical",
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellTwoLiner: {
		subtitleKey: "",
		titleKey: "verticalName",
	},
	type: ELEMENT_TYPES.TEXT_INPUT,
	isMulti: false,
	sortingKey: "vertical",
	id: "vertical",
	showHideText: false,
	disableSorting: false,
	

}, {
	width: 150,
	id: "mappingManager",
	visible: true,
	defaultSortBy: true,
	isMulti: false,
	title: "Mapping Manager",
	sortingKey: "mappingManager",
	type: ELEMENT_TYPES.TEXT_INPUT,
	disableSorting: false,
	showHideText: false,
	enableTooltip: {
		tooltipKey: "username",
		displayKey: "name"
	},
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},
{
	defaultSortBy: true,
	id: "mappedby",
	visible: true,
	title: "Mapped by",
	type: ELEMENT_TYPES.TEXT_INPUT,
	isMulti: true,
	sortingKey: "mappedby",
	width: 125,
	disableSorting: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLinerTooltip: true,
	cellTwoLiner: {
		titleKey: "name",
		subtitleKey: "mappedDate",
		tooltip: "email"
	},

}, {
	defaultSortBy: true,
	id: "includeInAdoptionStats",
	visible: true,
	title: "Dashboard",
	width: 105,
	type: ELEMENT_TYPES.SWITCH,
	isMulti: false,
	sortingKey: "includeInAdoptionStats",
	disableSorting: true,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	tooltip: "Include in Adoption Statistics as part of Usage Dashboard",
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
}
];


export const getAccountsDropdown = (value: any, dataSource: IOption[]): IFormElement => {
	return {
		id: "accounts",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true,
		},
		errorMessage: "",
		visible: true,
		value: value || null,
		configuration: {
			dataSourceKey: "agencies",
			dataSource: dataSource,
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Agency",
			title: "Agency",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};
};
