import {
	combineReducers
} from "redux";
import error from "./Error";
import filterOptions from "./FilterOptions";
import toolList from "./ToolList";
import initial from "./Initial";
import filter from "./FilterState";

// Combining reducers
const RootReducers = combineReducers({
	error,
	initial,
	filterOptions,
	toolList,
	filter,
});

export default RootReducers;