import React from "react";

export const ErrorIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="14"
    height="14"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path
      fill="#e65d63"
      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z"
    />
  </svg>
);

export const LoaderIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width="16"
    height="16"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M12 3a9 9 0 0 1 9 9h-2a7 7 0 0 0-7-7V3z" />
  </svg>
);

export const SuccessIcon = (
  <svg width="12px" height="12px" viewBox="0 0 12 8">
    <g
      id="Screens"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="Assets" transform="translate(-390.000000, -40.000000)">
        <g id="check-fill" transform="translate(388.000000, 36.000000)">
          <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
          <polygon
            id="Path"
            fill="#109F6A"
            points="6.66666667 10.1146667 12.7946667 3.986 13.738 4.92866667 6.66666667 12 2.424 7.75733333 3.36666667 6.81466667"
          ></polygon>
        </g>
      </g>
    </g>
  </svg>
);
