import { Theme } from "@material-ui/core/styles";
import Colors from "../../../../../../Theme/colors";

const style: any = (theme: Theme) => ({
	gridHeader:{
		height:92,
		display:"flex",
		"& .heading":{
			fontSize:"1.5rem"
		}
	},
	search: {
		position: "relative" as const,
		borderRadius: 2,
		backgroundColor: Colors.WHITE,
		[theme.breakpoints.up("sm")]: {
			width: "auto",
		},
	},
	searchIcon: {
		padding: "0px 13px",
		height: "100%",
		position: "absolute" as const,
		pointerEvents: "none" as const,
		display: "flex",
		alignItems: "center" as const,
		justifyContent: "center" as const,
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		maxWidth: "100%",
		fontSize: 14,
		height: 36,
		width: 397,
		fontWeight: "normal" as const,
		backgroundColor: "rgba(255, 255, 255, 0)",
		border: "1px solid rgb(208, 213, 218)",
		borderRadius: 2,
		"box-sizing": "border-box",
	},
	inputElement: {
		"&:hover": {
			border: "1px solid rgb(134, 142, 150)",
		},
		"&.Mui-focused": {
			border: "1px solid rgb(58, 145, 232)",
		},
		"&.Mui-disabled": {
			border: "1px solid rgb(208, 213, 218)",
		},
	},
	actionContainer:{
		display:"flex",
		padding:"5px 0 4px 20px"
	}
});

export default style;
