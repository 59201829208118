import moment from "moment";

enum ELEMENT_TYPES {
	ID = 1,
	SELECT_INPUT = 2,
	TEXT_INPUT = 3,
	SWITCH = 4,
	RADIO_INPUT = 5,
	CHECKBOX_INPUT = 6,
	DISPLAY_ACCOUNT = 7,
	DATERANGE_INPUT = 8,
}

enum SORT_ORDER {
	ASC = "asc",
	DESC = "desc",
}

enum TEXT_ALIGNMENT {
	DEFAULT = 0,
	CENTER = 1,
	LEFT = 1,
	RIGHT = 1
}

enum FORM_TITLE {
	NEW = "New",
	EDIT = "Edit",
}

enum GRID_CELL_ELEMENT {
	TWO_LINER_TITLE_SUBTITLE = "two-liner-title-subtitle",
	TWO_LINER_TEXT = "two-liner-text",
	DEFAULT = "default"
}

enum NOTIFICATION_TYPE {
	SUCCESS = "success",
	ERROR = "error",
	INFO = "info",
	WARNING = "warning"
}

const ALL_OPTION_LABEL = "All";
const ALL_OPTION_VALUE = "all";

const ALL_OPTION_OBJ = {
	label: ALL_OPTION_LABEL,
	value: ALL_OPTION_VALUE
};

const DATE_OBJ = {
	start_date: moment(new Date(), "YYYY/MM/DD", true).subtract(90, "d").local(),
	end_date: moment(new Date(), "YYYY/MM/DD", true).local()
};

const CALENDER_OPTIONS = [
	{ label: "Last 7 Days", value: 6 },
	{ label: "Last 15 Days", value: 14 },
	{ label: "Last 1 Month", value: 30 },
	{ label: "Last 3 Months", value: 90 },
	{ label: "Last 6 Months", value: 180 },
	{ label: "Last 1 Year", value: 365 },
	{ label: "Custom Date", value: 1 },
];

export {
	ELEMENT_TYPES,
	SORT_ORDER,
	TEXT_ALIGNMENT,
	ALL_OPTION_OBJ,
	ALL_OPTION_LABEL,
	ALL_OPTION_VALUE,
	GRID_CELL_ELEMENT,
	FORM_TITLE,
	NOTIFICATION_TYPE,
	DATE_OBJ,
	CALENDER_OPTIONS
};


