import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton } from "@material-ui/core";
import classNames from "classnames";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onClick: any;
  title: string;
  disabled: boolean;
  isFileUpload?: boolean;
};

// Trash Icon functional component
export const IconTrash = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onClick, title, disabled, isFileUpload } = props;

  return (
    <Tooltip title={title} onClick={onClick} placement={"top"}>
      <IconButton
        aria-label={title}
        size={"small"}
        className={classNames(classes.iconButton, classes.disabled)}
        disabled={disabled}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={isFileUpload ? "18" : "24"}
          height={isFileUpload ? "18" : "24"}
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3zm-8 5v6h2v-6H9zm4 0v6h2v-6h-2zM9 4v2h6V4H9z" />
        </svg>
      </IconButton>
    </Tooltip>
  );
};
