import * as _ from "lodash";
import { IOption } from "../../../../../../../UI/Elements/Forms/interface/IOption";
import { IFormElement } from "../../../../../Interfaces/Form/FormElement/IFormElement";


// Single Select Element Helper 
export class SingleSelectHelper{

	ifSingleSelectInputUndefined = (formElement: IFormElement, elementValue: any) : IFormElement =>{
		const Element = {...formElement};
		const Option:IOption = formElement.configuration.dataSource.find((option: IOption)=> 
    				_.isEqual((option.value || "").toString().trim(), (elementValue || "").toString()))!;
					
				Element.value =_.isUndefined(Option) ?  null : Option;      
				Element.touched = true;
			return Element;
	}

	ifSingleSelectInputNotUndefined = (formElement: IFormElement, elementValue: any): IFormElement =>{
		const Element = {...formElement};
		const Option:IOption = formElement.configuration.dataSource.find((option: IOption)=> 
    				_.isEqual((option.value || "").toString().trim(), (elementValue.value || "").toString()))!;
        
				Element.value = _.isUndefined(Option) ?  null : Option;

				Element.touched = true;
		return Element;
	}

    /**
     * Get single-select value
     *
     * @param {formElement} IFormElement.
     * @return {formElement} IFormElement.
     */
    getSingleDropdownValue = (formElement: IFormElement) : IFormElement =>{
        
    	let Element = {...formElement};
    	if(_.isNull(formElement.value)){
    		Element.value = null;
    		return Element;
    	}

		const FormElementValue = (formElement.value || "").toString().trim();

    	const elementValue = _.isUndefined(formElement.value) ? 
    		FormElementValue : formElement.value;
	
    	if(!_.isEqual(FormElementValue.length, 0) && _.isArray(formElement.configuration.dataSource)){
    		if(_.isUndefined(elementValue.value)){
    			Element = this.ifSingleSelectInputUndefined(formElement, elementValue);
    		}
    		else{
    			Element = this.ifSingleSelectInputNotUndefined(formElement, elementValue);
    		}
    		return Element;
    	}
    	Element.value = null;
    	return Element;
    }
}
