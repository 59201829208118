import React, { Fragment } from "react";
import RowLoader from "./RowLoader";
import { IGridHeader } from "../../../../Forms/interface/Grid/IGridHeader";

type props = {
  rows: number;
  headers: IGridHeader[];
  loadHeader: boolean;
  isGridAction: boolean;
};

export default function GridLoaderset(props: props): React.JSX.Element {
  const { rows, headers, isGridAction } = props;

  return (
    <Fragment>
      <RowLoader isGridAction={isGridAction} headers={headers} rows={rows} />
    </Fragment>
  );
}
