import React, { useRef, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import * as _ from "lodash";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { ArrowDown, ArrowUp } from "./Icons";
import style from "./style";
import {
  GOOGLE_ANALYSTICS_ACTIONS,
  GOOGLE_ANALYSTICS_CATEGORIES,
} from "../../helpers/GA/GoogleAnalysticsUtils";
import { GAEvent } from "../../helpers/GA";
import { LogService } from "../../api/service/Log";
import { IUserPermission } from "../Routes/Permissions/IUserPermission";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  title: string;
  indexValue: number;
  selectedIndex: number;
  handleListItemClick: any;
  childRoutes: IUserPermission[];
};

export const MenuComponent = (props: props): any => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const history = useHistory();

  const handleToggle = (): void => {
    setOpen((prevState: any) => !prevState);
  };

  const handleClose = (event: React.MouseEvent<EventTarget>): void => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent): void {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classNames(classes.listItems, classes.menuButton, {
          [classes.selectedMenu]: _.isEqual(
            props.selectedIndex,
            props.indexValue
          ),
        })}
      >
        <div>{props.title}</div>
        <div className={classes.menuIcon}>{open ? ArrowUp : ArrowDown}</div>
      </button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              boxShadow:
                "0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)",
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menuList}
                >
                  {props.childRoutes.map((route: IUserPermission) => {
                    if (route.allowedAccess) {
                      return (
                        <MenuItem
                          key={route.title}
                          className={classes.menuItems}
                          onClick={(event: React.MouseEvent<EventTarget>) => {
                            if (_.isUndefined(route.target)) {
                              history.push(route.url);
                            } else {
                              window.open(route.url, route.target);
                              return;
                            }

                            LogService({
                              action: "click",
                              name: route.title,
                            });
                            GAEvent(
                              GOOGLE_ANALYSTICS_CATEGORIES.NAVIGATION,
                              GOOGLE_ANALYSTICS_ACTIONS.LINK_CLICKED,
                              route.gaTitle
                            );
                            props.handleListItemClick(
                              event,
                              props.indexValue,
                              route.title
                            );

                            handleClose(event);
                          }}
                        >
                          {route.title}
                        </MenuItem>
                      );
                    }
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
