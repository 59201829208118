import React, { useEffect, useState} from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Container, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import ShowMoreText from "react-show-more-text";
import * as _ from "lodash";
import style from "./style";
import GroupMBG from "../../../assets/GroupMBG.png";
import GroupMNexusLogo from "../../../assets/icons/GroupMNexusLogo.png";
import { UIOutlineButton } from "../../../UI/Buttons";
import { USER_PROFILE_GET_DATA } from "../../../api/paths";
import { Axios } from "../../../api/service/Axios";
import { useOktaAuth } from "@okta/okta-react";
import { CircularProgress } from "../../../UI/Progress";
import { ProfileForms } from "./Configuration/Form";
import { FormElementHelper } from "../../../components/UserProfile/Helpers/Forms/Element";
import { ValidationHelper } from "../../../components/UserProfile/Helpers/Validations";
import { EditFormHelper, SubmitFormHelper } from "../../../components/UserProfile/Helpers/Forms";
import { IFormElementUpdate } from "../../../components/UserProfile/Interface/FormElement/IFormElementUpdate";
import { IForm } from "../../../components/UserProfile/Interface/IForm";
import { FormBuilder } from "../../../components/UserProfile/Form";
import { catchError } from "../../../components/Mapping/Utility/ErrorMessage";
import { SnackbarAlert } from "../../../UI/Snackbar";
import { Footer } from "../Footer";
import { FormHelper } from "../../../components/UserProfile/Helpers/Forms/Helper";
import { FORM_ELEMENT_TYPES } from "../../../components/UserProfile/Form/ElementTypes";
import { IFormElementValue } from "../../../components/UserProfile/Interface/FormElement/IFormElementValue";
import { ParentChildElementUtil } from "../../../components/UserProfile/Helpers/Forms/ParentChildElement";
import { UIUserInfo } from "../../../components/UserProfile/Form/Elements/Profile";
import { UIHR } from "../../../components/UserProfile/Form/Elements/ChildElements/HR";
import { useHistory } from "react-router";
// CSS Styles
const useStyles = makeStyles(() => createStyles(style()));

type ESnackbarAlert = "success" | "error" | "info" | "warning";

// User Profile
const UserProfile = (): any => {
  const classes = useStyles();
  const { oktaAuth, authState} = useOktaAuth();
  const [formData, setFormData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [isExpanded, setExpanded] = useState(false);
  const [initialLoader, setInitialLoader] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  const bundle = useSelector((state: any) => state.initial.bundle);
  const UserInfo = useSelector((state: any) => state.initial.userInfo);
  const initial = useSelector((state: any) => state.initial);
  const history = useHistory();
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  const isExternalUser = initial.isExternalUser;
  if (isExternalUser) {
    history.push("/");
  }
  useEffect(()=>{
    Axios.get(USER_PROFILE_GET_DATA)
    .then(async (response: any)=>{
      await setForm(response.data);
      setInitialLoader(false);
    }).catch((err: any)=>{
      const errorMessage = catchError(
        err,
        bundle.USER_PROFILE_LOAD_ERROR_MSG
      );

      setLoadingError(errorMessage);
      setInitialLoader(false);
    });
  }, []);

  const setAlert = (message: string, variant: ESnackbarAlert): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };
  
  const setForm = async (dataSources: any): Promise<void> => {
    const datasource = dataSources.dataSource;
    const userProfile = dataSources.userInfo;
    const formHelper = new EditFormHelper();
    const validationHelper = new ValidationHelper();

    let _forms = await formHelper.buildEditForm(
      datasource,
      userProfile,
      UserInfo,
      ProfileForms
    );
    const formElementHelper = new FormElementHelper();
    _forms = formElementHelper.findDependentParentElements(_forms);
    _forms = formElementHelper.findDisabledChildElements(_forms);
    _forms = validationHelper.isValidForms(_forms);

    setFormData((prevState: any) => ({
      ...prevState,
      ...dataSources,
      forms: _forms
    }));
  };

  const ParentChildElementValidation = async (
    updatedForms: any,
    element: any
  ): Promise<any> => {
    const validationHelper: ValidationHelper = new ValidationHelper();
    const parentChildEle: ParentChildElementUtil = new ParentChildElementUtil();
    setLoading(true);

    // Call API if any child is associated
    let UpdatedForms: any = await parentChildEle.findParentElements(
      updatedForms,
      element
    );

    setFormData((prevState: any)=>({
      ...prevState,
      forms: UpdatedForms
    }));

    UpdatedForms = await parentChildEle.findChildElements(UpdatedForms, element);
    
    setFormData((prevState: any)=>({
      ...prevState,
      forms: UpdatedForms
    }));
    const ValidatedForms = validationHelper.isValidForms(UpdatedForms);

    setFormData((prevState: any)=>({
      ...prevState,
      forms: ValidatedForms
    }));

    setLoading(false);

    return ValidatedForms;
  };

  const onChange = async (element: IFormElementUpdate): Promise<void> => {
    const editFormHelper = new EditFormHelper();
 
    const ElementValue: IFormElementValue = {
      currentValue: element.value,
      previousValue: element.formElement.value,
    };
    const validationHelper = new ValidationHelper();
    const formElementHelper = new FormElementHelper();
    
    let UpdatedForms: IForm[] = editFormHelper.updateForms(
      formData.forms,
      element,
      ElementValue
    );
    UpdatedForms = formElementHelper.findDependentElements(
      UpdatedForms,
      element
    );

    UpdatedForms = formElementHelper.findDisabledElements(
      UpdatedForms,
      element
    );
    UpdatedForms = validationHelper.isValidElement(UpdatedForms, element);
    UpdatedForms = validationHelper.isValidForms(UpdatedForms);

    setFormData((prevState: any)=>({
      ...prevState,
      forms: UpdatedForms
    }));

    const formHelper = new FormHelper();
    if (
      _.isEqual(element.formElement.type, FORM_ELEMENT_TYPES.SELECT_INPUT) &&
      !validationHelper.isPreviousAndCurrentValueAreSame(
        element.formElement,
        ElementValue
      ) &&
      formHelper.ifHasChildElements(UpdatedForms, element.formElement)
    ) {
      UpdatedForms = await ParentChildElementValidation(UpdatedForms, element);
      UpdatedForms = validationHelper.isValidForms(UpdatedForms);
      setFormData((prevState: any)=>({
        ...prevState,
        forms: UpdatedForms
      }));
    }
  };


  const onRedirect =async (): Promise<void> => {
    if(initial.isEmbed){
      window.location.href = "/?embed=true";
    }else{
      window.location.href = "/";
    }
  };

  const onSave = async (): Promise<void> => {
    const helper = new SubmitFormHelper();
    const FormData = helper.getSubmitForm(formData.forms);
    setLoading(true);
    try {
      await helper.saveProfile(UserInfo.userId, FormData);
      onRedirect();
    } catch (err: any) {
      const errorMessage = catchError(
        err,
        bundle.USER_PROFILE_ERROR_MSG
      );

      setAlert(errorMessage, "error");
      setLoading(false);
    }
  };

  if(initialLoader){
    return <>
    <div className={classes.container}>
      <CircularProgress />
      <img src={GroupMBG} className={classes.bgImage} />
    </div>
    </>;
  }

  const onLogout = (): void => {
    if (oktaAuth && authState?.isAuthenticated) {
      oktaAuth.signOut().catch(() => {
        onRedirect();
      });
      return;
    }
    onRedirect();
  };


  return (
    <>
      <img src={GroupMBG} className={classes.bgImage} />
      <Container maxWidth="lg">
            <Grid container justifyContent="center" className={classes.contentWrapper}>
              <Grid
                container
                item
                xs={12}
                sm={12}
                md={12}
                lg={8}
                xl={8}
              >
                {loading && <CircularProgress />}
                <div className={classes.content}>
                
                    <div className={classes.header}>
                    <img className={classes.logo} src={GroupMNexusLogo}  alt="GroupM Nexus"/>
                    <div className={classes.siteTitle}>Automations Portal</div>
                    </div>
                    <UIUserInfo />
                    <UIHR />
                    {
                      _.isNull(loadingError) ? 
                      <div className={classes.showHide}>
                        <ShowMoreText
                /* Default options */
                lines={2}
                more="Show more"
                less="Show less"
                anchorClass={classes.link}
                onClick={()=>{
                  setExpanded(!isExpanded);
                }}
                expanded={isExpanded}
                keepNewLines={false}
                truncatedEndingComponent={"... "}
            >
                      <span>
                        Before entering the Automations Portal please fill in the profile form as it will help the automations team to generate more accurate user tracking data which will help to improve the following areas of the adoption tracking dashboard
                        </span><br/>
                        <ol>
                          <li>
                          Provide usage statistics per market of all non-script based tools.
                          </li>
                          <li>
                          Ability to break up the total usage statistics for non-script based tools in local market teams usage vs global offshore hubs.
                          </li>
                          <li>
                          Get a better understanding of the Automation Portal&apos;s user base which will help the team to add new functionality to the portal.
                          </li>
                        </ol>
                  <p>
                        You can access your current profile information by clicking on the icon in the top right-hand corner of the Automations Portal. In order to keep the personal profile information up-to-date you can edit the fields where needed. Every 6 months you will be asked to check your profile and make changes where needed.
                        </p>
            </ShowMoreText>
                       
                       
                      </div> : <div className={classes.note} dangerouslySetInnerHTML={{
                        __html: `Error: ${loadingError}`
                      }}>
                      </div>
                    }
                    <div>
                      {
                        !_.isNull(formData) && formData.forms.map((form: IForm) => {
                          return <FormBuilder form={form} onChange={onChange} key={form.id} />;
                        })
                      }
                    </div>
                    <div className={classes.actionButtons}>
                      <UIOutlineButton
                          cssStyle={{
                            marginRight: 7,
                          }}
                          isOutline={true}
                          onClick={onLogout}
                          id="SignOut"
                          btnText="Sign out"
                  />
                      {
                        !_.isNull(formData) && <Button
                        onClick={onSave}
                        variant="contained"
                        color="primary"
                        id="Save"
                        disabled={!formData.forms[0].isValid || formData.forms[0].loading}
                        >
                        Save
                      </Button>
                      }
                      
                      
                  </div>
                  </div>
              </Grid>
            </Grid>
          </Container>
      <Footer />
      <div className={classes.snackbar}>
      <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
        />
      </div>
    </>
  );
};

export default UserProfile;
