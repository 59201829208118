export const FORM_ELEMENT_TYPES = {
	SELECT_INPUT: "SELECT",
	TEXT_INPUT: "TEXTBOX",
	CHECKBOX_INPUT: "CHECKBOX",
	SWITCH: "SWITCH",
	RADIO_INPUT: "RADIO_INPUT",
	READ_ONLY: "READ_ONLY",
};

enum TOOL_SELECTION_TYPES {
	NEW = 1,
	EXISTING = 2
}

export const ENUMS = {
	TOOL_SELECTION_TYPES
};