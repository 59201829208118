import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

import style from "./style";
import { IconClose } from "../../../../UI/Icons";
import { UIOutlineButton } from "../../../../UI/Buttons";
import { IFormElementUpdate } from "./../../Interfaces/Form/FormElement/IFormElementUpdate";
import { EditFormHelper } from "../../Helpers/Forms/Edit";
import { FormBuilder } from "../Builder";
import { IForm } from "../../Interfaces/Form/IForm";
import { CircularProgress } from "../../../../UI/Progress";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IOption } from "../../../../UI/Elements/Forms/interface/IOption";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  toggleDrawer: any;
  formTitle: string;
  btnSaveText: string;
  onSave: any;
  btnCancelText: string;
  onCancel: any;
  width?: number;
  formData: any;
  isFilterForm: boolean;
  onReset?: any;
  resetText?: string;
};

export const SidebarFormComponent: any = (props: props): React.JSX.Element => {
  const {
    formTitle,formData,btnSaveText, btnCancelText,onSave,onCancel, width, resetText, onReset
  } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [form, setForm] = useState<IForm>(formData);
  const onCreate_Option = (formElement: IFormElement, option: IOption): void => {
    const editFormHelper = new EditFormHelper();
    const Form = editFormHelper.updateElementDataSourceHist(
      form,
      formElement,
      option
    );
    setForm(Form);
  };

  const onFormChange = async (element: IFormElementUpdate): Promise<void> => {
    const editFormHelper = new EditFormHelper();
    const Form = await editFormHelper.updateForminHist(form, element);
    setLoading(false);
    setForm(Form);
  };

  useEffect(() => {
    setForm(formData);
  }, []);

  return (
    <>
      <div
        className={classes.sideBarContainer}
        style={{
          width: width || 450,
        }}
        role="presentation"
      >
        <div className={classes.sidebarHeader}>
          <div className={classes.sideBarTitle}>{formTitle}</div>
          <IconClose onClick={onCancel} title="" />
        </div>
        <FormBuilder
          form={form}
          onChange={onFormChange}
          onCreateOption={onCreate_Option}
        />
      </div>
      <div
        className={classes.actionContainer}
        style={{
          width: width || 424,
        }}
      >
        <div>
          <Button
            color="primary"
            className={classes.resetBtn}
            onClick={onReset}

          >
            {resetText}
          </Button>
        </div>
        <UIOutlineButton
          isOutline={true}
          onClick={onCancel}
          btnText={btnCancelText}
          cssStyle={{
            marginRight: "0.75rem",
          }}
        />
        <Button
          variant="contained"
          color="primary"
          disabled={!form.isValid}
          onClick={() => {
            onSave(form);
          }}
        >
          {btnSaveText}
        </Button>
      </div>
      {loading && <CircularProgress />}
    </>
  );
};
