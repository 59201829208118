import * as _ from "lodash";
import { ELEMENT_TYPES } from "../../configuration";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IFormElementUpdate } from "../../Interfaces/Form/FormElement/IFormElementUpdate";
import { IForm } from "../../Interfaces/Form/IForm";
import { IFormGroup } from "../../Interfaces/Form/IFormGroup";
import { IOption } from "../../../../../../../UI/Elements/Forms/interface/IOption";

// Form element helper class
export class FormElementHelper {

    findDependentEle = (forms: IForm, element: IFormElementUpdate): any => {//NOSONAR
    return  {
            ...forms,
            groups: forms.groups.map((formGroup: IFormGroup) => {//NOSONAR
                return {
                    ...formGroup,
                    elements: formGroup.elements.map((formElement: IFormElement) => {//NOSONAR
                        let isVisible :boolean;
                        if (!_.isEqual(formElement.configuration.dependsOn.indexOf(element.formElement.id), -1)) {//NOSONAR
                            let elementValue = this.getElementValue({//NOSONAR
                                ...element.formElement,
                                value: element.value
                            });
                            elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase();//NOSONAR
                            const Dependentvalues = _.toLower(formElement.configuration.dependentValue || "").toString().trim();//NOSONAR
                            if (_.isEqual(elementValue.length, 0)) {//NOSONAR
                                isVisible = false;
                            } else {
                                isVisible = !_.isEqual(Dependentvalues.indexOf(elementValue), -1);//NOSONAR
                            }
                            return {
                                ...formElement,
                                visible: isVisible
                            };
                        }
                        return {
                            ...formElement
                        };

                    })
                };

            })
        };
        
    }

    /**
             * Find Related elements based on parent element
             *
             * @param {IForm[]} forms.
             * @param {IElementUpdate} element.
             * @return any.
             */
    findRelatedParentElements = (forms: IForm[]): any => {//NOSONAR

        const Forms = forms;

        for (let iForm = 0; iForm < Forms.length; iForm++) { //NOSONAR 
            const Groups = Forms[iForm].groups;
            for (let iFormGroup = 0; iFormGroup < Groups.length; iFormGroup++) { //NOSONAR 
                const Elements = Groups[iFormGroup].elements;
                for (let iFormElement = 0; iFormElement < Elements.length; iFormElement++) { //NOSONAR 
                    const formElement = Elements[iFormElement];
                    let isVisible = formElement.visible;

                    const DependsOn: string[] = formElement.configuration.dependsOn;//NOSONAR

                    if (!_.isEmpty(DependsOn)) { //NOSONAR 
                        DependsOn.forEach((elementId: string) => {
                            const FormElement = this.findValuebyId(forms, elementId);
                            if (!isVisible && FormElement.visible) { //NOSONAR 
                                let elementValue = this.getElementValue({
                                    ...FormElement,
                                    value: FormElement.value
                                });

                                elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase(); //NOSONAR 
                                const Dependentvalues = _.toLower(formElement.configuration.dependentValue || "").toString().trim(); //NOSONAR 
                                if (_.isEqual(elementValue.length, 0)) { //NOSONAR 
                                    isVisible = false;
                                } else {
                                    isVisible = !_.isEqual(Dependentvalues.indexOf(elementValue), -1); //NOSONAR 
                                }
                            } else {
                                isVisible = FormElement.visible;
                            }
                        });

                    }
                    Elements[iFormElement] = {
                        ...Elements[iFormElement],
                        visible: isVisible
                    };
                }
            }
        }

        return Forms;
    }



    /**
     * Find dependents elements based on parent element
     *
     * @param {IForm} forms.
     * @param {IElementUpdate} element.
     * @return any.
     */
    findDependentParentElements = (forms: IForm): any => {//NOSONAR
        const Forms = forms;
        const Groups = Forms.groups;
        for (let iFormGroup = 0; iFormGroup < Groups.length; iFormGroup++) { //NOSONAR
            const Elements = Groups[iFormGroup].elements;
            for (let iFormElement = 0; iFormElement < Elements.length; iFormElement++) {//NOSONAR
                const formElement = Elements[iFormElement];
                let isVisible = formElement.visible;
                const DependsOn: string[] = formElement.configuration.dependsOn;//NOSONAR


                if (!_.isEmpty(DependsOn)) {
                    DependsOn.forEach((elementId: string) => {
                        const FormElement = this.findElementbyId(forms, elementId);
                        if (!isVisible && FormElement.visible) {
                            let elementValue = this.getElementValue({
                                ...FormElement,
                                value: FormElement.value
                            });

                            elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase(); //NOSONAR
                            const Dependentvalues = _.toLower(formElement.configuration.dependentValue || "").toString().trim();//NOSONAR

                            if (_.isEqual(elementValue.length, 0)) {
                                isVisible = false;
                            } else {
                                isVisible = !_.isEqual(Dependentvalues.indexOf(elementValue), -1);//NOSONAR

                            }
                        } else {
                            isVisible = FormElement.visible;

                        }
                    });

                }
                Elements[iFormElement] = {
                    ...Elements[iFormElement],
                    visible: isVisible
                };
            }
        }
        return Forms;
    }

    /**
 * Find disabled elements based on parent element
 *
 * @param {IForm} forms.
 * @param {IElementUpdate} element.
 * @return any.
 */
    findDisabledChildElements = (forms: IForm): any => {

        const Forms = forms;
        const Groups = Forms.groups;
        for (let iFormGroup = 0; iFormGroup < Groups.length; iFormGroup++) { //NOSONAR
            const Elements = Groups[iFormGroup].elements;
            for (let iFormElement = 0; iFormElement < Elements.length; iFormElement++) { //NOSONAR
                const formElement = Elements[iFormElement];

                let isDisabled = formElement.disabled;
                const value = formElement.value;
                const disabledBasedOn: string[] = formElement.configuration.disabledBasedOn;

                if (!_.isEmpty(disabledBasedOn)) {//NOSONAR
                    disabledBasedOn.forEach((elementId: string) => {
                        const FormElement = this.findElementbyId(forms, elementId);

                        const ElementValue = this.getElementValue({
                            ...FormElement,
                            value: FormElement.value
                        });

                        const DisabledValue = formElement.configuration.disabledBasedOnValue;

                        isDisabled = _.isEqual(DisabledValue, ElementValue);
                    });
                }
                Elements[iFormElement] = {
                    ...Elements[iFormElement],
                    value,
                    disabled: isDisabled
                };
            }
        }
        return Forms;
    }

    findDisabledEle = (forms: IForm, element: IFormElementUpdate): any => {//NOSONAR
        const updatedForm = {//NOSONAR
            ...forms,
            groups: forms.groups.map((formGroup: IFormGroup) => { //NOSONAR
                return {
                    ...formGroup,
                    elements: formGroup.elements.map((formElement: IFormElement) => { //NOSONAR
                        let isDisabled = formElement.disabled;
                        if (!_.isEqual(formElement.configuration.disabledBasedOn.indexOf(element.formElement.id), -1)) { //NOSONAR
                            const ElementValue = this.getElementValue({
                                ...element.formElement,
                                value: element.value
                            });
                            const DisabledValue = formElement.configuration.disabledBasedOnValue;
                            isDisabled = _.isEqual(DisabledValue, ElementValue);

                            return {
                                ...formElement,
                                disabled: isDisabled
                            };
                        }
                        return {
                            ...formElement
                        };

                    })
                };

            })
        };
        return updatedForm;//NOSONAR
    }

    /**
     * Get element value
     *
     * @param {IFormElement} element.
     * @return boolean.
     */
    getElementValue = (element: IFormElement): any => {
        if (_.isEmpty(element)) {//NOSONAR
            return null;
        }
        let elementValue = element.value;
        if (_.isEqual(element.type, ELEMENT_TYPES.SELECT_INPUT)) { //NOSONAR
            if (element.configuration.isMulti) {
                elementValue = element.value.map((ele: IOption) => (ele.value)); //NOSONAR
            } else {
                // Single Select
                elementValue = element.value.value;

            }
        }
        return elementValue;
    }

    /**
* Find an element by element id
*
* @param {IForm[]} forms.
* @param {number} elementId.
* @return {IFormElement} formElement.
*/
    findValuebyId = (forms: IForm[], elementId: string): any => {
        for (const form of forms) {
            for (const formGroup of form.groups) {
                for (const formElement of formGroup.elements) {
                    if (_.isEqual(formElement.id, elementId)) {
                        return formElement;
                    }
                }
            }
        }
    }


    /**
* Find an element by element id
*
* @param {IForm} forms.
* @param {number} elementId.
* @return {IFormElement} formElement.
*/
    findElementbyId = (forms: IForm, elementId: string): any => {
        for (const formGroup of forms.groups) {
            for (const formElement of formGroup.elements) {
                if (_.isEqual(formElement.id, elementId)) {
                    return formElement;
                }
            }
        }

    }

}