import * as _ from "lodash";
import { IFormElement } from "../../../../interface/IFormElement";


export const getTextInput = (formElement: IFormElement, value: any): any =>{

	if(formElement.is_email){
		return (formElement.value || "")
		.replaceAll(/,/gi, ";")
		.split(";")
		.filter((email: string) => {
		  const Email = (email || "").toString().trim();
		  return !_.isEqual(Email.length, 0);
		})
		.map((email: string) => {
		  return (email || "").toString().trim();
		}).join(";");
	}
	return value;
};

export const getPasswordInput = (value: any): string => {
	return !_.isEmpty(value) ? "*********" : value;
  };