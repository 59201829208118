import * as _ from "lodash";
import moment from "moment";
import { IElementUpdate } from "../../../interface/IElementUpdate";
import { IForm } from "../../../interface/IForm";
import { IFormElement } from "../../../interface/IFormElement";
import { IFormGroup } from "../../../interface/IFormGroup";
import {
    FormElementHelper
} from "../../Utils/Element";
import { UtilHelper } from "../../Utils";
import {
    UI_ELEMENT_TYPES
} from "../../../../config";
import { DateUtil } from "../../Utils/Date";
import {
    RangeValidation
} from "../Validation/Range";
import {
    LengthValidation
} from "../Validation/Length";

// Date and Days Element Helper
export class EditDateDaysElement{
    private elementHelper: FormElementHelper;
    private utilHelper: UtilHelper;
    private dateUtil: DateUtil;
    private rangeValidation: RangeValidation;
    private lengthValidation: LengthValidation;

    constructor(){
        this.elementHelper = new FormElementHelper();
        this.utilHelper = new UtilHelper();
        this.dateUtil = new DateUtil();
        this.rangeValidation =  new RangeValidation();
        this.lengthValidation = new LengthValidation();
    }

    /**
     * Find the parent and child relationship for dayspickers
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return {IForm[]} Forms.
     */
     public updateFormsForDaysPicker = (forms: IForm[], element: IElementUpdate): IForm[] =>{
         return forms.map((form: IForm) =>{
            return {
                ...form,
                groups: form.groups.map((formGroup: IFormGroup)=>{
                    return {
                        ...formGroup,
                        elements: formGroup.elements.map((formElement: IFormElement)=>{
                            const FormElement = {
                                ...formElement,
                            };
        
                            if(_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.DATETIME_INPUT)){
                                let errorMessage = FormElement.error;
                                if(_.isEqual(FormElement.id, element.formElement.id)){
                                    const ParentElement = this.elementHelper.findFormElement(forms,FormElement.parent_compare_element_id);
                                    const ChildElement = this.elementHelper.findDateChildFormElement(forms, FormElement.id);
                                  
                                    if(!_.isNull(ParentElement)){
                                        const ParentValue = (ParentElement?.value || null);
                                        const ElementValue = element.value || null;
                                       
                                        if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                            const StartDaysAgo = ParentElement?.value;
                                            const EndDaysAgo= element.value;
                                           
                                            if(!this.dateUtil.compareDays(StartDaysAgo, EndDaysAgo)){
                                                errorMessage =  `${FormElement.labelName} should be less or equal to ${ParentElement?.labelName}.`;
                                            }
                                            else{
                                                errorMessage = "";
                                            }
                                        }
                                    }
                                    else if(!_.isNull(ChildElement)){
                                        const ParentValue = (element.value || null);
                                        const ElementValue = ChildElement?.value || null;
                                      
                                        if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                            const StartDaysAgo = element.value;
                                            const EndDaysAgo = ChildElement?.value;
                        
                                            if(!this.dateUtil.compareDays(StartDaysAgo, EndDaysAgo)){
                                                errorMessage =  `${FormElement.labelName} should be greater or equal to ${ChildElement?.labelName}.`;
                                            }
                                            else{
                                                errorMessage = "";
                                            }
                                        }
                                    }
                                }
                                else if(_.isEqual(element.formElement.parent_compare_element_id, FormElement.id)){
                                    const ParentValue = (FormElement?.value || null);
                                    const ElementValue = element.value || null;
                               
                                    if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                        const StartDaysAgo = FormElement?.value;
                                        const EndDaysAgo = element.value;
                                       
                                        if(this.dateUtil.compareDays( StartDaysAgo, EndDaysAgo)){
                                            errorMessage = "";
                                        }
                                    }
                                    else if(!_.isNull(ParentValue) && _.isNull(ElementValue)){
                                        errorMessage = "";
                                    }
                                }
                                else if(_.isEqual(FormElement.parent_compare_element_id, element.formElement.id)){
                                    const ParentValue = (element.value || null);
                                    const ElementValue = FormElement?.value || null;
        
                                    if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                        const StartDaysAgo = element.value;
                                        const EndDaysAgo = FormElement?.value;
                                           
                                        if(this.dateUtil.compareDays(StartDaysAgo, EndDaysAgo)){
                                            errorMessage =  "";
                                        }
                                    }
                                    else if(!_.isNull(ElementValue) && _.isNull(ParentValue)){
                                        errorMessage = "";
                                    }
                                }
        
                                const IsEmptyField = this.utilHelper
                                    .isEmptyElementValue(FormElement, FormElement.value);
        
                                if((!_.isNull(FormElement.max_range) || !_.isNull(FormElement.min_range))
                                       && _.isEmpty(errorMessage)){
                                    errorMessage = this.rangeValidation.validate(FormElement, FormElement.value);
                                }
                                if((!_.isNull(FormElement.max_character) || !_.isNull(FormElement.min_character))
                                       && _.isEmpty(errorMessage)){
                                    errorMessage = this.lengthValidation.validate(FormElement, FormElement.value);
                                }
                                // If the field is empty and required
                                if (IsEmptyField && FormElement.is_required){
                                    errorMessage = `${FormElement.labelName} is required.`;
                                }
        
                                if (IsEmptyField && !FormElement.is_required){
                                    errorMessage = "";
                                }
                                       
                                FormElement.error = errorMessage; 
                                FormElement.isValid = _.isEqual(errorMessage.length, 0);
                            }
        
                            return FormElement;
                        })
                    };
                })
            };
         });
     }

     /**
     * Find the parent and child relationship for numbers 
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return {IForm[]} Forms.
     */
     public updateFormsForNumberRelationship= (forms: IForm[], element: IElementUpdate): IForm[] =>{
        return forms.map((form: IForm) =>{
            return {
                ...form,
                groups: form.groups.map((formGroup: IFormGroup)=>{
                    return {
                        ...formGroup,
                        elements: formGroup.elements.map((formElement: IFormElement)=>{
                            const FormElement = {
                                ...formElement,
                            };
       
                            if(_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
                           && FormElement.is_number){
                                let errorMessage = FormElement.error;
                               
                                if(_.isEqual(FormElement.id, element.formElement.id)){
                                    const ParentElement = this.elementHelper.findFormElement(forms,FormElement.parent_compare_element_id);
                                    const ChildElement = this.elementHelper.findDateChildFormElement(forms, FormElement.id);
                               
                                    if(!_.isNull(ParentElement)){
                                        const Min = (ParentElement?.value || null);
                                        const Max = element.value || null;
                                       
                                        if(!_.isNull(Min) && !_.isNull(Max)){
                                            const MinNumber = parseInt(ParentElement?.value);
                                            const MaxNumber= parseInt(element.value);
                                           
                                            if(_.lt(MaxNumber, MinNumber)){
                                                errorMessage =  `${FormElement.labelName} should be greater or equal to ${ParentElement?.labelName}.`;
                                            }
                                            else{
                                                errorMessage = "";
                                            }
                                        }
                                    }
                                    else if(!_.isNull(ChildElement)){
                                        const ParentValue = (element.value || null);
                                        const ElementValue = ChildElement?.value || null;
                                      
                                        if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                            const MinNumber = parseInt(element.value);
                                            const MaxNumber = parseInt(ChildElement?.value);
                        
                                            if(_.gt(MinNumber, MaxNumber)){
                                                errorMessage =  `${FormElement.labelName} should be less or equal to ${ChildElement?.labelName}.`;
                                            }
                                            else{
                                                errorMessage = "";
                                            }
                                        }
                                    }
                                }
                                else if(_.isEqual(element.formElement.parent_compare_element_id, FormElement.id)){
                                    const ParentValue = (FormElement?.value || null);
                                    const ElementValue = element.value || null;
                            
                               
                                    if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                        const MinValue = parseInt(FormElement?.value);
                                        const MaxValue = parseInt(element.value);
                                        if(_.lte(MinValue, MaxValue)){
                                            errorMessage = "";
                                        }
                                     
                                    }
                                    else if(!_.isNull(ParentValue) && _.isNull(ElementValue)){
                                        errorMessage = "";
                                    }
                                }
                                else if(_.isEqual(FormElement.parent_compare_element_id, element.formElement.id)){
                                    const ParentValue = (element.value || null);
                                    const ElementValue = FormElement?.value || null;
       
                                    if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                        const MinValue = parseInt(element.value);
                                        const MaxValue = parseInt(FormElement?.value);
                                           
                                        if(_.lte(MinValue, MaxValue)){
                                            errorMessage =  "";
                                        }
                                    }
                                    else if(!_.isNull(ElementValue) && _.isNull(ParentValue)){
                                        errorMessage = "";
                                    }
                                }
                       
                                const IsEmptyField = this.utilHelper
                                    .isEmptyElementValue(FormElement, FormElement.value);
       
                                    
       
                                if((!_.isNull(FormElement.max_range) || !_.isNull(FormElement.min_range))
                                       && _.isEmpty(errorMessage)){
                                    errorMessage = this.rangeValidation.validate(FormElement, FormElement.value);
                                }
                                if((!_.isNull(FormElement.max_character) || !_.isNull(FormElement.min_character))
                                       && _.isEmpty(errorMessage)){
                                    errorMessage = this.lengthValidation.validate(FormElement, FormElement.value);
                                }
                                // If the field is empty and required
                                if (IsEmptyField && FormElement.is_required){
                                    errorMessage = `${FormElement.labelName} is required.`;
                                }
       
                                if (IsEmptyField && !FormElement.is_required){
                                    errorMessage = "";
                                }
                                       
                                FormElement.error = errorMessage; 
                                FormElement.isValid = _.isEqual(errorMessage.length, 0);
                            }
        
                            return FormElement;
                        })
                    };
                })
            };
         });
     }

    /**
     * Find the parent and child relationship for datepickers
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return {IForm[]} Forms.
     */
    public updateFormsForDatePicker = (forms: IForm[], element: IElementUpdate): IForm[] =>{
        return forms.map((form: IForm) =>{
            return {
                ...form,
                groups: form.groups.map((formGroup: IFormGroup)=>{
                    return {
                        ...formGroup,
                        elements: formGroup.elements.map((formElement: IFormElement)=>{
                            const FormElement = {
                                ...formElement,
                            };
        
                            if(_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.DATETIME_INPUT)){
                              
                                let errorMessage = FormElement.error;
                                if(_.isEqual(FormElement.id, element.formElement.id)){
                                    const ParentElement = this.elementHelper.findFormElement(forms,FormElement.parent_compare_element_id);
                                    const ChildElement = this.elementHelper.findDateChildFormElement(forms, FormElement.id);
                                   
                                    if(!_.isNull(ParentElement)){
                                        const ParentValue = (ParentElement?.value || null);
                                        const ElementValue = element.value || null;
                                        
                                        if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                            const StartDate = ParentElement?.dateInput;
                                            const EndDate = element.formElement.dateInput;
                                            
                                            if(!this.dateUtil.compareDates(FormElement, StartDate, EndDate)){
                                                errorMessage =  `${FormElement.labelName} should be greater or equal to ${ParentElement?.labelName}.`;
                                            }
                                            else{
                                                errorMessage = "";
                                            }
                                        }
                                    }
                                    else if(!_.isNull(ChildElement)){
                                        const ParentValue = (element.value || null);
                                        const ElementValue = ChildElement?.value || null;
        
                                        if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                            const StartDate = element.formElement.dateInput;
                                            const EndDate = ChildElement?.dateInput;
                                           
                                            if(!this.dateUtil.compareDates(FormElement, StartDate, EndDate)){
                                                errorMessage =  `${FormElement.labelName} should be less or equal to ${ChildElement?.labelName}.`;
                                            }
                                            else{
                                                errorMessage = "";
                                            }
                                        }
                                    }
                                }
                                else if(_.isEqual(element.formElement.parent_compare_element_id, FormElement.id)){
                                    
                                    const ParentValue = (FormElement?.value || null);
                                    const ElementValue = element.value || null;
                                
                                    if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                        const StartDate = FormElement?.dateInput;
                                        const EndDate = element.formElement.dateInput;
                                   
                                        if(this.dateUtil.compareDates(FormElement, StartDate, EndDate)){
                                            errorMessage = "";
                                        }
                                    }
                                    else if(!_.isNull(ParentValue) && _.isNull(ElementValue)){
                                        errorMessage = "";
                                    }
                                }
                                else if(_.isEqual(FormElement.parent_compare_element_id,element.formElement.id)){
                                    const ParentValue = (element.value || null);
                                    const ElementValue = FormElement?.value || null;
                                        
                                    if(!_.isNull(ParentValue) && !_.isNull(ElementValue)){
                                        const StartDate = element.formElement.dateInput;
                                        const EndDate = FormElement?.dateInput;
                                            
                                        if(this.dateUtil.compareDates(FormElement, StartDate, EndDate)){
                                            errorMessage =  "";
                                        }
                                    }
                                    else if(!_.isNull(ElementValue) && _.isNull(ParentValue)){
                                        errorMessage = "";
                                    }
                                }
        
        
                                const IsEmptyField = this.utilHelper
                                    .isEmptyElementValue(FormElement, FormElement.value);
                                
                                // If the field is empty and required
                                if (IsEmptyField && FormElement.is_required){
                                    errorMessage = `${FormElement.labelName} is required.`;
                                }
        
                                const DateFormat = FormElement.show_time ? DateUtil.momentDateTimeFormat : DateUtil.momentDateFormat;
        
                                if (!_.isUndefined(FormElement.input_event_value) 
                                    && !IsEmptyField
                                    && !moment(FormElement.input_event_value, DateFormat, true).isValid()) {
                                    errorMessage = `${FormElement.labelName} is not a valid date.`;
                                }
        
                                FormElement.error = errorMessage; 
                                FormElement.isValid = _.isEqual(errorMessage.length, 0);
                            }
        
                            return FormElement;
                        })
                    };
                })
            };
         });
    }

}