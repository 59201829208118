import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ListItem } from "@material-ui/core";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import style from "./style";
import "./style.css";

// CSS Styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Footer
export const Footer = (): any => {
  const classes = useStyles();

  return (<div className={classes.footer}>
    <div className={classes.footerLinks}>
      <ListItem
        onClick={() => {
          window.open("https://www.groupm.com/privacy-notice/", "_blank");
        }}
        className={classes.listItems}
      >
        Privacy Notice
      </ListItem>
      <ListItem
        onClick={() => {
          window.open("https://www.groupm.com/terms-conditions/", "_blank");
        }}
        className={classes.listItems}
      >
        Terms & Conditions
      </ListItem>
    </div>
  </div>);
};

