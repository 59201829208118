import * as _ from "lodash";
import { GET_TOOL } from "../../../../api/paths";
import { Axios } from "../../../../api/service/Axios";


export const getToolInfo = (searchParams: any) : Promise<any> =>{
	const Params = new URLSearchParams(searchParams);
      const TOOL_VERSION: string = Params.get("version") || "";
      const TOOL_NAME: string = Params.get("name") || "";
      const TOOL_ID: any = _.isNaN(Params.get("id")) ? null : Params.get("id");

      return new Promise((resolve: any, reject: any) => {
		  Axios.get(GET_TOOL(TOOL_ID, TOOL_NAME, TOOL_VERSION))
        .then((response: any) => {
			resolve(response);
        })
        .catch((error: any) => {
			reject(error);
        });
	});
};

export const IsValidURL = (searchParams: any): boolean =>{
    const Params = new URLSearchParams(searchParams);
	const TOOL_VERSION = (Params.get("version") || "").toString();
    const TOOL_NAME = (Params.get("name") || "").toString();
    const TOOL_ID: any = (Params.get("id") || "").toString();

	if(_.isNaN(Number(TOOL_ID))){
		return false;
	}
	const Parameters = [];
	for (const entry of Params.entries()) {
		Parameters.push(entry[0]);
	}

	return !_.isEqual(TOOL_VERSION.length, 0) &&
	!_.isEqual(TOOL_NAME.length, 0) &&
	!_.isEqual(TOOL_ID.length, 0) &&
	!_.isNaN(parseInt(TOOL_ID)) && (_.isEqual(Parameters.length, 3) || _.isEqual(Parameters.length, 4));
};