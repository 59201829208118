import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import style from "./style";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  btnText: string;
  cssStyle?: any;
  onClick?: any;
};

export const BackButton = (props: props): JSX.Element => {
  const classes = useStyles();
  const { btnText, cssStyle, onClick } = props;

  return (
    <div className={classes.container}>
      <Button
        color="primary"
        startIcon={<KeyboardBackspaceIcon />}
        onClick={onClick}
        style={cssStyle}
        className={classes.backButton}
      >
        {btnText}
      </Button>
    </div>
  );
};
