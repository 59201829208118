import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { IForm } from "../../../../interface/IForm";
import { IFormElement } from "../../../../interface/IFormElement";
import { IFormGroup } from "../../../../interface/IFormGroup";
import style from "../style";
import { UtilHelper } from "../../../../helper/Utils";
import { UI_ELEMENT_TYPES } from "../../../../../config";
import { getTextInput } from "./Utility";
import {
  getDefaultElements,
  getChips,
  getFileInput,
  getCheckBoxInput,
  getSelectInput,
  getImageText,
  getNewGridElement,
} from "./Helper";
import { UIThumbnailView } from "./Thumbnail";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  forms: IForm[];
  goToStep: any;
};

const utilHelper = new UtilHelper();

export const ReviewForm = (props: props): JSX.Element | any => {
  const { forms, goToStep } = props;
  const classes = useStyles();

  return forms.map((form: IForm, index: number) => {
    const Groups: any = form.groups
      .filter((group: IFormGroup) => group.isVisible)
      .map((group: IFormGroup) => {
        const Elements: any = group.elements
          .filter((formElement: IFormElement) => formElement.is_visible)
          .map((formElement: IFormElement) => {
            let ElementValue =
              utilHelper.getElementValueForReviewForm(formElement);

            switch (formElement.type_id) {
              case UI_ELEMENT_TYPES.TEXT_INPUT:
                ElementValue = getTextInput(formElement, ElementValue);
                break;
              case UI_ELEMENT_TYPES.FILE_INPUT:
                ElementValue = getFileInput(formElement, ElementValue);
                break;
              case UI_ELEMENT_TYPES.CHECKBOX_INPUT:
                ElementValue = getCheckBoxInput(classes, ElementValue);
                break;
              case UI_ELEMENT_TYPES.SELECT_INPUT:
                ElementValue = getSelectInput(
                  formElement,
                  classes,
                  ElementValue
                );
                break;
              case UI_ELEMENT_TYPES.CHIP:
                ElementValue = getChips(classes, formElement.value);
                break;
              case UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD:
                ElementValue = getImageText(formElement);
                break;
              case UI_ELEMENT_TYPES.THUMBNAIL:
                ElementValue = <UIThumbnailView formElement={formElement} />;
                break;
              case UI_ELEMENT_TYPES.GRID:
                ElementValue = getNewGridElement(formElement);
                break;

              case UI_ELEMENT_TYPES.DOWNLOAD:
                ElementValue = <></>;
                break;
              default:
                ElementValue = getDefaultElements(formElement, ElementValue);
                break;
            }

            if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DOWNLOAD)) {
              return <></>;
            }

            return (
              <div className={classes.formElement} key={formElement.id}>
                {_.isNull(formElement.dynamic_configuration) && (
                  <div className={classes.elementLabel}>
                    {formElement.labelName}
                  </div>
                )}

                <div className={classes.elementValue}>{ElementValue}</div>
              </div>
            );
          });

        return (
          <div className={classes.reviewFormGroup} key={group.id}>
            <div className={classes.formGroupTitle}>{group.title}</div>
            {Elements}
          </div>
        );
      });

    return (
      <div className={classes.reviewForm} key={form.id}>
        <div className={classes.reviewStepContainer}>
          <div className={classes.reviewFormTitle}>{form.title}</div>
          <div
            onClick={() => {
              //Added check to goBack for parent active step for MultiForm
              if (forms[index].parentId) {
                index = forms.findIndex(x => x.id == forms[index].parentId);
              }
              goToStep(index);
            }}
            className={classes.reviewFormStepLink}
          >
            Edit
          </div>
        </div>
        {Groups}
      </div>
    );
  });
};
