import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";
import PreviewGridset from "./PreviewSet";
import { DataGridDialogset } from "./DialogSet";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  errorMessage: string;
  loading:boolean;
  ishavingParent:any;
};

// UIDataGrid Component
export const UIDataGridset = (props: props): React.JSX.Element => {
  const { formElement, onChange, errorMessage,loading,ishavingParent } = props;
  const [showDataGrid, setShowDataGrid] = useState(false);
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {showDataGrid && (
        <DataGridDialogset
          formElement={formElement}
          onCancel={() =>{ 
            setShowDataGrid(false);
            onChange(formElement.dataSource);
          }
          }
          onSaveChanges={(gridData: any) => {
            const UpdatedGridData = gridData.map((data: any) =>
              _.omit(data, ["isNewRow"])
            );
            onChange(UpdatedGridData);
            setShowDataGrid(false);
          }}
        />
      )}
        <PreviewGridset
          formElement={formElement}
          onEditGrid={() => setShowDataGrid(true)}
          enableEdit={formElement?.dataSource && !_.isEqual(formElement.dataSource?.length, 0)  ? true : false}
          errorMessage={errorMessage}
          editHideFromReview={true}
          loading={loading}
          ishavingParent={ishavingParent}
        />
    </div>
  );
};
