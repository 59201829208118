import * as _ from "lodash";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../Interfaces/Form/IForm";
import { IFormGroup } from "../../Interfaces/Form/IFormGroup";


function getScheduleType(Data: any): any {
	if (_.isEqual(Data.type, "Weekly")) {
		if(!_.isNull(Data.schedule_week_day) && !_.isUndefined(Data.schedule_week_day)){
		return Data.schedule_week_day.value;
		}
	}
	else if(_.isEqual(Data.type, "Monthly")){
		if(!_.isNull(Data.schedule_month) && !_.isUndefined(Data.schedule_month)){
			return Data.schedule_month.value;
			}			
	}
	else{
		return null;
	}
}

export const getSubmitPayload = (FormData: any): any => {
	const payload: any = {
		active: FormData.active,
		frequency: {
			type: FormData.type,
			value: getScheduleType(FormData),
		},
		jobName: FormData.job
	};
	if (!_.isNull(FormData.time) && !_.isUndefined(FormData.time)) {
		return {
			...payload,
			frequency: {
				type: FormData.type,
				value: getScheduleType(FormData),
				time: FormData.time.value
			}
		};
	}
	else {
		return { ...payload };
	}
};

export const buildSubmitFormData = (form: IForm): any => {
	const FormData: any = {};
	form.groups.forEach((formGroup: IFormGroup) => {
		formGroup.elements.forEach((formElement: IFormElement) => {
			FormData[formElement.id] = formElement.value;
		});
	});
	return getSubmitPayload(FormData);
};

export const ifHasChildElements = (form: IForm, formElement: IFormElement): boolean => {

	const hasChildElements = false;
	for (const group of form.groups) {
		for (const element of group.elements) {
			if (!_.isEqual(element.configuration.parentElements.indexOf(formElement.id), -1)) {
				return true;
			}
		}
	}
	return hasChildElements;
};




