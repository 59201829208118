import React, { MouseEventHandler, useEffect, useState, useRef } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import style from "./style";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import { DataGridDialogHead } from "./DialogHeader";
import { DataGridEditor } from "./Editor";
import { IFilter } from "./Editor/IFilter";
import { FileValidation } from "../../../Forms/helper/Forms/Validation/File";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onCancel: MouseEventHandler;
  onSaveChanges: any;
  formElement: IFormElement;
};

type state = {
  filter: IFilter;
  rowData: any[];
  isValid: boolean;
};

export const DataGridDialogset = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { formElement, onCancel, onSaveChanges } = props;

  const headerRef: any = useRef(null);

  const [timer, setTimer] = useState<any>(null);
  const [dataGrid, setDataGrid] = useState<state>(() => {
    const fileValidator = new FileValidation();
    const DefaultOrderBy = !_.isNull(formElement.customConfiguration?.grid?.defaultSort) ? formElement.customConfiguration?.grid?.defaultSort : null;
    
    
    return {
      filter: {
        page: 0,
        rowsPerPage: formElement.customConfiguration?.grid?.pageSize || 20,
        search: "",
        orderBy: DefaultOrderBy,
        order: !_.isNull(DefaultOrderBy) ? "asc" : null,
      },
      isValid: fileValidator.isValidGrid(
        formElement?.customConfiguration?.grid?.headerData || [],
        formElement.dataSource
      ),
      rowData: (formElement.dataSource || []).map((data: any) => ({
        ...data,
      })),
    };
  });

  useEffect(() => {
    sortHandler(dataGrid?.filter?.orderBy,dataGrid?.filter?.order);
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const handleChangePage = (event: any, page: number): void => {
    setDataGrid((prevState: any) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        page,
      },
    }));
  };

  const handleChangeRowsPerPage = (rowsPerPage: number): void => {
    setDataGrid((prevState: any) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        rowsPerPage,
      },
    }));
  };
  const functionForAsc = (keyname:string,data:any):any => {

    const lexCompare = (a: any, b: any): any => {
      if(a && b){
        const minLength = Math.min(a.length, b.length);
        for (let i = 0; i < minLength; i++) {
          if (_.isEqual(a[i] , b[i])) continue;
          return a[i].localeCompare(b[i], "en", { caseFirst: "upper" });
        }
        return a.length - b.length;

      }
    };
    const sortedArray =  _.slice(data).sort((item1:any, item2:any) => {
      const a = isNaN(item1[keyname]) ? item1[keyname] : _.toNumber(item1[keyname]);
      const b = isNaN(item2[keyname]) ? item2[keyname] : _.toNumber(item2[keyname]);
      if (_.isEqual(typeof a,typeof b)) {
        return _.isNumber(a) ? a - b : lexCompare(a, b);
      }
      else {
        return _.isNumber(a) ? 1 : -1;
      }
    });
    return sortedArray;
  };

  const functionFordesc = (keyname:string,data:any):any => {

    const lexCompare = (a: any, b: any): any => {
      if(a && b){
      const minLength = Math.min(a.length, b.length);
      for (let i = 0; i < minLength; i++) {
        if (_.isEqual(b[i], a[i])) continue;
        return b[i].localeCompare(a[i], "en", { caseFirst: "upper" });
      }
      return b.length - a.length;
    }
    };
    const sortedArray = _.slice(data).sort((item1:any, item2:any) => {
      const a = isNaN(item1[keyname]) ? item1[keyname] : _.toNumber(item1[keyname]);
      const b = isNaN(item2[keyname]) ? item2[keyname] : _.toNumber(item2[keyname]);
      if (_.isEqual(typeof a,typeof b)) {
        return _.isNumber(a) ? b - a : lexCompare(a, b);
      } else {
        return _.isNumber(a) ? 1 : -1;
      }
    });
    return sortedArray;

  };

  const sortHandler = (orderBy: string, order: any): void => {
    let gridData: any[] = dataGrid.rowData.map((data: any) => ({ ...data }));
    if(order === "asc"){
      gridData =functionForAsc(orderBy,dataGrid.rowData);
    }else{
      gridData =functionFordesc(orderBy,dataGrid.rowData);
    }
    setDataGrid((prevState: any) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        orderBy,
        order,
      },
      rowData: gridData,
    }));
  };

  const getGridData = (gridData: any): any => {
    return gridData.map((row: any) => _.omit(row, ["isNewRow"]));
  };

  const resetNewRowGridData = (): any => {
    setDataGrid((prevState: any) => {
      const GridData = getGridData(prevState.rowData);
      return {
        ...prevState,
        rowData: GridData,
      };
    });
  };

  const searchHandler = (search: string): void => {
    const Searchtext = (search || "").toString();

    setDataGrid((prevState: any) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        search: Searchtext,
      },
    }));
  };

  const handleDeleteRow = (id: string): void => {
    const fileValidator = new FileValidation();
    const GridData = dataGrid.rowData.filter(
      (data: any) => !_.isEqual(id, data.id)
    );
    const headerData:any = formElement.customConfiguration?.grid?.headerData;
    setDataGrid((prevState: any) => ({
      ...prevState,
      rowData: GridData,
      isValid: fileValidator.isValidGrid(
        headerData,
        GridData
      ),
    }));
  };

  const updateDataGrid = (gridData: any, pageSize: number): void => {
    const fileValidator = new FileValidation();
    setDataGrid((prevState: any) => ({
      ...prevState,
      rowData: gridData,
      filter: {
        ...prevState.filter,
        rowsPerPage: pageSize || 20,
        search: "",
        orderBy: null,
        order: null,
      },
      isValid: fileValidator.isValidGrid(
        formElement.customConfiguration?.grid?.headerData || [],
        gridData
      ),
    }));
  };

  const onAddForm = (form: any): void => {
    const  pageSize  = formElement.customConfiguration.grid?.pageSize;
    const fileValidator = new FileValidation();
    const GridData: any[] = getGridData(dataGrid.rowData);
    const UpdatedForm = { ...form, isNewRow: true };

    GridData.unshift(UpdatedForm);
    setDataGrid((prevState: any) => ({
      ...prevState,
      rowData: GridData,
      filter: {
        page: 0,
        rowsPerPage: pageSize || 20,
        search: "",
        orderBy: null,
        order: null,
      },
      isValid: fileValidator.isValidGrid(
        formElement.customConfiguration.grid?.headerData || [],
        GridData
      ),
    }));

    clearTimeout(timer);
    const newRowTimer = setTimeout(() => {
      resetNewRowGridData();
    }, 1500);
    setTimer(newRowTimer);
  };

  const onEditForm = (field: string, value: string, id: string): void => {
    const  pageSize  = formElement?.customConfiguration?.grid?.pageSize || 20;
    const GridData: any[] = dataGrid.rowData.map((data: any) => ({ ...data }));

    const ElementIndex: number = GridData.findIndex((data: any) =>
      _.isEqual(data.id, id)
    );
    const Element = GridData.find((data: any) => _.isEqual(data.id, id));
    Element[field] = value;

    GridData[ElementIndex] = {
      ...Element,
    };
    updateDataGrid(GridData, pageSize);
  };

  return (
    <div
      className={classes.gridContainer}
      onScroll={(e: any): void => {
        const ScrollTop = e.target.scrollTop;
        if (_.gt(ScrollTop, 0)) {
          headerRef.current.style.boxShadow =
            "0px 1px 3px 0px rgb(0 0 0 / 10%), 0px 1px 2px 0px rgb(0 0 0 / 4%)";
          return;
        }
        headerRef.current.style.boxShadow = "none";
      }}
    >
      <DataGridDialogHead
        dataGrid={dataGrid}
        onSaveChanges={onSaveChanges}
        formElement={formElement}
        onCancel={onCancel}
        forwardRef={headerRef}
        isValidGrid={dataGrid.isValid}
      />
      <DataGridEditor
        dataGrid={dataGrid}
        onAddForm={onAddForm}
        onEditForm={onEditForm}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleDeleteRow={handleDeleteRow}
        sortHandler={sortHandler}
        searchHandler={searchHandler}
        formElement={formElement}
      />
    </div>
  );
};
