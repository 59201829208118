import { ELEMENT_TYPES } from "../../configuration";
import * as _ from "lodash";
import { IFormElementValue } from "../../../../UI/Elements/Forms/interface/IFormElementValue";
import { IForm } from "../../Interfaces/Form/IForm";
import { MultiSelectHelper } from "../Forms/SelectInput/MultiSelect";
import { IFormGroup } from "../../Interfaces/Form/IFormGroup";
import { RequiredValidation } from "./Required";
import { LengthValidation } from "./Length";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";

// Validation helper for forms
export class ValidationHelper {
	private requiredValidation: RequiredValidation;
	private lengthValidation: LengthValidation;
	private multiSelectHelper: MultiSelectHelper;

	constructor() {
		this.multiSelectHelper = new MultiSelectHelper();
		this.lengthValidation = new LengthValidation();
		this.requiredValidation = new RequiredValidation();
	}

	/**
	 * Validate forms
	 *
	 * @param {IForm[]} forms.
	 * @param {IElementUpdate} element.
	 * @param {boolean} isDelayed.
	 * @return {string} errorMessages.
	 */
	isValidForm = (form: IForm): IForm => {
		let formIsVisible = false;
		let formIsValid = true;

		return ({
			...form,groups: form.groups.map((form_group: IFormGroup) => {
				let groupIs_Visible = false;
				const Group: IFormGroup = ({
					...form_group,
					elements: form_group.elements.map((formElement: IFormElement) => {
						let isElement_Valid = formElement.isValid;

						if (!formElement.visible) {
							isElement_Valid = true;
						}
						formIsValid = formIsValid && isElement_Valid;
						groupIs_Visible = groupIs_Visible || formElement.visible;

						return {
							...formElement,
						};
					}),
					isVisible: groupIs_Visible
				});
				formIsVisible = formIsVisible || groupIs_Visible;

				return Group;
			}),
			isVisible: formIsVisible,
			isValid: formIsValid
		});
	}

	/**
	 * Validate form element
	 *
	 * @param {IFormElement} formElement.
	 * @param {any} elementValue.
	 * @return {string} errorMessages.
	 */
	validate = (formElement: IFormElement, elementValue: any): string => {
		const Is_EmptyField = this.requiredValidation.isEmptyElementValue(formElement, elementValue);
		let error_Message = "";

		// If the field is empty and not required
		if (Is_EmptyField && !formElement.validations.required) {
			return error_Message;
		}

		// Required field validation
		if (formElement.validations.required) {
			error_Message = this.requiredValidation.validate(formElement,elementValue);
			if (!_.isEqual(error_Message.length, 0)) return error_Message;
		}
		if (_.isEqual(formElement.type, ELEMENT_TYPES.TEXT_INPUT)) {
			if (!_.isUndefined(formElement.validations.len)) {
				error_Message = this.lengthValidation.validate(formElement,
					elementValue);
				if (!_.isEqual(error_Message.length, 0)) return error_Message;
			}
		}

		return error_Message;
	}

	/**
 * Check if the current and previous element value are same or not
 *
 * @param {IFormElement} formElement.
 * @param {IFormElementValue} element.
 * @return {boolean}
 */
	isPreviousAndCurrentValueAreSame = (formElement: IFormElement, element: IFormElementValue): boolean => {
		if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)
			&& formElement.configuration.isMulti) {
			const PrevValue = this.multiSelectHelper.getMultiSelectValues(element.previousValue || []);
			const CurrentValue = this.multiSelectHelper.getMultiSelectValues(element.currentValue || []);
			return _.isEqual(PrevValue, CurrentValue);
		}
		let currentValue = element.currentValue;
		let previous_Value = element.previousValue;

		if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)
			&& !formElement.configuration.isMulti) {
				previous_Value = _.isNull(previous_Value) ? null : previous_Value.value;
			currentValue = _.isNull(currentValue) ? null : currentValue.value;
		}
		currentValue = (currentValue || "").toString().trim();
		previous_Value = (previous_Value || "").toString().trim();

		return _.isEqual(currentValue, previous_Value);
	}
}