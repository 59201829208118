import React from "react";
import { UIErrorOrNoteMessage } from "../../Child/ErrorOrNote";
import UILabel from "../../Child/Label";
import { UIImageTextReadOnlyPlaceholder } from "./Placeholder";
import { IFormElement } from "../../../Forms/interface/IFormElement";

// Props type
type props = {
  formElement: IFormElement;
};

// UIImageTextReadOnlyField Component
export const UIImageTextReadOnlyField = (props: props): JSX.Element => {
  const { formElement } = props;

  if (formElement.hideBasedOnParent) {
    return <></>;
  }

  return (
    <>
      <UILabel formElement={formElement} />
      <UIImageTextReadOnlyPlaceholder formElement={formElement} />
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={formElement.apiError}
      />
    </>
  );
};
