import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import style from "./style";
import UITooltip from "../../../Layout/Elements/Tooltip";

type props = {
  text: string;
  tooltip: string;
};

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const TwoLineText = (props: props): React.JSX.Element => {
  const { tooltip, text } = props;
  const classes = useStyles();
  const cellRef = useRef<HTMLTableCellElement>(null);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    const ResizeEL = (): void => {
      const CellElement = cellRef.current;
      const scrollHeight = CellElement?.scrollHeight;
      const clientHeight = CellElement?.clientHeight;

      setOverflow(_.gt(scrollHeight, clientHeight));
    };
    window.addEventListener("resize", ResizeEL);
    ResizeEL();

    return () => {
      window.removeEventListener("resize", ResizeEL);
    };
  }, []);

  if (overflow) {

    return (

      <UITooltip title={tooltip || ""}>

        <div>

          <div className={classes.textContainerEllipse} ref={cellRef}>

            {text}

          </div>

        </div>
        
      </UITooltip>
    );
  }

  return (
    <div className={classes.textContainer} ref={cellRef}>
      {text}
    </div>
  );
};
