import COLORS from "../../../../Theme/colors";

const style: any = (): any => ({
    error:{
        minHeight: "1.25rem",
        marginTop:"0.25rem",
        color: "#b82533",
        fontSize: "0.75rem",
        width:"100%",
        "word-break":"break-word",
        "font-weight":"normal"
    },
    note:{
        minHeight: "1.25rem",
        marginTop:"0.25rem",
        fontSize:"0.75rem",
        color:"#6a737c",
        "word-break":"break-word",
        "font-weight":"normal",
        width:"100%",
        "& ul, ol":{
            paddingLeft: "1.5rem",
            marginTop: "0.25rem"
        },
        "& a":{
            color:"#005fbe",
            textDecoration:"none"
        }
    },
	noteErrorMargin:{
		marginBottom:"0.3rem !important"
	},
	infoContainer:{
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom:"0.5rem",
		flex:1,
		marginTop:8,
	},
	info:{
		display:"flex",
		alignItems:"center",
		whiteSpace:"nowrap",
		flex:1,
	},
	infoIcon:{
		display:"flex",
		alignItems:"center",
		width:20,
	},
	message:{
		fontSize:"0.875rem",
		color: COLORS.DARK_GRAY,
	},
});

export default style;