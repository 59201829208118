import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import _ from "lodash";
import { v4 as uuid4 } from "uuid";
import { DataGridEditorHeader } from "./Header";
import { GridEditor } from "./Grid";
import { IFormElement } from "../../../../Forms/interface/IFormElement";
import { UI_GRID_CELL_EDITTOR_TYPES } from "../../../../config";
import { IGridHeader } from "../../../../Forms/interface/Grid/IGridHeader";

type props = {
  formElement: IFormElement;
  dataGrid: any;
  searchHandler: any;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  handleDeleteRow: any;
  sortHandler: any;
  onAddForm: any;
  onEditForm: any;
};

function buidState(formElement: IFormElement): {
  isValidForm: boolean;
  formData: any;
} {
  const FormData: any = {};
  let IsValidForm = true;
  const cd  = formElement.customConfiguration?.grid?.headerData || [];

    cd.filter((header: IGridHeader) => !header.hide)
    .forEach((header: IGridHeader) => {
      IsValidForm = IsValidForm && !header.config.required;
      if (_.isEqual(header.type, UI_GRID_CELL_EDITTOR_TYPES.DROPDOWN)) {
        FormData[header.field] = {
          value: null,
          touched: false,
          required: header.config.required,
        };
      } else {
        FormData[header.field] = {
          value: "",
          touched: false,
          required: header.config.required,
        };
      }
    });
  return {
    isValidForm: IsValidForm,
    formData: FormData,
  };
}

export const DataGridEditor = (props: props): React.JSX.Element => {
  const {
    formElement,
    searchHandler,
    handleChangePage,
    handleChangeRowsPerPage,
    handleDeleteRow,
    sortHandler,
    dataGrid,
    onAddForm,
    onEditForm,
  } = props;

  const [form, setForm] = useState(() => buidState(formElement));

  const onAddFormChange = (field: string, value: any): void => {
    let isValidForm = true;
    const Form = {
      ...form,
      formData: {
        ...form.formData,
        [field]: {
          ...form.formData[field],
          touched: true,
          value,
        },
      },
    };
    const FormData = Form.formData;
    for (const key in FormData) {
      const Value = (FormData[key].value || "").toString().trim();
      if (FormData[key].required) {
        isValidForm = isValidForm && !_.isEqual(Value.length, 0);
      }
    }
    setForm((prevState: any) => ({ ...prevState, ...Form, isValidForm }));
  };

  const handleAddForm = (): void => {
    if (!form.isValidForm) {
      const UIForm: any = {};
      const UIFormD = { ...form.formData };
      for (const key in UIFormD) {
        UIForm[key] = {
          ...UIFormD[key],
          touched: true,
        };
      }
      setForm((prevState: any) => ({ ...prevState, formData: { ...UIForm } }));
      return;
    }
    const UIFormData = { ...form.formData };
    const Form: any = {};

    for (const key in UIFormData) {
      Form[key] = UIFormData[key].value;
    }

    const EmptyForm = buidState(formElement);
    setForm(EmptyForm);
    onAddForm({
      id: uuid4(),
      ...Form,
    });
  };

  const onClearForm = (): void => {
    const EmptyForm = buidState(formElement);
    setForm(EmptyForm);
  };
  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <DataGridEditorHeader
        formElement={formElement}
        searchHandler={searchHandler}
        filter={dataGrid.filter}
        onAddForm={handleAddForm}
        isValidForm={form.isValidForm}
        rowData={dataGrid.rowData}
      />
      <GridEditor
        handleChangePage={handleChangePage}
        rowData={dataGrid.rowData}
        filter={dataGrid.filter}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleDeleteRow={handleDeleteRow}
        sortHandler={sortHandler}
        formElement={formElement}
        onAddFormChange={onAddFormChange}
        form={form.formData}
        onClearForm={onClearForm}
        onEditForm={onEditForm}
      />
    </Grid>
  );
};
