import React, { Fragment } from "react";
import classnames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import { ErrorIcon } from "../Icons";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onDelete: any;
  fileName: string;
  fileSize: string;
};

// Display File
export const UIFileComponent = (props: props): JSX.Element => {
  const classes = useStyles();
  const { fileName, fileSize, onDelete } = props;

  return (
    <Fragment>
      <div className={classnames(classes.fileContainer)}>
        <div className={classes.fileInfo}>
          <div className={classes.fileIcon}>{ErrorIcon}</div>
          <div className={classes.fileName}>{fileName}</div>
          <div className={classes.fileSize}>{fileSize}</div>
        </div>
        <div className={classes.fileActionContainer}>
          <div className={classnames(classes.fileAction)} onClick={onDelete}>
            Delete
          </div>
        </div>
      </div>
    </Fragment>
  );
};
