import colors from "../../../../Theme/colors";

const style:any = (): any => ({
    tiktokButtonContainer:{
        display: "flex",
        alignItems: "center",
        backgroundColor: colors.TIKTOK_BTN_BG_COLOR,
        color: colors.WHITE,
        width: 278,
        height: 50,
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 24,
        paddingRight: 24,
        borderRadius: 5,
        cursor: "pointer",
        fontSize: 14,
        justifyContent: "center",
        marginBottom: "20px",
    },
    tiktokButton:{
        marginLeft:8, 
        fontSize: 14
    }  
});

export default style;
