import { Grid, Button, Container } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { FormBuilder } from "../builder";
import { ITool } from "../../interface/ITool";
import { ISignout } from "../../interface/ISignout";
import { UIOutlineButton } from "../../../../Buttons";
import style from "./style";
import {
  ToolHeader,
  ToolInfo,
} from "../../../../../components/Toolkit/Tool/Type/Layout";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  tool: ITool;
  onChange: any;
  onSubmit: any;
  onValidateForms: any;
  handleNewForm: any;
  backBtnTitle: string;
  handleBackButton: any;
  loading: boolean;
  signoutConfig: ISignout;
  ishavingParent: any;
};

export const SinglePageForm = (props: props): JSX.Element => {
  const {
    tool,
    onChange,
    onSubmit,
    onValidateForms,
    handleNewForm,
    backBtnTitle,
    handleBackButton,
    signoutConfig,
    loading,
    ishavingParent,
  } = props;
  const classes = useStyles();

  const [formScreen, setFormScreen] = useState(true);

  const ToolData = {
    data: { ...tool },
  };

  return (
    <Fragment>
      <ToolHeader
        handleBackButton={handleBackButton}
        signoutConfig={signoutConfig}
        backBtnTitle={backBtnTitle}
        {...ToolData}
      />
      <Container maxWidth="lg">
        <Grid container className={classes.toolContentWrapper}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className={classes.infoWrapper}
          >
            {!formScreen && (
              <div className={classes.toolContent}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleNewForm();
                    return setFormScreen(true);
                  }}
                  className={classes.primaryButton}
                >
                  New Form
                </Button>
                <UIOutlineButton
                  cssStyle={{
                    marginLeft: "0.75rem",
                  }}
                  isOutline={true}
                  onClick={() => {
                    handleBackButton({
                      action: "Go back",
                    });
                  }}
                  btnText="Go back"
                />
              </div>
            )}
            {formScreen && (
              <div className={classes.toolContent}>
                <FormBuilder
                  onValidateForms={onValidateForms}
                  form={tool.forms[0]}
                  onChange={onChange}
                  loading={loading}
                  ishavingParent={ishavingParent}
                />
                <div>
                  <Button
                    onClick={() =>
                      onSubmit(tool.forms, (isError: boolean) => {
                        setFormScreen(isError);
                      })
                    }
                    variant="contained"
                    color="primary"
                    disabled={!tool.forms[0].isValid || tool.forms[0].loading}
                  >
                    Save
                  </Button>
                  <UIOutlineButton
                    cssStyle={{
                      marginLeft: "0.75rem",
                    }}
                    isOutline={true}
                    onClick={() => {
                      handleBackButton({
                        action: "Cancel",
                      });
                    }}
                    btnText="Cancel"
                  />
                </div>
              </div>
            )}
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            justifyContent="center"
            className={classes.infoWrapper}
          >
            <ToolInfo {...ToolData} />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};
