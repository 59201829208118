import * as _ from "lodash";
import XLSX from "xlsx";
import { UI_BTN_ACTIONS } from "../../../config";
import { FileValidation } from "../../../Forms/helper/Forms/Validation/File";
import { UtilHelper } from "../../../Forms/helper/Utils";
import { ExcelHelper } from "../../../Forms/helper/Utils/FileUtil";


const handleChange = async (fileEvent: any, props: any): Promise<any> => {
    const Files = Array.from(fileEvent.target.files);
	const { formElement } = props;
    if (_.isEqual(Files.length, 0)) {
      return;
    }
    const File: any = Files[0];
    const fileValidation = new FileValidation();
    const FileError: string = await fileValidation.isValidFileForGrid(
      formElement,
      File
    );

    if (!_.isEmpty(FileError)) {
		updateState(props, {
			action: UI_BTN_ACTIONS.UPLOAD,
			errorMessage: FileError,
			value: [],
	  }, {
		acceptedFiles:[],
		rejectedFiles:[File]
	  });
	  return;
    }

	try{
		const data = await getFileData(File, props);
		updateState(props,{
			action: UI_BTN_ACTIONS.UPLOAD,
			errorMessage: "",
			value: data,
		  }, {
			acceptedFiles: Files,
			rejectedFiles: [],
		  });
	}
	catch(err: any){
		updateState(props,{
			action: UI_BTN_ACTIONS.UPLOAD,
			errorMessage: err.message,
			value: [],
		}, {
			acceptedFiles: [],
			rejectedFiles: Files,
		});
	}
  };

  const getFileData = async (file: any, props:any): Promise<any> =>{
	const data = await fileReader(file);
	const { formElement } = props;
	const Workbook = XLSX.read(data, { type: "binary" });
	const excelHelper = new ExcelHelper();
	const SheetName = Workbook.SheetNames[0];
	const headers = excelHelper.getHeaders(Workbook.Sheets[SheetName]);
	const SheetData = XLSX.utils.sheet_to_json(Workbook.Sheets[SheetName], {
		header: 0,
		defval: "",
	});
			  const fileValidation = new FileValidation();
	  
			  const isValidSchema = fileValidation.isValidExcelFileSchema(
				formElement,
				headers
			  );
	  
			  if (!isValidSchema) {
				  throw new Error("The file schema is not valid. Please use the attached excel template and don't modify the column headers.");
			  }

			  if(_.isEqual(SheetData.length, 0)){
				throw new Error("The file is empty.");
			  }
	  
			  const GridData = await excelHelper.generateGridData(
				SheetData,
				formElement
			  );
	  
			  const AllowedRows =
				formElement.dynamic_configuration.validations.allowedRows || null;
	  
			  const utilHelper = new UtilHelper();

			  if(!_.isNull(AllowedRows) && _.gt(GridData.length, AllowedRows)){
					throw new Error(`Please upload a file up to ${utilHelper.numberToKMB(
						AllowedRows
					  )} records.`);
			  }
			  return GridData;
  };

  const fileReader = (file: any): Promise<any> =>{

	return new Promise((resolve: any, reject: any)=>{
		const reader = new FileReader();
		reader.onload = (event: any) => {
			resolve(event.target.result);
		  };
		  reader.onerror = () => {
			reject(new Error("Unable to read the selected file"));
		  };
		  reader.readAsBinaryString(file);
	});
  };

  const updateState = (props: any, dynamicElement: any, fileValue: any):void =>{
	const { formElement, onChange, formId, formGroupId } = props;
	onChange({
		formId,
		formGroupId,
		formElement,
		dynamicElement: {...dynamicElement},
		value: fileValue,
	  });
  };

  export default {
	handleChange,
	updateState
  };