import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { ConfirmationDialog } from "../Confimation";

export const RouterPrompt = (props: any): any => {
  const { when, onSubmit, onCancel } = props;

  const history = useHistory();
  const unblockRef: any = useRef();
  const bundle = useSelector((state: any) => state.initial.bundle);
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    unblockRef.current = history.block((prompt): any => {
      if (when) {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        window.onbeforeunload = () => true;
        return false;
      }
      window.onbeforeunload = null;
      return true;
    });
    return () => {
      unblockRef.current && unblockRef.current();
      window.onbeforeunload = null;
    };
  }, [history, when]);

  useEffect(() => {
    return () => {
      unblockRef.current && unblockRef.current();
      window.onbeforeunload = null;
    };
  }, []);

  const handleOK = useCallback(async () => {
    if (onSubmit) {
      const canRoute = await Promise.resolve(onSubmit());
      if (canRoute) {
        unblockRef.current();
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onSubmit]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        unblockRef.current();
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <ConfirmationDialog
      onClose={handleOK}
      onSubmit={handleCancel}
      heading={bundle.TOOL_DISCARD_CHANGE_HEADING}
      body={bundle.TOOL_DISCARD_CHANGE_BODY}
      cancelBtnText={bundle.TOOL_DISCARD_CHANGE_CANCEL_BTN}
      submitBtnText={bundle.TOOL_DISCARD_CHANGE_SUBMIT_BTN}
      isDeleteAction={false}
      onCloseDialog={() => {
        setShowPrompt(false);
      }}
    />
  ) : null;
};
