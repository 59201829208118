
import { SubmitFormHelper } from "./Submit";
import _ from "lodash";
import { IGridFilter } from "./../../Interfaces/Filter/IGridFilter";
import { IForm } from "./../../Interfaces/Form/IForm";
import { IFormElement } from "./../../Interfaces/Form/FormElement/IFormElement";
import { UtilHelper } from "./Utility";
import { IFormGroup } from "./../../Interfaces/Form/IFormGroup";
import { NewFormHelper } from "./New";
import { IOption } from "./../../../../UI/Elements/Forms/interface/IOption";
import { ALL_OPTION_VALUE, ELEMENT_TYPES } from "../../configuration";

export class FilterFormHelper {
	private newFormHelper: NewFormHelper;
	private submitFormHelper: SubmitFormHelper;
	private utilHelper: UtilHelper;

	constructor() {
		this.submitFormHelper = new SubmitFormHelper();
		this.newFormHelper = new NewFormHelper();
		this.utilHelper = new UtilHelper();
	}
	public async buildFilterForm(form: IForm, dataSources: any): Promise<IForm> {
		return {
			...form,groups: form.groups.map((formGroup: IFormGroup) => {
				return {
					...formGroup,elements: formGroup.elements.map((formElement: IFormElement) => {
						const element = this.newFormHelper.buildNewFormElement(formElement, dataSources);
						return {
							...element,
							touched: false,
							readOnly: !_.isUndefined(element.configuration.readOnly) ? element.configuration.readOnly.newForm : element.readOnly,
							isValid: element.validations.required ? element.isValid : true,
						};
					}),
				};
			}),
		};
	}

	public getFormData(form: IForm): any {
		const Submit_Data: any = {};
		form.groups.forEach((formGroup: IFormGroup) => {
			formGroup.elements.forEach((formElement: IFormElement) => {
				Submit_Data[formElement.id] = {
					value: this.submitFormHelper.getElementValue(formElement),
					type: formElement.type,
				};
			});
		});
		return Submit_Data;
	}

	public getMultiSelectValue = (formElement: IFormElement): any[] => {
		const ElementValue = formElement.value || [];

		if (formElement.configuration.allowAll) {
			return ElementValue.filter((option: IOption) => !_.isEqual(option.value, ALL_OPTION_VALUE)).map((option: IOption) => option.value);
		}
		return ElementValue.map((option: IOption) => option.value);
	}

	public getFilterSubmitData = (gridFilter: IGridFilter, searchText: string): number => {
		const FilterData: any = {
			pageSize: gridFilter.pageSize,
			search: (searchText || "").toString().trim(),
			pageNumber: gridFilter.pageNumber + 1,
			orderBy: gridFilter.orderBy,
			order: gridFilter.order,
		};

		gridFilter.sidebarFilter!.form.groups.forEach((formGroup: IFormGroup) => {
			formGroup.elements.forEach((formElement: IFormElement) => {
				if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)) {
					FilterData[formElement.id] = this.getSelectSubmitData(formElement);
				} else {
					FilterData[formElement.id] = formElement.value;
				}
			});
		});

		return FilterData;
	}

	


	public getFilterCount = (gridFilter: IGridFilter): number => {
		const Form_Data: any = this.getFormData(gridFilter.sidebarFilter!.form);
		let filterCount = 0;
		for (const key in FormData) {
			const FormElVal = !_.isArray(Form_Data[key].value) ? [] : Form_Data[key].value;
			const Values = FormElVal.map((option: IOption) => option.value);
			if (ELEMENT_TYPES.SELECT_INPUT) {
				filterCount = !_.isEqual(Values.indexOf(ALL_OPTION_VALUE), -1) ? filterCount : filterCount + 1;
			}
		}
		return filterCount;
	}

	private getSelectSubmitData = (formElement: IFormElement): any => {
		if (formElement.configuration.isMulti
			&& !_.isNull(formElement.value)) {
			return this.utilHelper.isAllSelected(formElement.value) ? [] : this.getMultiSelectValue(formElement);
		}
		return _.isNull(formElement.value) ? null : formElement.value;
	}

}
