import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

type props = {
  onResponse: any;
  scope: any;
  fields: any;
  appid: any;
  onRender: any;
};

export const FacebookAuth = (props: props): React.JSX.Element => {
  const { onResponse, fields, scope, appid, onRender } = props;

  return (
    <FacebookLogin
      appId={appid}
      autoLoad={false}
      fields={fields}
      callback={onResponse}
      scope={scope}
      render={onRender}
    />
  );
};
