import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import classNames from "classnames";
import style from "./style";
import * as _ from "lodash";

const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  onClick?: any;
  disabled?: boolean;
  smallIcon: boolean;
  title?: any;
  isFileUpload?: any;
};

// downloadButton Component
export const IconDownload = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onClick, disabled, smallIcon, title, isFileUpload } = props;

  return (
    <Tooltip title={!_.isEmpty(title) ? title : ""} placement="top">
      <span>
        <IconButton
          size={"small"}
          className={smallIcon ? classNames(classes.iconDownloadSmall) : classNames(classes.iconDownload)}
          onClick={
            onClick
          }
          disabled={disabled}
        >
          <svg xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="18"
            height="18"
            className={classNames(disabled && classes.disabledDownloadIcon)}
          >
            <path fill="none" d="M0 0h24v24H0z" /><path d="M4 19h16v-7h2v8a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-8h2v7zM14 9h5l-7 7-7-7h5V3h4v6z" fill={isFileUpload ? "#005FBE" : "rgba(106,115,124,1)"} />
          </svg>
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default IconDownload;
