import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { TablePagination } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GridTable } from "../../Layout/Grid";
import {
  FORM_TITLE,
  NOTIFICATION_TYPE,
  SORT_ORDER,
  PLATFORM_TYPE,
} from "../../configuration";
import { MappingPage } from "../../Layout/Page";
import {
  getGoogleAdsFilterFormConfig,
  GOOGLEADS_GRID_HEADERS,
  UI_AUTH_HEADER_NAMES,
} from "../Configuration/GoogleAds";
import { SidebarComponent } from "../../Layout/Grid/Sidebar";
import style from "./style";
import { CircularProgress } from "../../../../UI/Progress";
import { IGridFilter } from "../../Interfaces/Filter/IGridFilter";
import { IBulkEditFilter } from "../../Interfaces/Filter/IBulkEditFilter";
import { SnackbarAlert } from "../../../../UI/Snackbar";
import { FilterFormHelper } from "../../Layout/Form/Helpers/Forms/Filter";
import { IForm } from "../../Interfaces/Form/IForm";
import { ISidebar } from "../../Interfaces/Filter/IGridSidebar";
import { catchError, catchSuccessMessage } from "../../Utility/ErrorMessage";
import * as MapperHelper from "./Helper";

import { MapperGridHeader } from "./Header";
import { IGridData } from "../../Interfaces/Grid/IGridData";
import { IBulkEditData } from "../../Interfaces/Grid/IBulkEditData";
import { IinitData } from "../../Interfaces/Grid/IinitData";
import { ISelectedData } from "../../Interfaces/Grid/ISelectedData";
import { Axios } from "../../../../api/service/Axios";
import {
  GET_MAPPER_GRID_DATA,
  GET_MAPPER_GRID_INIT_DATA,
  UPDATE_MAPPER_GRID_DATA,
} from "../../../../api/APIPaths/mapping";
import { EditFormHelper } from "../../Layout/Form/Helpers/Forms";
import { IGridHeader } from "../../Interfaces/Grid/IGridHeader";
import { IAccount } from "../interface/IAccount";
import { IStatus } from "../interface/IStatus";
import { IChildAccount } from "../interface/IChildAccount";
import {
  getChildAccountsConfig,
  getGridHeader,
  getEditFormConfiguration,
  getSearchPlaceHolder,
  getDropdownPlaceHolder,
} from "./getConfiguration";
import { IPlatform } from "../interface/IPlatform";
import { BulkEditMode } from "./BulkEditMode";
import { SIGNOUT_BUTTON_USER_INFO_MAPPER } from "../../../../api/paths";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

const DefaultFilter = {
  order: SORT_ORDER.ASC,
  orderBy: "account",
  pageNumber: 0,
  pageSize: 20,
  filterCount: 0,
  header: GOOGLEADS_GRID_HEADERS[1],
};

type props = {
  assignmentId: number;
};

//Mapping Mapper Grid Component
export const MapperGrid = (props: props): React.JSX.Element => {
  //NOSONAR
  const classes = useStyles();
  const [gridData, setGridData] = useState<IGridData>({
    count: 0,
    data: [],
  });

  const [account, setAccount] = useState<IAccount>({
    value: null,
    managerAccounts: [],
    pageTitle: "Account Mapping",
    childAccounts: [],
    userToken: null,
    mappingManager: "",
  });
  const [status, setStatus] = useState<IStatus>({
    value: null,
    statuses: [],
  });
  const [filter, setFilter] = useState<IGridFilter>({
    sidebarFilter: null,
    ...DefaultFilter,
  });

  const [bulkEditfilter, setBulkEditFilter] = useState<IBulkEditFilter>({
    order: SORT_ORDER.ASC,
    orderBy: "account",
    header: GOOGLEADS_GRID_HEADERS[1],
    pageNumber: 0,
    pageSize: 20,
  });

  const [sidebar, setSidebar] = useState<ISidebar | null>(null);
  const [formFilter, setFormFilter] = useState<any>(null);
  const bundle = useSelector((state: any) => state.initial.bundle);
  const [initData, setinitData] = useState<IinitData>({
    data: [],
  });

  const [SelectedData, setSelectedData] = useState<ISelectedData>({
    data: [],
  });

  const [initLoader, setInitLoader] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [gridLoader, setGridLoader] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<any>(null);
  const [mapperHeaderData, setMapperHeaderData] = useState<any>(null);
  const [bulkEditData, setBulkEditData] = useState<IBulkEditData>({
    data: [],
    count: 0,
  });
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });
  const [platform, setPlatform] = useState<IPlatform>();
  const [bulkEditMode, setBulkEditMode] = useState<boolean>(false);
  const [checkedCount, setCheckedCount] = useState<number>(0);

  const getFilterPayload = (
    gridFilter: IGridFilter,
    search: string,
    accountData: IAccount
  ): any => {
    return MapperHelper.getFilterSubmitData(
      gridFilter,
      search || "",
      accountData,
      sidebar?.dataSources || {},
      platform?.platformName
    );
  };

  const setFilterDatasource = async (dataSources: any): Promise<any> => {
    const filterFormHelper = new FilterFormHelper();
    return filterFormHelper.buildFilterForm(formFilter, dataSources);
  };

  const getGridData = async (
    gridFilter: IGridFilter,
    accountData: IAccount
  ): Promise<any> => {
    //NOSONAR
    try {
      const FilterData = getFilterPayload(
        gridFilter,
        gridFilter.search || "", //NOSONAR
        accountData
      );

      const GRID_DATA = await Axios.post(GET_MAPPER_GRID_DATA, FilterData);

      let updatedGridData = MapperHelper.formatGridData(
        GRID_DATA.data,
        platform?.platformName
      );

      const uniqueRecords = _.uniqBy(updatedGridData, "status");
      const uniqueStatuses: any = [];
      uniqueRecords.forEach((record: any) => {
        uniqueStatuses.push({
          label: record.status,
          value: record.status,
        });
      });

      setStatus((prevState: any) => ({
        ...prevState,
        statuses: uniqueStatuses,
      }));

      const SelectedHeader: any = getGridHeader(platform?.platformName);
      updatedGridData = sortData(
        gridFilter.orderBy,
        gridFilter.order,
        SelectedHeader[1],
        updatedGridData
      );

      setGridData({
        data: updatedGridData,
        count: updatedGridData.length,
      });

      if (bulkEditMode) {
        const filterDataByStatus = updatedGridData.filter((record: any) => {
          return _.isEqual(record.status, status.value.value);
        });
        getBulkEditFilterData(filterDataByStatus);
      }
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setGridData({
        data: [],
        count: 0,
      });
    }
  };

  const setAlert = (message: string, variant: NOTIFICATION_TYPE): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const checkedRecordCount = (checkCount: any): any => {
    //NOSONAR
    setCheckedCount(checkCount);
  };

  const setPageNumber = (pageNumber: number): any => {
    //NOSONAR
    setBulkEditFilter((prevState: any) => ({
      ...prevState,
      pageNumber,
    }));
  };

  const cancelBulkEditMode = (): void => {
    //NOSONAR
    setBulkEditMode(false);
    setStatus((prevState: any) => ({
      ...prevState,
      value: null,
    }));
    setBulkEditFilter((prevState: any) => ({
      //NOSONAR
      ...prevState,
      order: SORT_ORDER.ASC,
      orderBy: "account",
      pageNumber: 0,
      pageSize: 20,
    }));
    setSelectedData({
      data: [],
    });
  };

  const getDefaultFilterForm = async (dataSources: any): Promise<any> => {
    //NOSONAR
    const filterFormHelper = new FilterFormHelper();

    return filterFormHelper.buildFilterForm(
      getGoogleAdsFilterFormConfig({}),
      dataSources
    );
  };

  const getFilterData = async (dataSources: any): Promise<any> => {
    const form = await getDefaultFilterForm(dataSources);
    return {
      ...filter.sidebarFilter,
      dataSources,
      form,
      title: FORM_TITLE.NEW,
      open: false,
    };
  };

  const getHeaderKey = (headerName: any): any => {
    //NOSONAR
    let data: any = {};
    switch (headerName) {
      case PLATFORM_TYPE.GOOGLE_ADS:
        data = UI_AUTH_HEADER_NAMES.GOOGLE;
        break;

      case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
        data = UI_AUTH_HEADER_NAMES.FACEBOOK;
        break;
      default:
        data = UI_AUTH_HEADER_NAMES.GOOGLE;
    }
    return data;
  };

  const getuserInfo = async (
    typeName: string,
    userToken: string,
    platformId: string
  ): Promise<any> => {
    const tokenInfo: any = [];
    const info: any = { type: typeName, value: userToken, type_id: platformId };
    tokenInfo.push(info);
    await Axios.post(SIGNOUT_BUTTON_USER_INFO_MAPPER, { token_info: tokenInfo })
      .then((response) => {
        setPlatform((prev: any) => ({
          ...prev,
          userInfo: response.data.message,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    const getInitialData = async (): Promise<void> => {
      //NOSONAR
      setInitLoader(true);
      try {
        const InitData: any = await Axios.get(
          GET_MAPPER_GRID_INIT_DATA(props.assignmentId)
        );

        setMapperHeaderData(InitData.data.headerData);

        setStatus({
          value: null,
          statuses: InitData.data.filterDataSource.status,
        });

        setinitData({
          data: InitData.data.filterDataSource,
        });

        setPlatform({
          platformName: InitData.data.platformName,
          platformId: InitData.data.platformId,
        });
        if(_.isEqual(InitData.data?.platformId, 1)){
          await getuserInfo(InitData.data.typeName, InitData.data.userToken, InitData.data.platformId );
        }
        const header = getGridHeader(InitData.data.platformName);
        setBulkEditFilter((prevState: any) => ({
          ...prevState,
          header: header[1],
        }));

        setFilterData(InitData.data.filterDataSource);

        const SidebarForm = await getFilterData(
          InitData.data.filterDataSource || {}
        );
        setFilter((prevState: any) => ({
          ...prevState,
          sidebarFilter: SidebarForm,
        }));

        setSidebar((prevState: any) => ({
          ...prevState,
          dataSources: InitData.data.formDataSources || {},
        }));

        let managerAccounts = [];
        const headerKey = getHeaderKey(InitData.data.platformName);

        if (
          !_.isNull(InitData.data.userToken) &&
          !_.isNull(InitData.data.accountAPIs)
        ) {
          const Response = await Axios.get(
            InitData.data.accountAPIs.apis.managerAccounts.url,
            {
              headers: {
                [headerKey]: InitData.data.userToken,
              },
            }
          );
          managerAccounts = Response.data;
        }

        const businessAccounts = managerAccounts.map((acc: any) => {
          return {
            business_manager_id: acc.business_manager_id,
            business_manager_name: acc.business_manager_name,
            label: `${acc.label} (${acc.value})`,
            value: acc.value,
          };
        });

        setAccount({
          value: null,
          managerAccounts: _.isEqual(
            InitData.data.platformName,
            PLATFORM_TYPE.GOOGLE_ADS
          )
            ? managerAccounts
            : businessAccounts, //NOSONAR
          pageTitle: InitData.data.platformName,
          accountAPIs: InitData.data.accountAPIs || null,
          childAccounts: [],
          userToken: InitData.data.userToken,
          mappingManager: InitData.data.mappingManager,
          platformId: InitData.data.platformId,
        });

        setInitLoader(false);
      } catch (err: any) {
        const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
        setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        setInitLoader(false);
      }
    };
    getInitialData();
  }, []);

  const sortToLowerCase = (record: any, orderby: any, Sortbykey: any): any => {
    const sortValue = record[orderby][Sortbykey] || "";
    return sortValue.toLowerCase();
  };

  const sortData = (
    orderBy: any,
    order: SORT_ORDER,
    header: IGridHeader,
    data: any
  ): any => {
    let updatedGridData: any[] = [];

    if (!_.isUndefined(header.enableTooltip)) {
      const SortByKey: any = header.enableTooltip.displayKey;
      updatedGridData = _.orderBy(
        data,
        (record) => {
          return sortToLowerCase(record, orderBy, SortByKey);
        },
        [order]
      );
    } else if (!_.isUndefined(header.cellTwoLiner)) {
      const SortByKey: any = header.cellTwoLiner.titleKey;
      updatedGridData = _.orderBy(
        data,
        (record) => {
          return sortToLowerCase(record, orderBy, SortByKey);
        },
        [order]
      );
    } else {
      updatedGridData = _.orderBy(
        data,
        [
          (record) => {
            const sortValue = record[orderBy] || "";
            return sortValue.toLowerCase();
          },
        ],
        [order]
      );
    }

    return updatedGridData;
  };

  const sortHandler = (
    orderBy: any,
    order: SORT_ORDER,
    header: IGridHeader
  ): void => {
    if (bulkEditMode) {
      const UpdatedGridData: any[] = sortData(
        orderBy,
        order,
        header,
        bulkEditData.data
      );
      setBulkEditFilter((prevState: IGridFilter) => ({
        ...prevState,
        order,
        orderBy,
        header,
      }));
      setBulkEditData((prevState: any) => ({
        ...prevState,
        data: UpdatedGridData,
      }));
    } else {
      const UpdatedGridData: any[] = sortData(
        orderBy,
        order,
        header,
        gridData.data
      );

      setFilter((prevState: IGridFilter) => ({
        //NOSONAR
        ...prevState,
        order,
        orderBy,
        header,
      }));
      setGridData((prevState: any) => ({
        ...prevState,
        data: UpdatedGridData,
      }));
    }
  };

  const onSearchChange = async (value: string): Promise<void> => {
    try {
      setLoading(true);

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        search: value,
        pageNumber: 0,
      }));

      const Filter: any = {
        ...filter,
        search: value,
        pageNumber: 0,
      };

      await getGridData(Filter, account);
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onReset = async (): Promise<void> => {
    try {
      setLoading(true);

      const SidebarForm = await getFilterData(initData.data);

      const Filter: any = {
        ...filter,
        sidebarFilter: SidebarForm,
      };
      upDateSideBarForm(SidebarForm);
      await getGridData(Filter, account);
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onCancel = async (): Promise<void> => {
    if (_.gt(filter.filterCount, 0)) {
      const form = await setFilterDatasource(filterData);

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        sidebarFilter: {
          ...prevState.sidebarFilter,
          form: form,
          open: false,
        },
      }));
    } else {
      const SidebarForm = await getFilterData(initData.data);
      upDateSideBarForm(SidebarForm);
    }
  };

  const upDateSideBarForm = (SidebarForm: any): void => {
    setFilter((prevState: any) => ({
      ...prevState,
      pageNumber: 0,
      filterCount: 0,
      sidebarFilter: SidebarForm,
    }));
  };

  const onApply = async (form: IForm): Promise<void> => {
    try {
      setLoading(true);
      const filterHelper = new FilterFormHelper();
      const Filter: any = {
        ...filter,
        sidebarFilter: {
          ...filter.sidebarFilter,
          form,
        },
        pageNumber: 0,
      };

      const FilterCount = filterHelper.getFilterCount(Filter);

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        filterCount: FilterCount,
        sidebarFilter: {
          ...prevState.sidebarFilter,
          open: false,
          form,
        },
        pageNumber: 0,
      }));
      setFormFilter(form);

      await getGridData(Filter, account);
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onSave = async (form: IForm): Promise<void> => {
    if (_.isNull(sidebar)) {
      return;
    }
    let FormData = "";

    FormData = MapperHelper.buildSubmitFormData(
      form,
      sidebar?.formData,
      account,
      props.assignmentId,
      platform?.platformName,
      SelectedData,
      status.value
    );

    setLoading(true);

    try {
      const Response = await Axios.post(UPDATE_MAPPER_GRID_DATA, FormData);

      setSidebar((prevState: any): any => ({
        ...prevState,
        open: false,
      }));

      await getGridData(filter, account);

      const SuccessMessage = catchSuccessMessage(
        Response,
        bundle.MAPPER_GRID_INSERT_UPDATE_SUCCESS_MSG
      );

      setAlert(
        SuccessMessage,
        Response.data.warning
          ? NOTIFICATION_TYPE.WARNING
          : NOTIFICATION_TYPE.SUCCESS
      );
      setCheckedCount(0);
      setLoading(false);
    } catch (err) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onPageChange = async (offset: any, pageNumber: any): Promise<void> => {
    if (bulkEditMode) {
      setBulkEditFilter((prevState: any) => ({
        ...prevState,
        pageNumber,
      }));
      return;
    }
    setFilter((prevState: any) => ({
      ...prevState,
      pageNumber,
    }));
  };

  const onEdit = async (id: number, rowData: any): Promise<void> => {
    setLoading(true);
    try {
      const editFormHelper = new EditFormHelper();
      const FormData: any = MapperHelper.buildFormData(
        rowData,
        account.value,
        platform?.platformName
      );
      const getEditForm: any = getEditFormConfiguration(
        platform?.platformName,
        bulkEditMode
      );
      const form = await editFormHelper.buildEditForm(
        getEditForm,
        sidebar!.dataSources,
        FormData
      );

      setSidebar((prevState: ISidebar | null): any => {
        return {
          ...prevState,
          title: FORM_TITLE.EDIT,
          id,
          open: true,
          form,
          formData: FormData,
        };
      });
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onBulkEdit = async (): Promise<void> => {
    const rowData = null;
    setLoading(true);
    const editFormHelper = new EditFormHelper();
    const FormData: any = MapperHelper.buildFormData(
      rowData,
      account.value,
      platform?.platformName
    );
    const getEditForm: any = getEditFormConfiguration(
      platform?.platformName,
      bulkEditMode
    );
    const form = await editFormHelper.buildEditForm(
      getEditForm,
      sidebar!.dataSources,
      FormData
    );

    setSidebar((prevState: ISidebar | null): any => {
      return {
        ...prevState,
        title: FORM_TITLE.BULKEDIT,
        open: true,
        form,
        formData: FormData,
      };
    });
    setLoading(false);
  };

  const getBulkEditFilterData = (filterDataByStatus: any): void => {
    const bulkData: any = [];
    filterDataByStatus.forEach((row: any, index: number) => {
      const bulkEditRow = {
        ...row,
        id: index,
        isSelected: false,
      };
      bulkData.push(bulkEditRow);
    });
    const header: any = bulkEditfilter.header;
    const updatedGridData = sortData(
      bulkEditfilter.orderBy,
      bulkEditfilter.order,
      header,
      bulkData
    );

    setBulkEditData({
      data: updatedGridData,
      count: updatedGridData.length,
    });
  };

  const onStatusChange = async (selectedOption: any): Promise<void> => {
    if (_.isNull(selectedOption)) {
      return;
    }

    setStatus((prevState: any) => ({
      ...prevState,
      value: selectedOption,
    }));
    setBulkEditMode(true);
    const filterDataByStatus = gridData.data.filter((record: any) => {
      return _.isEqual(record.status, selectedOption.value);
    });
    getBulkEditFilterData(filterDataByStatus);
  };

  const onAccountChange = async (selectedOption: any): Promise<void> => {
    if (_.isNull(selectedOption)) {
      return;
    }
    if (
      !_.isNull(account.value) &&
      _.isEqual(selectedOption.value, account.value.value)
    ) {
      return;
    }
    try {
      setGridLoader(true);
      setAccount((prevState: any) => ({
        ...prevState,
        value: selectedOption,
      }));

      setFilter((prevState: IGridFilter): any => ({
        ...prevState,
        pageNumber: 0,
      }));

      const Filter: any = {
        ...filter,
        pageNumber: 0,
      };
      const headerKey = getHeaderKey(account.pageTitle);
      const ChildAccounts = await Axios.get(
        `${account.accountAPIs?.apis.childAccounts.url}?${account.accountAPIs?.apis.childAccounts.paramKey}=${selectedOption.value}`,
        {
          headers: {
            [headerKey]: account.userToken,
          },
        }
      );
      let childAccounts = ChildAccounts.data || [];
      childAccounts = childAccounts.map((option: IChildAccount) =>
        getChildAccountsConfig(option, platform?.platformName)
      );
      const UpdatedAccount = {
        ...account,
        value: selectedOption,
        childAccounts,
      };

      setAccount((prevState: any) => ({
        ...prevState,
        value: selectedOption,
        childAccounts,
      }));

      await getGridData(Filter, UpdatedAccount);
      setGridLoader(false);
    } catch (err) {
      const errorMessage = catchError(err, bundle.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setGridLoader(false);
    }
  };

  if (initLoader) {
    //NOSONAR
    return <CircularProgress />;
  }

  return (
    <>
      <Helmet>
        <title>Mapping Mapper - {account.pageTitle}</title>
      </Helmet>

      <MappingPage
        platform={platform}
        setAlert={setAlert}
        errormsg={bundle.DEFAULT_ERROR_MESSAGE}
        setInitLoader={setInitLoader}
        mapperHeaderData={mapperHeaderData}
        loading={gridLoader}
        backButtonLabel={bundle.MAPPER_SCREEN_BACK_BUTTON_LABEL}
        bulkEditBackButtonLabel={
          bundle.MAPPER_SCREEN_BULK_EDIT_BACK_BUTTON_LABEL
        }
        bulkEditMode={bulkEditMode}
        exitBulkEditMode={cancelBulkEditMode}
        pageTitle={account.pageTitle}
        recordCount={bulkEditMode ? bulkEditData.count : gridData.count} //NOSONAR
        tabComponent={<></>}
        enableContentBgColor={true}
        hideContent={_.isNull(account.value)}
        header={
          bulkEditMode ? ( //NOSONAR
            <BulkEditMode
              cancelBulkEditMode={cancelBulkEditMode}
              onBulkEdit={onBulkEdit}
              checkedCount={checkedCount}
            />
          ) : (
            <MapperGridHeader
              handleSearchChange={(value: string): void => {
                onSearchChange(value);
              }}
              filterCount={
                _.isUndefined(filter.filterCount) ? 0 : filter.filterCount //NOSONAR
              }
              loading={gridLoader}
              onOpenFilter={async () => {
                setFilter((prevState: IGridFilter): any => ({
                  ...prevState,
                  sidebarFilter: {
                    ...prevState.sidebarFilter,
                    open: true,
                  },
                }));
              }}
              searchPlaceholder={getSearchPlaceHolder(platform?.platformName)}
              searchValue={filter.search || ""} //NOSONAR
              recordsCount={gridData.count}
              onAccountChange={onAccountChange}
              onStatusChange={onStatusChange}
              accountDataSource={account?.managerAccounts || []} //NOSONAR
              statusDataSource={status.statuses || []} //NOSONAR
              accountValue={account?.value}
              statusValue={status.value}
              dropdownPlaceholder={getDropdownPlaceHolder(
                platform?.platformName
              )}
            />
          )
        }
        footer={
          _.isEqual(gridData.data, 0) || //NOSONAR
          gridLoader || //NOSONAR
          _.isNull(account.value) ? ( //NOSONAR
            <span />
          ) : (
            <div className={classes.footerContainer}>
              {bulkEditMode ? ( //NOSONAR
                <TablePagination
                  component="div"
                  count={bulkEditMode ? bulkEditData.count : gridData.count} //NOSONAR
                  labelRowsPerPage=""
                  rowsPerPage={
                    bulkEditMode ? bulkEditfilter.pageSize : filter.pageSize
                  } //NOSONAR
                  page={
                    bulkEditMode ? bulkEditfilter.pageNumber : filter.pageNumber
                  } //NOSONAR
                  onPageChange={onPageChange}
                />
              ) : (
                <TablePagination
                  component="div"
                  count={gridData.count}
                  labelRowsPerPage=""
                  rowsPerPage={filter.pageSize}
                  page={filter.pageNumber}
                  onPageChange={onPageChange}
                />
              )}
            </div>
          )
        }
      >
        {!_.isNull(account.value) && ( //NOSONAR
          <GridTable
            filter={filter}
            bulkEditfilter={bulkEditfilter}
            onEdit={onEdit}
            headers={getGridHeader(platform?.platformName)}
            gridData={
              //NOSONAR
              bulkEditMode //NOSONAR
                ? bulkEditData.data
                : gridData.data.slice(
                    filter.pageNumber * filter.pageSize,
                    filter.pageNumber * filter.pageSize + filter.pageSize
                  )
            }
            sortHandler={sortHandler}
            loading={gridLoader}
            isGridAction={true}
            loadHeader={false}
            bulkEditMode={bulkEditMode}
            checkedRecordCount={checkedRecordCount}
            setSelectedData={setSelectedData}
            snackbar={snackbar}
            setPageNumber={setPageNumber}
          />
        )}
        {!_.isNull(filter.sidebarFilter) && ( //NOSONAR
          <SidebarComponent
            width={424}
            formTitle={"Filters"}
            btnSaveText="Apply"
            btnCancelText="Cancel"
            onSave={onApply}
            onReset={onReset}
            onCancel={onCancel}
            formData={filter.sidebarFilter!.form}
            open={filter.sidebarFilter!.open}
            isFilterForm={true}
          />
        )}
        {!_.isNull(sidebar) && ( //NOSONAR
          <SidebarComponent
            width={624}
            formTitle={sidebar.title}
            btnSaveText="Save"
            btnCancelText="Cancel"
            onSave={onSave}
            onCancel={() => {
              setSidebar((prevState: ISidebar | null): any => ({
                ...prevState,
                open: false,
              }));
            }}
            formData={sidebar.form}
            open={sidebar.open}
            isFilterForm={false}
            bulkEditMode={bulkEditMode}
            checkedCount={checkedCount}
            statusValue={status.value}
          />
        )}
      </MappingPage>
      {
        loading && <CircularProgress /> //NOSONAR
      }
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        onOpenCloseAlert={handleCloseAlert}
      />
    </>
  );
};
