import {
	getResource
} from "../../api/services";
import {
	INITIAL_DATA_REQUEST,
	INITIAL_DATA_SUCCESS,
	INITIAL_DATA_FAILED,
	UPDATE_INITIAL_STATE,
	EMBED_CASE
} from "../types";
import {
	GET_INITIAL_DATA
} from "../../api/paths";

const updateInitialState = (data: any): any => ({ type: UPDATE_INITIAL_STATE, payload: data });

// Get initial data initiate
const initialDataRequest = (): any => ({ type: INITIAL_DATA_REQUEST });

// Get initial data successful
const initialDataSuccess = (data: any): any => ({ type: INITIAL_DATA_SUCCESS, payload: data });

// Get initial data failed
const initialDataFailed = (statusCode: number): any => ({ type: INITIAL_DATA_FAILED, payload: statusCode });

// Get Initial data
export const getInitialData = () => (dispatch: any): any => {
	dispatch(initialDataRequest());
	getResource(dispatch, GET_INITIAL_DATA, (response: any) =>
	{
		dispatch(updateInitialState(response.data.user));
		dispatch(initialDataSuccess(response.data));
	},
	(error: any) => {
		dispatch(initialDataFailed(error.response.status));
	}
	);
};

const initialEmbedurl = (data: any): any => ({ type: EMBED_CASE, isEmbed: data });

// Get Embed url
export const idEmbedurl = (flag:boolean) => (dispatch: any): any => {
	dispatch(initialEmbedurl(flag));
};
