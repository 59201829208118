import React from "react";
import { Input } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import style from "./style";
import commonStyle from "../../../../../sharedStyle";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  placeholder: string;
  element: {
    value: string;
    touched: boolean;
  };
  field: string;
  onFormChange: any;
  isError: boolean;
};

// UIInput Component
export const UITextInput = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { placeholder, element, field, onFormChange, isError } = props;

  const commonClasses = useCommonStyles();
  const Disabled = false;

  return (
    <Input
      type="text"
      id={`id-${field}`}
      autoComplete="false"
      className={classes.inputContainer}
      value={element.value}
      placeholder={placeholder}
      onChange={(event: any) => {
        const Value = event.target.value;
        return onFormChange(field, Value);
      }}
      inputProps={{
        autocomplete: "off",
        style: {
          height: 40,
        },
        className: classNames(classes.input, {
          [commonClasses.disabled]: Disabled,
          [classes.inputElement]: !Disabled && isError,
          [commonClasses.error]: isError,
        }),
      }}
    />
  );
};
