import React from "react";
import { Tooltip, IconButton } from "@material-ui/core";
import * as _ from "lodash";

// Props type
type props = {
  title: any;
};

// UITooltip Component
export const UITooltip = (props: props): React.JSX.Element => {
  const { title } = props;

  if (_.isEqual(title.length, 0)) {
    return <span />;
  }

  return (
    <Tooltip title={title} placement={"top"}>
      <IconButton size="small" aria-label={title}>
        <svg width="14" height="14" viewBox="0 0 48 48">
          <path fill="none" d="M0 0h48v48h-48z" />
          <path
            fill="#6a737c"
            d="M22 34h4v-12h-4v12zm2-30c-11.05 0-20 8.95-20 20s8.95 20 20 20 20-8.95 20-20-8.95-20-20-20zm0 36c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-2-22h4v-4h-4v4z"
          />
        </svg>
      </IconButton>
    </Tooltip>
  );
};

// Default Props
UITooltip.defaultProps = {
  title: "",
};

export default UITooltip;
