import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { UILabel } from "../Child/Label";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
};

// UILabel Component
export const UIDisplayText = (props: props): React.JSX.Element => {
  const { formElement } = props;
  const classes = useStyles();


  return (
    <>
      <UILabel formElement={formElement} />
      <div className={classes.label} >
        {formElement.value}
      </div>
    </>
  );
};
