import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton } from "@material-ui/core";
import style from "./style";
import classNames from "classnames";

const useStyles = makeStyles(() => createStyles(style()));

type IProps = {
  onClick?: any;
  title: string;
};

// Feedback Icon functional component
export const IconFeedback = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { onClick, title } = props;

  return (
    <Tooltip title={title} onClick={onClick}>
      <IconButton
        aria-label={title}
        size={"small"}
        className={classNames(classes.iconButton)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="18"
          height="18"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM7 10v2h2v-2H7zm4 0v2h2v-2h-2zm4 0v2h2v-2h-2z"
            fill="rgba(106,115,124,1)"
          />
        </svg>
      </IconButton>
    </Tooltip>
  );
};
