import { ISearchPlaceholder } from "../../Interfaces/Page/ISearchPlaceholder";

export const searchPlaceHolder: ISearchPlaceholder[] = [
    {
        id: "Google Ads",
        value: "Search by Account"
    },
    {
        id: "Facebook Business Manager",
        value: "Search by Ad Account"
    },
];