import React, { useState } from "react";
import { Input, IconButton, InputAdornment } from "@material-ui/core";
import classNames from "classnames";
import { DateRange } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import * as _ from "lodash";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IFormElement } from "../../Forms/interface/IFormElement";
import style from "./style";
import commonStyle from "../sharedStyle";
import {
  getPropsAndDateFormat,
  getStartAndEndDateRange,
} from "../DatePicker/Utility";
import { DateValidation } from "../../Forms/helper/Forms/Validation/Date";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

const dateValidation = new DateValidation();

// UIDaterangePicker Component
export const UIDateRangePicker = (props: props): JSX.Element => {
  const { formElement, formId, formGroupId, onChange } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const touched = dateValidation.isValidTouchForDateRange(formElement.value);

  const ErrorMessage = touched ? formElement.error : "";
  const [startDate, endDate] = getStartAndEndDateRange(formElement);

  const DateControl = getPropsAndDateFormat(formElement);

  return (
    <div
      style={{
        width: `${formElement.width}%`,
      }}
    >
      <UILabel formElement={formElement} />
      <DatePicker
        selectsRange={true}
        {...DateControl.props}
        dateFormat={DateControl.dateFormat}
        startDate={startDate}
        endDate={endDate}
        placeholderText={formElement.placeholder}
        open={isOpen}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={() => {
          if (_.isNull(startDate) && _.isNull(endDate)) {
            setIsOpen(false);
            return;
          }
          setIsOpen(_.isNull(endDate));
        }}
        customInput={
          <Input
            type="text"
            id={`${formElement.id}`}
            className={classNames(classes.input, {
              [commonClasses.disabled]: formElement.disabled,
              [classes.inputElement]:
                !formElement.disabled && _.isEqual(ErrorMessage.length, 0),
              [commonClasses.error]: !_.isEqual(ErrorMessage.length, 0),
            })}
            value={formElement.value}
            endAdornment={
              <InputAdornment position="end">
                <IconButton className={commonClasses.iconButton} size={"small"}>
                  <DateRange fontSize={"small"} />
                </IconButton>
              </InputAdornment>
            }
          />
        }
        onChange={(selectedRange: any) => {
          if (selectedRange[1]) {
            setIsOpen(false);
          }
          return onChange({
            formId,
            formGroupId,
            formElement,
            value: selectedRange,
          });
        }}
      />
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={formElement.disabled ? "" : ErrorMessage}
      />
    </div>
  );
};

// Default Props
UIDateRangePicker.defaultProps = {
  formElement: {},
};
