
const style: any = (): any => ({
    optional: {
        minHeight: 18,
        color: "rgb(106, 115, 124)",
        fontSize: 12,
        "font-weight": "normal",
        marginLeft: 5,
        display: "inline-block"
    },
    note: {
        color: "#6a737c",
        fontSize: 12,
        fontWeight: "normal" as const,
        padding: "0px 8px",
    },
    label: {
        fontSize: "14px",
        color: "#6A737C",
        fontWeight: 600
    },

});

export default style;