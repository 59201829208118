import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { TableCell, TableHead, TableRow } from "@material-ui/core";
import style from "./style";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  headers: any[];
};

// create table head cells
const createTableHeadCells = (props: props, classes: any): any => {
  const { headers } = props;

  const TableCells = [];
  for (let iCell = 0; iCell < headers.length; iCell++) {
    if (!headers[iCell].hide) {
      TableCells.push(
        <TableCell key={iCell} className={classes.headers}>
          {headers[iCell].headerName}
        </TableCell>
      );
    }
  }
  return TableCells;
};

export default function PreviewHeader(props: any): JSX.Element {
  const classes = useStyles();
  const TableCells = createTableHeadCells(props, classes);

  return (
    <TableHead>
      <TableRow>{TableCells}</TableRow>
    </TableHead>
  );
}
