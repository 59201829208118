import React from "react";
import { TablePagination, Grid } from "@material-ui/core";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import classNames from "classnames";
import _ from "lodash";
import GridHeader from "./GridHeader";
import style from "./style";
import StatusIndicator from "../../../UI/StatusIndicator";
import RowLoader from "./RowLoader";
import UITooltip from "../../../UI/Tooltip";
import GridDownload from "./GridDownload";
import { ShowMoreComponent } from "../Layout/Elements/ShowMore";
import { MiddleTextEllipsis } from "../Layout/Elements/Text/MiddleEllipsis";


const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  data: Array<any>;
  totalCount: number;
  handleChangePage: any;
  filters: any;
  createSortHandler: any;
  loading: boolean;
  onDownloadHandler: any;
  expiryDateCheck: any;
};

interface IRowData {
  executionDate: string;
  executionTime: string;
  status: any;
  toolName: string;
  version: string;
  statusMessage: any;
  jobName: any
  jobId: any,
  executionId: any,
  bucketName: any,
  fileName: any,
  fileExpiryTime: any,
  event: any
}

export default function GridTable(props: props): React.JSX.Element {
  const classes = useStyles();
  const {
    data,
    totalCount,
    handleChangePage,
    filters,
    createSortHandler,
    loading,
    onDownloadHandler,
    expiryDateCheck
  } = props;

  return (
    <>
      {_.gt(totalCount, 0) &&
        <Grid item lg={12} md={12} sm={12} xs={12} className={classes.tableContainer}>
          <div className={classes.tableWrapper}>
            <table className={classes.table}>
              <GridHeader
                order={filters.order}
                orderBy={filters.order_by}
                createSortHandler={createSortHandler}
              />
              <TableBody>
                {!_.isUndefined(data) &&
                  !loading &&
                  data.slice(filters.page_number * filters.record_limit, ((filters.page_number + 1) * filters.record_limit)).map((row: IRowData, index: number) => (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell align="left" className={classes.tableColumn}>
                        <span className={classes.tableCellContent}>
                          {row.executionDate}
                        </span>
                        <span
                          className={classNames(
                            classes.tableCellContent,
                            classes.executedTime
                          )}
                        >
                          {row.executionTime}
                        </span>
                      </TableCell>
                      <TableCell align="left" className={classes.tableColumn}>
                        <StatusIndicator status={row.status} />
                      </TableCell>
                      <TableCell align="left" className={classes.tableColumn}>
                        <div style={{ width: "160px" }}>
                          <MiddleTextEllipsis title={row.toolName} showTooltip={true} />
                        </div>

                        <span className={classes.version}>{row.version}</span>
                      </TableCell>
                      <TableCell align="left" className={classes.tableColumn}>
                        {!_.isNull(row.jobName) ?
                          <UITooltip title={{
                            "Job ID": row.jobId,
                            "Job Name": row.jobName,
                            "Execution ID": row.executionId
                          }} isMulti={true} copy={true}>
                            <span className={classes.tableCellContent}>
                              <div style={{ width: "145px" }}>
                                <MiddleTextEllipsis title={row.jobName} showTooltip={false} />
                                <span className={classes.version} style={{ width: "135px" }}>
                                  <MiddleTextEllipsis title={row.jobId} showTooltip={false} />
                                </span>
                              </div>
                            </span>
                          </UITooltip>
                          :
                          <UITooltip title={"Not Available"} isMulti={false} copy={false}>
                            <span className={classes.tableCellContent}>
                              -NA-
                            </span>
                          </UITooltip>
                        }
                      </TableCell>
                      <TableCell
                        align="left"
                        className={classNames(
                          classes.tableColumn,
                          classes.tableCellContent,
                          classes.statusMessage
                        )}
                      ><ShowMoreComponent key={Date.now()} data={row.statusMessage} /></TableCell>
                      <GridDownload
                        filename={row.fileName}
                        bucketname={row.bucketName}
                        expirydate={row.fileExpiryTime}
                        event={row.event}
                        onDownloadHandler={onDownloadHandler}
                        expiryDateCheck={expiryDateCheck}
                      />
                    </TableRow>
                  ))}
                {loading && <RowLoader cols={6} rows={filters.record_limit} />}
              </TableBody>
            </table>

          </div>
          <TablePagination
            component="div"
            count={totalCount}
            labelRowsPerPage=""
            rowsPerPage={filters.record_limit}
            page={filters.page_number}
            onPageChange={(offset, page) => handleChangePage(offset, page)}
          />
        </Grid>
      }
      {_.lt(totalCount, 1) && (
        <div className={classes.noHistoryMessage}>
          <div className={classes.noHistoryMessageContainer}>
            <div style={{ paddingTop: "45px" }}>No execution history available for selected criteria</div>
          </div>
        </div>
      )}
    </>
  );
}
