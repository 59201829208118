import COLORS from "../../../../Theme/colors";

const style: any = (): any => ({
	uploadText: {
		fontSize: "0.875rem",
		color: COLORS.DARK_GRAY,
		marginTop: 10,
		width: "100%"
	},
	linearProgress: {
		marginTop: 16,
		marginLeft: 20
	},
	fileContainerMargin: {
		marginTop: 20,
	},
	uploadFileTemplate: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
	},
	templateLink: {
		color: "#005fbe",
		fontSize: "14px",
		display: "block",
		textDecoration: "underline",
		fontWeight: 500,
		width: "fit-content",
		paddingTop: "10px",
		marginBottom: "20px"
	},

	note: {
		minHeight: "1.25rem",
		marginTop: "0.25rem",
		fontSize: "0.75rem",
		color: "#6a737c",
		"word-break": "break-all",
		"font-weight": "normal",
		width: "100%",
		"& ul, ol": {
			paddingLeft: "1.5rem",
			marginTop: "0.25rem"
		},
		"& a": {
			color: "#005fbe",
			textDecoration: "none"
		}
	},
	fileHeaderContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flex: 1,
		fontSize: 12,
		fontWeight: 600,
		color: COLORS.GRAY,
		height: 47,
		borderBottom: "1px solid rgba(224, 224, 224, 1)",
		"& div": {
			padding: "11px 0px"
		}
	},
	fileTextbox: {
		margin: "0 10px",
		width: "100%",
		display: "flex"
	},
	fileName: {
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
		flex: "0 0 14rem"
	},
	actionContainer: {
		display: "flex",
		marginRight: 40
	},
	fileUploadDiv: {
		width: "432px",
		height: "131px",
		border: "1px dashed #868E96"
	},
	uploadContentDiv: {
		textAlign: "center"
	},
	fileInsertIcon: {
		fontSize: "30px",
		color: "#6A737C",
		marginTop: "25px"
	},
	allowedFileType: {
		color: "#6a737c",
		fontSize: 10,
		fontWeight: "normal" as const,
		marginTop: 0

	},
	fileSpan: {
		fontSize: "14px",
		color: "#005FBE",
		textDecoration: "underline",
		fontWeight: 500,
		"&:hover": {
			backgroundColor: "transparent",
			textDecoration: "underline",
		},
		"&:disabled": {
			fontSize: "14px",
			color: "#005FBE",
			textDecoration: "underline",
			fontWeight: 500,
		}
	},
	downloadIcon: {
		paddingRight: "5px",
	},
	tooltipIcon: {
		paddingLeft: "4px",
		paddingTop:"10px"
	},
	disableDiv: {
		pointerEvents: "none",
		opacity: 0.5,
		width: "432px",
		height: "131px",
		border: "1px dashed #868E96"
	}
});

export default style;



