import React from "react";
import { FormControl } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { UILabel } from "../Child/Label";

type props = any;

// Toggle button functional component
function UIToggleButtons(props: props): React.JSX.Element {
  const { classes, formElement, handleChange } = props;

  return (
    <div>
      <UILabel formElement={formElement} />
      <FormControl>
        <ToggleButtonGroup
          exclusive={true}
          value={formElement.value}
          onChange={(event, newValue) => handleChange(event, newValue)}
          aria-label="button"
          className={classes.toggleButtonGroup}
        >
          {formElement.configuration.dataSource.map((button: any, i: number) => {
            return (
              <ToggleButton
                value={button}
                key={`${button} - ${i}`}
                aria-label={`${button} + ${i}`}
                className={classes.toggleButton}
                classes={formElement.disabled ? { selected: classes.disabledButton } : { selected: classes.selectedButton }}
                disabled={formElement.disabled}

              >
                {button}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </FormControl>
    </div>
  );
}

export default (withStyles(styles)(UIToggleButtons));





