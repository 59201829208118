import React from "react";
import * as _ from "lodash";

import { Tooltip } from "@material-ui/core";

// Props type
type props = {
  children: any;
  disableTooltip?: boolean;
  title: any;
};

// UITooltip Component
export const UITooltip = (props: props): React.JSX.Element => {
  const { title,
     disableTooltip 
    } = props;

  if (_.isEqual(title.length, 0)) {
    return <span />;
  }

  return (
    <Tooltip
    interactive
    title={title}
    placement={"top"}
    disableHoverListener={disableTooltip}
    >
      {props.children}
    </Tooltip>
  );
};


// Default Props
UITooltip.defaultProps = {
  title: "",
};

export default UITooltip;
