import React from "react";
import * as _ from "lodash";
import { useLocation, Redirect } from "react-router-dom";
import { MapperGrid } from "./Grid";
import { MapperTile } from "./Mapper";

const hasParamaters = (searchParams: any): boolean => {
  const Params = new URLSearchParams(searchParams);
  const Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push(entry[0]);
  }
  return !_.isEqual(Parameters.length, 0);
};

const IsValidURL = (searchParams: any): boolean => {
  const Params = new URLSearchParams(searchParams);
  const AssignmentId = (Params.get("assignmentId") || "").toString();
  const PlatformName = (Params.get("platform") || "").toString();

  if (_.isNaN(Number(AssignmentId))) {
    return false;
  }
  const Parameters = [];
  for (const entry of Params.entries()) {
    Parameters.push(entry[0]);
  }

  return (
    !_.isEqual(PlatformName.length, 0) &&
    !_.isEqual(AssignmentId.length, 0) &&
    !_.isNaN(parseInt(AssignmentId)) &&
    _.isEqual(Parameters.length, 2)
  );
};

const MapperScreen = (): React.JSX.Element => {
  const location = useLocation();

  if (hasParamaters(location.search)) {
    if (IsValidURL(location.search)) {
      const Params = new URLSearchParams(location.search);
      const AssignmentId = (Params.get("assignmentId") || "").toString();
      return <MapperGrid assignmentId={Number(AssignmentId)} />;
    }
    return <Redirect to="/" />;
  }
  return <MapperTile />;
};

export default MapperScreen;
