
import COLORS from "../../../../../UI/Theme/colors";

const style: any = (): any => ({
    userInfo:{
		display:"flex",
		alignItems: "center",
		marginTop:16,
		color: COLORS.DARK_GRAY,
		width:"100%",
		marginLeft:"0.5rem"
	  },
	userInfoName:{
	  fontSize:14,
	  fontWeight: 500,
	  display:"flex",
	  alignItems:"center"
	},
	userInfoEmail:{
	  fontSize:12,
	  marginLeft: 8
	}
});

export default style;