import React, { Fragment, useContext, useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import * as _ from "lodash";
import { Button, Drawer } from "@material-ui/core";
import style from "./style";
import { withErrorHandler } from "../../../HOC";
import { useDispatch, useSelector } from "react-redux";
import { IconClose, IconFilter } from "../../../UI/Icons";
import FormHelper from "../../../helpers/FormHelper";
import withSnackbarComponent from "../../../HOC/withSnackbar";
import FilterOptionContext from "../../Context/FilterOptionContext";
import FormConfig from "./FormConfig";
import { createInputForm } from "../../../UI/helpers.component";
import { updateFilterState } from "../../../store/actions/Automation/FilterState";
import { UserPermissionHelper } from "../../../Layout/Routes/Permissions/Helper";

type Anchor = "top" | "left" | "bottom" | "right";

type hocProps = {
  errorHandlerAsync: any;
  errorAlert: any;
  getFilterOptions: any;
};

type inputProps = {
  filterCount: number;
  setFilterCounter: any;
};

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

const FilterOptionCompnent: any = (
  props: hocProps & inputProps
): React.JSX.Element => {
  const { filterCount, setFilterCounter } = props;

  const classes = useStyles();

  const REDUX_STATE = useSelector((state: any) => ({
    FILTER_OPTION_ERROR: state.filterOptions.error,
    BUNDLE: state.initial.bundle,
    FILTER_OPTIONS: state.filterOptions.filterOptions,
    TOOL_LIST: state.toolList,
    USER_INFO: state.initial.userInfo,
  }));

  const selectedFilter: any = localStorage.getItem("filterCategories");
  const filter = JSON.parse(selectedFilter);
  const [sideBar, setSideBar] = useState({
    right: false,
  });
  const { stateFilter, updateSelectedFilter } = useContext(FilterOptionContext);
  const [filterForm, setFilterForm] = useState(
    FormConfig(
      REDUX_STATE.BUNDLE,
      filter,
      REDUX_STATE.FILTER_OPTIONS,
      false,
      REDUX_STATE.USER_INFO
    )
  );
  const [isFormValid, setIsFormValid] = useState(true);

  const filterState = useSelector((state: any) => state.filter);

  const dispatch = useDispatch();
  useEffect(() => {
    const userPermissionHelper = new UserPermissionHelper();
    if (userPermissionHelper.isOnlyRegularUser(REDUX_STATE.USER_INFO)) {
      const selectFilter: any = localStorage.getItem("filterCategories");
      const Filter = JSON.parse(selectFilter);
      const DATA = {
        ...Filter,
        include_beta: 0,
      };
      const SELECTED_OPTION = { ...stateFilter, ...DATA };

      setFilterCounter(SELECTED_OPTION);

      localStorage.setItem("filterCategories", JSON.stringify(SELECTED_OPTION));
    }
  }, []);

  const toggleDrawer = props.errorHandlerAsync(
    async (anchor: Anchor, open: boolean) => {
      setSideBar({ ...sideBar, [anchor]: open });
      if (open) {
        setFilterForm(
          FormConfig(
            REDUX_STATE.BUNDLE,
            filter,
            REDUX_STATE.FILTER_OPTIONS,
            true,
            REDUX_STATE.USER_INFO
          )
        );
      }
    }
  );

  const handleChange = (
    event: any,
    newValue: any,
    inputIdentifier: string
  ): any => {
    const FORM_HELPER = new FormHelper();
    const UPDATED_FORM: any = { ...filterForm };
    const ELEMENT_VALUE: any = FORM_HELPER.getElementValue(
      UPDATED_FORM,
      inputIdentifier,
      event,
      newValue
    );

    let ELEMENT_IS_VALID = FORM_HELPER.checkInputValidity(
      UPDATED_FORM[inputIdentifier],
      ELEMENT_VALUE
    );

    UPDATED_FORM[inputIdentifier].valid = ELEMENT_IS_VALID.isValid;
    UPDATED_FORM[inputIdentifier].errorMessage = ELEMENT_IS_VALID.errorMessage;
    UPDATED_FORM[inputIdentifier].value = ELEMENT_VALUE;

    if (_.isEqual(inputIdentifier, "platform_id")) {
      const UpdatedValue: any = FORM_HELPER.getUpdatedCheckboxValue(
        UPDATED_FORM[inputIdentifier].elementConfig.data,
        event
      );
      UPDATED_FORM[inputIdentifier].elementConfig.data = UpdatedValue;
      UPDATED_FORM[inputIdentifier].value = UpdatedValue;
      ELEMENT_IS_VALID = FORM_HELPER.checkInputValidity(
        UPDATED_FORM[inputIdentifier],
        UpdatedValue
      );
      UPDATED_FORM[inputIdentifier].valid = ELEMENT_IS_VALID.isValid;
      UPDATED_FORM[inputIdentifier].errorMessage =
        ELEMENT_IS_VALID.errorMessage;
      setIsFormValid(ELEMENT_IS_VALID.isValid);
    }
    UPDATED_FORM[inputIdentifier].touched = true;

    return setFilterForm((prevState: any) => ({
      ...prevState,
      ...UPDATED_FORM,
    }));
  };

  // Onclick of Apply Button
  const onApplyButtonClick = (): void => {
    const FORM_HELPER = new FormHelper();

    const DATA = {
      tooltype_id: filterForm.tooltype_id.value,
      toollabel_id: filterForm.toollabel_id.value ? 2 : 0,
      include_beta: filterForm.include_beta.value ? 1 : 0,
      include_favorite: filterForm.include_favorite.value ? 1 : 0,
      platform_id: FORM_HELPER.getAllCheckedValue(
        filterForm.platform_id.elementConfig.data
      ),
    };
    const SELECTED_OPTION: any = { ...stateFilter, ...DATA };

    setFilterCounter(SELECTED_OPTION);

    localStorage.setItem("filterCategories", JSON.stringify(SELECTED_OPTION));

    updateSelectedFilter(DATA);

    dispatch(updateFilterState(SELECTED_OPTION));

    toggleDrawer("right", false);
  };

  // Onclick of Reset Button
  const onResetButtonClick = (): void => {
    const DATA = {
      tooltype_id: 0,
      toollabel_id: 0,
      include_beta: 0,
      include_favorite: 0,
      platform_id: [0],
    };
    const SELECTED_OPTION: any = { ...stateFilter, ...DATA };
    setFilterCounter(SELECTED_OPTION);

    localStorage.setItem("filterCategories", JSON.stringify(SELECTED_OPTION));

    dispatch(updateFilterState(SELECTED_OPTION));
    updateSelectedFilter(DATA);
    toggleDrawer("right", false);
  };

  const FORM = createInputForm(filterForm, handleChange);

  const FilterOptionForm = (anchor: Anchor): React.JSX.Element => (
    <Fragment>
      <div className={classes.sideBarContainer} role="presentation">
        <div className={classes.closeButton}>
          <IconClose onClick={() => toggleDrawer(anchor, false)} title="" />
        </div>
        <div className={classes.sideBarTitle}>
          {REDUX_STATE.BUNDLE.FILTER_HEADER_TITLE}
        </div>
        {FORM}
      </div>

      <div className={classes.actionContainer}>
        <div>
          <Button
            color="primary"
            className={classes.resetBtn}
            onClick={() => onResetButtonClick()}
          >
            {REDUX_STATE.BUNDLE.RESET_BUTTON_TITLE}
          </Button>
        </div>
        <Button
          variant="outlined"
          style={{ marginRight: 16 }}
          onClick={() => toggleDrawer(anchor, false)}
        >
          {REDUX_STATE.BUNDLE.CANCEL_BUTTON_TITLE}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onApplyButtonClick()}
          disabled={!isFormValid || REDUX_STATE.FILTER_OPTION_ERROR}
        >
          {REDUX_STATE.BUNDLE.APPLY_BUTTON_TITLE}
        </Button>
      </div>
    </Fragment>
  );

  return (
    <Fragment key={"filter"}>
      <Button
        className={classes.filterButton}
        onClick={() => toggleDrawer("right", true)}
        disabled={
          REDUX_STATE.TOOL_LIST.loading || !_.isEmpty(filterState.filter.search)
        }
      >
        <div style={{ opacity: REDUX_STATE.TOOL_LIST.loading ? 0.5 : 1 }}>
          <IconFilter title="" />
        </div>
        {REDUX_STATE.BUNDLE.FILTER_BUTTON_TITLE}
        {!_.isEqual(filterCount, 0) && (
          <>
            <div className={classes.filterDot}>&middot;</div>
            <div>{filterCount}</div>
          </>
        )}
      </Button>
      <Drawer anchor="right" open={sideBar["right"]}>
        {FilterOptionForm("right")}
      </Drawer>
    </Fragment>
  );
};

export default withErrorHandler(withSnackbarComponent(FilterOptionCompnent));
