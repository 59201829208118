import React from "react";
import Select, { components } from "react-select";
import * as _ from "lodash";
import classNames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import { CustomStyles, style } from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";

import { isCloseMenuOnSelect } from "./Utility";
import { getErrorMessage } from "../Dataset/Utility";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

export const UISelect = (props: props): JSX.Element => {
  const { formElement, onChange, formId, formGroupId } = props;
  const classes = useStyles();
  const errorMessage = getErrorMessage(formElement);
  const IsError = !_.isEqual(errorMessage.length, 0);
  const Disabled = formElement.disabled || formElement.read_only;

  const IsHighlightOptionError = !_.isEqual(
    (formElement.highlight_option_error || "").length,
    0
  );

  return (
    <div
      className={classNames({
        [classes.sortingContainer]: formElement.enable_sorting_option,
      })}
    >
      {!formElement.enable_sorting_option ? (
        <UILabel formElement={formElement} />
      ) : (
        <div className={classes.sortingLabel}>
          <UILabel formElement={formElement} />
          <div className={classes.defaultHeightPlaceHolder}></div>
        </div>
      )}
      <div
        className={classNames({
          [classes.width100]: formElement.enable_sorting_option,
        })}
      >
        <span className={classes.width100}>
          <Select
            isMulti={formElement.is_multi}
            closeMenuOnSelect={isCloseMenuOnSelect(formElement)}
            isClearable={!formElement.is_required}
            value={formElement.value}
            isDisabled={Disabled}
            components={{
              DropdownIndicator: (data: any) => {
                return (
                  <components.DropdownIndicator
                    {...data}
                    className="caret-select"
                  />
                );
              },
            }}
            styles={CustomStyles(IsError, Disabled, IsHighlightOptionError)}
            onChange={(selectedOption: any) => {
              return onChange({
                formId,
                formGroupId,
                formElement,
                value: selectedOption,
              });
            }}
            options={formElement.dataSource}
            placeholder={formElement.placeholder}
          />
        </span>
        <UIErrorOrNoteMessage
          formElement={formElement}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
};

// Default Props
UISelect.defaultProps = {
  formElement: {},
};
