import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import style from "./style";
import UITooltip from "../../../Layout/Elements/Tooltip";

type props = {
  text: string;
  tooltip: string;
  disableTooltip: boolean;
};

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const SingleLineText = (props: props): React.JSX.Element => {
  const { tooltip, text, disableTooltip } = props;
  const classes = useStyles();

  if (disableTooltip) {
    return <div className={classes.singleLineTextContainer}>{text}</div>;
  }

  return (
    <UITooltip title={tooltip || ""}>
      <div className={classes.singleLineTextContainer}>{text}</div>
    </UITooltip>
  );
};
