import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import classnames from "classnames";
import style from "../style";
import { IFormElement } from "../../../../../../Forms/interface/IFormElement";
import { IGridHeader } from "../../../../../../Forms/interface/Grid/IGridHeader";
import { IconTrash } from "../../../../../../../Icons";
import { IFilter } from "../../IFilter";
import { DataGridBodyRow } from "./GridRow";
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  formElement: IFormElement;
  rowData: any[];
  filter: IFilter;
  handleDeleteRow: any;
  onEditForm: any;
};

export const DataGridBody = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { formElement, rowData, filter, handleDeleteRow, onEditForm } = props;

  const { rowsPerPage, page, search } = filter;

  let RowData = (rowData || []).map((data: any) => ({
    ...data,
  }));
  if (!_.isEqual(search.length, 0)) {
    RowData = RowData.filter((data: any) => {
      if (_.isEqual(search.length, 0)) {
        return true;
      }
      let isShowRow = false;

      for (const key in data) {
        if (!_.isEqual(key, "id")) {
          isShowRow =
            isShowRow ||
            !_.isEqual(_.toLower(data[key]).indexOf(_.toLower(search)), -1);
        }
      }
      return isShowRow;
    });
  }

  const GridData = (
    _.gt(rowsPerPage, 0)
      ? _.slice(RowData, page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : RowData
  ).map((data: any) => {
    const ddd = formElement.customConfiguration.grid?.headerData || [];
    const GridCells = ddd
      .filter((header: IGridHeader) => !header.hide)
      .map((header: IGridHeader) => {
        return (
          <DataGridBodyRow
            key={header.field}
            header={header}
            data={data}
            onEditForm={onEditForm}
            formElement={formElement}
          />
        );
      });

    GridCells.push(
      <div
        key={data.id}
        className={classnames(
          classes.gridCell,
          classes.gridActionCell,
          classes.deleteIcon
        )}
      >
        <IconTrash
          title={"Remove"}
          onClick={() => handleDeleteRow(data.id)}
          disabled={false}
        ></IconTrash>
      </div>
    );
    return (
      <div
        key={`${data.id}-row`}
        className={classnames(classes.gridRow, {
          [classes.highlightNewRow]: data.isNewRow,
        })}
      >
        {GridCells}
      </div>
    );
  });

  return <div>{GridData}</div>;
};
