import Colors from "../../../../../../UI/Theme/colors";
const style: any = (): any => ({
	root: {
		flexGrow: 1,
	},
	menuGroup: {
        zIndex: 3,
        minWidth: "147px",
        minHeight: "30px",
        marginRight: "50px"
    },
    menuList: {
        marginTop: "10px",
    },
    menuListFirstItem:{
        paddingTop: "10px"
    },
    menuListText:{
        paddingLeft: "5px"
    },
    menuItem: {
        fontSize: "14px",
        color: "#242C34",
        fontWeight: 400,
        paddingLeft:"10px",
        "&:hover": {
            color: `${Colors.DARK_BLUE} !important`,
            backgroundColor: "#f7f8fa !important"
        },
    },
    moreIcon: {
        color: Colors.WHITE,
        height: 24,
        width: 24,
        "&:hover": {
            backgroundColor: "#CFE7FD",
        }
    },
});

export default style;