import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import style from "./style";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  style: any;
};

export const IconPlaceholer = (props: props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.iconPlaceholder} style={{ ...props.style }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="36"
        height="36"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M4.828 21l-.02.02-.021-.02H2.992A.993.993 0 0 1 2 20.007V3.993A1 1 0 0 1 2.992 3h18.016c.548 0 .992.445.992.993v16.014a1 1 0 0 1-.992.993H4.828zM20 15V5H4v14L14 9l6 6zm0 2.828l-6-6L6.828 19H20v-1.172zM8 11a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
          fill="rgba(149,164,166,1)"
        />
      </svg>
    </div>
  );
};
