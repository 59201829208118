import * as _ from "lodash";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import style from "./style";
import CloseIcon from "@material-ui/icons/Close";
import colors from "../../../../../../UI/Theme/colors";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  data: number;
  status: string;
};

export const SwitchElement = (props: props): React.JSX.Element => {
  const { data, status } = props;
  const classes = useStyles();

  if ((_.isEqual(status, "Mapped*") || _.isEqual(status, "Mapped") || _.isEqual(status, "Inactive")) && !_.isEqual(data, 1)) {
    return (
      <div className={classes.switchContainer}>
        <CloseIcon style={{ width: "22px", height: "22px", color: colors.GRAY }} />
      </div>
    );
  }

  if (!_.isEqual(data, 1) && _.isEqual(status, "Unmapped")) {
    return <></>;
  }
  
  if (!_.isEqual(data, 1)) {
    return (
      <div className={classes.switchContainer}>
        <CloseIcon style={{ width: "22px", height: "22px", color: colors.GRAY }} />
      </div>
    );
  }
  return (
    <div className={classes.switchContainer}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="22"
        height="22"
      >
        <path fill="none" d="M0 0h24v24H0z" />
        <path
          d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"
          fill="rgba(0,95,190,1)"
        />
      </svg>
    </div>
  );
};
