import { Theme } from "@material-ui/core";

const style:any = (theme: Theme): any => ({
  success: {
    backgroundColor: "#f0fcf5",
    color: "#25d089",
    fill: "#25d089",
  },
  error: {
    backgroundColor: "#fff2f2",
    color: "#e65d63",
  },
  info: {
    backgroundColor: "#f0f8ff",
    color: "#3a91e8",
  },
  warning: {
    backgroundColor: "#fff5e2",
    color: "#e98734",
  },
  successBorder: {
    borderLeft: "8px solid #25d089",
  },
  errorBorder: {
    borderLeft: "8px solid #e65d63",
  },
  infoBorder: {
    borderLeft: "8px solid #3a91e8",
  },
  warningBorder: {
    borderLeft: "8px solid #e98734",
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  close: {
    padding: "0px 8px",
    position: "absolute" as const,
    right: 0,
  },
  iconIndicator: {
    margin: "0px 16px 0px 13px",
    display: "inline-flex" as const,
  },
  heighlight: {
    height: 55,
    width: 8,
    marginRight: 13,
    borderRadius: "2px 0px 0px 2px",
  },
  message: {
    color: "#242c34",
    overflow: "hidden" as const,
    padding: "13px 40px 13px 0px"
  },
  onClose: {
    animation: "toClose 200ms ease-in-out 0ms !important",
  },
  onOpen: {
    animation: "toOpen 300ms ease-in-out 0ms !important",
  }
});

export default style;
