import React from "react";
import * as _ from "lodash";
import { v4 as uuid } from "uuid";
import { UI_BTN_ACTIONS, UI_DYNAMIC_ELE_TYPES } from "../../config";
import { IconTrash } from "../../../Icons";
import { IElementUpdate } from "../../Forms/interface/IElementUpdate";
import { UISortingDropdown } from "../Sorting";
import { UISelect } from "../Select";
import { getFieldValue } from "./Utility";
import { IOption } from "../../Forms/interface/IOption";
import * as ElementDS from "../../../ElementDS";
import { IFormElement } from "../../Forms/interface/IFormElement";

const buildElement = (classes: any, config: any, props: any): JSX.Element => {
  const {
    IsRequired,
    FieldValue,
    IsDisabled,
    dataSource,
    index,
    SortByValue,
    DynamicConfiguration,
    id,
  } = config;
  const { formId, formGroupId, onChange, formElement } = props;

  return (
    <div className={classes.dynamicElementContainer} key={id}>
      <UISelect
        formId={formId}
        formGroupId={formGroupId}
        onChange={(param: IElementUpdate) => {
          const DynamicElement = {
            ...param,
            dynamicElement: {
              index: index,
              action: UI_BTN_ACTIONS.EDIT,
              type: UI_DYNAMIC_ELE_TYPES.ELEMENT,
              value: param.value,
            },
            sortByValue: SortByValue,
          };
          return onChange(DynamicElement);
        }}
        formElement={{
          ...formElement,
          is_required: IsRequired,
          labelName: _.isEqual(index, 0) ? "Order by" : "...Then by",
          value: FieldValue,
          disabled: IsDisabled,
          touched: formElement.dynamic_fields_values[index].touched,
          error:
            IsRequired && _.isNull(FieldValue)
              ? `${formElement.dynamic_field_label} ${index + 1} is required`
              : "",
          dataSource,
        }}
      />
      <UISortingDropdown
        formId={formId}
        formGroupId={formGroupId}
        onChange={(param: IElementUpdate) => {
          const DynamicElement = {
            ...param,
            dynamicElement: {
              index: index,
              action: UI_BTN_ACTIONS.EDIT,
              type: UI_DYNAMIC_ELE_TYPES.SORT,
              value: FieldValue,
            },
            sortByValue: param.value,
          };
          return onChange(DynamicElement);
        }}
        formElement={{
          ...formElement,
          is_required: !_.isNull(FieldValue) && _.isNull(SortByValue),
          disabled: _.isNull(FieldValue) || IsDisabled,
          labelName: `${formElement.labelName} ${index + 1}`,
          value: SortByValue,
          error:
            !_.isNull(FieldValue) && _.isNull(SortByValue)
              ? "Sort order is required"
              : "",
          placeholder: "Select Order",
        }}
      />
      {IsRequired ? (
        <div className={classes.placeholder}></div>
      ) : (
        <div className={classes.deleteSectionBtn}>
          <IconTrash
            title={DynamicConfiguration.configuration.removeButtonText}
            disabled={false}
            onClick={() => {
              const DynamicElement = {
                formId,
                formGroupId,
                formElement,
                dynamicElement: {
                  action: UI_BTN_ACTIONS.DELETE,
                  index: index,
                },
                value: null,
              };
              return onChange(DynamicElement);
            }}
          ></IconTrash>
        </div>
      )}
    </div>
  );
};

export const buildElements = (
  formElement: IFormElement,
  classes: any,
  props: any,
  config: any
): JSX.Element[] => {
  const { UpdatedDataSource, DynamicConfiguration } = config;
  const Elements = [];
  for (
    let index = 0;
    index < formElement.dynamic_fields_values.length;
    index++
  ) {
    const IsRequired = formElement.dynamic_fields_values[index].required;
    const IsDisabled = formElement.dynamic_fields_values[index].disabled;
    const SortBy = formElement.dynamic_fields_values[index].sortby;

    let SortByValue: any = null;
    if (_.isEqual(SortBy, ElementDS.ascValue)) {
      SortByValue = ElementDS.getASC();
    } else if (_.isEqual(SortBy, ElementDS.descValue)) {
      SortByValue = ElementDS.getDESC();
    }

    let FieldValue: any = getFieldValue(formElement, index);

    if (_.isUndefined(FieldValue) || _.isNull(FieldValue)) {
      FieldValue = null;
      SortByValue = null;
    }

    let DS = UpdatedDataSource.map((option: IOption) => ({ ...option }));
    if (!_.isNull(FieldValue)) {
      DS.push(FieldValue);
    }

    if (!_.isEqual(DS.length, 0)) {
      DS = _.orderBy(DS, ["label"], [ElementDS.ascValue]);
    }

    Elements.push(
      buildElement(
        classes,
        {
          IsRequired,
          FieldValue,
          IsDisabled,
          dataSource: DS,
          index,
          SortByValue,
          DynamicConfiguration,
          id: uuid(),
        },
        props
      )
    );
  }

  return Elements;
};
