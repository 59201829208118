import { Button } from "@material-ui/core";
import React, { Fragment, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { CircularProgress } from "../../UI/Progress";
import { ExcelHelper } from "../../UI/Elements/Forms/helper/Utils/FileUtil";
import UITooltip from "../../UI/Tooltip";

type props = {
  errorAlert: any;
  successAlert: any;
  gridData : any;
};

function Download(props: props): React.JSX.Element {
  const { errorAlert, successAlert, gridData } = props;
  const bundle = useSelector((state: any) => state.initial.bundle);
  const [loading, setLoading] = useState(false);

  const onDownload = (): void => {
    
    setLoading(true);
    try{
      const excelHelper = new ExcelHelper();
      const SheetData = (gridData || []).map((sheet: any) => {

          const gridRowData = {
            "Executed On (UTC)": `${sheet.executionDate} ${sheet.executionTime}`,
            "Status": sheet.status,
            "Tool Name": sheet.toolName,
            "Tool Version": sheet.version,
            "Job ID": sheet.jobId,
            "Job Name": sheet.jobName,
            "Execution ID": sheet.executionId,
            "Message": sheet.statusMessage
          };
          const Message = (gridRowData.Message || "").toString();
        return {
          ...gridRowData,
          Message: Message.replace(/(<([^>]+)>)/gi, ""),
        };
      });

      const FileName = `Automations Portal History ${moment(
        new Date()
      ).format("yyyy_MM_DD_HH_mm")}`;

      excelHelper.export(SheetData, FileName, "Automations Portal History");

      successAlert(bundle.DOWNLOAD_HISTORY_SUCCESS_MESSAGE);
      setLoading(false);
  }
  catch{
        setLoading(false);
        errorAlert("Can't Download File");
      }
  };

  return (
    <Fragment>
        {loading && <CircularProgress />}
        <UITooltip title={"Download .xlsx version of all the Execution History"} isMulti={false} copy={false} customWidth={"150px"}>
        <Button color="primary" variant="contained" onClick={onDownload}>
          Download
        </Button>
      </UITooltip>
    </Fragment>
  );
}

export default Download;
