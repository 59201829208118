import COLORS from "../../UI/Theme/colors";

const style: any = (): any => ({
    toolBaxWrapper: {
        width: "100%", 
        padding: "0px 28px", 
        margin: 0
    },
    cardContainer: {
        margin: 0,
        width: 558,
        height: 244, 
        padding: 32,
        transition: "boxShadow 1s",
    },
    loadAvatar: {
        height: 32,
        width: 32,
        borderRadius: 2,
        marginBottom: 16,
    },
    loadTitle: {
        height: 24,
        width: 240,
        borderRadius: 2,
        marginBottom: 8
    },
    loadVersion: {
        height: 20,
        width: 160,
        borderRadius: 2,
        marginBottom: 20
    },
    loadDescription: {
        height: 56,
        width: "auto",
        borderRadius: 2,
    },
    gradient: {
        backgroundImage: "linear-gradient(90deg, #F3F5F7 0px, #F7F8FA 40px, #F3F5F7 80px)",
        backgroundSize: 600,
        animation: "shine-lines 1.6s infinite linear",
        backgroundColor: COLORS.BASECOLOR
    },
});

export default style;