import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import style from "./style";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const UIHR = (): React.JSX.Element => {
  const classes = useStyles();

  return ( <div className={classes.container}>
    <hr className={classes.hr}/>
  </div>);
};
