import * as _ from "lodash";
import { IForm } from "../../../../interface/IForm";
import { IFormElement } from "../../../../interface/IFormElement";
import { IFormGroup } from "../../../../interface/IFormGroup";
import { UtilHelper } from "../../../Utils";
import { DateUtil } from "../../../Utils/Date";
import { UI_ELEMENT_TYPES } from "../../../../../config";
import { SelectElement } from "../SelectInput";
import { RadioCheckboxElement } from "../RadioCheckbox";
import { BuildFormValidationHelper } from "../Validation";
import { ChipElement } from "../Chip";
import { IGridHeader } from "../../../../interface/Grid/IGridHeader";
import { Axios } from "../../../../../../../api/service/Axios";
import { catchError } from "../../../../../../../helpers/ErrorHandler.component";
import { DateDaysElement } from "../DateDaysPicker";
import { ThumbnailElement } from "../Thumbnail";
import { FormElementHelper } from "../../../Utils/Element";
import { ImageTextHelper } from "../ImageText";
import { DownloadHelper } from "../Download";

// Create form
export class BuildNewFormHelper {
  private utilHelper: UtilHelper;
  private dateUtil: DateUtil;
  private selectElement: SelectElement;
  private thumbnailElement: ThumbnailElement;
  private radioCheckboxElement: RadioCheckboxElement;
  private buildFormValidationHelper: BuildFormValidationHelper;
  private chipElement: ChipElement;
  private dateDaysHelper: DateDaysElement;
  private formElementHelper: FormElementHelper;
  private imageTextHelper: ImageTextHelper;
  private downloadHelper: DownloadHelper;

  constructor() {
    this.buildFormValidationHelper = new BuildFormValidationHelper();
    this.formElementHelper = new FormElementHelper();
    this.utilHelper = new UtilHelper();
    this.dateUtil = new DateUtil();
    this.selectElement = new SelectElement();
    this.radioCheckboxElement = new RadioCheckboxElement();
    this.chipElement = new ChipElement();
    this.dateDaysHelper = new DateDaysElement();
    this.thumbnailElement = new ThumbnailElement();
    this.imageTextHelper = new ImageTextHelper();
    this.downloadHelper = new DownloadHelper();
  }
  /**
   * Get ordered forms
   *
   * @param {IForm[]} form array.
   * @return {IForm[]} form array.
   */
  getOrderedForms = (forms: IForm[]): IForm[] => {
    return _.orderBy(forms, ["display_order"], ["asc"]).map((form: IForm) => ({
      ...form,
      isVisible: true,
      groups: _.orderBy(form.groups, ["display_order"], ["asc"]).map(
        (group: IFormGroup) => ({
          ...group,
          isVisible: true,
          elements: _.orderBy(group.elements, ["display_order"], ["asc"]).map(
            (element: IFormElement) => ({ ...element, apiError: "", error: "" })
          ),
        })
      ),
    }));
  };

  /**
   * Use to build initial form for UI.
   *
   * @param {IForm[]} form array.
   * @return {IForm[]} form array.
   */
  form = async (forms: IForm[]): Promise<any[]> => {
    try {
      const OrderedForms: IForm[] = this.getOrderedForms(forms);
      const Forms: IForm[] = [];
      for (const form of OrderedForms) {
        const FormGroups = [];
        for (const formGroup of form.groups) {
          const Elements = [];

          for (const formElement of formGroup.elements) {
            const ElementValue = (formElement.value || "").toString().trim();
            let Element: IFormElement = {
              ...formElement,
              disabled: _.isEqual(formElement.read_only, 1),
              error: "",
            };

            let DynamicConfiguration = _.isNull(Element.dynamic_configuration)
              ? null
              : JSON.parse(Element.dynamic_configuration);

            const AddDynamicElements = _.isNull(Element.add_dynamic_elements)
              ? null
              : _.isObject(Element.add_dynamic_elements) ? Element.add_dynamic_elements : JSON.parse(Element.add_dynamic_elements);
            switch (formElement.type_id) {
              // Text input
              case UI_ELEMENT_TYPES.TEXT_INPUT:
                Element = this.formTextInput(Element, formElement, ElementValue);
                break;
              // Image Text field
              case UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD:
                Element = await this.imageTextHelper.generateImageTextElement(formElement);
                break;
              case UI_ELEMENT_TYPES.CHECKBOX_INPUT:
              case UI_ELEMENT_TYPES.RADIO_INPUT:
                Element = {
                  ...Element,
                  ...this.radioCheckboxElement.buildRadioOrCheckbox(
                    formElement
                  ),
                };
                break;
              // Switch
              case UI_ELEMENT_TYPES.SWITCH_INPUT: {
                Element = this.formSwitchInput(Element, ElementValue);
                break;
              }
              // Select list
              case UI_ELEMENT_TYPES.SELECT_INPUT:
                {
                  const UpdatedElement = await this.selectElement.generateSelect(
                    formElement
                  );
  
                  Element = {
                    ...UpdatedElement,
                  };
                  break;
                }
              case UI_ELEMENT_TYPES.GRID:
                 {
                const UpdatedElement = await this.selectElement.generateSelect(
                  formElement
                );
                 
                Element = {
                  ...UpdatedElement,
                  value:UpdatedElement.dataSource
                };
                break;
              }
              
              // Thumbnail list
              case UI_ELEMENT_TYPES.THUMBNAIL: {
                const UpdatedElement = await this.thumbnailElement.generateThumbnail(
                  formElement
                );

                Element = {
                  ...UpdatedElement,
                };
                break;
              }
              // Hyperlink Element
              case UI_ELEMENT_TYPES.DOWNLOAD: {
                const UpdatedElement = await this.downloadHelper.generateDownload(formElement);

                Element = {
                  ...UpdatedElement,
                };
                break;
              }
              // Chip Element
              case UI_ELEMENT_TYPES.CHIP: {
                const UpdatedElement = await this.chipElement.generateChip(
                  formElement
                );

                Element = {
                  ...UpdatedElement,
                };
                break;
              }
              // Datepicker
              case UI_ELEMENT_TYPES.DATETIME_INPUT: {
                Element = this.formDatePicker(
                  Element,
                  formElement,
                  ElementValue
                );
                break;
              }
              // Daterange input
              case UI_ELEMENT_TYPES.DATERANGE_INPUT: {
                Element = this.dateDaysHelper.dateRangePicker(Element, formElement);
                break;
              }
              // File input
              case UI_ELEMENT_TYPES.FILE_INPUT:
                if (
                  Element.load_data_to_grid &&
                  !_.isNull(DynamicConfiguration)
                ) {
                  const Data: any = await this.fileInputLoadGrid(
                    Element,
                    DynamicConfiguration
                  );
                  DynamicConfiguration = Data.DynamicConfiguration;
                  Element = Data.Element;
                }
                break;
            }

            Elements.push({
              ...Element,
              dynamic_fields_values: [],
              dynamic_configuration: DynamicConfiguration,
              add_dynamic_elements: AddDynamicElements,
              width: _.isNull(Element.width) ? 100 : Element.width,
              is_visible: formElement.is_visible,
              touched: false,
              read_only: _.isEqual(formElement.read_only, 1),
              hasChildElement: this.formElementHelper.hasChildElements(forms, Element.id)
            });
          }

          FormGroups.push({
            ...formGroup,
            elements: Elements,
          });
        }
        Forms.push({
          ...form,
          groups: FormGroups,
        });
      }
      let UpdatedForms= {...forms};
      UpdatedForms = 
        await this.buildFormValidationHelper.validateConfigurationApiUrl(
          Forms
        );
      UpdatedForms =
        await this.buildFormValidationHelper.validateParentChildRelationship(
          UpdatedForms
        );
      return await this.buildFormValidationHelper.validateBuildForm(UpdatedForms);
    } catch (error: any) {
      console.log(error);
      return [];
    }
  };

  /**
   * Build text input for new form
   *
   * @param {element} IFormElement.
   * @param {formElement} IFormElement.
   * @param {elementValue} any.
   * @return {Element} IFormElement.
   */
  formTextInput = (
    element: IFormElement,
    formElement: IFormElement,
    elementValue: any
  ): IFormElement => {
    let Element = { ...element };
    if (formElement.is_number) {
      const IsValidNumber = this.utilHelper.getValidNumber(elementValue, formElement);

      Element = {
        ...Element,
        value: IsValidNumber,
      };
    } else {
      const ElementVal = _.isEqual(
        (formElement.value || "").toString().trim().length,
        0
      )
        ? ""
        : formElement.value;
      Element = {
        ...Element,
        value: ElementVal,
      };
    }
    return Element;
  };

  /**
  * Build switch input for new form
  *
  * @param {element} IFormElement.
  * @param {elementValue} any.
  * @return {Element} IFormElement.
  */
  formSwitchInput = (element: IFormElement, elementValue: any): IFormElement => {
    let Element = { ...element };

    const ElValue = !_.isEqual((elementValue || "").length, 0)
      ? _.isEqual(elementValue.toLowerCase(), "true")
      : false;

    Element = {
      ...Element,
      value: ElValue,
    };
    return Element;
  };

  /**
  * Build date picker input for new form
  *
  * @param {element} IFormElement.
  * @param {formElement} IFormElement.
  * @param {elementValue} any.
  * @return {Element} IFormElement.
  */
  formDatePicker = (
    element: IFormElement,
    formElement: IFormElement,
    elementValue: any
  ): IFormElement => {
    let Element = { ...element };

    let DateValue = this.dateUtil.formatDateValue(elementValue, formElement);

    if (_.isNull(formElement.dateInput) && !formElement.is_default_value) {
      DateValue = null;
    }

    let MinDate = this.dateUtil.formatDateMinMaxValue(
      Element.min_range,
      formElement
    );
    let MaxDate = this.dateUtil.formatDateMinMaxValue(
      Element.max_range,
      formElement
    );

    DateValue = this.dateUtil.isDateBetweenStartAndEnd(
      formElement,
      DateValue,
      MinDate,
      MaxDate
    )
      ? DateValue
      : null;

    if (Element.is_number) {
      const IsValidNumber = this.utilHelper.getValidNumber(elementValue, formElement);
      DateValue = IsValidNumber;
      MinDate = this.utilHelper.getValidNumber(Element.min_range, formElement);
      MaxDate = this.utilHelper.getValidNumber(Element.max_range, formElement);
    }

    Element = {
      ...Element,
      value: DateValue,
      min_range: MinDate,
      max_range: MaxDate,
    };
    return Element;
  };

  /**
  * Build file input grid for new form
  *
  * @param {element} IFormElement.
  * @param {dynamicConfiguration} any.
  * @return {any} any.
  */
  fileInputLoadGrid = async (
    element: IFormElement,
    dynamicConfiguration: any
  ): Promise<any> => {
    const Element = { ...element };
    const HeaderData: IGridHeader[] = dynamicConfiguration.headerData;

    const UpdatedHeaderData: IGridHeader[] = [];

    for (const header of HeaderData) {
      if (!_.isNull(header.dataSource)) {
        let Header: IGridHeader = { ...header };
        const apiurl:any = Header?.dataSource?.apiURL;
        const lists :any= Header.dataSource?.list;
        if (!_.isNull(header.dataSource?.apiURL)) {
          try {
            const APIURL: any = header.dataSource?.apiURL;
            const Data = await Axios.get(APIURL);
          
            Header = {
              ...Header,
              dataSource: {
                apiURL:apiurl,
                data: Data.data,
                list:lists,
              },
            };
          } catch (error) {
            Header = {
              ...Header,
              dataSource: {
                apiURL:apiurl,
                data: [],
                list:lists ,
              },
            };
            Element.apiError = catchError(error);
          }
        } else {
          Header = {
            ...Header,
            dataSource: {
              apiURL:apiurl ,
                data:lists ,
                list:lists ,
            },
          };
        }

        UpdatedHeaderData.push({ ...Header });
      } else {
        UpdatedHeaderData.push({ ...header });
      }
    }
    const DynamicConfiguration = {
      ...dynamicConfiguration,
      headerData: UpdatedHeaderData,
    };
    return {
      DynamicConfiguration,
      Element,
    };
  };

}
