import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import style from "./style";
import * as _ from "lodash";
import { UIOutlineButton } from "../../../../UI/Buttons";
import { Button } from "@material-ui/core";
import { ArrowRight } from "../../../../UI/Icons/ArrowRight";


// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
    cancelBulkEditMode: any;
    onBulkEdit: any;
    checkedCount: number;
};

//Mapping Mapper Grid Header Component
export const BulkEditMode = (props: props): React.JSX.Element => {
    const classes = useStyles();
    const {
        cancelBulkEditMode,
        onBulkEdit,
        checkedCount
    } = props;
    return (
        <>
            <div className={classes.Container}>
                <div className={classes.headerContainer}>
                    <Button
                        disabled={_.isEqual(checkedCount, 0)}
                        variant="contained"
                        onClick={onBulkEdit}
                        color="primary"
                        style={{
                            marginRight: "0.75rem",
                        }}
                    >
                        <span className={classes.btnContent}>Bulk Edit</span> <ArrowRight />
                    </Button>
                    <UIOutlineButton
                        isOutline={true}
                        onClick={cancelBulkEditMode}
                        btnText="Exit Edit Mode"
                    />

                </div>
                <div className={classes.toolBoxCountText}>{checkedCount} Selected Accounts</div>
            </div>
        </>
    );
};
