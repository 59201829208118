import React from "react";
import { FormControl } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

type props = any;

// Toggle button functional component
function ToggleButtons(props: props): React.JSX.Element {
  const { classes, id, data, handleChange, value, exclusive, loading } = props;

  return (
    <div>
      <FormControl>
        <ToggleButtonGroup
          exclusive={exclusive}
          value={value}
          onChange={(event, newValue) => handleChange(event, newValue, id)}
          aria-label="button"
          className={classes.toggleButtonGroup}
          style={{ opacity: loading ? 0.5 : 1 }}
        >
          {data.map((button: any, i: number) => {

            return (
              <ToggleButton
                value={button.value}
                key={`${button.label} - ${i}`}
                aria-label={`${button.label} + ${i}`}
                className={classes.toggleButton}
                classes={{ selected: classes.selectedButton }}
                disabled={loading}
              >
                {button.label}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </FormControl>
    </div>
  );
}

export default (withStyles(styles)(ToggleButtons));





