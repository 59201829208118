import React, { useRef, useState, useEffect } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import TableCell from "@material-ui/core/TableCell";
import style from "./style";
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  data: any;
};

export default function PreviewBodyset(props: props): React.JSX.Element {
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);
  const cellRef = useRef<HTMLTableCellElement>(null);
  const { data } = props;

  const CellData = (data || "").toString();

  useEffect(() => {
    const ResizeEL = (): void => {
      const CellElement = cellRef.current;
      const OffsetWidth = CellElement?.offsetWidth;
      const ScrollWidth = CellElement?.scrollWidth;

      if (_.lt(OffsetWidth, ScrollWidth)) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    };
    window.addEventListener("resize", ResizeEL);
    ResizeEL();

    return () => {
      window.removeEventListener("resize", ResizeEL);
    };
  }, [CellData.length]);

  if (showTooltip) {
    return (
      <TableCell ref={cellRef} align="left" className={classes.tableCell}>
        <Tooltip title={CellData} placement={"top-start"}>
          <span> {CellData}</span>
        </Tooltip>
      </TableCell>
    );
  }

  return (
    <TableCell ref={cellRef} align="left" className={classes.tableCell}>
      {CellData}
    </TableCell>
  );
}
