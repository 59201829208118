import ReactGA4 from "react-ga4";
import { GOOGLE_ANALYSTICS_ACTIONS } from "./GoogleAnalysticsUtils";

// Add events to Google Analystics
export const GAEvent = (
  category: string | any,
  action: GOOGLE_ANALYSTICS_ACTIONS,
  label?: string
): void => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};
