import * as _ from "lodash";
import { IFormElement } from "../../../interface/IFormElement";
import { UtilHelper } from "../../Utils";

// Validation helper for Range
export class RangeValidation{
    private utilHelper: UtilHelper;

    constructor(){
    	this.utilHelper = new UtilHelper();
    }

    /**
     * Number min and max range validation
     *
     * @param {IFormElement} formElement.
     * @param {any} elementValue.
     * @return {string} errorMessages.
     */
    validate = (formElement: IFormElement,elementValue: any): string =>{
    	const InputNumber = this.utilHelper.getValidNumber(elementValue, formElement);
    	const ElementValue = _.isNull(InputNumber) ? null : InputNumber;
    	let errorMessage = "";

    	const MinRangeNumber = this.utilHelper.getValidNumber(formElement.min_range, formElement);
    	const MaxRangeNumber = this.utilHelper.getValidNumber(formElement.max_range, formElement);

		const MinRange = this.getMinMaxRange(MinRangeNumber);
		const MaxRange = this.getMinMaxRange(MaxRangeNumber);
		
    	if(!_.isNull(MinRange) && !_.isNull(MaxRange)){
    		if(_.lte(Math.abs(MinRange), Math.abs(MaxRange)) 
            &&(_.lt(ElementValue, Math.abs(MinRange)) || _.gt(ElementValue, Math.abs(MaxRange)))){
    			errorMessage = `${formElement.labelName} range should be between ${Math.abs(MinRange)}-${Math.abs(MaxRange)}.`;
    		}
    	}
    	else if(_.isNull(MinRange) && !_.isNull(MaxRange)
        && _.gt(ElementValue, Math.abs(MaxRange))){
    		errorMessage = `${formElement.labelName} range should not exceed ${Math.abs(MaxRange)}.`;
    	}
    	else if(!_.isNull(MinRange) && _.isNull(MaxRange)
        && _.lt(ElementValue, Math.abs(MinRange))){
    		errorMessage = `${formElement.labelName} range should be more or equal to ${Math.abs(MinRange)}.`;
    	}
    	return errorMessage;
    }

	getRangeMessage = (formElement: IFormElement): string =>{
		if(_.isNull(formElement.min_range) && _.isNull(formElement.max_range)){
			return "";
		}
		const MinRangeNumber = this.utilHelper.getValidNumber(formElement.min_range, formElement);
    	const MaxRangeNumber = this.utilHelper.getValidNumber(formElement.max_range, formElement);

		const MinRange = this.getMinMaxRange(MinRangeNumber);
		const MaxRange = this.getMinMaxRange(MaxRangeNumber);
		if(!_.isNull(MinRange) && !_.isNull(MaxRange)){
    		return `${formElement.labelName} range should be between ${Math.abs(MinRange)}-${Math.abs(MaxRange)}.`;
    	}
    	else if(_.isNull(MinRange) && !_.isNull(MaxRange)){
    		return `${formElement.labelName} range should not exceed ${Math.abs(MaxRange)}.`;
    	}
    	else if(!_.isNull(MinRange) && _.isNull(MaxRange)){
    		return `${formElement.labelName} range should be more or equal to ${Math.abs(MinRange)}.`;
    	}
		return "";
	}


	getMinMaxRange = (number: any): any =>{
		if(!_.isNull(number) && !_.isEmpty(number)){
			return  _.isEqual(number, 0) ? null : number;
		}
		return null;
	}
}