import * as _ from "lodash";
import { UtilHelper } from "../../../../UI/Elements/Forms/helper/Utils";
import { FileHelper } from "../../../../UI/Elements/Forms/helper/Utils/FileUtil";
import { IFormElement } from "../../Interface/FormElement/IFormElement";

// Validation helper for file
export class FileValidation{
    private utilHelper: UtilHelper;
	private fileHelper: FileHelper;

    constructor(){
    	this.utilHelper = new UtilHelper();
		this.fileHelper = new FileHelper();
    }

    /**
     * Check for file extension
     *
     * @param {File} file.
     * @return {boolean} true/false.
     */
    isValidExtension = (file: any, fileExtension: string): boolean =>{
    	const FileName = file.name;
    	const FileExtension = FileName.split(".").pop();
    	return !_.isEqual(fileExtension.indexOf(FileExtension), -1);
    }

    /**
     * Validate file size
     *
     * @param {IFormElement} formElement.
     * @param {number} fileSize.
     * @return {boolean}.
     */
    isValidFileSize = (formElement: IFormElement, fileSize: number): boolean =>{
    	
		if(_.isUndefined(formElement.configuration.file)){
			return true;
		}

		return _.lte(fileSize, formElement.configuration.file.maxSize);
    }

	/**
     * Validate file extension
     *
     * @param {IFormElement} formElement.
     * @param {number} fileSize.
     * @return {boolean}.
     */
	 isValidFileExtension = (formElement: IFormElement, fileExtension: string): boolean =>{

		if(_.isUndefined(formElement.configuration.file)){
			return true;
		}

		const AllowedFileType: string = formElement.configuration.file.allowedFileTypes;

		if(!_.isNull(AllowedFileType)){
			const FileExtensions = AllowedFileType.split(",");
			return !_.isEqual(FileExtensions.indexOf(`.${fileExtension}`), -1);
		}
		return true;
    }
}