import * as _ from "lodash";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";

// Validation helper for character length
export class LengthValidation{

    /**
     * Character min and max length validation
     *
     * @param {IFormElement} formElement.
     * @param {any} elementValue.
     * @return {string} errorMessages.
     */
    validate = (formElement: IFormElement,elementValue: any): string =>{
    	
		if(_.isUndefined(formElement.validations.len)){
			return "";
		}
		
		const CharLength = (elementValue || "").toString().length;
    	let errorMessage = "";

		const MinCharacter = this.getMinCharacter(formElement);
		const MaxCharacter = this.getMaxCharacter(formElement);
    	if(!_.isNull(MinCharacter) && !_.isNull(MaxCharacter)){
    		if(_.lt(CharLength, Math.abs(MinCharacter)) || _.gt(CharLength, Math.abs(MaxCharacter))){
    			errorMessage = `${formElement.configuration.title} should be between ${Math.abs(MinCharacter)}-${Math.abs(MaxCharacter)} characters long.`;
    		}
    	}
    	else if(_.isNull(MinCharacter) && !_.isNull(MaxCharacter)
        && _.gt(CharLength, Math.abs(MaxCharacter))){
    		errorMessage = `${formElement.configuration.title} should not exceed ${Math.abs(MaxCharacter)} characters.`;
    	}
    	else if(!_.isNull(MinCharacter) && _.isNull(MaxCharacter)
        && _.lt(CharLength, Math.abs(MinCharacter))){
    		errorMessage = `${formElement.configuration.title} should be more or equal to ${Math.abs(MinCharacter)} characters.`;
    	}
    	return errorMessage;
    }

	getMinCharacter = (formElement: IFormElement): any =>{
		const MinCharacter = formElement.validations.len?.min;
		if(!_.isUndefined(MinCharacter)){
			return _.isEqual(MinCharacter, 0) ? null : MinCharacter;
		}
		return null;
	}

	getMaxCharacter = (formElement: IFormElement): any =>{
		const MaxCharacter = formElement.validations.len?.max;
		if(!_.isUndefined(MaxCharacter)){
			return _.isEqual(MaxCharacter, 0) ? null : MaxCharacter;
		}
		return null;
	}
}