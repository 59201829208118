import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import style from "./style";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
// Props type
type props = {
  chip: string;
};

// Chip Component
export const Chip = (props: props): JSX.Element => {
	const { chip } = props;
	const classes = useStyles();

	return <div className={classes.chip}>{chip}</div>;
};

export default Chip;
