enum ELEMENT_TYPES {
  ID = 1,
  SELECT_INPUT = 2,
  TEXT_INPUT = 3,
  SWITCH = 4,
  RADIO_INPUT = 5,
  CHECKBOX_INPUT = 6,
  DISPLAY_ACCOUNT = 7,
  ASYNC_SELECT = 8,
}

enum SORT_ORDER {
  ASC = "asc",
  DESC = "desc",
}

enum PAGE_ACCESS_TYPE {
  UNAUTHORIZED = 1,
  NOT_FOUND = 2,
}

enum TEXT_ALIGNMENT {
  DEFAULT = 0,
  CENTER = 1,
  LEFT = 1,
  RIGHT = 1,
}

enum FORM_TITLE {
  NEW = "New",
  EDIT = "Edit",
  BULKEDIT = "Bulk Edit",
}

enum AUTH_APP_NAME {
  GOOGLE = "google",
  FACEBOOK = "facebook",
}

enum GRID_CELL_ELEMENT {
  TWO_LINER_TITLE_SUBTITLE = "two-liner-title-subtitle",
  TWO_LINER_TEXT = "two-liner-text",
  DEFAULT = "default",
}

enum NOTIFICATION_TYPE {
  SUCCESS = "success",
  ERROR = "error",
  INFO = "info",
  WARNING = "warning",
}

enum STATUS_TYPE {
  UNMAPPED = "Unmapped",
  MAPPED = "Mapped",
}

enum PLATFORM_TYPE {
  FACEBOOK_BUSINESS_MANAGER = "Facebook Business Manager",
  GOOGLE_ADS = "Google Ads",
  DV360 = "DV360",
  CAMPAIGN_MANAGER = "Campaign Manager",
}

const ALL_OPTION_LABEL = "All";
const ALL_OPTION_VALUE = "all";

const ALL_OPTION_OBJ = {
  label: ALL_OPTION_LABEL,
  value: ALL_OPTION_VALUE,
};

export {
  ELEMENT_TYPES,
  SORT_ORDER,
  TEXT_ALIGNMENT,
  ALL_OPTION_OBJ,
  ALL_OPTION_LABEL,
  ALL_OPTION_VALUE,
  AUTH_APP_NAME,
  GRID_CELL_ELEMENT,
  PAGE_ACCESS_TYPE,
  FORM_TITLE,
  NOTIFICATION_TYPE,
  PLATFORM_TYPE,
  STATUS_TYPE
};
