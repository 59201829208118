export default {
	WHITE: "#FFFFFF",
	BLACK: "#000000",
	GRAY: "#6a737c",
	DARK_GRAY: "#242c34",
	LIGHT_GRAY: "#e4dede",
	BLUE: "#1576d6",
	DARK_BLUE: "#005fbe",
	LIGHT_BLUE: "#3a91e8",
	VERY_LIGHT_BLUE: "#1576d6",
	ORANGE: "#e98734",
	LIGHT_ORANGE: "#f5a855",
	RED: "#b82533",
	LIGHT_RED: "#fff2f2",
	DARK_RED: "#9b2b02",
	WARNING_COLOR: "#ffa000",
	GREEN: "#177e58",
	LIGHT_GREEN: "#f0fcf5",
	BASECOLOR: "#F3F5F7",
	SHAINCOLOR: "#F7F8FA",
	BORDER_BUTTON_COLOR: "#868e96",
	HIGHLIGHT_TEXT_COLOR: "#fd0",
	ERROR: "#e65d63",
	PROGRESS_PENDING_BAR: "#edf0f2",
	YELLOW_COLOR: "#ffec9c",
	TIKTOK_BTN_BG_COLOR: "#121212",
	HR_COLOR:"#E5E5E5",
};
