import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import Select, { components } from "react-select";
import { IOption } from "../../../../../UI/Elements/Forms/interface/IOption";
import { CustomStyles } from "./style";
import { IFormElementConfig } from "../../../Interfaces/Form/FormElement/IFormElementConfig";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { isCloseMenuOnSelect } from "./Utility";
import { IFormElementValidation } from "../../../Interfaces/Form/FormElement/IFormElementValidation";
import { IForm } from "../../../Interfaces/Form/IForm";
import { EditFormHelper } from "../../Form/Helpers/Forms";
import { FormHelper } from "../../Form/Helpers/Forms/Helper";

type IProps = {
  value: IOption | null;
  onChange: any;
  errorMessage: string;
  form: IForm;
  formElement: IFormElement;
};

const formHelper = new FormHelper();

export const UISelect = (props: IProps): React.JSX.Element => {
  const { onChange, value, formElement, errorMessage, form } = props;
  
  const [selectValue, setSelectValue] = useState(value);

  const isError = !_.isEqual(errorMessage.length, 0);

  const ElementConfiguration: IFormElementConfig = formElement.configuration;
  const ElementValidation: IFormElementValidation = formElement.validations;

  const Disabled = formElement.readOnly || formElement.disabled;

  const HasChildElements: boolean = formHelper.ifHasChildElements(
    form,
    formElement
  );

  const IsHighlightOptionError = !_.isEqual((formElement.highlightRemovedOptionError || "").length,0);

  useEffect(() => {
    setSelectValue(value);
  }, [JSON.stringify(value)]);

  const onSelectChange = (selectOption: any): void => {
    const editFormHelper = new EditFormHelper();
    const SelectValue = editFormHelper.updateSelectValue(
      formElement,
      selectOption,
    );
    setSelectValue(SelectValue);
    if ( !HasChildElements ||
      (HasChildElements && !ElementConfiguration.isMulti)
    ) {
      onChange(SelectValue);
    }
  };
  return (
    <Select
    isClearable={!ElementValidation.required}
    closeMenuOnSelect={isCloseMenuOnSelect(formElement)}
    isMulti={ElementConfiguration.isMulti}
    value={selectValue}
    onMenuClose={() => {
      if (HasChildElements && ElementConfiguration.isMulti) {
        onChange(selectValue);
      }
    }}
    isDisabled={Disabled}
    onBlur={(): void => {
      return onSelectChange(selectValue);
    }}
      components={{
        DropdownIndicator: (data: any) => {
          return (
            <components.DropdownIndicator {...data} className="caret-select" />
          );
        },
      }}
      onChange={(selectedOption: any) => {
        return onSelectChange(selectedOption);
      }}
      styles={CustomStyles(isError, Disabled, IsHighlightOptionError)}
     
      
      options={
        _.isArray(ElementConfiguration.dataSource)
          ? ElementConfiguration.dataSource
          : []
      }
      placeholder={ElementConfiguration.placeholder}
    />
  );
};
