import Colors from "../../../Elements/utilstyle";

const style: any = (): any => ({
	container:{
		width: "100%",
		height:36
	},
	input: {
		backgroundColor: Colors.BackgroundColor,
		border: `1px solid ${Colors.BorderColor}`,
		borderRadius: 2,
		width:"100%",
		fontSize:"0.875rem",
		padding: "7px 12px",
		"box-sizing": "border-box",
	},
	textfield: {
		backgroundColor: Colors.BackgroundColor,
		border: `1px solid ${Colors.BorderColor}`,
		borderRadius: 2,
		width:"100%",
		fontSize:"0.875rem",
		padding: "7px 12px",
		"box-sizing": "border-box",
		marginTop: "-4px",
		marginBottom: "-4px",
	},
	inputElement:{
		"&:hover":{
			border: `1px solid ${Colors.HoverColor}`,
		},
		"&:focus":{
			border: `1px solid ${Colors.FocusColor}`,
		}
	},
});

export default style;