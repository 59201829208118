import { IForm } from "../../Interface/IForm";

import {
	ToolGroup,
} from "./FormGroup";


export const ToolForm: IForm = {
	id: "toolform",
	groups: [ToolGroup],
	isValid: false,
	isVisible: true,
	title:""
};

export const ToolForms : IForm[] = [ToolForm];
