const CONTENT_BOX = "content box";
const INTERNAL = "internal";
const EXTERNAL = "external";
const REPORT = "report";
const SCRIPT = "script";
const TOOL = "tool";
const BETA = "beta";
const REGULAR = "regular";
const ADMIN = "Admin";
const TOOL_ADMIN = "Portal Tool Configuration Admin";
const TOOL_CONTENT_ADMIN = "Portal Content Configuration Admin";
const MAPPING_ADMIN = "Mapping Admin";
const MAPPING_MANAGER = "Mapping Manager";
const MAPPING_MAPPER = "Mapping Mapper";


export default {
	CONTENT_BOX,
	INTERNAL,
	EXTERNAL,
	REPORT,
	SCRIPT,
	TOOL,
	BETA,
	REGULAR,
	ADMIN,
	TOOL_ADMIN,
	TOOL_CONTENT_ADMIN,
	MAPPING_ADMIN,
	MAPPING_MANAGER,
	MAPPING_MAPPER
};