import React, { Fragment } from "react";
import Switch from "@material-ui/core/Switch";
import classNames from "classnames";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import style from "../../../../../UI/Elements/FormElements/Switch/style";
import switchStyle from "./style";

import { IFormElement } from "../../../Interface/FormElement/IFormElement";
import UILabel from "../ChildElements/Label";
import { UIErrorOrNoteMessage } from "../ChildElements/ErrorOrNote";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));
const switchStyles = makeStyles((theme: Theme) =>
  createStyles(switchStyle(theme))
);

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: string;
  formGroupId: string;
};

export const UISwitch = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const switchClasses = switchStyles();
  const { formElement, onChange, formId, formGroupId } = props;
  const Disabled = formElement.disabled;

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <div className={switchClasses.switchContainer}>
        <Switch
          focusVisibleClassName={classes.focusVisible}
          disableRipple
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            thumb: classes.thumb,
            track: classes.track,
            checked: classes.checked,
          }}
          disabled={formElement.disabled}
          checked={formElement.value ? true : false}
          onChange={(event: any): void => {
            const Value = event.target.checked;
            onChange({ formId, formGroupId, formElement, value: Value });
          }}
          className={classNames(
            (formElement.value || "").toString() && classes.isActive,
            {
              [classes.disabled]: Disabled,
            }
          )}
        />
      </div>
      <UIErrorOrNoteMessage formElement={formElement} errorMessage={""} />
    </Fragment>
  );
};
