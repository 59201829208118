import React, { useState, useRef } from "react";
import { Tooltip } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { SnackbarAlert } from "../Snackbar";
import { IconCopy } from "../Icons";
import style from "./style";

// Added styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  title: any;
  children: any;
  disableTooltip?: boolean;
  isMulti?: boolean;
  copy?: boolean;
  customWidth?: any;
};

// UITooltip Component
export const UITooltip = (props: props): JSX.Element => {
  const { title, disableTooltip, isMulti, copy, customWidth } = props;
  const [snackbar, setSnackbar] = useState({
    message: "Copied to Clipboard.",
    open: false,
    variant: "success",
  });
  const copyTextRef: any = useRef(null);
  const classes = useStyles();
  const handleClick = (): any => {
    const text: any = copyTextRef.current?.innerText;
    navigator.clipboard.writeText(text);

    setSnackbar(
      {
        ...snackbar,
        open: true
      }
    );
  };
  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  if (isMulti) {
    return (
      <>
        <Tooltip
          title={
            <div className={classes.multiTooltip}>
              {_.isEqual(Object.keys(title).length, 1) ?
                (<div ref={copyTextRef}>
                  {
                    <div>
                      <div className={classes.label}>{Object.keys(title).toString()}</div>
                      <div className={classes.value}>{Object.values(title).toString()}</div>
                    </div>}
                </div>) :
                (<div ref={copyTextRef}>
                  {Object.keys(title).map((key: any, index: any) => <div key={index}>{key} : {!_.isEqual(title[key], null) ? title[key] : ""}</div>)}
                </div>
                )
              }
              {copy && <IconCopy onClick={handleClick} />}
            </div>
          }
          interactive
          disableHoverListener={disableTooltip}
          placement={"top"}
        >
          {props.children}
        </Tooltip>
        <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
          duration={3000}
        />
      </>
    );
  }

  return (
    <Tooltip
      title={
        customWidth ? 
        <div style={{width : customWidth}}>
          {title}
        </div>
        :
        title
      }
      interactive
      disableHoverListener={disableTooltip}
      placement={"top"}
    >
      {props.children}
    </Tooltip>
  );
};

// Default Props
UITooltip.defaultProps = {
  title: "",
};

export default UITooltip;
