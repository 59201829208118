import React from "react";
import MiddleEllipsis from "react-middle-ellipsis";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import style from "./style";

type props = {
  text: string;
  size?: string;
};

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const MiddleTextEllipsis = (props: props): React.JSX.Element => {
  const classes = useStyles();
  return (
    <span className={classes.middleTextEllipsis}>
      <MiddleEllipsis>
        <span>{props.text}</span>
      </MiddleEllipsis>
    </span>
  );
};
