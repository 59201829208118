import * as _ from "lodash";
import { Axios } from "../../../../../../api/service/Axios";
import { ELEMENT_TYPES } from "../../../../configuration";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../../../Interfaces/Form/IForm";
import { IFormGroup } from "../../../../Interfaces/Form/IFormGroup";
import { UtilHelper } from "../Utility";

// Form element helper class
export class FormElementHelper {
  private utilHelper: UtilHelper;
  constructor() {
    this.utilHelper = new UtilHelper();
  }

  /**
   * Find if any of the element parent value is empty
   *
   * @param {IForm[]} forms.
   * @param {id} element.
   * @return IFormElement | null.
   */
  isAnyParentEmpty = (form: IForm, parentElements: string[]): boolean => {
    let isEmpty = false;
    for (const formGroup of form.groups) {
      for (const formElement of formGroup.elements) {
        if (!_.isEqual(parentElements.indexOf(formElement.id), -1)) {
          if (
            this.utilHelper.isEmptyElementValue(formElement, formElement.value)
          ) {
            isEmpty = true;
          }
        }
      }
    }
    return isEmpty;
  };

 

  /**
   * Find all child elements
   *
   * @param {IForm[]} forms.
   * @param {id} element id.
   * @return {FormElements} IFormElement[].
   */
  findChildElements = (forms: IForm[], id: string): IFormElement[] => {
    const FormElements: IFormElement[] = [];
    for (const form of forms) {

      for (const formGroup of form.groups) {
        
        for (const formElement of formGroup.elements) {
          if (
            !_.isEqual(formElement.configuration.parentElements.indexOf(id), -1)
          ) {
            FormElements.push(formElement);
          }
        }
      }
    }
    return FormElements;
  };

   /**
   * Find a form element
   *
   * @param {IForm[]} forms.
   * @param {id} element.
   * @return IFormElement | null.
   */
   findFormElement = (forms: IForm[], id: number): IFormElement | null => {
    for (const form of forms) {
      for (const formGroup of form.groups) {
        for (const formElement of formGroup.elements) {
          if (_.isEqual(formElement.id, id)) {
            return formElement;
          }
        }
      }
    }
    return null;
  };

  /**
   * Create parents value string for Get request
   *
   * @param {IForm[]} forms.
   * @param {number[]} parentElements.
   * @return string.
   */
  getParentValuesForGETRequest = (
    form: IForm,
    parentElements: string[]
  ): string => {
    const ParentValues: any = [];

    form.groups.forEach((formGroup: IFormGroup) => {
      formGroup.elements.forEach((formElement: IFormElement) => {
        if (!_.isEqual(parentElements.indexOf(formElement.id), -1)) {
          const Key = formElement.configuration.apiKey || formElement.id;

          if (
            formElement.configuration.isMulti &&
            _.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)
          ) {
            if (!this.utilHelper.isAllSelected(formElement.value)) {
              const Values = this.utilHelper.getElementValue(formElement);
              for (const value of Values) {
                ParentValues.push(`${Key}=${encodeURIComponent(value)}`);
              }
            }
          } else {
            ParentValues.push(
              `${Key}=${encodeURIComponent(
                this.utilHelper.getElementValue(formElement)
              )}`
            );
          }
        }
      });
    });

    return ParentValues.join("&");
  };

  /**
   * Generate API data based on request methods
   *
   * @param {IForm[]} forms.
   * @param {number[]} parentElements.
   * @return any.
   */
  getAPIData = async (form: IForm, formElement: IFormElement): Promise<any> => {
    const ParentElements = formElement.configuration.parentElements;

    const GETParams = this.getParentValuesForGETRequest(form, ParentElements);
    const QueryParams = _.isEqual(GETParams.length, 0) ? "" : `?${GETParams}`;
    const Data = await Axios.get(
      `${formElement.configuration.apiURL}${QueryParams}`
    );

    return Data.data;
  };

  /**
   * Get query parameters i.e Parent and async
   *
   * @param {IForm} forms.
   * @param {IFormElement} formElement.
   * @param {string} inputValue.
   * @return string.
   */
  getAsyncQueryParams = (
    form: IForm,
    formElement: IFormElement,
    inputValue: string
  ): string => {
    const ParentElements = formElement.configuration.parentElements;
    const GETParams = this.getParentValuesForGETRequest(form, ParentElements);

    const QueryParams = _.isEqual(GETParams.length, 0) ? "" : `?${GETParams}`;
    let URL = `${formElement.configuration.apiURL}${QueryParams}`;

    if (formElement.configuration.isAsyncSelect) {
      const AsyncQueryParams = `${
        formElement.configuration.asyncRequestKey
      }=${encodeURIComponent(inputValue)}`;

      if (!_.isNull(AsyncQueryParams)) {
        URL = _.isEqual(GETParams.length, 0)
          ? `${URL}?${AsyncQueryParams}`
          : `${URL}&${AsyncQueryParams}`;
        return URL;
      }
    }
    return URL;
  };

  /**
   * Find an element by element id
   *
   * @param {IForm[]} forms.
   * @param {number} elementId.
   * @return {IFormElement} formElement.
   */
  findElementbyId = (forms: IForm[], elementId: number): any => {
    for (const form of forms) {
      for (const formGroup of form.groups) {
        for (const formElement of formGroup.elements) {
          if (_.isEqual(formElement.id, elementId)) {
            return formElement;
          }
        }
      }
    }
  };
}
