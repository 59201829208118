import COLORS from "../../../../Theme/colors";

const style: any = (): any => {
	return {
		fileContainer:{
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			flex:1,
			marginTop:8
		},
		fileInfo:{
			display:"flex",
			alignItems:"center",
			whiteSpace:"nowrap",
			flex:1,
		},
		fileIcon:{
			display:"flex",
			alignItems:"center",
			width:20,
		},
		fileName:{
			fontSize:"0.875rem",
			color: COLORS.DARK_GRAY,
		},
		fileSize:{
			fontSize:"0.75rem",
			color: COLORS.GRAY,
			marginLeft:8,
			display:"inline-block"
		},
		fileActionContainer:{
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			marginLeft:8,
		},
		fileAction:{
			fontSize:"0.75rem",
			color: COLORS.DARK_BLUE,
			cursor:"pointer",
			marginLeft: 20
		},
		fileErrorContainer:{
			width:"100%"
		}
	};
};

export default style;
