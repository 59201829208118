import React from "react";
import * as _ from "lodash";
import { IFileUpload } from "../../../Forms/interface/File/IFileUpload";
import { UILinearProgress } from "../../../../Progress/LinearProgress/BucketUpload";
import { UIFileComponent } from "../../../../Progress/LinearProgress/FileComponent";
import { ErrorIcon } from "../Icons";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import UITooltip from "../../Child/Tooltip";
import { LinearProgress } from "@material-ui/core";
const renderAcceptedFiles = (
  acceptedFiles: IFileUpload[],
  onOpenDialog: any,
  onRetry: any,
  loading: boolean,
  formElement: IFormElement,
  onInputChange: any,
  allProgressData: any
): React.JSX.Element[] => {
  return acceptedFiles.map((file: IFileUpload) => {
    let fileStatus = file.status;
    let progress;
    if (!_.isNull(allProgressData)) {
      for (const x of allProgressData) {
        if ((file?.id === x?.fileId && x?.progress > 0 && x?.progress <= 100)) {
          fileStatus = "uploading";
          progress = x.progress;
        }
      }
    }
    return (
      <>
        <div key={file.id} style={{ marginTop: 16 }}>
          {progress > 0 && <LinearProgress
            variant="determinate"
            value={_.isNull(progress) ? 0 : progress}
          />
          }
          <UILinearProgress
            onDelete={() => onOpenDialog(file, "delete")}
            onRetry={() => onRetry(file)}
            onCancel={() => onOpenDialog(file, "cancel")}
            status={fileStatus}
            loading={loading}
            file={file}
            formElement={formElement}
            onInputChange={(data: any) => {
              onInputChange("acceptedFiles", acceptedFiles, file, data);
            }}
            showTextBox={true}
          />
        </div>
      </>
    );
  });
};

const renderRejectedFiles = (
  files: IFileUpload[],
  onDeleteErrorFile: any,
  formElement: IFormElement,
  onInputChange: any
): React.JSX.Element[] => {
  return files.map((file: any) => {
    return (
      <UIFileComponent
        key={file.id}
        onDelete={() => onDeleteErrorFile(file)}
        status={"error"}
        loading={false}
        isError={true}
        file={file}
        showRetryOption={false}
        isPending={false}
        isUploading={false}
        fileIcon={ErrorIcon}
        formElement={formElement}
        onInputChange={(data: any) => {
          onInputChange("rejectedFiles", files, file, data);
        }}
        isRejectFile={true}
        showTextBox={true}
      />
    );
  });
};

const getHeaders = (formElement: IFormElement, classes: any): React.JSX.Element => {

  if (_.isNull(formElement.value)) {
    return <></>;
  }
  if (
    _.isEmpty(formElement.value.acceptedFiles) &&
    _.isEmpty(formElement.value.rejectedFiles)
  ) {
    return <></>;
  }
  if (_.isNull(formElement.customConfiguration)) {
    return <></>;
  }
  if (_.isUndefined(formElement.customConfiguration.bucketUpload)) {
    return <></>;
  }
  if (
    _.isUndefined(formElement.customConfiguration.bucketUpload.tableHeaders)
  ) {
    return <></>;
  }
  const GridHeaders =
    formElement.customConfiguration.bucketUpload.tableHeaders || [];

  const ToolTip = formElement.customConfiguration.bucketUpload.textBox?.tooltip || null;
  return (
    <div className={classes.fileHeaderContainer}>
      <div className={classes.fileName}>{GridHeaders[0]}</div>
      <div className={classes.fileTextbox}><div>{GridHeaders[1]}</div>
        {
          !_.isNull(ToolTip) && <UITooltip title={formElement.customConfiguration.bucketUpload.textBox?.tooltip || ""} />
        }
      </div>
      <div className={classes.actionContainer}>{GridHeaders[2]}</div>
    </div>
  );
};

export default {
  renderAcceptedFiles,
  renderRejectedFiles,
  getHeaders,
};
