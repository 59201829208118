import React, {
  useRef, useState
} from "react";
import Select, { components } from "react-select";
import * as _ from "lodash";
import { CustomStyles } from "./style";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { IFormElementConfig } from "../../../Interfaces/Form/FormElement/IFormElementConfig";
import { IFormElementValidation } from "../../../Interfaces/Form/FormElement/IFormElementValidation";
import { isCloseMenuOnSelect } from "./Utility";
import * as ScheduleHelper from "../../../Helpers/Forms/Helper";
import { IForm } from "../../../Interfaces/Form/IForm";
import { EditFormHelper } from "../../../Helpers/Forms/Edit";
import { UILabel } from "../Child/Label";

type IProps = {
  onChange: any;
  formElement: IFormElement;
  errorMessage: string;
  form: IForm;
};

export const UISelect = (props: IProps): React.JSX.Element => {
  const { onChange, formElement, errorMessage, form } = props;
  const [selectValue, setSelectValue] = useState(formElement.value);
  const isError = !_.isEqual(errorMessage.length, 0);
  const ElementConfiguration: IFormElementConfig = formElement.configuration;
  const ElementValidation: IFormElementValidation = formElement.validations;
  const Disabled = formElement.readOnly || formElement.disabled;
  const selectInputRef = useRef<any>();
  const HasChildElements: boolean = ScheduleHelper.ifHasChildElements(
    form,
    formElement
  );

  const IsHighlightOptionError = !_.isEqual(
    (formElement.highlightRemovedOptionError || "").length,
    0
  );

  const onSelectChange = (selectOption: any, event: any): void => {
    const editFormHelper = new EditFormHelper();
    const SelectValue = editFormHelper.updateSelectValue(
      formElement,
      selectOption
    );

    setSelectValue(SelectValue);
    if (
      !HasChildElements ||
      (HasChildElements && !ElementConfiguration.isMulti)
    ) {
      onChange(event, SelectValue);
    }
  };
  return (
    <>
      <UILabel formElement={formElement} />
      <Select
        closeMenuOnSelect={isCloseMenuOnSelect(formElement)}
        ref={selectInputRef}
        value={formElement.value}
        isClearable={!ElementValidation.required}
        isMulti={ElementConfiguration.isMulti}
        styles={CustomStyles(isError, Disabled, IsHighlightOptionError)}
        onMenuClose={() => {
          if (HasChildElements && ElementConfiguration.isMulti) {
            onChange(selectValue);
          }
        }}
        isDisabled={Disabled}
        components={{
          DropdownIndicator: (data: any) => {
            return (
              <components.DropdownIndicator {...data} className="caret-select" />
            );
          },
        }}
       
        placeholder={ElementConfiguration.placeholder}
      
        onBlur={(event: any): void => {
          return onSelectChange(selectValue, event);
        }}
        options={ElementConfiguration.dataSource}
        onChange={(selectedOption: any, event: any) => {
          return onSelectChange(selectedOption, event);
        }}
      />
    </>
  );
};
