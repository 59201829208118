const style: any = (): any => {
    return {
        formGroup: {
            display: "inline-grid"
        },
        root: {
            padding: 7,

            "&:hover": {
              backgroundColor: "transparent",
            },
        },
        icon: {
            height: 20,
            width: 20,
            borderRadius: 2,
            boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
            backgroundColor: "#FFFF",
            backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
            "$root.Mui-focusVisible &": {
                outline: "2px auto rgba(19,124,189,.6)",
                outlineOffset: 2,
            },
            "input:hover ~ &": {
                backgroundColor: "#ebf1f5",
            },
            "input:disabled ~ &": {
                boxShadow: "none",
                background: "rgba(206,217,224,.5)",
                opacity:"0.5"
            },
        },
        checkedIcon: {
            height: 20,
            width: 20,
            borderRadius: 2,            
            border: "1px solid rgb(58, 145, 232)",
            boxShadow: "0px 1px 4px 0px rgba(21, 118, 214, 0.15)",
            backgroundColor: "#f0f8ff",
            backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
            "&:before": {
                display: "block",
                height: 20,
                width: 20,
                backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath" +
                " d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgb(58, 145, 232)' /%3E%3C/svg%3E\")",
                content: "\"\"",
            },
            "input:hover ~ &": {
                backgroundColor: "#ebf1f5",
            },
        },
    };
};

export default style;