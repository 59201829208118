import * as _ from "lodash";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { IOption } from "../../Forms/interface/IOption";


export const getFieldValue = (formElement: IFormElement, index: number): any => {
    return formElement.dataSource.find((option: IOption) => {
      const Value = !_.isNull(formElement.dynamic_fields_values[index].value)
        ? formElement.dynamic_fields_values[index].value.value
        : null;
      return _.isEqual(option.value, Value);
    });
  };