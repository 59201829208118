import COLORS from "../../../UI/Theme/colors";

const style = (): any => ({
	container: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	contentWrapper: {
		padding: "20px 28px 28px 28px",
	},
	content: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.WHITE,
		padding: 31,
		zIndex:1
	},
	header:{
		display:"flex",
		alignItems:"center",
		flexDirection:"column"
	},
	bgImage:{
		position: "fixed",
		width: "100%",
		height: "100%",
		top: 0,
		bottom: 0,
	  },
	  profileContainer:{
		backgroundColor: COLORS.WHITE,
		borderRadius: 6,
		border: "1px solid rgba(255,255,255, 0)",
		display:"flex",
		flexDirection: "column",
    	alignItems: "center"
	  },
	  logo:{
		width:"211.6px",
		height:32
	  },
	  subtitle:{
		fontSize:12,
		color: COLORS.GRAY
	  },
	  actionButtons:{
		display:"flex",
		width:"100%",
		justifyContent:"end",
		paddingRight:"0.5rem"
	  },
	backdrop:{
		position:"fixed",
		top:0,
		left:0,
		right:0,
		bottom:0,
		backgroundColor: COLORS.WHITE,
		opacity:0,
		zIndex:99999999,
	},
	note:{
		color: COLORS.DARK_GRAY,
		marginBottom: 24,
		width:"100%",
		paddingLeft:"0.5rem"
	},
	showHide:{
		color: COLORS.DARK_GRAY,
		marginBottom: 24,
		width:"100%",
		paddingLeft:"0.5rem",
		paddingRight:"0.5rem"
	},
	siteTitle:{
		fontSize: 16,
		color: COLORS.DARK_GRAY,
		fontWeight:600,
		marginTop:8
	},
	errorMessage:{
		color: COLORS.ERROR
	},
	snackbar:{
		zIndex:10001
	},
	link: {
		color: COLORS.DARK_BLUE,
		fontSize: "0.75rem",
		cursor: "pointer",
		textDecoration: "none",
		"&:hover": {
			textDecoration: "underline"
		}
	},
});

export default style;