const style:any = (): any => ({
	root: {
		display: "flex",
		height:"100%"
	},
	content: {
		flexGrow: 1,
		marginTop: 56,
		height: "calc(100vh - 70px)",
	},
	container:{
		position: "relative",
  		height: "100%"
	},
	subContainer: {
		position: "absolute",
		margin: 0,
		top: "40%",
		textAlign: "center",
		width: "100%",
		padding: "1rem",
		boxSizing: "border-box",
		transform: "translateY(-60%)"
	},
	heading:{
		fontSize: "3rem",
		fontWeight: 500,
		marginTop: "2.5rem"
	},
	description:{
		fontSize: "1.25rem",
		marginTop: "1.25rem",
		color: "rgb(36, 44, 52)"
	}
});

export default style;