import React from "react";
import { IGridHeader } from "../../../Interfaces/Grid/IGridHeader";
import { GridRow } from "./GridRow";

type props = {
  headers: IGridHeader[];
  rowData: any;
  onEdit: any;
  bulkEditMode: boolean | undefined;
  handleCheckChange?: any;
  selected?: any;
  allSelected?: boolean;
};
export const GridBody = (props: props): React.JSX.Element => {
  const { rowData, headers, onEdit, bulkEditMode, handleCheckChange, selected, allSelected } = props;

  const GridRows = rowData.map((row: any, index: number) => {
    return <GridRow headers={headers} selected={selected} allSelected={allSelected} onEdit={onEdit} row={row} key={index} bulkEditMode={bulkEditMode} handleCheckChange={handleCheckChange} />;
  });

  return <tbody>{GridRows}</tbody>;
};
