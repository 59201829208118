import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./style";

// Circular progress loader
function CircularIndeterminate(props: any): JSX.Element {
  const { classes } = props;
  return (
    <div className={classes.loaderContainer}>
      <CircularProgress color="secondary" />
    </div>
  );
}

export default withStyles(styles)(CircularIndeterminate);
