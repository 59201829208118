import * as _ from "lodash";
import Select, { components } from "react-select";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import React, { useEffect, useState } from "react";
import { IOption } from "../../../../../UI/Elements/Forms/interface/IOption";
import { CustomStyles } from "./style";
import { isCloseMenuOnSelect } from "./Utility";
import { IFormElementValidation } from "../../../Interfaces/Form/FormElement/IFormElementValidation";
import { IFormElementConfig } from "../../../Interfaces/Form/FormElement/IFormElementConfig";
import { EditFormHelper } from "../../../Helpers/Forms/Edit";
import { FormHelper } from "../../../Helpers/Forms/Helper";
import { IForm } from "../../../Interfaces/Form/IForm";

type IProps = {
  value: IOption | null;
  onChange: any;
  form: IForm;
  errorMessage: string;
  formElement: IFormElement;
};

const formHelper = new FormHelper();

export const UISelect = (props: IProps): React.JSX.Element => {
  const { onChange, value, formElement, errorMessage, form } = props;
  const [selectValue, setSelectValue] = useState(value);
  const Disabled = formElement.readOnly || formElement.disabled;

  const HasChildElements: boolean = formHelper.ifHasChildElements(
    form,
    formElement
  );

  const ElementValidation: IFormElementValidation = formElement.validations;
  const ElementConfiguration: IFormElementConfig = formElement.configuration;
  
  useEffect(() => {
    setSelectValue(value);
  }, [JSON.stringify(value)]);
  const is_Error = !_.isEqual(errorMessage.length, 0);

  const IsHighlightOptionError = !_.isEqual(
    (formElement.highlightRemovedOptionError || "").length,
    0
  );


  const onSelectChange = (selectOption: any): void => {
    const editFormHelper = new EditFormHelper();
    const SelectValue = editFormHelper.updateSelectValue_hist(
      formElement,
      selectOption
    );
    setSelectValue(SelectValue);
    if (
      !HasChildElements ||
      (HasChildElements && !ElementConfiguration.isMulti)
    ) {
      onChange(SelectValue);
    }
  };
  return (
    <Select
    isClearable={!ElementValidation.required}
    closeMenuOnSelect={isCloseMenuOnSelect(formElement)}
      value={selectValue}
      isDisabled={Disabled}
      isMulti={ElementConfiguration.isMulti}
      onBlur={(): void => {
        return onSelectChange(selectValue);
      }}
      components={{
        DropdownIndicator: (data: any) => {
          return (
            <components.DropdownIndicator {...data} className="caret-select" />
          );
        },
      }}
      styles={CustomStyles(is_Error, Disabled, IsHighlightOptionError)}
      onMenuClose={() => {
        if (HasChildElements && ElementConfiguration.isMulti) {
          onChange(selectValue);
        }
      }}
      onChange={(selectedOption: any) => {
        return onSelectChange(selectedOption);
      }}
      
      placeholder={ElementConfiguration.placeholder}
      options={
        _.isArray(ElementConfiguration.dataSource)
          ? ElementConfiguration.dataSource
          : []
      }
    />
  );
};
