
import * as _ from "lodash";
import { UtilHelper } from "../../../../UI/Elements/Forms/helper/Utils";
import { IFileUpload } from "../../../../UI/Elements/Forms/interface/File/IFileUpload";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";

export class RequiredValidation {
    private utilHelper: UtilHelper;

	constructor(){
    	this.utilHelper = new UtilHelper();
    }

	public validate(formElement: IFormElement, elementValue: any): string{

		let errorMessage = "";

    	const RequiredMessage = `${formElement.configuration.title} is required.`;

    	switch(formElement.type){
    	// Text box
    	case FORM_ELEMENT_TYPES.TEXT_INPUT:
    		// Checkbox
    		/* falls through */
    	case FORM_ELEMENT_TYPES.CHECKBOX_INPUT:
    		// Radio
    		/* falls through*/
    	case FORM_ELEMENT_TYPES.RADIO_INPUT:
    		errorMessage = this.validateInputElements(formElement, elementValue, RequiredMessage);
    		break;
    		// Select
    	case FORM_ELEMENT_TYPES.SELECT_INPUT:{
			errorMessage = this.validateSelectInput(elementValue, RequiredMessage);
    		break;
    	}
    		// File Upload 
    	case FORM_ELEMENT_TYPES.FILE_UPLOAD:
    		errorMessage = this.validateFileinput(elementValue, RequiredMessage);
			break;
		// HTML Editor
		case FORM_ELEMENT_TYPES.HTML_EDITOR:
			errorMessage = this.validateHTMLInput(elementValue, RequiredMessage);
			break;
	}
    	return errorMessage;
	}

	private validateInputElements(formElement: IFormElement, elementValue: any, requiredMessage: string): string{
		const ElementValueLen = (elementValue || "").toString().trim().length;
		return _.isEqual(ElementValueLen, 0) 
				? requiredMessage : "";
	}

	validateSelectInput = (elementValue: any, requiredMessage: string): string =>{
		return !_.isNull(elementValue) && !_.isEqual((elementValue || []).length, 0)
    				? "" :requiredMessage;
	}

	validateHTMLInput = (elementValue: any, requiredMessage: string): string =>{
    	const HTMLRemoveRegex = /(<([^>]+)>)/ig;
		const ElementValueLen = (elementValue.replace(HTMLRemoveRegex, "") || "").toString().trim().length;

		return _.isEqual(ElementValueLen, 0) 
		? requiredMessage : "";
	}

	validateFileinput =(elementValue: any, requiredMessage: string): string =>{
		if(!_.isNull(elementValue)){

			if(!_.isEmpty(elementValue.rejectedFiles)){
				return "";
			}
			const AcceptedFiles = elementValue.acceptedFiles.filter((file: IFileUpload) => !_.isEqual(file.status, "error"));
			
			return _.isEqual(AcceptedFiles.length, 0) ? requiredMessage : "";
		}
		return requiredMessage;
	}

	    /**
     * Check if the element value is empty or not
     *
     * @param {IFormElement} element.
     * @param {any} elementValue.
     * @return boolean.
     */
		 isEmptyElementValue = (element: IFormElement, elementValue:any): any=>{
			let isEmpty = true;
			switch(element.type){
			// Text box
			case FORM_ELEMENT_TYPES.TEXT_INPUT:
				// Checkbox
				/* falls through*/
			case FORM_ELEMENT_TYPES.CHECKBOX_INPUT:
				// Radio 
				/* falls through*/
			case FORM_ELEMENT_TYPES.RADIO_INPUT:
				isEmpty = _.isEqual((elementValue || "").toString().trim().length, 0);
				break;
				// Multiselect
			case FORM_ELEMENT_TYPES.SELECT_INPUT:
				if(element.configuration.isMulti){
					isEmpty = _.isNull(elementValue) || _.isEqual((elementValue || []).length, 0);
				}else{
					isEmpty = _.isNull(elementValue);
				}
				break;
				//File input
			case FORM_ELEMENT_TYPES.FILE_UPLOAD:
				isEmpty = _.isNull(elementValue) || (_.isEmpty(elementValue.acceptedFiles) && _.isEmpty(elementValue.rejectedFiles));
				break;
			}
			return isEmpty;
		}
}
