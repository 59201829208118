import React from "react";
import { useSelector } from "react-redux";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import UITooltip from "../ChildElements/Tooltip";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Props
type props = {
    customStyle?: any;
}

export const UIUserInfo = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const UserInfo = useSelector((state: any) => state.initial.userInfo);
  
  return (<div className={classes.userInfo} style={{
    ...props.customStyle
  }}>
    <div className={classes.userInfoName}>
        <div id="fullName">{UserInfo.fullName}</div>
        <div id="userEmail" 
        className={classes.userInfoEmail}>({UserInfo.email})</div></div>
        <UITooltip title="This is automatically populated based on your OKTA login infomation."/>
    </div>);
};
