import * as _ from "lodash";
import { RequiredValidation } from "./Required";
import { RegexValidation } from "./Regex";
import { URLValidation } from "./URL";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { IFormElementUpdate } from "../../Interface/FormElement/IFormElementUpdate";
import { LengthValidation } from "./Length";

// Validation helper for forms
export class ValidationHelper {
	private requiredValidation: RequiredValidation;
	private regexValidation: RegexValidation;
	private urlValidation: URLValidation;
	private lengthValidation: LengthValidation;

	constructor() {
		this.requiredValidation = new RequiredValidation();
		this.regexValidation = new RegexValidation();
		this.urlValidation = new URLValidation();
		this.lengthValidation = new LengthValidation();
	}

	/**
	 * Validate forms
	 *
	 * @param {IForm[]} forms.
	 * @param {IElementUpdate} element.
	 * @param {boolean} isDelayed.
	 * @return {string} errorMessages.
	 */
	isValidForms = (forms: IForm[]): IForm[] => {
		return forms.map((form: IForm) => {
			let formIsValid = true;
			let elementInProgress = false;
			let formIsVisible = false;
			return ({
				...form,
				groups: form.groups.map((group: IFormGroup) => {
					let groupIsVisible = false;
					const Group: IFormGroup = ({
						...group,
						elements: group.elements.map((formElement: IFormElement) => {
							let isElementValid = formElement.isValid;
							if (!formElement.visible) {
								isElementValid = true;
							}
							elementInProgress = elementInProgress || formElement.loading;

							formIsValid = formIsValid && isElementValid;
							groupIsVisible = groupIsVisible || formElement.visible;

							return {
								...formElement,
							};
						}),
						isVisible: groupIsVisible
					});
					formIsVisible = formIsVisible || groupIsVisible;

					return Group;
				}),
				isVisible: formIsVisible,
				isValid: formIsValid,
				loading: elementInProgress
			});
		});
	}

	/**
	 * Validate element
	 *
	 * @param {IForm[]} forms.
	 * @param {IElementUpdate} element.
	 * @param {boolean} isDelayed.
	 * @return {string} errorMessages.
	 */
	isValidElement = (forms: IForm[], element?: IFormElementUpdate): IForm[] => {

		const Forms: IForm[] = [];
		for (const form of forms) {
			const Groups: IFormGroup[] = [];
			for (const formGroup of form.groups) {
				const Elements: IFormElement[] = [];
				for (const formElement of formGroup.elements) {
					const FormElement: IFormElement = { ...formElement };
					if (_.isEqual(FormElement.id, element?.formElement.id)) {
						const errorMessage = this.validate(FormElement, element?.value);
						const isValid = _.isEqual((errorMessage || "").length, 0);
						Elements.push({
							...FormElement,
							touched: true,
							errorMessage,
							isValid
						});
					} else {
						Elements.push({
							...FormElement
						});
					}
				}
				Groups.push({
					...formGroup,
					elements: Elements
				});
			}
			Forms.push({
				...form,
				groups: Groups
			});
		}
		return Forms;
	}

	private validateTextField = (formElement: IFormElement, elementValue: any): string => {
		if (formElement.validations.isNumber) {
			if (!_.isUndefined(formElement.validations.length)) {
				return this.lengthValidation.validate(formElement,
					elementValue);
			}
		}

		if (formElement.validations.isURL
			&& !formElement.validations.isEmail && !formElement.validations.isNumber) {
			return "";
		}

		if (!_.isUndefined(formElement.validations.regex)) {
			return this.regexValidation.validate(formElement, elementValue);
		}
		return "";
	}

	private validateFileUpload = (formElement: IFormElement): string => {
		if (!_.isNull(formElement.value)) {
			const RejectedFilesLen = formElement.value.rejectedFiles.length;
			const FileText = _.gt(RejectedFilesLen, 1) ? "s" : "";
			return _.isEqual(RejectedFilesLen, 0) ? "" : `Please delete the invalid file${FileText}.`;
		}
		return "";
	}

	/**
	 * Validate form element
	 *
	 * @param {IFormElement} formElement.
	 * @param {any} elementValue.
	 * @return {string} errorMessages.
	 */
	validate = (formElement: IFormElement, elementValue: any): string => {
		let errorMessage = "";

		const IsEmptyField = this.requiredValidation.isEmptyElementValue(formElement, elementValue);

		// If the field is empty and not required
		if (IsEmptyField && !formElement.validations.required) {

			return errorMessage;
		}

		// Required field validation
		if (formElement.validations.required) {
			errorMessage = this.requiredValidation.validate(formElement,
				elementValue);

			if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
		}

		switch (formElement.type) {
			case FORM_ELEMENT_TYPES.TEXT_INPUT: {
				errorMessage = this.validateTextField(formElement, elementValue);
				break;
			}
			case FORM_ELEMENT_TYPES.FILE_UPLOAD: {
				errorMessage = this.validateFileUpload(formElement);
				break;
			}
			default:
				errorMessage = "";
		}

		return errorMessage;
	}
}