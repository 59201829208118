
import { TEXT_ALIGNMENT , ELEMENT_TYPES, GRID_CELL_ELEMENT } from "../configuration";
import { IFormElement } from "../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../Interfaces/Form/IForm";
import { IGridHeader } from "../Interfaces/Grid/IGridHeader";

export const getFormConfig = (formData: any): IForm =>{
	
	const ID: IFormElement  = {
		id:"id",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.ID,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		value: formData.id || "",
		visible: false,
		configuration:{
			dataSource: [],
			isMulti: false,
			isVisible: false,
			multiRows: 2,
			placeholder: "ID",
			title: "ID",
			defaultValue: null,
			dataSourceKey: "",
			allowAll: false,
			parentElements:[]
		},
		readOnly: true,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	const Channel: IFormElement  = {
		id:"channel",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.channel || null,
		configuration:{
			dataSourceKey:"channels",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Channel",
			title: "Channel",
			defaultValue: null,
			readOnly:{
				newForm: false,
				editForm: true
			},
			allowAll: false,
			parentElements:[]
		},
		readOnly: true,
		readOnlyOptions:{
			title:"",
			subtitle:"",
			keys:{
				title:"label",
				subtitle:null
			}
		},
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};

	const MappingManager: IFormElement  = {
		id:"mappingManagers",
		disabled: false,
		isValid: false,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations:{
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage:"",
		visible: true,
		value: formData.userid || null,
		configuration:{
			dataSourceKey:"portalusers",
			dataSource:  [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Mapping Manager(s)",
			title: "Mapping Manager(s)",
			defaultValue: null,
			allowAll: false,
			parentElements:[]
		},
		readOnly: false,
		apiError:"",
		highlightRemovedOptionError:"",
		highlightRemovedOption: false
	};
	
	return {
		id:"admin",
		title:"Mapping Manager",
		isValid: false,
		isVisible: true,
		groups: [{
			id:"group1",
			elements: [ID, Channel,MappingManager],
			isVisible: true,
			title:""
		}]
	};
};

export const GRID_HEADERS: IGridHeader[] = [{
	defaultSortBy: false,
	id:"id",
	visible: false,
	title:"",
	type: ELEMENT_TYPES.ID,
	isMulti: false,
	sortingKey: "id",
	disableSorting: true,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},{
	defaultSortBy: true,
	id:"channelName",
	visible: true,
	title:"Channel",
	type: ELEMENT_TYPES.SELECT_INPUT,
	isMulti: false,
	width:150,
	sortingKey: "channelName",
	disableSorting: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},{
	defaultSortBy: true,
	id:"mappingManagers",
	visible: true,
	title:"Mapping Manager(s)",
	type: ELEMENT_TYPES.SELECT_INPUT,
	isMulti: true,
	sortingKey: "mappingManagers",
	disableSorting: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	showHideText: true,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
}
];