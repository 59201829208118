import * as _ from "lodash";
import { UI_ELEMENT_TYPES } from "../../../../config";
import { IFormElement } from "../../../interface/IFormElement";

// Validation helper for character length
export class LengthValidation{

    /**
     * Character min and max length validation
     *
     * @param {IFormElement} formElement.
     * @param {any} elementValue.
     * @return {string} errorMessages.
     */
    validate = (formElement: IFormElement,elementValue: any): string =>{
		let ElementValue = elementValue;

		if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)){
			ElementValue = ElementValue.text;
		}

    	const CharLength = (ElementValue || "").toString().length;
    	let errorMessage = "";

		const MinCharacter = this.getMinCharacter(formElement);
		const MaxCharacter = this.getMaxCharacter(formElement);
    	if(!_.isNull(MinCharacter) && !_.isNull(MaxCharacter)){
    		if(_.lt(CharLength, Math.abs(MinCharacter)) || _.gt(CharLength, Math.abs(MaxCharacter))){
    			errorMessage = `${formElement.labelName} should be between ${Math.abs(MinCharacter)}-${Math.abs(MaxCharacter)} characters long.`;
    		}
    	}
    	else if(_.isNull(MinCharacter) && !_.isNull(MaxCharacter)
        && _.gt(CharLength, Math.abs(MaxCharacter))){
    		errorMessage = `${formElement.labelName} should not exceed ${Math.abs(MaxCharacter)} characters.`;
    	}
    	else if(!_.isNull(MinCharacter) && _.isNull(MaxCharacter)
        && _.lt(CharLength, Math.abs(MinCharacter))){
    		errorMessage = `${formElement.labelName} should be more or equal to ${Math.abs(MinCharacter)} characters.`;
    	}
	
    	return errorMessage;
    }

	getMinCharacter = (formElement: IFormElement): any =>{
		if(!_.isEqual(formElement.min_character, null)){
			return _.isEqual(formElement.min_character, 0) ? null : formElement.min_character;
		}
		return null;
	}

	getMaxCharacter = (formElement: IFormElement): any =>{
		if(!_.isEqual(formElement.max_character, null)){
			return _.isEqual(formElement.max_character, 0) ? null : formElement.max_character;
		}
		return null;
	}
}