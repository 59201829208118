import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../../../UI/Theme/colors";

const style:any = (theme: Theme) => ({
	leftText:{
		flex: "0 1 auto",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "pre"
	},
	rightText: {
		flex: "1 0 auto",
		overflow: "hidden",
		whiteSpace: "pre"
	},
	subtitle:{
		fontSize:12,
		color: COLORS.GRAY,
		fontWeight:theme.typography.fontWeightMedium
	},
	middleTextEllipsis:{
		width: "100%",
		whiteSpace: "nowrap",
		display:"block",
		minHeight:21,
	},
	emptyPlaceholder:{
		minHeight:21,
		display:"block"
	},
	ellipsisContainer:{
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		visibility: "hidden",
		height:0,
		maxHeight:0,
		display:"block"
	  },
	  textContainer:{
		maxHeight:36,
		wordBreak: "break-all",
		display:"block"
	},
	textContainerEllipse:{
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 2,
		wordBreak: "break-all"
	  },
	  singleLineContainer:{
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		display:"block"
	  }
});

export default style;
