import * as _ from "lodash";
import { IOption } from "../../../../UI/Elements/Forms/interface/IOption";
import { PLATFORM_TYPE, STATUS_TYPE } from "../../configuration";
import { IGridFilter } from "../../Interfaces/Filter/IGridFilter";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../Interfaces/Form/IForm";
import { IFormGroup } from "../../Interfaces/Form/IFormGroup";
import { FilterFormHelper } from "../../Layout/Form/Helpers/Forms/Filter";
import { IAccount } from "../interface/IAccount";
import { EditFormHelper } from "../../Layout/Form/Helpers/Forms";

const getLabelValue = (dataValue: any, key: string): any => {
  return !_.isNull(dataValue) &&
    !_.isNull(dataValue[key]) &&
    !_.isUndefined(dataValue[key])
    ? dataValue[key]
    : "";
};

const getData = (gridData: any): any => {
  return gridData || null;
};

export const formatGridData = (data: any[], platformName: any): any[] => {
  const getMappedByData = (Mapper: any, mappedByDate: any): any => {
    if (
      _.isNull(Mapper.email) &&
      _.isNull(Mapper.name) &&
      !_.isNull(mappedByDate)
    ) {
      return {
        name: "-NA-",
        mappedDate: mappedByDate || "",
        email: "Not Available",
      };
    } else {
      return {
        name: getLabelValue(Mapper, "name"),
        mappedDate: mappedByDate || "",
        email: getLabelValue(Mapper, "email"),
      };
    }
  };

  return data.map((grid: any) => {
    const Agency = getData(grid.agency);
    const Brand = getData(grid.brand);
    const Client = getData(grid.client);
    const Mapper = getData(grid.mapper);
    const Market = getData(grid.market);
    const Subagency = getData(grid.subagency);
    const Vertical = getData(grid.vertical);
    let gridData: any = {};

    const formData = {
      status: grid.status || "",
      agency: {
        agencyName: getLabelValue(Agency, "label"),
        agencyValue: getLabelValue(Agency, "value"),
        subagencyName: getLabelValue(Subagency, "label"),
        subAgencyValue: getLabelValue(Subagency, "value"),
      },
      market: {
        marketName: getLabelValue(Market, "label"),
        marketValue: getLabelValue(Market, "value"),
        marketCode: getLabelValue(Market, "code"),
      },
      client: {
        clientName: getLabelValue(Client, "label"),
        clientValue: getLabelValue(Client, "value"),
        brandName: getLabelValue(Brand, "label"),
        brandValue: getLabelValue(Brand, "value"),
      },
      vertical: {
        verticalName: getLabelValue(Vertical, "label"),
        verticalValue: getLabelValue(Vertical, "value"),
      },
      mappedby: getMappedByData(Mapper, grid.modifieddate),

      includeInAdoptionStats: _.isEqual(grid.includeinadoptionstats, 1) ? 1 : 0,
    };
    switch (platformName) {
      case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
        gridData = {
          ...formData,
          mappingmetaid: grid.mappingmetaid,
          account: {
            adaccountName: grid.adaccountname || "",
            adaccountId: grid.adaccountid || "",
          },
          manager: {
            businessmanagerName: grid.businessmanagername || "",
            businessmanagerId: grid.businessmanagerid || "",
          },
        };
        break;

      case PLATFORM_TYPE.GOOGLE_ADS:
        gridData = {
          ...formData,
          mappingGoogleAdsId: grid.mappinggoogleadsid,
          account: {
            accountName: grid.accountname || "",
            accountId: grid.accountid || "",
          },
          manager: {
            managerName: grid.managername || "",
            managerId: grid.managerid || "",
          },
        };
        break;
      default:
        gridData = {};
    }

    return gridData;
  });
};

function getDropdownValue(label: any, value: any): any {
  const Value = value || null;

  if (_.isNull(Value)) {
    return null;
  }
  return {
    label: label,
    value: Value,
  };
}

export const buildFormData = (rowData: any, accountValue: IOption, platformName: any): any => {//NOSONAR
	const formData = {
		account: !_.isNull(rowData) ? rowData.account : null,//NOSONAR
		manager: !_.isNull(rowData) ? rowData.manager : null,//NOSONAR
		agency: !_.isNull(rowData) ? getDropdownValue(rowData.agency.agencyName, rowData.agency.agencyValue) : null,//NOSONAR
		subagency: !_.isNull(rowData) ? getDropdownValue(rowData.agency.subagencyName, rowData.agency.subAgencyValue) : null,//NOSONAR
		market: !_.isNull(rowData) ? getDropdownValue(rowData.market.marketName, rowData.market.marketValue) : null,//NOSONAR
		client: !_.isNull(rowData) ? getDropdownValue(rowData.client.clientName, rowData.client.clientValue) : null,//NOSONAR
		brand: !_.isNull(rowData) ? getDropdownValue(rowData.client.brandName, rowData.client.brandValue) : null,//NOSONAR
		vertical: !_.isNull(rowData) ? rowData.vertical : null,//NOSONAR
		includeInAdoptionStats: !_.isNull(rowData) ? rowData.includeInAdoptionStats : null,//NOSONAR
		mappedBy: !_.isNull(rowData) ? rowData.mappedby.name : "",//NOSONAR
		managerAccount: accountValue,
		date: !_.isNull(rowData) ? rowData.mappedby.mappedDate : null,//NOSONAR
		status: !_.isNull(rowData) ? rowData.status : null,//NOSONAR
	};
	let data: any = {};
	switch (platformName) {//NOSONAR
		case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
			data = {
				...formData,
				mappingmetaid: !_.isNull(rowData) ? rowData.mappingmetaid : null//NOSONAR
			};
			break;
		case PLATFORM_TYPE.GOOGLE_ADS:
			data = {
				...formData,
				mappinggoogleadsid: !_.isNull(rowData) ? rowData.mappingGoogleAdsId : null//NOSONAR
			};
			break;
		default:
			data = {};
	}
	return data;

};

 export const buildSubmitFormData = (
    form: IForm,
    formData: any,
    account: IAccount,
    mappingassignmentid: number,
    platformName: any,
    SelectedData?: any,
    status?: any
  ): any => {
    const FormData: any = {};
  
    form.groups.forEach((formGroup: IFormGroup) => {
      formGroup.elements.forEach((formElement: IFormElement) => {
        FormData[formElement.id] = formElement.value;
      });
    });
  
    const assignments: any = [];
    if (_.gt(SelectedData.length, 0)) {
      if (_.isEqual(platformName, PLATFORM_TYPE.GOOGLE_ADS)) {
        SelectedData.map((record: any) => {
          assignments.push({
            mappinggoogleadsid: record.mappingGoogleAdsId,
            accountname: record.account.accountName,
            accountid: record.account.accountId,
          });
        });
      }
  
      if (_.isEqual(platformName, PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER)) {
        SelectedData.map((record: any) => {
          assignments.push({
            mappingmetaid: record.mappingmetaid,
            adaccountname: record.account.adaccountName,
            adaccountid: record.account.adaccountId,
          });
        });
      }
    }
  
    let EditData: any = {};
  
    if (_.isNull(status) || _.isEqual(status.label, STATUS_TYPE.UNMAPPED)) {
      const includeInAdoptionStats = _.isNull(status)
        ? FormData.includeInAdoptionStats
        : FormData.includeInAdoptionStats.value;
      EditData = {
        platformid: account.platformId,
        type: "mapper",
        agencyid: FormData.agency.value,
        subagencyid: !_.isNull(FormData.subagency)
          ? FormData.subagency.value
          : "",
        verticalid: !_.isNull(FormData.vertical) ? FormData.vertical.value : "",
        clientid: FormData.client.value,
        brandid: !_.isNull(FormData.brand) ? FormData.brand.value : "",
        marketid: FormData.market.value,
        includeinadoptionstats: includeInAdoptionStats ? 1 : 0,
        mappingassignmentid: mappingassignmentid,
        unmapped: true,
      };
    } else {
      EditData = {
        platformid: account.platformId,
        type: "mapper",
        mappingassignmentid: mappingassignmentid,
        unmapped: false,
      };
  
      if (!_.isNull(FormData.agency)) {
        EditData = {
          ...EditData,
          agencyid: FormData.agency.value,
          subagencyid: !_.isNull(FormData.subagency)
            ? FormData.subagency.value
            : "",
        };
      }
      if (!_.isNull(FormData.subagency)) {
        EditData = {
          ...EditData,
          subagencyid: FormData.subagency.value,
        };
      }
      if (!_.isNull(FormData.client)) {
        EditData = {
          ...EditData,
          clientid: FormData.client.value,
        };
      }
      if (!_.isNull(FormData.brand)) {
        EditData = {
          ...EditData,
          brandid: FormData.brand.value,
        };
      }
      if (!_.isNull(FormData.market)) {
        EditData = {
          ...EditData,
          marketid: FormData.market.value,
        };
      }
      if (!_.isNull(FormData.includeInAdoptionStats)) {
        EditData = {
          ...EditData,
          includeinadoptionstats: FormData.includeInAdoptionStats.value,
        };
      }
      if (!_.isEqual(FormData.vertical.label, "None")) {
        EditData = {
          ...EditData,
          verticalid: FormData.vertical.value,
        };
      }
    }
  
    let data: any = {};
    switch (platformName) {
      case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
        if (_.gt(SelectedData.length, 0)) {
          data = {
            ...EditData,
            businessmanagerid: account.value.business_manager_id,
            businessmanagername: account.value.business_manager_name,
            assignments: assignments,
          };
        } else {
          data = {
            ...EditData,
            businessmanagerid: account.value.business_manager_id,
            businessmanagername: account.value.business_manager_name,
            mappingmetaid: formData.mappingmetaid,
            adaccountname: FormData.account.adaccountName,
            adaccountid: FormData.account.adaccountId,
          };
        }
        break;
      case PLATFORM_TYPE.GOOGLE_ADS:
        if (_.gt(SelectedData.length, 0)) {
          data = {
            ...EditData,
            managerid: account.value.manager_id,
            managername: account.value.manager_name,
            assignments: assignments,
          };
        } else {
          data = {
            ...EditData,
            managerid: account.value.manager_id,
            managername: account.value.manager_name,
            mappinggoogleadsid: formData.mappinggoogleadsid,
            accountname: FormData.account.accountName,
            accountid: FormData.account.accountId,
          };
        }
        break;
      default:
        data = null;
    }
  
    return data;
  };
  

export const getFilterSubmitData = (
  gridFilter: IGridFilter,
  searchText: string,
  accountData: IAccount,
  dataSources: any,
  platformName: any
): any => {
  const filterHelper = new FilterFormHelper();
  const FilterData: any = filterHelper.getFilterSubmitData(
    gridFilter,
    searchText
  );

  let assignedAgencies = dataSources.agencies || [];
  assignedAgencies = assignedAgencies.map((option: IOption) => option.value);

  let assignedMarkets = dataSources.markets || [];
  assignedMarkets = assignedMarkets.map((option: IOption) => option.value);

  const payloadData = {
    childAccounts: accountData.childAccounts,
    assignedAgencies,
    assignedMarkets,
    agencies: FilterData.agencies,
    subAgencies: FilterData.subAgencies,
    markets: FilterData.market,
    status: FilterData.status,
    mappedBy: FilterData.mappedby,
    clients: FilterData.clients,
    brands: FilterData.brands,
    verticals: FilterData.verticals,
    search: searchText,
    includedInStatsDashboard: FilterData.includeInAdoptionStats,
    platformId: accountData.platformId,
  };

  let data = {};
  switch (platformName) {
    case PLATFORM_TYPE.GOOGLE_ADS:
      data = {
        ...payloadData,
        managerAccountId: accountData.value.manager_id,
      };
      break;
    case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
      data = {
        ...payloadData,
        businessManagerAccountId: accountData.value.business_manager_id,
      };
      break;
    default:
      data = {};
  }
  return data;
};

export const validateBulkEditFormData = async (
  form: IForm,
  status: any
): Promise<IForm> => {
  const editFormHelper = new EditFormHelper();
  return await editFormHelper.updateBulkEditForm(form, status);
};


