
import { IForm } from "../../../../components/UserProfile/Interface/IForm";
import {
	ProfileGroup,
} from "./FormGroup";


export const ProfileForm: IForm = {
	id: "profileform",
	groups: [ProfileGroup],
	isValid: true,
	isVisible: true,
	title:""
};

export const ProfileForms : IForm[] = [ProfileForm];
