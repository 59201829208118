const style:any = (): any => ({
	dragContainer:{
		border:"dashed 2px #c2c6ca !important",
		padding:  "30px 20px 29px",
		textAlign: "center",
		backgroundColor:"#f7f8fa",
		borderRadius: 2,
		marginBottom: 5
	},
	disabled:{
		opacity:"0.5",
		cursor:"default"
	},
	defaultCursor:{
		cursor:"default !important"
	},
	noteText: {
		fontStyle: "italic", 
		fontSize: 12,
		marginTop: 2
	},
	boldText: {
		fontWeight: "bold"
	},
	highlightedText: {
		color: "#005fbe",
		cursor: "pointer"
	},
	highlightedSubText:{
		color:"#6a737c",
		fontSize:12
	},
	deleteOption: {
		color: "#005fbe",
		cursor: "pointer",
		verticalAlign: "super",
		display:"inline-block",
		margin:"0 .25rem",
		fontSize:"0.75rem"
	},
	filePath: {
		display: "-webkit-inline-box",
		wordBreak: "break-all",
		overflow: "hidden",
		textOverflow: "ellipsis",
		"-webkit-line-clamp": 1,
		"-webkit-box-orient": "vertical",
		paddingLeft: 10,
	},
	listColumn: {
		paddingBottom: 5,
	},
	subColumn : {
		padding: 0,
	},
	fileSize: {
		width: 80,
		fontSize: 12,
		color: "#6a737c",
		padding: "0px 8px 8px 5px",
		verticalAlign: "super"
	},
	templateLinkContainer:{
		marginLeft:"2.688rem"
	},
	templateLink:{
		color:"#005fbe",
		fontSize:"0.75rem",
		display: "block",
		textDecoration: "none",
		fontWeight: 500,
		width:"fit-content",
	},
	gridUploadFile:{
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		marginLeft: 23
	},
	overwriteText:{
		color:"#242c34",
		fontSize:12
	},
	fileErrorContainer:{
		width:"100%"
	}
});

export default style;