import Dashboard from "../../components/Dashboard";
import Admin from "../../components/Admin";
import MappingAdmin from "../../components/Mapping/Admin";
import MappingManager from "../../components/Mapping/Manager";
import MappingReview from "../../components/Mapping/Review";
import MappingMapper from "../../components/Mapping/Mapper";
import ToolsPage from "../../components/Toolkit";
import ToolType from "../../components/Toolkit/Tool/Type";
import History from "../../components/History";
import UserProfile from "../../components/UserProfile";
import RequiredInfo from "../Login/UserProfile";
import withSecureRouteHOC from "../../HOC/withSecureRoute";
import withProfileRedirectHOC from "../../HOC/WithProfileRedirectHOC";
import WithRequiredInfoProfile from "../../HOC/WithRequiredInfoProfile";

export const DashboardComponent = withProfileRedirectHOC(withSecureRouteHOC(Dashboard));
export const AdminComponent = withProfileRedirectHOC(withSecureRouteHOC(Admin));
export const MappingAdminComponent = withProfileRedirectHOC(withSecureRouteHOC(MappingAdmin));
export const MappingManagerComponent = withProfileRedirectHOC(withSecureRouteHOC(MappingManager));
export const MappingReviewComponent = withProfileRedirectHOC(withSecureRouteHOC(MappingReview));
export const MappingMapperComponent = withProfileRedirectHOC(withSecureRouteHOC(MappingMapper));
export const UserProfileComponent = withProfileRedirectHOC(UserProfile);
export const ToolsPageComponent = withProfileRedirectHOC(ToolsPage);
export const ToolTypeComponent = withProfileRedirectHOC(ToolType);
export const HistoryComponent = withProfileRedirectHOC(History);
export const RequiredInfoComponent = WithRequiredInfoProfile(RequiredInfo);
