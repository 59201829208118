import Colors from "../../Theme/colors";

export default function style(): any {

    return ({
        backButton: {
            minwidth: "54px",
            height: "18px",
            margin: "0 0 8px -3px",
            fontSize: "12px",
            fontWeight: 500,
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: 1.5,
            letterSpacing: "normal",
            color: Colors.DARK_BLUE,
        },
        container: {
            minWidth: 97,
            height: 36,
            margin: "8 580 0 0",
            padding: "9px 32px 9px 0"
        }
    });
}

