import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import { getLoadingCells } from "./Helper";
import { IGridHeader } from "../../../../Forms/interface/Grid/IGridHeader";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  rows: number;
  headers: IGridHeader[];
  isGridAction: boolean;
};

export default function RowLoader(props: props): React.JSX.Element {
  const classes = useStyles();

  const { rows, headers, isGridAction } = props;

  const Rows: number[] = [];
  for (let i = 0; i < rows; i++) {
    Rows.push(i);
  }
  const GridCells: React.JSX.Element[] = getLoadingCells(
    headers,
    classes,
    isGridAction
  );

  const GridRows = Rows.map((colData: any, colIndex: number) => {
    return (
      <tr key={colIndex} className={classes.loadingGridRow}>
        {GridCells}
      </tr>
    );
  });
  return <tbody>{GridRows}</tbody>;
}
