import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import styles from "../style";
import { UITextInput } from "../Elements/TextInput";
import { UISelect } from "../Elements/Dropdown";
import { UI_GRID_CELL_EDITTOR_TYPES } from "../../../../../../config";
import { IOption } from "../../../../../../Forms/interface/IOption";
import { IGridHeader } from "../../../../../../Forms/interface/Grid/IGridHeader";

const useStyles = makeStyles((theme: Theme) => createStyles(styles(theme)));

type props = {
  data: any;
  header: IGridHeader;
  onEditForm: any;
};

export const DataGridBodyRow = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { data, header, onEditForm } = props;
  const [showInput, setShowInput] = useState(false);

  let elementValue = (data[header.field] || "").toString();

  let element = <span />;
  if (_.isEqual(header.type, UI_GRID_CELL_EDITTOR_TYPES.TEXT)) {
    const IsError = header.config.required && _.isEmpty(elementValue);

    element = (
      <UITextInput
        placeholder={header.placeholder}
        field={header.field}
        element={{
          value: elementValue,
          touched: true,
        }}
        isError={IsError}
        onFormChange={(field: any, value: any): void => {
          onEditForm(field, value, data.id);
        }}
      />
    );
  }

  if (_.isEqual(header.type, UI_GRID_CELL_EDITTOR_TYPES.DROPDOWN)) {
    const IsError = header.config.required && _.isEmpty(elementValue);
    const dd:any = header?.dataSource?.data;
    const DropDownValue: any = (
      _.isArray(header?.dataSource?.data) ? (dd): []
    ).find((option: IOption) =>
      _.isEqual(
        _.toLower(option.value),
        _.toLower((elementValue || "").toString().trim())
      )
    );

    const Option: any = _.isUndefined(DropDownValue) ? null : DropDownValue;
    elementValue = _.isUndefined(DropDownValue) ? "" : elementValue;
    const d:any = header?.dataSource?.data;
    element = (
      <UISelect
        dataSource={d}
        placeholder={header.placeholder}
        field={header.field}
        elementValue={Option}
        onFormChange={(field: any, value: any): void => {
          setShowInput(false);
          onEditForm(field, value, data.id);
        }}
        isError={IsError}
        config={header.config}
      />
    );
  }
  let InputField = <div className={classes.gridCellInput}>{element}</div>;
  if (!showInput) {
    InputField =
      _.isEmpty(elementValue) && header.config.required ? (
        <div className={classes.gridEmptyCell}></div>
      ) : (
        <div className={classes.gridCellText}>{elementValue}</div>
      );
  }

  return (
    <div
      key={header.field}
      className={classes.gridCell}
      onMouseOver={() => {
        setShowInput(true);
      }}
      onMouseEnter={() => {
        setShowInput(true);
      }}
      onMouseLeave={() => {
        setShowInput(false);
      }}
    >
      {InputField}
    </div>
  );
};
