import React from "react";
import { FormGroup, FormControlLabel, FormControl } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import Checkbox from "@material-ui/core/Checkbox";
import classNames from "classnames";

type props = any;

// Checkbox input type component
function CheckboxComponent(props: props): JSX.Element {
  const { handleChange, data, id, label, classes, CheckboxProps } = props;

  const elements = data.map((d: any) => (
    <FormControlLabel
      key={d.value}
      value={d.value}
      control={
        <Checkbox
          name={d.label}
          checked={d.checked}
          className={classes.root}
          disableRipple
          color="primary"
          checkedIcon={
            <span className={classNames(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          inputProps={{ "aria-label": "decorative checkbox" }}
          {...CheckboxProps}
        />
      }
      label={d.label}
      labelPlacement="end"
    />
  ));

  return (
    <FormControl component="fieldset">
      <FormGroup
        aria-label={label}
        row
        key={id}
        className={classes.formGroup}
        onChange={handleChange}
      >
        {elements}
      </FormGroup>
    </FormControl>
  );
}

export default withStyles(styles)(CheckboxComponent);
