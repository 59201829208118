import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import axiosRetry from  "axios-retry";
import * as _ from "lodash";
import { RequestContentTypes } from "./ContentTypes";

const PortalAxios = axios.create({
     baseURL: process.env.REACT_APP_BASE_URL
});

// Retry delay
axiosRetry(PortalAxios, { retryDelay: (retryCount) => {
  return retryCount * 2000;
}});

PortalAxios.interceptors.request.use((request: any)=>{
	 const GetToken: any = localStorage.getItem("okta-token-storage");
     const AccessToken: any = JSON.parse(GetToken);
	 
	 request.headers = {
		Authorization: !_.isNull(AccessToken)
		? AccessToken.idToken?.idToken || ""
		: "",
        "X-AccessToken": AccessToken.accessToken?.accessToken,
		...request.headers,
	 };
     return request;
});

PortalAxios.interceptors.response.use((response: AxiosResponse)=>{
     return response;
});

const get = (url: string, config: AxiosRequestConfig = {}) : Promise<any> =>{
    return PortalAxios.get(url, { headers:{ "Content-Type": RequestContentTypes.JSON, ...config.headers}});
};

const post = (url: string, data: any={}, config: AxiosRequestConfig={}) : Promise<any> =>{
	return PortalAxios.post(url, data, { headers:{ "Content-Type": RequestContentTypes.JSON, ...config.headers}, onUploadProgress: config.onUploadProgress});
};

const put = (url: string, data: any={}, config: AxiosRequestConfig={}) : Promise<any> =>{
    return PortalAxios.put(url, data, { headers:{ "Content-Type": RequestContentTypes.JSON, ...config.headers}});
};

const remove = (url: string, config: AxiosRequestConfig = {}) : Promise<any> =>{
    return PortalAxios.delete(url, { headers:{ "Content-Type": RequestContentTypes.JSON, ...config.headers}});
};

export const Axios = {
    get,
    post,
    put,
    remove
};