import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import style from "./style";
import { GoogleLogin } from "react-google-login";
import classNames from "classnames";
import { IconSuccess } from "../../../../Icons";
import _ from "lodash";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onGoogleResponse: any;
  appid: any;
  scope: any;
  AuthGroup: any
};

export const GoogleAuth = (props: props): JSX.Element => {
  const classes = useStyles();


  const { onGoogleResponse, appid, scope, AuthGroup } = props;
  const visibllity = (!_.isEmpty(AuthGroup.value) && !_.isNull(AuthGroup.value)) ? "visible" : "hidden";

  return (
    <div className={classes.googleContainer}>
      <div>
        <GoogleLogin
          clientId={appid}
          buttonText="Sign in with Google"
          scope={scope}
          accessType="offline"
          prompt="consent"
          responseType="code"
          onSuccess={onGoogleResponse}
          onFailure={onGoogleResponse}
          className={classNames(classes.button, "MuiButtonBase-root")}
          cookiePolicy={"single_host_origin"}
          disabled={!_.isEmpty(AuthGroup.value) && !_.isNull(AuthGroup.value)}
        />
      </div>
      <div className={classes.successIconDiv} style={{ visibility: visibllity }}>
        <IconSuccess />
      </div>
    </div>

  );
};
