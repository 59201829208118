import * as _ from  "lodash";
import { IOption } from "./Elements/Forms/interface/IOption";

const SortOrderDS = [{ label: "Smallest to Largest", value: "asc" },
	{ label: "Largest to Smallest", value: "desc" }];

const getSortOrderDS = (): IOption[] => SortOrderDS.map((option: IOption)=>({...option}));

const getASC = (): IOption => ({ label: "Smallest to Largest", value: "asc" });
const getDESC = (): IOption => ({ label: "Largest to Smallest", value: "desc" });
const ascValue = "asc";
const descValue = "desc";

const getOrderLabel = (order: string): string => {
	const Value:any = getSortOrderDS().find((option: IOption)=> _.isEqual(option.value, order));
	return Value?.label;
};

const getOrderValue = (order: string): number => {
	const Value:any = getSortOrderDS().find((option: IOption)=> _.isEqual(option.value, order));
	return _.isEqual(Value.value, ascValue) ? 1 : 0;
};


export {
	getSortOrderDS,
	getASC,
	getOrderValue,
	getDESC,
	ascValue,
	descValue,
	getOrderLabel
};