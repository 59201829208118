import * as _ from "lodash";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IFormElementUpdate } from "../../Interface/FormElement/IFormElementUpdate";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { UtilHelper } from "../Utility";
import { ValidationHelper } from "../Validations";
import { FormElementHelper } from "./Element";

// Parent Child Element Util
export class ParentChildElementUtil{

    private validationHelper: ValidationHelper;
    private elementHelper: FormElementHelper;
    private utilHelper: UtilHelper;

    constructor(){
    	this.validationHelper = new ValidationHelper();
    	this.elementHelper = new FormElementHelper();
    	this.utilHelper = new UtilHelper();
    }

	private findParentElementsForSelect = (form: IForm, formElement: IFormElement, element: IFormElementUpdate): IFormElement =>{
		let Element = {...formElement};
		const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(form, Element.configuration.parentElements);
		
					if(IsAnyParentEmpty){
					   
						let errorMessage = "";
						if(Element.validations.required){
							errorMessage = this.validationHelper.validate(Element, 
							   _.isEqual(Element.type, FORM_ELEMENT_TYPES.TEXT_INPUT) ? "" : null);
						}

						Element = {
							...Element,
							disabled: true,
							value: null,
							configuration:{
								...Element.configuration,
								dataSource:[]
							},
							errorMessage,
							isValid: _.isEqual(errorMessage.length, 0),
							touched: _.isEqual(Element.id, element.formElement.id) ? true : formElement.touched,
						};
					}
					else{
					   
						let errorMessage = "";
						if(formElement.validations.required){
							errorMessage = this.validationHelper.validate(Element, 
								Element.value);
						}
					   
						Element = {
							...Element,
							errorMessage,
							isValid: _.isEqual(errorMessage.length, 0),
							touched: _.isEqual(Element.id, element.formElement.id) ? true : Element.touched,
						};
					}
				return Element;
	}
    /**
     * Find parent element
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IForms[]} forms.
     */
     public findParentElements = async (forms: IForm[], element: IFormElementUpdate): Promise<IForm[]> =>{
     	const Forms: IForm[] = [];
		for(let iForm=0;iForm<forms.length;iForm++){
			const Form: IForm = ({...forms[iForm], groups: 
				forms[iForm].groups.map((group: IFormGroup) => ({
					...group,
					elements: group.elements.map((formElement: IFormElement)=>({
						...formElement
					}))
				}))});
		
				forms[iForm].groups.forEach((formGroup: IFormGroup, formGroupIndex: number)=>{
					formGroup.elements.forEach((formElement: IFormElement, elementIndex: number)=>{
						if(!_.isEqual(formElement.configuration.parentElements.length, 0)
					   && (_.isEqual(formElement.type, FORM_ELEMENT_TYPES.SELECT_INPUT))){
							forms[iForm].groups[formGroupIndex].elements[elementIndex] = this.findParentElementsForSelect(forms[iForm], formElement, 
								element);
						}
						else{
							forms[iForm].groups[formGroupIndex].elements[elementIndex] = {
								...formElement,
								touched: _.isEqual(formElement.id, element.formElement.id) ? true : formElement.touched,
							};
						}
					});
				});
		
				Forms.push(Form);
		}
		return Forms;
     }

    /**
     * Find child elements
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IForms[]} forms.
     */
    public findChildElements =  async (forms: IForm[], element: IFormElementUpdate)
    : Promise<IForm[]> =>{
		const Forms: IForm[] = [];
		for(let iForm=0;iForm<forms.length;iForm++){
			const FormGroups: IFormGroup[] =  await this.apiGetFormGroups(forms[iForm], forms[iForm].groups, element);

			Forms.push({
				...forms[iForm],
				groups: FormGroups
			});
		}
		return Forms;
    }

    /**
     * Find form groups
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IFormGroup[]} FormGroups.
     */
    private apiGetFormGroups = async (form: IForm, formGroups: IFormGroup[], element: IFormElementUpdate):
    Promise<IFormGroup[]>=>{
    	const FormGroups: IFormGroup[] = [];
    	for(const formGroup of formGroups){
    		const FormElementArray = [];
    		for(const formElement of formGroup.elements){
    			const Element: IFormElement = await this.apiUpdateElement(form, formElement, element);
    			FormElementArray.push(Element);
    		}
    		FormGroups.push({
    			...formGroup,
    			elements: FormElementArray
    		});
    	}
    	return FormGroups;
    }

	private ifParentElementNotEmpty = async (form: IForm, element: IFormElement): Promise<any> =>{
		const Element: IFormElement = {...element};
		try{
			const APIData = await this.elementHelper.getAPIData(form, element);

			const DataSource = _.isArray(APIData) ? APIData : [];
			Element.configuration.dataSource = DataSource;
			Element.apiError = "";
			return {
				Element,
				dataSource: DataSource
			};
		}
		catch(error:any){
			Element.configuration.dataSource  = [];
			Element.apiError = this.utilHelper.getErrorMessage(error);
			return {
				Element,
				dataSource: []
			};
		} 
	}

    /**
     * Find if the current element is a parent and call the API
     *
     * @param {IFormElement} formElement.
     * @param {IElementUpdate} element.
     * @return {IFormElement} Element.
     */
    private apiUpdateElement = async (form: IForm, formElement: IFormElement, element: IFormElementUpdate)
    :Promise<IFormElement> =>{
    	let Element: IFormElement = {...formElement};
    	if(!_.isEqual(Element.configuration.parentElements.length, 0) &&!_.isEqual(Element.configuration.parentElements.indexOf(element.formElement.id), -1)){
    		const ElementValue = this.utilHelper.isEmptyValue(element.value) ? null : element.value;
    		let dataSource = [];
    		const elementValue = null;
    		const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(form, Element.configuration.parentElements);

    		if(!_.isNull(ElementValue) && !this.utilHelper.isEmptyValue(Element.configuration.apiURL || "")
            && !IsAnyParentEmpty){
    			const _element = await this.ifParentElementNotEmpty(form, Element);
				dataSource = _element.dataSource;
				Element = {
					..._element.Element
				};
    		}
            
    		let errorMessage = "";
    		if(Element.validations.required){
    			errorMessage = this.validationHelper.validate(Element, 
    				null);
    		}
        

    		return {
    			...Element,
    			disabled: IsAnyParentEmpty || formElement.readonly,
    			value:  elementValue,
    			configuration:{
					...Element.configuration,
					dataSource
				},
    			errorMessage,
    			touched: false,
    			isValid: _.isEqual(errorMessage.length, 0),
    		};
    	}
    	return Element;
    }

}