import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton } from "@material-ui/core";
import classNames from "classnames";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onClick: any;
  title: string;
  disabled: boolean;
};

// Save Icon functional component
export const IconSave = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onClick, title, disabled } = props;

  return (
    <Tooltip title={title} onClick={onClick} placement={"top"}>
      <IconButton
        aria-label={title}
        size={"small"}
        className={classNames(classes.iconButton, classes.disabled)}
        disabled={disabled}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M18 21v-8H6v8H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h13l4 4v13a1 1 0 0 1-1 1h-2zm-2 0H8v-6h8v6z" />
        </svg>
      </IconButton>
    </Tooltip>
  );
};
