import { Input, IconButton, InputAdornment } from "@material-ui/core";
import React, { Fragment } from "react";
import classNames from "classnames";
import { DateRange } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import * as _ from "lodash";
import moment from "moment";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IFormElement } from "../../Forms/interface/IFormElement";
import style from "./style";
import commonStyle from "../sharedStyle";
import { DateUtil } from "../../Forms/helper/Utils/Date";
import { getPropsAndDateFormat } from "./Utility";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

// UIDatePicker Component
export const UIDatePicker = (props: props): JSX.Element => {
  const { formElement, formId, formGroupId, onChange } = props;
  const classes = useStyles();
  const commonClasses = useCommonStyles();
  const Disabled = formElement.disabled || formElement.read_only;
  const ErrorMessage = formElement.touched ? formElement.error : "";

  let dateValue = null;
  const DateISOFormat = formElement.show_time
    ? DateUtil.ISODateTimeFormat
    : DateUtil.ISODateFormat;

  if (!_.isNull(formElement.value) && !_.isEmpty(formElement.value)) {
    const dateFormat = formElement.show_time
      ? DateUtil.momentDateTimeFormat
      : DateUtil.momentDateFormat;
    const DateValue: any = moment(formElement.value, dateFormat, true).local();
    dateValue = new Date(DateValue);
  }

  const onInputChange = (event: any): void => {
    if (!_.isUndefined(event)) {
      const DValue = event.target.value;

      return onChange({
        formId,
        formGroupId,
        formElement: {
          ...formElement,
          input_event_value: DValue,
          dateInput: formElement.dateInput,
        },
        value: formElement.value,
      });
    } else {
      const DateFormat = formElement.show_time
        ? DateUtil.momentDateTimeFormat
        : DateUtil.momentDateFormat;

      return onChange({
        formId,
        formGroupId,
        formElement: {
          ...formElement,
          input_event_value: moment(formElement.value).format(DateFormat),
          dateInput: formElement.dateInput,
        },
        value: formElement.value,
      });
    }
  };

  const DateControl = getPropsAndDateFormat(formElement);

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <div
        style={{
          width: formElement.show_time ? 205 : 140,
        }}
      >
        <DatePicker
          selected={dateValue}
          dateFormat={DateControl.dateFormat}
          placeholderText={formElement.placeholder}
          showTimeSelect={formElement.show_time}
          disabled={Disabled}
          {...DateControl.props}
          customInput={
            <Input
              type="text"
              disabled={Disabled}
              id={`${formElement.id}`}
              className={classNames(commonClasses.input, {
                [commonClasses.disabled]: Disabled,
                [classes.inputElement]:
                  !Disabled && _.isEqual(ErrorMessage.length, 0),
                [commonClasses.error]: !_.isEqual(ErrorMessage.length, 0),
              })}
              value={formElement.value}
              onInput={onInputChange}
              placeholder={formElement.placeholder}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    className={classNames(commonClasses.iconButton, {
                      [commonClasses.disabledIcon]: Disabled,
                    })}
                    size={"small"}
                  >
                    <DateRange fontSize={"small"} />
                  </IconButton>
                </InputAdornment>
              }
            />
          }
          onBlur={onInputChange}
          onChange={(selectedDate: any, event: any) => {
            const DateFormat = formElement.show_time
              ? DateUtil.momentDateTimeFormat
              : DateUtil.momentDateFormat;
            if (!_.isUndefined(event)) {
              const DValue = event.target.value;

              return onChange({
                formId,
                formGroupId,
                formElement: {
                  ...formElement,
                  input_event_value: DValue,
                  dateInput: moment(selectedDate, DateISOFormat, true)
                    .local()
                    .format(DateISOFormat),
                },
                value: selectedDate,
              });
            } else {
              return onChange({
                formId,
                formGroupId,
                formElement: {
                  ...formElement,
                  input_event_value: moment(selectedDate).format(DateFormat),
                  dateInput: moment(selectedDate, DateISOFormat, true)
                    .local()
                    .format(DateISOFormat),
                },
                value: selectedDate,
              });
            }
          }}
        />
      </div>

      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={Disabled ? "" : ErrorMessage}
      />
    </Fragment>
  );
};
