
import _ from "lodash";
import { ADMIN_GET_ALL_TOOLS, ADMIN_GET_TOOL_PAGE, ADMIN_SCHEDULE_TOOL_UPDATE } from "../../../../api/paths";
import { Axios } from "../../../../api/service/Axios";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { IOption } from "../../Interface/IOption";

export class SubmitFormHelper {

	public getSubmitForm(forms: IForm[]): IForm[] {
		const SubmitData: any = {};

		forms.forEach((form: IForm) => {
			form.groups.forEach((formGroup: IFormGroup) => {

				formGroup.elements.forEach((formElement: IFormElement) => {
					if (formElement.visible) {
						SubmitData[formElement.id] = this.getElementValue(formElement);
					}
					else {
						SubmitData[formElement.id] = null;
					}
				});
			});
		});

		return SubmitData;
	}

	public isTouchedElement(forms: IForm[]): boolean {
		let touched=false;
		forms.forEach((form: IForm) => {
			form.groups.forEach((formGroup: IFormGroup) => {

				formGroup.elements.forEach((formElement: IFormElement) => {
					if (_.isEqual(formElement.id,"isschedule") && formElement.touched) {
						touched=true;
					}
					if (_.isEqual(formElement.id,"isgrid") && formElement.touched) {
						touched=true;
					}
				});
			});
		});
		return touched;
	}

	private getElementValue = (
		formElement: IFormElement
	): IFormElement => {
		let elementValue = formElement.value;

		switch (formElement.type) {
			case FORM_ELEMENT_TYPES.SELECT_INPUT: {
				elementValue = this.getSelectValue(formElement);
				break;
			}
			case FORM_ELEMENT_TYPES.SWITCH:
				elementValue = formElement.value ? 1 : 0;
				break;
			case FORM_ELEMENT_TYPES.FILE_UPLOAD:
				elementValue = formElement.imageUrl;
				break;
		}
		return elementValue;
	};

	private getSelectValue = (formElement: IFormElement): any => {
		if (_.isNull(formElement.value)) {
			return null;
		}

		if( _.isEmpty(formElement.value) && _.isEqual(formElement.id,"ToolPersistButtons")){
			return [];
		}

		if (!formElement.configuration.isMulti) {
			return formElement.value.value;
		}

		if (!_.isUndefined(formElement.configuration.selectMetadata)
			&& formElement.configuration.isMulti) {
			const OptionValueKey: any = formElement.configuration.selectMetadata?.value;
			return formElement.value.map((option: IOption) => ({
				[OptionValueKey]: option.value
			}));
		}

		return formElement.value;

	}

	public createTool = (formData: any): Promise<void> => {
		return new Promise((resolve, reject) => {
			Axios.post(ADMIN_GET_ALL_TOOLS, formData)
				.then(() => {
					resolve();
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	public updateTool = (toolId: number, formData: any): Promise<void> => {
		return new Promise((resolve, reject) => {
			Axios.put(ADMIN_GET_TOOL_PAGE(toolId), formData)
				.then(() => {
					resolve();
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

	public scheduleUpdateTool = (toolId: number, formData: any): Promise<void> => {
		return new Promise((resolve, reject) => {
			Axios.put(ADMIN_SCHEDULE_TOOL_UPDATE(toolId), {isschedule:formData.isschedule,isgrid:formData.isgrid})
				.then(() => {
					resolve();
				})
				.catch((error: any) => {
					reject(error);
				});
		});
	}

}
