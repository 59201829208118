import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import commonStyle from "../../../../../../UI/Elements/FormElements/sharedStyle";
import UITooltip from "../../../../../../UI/Elements/FormElements/Child/Tooltip";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";
import { IFormElementConfig } from "../../../../Interfaces/Form/FormElement/IFormElementConfig";
import { IFormElementValidation } from "../../../../Interfaces/Form/FormElement/IFormElementValidation";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const utilStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  formElement: IFormElement;
};

// UILabel Component
export const UILabel = (props: props): React.JSX.Element => {
  const { formElement } = props;
  const classes = useStyles();
  const utilClasses = utilStyles();
  const ElementConfiguration: IFormElementConfig = formElement.configuration;

  const ElementValidation: IFormElementValidation = formElement.validations;

  return (
    <label htmlFor={`${formElement.id}`} className={utilClasses.elementLabel}>
      {ElementConfiguration.title}
      <UITooltip title={ElementConfiguration.tooltip || ""} />
      {!ElementValidation.required && !ElementConfiguration.readOnly && (
        <span className={classes.optional}>Optional</span>
      )}
    </label>
  );
};
