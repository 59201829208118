import * as _ from "lodash";
import { IFormElementUpdate } from "../../Interfaces/Form/FormElement/IFormElementUpdate";
import { IOption } from "./../../../../../../../UI/Elements/Forms/interface/IOption";
import { ALL_OPTION_OBJ, ALL_OPTION_VALUE, ELEMENT_TYPES } from "./../../configuration";
import { IFormElement } from "./../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "./../../Interfaces/Form/IForm";
import { ValidationHelper } from "../Validations";
import { IFormGroup } from "./../../Interfaces/Form/IFormGroup";
import { IScheduleSelectedJobInfo } from "../../Interfaces/IScheduleSelectedJobInfo";


// Edit form class
export class EditFormHelper {
	private validationHelper: ValidationHelper;

	constructor() {
		this.validationHelper = new ValidationHelper();
	}

	public updateElementDataSource(form: IForm, element: IFormElement, option: IOption): IForm {
		return {
			...form,
			groups: form.groups.map((formGroup: IFormGroup) => {
				return {
					...formGroup,
					elements: formGroup.elements.map((formElement: IFormElement) => {
						if (_.isEqual(formElement.id, element.id)
							&& _.isEqual(ELEMENT_TYPES.SELECT_INPUT, element.type)) {
							let updatedDataSource = [...formElement.configuration.dataSource,
								option];
							updatedDataSource = _.orderBy(updatedDataSource, [(op: IOption) => op.label.toLowerCase()], ["asc"]);

							return {
								...formElement,
								configuration: {
									...formElement.configuration,
									dataSource: updatedDataSource
								},
								value: option,
							};
						}
						return {
							...formElement
						};
					})
				};
			})
		};

	}

	public updateForm = async (form: IForm, element: IFormElementUpdate, selectedScheudledInfo: IScheduleSelectedJobInfo): Promise<IForm> => {
		const FormGroups = [];
		for (const formGroup of form.groups) {
			const FormElementArray = [];

			for (const formElement of formGroup.elements) {
				const Element = await this.updateElement(formElement, element, selectedScheudledInfo, form);
				FormElementArray.push(Element);
			}
			FormGroups.push({
				...formGroup,
				elements: FormElementArray,
			});
		}
		return {
			...form,
			groups: FormGroups
		};

	}
	public isSelectedScheduledParent = (form: IForm, selectedScheudledInfo: IScheduleSelectedJobInfo): any => {
		for (const formGroup of form.groups) {
			for (const formElement of formGroup.elements) {
				if (_.isEqual(formElement.configuration.dependentValue, selectedScheudledInfo.frequency.type) && _.isEqual(formElement.value?.value, selectedScheudledInfo.frequency.value)) {
						return true;
				}
			}
		}
		return false;
	}

	public timeUpdatedElement = (formElement: IFormElement, selectedScheudledInfo: IScheduleSelectedJobInfo, element: IFormElementUpdate, form: IForm): any => {//NOSONAR
		let updatedTimeElement = {
			...formElement
		};
		if (_.isNull(selectedScheudledInfo)) {//NOSONAR
			if (_.isEqual(element.formElement.id, "active")) {//NOSONAR
				if (_.isEmpty(formElement.value)) {//NOSONAR
					updatedTimeElement = {//NOSONAR
						...formElement,
						value: null
					};
				}
				updatedTimeElement = { ...formElement };
			}
			else {//NOSONAR
				updatedTimeElement = { ...formElement, value: null };
			}
		}
		else if (_.isEqual(element.formElement.id, "active") && !_.isNull(formElement.value) && !_.isUndefined(formElement.value)) {//NOSONAR
			if (!_.isEqual(formElement.value.value, selectedScheudledInfo.frequency.time)) {//NOSONAR
				updatedTimeElement = { ...formElement };
			}
		}
		else if (!_.isNull(selectedScheudledInfo)) {//NOSONAR
			if (_.isEqual(selectedScheudledInfo.frequency.type, "Daily") && _.isEqual(element.value, "Daily")) {//NOSONAR
				if (!_.isNull(selectedScheudledInfo.frequency.time) && _.has(selectedScheudledInfo.frequency, "time")) {//NOSONAR
					updatedTimeElement = {
						...formElement,
						value: { label: selectedScheudledInfo.frequency.time, value: selectedScheudledInfo.frequency.time }//NOSONAR
					};
				}
				else {//NOSONAR
					updatedTimeElement = {
						...formElement,
						value: null
					};
				}
			}
			else if (_.isEqual(element.value, selectedScheudledInfo.frequency.type) && _.has(selectedScheudledInfo.frequency, "time")) {//NOSONAR
				if (this.isSelectedScheduledParent(form, selectedScheudledInfo)) {//NOSONAR
					updatedTimeElement = {
						...formElement,
						value: { label: selectedScheudledInfo.frequency.time, value: selectedScheudledInfo.frequency.time }
					};
				}
				else {//NOSONAR
					updatedTimeElement = { ...formElement, value: null };
				}

			}
			else if (!_.has(selectedScheudledInfo.frequency, "time")) {//NOSONAR
				updatedTimeElement = {
					...formElement,
					value: null
				};
			}
			else if (!_.isEqual(element.value, selectedScheudledInfo.frequency.type)) {//NOSONAR
				updatedTimeElement = {...formElement,
					value: null
				};
			}
		}
		return updatedTimeElement;
	}

	private updateElement = async (formElement: IFormElement, element: IFormElementUpdate, selectedScheudledInfo: IScheduleSelectedJobInfo, form: IForm): Promise<IFormElement> => {
		let updatedElement = {
			...formElement,
		};
		if (_.isEqual(formElement.id, "time")) {
			updatedElement = this.timeUpdatedElement(formElement, selectedScheudledInfo, element, form);
		}

		if (_.isEqual(formElement.id, element.formElement.id)) {
			let value = element.value;

			if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)) {
				value = this.updateSelectValue(formElement, value);
			}
			const validationHelper = new ValidationHelper();
			const ErrorMessage = validationHelper.validate(formElement, value);
			const IsValid = true;

			updatedElement = {
				...updatedElement,
				touched: true,
				value,
				errorMessage: ErrorMessage,
				isValid: IsValid,
			};
		}
		else if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)
			&& !_.isEqual(formElement.configuration.parentElements.indexOf(element.formElement.id), -1)) {
			const errorMessage = "";
			updatedElement = {
				...formElement,
				value: null,
				touched: false,
				errorMessage,
				isValid: _.isEqual(errorMessage.length, 0),
			};
		}
		return {
			...updatedElement
		};
	}

	updateSelectValue = (formElement: IFormElement, value: any): any => {

		const {
			isMulti,
			allowAll
		} = formElement.configuration;

		if (isMulti && allowAll) {
			const Value = value || [];
			const IsAllOptionSelected = (Value).findIndex(
				(option: IOption) => {
					return _.isEqual(option.value, ALL_OPTION_VALUE);
				}
			);

			if (
				_.isEqual(IsAllOptionSelected, Value.length - 1) &&
				_.gt(Value.length, 1)
			) {
				return [ALL_OPTION_OBJ];
			} else if (
				!_.isEqual(IsAllOptionSelected, -1) &&
				_.gt(Value.length, 1)
			) {
				return Value.filter(
					(option: IOption) => !_.isEqual(option.value, ALL_OPTION_VALUE)
				);
			}
		}
		return value;
	}
	public getValidValue = (
		element: any
	): any => {
		if (element.validations.required) {
			return element.isValid;
		}
		else {
			return true;
		}
	}
	public async buildEditForm(form: IForm, dataSources: any): Promise<IForm> {
		let Form = {
			...form,
			groups: form.groups.map((formGroup: IFormGroup) => {
				return {
					...formGroup,
					elements: formGroup.elements.map((formElement: IFormElement) => {
						const element = this.buildEditFormElement(formElement, dataSources);
						return {
							...element,
							isValid: element.validations.required ? element.isValid : true,
							touched: false,
							readOnly: !_.isUndefined(element.configuration.readOnly) ? element.configuration.readOnly.newForm : element.readOnly
						};
					}),
				};
			}),
		};
		const validationHelper = new ValidationHelper();
		Form = validationHelper.isValidForm(Form);
		return Form;
	}

	public getObject = (
		dataSources: any
	): any => {
		return {
			job: dataSources.jobScheduleInfo.job_name,
			active: dataSources.jobScheduleInfo.active,
			type: dataSources.jobScheduleInfo.frequency.type,
			schedule_week_day: _.isEqual(dataSources.jobScheduleInfo.frequency.type, "Weekly") ? dataSources.jobScheduleInfo.frequency.value : null,
			schedule_month: _.isEqual(dataSources.jobScheduleInfo.frequency.type, "Monthly") ? dataSources.jobScheduleInfo.frequency.value : null,
			startDate: dataSources.jobScheduleInfo.frequency.startDate,
			endDate: dataSources.jobScheduleInfo.frequency.endDate,
			time: dataSources.jobScheduleInfo.frequency.time
		};

	}

	public getFormValue = (
		formElement: IFormElement,
		dataSource: any,
		Data: any
	): any => {
		const DefaultValue = formElement.configuration.defaultValue;
		let value = "";
		if (_.isNull(dataSource.jobScheduleInfo)) {
			value = DefaultValue;
		}
		else {
			value = Data[formElement.id];
		}
		return value;


	}


	public buildEditFormElement = (
		formElement: IFormElement,
		dataSources: any
	): IFormElement => {
		let element: any ;
		const readOnlyTitle = formElement.readOnlyOptions?.keys.title || "";
		const readOnlySubtitle = formElement.readOnlyOptions?.keys.subtitle || "";
		const Data = _.isNull(dataSources.jobScheduleInfo) ? "" : this.getObject(dataSources);

		switch (formElement.type) {//NOSONAR
			case ELEMENT_TYPES.SELECT_INPUT: {//NOSONAR
				element = {
					...formElement,
					configuration: {
						...formElement.configuration,
						dataSource: formElement.configuration.dataSource,
					},
					value: this.getSelectValue(formElement, Data),
					visible: !_.isNull(this.getSelectValue(formElement, Data)) && !_.isUndefined(this.getSelectValue(formElement, Data)) ? true : _.isEmpty(formElement.configuration.dependentValue) ? true : false//NOSONAR
				};
				break;
			}
			
			case ELEMENT_TYPES.TEXT_INPUT:
				element = {
					...formElement,
					value: dataSources[formElement.id],
					readOnlyOptions: {
						title: dataSources[readOnlyTitle],
						subtitle: dataSources[readOnlySubtitle]
					}
				};
				break;
			case ELEMENT_TYPES.DISPLAY_TEXT:
				element = {
					...formElement,
					value: dataSources[formElement.id]
				};
				break;
			default:
				element = {
					...formElement,
					value: this.getFormValue(formElement, dataSources, Data),
				};
				break;
		}
		return {
			...element,
		};
	};

	private getSelectValue = (formElement: IFormElement, formData: any): any => {
		if (_.isNull(formData[formElement.id])) {
			return null;
		}

		return formElement.configuration.dataSource.find((option: IOption) =>
			_.isEqual(option.value, formData[formElement.id]));

	}

	/**
* Validate parent and child relationship for date and select
*
* @param {forms} IForm[].
* @return {UpdatedForms} IForm[].
*/
	validateParentChildRelationship = (form_info: IForm): IForm => {
		let UpdatedForm_info = ({
			...form_info,
			groups: form_info.groups.map((group: IFormGroup) => ({
				...group,
				elements: group.elements.map((element: IFormElement) => ({ ...element }))
			}))
		});

		const FormGroups_info = UpdatedForm_info.groups;
		for (let iFormGroup = 0; iFormGroup < FormGroups_info.length; iFormGroup++) {
			const FormElements = FormGroups_info[iFormGroup].elements;

			for (let iFormElement = 0; iFormElement < FormElements.length; iFormElement++) {
				const FormElement = FormElements[iFormElement];

				const Element_info: IFormElement = { ...FormElement };
				const ParentElements = Element_info.configuration.parentElements;

				if (!_.isEqual(ParentElements.length, 0)) {
					Element_info.value = null;
					Element_info.touched = false;
					Element_info.disabled = true;
				}

				FormElements[iFormElement] = {...FormElements[iFormElement],...Element_info};
			}
			FormGroups_info[iFormGroup] = {...FormGroups_info[iFormGroup],elements: FormElements};
		}
		UpdatedForm_info = {...UpdatedForm_info,groups: FormGroups_info};
		return UpdatedForm_info;
	}

}