import React, { useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import style from "./style";
import TiktokLoginIcon from "../../../../../assets/icons/tiktok_icon.webp";
import IconSuccess from "../../../../Icons/SuccessIcon";
import _ from "lodash";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  appid: any;
  redirectURL: any;
  rid: any;
  AuthGroup: any
};

export const TiktokAuth = (props: props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { appid, rid, AuthGroup } = props;
  const visibllity = (!_.isEmpty(AuthGroup.value) && !_.isNull(AuthGroup.value)) ? "visible" : "hidden";

  useEffect(() => {
    window.addEventListener("message", (e) => {
      if (e.data.redirect) {
        history.push(`/tiktokhandler${e.data.redirect}`);
      }
    });
  }, []);

  const openNewTab = (): void => {

    const embed = location.search;
    const callback_url = `${window.origin}/tt/callback`;
    const width = 599;
    const height = 599;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;
    const features = `width=${width},height=${height},left=${left},top=${top},scrollbars=yes,resizable=yes`;
    window.open(`https://ads.tiktok.com/marketing_api/auth?app_id=${appid}&state=${encodeURIComponent(embed)}&redirect_uri=${encodeURIComponent(callback_url)}&rid=${rid}`, "childwindow", features);
  };

  return (
    <div style={{ display: "flex", flexWrap: "nowrap" }}>
      <div className={classes.tiktokButtonContainer} onClick={openNewTab}>
        <img src={TiktokLoginIcon} width="20" height="20" />
        <div className={classes.tiktokButton}>Login with TikTok for Business</div>
      </div>
      <div style={{ paddingLeft: "24px", marginTop: "10px", visibility: visibllity }}>
        <IconSuccess />
      </div>
    </div>
  );
};
