

import { Theme } from "@material-ui/core";
import COLORS from "../../../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	textContainer:{
		height:36,
		wordBreak: "break-all"
	},
	textContainerEllipse:{
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 2,
		wordBreak: "break-all"
	  },
	  textContainerEllipseOneLine:{
		overflow: "hidden",
		textOverflow: "ellipsis",
		display: "-webkit-box",
		WebkitBoxOrient: "vertical",
		WebkitLineClamp: 1,
		wordBreak: "break-all"
	  },
	  singleLineTextContainer:{
		  whiteSpace: "nowrap",
		  overflow:"hidden",
		  textOverflow:"ellipsis",
		  paddingRight:"0.5rem"
	  },
	  label:{
		fontSize:12,
		color:COLORS.GRAY
	},
	content:{
		fontSize:12,
		color:COLORS.GRAY,
		fontWeight: theme.typography.fontWeightBold,
	},
	labelContainer:{
		width:"100%"
	},
});

export default style;