import React from "react";
import * as _ from "lodash";
import { ConfirmationDialog } from "../../../../Dialogs/Confimation";
import { IDialogType } from "./IDialog";
import { IFileUpload } from "../../../Forms/interface/File/IFileUpload";

// Props type
type props = {
  onClose: any;
  onSubmit: any;
  type: IDialogType;
  files: IFileUpload[];
  fileTypeName?: string;
};

// UI Bucket Upload Alert
export const UIBucketUploadAlert = (props: props): React.JSX.Element => {
  const { onSubmit, onClose, type, files, fileTypeName } = props;

  const FILE_TYPE_NAME = fileTypeName || "file";

  let dialogHeading = "";
  let dialogBody = "";
  let btnSubmitTxt = "";
  let btnCancelTxt = "";
  if (_.isEqual(type, "delete")) {
    dialogHeading = `Delete the ${FILE_TYPE_NAME}`;
    dialogBody = `Do you want to delete the ${FILE_TYPE_NAME}?`;
    btnSubmitTxt = "Delete";
    btnCancelTxt = `Keep ${_.capitalize(FILE_TYPE_NAME)}`;
  } else if (_.isEqual(type, "replace")) {
    dialogHeading = `Overwrite existing ${FILE_TYPE_NAME}${
      _.gt(files.length, 1) ? "s" : ""
    }?`;
    dialogBody = `Do you want to overwrite existing ${FILE_TYPE_NAME}${
      _.gt(files.length, 1) ? "s" : ""
    }?`;
    btnSubmitTxt = "Continue";
    btnCancelTxt = `Keep ${_.capitalize(FILE_TYPE_NAME)}${
      _.gt(files.length, 1) ? "s" : ""
    }`;
  } else if (_.isEqual(type, "cancel")) {
    dialogHeading = "Cancel Upload";
    dialogBody = `Do you want to cancel the ${FILE_TYPE_NAME} upload?`;

    btnSubmitTxt = "Yes";
    btnCancelTxt = "No";
  }

  return (
    <ConfirmationDialog
      onClose={onClose}
      onSubmit={onSubmit}
      heading={dialogHeading}
      body={dialogBody}
      cancelBtnText={btnCancelTxt}
      submitBtnText={btnSubmitTxt}
      onCloseDialog={onClose}
      isDeleteAction={_.isEqual(type, "cancel") || _.isEqual(type, "delete")}
    />
  );
};
