import React, { useEffect, useRef, useState } from "react";
import { makeStyles, createStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import UITooltip from "../Tooltip";
import style from "./style";
import EllipsisText from "react-text-overflow-middle-ellipsis";
import * as _ from "lodash";

type props = {
  showTooltip: boolean;
  title: string;
};

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const MiddleTextEllipsis = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { title, showTooltip } = props;
  const textElementRef: any = useRef();
  const [hoverStatus, setHover] = useState(false);
  
  const Title = (title || "").toString().trim();
  const compareSize = (): any => {
    if (_.isNull(textElementRef) || _.isUndefined(textElementRef.current)) {
      return;
    }

    const compare = _.gt(
      textElementRef.current?.scrollWidth,
      textElementRef.current?.clientWidth
    );
    _.isEqual(showTooltip, true) ?
      setHover(compare) : setHover(false);
  };
  const reSize = (): any => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  };

 
  useEffect(() => {
    reSize();
  }, []);

  useEffect(() => {
    reSize();
  }, [Title]);



  return (
    <UITooltip disableTooltip={!hoverStatus} title={Title || ""} >
      <span >
        <span  className={classes.ellipsisContainer} ref={textElementRef}>
          {Title}
        </span>
        <EllipsisText text={Title} />

      </span>
    </UITooltip>
  );
};
