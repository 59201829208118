const style: any = (): any => {
	return {
		toggleButton: {
			padding: "7px 17px",
			marginLeft: "0px !important",
			borderRadius: 0,
			border: 0,
			backgroundColor: "#FFFF",
			height: 36,
			color: "#242c34 !important",
			fontSize: 14,
			fontWeight: "normal" as const,
			textTransform: "none" as const,

		},
		toggleButtonLifecycle: {
			padding: "7px 16.4px",
			marginLeft: "0px !important",
			borderRadius: 0,
			border: 0,
			backgroundColor: "#FFFF",
			height: 36,
			color: "#242c34 !important",
			fontSize: 14,
			fontWeight: "medium" as const,
			textTransform: "none" as const,
		},
		toggleButtonGroup: {
			display: "inline-block",
			border: "1px solid #d0d5da",
			borderRadius: 2
		},
		selectedButton: {
			border: "1px solid #3a91e8 !important",
			borderRadius: 2,
			boxShadow: "0px 1px 4px 0px rgba(21, 118, 214, 0.15)",
			backgroundColor: "#f0f8ff !important",
		}
	};
};

export default style;