

export const PORTAL_TOOL_CONTENT_USER_DISABLED_FIELD: any = {
	toolname: true,
	ToolGroupMapping: true,
	tooltypeid: true,
	regionid: true,
	marketid: true,
	clientid: true,
	agencyid: true,
	templatescriptpath: true,
	authlogincontent: true,
	formsubmitapiendpoint: true,
	formupdateapiendpoint: true,
	isfiledownloadapi: true,
	loadlastsaveddata: true,
	toolformtypeid: true,
	isserviceaccountauth: true,
	ismaintenance: true,
	resetauthorization: true,
	isgrid: true,
	isschedule: true,
	ToolPersistButtons: true,
};
