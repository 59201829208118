import * as _ from "lodash";
import { IFormElementValue } from "../../../../../../UI/Elements/Forms/interface/IFormElementValue";
import { ELEMENT_TYPES } from "../../../../configuration";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../../../Interfaces/Form/IForm";
import { IFormGroup } from "../../../../Interfaces/Form/IFormGroup";
import { MultiSelectHelper } from "../Forms/SelectInput/MultiSelect";
import { LengthValidation } from "./Length";
import { RequiredValidation } from "./Required";

// Validation helper for forms
export class ValidationHelper {
  private requiredValidation: RequiredValidation;
  private lengthValidation: LengthValidation;
  private multiSelectHelper: MultiSelectHelper;

  constructor() {
    this.requiredValidation = new RequiredValidation();
    this.lengthValidation = new LengthValidation();
    this.multiSelectHelper = new MultiSelectHelper();
  }

  /**
   * Validate forms
   *
   * @param {IForm[]} forms.
   * @param {IElementUpdate} element.
   * @param {boolean} isDelayed.
   * @return {string} errorMessages.
   */
  isValidForm = (form: IForm): IForm => {
    let formIsValid = true;
    let formIsVisible = false;

    return {
      ...form,
      groups: form.groups.map((group: IFormGroup) => {
        let groupIsVisible = false;
        const Group: IFormGroup = {
          ...group,
          elements: group.elements.map((formElement: IFormElement) => {
            let isElementValid = formElement.isValid;

            if (
              _.isNull(formElement.value) &&
              formElement.validations.required
            ) {
              isElementValid = false;
            }

            if (!formElement.visible) {
              isElementValid = true;
            }
            formIsValid = formIsValid && isElementValid;
            groupIsVisible = groupIsVisible || formElement.visible;

            return {
              ...formElement,
            };
          }),
          isVisible: groupIsVisible,
        };
        formIsVisible = formIsVisible || groupIsVisible;

        return Group;
      }),
      isVisible: formIsVisible,
      isValid: formIsValid,
    };
  };
  
  isValidBulkEditForm = (form: IForm): IForm => {
    let formIsValid = true;
    let formIsVisible = false;

    return {
      ...form,
      groups: form.groups.map((group: IFormGroup) => {
        let groupIsVisible = false;
        const Group: IFormGroup = {
          ...group,
          elements: group.elements.map((formElement: IFormElement) => {
            const isElementValid = formElement.isValid;
            formIsValid = formIsValid && isElementValid;
            groupIsVisible = groupIsVisible || formElement.visible;

            return {
              ...formElement,
            };
          }),
          isVisible: groupIsVisible,
        };
        formIsVisible = formIsVisible || groupIsVisible;

        return Group;
      }),
      isVisible: formIsVisible,
      isValid: formIsValid,
    };
  };

  /**
   * Validate form element
   *
   * @param {IFormElement} formElement.
   * @param {any} elementValue.
   * @return {string} errorMessages.
   */
  validateBulkEdit = (formElement: IFormElement): string => {
    let errorMessage = "";
    const ELEMENT_CONFIG = formElement.configuration;
    const IsEmptyField = this.requiredValidation.isEmptyElementValue(
      formElement,
      formElement.value
    );
    if (
      IsEmptyField &&
      _.isEqual(formElement.disabled, false) &&
      !_.isEqual(formElement.readOnly, true) &&
      _.isEqual(formElement.validations.required, true)
    ) {
      errorMessage = `${ELEMENT_CONFIG.title} is required.`;
    }
    return errorMessage;
  };

  validate = (formElement: IFormElement, elementValue: any): string => {
    let errorMessage = "";

    const IsEmptyField = this.requiredValidation.isEmptyElementValue(
      formElement,
      elementValue
    );

    // If the field is empty and not required
    if (IsEmptyField && !formElement.validations.required) {
      return errorMessage;
    }

    // Required field validation
    if (formElement.validations.required) {
      errorMessage = this.requiredValidation.validate(
        formElement,
        elementValue
      );

      if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
    }

    if (_.isEqual(formElement.type, ELEMENT_TYPES.TEXT_INPUT)) {
      if (!_.isUndefined(formElement.validations.len)) {
        errorMessage = this.lengthValidation.validate(
          formElement,
          elementValue
        );
        if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
      }
    }

    return errorMessage;
  };

  /**
   * Check if the current and previous element value are same or not
   *
   * @param {IFormElement} formElement.
   * @param {IFormElementValue} element.
   * @return {boolean}
   */
  isPreviousAndCurrentValueAreSame = (
    formElement: IFormElement,
    element: IFormElementValue
  ): boolean => {
    if (
      _.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT) &&
      formElement.configuration.isMulti
    ) {
      const PrevValue = this.multiSelectHelper.getMultiSelectValues(
        element.previousValue || []
      );
      const CurrentValue = this.multiSelectHelper.getMultiSelectValues(
        element.currentValue || []
      );

      return _.isEqual(PrevValue, CurrentValue);
    }

    let previousValue = element.previousValue;
    let currentValue = element.currentValue;

    if (
      _.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT) &&
      !formElement.configuration.isMulti
    ) {
      previousValue = _.isNull(previousValue) ? null : previousValue.value;
      currentValue = _.isNull(currentValue) ? null : currentValue.value;
    }

    previousValue = (previousValue || "").toString().trim();
    currentValue = (currentValue || "").toString().trim();

    return _.isEqual(currentValue, previousValue);
  };
}
