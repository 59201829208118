import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import Checkbox from "@material-ui/core/Checkbox";
import classNames from "classnames";

type props = any;

// Checkbox input type component
function CheckboxComponent(props: props): React.JSX.Element {
    const { handleChange, checked, classes, isIndeterminate } = props;
    return (
        <Checkbox
            checked={checked}
            className={classes.root}
            disableRipple
            color="primary"
            indeterminate={!!isIndeterminate}
            checkedIcon={
                <span className={classNames(classes.icon, classes.checkedIcon)} />
            }
            indeterminateIcon={
                <span className={classNames(classes.icon, classes.indeterminateIcon)} />
            }
            onChange={handleChange}
            icon={<span className={classes.icon} />}
            inputProps={{ "aria-label": "decorative checkbox" }}
        />
    );
}

export default withStyles(styles)(CheckboxComponent);
