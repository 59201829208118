import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import {
  Grid,
  Container,
  Theme,
  Button,
  Box,
  LinearProgress,
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Header } from "./Header";
import { ADMIN_GET_ALL_TOOLS, ADMIN_GET_TOOL_PAGE } from "../../api/paths";
import { Axios } from "../../api/service/Axios";
import { IForm } from "./Interface/IForm";
import { UIFormSelection } from "./Form/Elements/FormSelection";
import { FormBuilder } from "./Form";
import { ToolForms } from "./Form/Configuration/Form";
import style from "./style";
import { UIOutlineButton } from "../../UI/Buttons";
import { IFormElementUpdate } from "./Interface/FormElement/IFormElementUpdate";
import { EditFormHelper, NewFormHelper } from "./Helpers/Forms";
import { ValidationHelper } from "./Helpers/Validations";
import { CircularProgress } from "../../UI/Progress";
import { ENUMS } from "./Form/ElementTypes";
import { IOption } from "../../UI/Elements/Forms/interface/IOption";
import { SubmitFormHelper } from "./Helpers/Forms/Submit";
import SnackbarAlert from "../../UI/Snackbar/SnackbarAlert.component";
import { UserPermissionHelper } from "../../Layout/Routes/Permissions/Helper";
import { USER_PERMISSIONS_CODES } from "../../Layout/Routes/Permissions/UserPermissions";
import { catchError } from "../Mapping/Utility/ErrorMessage";
import { FormElementHelper } from "./Helpers/Forms/Element";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));
const userPermissionHelper = new UserPermissionHelper();

enum API_CALL_TYPE {
  FETCH = 1,
  UPDATE = 2,
  NONE = 3,
}

type ESnackbarAlert = "success" | "error" | "info" | "warning";

const AdminComponent = (): React.JSX.Element => {
  const [pageData, setPageData] = useState<any>(null);
  const [initLoader, setInitLoader] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [linearLoader, setLinearLoader] = useState<boolean>(false);

  const [toolSelection, setToolSelection] = useState<any>({
    selectionType: ENUMS.TOOL_SELECTION_TYPES.EXISTING,
    toolName: null,
    apiCallType: API_CALL_TYPE.FETCH,
  });

  const INITIAL_DATA = useSelector((state: any) => state.initial);

  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  const classes = useStyles();
  const bundle = useSelector((state: any) => state.initial.bundle);

  const setAlert = (message: string, variant: ESnackbarAlert): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const setNewForm = (dataSources: any): void => {
    const helper = new NewFormHelper();
    let _forms = helper.buildNewForm(dataSources, ToolForms);
    const formElementHelper = new FormElementHelper();
    _forms = formElementHelper.findDependentParentElements(_forms);
    _forms = formElementHelper.findDisabledChildElements(_forms, INITIAL_DATA.userInfo);
    setLinearLoader(true);
    setPageData((prevState: any) => ({
      ...prevState,
      dataSources,
      forms: [],
    }));

    const timeout = setTimeout(() => {
      setPageData((prevState: any) => ({
        ...prevState,
        dataSources,
        forms: _forms,
      }));
      setLinearLoader(false);
      clearTimeout(timeout);
    }, 500);
  };

  const getDataSources = async (callback: any): Promise<void> => {
    await Axios.get(ADMIN_GET_ALL_TOOLS)
      .then((response: any) => {
        setNewForm(response.data);
        callback(false);
      })
      .catch((err) => {
        const errorMessage = catchError(err, bundle.ADMIN_TOOLS_ERROR_MSG);
        setAlert(errorMessage, "error");
        callback(true);
      });
  };

  useEffect(() => {
    getDataSources(() => {
      setInitLoader(false);
    });
  }, []);

  if (initLoader) {
    return <CircularProgress />;
  }

  const onToolOptionSelection = (toolSelectionType: number): void => {
    setNewForm(pageData.dataSources);

    setToolSelection((prevState: any) => ({
      ...prevState,
      apiCallType: API_CALL_TYPE.NONE,
      selectionType: toolSelectionType,
      toolName: null,
    }));
  };

  const onToolChange = (selectedOption: IOption): void => {
    if (_.isNull(selectedOption)) {
      return;
    }
    if (
      !_.isNull(toolSelection.toolName) &&
      _.isEqual(selectedOption.value, toolSelection.toolName.value)
    ) {
      return;
    }

    setToolSelection((prevState: any) => ({
      ...prevState,
      toolName: selectedOption,
      apiCallType: API_CALL_TYPE.FETCH,
    }));
    setLoading(true);
    Axios.get(ADMIN_GET_TOOL_PAGE(selectedOption.value), {})
      .then((response: any) => {
        setLoading(false);
        const formHelper = new EditFormHelper();
        const formElementHelper = new FormElementHelper();
        const validationHelper = new ValidationHelper();
        let _forms = formHelper.buildEditForm(
          pageData.dataSources,
          response.data,
          INITIAL_DATA.userInfo,
          ToolForms
        );
        _forms = formElementHelper.findDependentParentElements(_forms);
        _forms = formElementHelper.findDisabledChildElements(_forms, INITIAL_DATA.userInfo);

        _forms = validationHelper.isValidForms(_forms);

        setPageData((prevState: any) => ({
          ...prevState,
          forms: _forms,
        }));
        setToolSelection((prevState: any) => ({
          ...prevState,
          toolName: selectedOption,
          apiCallType: API_CALL_TYPE.NONE,
        }));
      })
      .catch((err) => {
        const errorMessage = catchError(err, bundle.ADMIN_TOOL_ERROR_MSG);
        setLoading(false);
        setAlert(errorMessage, "error");
      });
  };

  const onChange = (element: IFormElementUpdate): void => {
    const editFormHelper = new EditFormHelper();
    const validationHelper = new ValidationHelper();
    const formElementHelper = new FormElementHelper();
    setPageData((prevState: any) => {
      let UpdatedForms: IForm[] = editFormHelper.updateForms(
        prevState.forms,
        element
      );
      UpdatedForms = formElementHelper.findDependentElements(
        UpdatedForms,
        element
      );

      UpdatedForms = formElementHelper.findDisabledElements(
        UpdatedForms,
        element
      );

      UpdatedForms = validationHelper.isValidElement(UpdatedForms, element);
      UpdatedForms = validationHelper.isValidForms(UpdatedForms);

      return {
        ...prevState,
        forms: UpdatedForms,
      };
    });
  };

  const onSaveSuccess = async (alertMessage: string): Promise<void> => {
    await getDataSources(() => {
      setAlert(alertMessage, "success");
      setLoading(false);
    });
    setToolSelection((prevState: any) => ({
      ...prevState,
      apiCallType: API_CALL_TYPE.NONE,
      selectionType: ENUMS.TOOL_SELECTION_TYPES.EXISTING,
      toolName: null,
    }));
  };

  const onSaveError = async (alertMessage: string): Promise<void> => {
    setAlert(alertMessage, "error");
    setLoading(false);
    setToolSelection((prevState: any) => ({
      ...prevState,
      apiCallType: API_CALL_TYPE.NONE,
    }));
  };

  const onSave = async (): Promise<void> => {
    const helper = new SubmitFormHelper();
    const formData = helper.getSubmitForm(pageData.forms);
    setLoading(true);
    setToolSelection((prevState: any) => ({
      ...prevState,
      apiCallType: API_CALL_TYPE.UPDATE,
    }));

    const ToolSelectionType = toolSelection.selectionType;
    const { NEW, EXISTING } = ENUMS.TOOL_SELECTION_TYPES;

    if (_.isEqual(ToolSelectionType, EXISTING)) {
      try {
        await helper.updateTool(toolSelection.toolName.value, formData);
        if (helper.isTouchedElement(pageData.forms)) {
          await helper.scheduleUpdateTool(toolSelection.toolName.value, formData);
        }
        await onSaveSuccess(bundle.ADMIN_UPDATE_TOOL_SUCCESS_MSG);
      } catch (err: any) {
        const errorMessage = catchError(
          err,
          bundle.ADMIN_UPDATE_TOOL_ERROR_MSG
        );
        await onSaveError(errorMessage);
      }

    } else if (_.isEqual(ToolSelectionType, NEW)) {
      try {
        await helper.createTool(formData);
        await onSaveSuccess(bundle.ADMIN_CREATE_TOOL_SUCCESS_MSG);
      } catch (err: any) {
        const errorMessage = catchError(
          err,
          bundle.ADMIN_CREATE_TOOL_ERROR_MSG
        );
        await onSaveError(errorMessage);
      }
    }
  };

  let forms = <span />;
  let disableSaveButton = true;
  if (!_.isNull(pageData) && !_.isEqual(pageData.forms.length, 0)) {
    disableSaveButton = !pageData.forms[0].isValid || pageData.forms[0].loading;
    const FORMS = pageData.forms.map((form: IForm) => {
      return <FormBuilder form={form} onChange={onChange} key={form.id} />;
    });
    forms = (
      <>
        <Grid container justifyContent="space-between">
          {FORMS}
        </Grid>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Button
            disabled={disableSaveButton}
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            Save
          </Button>
          <UIOutlineButton
            cssStyle={{
              marginLeft: "0.75rem",
            }}
            isOutline={true}
            onClick={() =>
              onToolOptionSelection(ENUMS.TOOL_SELECTION_TYPES.EXISTING)
            }
            btnText="Cancel"
          />
        </Box>
      </>
    );
  }

  if (
    _.isEqual(toolSelection.apiCallType, API_CALL_TYPE.FETCH) ||
    (_.isNull(toolSelection.toolName) &&
      _.isEqual(
        toolSelection.selectionType,
        ENUMS.TOOL_SELECTION_TYPES.EXISTING
      ))
  ) {
    forms = <></>;
  }

  return (
    <>
      <Helmet>
        <title>{bundle.MENU_ADMIN_TITLE}</title>
      </Helmet>
      <Header name={bundle.ADMIN_PAGE_TITLE} data-testid="AdminComponentTest" />
      <Container maxWidth="lg">
        <Grid container className={classes.toolContentWrapper}>
          <Grid container item className={classes.infoWrapper}>
            {loading && <CircularProgress />}
            {linearLoader ? (
              <>
                <div className={classes.backdrop}></div>
                <LinearProgress />
              </>
            ) : (
              <div className={classes.linearLoaderPlaceholer}></div>
            )}
            <div className={classes.toolContent}>
              <Grid container>
                <UIFormSelection
                  disableExistingToolOption={false}
                  disableNewToolOption={
                    !userPermissionHelper.isAllowedPermission(
                      INITIAL_DATA.userInfo,
                      USER_PERMISSIONS_CODES.AP_MANAGE_TOOL_CONFIG
                    )
                  }
                  toolSelectionType={toolSelection.selectionType}
                  onToolOptionSelection={onToolOptionSelection}
                  dataSource={
                    _.isNull(pageData) ? [] : pageData.dataSources.ToolNameList
                  }
                  toolName={toolSelection.toolName}
                  onToolChange={onToolChange}
                />
              </Grid>
              {forms}
            </div>
          </Grid>
        </Grid>
        <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
        />
      </Container>
    </>
  );
};

export default AdminComponent;
