import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import classnames from "classnames";
import style from "./style";
import { IFormElement } from "../../../../../Forms/interface/IFormElement";
import { TableSortIcons } from "../../../../../../Icons";
import { IGridHeader } from "../../../../../Forms/interface/Grid/IGridHeader";
import { IFilter } from "../IFilter";
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  formElement: IFormElement;
  sortHandler: any;
  filter: IFilter;
};

export const DataGridHead = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { formElement, sortHandler, filter } = props;
  const { orderBy, order } = filter;
  const d = formElement?.customConfiguration?.grid?.headerData || [];
  const GridHeaders = d
  .filter((header: IGridHeader) => !header.hide)
    .map((header: IGridHeader) => {
      let sortIcon = TableSortIcons.DEFAULT;

      if (_.isEqual(orderBy, header.field) && _.isEqual(order, "desc")) {
        sortIcon = TableSortIcons.UP;
      } else if (_.isEqual(orderBy, header.field) && _.isEqual(order, "asc")) {
        sortIcon = TableSortIcons.DOWN;
      }

      return (
        <div
          key={header.field}
          onClick={() => {
            let Order = "asc";
            if (_.isEqual(header.field, orderBy)) {
              Order = _.isEqual(order, "asc") ? "desc" : "asc";
            }
            sortHandler(header.field, Order);
          }}
          className={classes.gridHeaderContainer}
        >
          <div className={classes.gridHeaderName}>
            {header.headerName}
            <span className={classes.sortingIcon}>{sortIcon}</span>
          </div>
        </div>
      );
    });
  GridHeaders.push(
    <div
      key="grid-action"
      className={classnames(
        classes.gridHeaderContainer,
        classes.gridActionCell
      )}
    ></div>
  );
  return <div className={classes.gridHeader}>{GridHeaders}</div>;
};
