import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import { useHistory } from "react-router-dom";
import { withErrorHandler, withSnackbar } from "../../HOC";
import Channels from "./Tool/Channel";
import {
  getToolList,
  getFilterOptions,
  resetToolList,
} from "../../store/actions/Automation";
import Tools from "./Tool";
import FilterOptionContext from "../Context/FilterOptionContext";
import { CircularProgress } from "../../UI/Progress";
import FormHelper from "../../helpers/FormHelper";
import {
  updateFilterState,
  updateSortLabel,
} from "../../store/actions/Automation/FilterState";
import { IToolList } from "../../store/reducers/interfaces/IToolList";
import { UserPermissionHelper } from "../../Layout/Routes/Permissions/Helper";

// Set initial Filtered state
const filterCategories = {
  channel_id: 0,
  tooltype_id: 0,
  lifecycle_id: 0,
  toollabel_id: 0,
  include_beta: 0,
  platform_id: [0],
  search: "",
  order_by: "asc",
  include_favorite: 0,
};

// Store initial filter state in localstorage (only once)
if (_.isNull(localStorage.getItem("filterCategories"))) {
  localStorage.setItem("filterCategories", JSON.stringify(filterCategories));
}

const initialFilter = (value: any): void => {
  if (_.isUndefined(value)) {
    localStorage.setItem("filterCategories", JSON.stringify(filterCategories));
  }
};

if (!_.isNull(localStorage.getItem("filterCategories"))) {
  const SelectedFilterValues: any = localStorage.getItem("filterCategories");
  const FilterJSON = JSON.parse(SelectedFilterValues);
  initialFilter(FilterJSON.lifecycle_id);
  initialFilter(FilterJSON.platform_id);
  initialFilter(FilterJSON.include_favorite);
  initialFilter(FilterJSON.toollabel_id);
}

let initialPageCount = 0;

// Landing Page parent component
function ToolsPageComponent(): React.JSX.Element {
  const formHelper = new FormHelper();
  const filter = formHelper.getLocalStorageState();
  const [stateFilter, setStateFilter] = useState(filter);
  const [filterCount, setFilterCount] = useState(0);
  const history = useHistory();

  const dispatch = useDispatch();

  const REDUX_STATE = useSelector((state: any) => ({
    FILTER_STATE: state.filter,
    BUNDLE: state.bundle,
    FILTER_OPTIONS: state.filterOptions,
    TOOL_LIST: state.toolList,
    UPDATE_REQUIRED: state.toolList.updateRequired,
    USER_INFO: state.initial.userInfo,
  }));

  // Call on initial loading
  useEffect(() => {
    const selectedFilter: any = localStorage.getItem("filterCategories");

    if (!_.isNull(selectedFilter)) {
      const Filter: any = JSON.parse(selectedFilter);
      setFilterCounter(Filter);
      const data = {
        channel_id: Filter.channel_id,
        include_beta: Filter.include_beta,
        include_favorite: Filter.include_favorite,
        lifecycle_id: Filter.lifecycle_id,
        order_by: Filter.order_by,
        platform_id: Filter.platform_id,
        search: Filter.search,
        toollabel_id: Filter.toollabel_id,
        tooltype_id: Filter.tooltype_id,
      };
      dispatch(resetToolList());
      dispatch(updateFilterState(data));
      dispatch(updateSortLabel(Filter.order_by));
      getFilteredData(true);
    } else if (
      REDUX_STATE.UPDATE_REQUIRED ||
      _.isEmpty(REDUX_STATE.TOOL_LIST.toolList)
    ) {
      dispatch(resetToolList());
      getFilteredData(true);
    }
    dispatch(getFilterOptions());
  }, []);

  const setFilterCounter = (filterObject: any): void => {
    const DEFAULT_FILTER: any = {
      tooltype_id: 0,
      toollabel_id: 0,
      include_beta: 0,
      platform_id: [],
      include_favorite: 0,
    };

    let counter = 0;
    for (const filterKey in DEFAULT_FILTER) {
      if (_.isEqual(filterKey, "platform_id")) {
        const PLATFORMS = filterObject[filterKey];
        counter += _.isEqual(PLATFORMS.indexOf(0), -1) ? 1 : 0;
      } else if (
        !_.isEqual(DEFAULT_FILTER[filterKey], filterObject[filterKey])
      ) {
        counter += 1;
      }
    }

    setFilterCount(counter);
  };

  // Update Selected filters state if channel, search and filter sidebar value changed
  const updateSelectedFilter = (data: any): void => {
    initialPageCount = 0;
    const Filter = formHelper.getLocalStorageState();

    setStateFilter((prevState: any) => ({
      ...prevState,
      ...Filter,
      ...data,
    }));
    dispatch(resetToolList());
    getFilteredData(true);
  };

  // Api call to fetch toolList as per filtered criteria
  const getFilteredData = async (flag: boolean): Promise<void> => {
    const userPermissionHelper = new UserPermissionHelper();
    if (userPermissionHelper.isOnlyRegularUser(REDUX_STATE.USER_INFO)) {
      const selectedFilter: any = localStorage.getItem("filterCategories");
      const Filter = JSON.parse(selectedFilter);
      const DATA = {
        ...Filter,
        include_beta: 0,
      };

      const SELECTED_OPTION = { ...stateFilter, ...DATA };

      setFilterCounter(SELECTED_OPTION);

      localStorage.setItem("filterCategories", JSON.stringify(SELECTED_OPTION));
    }
    const FILTERS = formHelper.getLocalStorageState();
    const SEARCH = _.isUndefined(FILTERS.search) ? "" : FILTERS.search;

    if (flag && _.isEqual(history.action, "POP")) {
      initialPageCount = 0;
    }

    setFilterCounter(FILTERS);

    const ToolListParameters: IToolList = {
      ...FILTERS,
      search: SEARCH,
      page_number: initialPageCount,
      record_limit: REDUX_STATE.TOOL_LIST.limit,
    };

    dispatch(getToolList(ToolListParameters));
  };

  // Handle Tab value Changed
  const handleChange = (
    event: React.ChangeEvent<any>,
    ChangedTabValue: any
  ): void => {
    const data = { ...stateFilter, ...{ channel_id: ChangedTabValue } };

    if (
      !_.isEqual(REDUX_STATE.FILTER_STATE.filter.channel_id, ChangedTabValue)
    ) {
      localStorage.setItem("filterCategories", JSON.stringify(data));
      updateSelectedFilter({ channel_id: ChangedTabValue });
      dispatch(updateFilterState(data));
    }
  };

  // Handle LifeCycle value Changed
  const handleLifeCycleChange = (
    event: React.ChangeEvent<any>,
    ChangedToggleValue: any
  ): void => {
    const data = { ...stateFilter, ...{ lifecycle_id: ChangedToggleValue } };

    if (!_.isEqual(ChangedToggleValue, null)) {
      if (
        !_.isEqual(
          REDUX_STATE.FILTER_STATE.filter.lifecycle_id,
          ChangedToggleValue
        )
      ) {
        localStorage.setItem("filterCategories", JSON.stringify(data));
        updateSelectedFilter({ lifecycle_id: ChangedToggleValue });
        dispatch(updateFilterState(data));
      }
    }
  };

  // Handle sort value Changed
  const handleSortChange = (
    event: React.ChangeEvent<any>,
    ChangedOptionValue: any
  ): void => {
    const data = { ...stateFilter, ...{ order_by: ChangedOptionValue } };

    if (
      !_.isEqual(REDUX_STATE.FILTER_STATE.filter.order_by, ChangedOptionValue)
    ) {
      localStorage.setItem("filterCategories", JSON.stringify(data));
      updateSelectedFilter({ order_by: ChangedOptionValue });
      dispatch(updateFilterState(data));
    }
  };

  // Handle New Button value Changed
  const handleNewButtonValueChange = (event: React.ChangeEvent<any>): void => {
    event.preventDefault();

    if (_.isEqual(REDUX_STATE.FILTER_STATE.filter.toollabel_id, 0)) {
      const data = { ...stateFilter, ...{ toollabel_id: 2 } };
      dispatch(updateFilterState(data));
      setFilterCounter(data);
      localStorage.setItem("filterCategories", JSON.stringify(data));
      updateSelectedFilter({ toollabel_id: 2 });
    } else if (_.isEqual(REDUX_STATE.FILTER_STATE.filter.toollabel_id, 2)) {
      const data = { ...stateFilter, ...{ toollabel_id: 0 } };
      dispatch(updateFilterState(data));
      setFilterCounter(data);
      localStorage.setItem("filterCategories", JSON.stringify(data));
      updateSelectedFilter({ toollabel_id: 0 });
    }
  };

  // Handle Favorite Button value Changed
  const handleFavoriteButtonValueChange = (
    event: React.ChangeEvent<any>
  ): void => {
    event.preventDefault();
    if (_.isEqual(REDUX_STATE.FILTER_STATE.filter.include_favorite, 0)) {
      const data = {
        ...stateFilter,
        ...{ include_favorite: 1 },
        ...{ page_number: 0 },
      };
      dispatch(updateFilterState(data));
      setFilterCounter(data);
      localStorage.setItem("filterCategories", JSON.stringify(data));
      updateSelectedFilter({ include_favorite: 1 });
    } else if (_.isEqual(REDUX_STATE.FILTER_STATE.filter.include_favorite, 1)) {
      const data = {
        ...stateFilter,
        ...{ include_favorite: 0 },
        ...{ page_number: 0 },
      };
      dispatch(updateFilterState(data));
      setFilterCounter(data);
      localStorage.setItem("filterCategories", JSON.stringify(data));
      updateSelectedFilter({ include_favorite: 0 });
    }
  };

  // Combined parameters to pass TabList component as props.
  const propsToTabList = {
    handleTabChange: handleChange,
    value: REDUX_STATE.FILTER_STATE.filter.channel_id,
    setFilterCounter: setFilterCounter,
  };

  // Combined parameters to pass ToolList component as props.
  const propsToToolList = {
    getFilteredData: getFilteredData,
    handleLifeCycleChange: handleLifeCycleChange,
    handleNewButtonValueChange: handleNewButtonValueChange,
    handleSortChange: handleSortChange,
    handleFavoriteButtonValueChange: handleFavoriteButtonValueChange,
    value: REDUX_STATE.FILTER_STATE.filter.lifecycle_id,
  };

  if (REDUX_STATE.FILTER_OPTIONS.loading) return <CircularProgress />;

  return (
    <FilterOptionContext.Provider
      value={{
        stateFilter,
        updateSelectedFilter,
      }}
    >
      <Channels {...propsToTabList} />
      <Tools
        {...propsToToolList}
        setFilterCounter={setFilterCounter}
        filterCount={filterCount}
      />
    </FilterOptionContext.Provider>
  );
}

export default withErrorHandler(withSnackbar(ToolsPageComponent));

