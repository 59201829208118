import { Theme } from "@material-ui/core";
import COLOR from "../../../../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	adhoc: {
		color: COLOR.DARK_GRAY,
		backgroundColor: COLOR.PROGRESS_PENDING_BAR
	},

	text: {
		display: "inline",
		padding: "3px 8px 2px 8px",
		fontSize: 10,
		fontWeight: theme.typography.fontWeightMedium,
		textTransform: "uppercase" as const,
	},
});

export default style;