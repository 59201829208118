import React from "react";
import { Snackbar } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import SnackbarContentWrapper from "./SnackbarContent.component";

type props = {
  handleClose?: any;
  open: boolean;
  anchorOrigin?: any;
  message: string;
  variant: any;
  onOpenCloseAlert: any;
  duration?: any;
};

// Snackbar alert
function SnackbarAlert(props: props): JSX.Element {
  const {
    message = "",
    variant = "info",
    open = false,
    onOpenCloseAlert,
    duration
  } = props;
  
  return (
    <Snackbar
      id={`id-${variant}-snackbar`}
      autoHideDuration={duration ?  duration : 30000}
      transitionDuration={{ enter: 300, exit: 200 }}
      key={`${variant}+${message}+Transition`}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      ClickAwayListenerProps={{ onClickAway: () => onOpenCloseAlert(true) }}
      onClose={() => onOpenCloseAlert(false)}
      TransitionComponent={Slide}
    >
      <SnackbarContentWrapper
        onClose={() => onOpenCloseAlert(false)}
        variant={variant}
        message={message}
        isOpen={open}
      />
    </Snackbar>
  );
}

export default SnackbarAlert;
