
import * as _ from "lodash";
import { ELEMENT_TYPES } from "../configuration";
import { IGridFilter } from "../Interfaces/Filter/IGridFilter";
import { IFormElement } from "../Interfaces/Form/FormElement/IFormElement";
import { IFormGroup } from "../Interfaces/Form/IFormGroup";
import { FilterFormHelper } from "./Forms/Filter";
import { UtilHelper } from "./Forms/Utility";

const getSelectSubmitData = (formElement: IFormElement): any => {
	const utilHelper = new UtilHelper();
	const filterHelper = new FilterFormHelper();
	if (formElement.configuration.isMulti
		&& !_.isNull(formElement.value)) {
		return utilHelper.isAllSelected(formElement.value) ? [] : filterHelper.getMultiSelectValue(formElement);
	}
	return _.isNull(formElement.value) ? null : formElement.value;
};


export const getFilterSubmitData = (gridFilter: IGridFilter): any => {
	const FilterData: any = {
		pageSize: gridFilter.pageSize,
		pageNumber: gridFilter.pageNumber + 1,
		order: gridFilter.order,
		orderBy: gridFilter.orderBy
	};

	gridFilter.sidebarFilter!.form.groups.forEach((formGroup: IFormGroup) => {

		formGroup.elements.forEach((formElement: IFormElement) => {
			if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)) {
				FilterData[formElement.id] = getSelectSubmitData(formElement);
			}
			else {
				FilterData[formElement.id] = formElement.value;
			}
		});
	});

	return {
		filterToolName: FilterData.toolName,
		filterJobName: FilterData.jobName,
		filterStatus: FilterData.status,
	};
};