import * as _ from "lodash";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IForm } from "../../Interface/IForm";


export class FormHelper {

	public ifHasChildElements = (forms: IForm[], formElement: IFormElement): boolean =>{

		const hasChildElements = false;

		for(const form of forms){
			for(const group of form.groups){
				for(const element of group.elements){
					if(!_.isEqual(element.configuration.parentElements.indexOf(formElement.id), -1)){
						return true;
					}
				}
			}
		}

		return hasChildElements;
	}

}
