
const style: any = (): any => ({
	infoWrapper: {
		padding: 12,
	},
	toolContentWrapper: {
		padding: "20px 28px 28px 28px",
	},
	toolContent: {
		width: "100%",
		backgroundColor: "#FFFF",
		minHeight: "calc(100vh - 30vh)",
		position: "relative",
		boder: "1px solid black"
	},
	authComponent: {
		height:"80%",
		width: "80%",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		display: "flex",
		flexDirection: "column",
		alignContent: "center",
		justifyContent: "center"
	},
	authComponentDiv : {
		paddingLeft: "48px"
	},
	
	authContentDiv: {
		padding: "30px 20px 0 20px",
	},
	buttonAuthcenter:{
	   textAlign:"center"
	}
});

export default style;
