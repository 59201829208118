import React from "react";
import * as _ from "lodash";
import { Box, Grid } from "@material-ui/core";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { UISelect } from "../Elements/Dropdown";
import { ELEMENT_TYPES } from "../../configuration";
import { UIErrorOrNoteMessage } from "../Elements/Child/ErrorOrNote";
import { IForm } from "../../Interfaces/Form/IForm";
import { UISwitch } from "../Elements/Switch";
import UIToggleButtons from "../Elements/ToggleButton";
import { UITextEllipses } from "../Elements/EllipsesText";
import { UIDisplayText } from "../Elements/DisplayText";

const getErrorMessage = (formElement: IFormElement): string => {
  let error_Message = "";
  if (formElement.apiError) {
    error_Message = formElement.apiError;
  } else {
    error_Message = formElement.touched ? formElement.errorMessage : "";
  }
  if (formElement.disabled || formElement.readOnly) {
    error_Message = "";
  } else {
    const HighlightOption_Error = formElement.highlightRemovedOptionError;
    error_Message = !_.isEqual(HighlightOption_Error.length, 0) ? HighlightOption_Error : error_Message;
  }
  return error_Message;
};

type props = {
  formGroupId: number;
  onChange: any;
  formElement: IFormElement;
  onCreateOption: any;
  formId: number;
  form: IForm;

};

export const FormElement = (props: props): React.JSX.Element => {
  const { formElement, onChange, form } = props;
  const GridColumns: any = formElement.configuration.gridColumns;
  const ErrorMessage = getErrorMessage(formElement);
  const Note = (formElement.configuration.note || "").toString().trim();
  const onElementChange = (event: any, value: any): void => {
    if (_.isEqual(formElement.type, ELEMENT_TYPES.TOGGLE_INPUT)) {
      if (!_.isNull(value)) {
        onChange({
          formElement: formElement,
          value,
        });
      }
      else {
        return;
      }
    }
    else {
      onChange({
        formElement: formElement,
        value,
      });
    }
  };

  let element = <></>;
  switch (formElement.type) {
    case ELEMENT_TYPES.SELECT_INPUT: {
      element = (
        <UISelect
          onChange={onElementChange}
          formElement={formElement}
          errorMessage={ErrorMessage}
          form={form}
        />
      );
      break;
    }

    case ELEMENT_TYPES.TEXT_INPUT: {
      element = (
        <UITextEllipses
        formElement={formElement}
        />
      );
      break;
    }

    case ELEMENT_TYPES.DISPLAY_TEXT: {
      element = (
        <UIDisplayText
          formElement={formElement}
        />
      );
      break;
    }

    case ELEMENT_TYPES.SWITCH: {
      element = (
        <UISwitch
          formElement={formElement}
          onChange={onElementChange}
        />
      );
      break;
    }
    case ELEMENT_TYPES.TOGGLE_INPUT: {
      element = (
        <UIToggleButtons
          formElement={formElement}
          handleChange={onElementChange}
        />
      );
      break;
    }
  }

  return (
    <Grid container item xl={GridColumns} lg={GridColumns} md={GridColumns} xs={GridColumns}>
      <Box
        style={{
          width: "100%",
        }}
        sx={{
          mr: 1,
          ml: 1,
        }}
      >
        {element}
        <UIErrorOrNoteMessage note={Note} errorMessage={ErrorMessage} />
      </Box>
    </Grid>
  );
};
