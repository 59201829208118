import { Theme } from "@material-ui/core";
import Colors from "../../../../../../Theme/colors";

const style: any = (theme: Theme): any => ({
	gridHeaderContainer: {
		display: "flex",
		alignItems: "center",
		flex: 1,
		height: 40,
		padding:"5px 0 4px 20px"
	},
	gridHeader: {
		display: "flex",
		alignItems: "center",
		flex: 1,
		height: 40,
	},
	gridHeaderName: {
		fontSize: "0.75rem",
		color: Colors.GRAY,
		fontWeight: 500,
		cursor: "pointer",
	},
	gridCell: {
		display:"flex",
		alignItems: "center",
		flex: 1,
		minWidth:0,
		padding:"5px 0 4px 20px"
	},
	gridEmptyCell:{
		border: `1px solid ${Colors.ERROR}`,
		width:"100%",
		height:40,
		borderRadius:2
	},
	gridCellText: {
		fontSize: "0.875rem",
		textOverflow: "ellipsis",
		overflow:"hidden",
		whiteSpace:"nowrap",
		minWidth:0,
		paddingLeft:13,
		paddingTop:2
	},
	gridCellInput:{
		fontSize: "0.875rem",
		width:"100%"
	},
	gridActionCell:{
		flex:"0 76px",
		paddingRight:20
	},
	gridRow: {
		display: "flex",
		height: 48,
		borderBottom:"1px solid rgba(224, 224, 224, 1)",
		"&:hover": {
			backgroundColor: "#f8f8f8",
      
			"& $deleteIcon":{
				visibility: "visible"
			}
		},
	},
	sortingIcon: {
		padding: "0px 5px",
		verticalAlign: "middle",
	},
	deleteIcon:{
		visibility: "hidden"
	},
	highlightNewRow:{
		animation: `$animHighlightNewRow 1500ms ${theme.transitions.easing.easeInOut}`
	},
	"@keyframes animHighlightNewRow":{
		"0%":{
			backgroundColor:Colors.YELLOW_COLOR,
		},
		"100%":{
			backgroundColor:Colors.WHITE,
		}
	}
});

export default style;
