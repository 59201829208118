import React from "react";
import { Drawer } from "@material-ui/core";
import { SidebarFormComponent } from "./Form";

type props = {
  formTitle: string;
  toggleDrawer: any;
  btnSaveText: string;
  btnCancelText: string;
  onSave: any;
  onCancel: any;
  onReset?: any;
  open: boolean;
  formData: any;
  isFilterForm: boolean;
  width?: number;
  bulkEditMode?: boolean;
  checkedCount?: number;
  statusValue?: any;
};

export const SidebarComponent: any = (props: props): React.JSX.Element => {
  const {
    formTitle,
    formData,
    btnSaveText,
    btnCancelText,
    onSave,
    onCancel,
    onReset,
    open,
    width,
    isFilterForm,
    bulkEditMode,
    checkedCount,
    statusValue
  } = props;

  return (
    <Drawer anchor="right" open={open}>
      {open && (
        <SidebarFormComponent
          formTitle={formTitle}
          formData={formData}
          btnSaveText={btnSaveText}
          btnCancelText={btnCancelText}
          onSave={onSave}
          onCancel={onCancel}
          onReset={onReset}
          width={width}
          isFilterForm={isFilterForm}
          bulkEditMode={bulkEditMode}
          checkedCount={checkedCount}
          statusValue={statusValue}
        />
      )}
    </Drawer>
  );
};
