import * as _ from "lodash";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IFormElementUpdate } from "../../Interface/FormElement/IFormElementUpdate";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { IOption } from "../../Interface/IOption";
import { UserPermissionHelper } from "../../../../Layout/Routes/Permissions/Helper";
import { USER_PERMISSIONS_CODES } from "../../../../Layout/Routes/Permissions/UserPermissions";

// Form element helper class
export class FormElementHelper {
    private userPermissionHelper: UserPermissionHelper;
    constructor() {
        this.userPermissionHelper = new UserPermissionHelper();
    }
    /**
     * Find dependents elements based on parent element
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return any.
     */
    findDependentElements = (forms: IForm[], element: IFormElementUpdate): any => {

        let updatedForms_data: any = forms.map((form: IForm) => ({
            ...form,
            groups: form.groups.map((formGroup: IFormGroup) => ({
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement) => {
                    let isVisible_element = formElement.visible;

                    if (!_.isEqual(formElement.configuration.dependsOn.indexOf(element.formElement.id), -1)) {
                        let elementValue = this.getElementValue({ ...element.formElement, value: element.value
                        });
                        elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase();
                        const Dependent_values = _.toLower(formElement.configuration.dependentValue || "").toString().trim();
                        if (_.isEqual(elementValue.length, 0)) {
                            isVisible_element = false;
                        } else {
                            isVisible_element = !_.isEqual(Dependent_values.indexOf(elementValue), -1);
                        }
                    }

                    return { ...formElement, visible: isVisible_element };
                })
            }))
        }));

        updatedForms_data = this.findDependentParentElements(updatedForms_data);
        return updatedForms_data;
    }

    /**
     * Disabled elements based on parent element value
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return any.
     */
    findDisabledElements = (forms: IForm[], element: IFormElementUpdate): any => {//NOSONAR
        return forms.map((form: IForm) => ({ //NOSONAR
            ...form,
            groups: form.groups.map((formGroup: IFormGroup) => ({//NOSONAR
                ...formGroup,
                elements: formGroup.elements.map((formElement: IFormElement) => {//NOSONAR
                    let isDisabled = formElement.disabled;
                    let value = formElement.value;
                    let isRequired = formElement.validations.required;
                    let error: any = formElement.errorMessage;
                    let isValidElement: any = formElement.isValid;

                    if (_.isEqual(formElement.id, "ToolPersistButtons") && _.isEqual(element.formElement.id, "isgrid")) {//NOSONAR
                        if ((_.isEqual(formElement.value, false) || _.isEmpty(formElement.value)) && _.isEqual(element.value, true)) {//NOSONAR
                            value = [{ value: 1, label: "All" }];
                            isRequired = true;
                        }
                        else {//NOSONAR
                            isRequired = false;
                            error = "";
                            isValidElement = true;

                        }
                    }
                    if (!_.isEqual(formElement.configuration.disabledBasedOn.indexOf(element.formElement.id), -1)) {//NOSONAR

                        const ElementValue = this.getElementValue({//NOSONAR
                            ...element.formElement,
                            value: element.value
                        });
                        const DisabledValue = formElement.configuration.disabledBasedOnValue;
                        isDisabled = _.isEqual(DisabledValue, ElementValue);
                        value = isDisabled ? false : value;//NOSONAR

                    }

                    return {
                        ...formElement,
                        errorMessage: error,
                        isValid: isValidElement,
                        validations: {
                            ...formElement.validations,
                            required: isRequired
                        },
                        disabled: isDisabled,
                        value
                    };
                })
            }))
        }));
    }
    /**
    * Get element value
    *
    * @param {IFormElement} element.
    * @return boolean.
    */
    getElementValue = (element: IFormElement): any => {//NOSONAR
        if (this.isEmptyElementValue(element, element.value)) {//NOSONAR
            return null;
        }
        let elementValue = element.value;

        if (_.isEqual(element.type, FORM_ELEMENT_TYPES.SELECT_INPUT)) {//NOSONAR
            if (element.configuration.isMulti) {
                elementValue = element.value.map((ele: IOption) => (ele.value));
            } else {
                // Single Select
                elementValue = element.value.value;
            }
        }
        return elementValue;
    }



    /**
     * Find dependents elements based on parent element
     *
     * @param {IForm[]} forms.
     * @param {IElementUpdate} element.
     * @return any.
     */
    findDependentParentElements = (forms: IForm[]): any => {//NOSONAR

        const Forms_array = forms;

        for (let iForm = 0; iForm < Forms_array.length; iForm++) { //NOSONAR
            const form_Groups = Forms_array[iForm].groups;
            for (let iFormGroup = 0; iFormGroup < form_Groups.length; iFormGroup++) { //NOSONAR
                const Elements = form_Groups[iFormGroup].elements;
                for (let iFormElement = 0; iFormElement < Elements.length; iFormElement++) { //NOSONAR
                    const formElement = Elements[iFormElement];
                    let is_Visible = formElement.visible;

                    const DependsOn: string[] = formElement.configuration.dependsOn;

                    if (!_.isEmpty(DependsOn)) { //NOSONAR
                        DependsOn.forEach((elementId: string) => {
                            const FormElement = this.findElementbyId(forms, elementId);
                            if (!is_Visible && FormElement.visible) { //NOSONAR
                                let elementValue = this.getElementValue({...FormElement,
                                    value: FormElement.value
                                });

                                elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase(); //NOSONAR
                                const Dependentvalues = _.toLower(formElement.configuration.dependentValue || "").toString().trim(); //NOSONAR
                                if (_.isEqual(elementValue.length, 0)) { //NOSONAR
                                    is_Visible = false;
                                } else {
                                    is_Visible = !_.isEqual(Dependentvalues.indexOf(elementValue), -1); //NOSONAR
                                }
                            } else {
                                is_Visible = FormElement.visible; //NOSONAR
                            }
                        });

                    }
                    Elements[iFormElement] = { ...Elements[iFormElement], visible: is_Visible
                    };
                }
            }
        }

        return Forms_array;
    }

    /**
    * Find disabled elements based on parent element
    *
    * @param {IForm[]} forms.
    * @param {IElementUpdate} element.
    * @return any.
    */
    findDisabledChildElements = (forms: IForm[], user: string): any => {
        const Forms = forms;
    
        for (let iForm = 0; iForm < Forms.length; iForm++) {//NOSONAR
          const Groups = Forms[iForm].groups;
          for (let iFormGroup = 0; iFormGroup < Groups.length; iFormGroup++) {//NOSONAR
            const Elements = Groups[iFormGroup].elements;
            for (
              let iFormElement = 0;
              iFormElement < Elements.length;
              iFormElement++
            ) {
              const formElement = Elements[iFormElement];
    
              let isDisabled = formElement.disabled;
              let value = formElement.value;
              let isRequired = formElement.validations.required;
              const disabledBasedOn: string[] =
                formElement.configuration.disabledBasedOn;
              if (!_.isEmpty(disabledBasedOn)) {
                disabledBasedOn.forEach((elementId: string) => {
                  const FormElement = this.findElementbyId(forms, elementId);
                  if (
                    _.isEqual(formElement.id, "ToolPersistButtons") &&
                    _.isEqual(FormElement.id, "isgrid")
                  ) {
                    if (_.isEqual(FormElement.value, true)) {
                      isRequired = true;
                      if (
                        this.userPermissionHelper.isAllowedPermission(
                          user,
                          USER_PERMISSIONS_CODES.AP_MANAGE_TOOL_CONFIG
                        ) ||
                        !this.userPermissionHelper.isAllowedPermission(
                          user,
                          USER_PERMISSIONS_CODES.AP_MANAGE_TOOL_CONTENT
                        )
                      ) {
                        isDisabled = false;
                      }
                    } else {
                      isRequired = false;
                    }
                  } else {
                    const ElementValue = this.getElementValue({
                      ...FormElement,
                      value: FormElement.value,
                    });
                    const DisabledValue =
                      formElement.configuration.disabledBasedOnValue;
    
                    if (
                      !this.userPermissionHelper.isAllowedPermission(
                        user,
                        USER_PERMISSIONS_CODES.AP_MANAGE_TOOL_CONFIG
                      )
                    ) {
                      isDisabled = true;
                    } else {
                      isDisabled = _.isEqual(DisabledValue, ElementValue);
                      value = isDisabled ? false : value;
                    }
                  }
                });
              }
              Elements[iFormElement] = {
                ...Elements[iFormElement],
                validations: {
                  ...formElement.validations,
                  required: isRequired,
                },
                value,
                disabled: isDisabled,
              };
            }
          }
        }
    
        return Forms;
      };

    /**
    * Find an element by element id
    *
    * @param {IForm[]} forms.
    * @param {number} elementId.
    * @return {IFormElement} formElement.
    */
    findElementbyId = (forms: IForm[], elementId: string): any => {
        for (const form of forms) {
            for (const formGroup of form.groups) {
                for (const formElement of formGroup.elements) {
                    if (_.isEqual(formElement.id, elementId)) {
                        return formElement;
                    }
                }
            }
        }
    }

    /**
    * Check if the element value is empty or not
    *
    * @param {IFormElement} element.
    * @param {any} elementValue.
    * @return boolean.
    */
    isEmptyElementValue = (form_element: IFormElement, element_Value: any): any => {
        let isEmpty = true;
        switch (form_element.type) {
            // Text box
            case FORM_ELEMENT_TYPES.TEXT_INPUT:
            // Checkbox
            /* falls through*/
            case FORM_ELEMENT_TYPES.TEXTAREA:
            // Textarea
            /* falls through*/
            case FORM_ELEMENT_TYPES.CHECKBOX_INPUT:
            // HTML Editor
            /* falls through*/
            case FORM_ELEMENT_TYPES.HTML_EDITOR:
            // Radio 
            /* falls through*/
            case FORM_ELEMENT_TYPES.RADIO_INPUT:
                isEmpty = _.isEqual((element_Value || "").toString().trim().length, 0);
                break;
             //File input
             case FORM_ELEMENT_TYPES.FILE_UPLOAD:
                isEmpty = _.isNull(element_Value) || (_.isEmpty(element_Value.acceptedFiles) && _.isEmpty(element_Value.rejectedFiles));
                break;
            // Multiselect
            case FORM_ELEMENT_TYPES.SELECT_INPUT:
                if (form_element.configuration.isMulti) {
                    isEmpty = _.isNull(element_Value) || _.isEqual((element_Value || []).length, 0);
                } else {
                    isEmpty = _.isNull(element_Value);
                }
                break;
           
            case FORM_ELEMENT_TYPES.SWITCH:
                isEmpty = _.isNull(element_Value) || _.isUndefined(element_Value);
                break;
        }
        return isEmpty;
    }
}