import React, { useState, Fragment, useEffect } from "react";
import Popover from "@material-ui/core/Popover";
import * as _ from "lodash";
import { withStyles } from "@material-ui/styles";
import { Button, Grid } from "@material-ui/core";
import { useOktaAuth } from "@okta/okta-react";
import { connect, useSelector } from "react-redux";
import styles from "./style";
import { withErrorHandler } from "../../HOC";
import { SingleLineEllipsisText } from "../../UI/LineText/SingleLineEllipsis";
import { useHistory } from "react-router-dom";

function UserInfoComponent(props: any): React.JSX.Element {
  const { classes, bundle } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const initial = useSelector((state: any) => state.initial);

  const { authState, oktaAuth } = useOktaAuth();

  const UserInfo: any = authState?.idToken?.claims;

  const Name: any = initial.userInfo.fullName || UserInfo.name;
  const Email: any = initial.userInfo.email || UserInfo.email;
  const UserInitial: any = Name?.charAt(0);
  const history = useHistory();

  const handleClick = props.errorHandlerSync(
    (event: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(event.currentTarget);
    }
  );

  const handleClose = props.errorHandlerSync(() => {
    setAnchorEl(null);
  });
  const handleInvalidToken = (event: any): any => {
    const IsNullOktaStorage = _.isNull(
      localStorage.getItem("okta-token-storage")
    );
    if (_.isEqual(event.key, "okta-token-storage") && IsNullOktaStorage) {
      oktaAuth.signOut();
    }
  };

  const handelEdit = (): any => {
    history.push("/profile");
    handleClose();
  };
  const isExternalUser = initial.isExternalUser;
  const isAuthenticated = initial.isAuthenticated;

  useEffect(() => {
    window.addEventListener("storage", handleInvalidToken);

    return () => {
      window.removeEventListener("storage", handleInvalidToken);
    };
  }, []);

  const onSignout = (): any => {
    localStorage.removeItem("filterCategories");
    oktaAuth.signOut();
    window.removeEventListener("storage", handleInvalidToken);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Fragment>
      <div
        aria-describedby={id}
        onClick={handleClick}
        className={classes.userProfileImage}
      >
        <div aria-label={UserInitial} className={classes.userProfileAvatar}>
          {UserInitial}
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.popoverInfoPaper}
      >
        <Grid container direction="column" alignItems="center">
          <div className={classes.popoverContainer}>
            <div className={classes.userName}>
              <SingleLineEllipsisText text={Name} />
            </div>
            <div className={classes.userEmail}>
              <SingleLineEllipsisText text={Email} />
            </div>

            <hr className={classes.hr}></hr>
            {!isExternalUser && isAuthenticated && (
              <div className={classes.signOut}>
                <Button
                  variant="outlined"
                  className={classes.editButton}
                  onClick={handelEdit}
                >
                  Edit Profile
                </Button>
              </div>
            )}
            <div className={classes.signOut}>
              <Button
                variant="contained"
                color="primary"
                className={classes.signOutButton}
                onClick={onSignout}
              >
                {bundle.SIGN_OUT_TITLE || "Sign Out"}
              </Button>
            </div>
          </div>
        </Grid>
      </Popover>
    </Fragment>
  );
}

const mapStateToProps = (state: any): any => ({
  bundle: state.initial.bundle,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(withErrorHandler(UserInfoComponent)));
