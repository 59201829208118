import React, { useEffect, useState } from "react";
import { Input, IconButton, InputAdornment, Grid } from "@material-ui/core";
import classNames from "classnames";
import { DateRange } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import commonStyle from "./sharedStyle";
import moment from "moment";
import _ from "lodash";


// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));
const useCommonStyles = makeStyles(() => createStyles(commonStyle()));

type props = {
    handleDateChange: any;
    selectedStartDate: any;
    selectedEndDate: any;
    selection: any;
    isSelectOpen: any;
    handleSelectOpen: any;
}

export const UIDateRangePicker = (props: props): React.JSX.Element => {
    const { handleDateChange, selectedStartDate, selectedEndDate, selection, isSelectOpen, handleSelectOpen } = props;
    const classes = useStyles();
    const commonClasses = useCommonStyles();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isFirstClick, setIsFirstClick] = useState<boolean>(true);
    const [selectedStart, setSelectedStart] = useState(null);

    const getMomentDate = (date: any): Date => {
        const dateFormat = "YYYY/MM/DD";
        const LocalDateTime: any = moment(date, dateFormat, true).local();
        return new Date(LocalDateTime);
    };

    const calculatetStartDate = getMomentDate(selectedStartDate);
    const calculateEndDate = getMomentDate(selectedEndDate);
    const [startDate, setStartDate] = useState(calculatetStartDate);
    const [endDate, setEndDate] = useState(calculateEndDate);
    const newStartDatefun = (val: any): any => {
        if (_.gt(val, 15)) {
            if (_.gt(val, 180)) {
                return moment(new Date(), "YYYY/MM/DD", true).subtract(1, "years").format("YYYY/MM/DD");
            } else {
                return moment(new Date(), "YYYY/MM/DD", true).subtract((selection.value / 30), "months").format("YYYY/MM/DD");
            }
        } else {
            return moment(new Date(), "YYYY/MM/DD", true).subtract(selection.value, "d").format("YYYY/MM/DD");
        }
    };
    useEffect(() => {
        if (!_.isEqual(selection.value, 1)) {
            const newEndDate: any = moment(new Date(), "YYYY/MM/DD", true).format("YYYY/MM/DD");
            const newStartDate: any =newStartDatefun(selection.value);
            setStartDate(getMomentDate(newStartDate));
            setEndDate(getMomentDate(newEndDate));
        }
        setIsOpen(false);
    }, [selection]);

    useEffect(() => {
        if (_.isEqual(isSelectOpen, true)) {
            setIsOpen(false);
        }
    }, [isSelectOpen]);

    useEffect(() => {
        if (!isOpen) {
            setStartDate(getMomentDate(selectedStartDate));
            setEndDate(getMomentDate(selectedEndDate));
            setIsFirstClick(true);
        }
    }, [isOpen]);
    
   
    return (
        <div>
            <div style={{ width: "100%", display: "flex", backgroundColor: "white" }}>
                <Grid container>
                    <Grid item xs={12}>
                        <DatePicker
                            key={Date.now()}
                            selectsRange={true}
                            maxDate={new Date()}
                            dateFormat={"yyyy/MM/dd"}
                            startDate={startDate}
                            endDate={endDate}
                            open={isOpen}
                            readOnly={true}
                            openToDate={startDate}
                            monthsShown={2}
                            className={classes.selectCursor}
                            onInputClick={() => {
                                setIsOpen(true);
                                handleSelectOpen(false);
                            }
                            }
                            onClickOutside={() => {
                                if (_.isNull(selectedStartDate) && _.isNull(selectedEndDate)) {
                                    setIsOpen(false);

                                    return;
                                }
                                let changedStartDate: any = moment(startDate).format("YYYY/MM/DD");
                                let changedEndDate: any = moment(endDate).format("YYYY/MM/DD");
                                if (startDate > endDate) {
                                    changedStartDate = selectedStartDate;
                                    changedEndDate = selectedEndDate;
                                }
                                handleDateChange({ start_date: changedStartDate, end_date: changedEndDate });
                                setIsFirstClick(true);
                                setIsOpen(_.isNull(endDate));
                            }}
                            customInput={
                                <Input
                                    type="text"
                                    className={classNames(classes.input, {
                                        [commonClasses.disabled]: false
                                    })}
                                    readOnly={true}
                                    disabled={false}
                                    fullWidth={false}
                                    value={{ start_date: moment(startDate).format("YYYY/MM/DD"), end_date: moment(endDate).format("YYYY/MM/DD") }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton className={commonClasses.iconButton} size={"small"}>
                                                <DateRange fontSize={"small"} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            }
                            onChange={(selectedRange: any) => {//NOSONAR
                                let updatedStartDate = null;
                                if (isFirstClick) {//NOSONAR
                                    if (endDate && selectedRange[0] > endDate) {//NOSONAR
                                        setSelectedStart(selectedRange[0]);
                                        setStartDate(selectedRange[0]);
                                        selectedRange[0] = startDate;
                                    }
                                    else {//NOSONAR
                                        setSelectedStart(null);
                                        setStartDate(selectedRange[0]);
                                        selectedRange[1] = endDate;
                                    }
                                    setIsFirstClick(false);
                                }
                                if (!isFirstClick) {//NOSONAR
                                    if (startDate && selectedRange[0] < startDate) {//NOSONAR
                                        setStartDate(selectedRange[0]);
                                        updatedStartDate = selectedRange[0];
                                        selectedRange[1] = endDate;
                                    }
                                    else if (!selectedRange[1]) {//NOSONAR
                                        selectedRange[1] = selectedRange[0];
                                        setEndDate(selectedRange[0]);

                                    }
                                    if(selectedStart){//NOSONAR
                                        selectedRange[0] = updatedStartDate; 
                                    }else{//NOSONAR
                                        selectedRange[0]= startDate;
                                    }
                                    if (selectedStart) {//NOSONAR
                                        setStartDate(selectedStart);
                                    }
                                    setIsFirstClick(true);
                                    let changedStartDate: any = moment(selectedRange[0]).format("YYYY/MM/DD");//NOSONAR
                                    let changedEndDate: any = moment(selectedRange[1]).format("YYYY/MM/DD");//NOSONAR
                                    if (changedStartDate > changedEndDate) {//NOSONAR
                                        changedStartDate = selectedStartDate;
                                        changedEndDate = selectedEndDate;
                                    }
                                    handleDateChange({ start_date: changedStartDate, end_date: changedEndDate });
                                    setIsOpen(false);
                                    setSelectedStart(null);
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};