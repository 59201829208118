
const style:any = (): any => ({
	container:{
		position: "fixed",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 999999999999999,
	}
});


export default style;