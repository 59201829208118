import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "./style";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { SingleLineEllipsisText } from "../Text/SingleLineEllipsis";
import { TwoLineEllipsis } from "../Text/TwoLineEllipsis";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
};

// UIDisplayText Component
export const UIDisplayText = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { formElement } = props;
  let title = "";
  let subtitle = "";

  const ElementValue = formElement.value || {};

  if (!_.isUndefined(formElement.readOnlyOptions)) {
    title = ElementValue[formElement.readOnlyOptions.keys.title];
    subtitle = ElementValue[formElement.readOnlyOptions.keys.subtitle];
  }
  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <TwoLineEllipsis label={""} tooltip={title} text={title} />
      </div>
      <div className={classes.subtitle}>
        <SingleLineEllipsisText text={subtitle} />
      </div>
    </div>
  );
};
