import * as _ from "lodash";
import { IOption } from "../../../../../../../../UI/Elements/Forms/interface/IOption";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";


// Select Element Helper 
export class MultiSelectHelper {

	getMultiSelectValues = (options: IOption[]): any[] => {
		return options.map((option: IOption) => option.value);
	}

	ifMultiSelectValueNotArray = (formElement: IFormElement, elementValue: any): IFormElement => {
		const Element = { ...formElement };
		const Options = _.isObject(formElement.value) ? [] : elementValue.split(",").map((option: any) => option.toString().trim());
		const ValueOptions = _.isObject(formElement.value) ? [] : elementValue.split(",").map((option: any) => ({
			label: option.toString().trim(),
			value: option.toString().trim()
		}));

		const DSOptions = formElement.configuration.dataSource.map((option: IOption) => option.value.toString().trim());

		const MultiOptions: IOption[] = ValueOptions.filter((option: IOption) => {
			const Index = DSOptions.indexOf((option.value || "").toString().trim());
			return !_.isEqual(Index, -1);
		})!;

		if (_.isEqual(Options.length, 0)) {
			Element.value = null;
		} else {
			Element.value = _.isEqual(MultiOptions.length, 0) ? null : MultiOptions;
		}
		Element.touched = true;
		return Element;
	}

	ifMultiSelectValueIsArray = (formElement: IFormElement, elementValue: any): IFormElement => {
		const Element = { ...formElement };
		const Options = elementValue.map((option: any) => option.value.toString().trim());
		const DSOptions = formElement.configuration.dataSource.map((option: any) => option.value.toString().trim());

		const MultiOptions: IOption[] = elementValue.filter((option: IOption) => {
			const Index = DSOptions.indexOf((option.value || "").toString().trim());
			return !_.isEqual(Index, -1);
		})!;

		if (_.isEqual(Options.length, 0)) {
			Element.value = _.isEqual(Options.length, 0);
		} else {
			Element.value = _.isEqual(MultiOptions.length, 0) ? null : MultiOptions;
		}
		Element.touched = true;
		return Element;
	}

	/**
	 * Get Multi-select value
	 *
	 * @param {formElement} IFormElement.
	 * @return {formElement} IFormElement.
	 */
	getMultiDropdownValue = (formElement: IFormElement): IFormElement => {

		let Element = { ...formElement };

		if (_.isNull(formElement.value)) {
			Element.value = null;
			return Element;
		}

		const elementValue = !_.isArray(formElement.value) ?
			(formElement.value || "").toString().trim() : formElement.value;

		if (!_.isEqual((formElement.value || "").toString().trim().length, 0) && _.isArray(formElement.configuration.dataSource)) {

			if (!_.isArray(elementValue)) {
				Element = this.ifMultiSelectValueNotArray(formElement, elementValue);

			} else {
				Element = this.ifMultiSelectValueIsArray(formElement, elementValue);
			}
			return Element;
		}
		Element.value = null;
		return Element;
	}
}
