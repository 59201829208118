
const style:any = (): any => ({
	container:{
		marginBottom:"1.5rem",
		display:"flex",
		flexWrap:"wrap"
	},
	chip: {
		minWidth: 60,
		height: 20,
		lineHeight:"11px",
		backgroundColor: "rgb(243, 245, 247)",
		border: "1px solid rgba(255, 255, 255, 0)",
		borderRadius: 2,
		textAlign:"center",
		textTransform:"uppercase",
		color:"rgb(36, 44, 52)",
		fontSize:"0.625rem",
		width:"fit-content",
		fontWeight:500,
		marginRight:6,
		marginBottom:6,
		padding:"3px 5px",
	}
});

export default style;