
import _ from "lodash";
import { IOption } from "../../../../../../UI/Elements/Forms/interface/IOption";
import { ALL_OPTION_VALUE, ELEMENT_TYPES } from "../../../../configuration";
import { IGridFilter } from "../../../../Interfaces/Filter/IGridFilter";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../../../Interfaces/Form/IForm";
import { IFormGroup } from "../../../../Interfaces/Form/IFormGroup";
import { UtilHelper } from "../Utility";
import { NewFormHelper } from "./New";
import { SubmitFormHelper } from "./Submit";

export class FilterFormHelper {
	private submitFormHelper: SubmitFormHelper;
	private newFormHelper: NewFormHelper;
	private utilHelper: UtilHelper;

	constructor() {
		this.submitFormHelper = new SubmitFormHelper();
		this.newFormHelper = new NewFormHelper();
		this.utilHelper = new UtilHelper();
	}
	public async buildFilterForm(form: IForm, dataSources: any): Promise<IForm> {
		return {
			...form,
			groups: form.groups.map((formGroup: IFormGroup) => {
				return {
					...formGroup,
					elements: formGroup.elements.map((formElement: IFormElement) => {
						const isParentContainsValue = this.isParentContainsValue(form, formElement);
						const element = this.newFormHelper.buildNewFormElement(formElement, dataSources, isParentContainsValue);
						return {
							...element,
							isValid: element.validations.required ? element.isValid : true,
							touched: false,
							readOnly: !_.isUndefined(element.configuration.readOnly) ? element.configuration.readOnly.newForm : element.readOnly
						};
					}),
				};
			}),
		};
	}

	private isParentContainsValue = (form: any, formElement: IFormElement): boolean => {
		let isContainsValue = false;
		if (!_.isEqual(formElement.configuration.parentElements.length, 0)) {
			let parentElement = "";
			formElement.configuration.parentElements.forEach((value: any) => {
				parentElement = value;
			});
			form.groups.forEach((formGroup: IFormGroup) => {
				formGroup.elements.forEach((element: IFormElement) => {
					if (_.isEqual(parentElement, element.id)) {
						if (_.isNull(element.value)) {
							isContainsValue = false;
						}
						else {
							if (this.utilHelper.isAllSelected(element.value)) {
								isContainsValue = false;
							}
							else {
								isContainsValue = true;
							}
						}
					}
				});
			});
			return isContainsValue;
		}
		else {

			return isContainsValue;
		}
	}

	public getFormData(form: IForm): any {
		const SubmitData: any = {};

		form.groups.forEach((formGroup: IFormGroup) => {

			formGroup.elements.forEach((formElement: IFormElement) => {
				SubmitData[formElement.id] = {
					type: formElement.type,
					value: this.submitFormHelper.getElementValue(formElement)
				};
			});
		});

		return SubmitData;
	}

	private getSelectSubmitData = (formElement: IFormElement): any => {
		if (formElement.configuration.isMulti
			&& !_.isNull(formElement.value)) {
			return this.utilHelper.isAllSelected(formElement.value) ? [] : this.getMultiSelectValue(formElement);
		}
		return _.isNull(formElement.value) ? null : formElement.value;
	}

	public getFilterSubmitData = (gridFilter: IGridFilter, searchText: string): number => {
		const FilterData: any = {
			search: (searchText || "").toString().trim(),
			pageSize: gridFilter.pageSize,
			pageNumber: gridFilter.pageNumber + 1,
			order: gridFilter.order,
			orderBy: gridFilter.orderBy
		};

		gridFilter.sidebarFilter!.form.groups.forEach((formGroup: IFormGroup) => {

			formGroup.elements.forEach((formElement: IFormElement) => {
				if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)) {
					FilterData[formElement.id] = this.getSelectSubmitData(formElement);
				} else {
					FilterData[formElement.id] = formElement.value;
				}
			});
		});


		return FilterData;
	}

	public getMultiSelectValue = (formElement: IFormElement): any[] => {
		const ElementValue = formElement.value || [];

		if (formElement.configuration.allowAll) {
			return ElementValue.filter((option: IOption) => !_.isEqual(option.value, ALL_OPTION_VALUE)).map((option: IOption) => option.value);
		}
		return ElementValue.map((option: IOption) => option.value);
	}


	public getFilterCount = (gridFilter: IGridFilter): number => {

		const FormData: any = this.getFormData(gridFilter.sidebarFilter!.form);
		let filterCount = 0;

		for (const key in FormData) {
			const FormElVal = !_.isArray(FormData[key].value) ? [] : FormData[key].value;
			const Values = FormElVal.map((option: IOption) => option.value);

			if (ELEMENT_TYPES.SELECT_INPUT) {
				filterCount = (!_.isEqual(Values.indexOf(ALL_OPTION_VALUE), -1)) || (_.isEqual(Values.length, 0)) ? filterCount : filterCount + 1;
			}
		}
		return filterCount;
	}
}
