import * as _ from "lodash";
import moment from "moment";
import { IFormElement } from "../../../interface/IFormElement";
import { DateUtil } from "../../Utils/Date";

// Validation helper for date
export class DateValidation{
    private dateUtil: DateUtil;

    constructor(){
    	this.dateUtil = new DateUtil();
    }

    /**
     * Validate date
     *
     * @param {IFormElement} element.
     * @param {any} elementValue.
     * @return {string} errorMessages.
     */
    validate = (formElement: IFormElement, elementValue:any): string=>{
    	if(_.isNull(elementValue)){
    		return "";
    	}
      
    	let errorMessage = "";

    	const DateFormat = formElement.show_time ? DateUtil.momentDateTimeFormat : DateUtil.momentDateFormat;

    	if (!_.isUndefined(formElement.input_event_value) 
            && !moment(formElement.input_event_value, DateFormat, true).isValid()) {
    		errorMessage = `${formElement.labelName} is not a valid date.`;
    		return errorMessage;
    	}
   
    	if(!this.dateUtil.isValidDate(formElement, elementValue)){
    		errorMessage = `${formElement.labelName} is not a valid date.`;
    		return errorMessage;
    	}
    	return errorMessage;
    }

	/**
     * Get Touched flag for daterange picker
     *
     * @param {any} elementValue.
     * @return {boolean}.
     */
	isValidTouchForDateRange = ( elementValue:any): boolean =>{
		if(_.isNull(elementValue)){
			return false;
		}
		const DateRangeNullArr = elementValue.filter((value: any) => _.isNull(value));
		if(_.isEqual(DateRangeNullArr.length, 2)){
			return true;
		}
		return !_.isEqual(DateRangeNullArr.length, 1);
	}

	/**
     * Validate date range picker
     *
     * @param {IFormElement} element.
     * @param {any} elementValue.
     * @return {boolean} errorMessage.
     */
	 validateDateRangePicker = (formElement: IFormElement,  elementValue:any): string =>{
		const INVALID_DATE_RANGE_ERROR = `${formElement.labelName} is not a valid date range.`;

		if(_.isNull(elementValue)){
			return "";
		}
		const DateRangeNullArr = elementValue.filter((value: any) => _.isNull(value));
	
		if(_.isEqual(DateRangeNullArr.length, 2)){
			return "";
		}
		return !_.isEqual(DateRangeNullArr.length, 1) ? "" : INVALID_DATE_RANGE_ERROR;
	}
}