import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { UITooltip } from "../Tooltip";
import style from "./style";
import commonStyle from "../../sharedStyle";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import * as _ from "lodash";
import { UI_ELEMENT_TYPES } from "../../../config";


// Create styles
const useStyles = makeStyles(() => createStyles(style()));
const utilStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  formElement: IFormElement;
};

// UILabel Component
export const UILabel = (props: props): JSX.Element => {
  const { formElement } = props;
  const classes = useStyles();
  const utilClasses = utilStyles();

  return (
    <label htmlFor={`${formElement.id}`} className={utilClasses.elementLabel}>
      {formElement.labelName}
      <UITooltip title={formElement.tooltip || ""} />
      {!formElement.is_required && !formElement.read_only &&
        !_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DOWNLOAD) && (
          <span className={classes.optional}>Optional</span>
        )}
    </label>
  );
};

export default UILabel;
