import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { TablePagination, Grid } from "@material-ui/core";
import * as _ from "lodash";
import style from "./style";
import { IFormElement } from "../../../../../../../../UI/Elements/Forms/interface/IFormElement";
import { TitleElement } from "../../../../../../../../UI/Elements/FormElements/Thumbnail/Title";
import MediaType from "../../../../../../../../UI/Elements/FormElements/Thumbnail/MediaType";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));
const DATA_PER_PAGE = 8;

// Props type
type props = {
  formElement: IFormElement;
};

// UIThumbnailView Component
export const UIThumbnailView = (props: props): React.JSX.Element => {
  const { formElement } = props;
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);
  const Disabled = formElement.disabled || formElement.read_only;

  const DATA_SOURCE: any[] = formElement.dataSource || [];

  const handleChangePage = (offset: any, pageNumber: number): void => {
    setPage(pageNumber);
  };

  if (formElement.hideBasedOnParent) {
    return <></>;
  }

  let ELEMENT_VALUE_ID = _.isNull(formElement.value)
    ? null
    : formElement.value.id;

  if (_.isEmpty(DATA_SOURCE)) {
    ELEMENT_VALUE_ID = null;
  }

  let showPagination = !_.isEmpty(DATA_SOURCE);
  if (_.lte(DATA_SOURCE.length, DATA_PER_PAGE)) {
    showPagination = false;
  }

  const getCustomStyle = (elementData: IFormElement): any => {
    if (!_.isNull(elementData.customConfiguration)) {
      return elementData.customConfiguration.thumbnail?.imageStyle;
    }
    return {};
  };

  const CustomThumbnailStyle = getCustomStyle(formElement);

  const getThumnail = (data: any): React.JSX.Element => {
    if (_.isNull(data)) {
      return <></>;
    }
    const Title: string = data.title || "";
    const Subtitle: string = data.subtitle || "";
    const MediaTypeStr: string = data.mediaType || "";

    return (
      <Grid key={data.id} item xs={6} lg={3} md={4} xl={3} sm={4}>
        <div className={classes.card}>
          <img
            src={data.thumbnail}
            className={classes.cardImage}
            width="100%"
            height="180"
            style={{
              ...CustomThumbnailStyle,
            }}
          />
          <div className={classes.cardContainer}>
            <div className={classes.cardTitle}>
              <TitleElement title={Title} />
            </div>
            <div className={classes.cardSubTitle}>
              <div className={classes.subtitleText}>{Subtitle}</div>
              <MediaType title={MediaTypeStr} />
            </div>
          </div>
        </div>
      </Grid>
    );
  };

  if (Disabled && _.isNull(ELEMENT_VALUE_ID)) {
    return (
      <div className={classes.container}>
        <div className={classes.containerData}>
          <Grid container className={classes.root} spacing={2}>
            {DATA_SOURCE.slice(
              page * DATA_PER_PAGE,
              page * DATA_PER_PAGE + DATA_PER_PAGE
            ).map((data: any) => {
              return getThumnail(data);
            })}
          </Grid>
        </div>
        {showPagination && (
          <TablePagination
            component="div"
            count={DATA_SOURCE.length}
            labelRowsPerPage=""
            rowsPerPage={DATA_PER_PAGE}
            page={page}
            onPageChange={(offset, pageNumber) =>
              handleChangePage(offset, pageNumber)
            }
          />
        )}
      </div>
    );
  }

  let element = null;

  if (!_.isNull(ELEMENT_VALUE_ID)) {
    element = DATA_SOURCE.find((data: any) =>
      _.isEqual(data.id, ELEMENT_VALUE_ID)
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.containerData}>
        <Grid container className={classes.root} spacing={2}>
          {getThumnail(element)}
        </Grid>
      </div>
    </div>
  );
};
