import axios from "axios";
import * as _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { FileHelper } from "../../../../../UI/Elements/Forms/helper/Utils/FileUtil/File";
import { GoogleUtilHelper } from "../../../../../UI/Elements/Forms/helper/Utils/FileUtil/GoogleUtil";
import { IFile } from "../../../../../UI/Elements/Forms/interface/File/IFile";
import { IFileUpload } from "../../../../../UI/Elements/Forms/interface/File/IFileUpload";
import { FileValidation } from "../../../Helpers/Validations/File";
import { IFileUploadResponse } from "../../../Interface/File/IFileUploadResponse";
import { IFormElement } from "../../../Interface/FormElement/IFormElement";

const uploadFileToBucket = async (fileId: any, abortController: AbortController,  uploadSessionURI: string, file: any, setActiveFile: any, contentLength=0): Promise<void> =>{

	await axios.put(uploadSessionURI, file, {
        signal: abortController.signal,
        headers: {
          "X-Upload-Content-Length": contentLength,
          "Content-Range": `bytes 0-${file.size -1}/${file.size}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const progress: any = Math.floor((loaded * 100) / total);
     
		  setActiveFile({
            progress,
            id: fileId,
            uploadSessionURI: uploadSessionURI,
          });
        },
      });
};

const resumeFileToBucket = async (fileId: any, abortController: AbortController, bytesRange: any,  uploadSessionURI: string, file: any, setActiveFile: any, contentLength=0): Promise<void> =>{

	const {start, end} = bytesRange;
	const updatedFile = file.slice(start, start +end);

	await axios.put(uploadSessionURI, updatedFile, {
        signal: abortController.signal,
        headers: {
          "X-Upload-Content-Length": contentLength,
          "Content-Range": `bytes ${start}-${end}/${file.size}`,
        },
        onUploadProgress: (progressEvent: any) => {
          const { loaded } = progressEvent;
		  const TotalLoaded = loaded + start;
          const progress: any = Math.floor((TotalLoaded * 100) / file.size);

		  setActiveFile({
            progress,
            id: fileId,
            uploadSessionURI: uploadSessionURI,
          });
        },
      });
};

const generatePublicURL = async (bucketFileName: string, uploadSessionURI: string): Promise<IFileUploadResponse> =>{
	const googleUtil = new GoogleUtilHelper();
	const PublicURL: any = await googleUtil.getBlobPublicURL(bucketFileName, process.env.REACT_APP_PORTAL_FILE_UPLOAD_GOOGLE_BUCKET || "");

	  return {
		error: _.isNull(PublicURL),
		publicURL: PublicURL,
		uploadSessionURI,
	  };
};

const splitFiles = (fileData: IFileUpload[]): any =>{
	const acceptedFiles: IFileUpload[] = [];
	const rejectedFiles: IFileUpload[] = [];

	fileData.forEach((file: IFileUpload)=>{

		if(file.isValidForUpload){
			acceptedFiles.push(file);
		}else{
			rejectedFiles.push(file);
		}
	});

	return {
		acceptedFiles,
		rejectedFiles
	};
};

const isValidForUpload = (files: IFileUpload[]): boolean=>{
	const ValidFiles = files.filter((file: IFileUpload)=> file.isValidForUpload);

	return !_.isEqual(ValidFiles.length, 0);
};

const getFilesData = (files: any, formElement: IFormElement): any =>{
	const Files: IFileUpload[] = [];
	const fileValidation = new FileValidation();

    for (const file of files) {
		const File: IFile = file;
		const FileName: any = File.name.split(".").slice(0, -1).join(".");
		const FileExtension: any = File.name.split(".").pop();
  
		const UniqueFileName = `Automations-Portal-${uuidv4()}.${FileExtension}`;
		const abortController = new AbortController();
		const fileHelper = new FileHelper();
		// Validation
	
		const isValidFile = fileValidation.isValidFileExtension(formElement,FileExtension)
		&& fileValidation.isValidFileSize(formElement, File.size);
		Files.push( {
			id: uuidv4(),
			displayName: `${fileHelper.breakFileName(FileName)}.${FileExtension}`,
			displaySize: fileHelper.formatBytes(File.size),
			file: File,
			uniqueFileName: UniqueFileName,
			abortController: abortController,
			isValidForUpload: isValidFile,
			name: File.name,
			size: File.size,
			type: File.type,
			progress: 0,
			status: isValidFile ? "pending" : "error",
			uploadSessionURI: null,
			signedURL: null,
			fileInputText:"",
			touched:false
		  });
	  }
	  return {
		  rejectedFiles: Files.filter((file: IFileUpload)=> !file.isValidForUpload),
		  acceptedFiles: Files.filter((file: IFileUpload)=> file.isValidForUpload)
	  };
};

const resumeFileUpload = async (fileInfo: IFileUpload, bytesRange: string, contentLength: number, setActiveFile: any): Promise<any> =>{
	const UploadSessionURI: any = fileInfo.uploadSessionURI;
	try{
		const { file, uniqueFileName, abortController } = fileInfo;
		await resumeFileToBucket(
			fileInfo.id,
			abortController,
			bytesRange,
			UploadSessionURI,
			file,
			setActiveFile,
			contentLength
		  );
	
		  return await generatePublicURL(uniqueFileName, UploadSessionURI);
		} catch (err) {
		  return {
			error: true,
			signedURL: null,
			uploadSessionURI: UploadSessionURI,
		  };
		}
};

const handleOnChange = (props: any, files: any, loading: boolean): void =>{
	const { formElement, onChange, formId, formGroupId } = props;
	onChange({
		formId,
		formGroupId,
		formElement,
		value: files,
		loading,
	  });
};

export default {
	uploadFileToBucket,
	generatePublicURL,
	getFilesData,
	splitFiles,
	isValidForUpload,
	resumeFileUpload,
	handleOnChange
};