import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import style from "./style";
import _ from "lodash";
import { IconDownload } from "../../../UI/Icons/Download";


type props = {
    filename : any,
    bucketname : any,
    expirydate : any,
    event : any,
    onDownloadHandler : any,
    expiryDateCheck : any
  };

const allowedEvent = ["Schedule", "Adhoc"];

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export default function GridDownload(props: props): React.JSX.Element {
    const classes = useStyles();
    const {filename, bucketname, expirydate, event, onDownloadHandler, expiryDateCheck} = props;

    if((!(_.isNull(bucketname) || _.isNull(filename)) && expiryDateCheck(expirydate))){
        return (
        <TableCell align="right" className={classes.tableColumn}>
            <IconDownload onClick={() => onDownloadHandler(filename, bucketname, expirydate)} smallIcon={false} title={"Download Execution Report"}/>
        </TableCell>
        );
    }
    if ((allowedEvent.includes(event) || (!expiryDateCheck(expirydate)) && !_.isNull(expirydate))){
        return (
            <TableCell align="right" className={classes.tableColumn}>
                <IconDownload smallIcon={false} disabled={true} title={"File Does Not Exist"}/>
            </TableCell>
        );
    }
    return <td/>;
}