import React from "react";
import { Box, Grid } from "@material-ui/core";
import { IForm } from "../../Interface/IForm";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { FORM_ELEMENT_TYPES } from "../ElementTypes";
import { UITextInput } from "./ToolInput";
import { UISwitch } from "./Switch";
import { UISelect } from "./SelectInput";
import { UIImageUpload } from "./Upload";
import { UIHTMLEditor } from "./Editor";

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  onValidateForms: any;
  formId: string;
  formGroupId: string;
  form: IForm;
};

// UIInput Component
export const UIInput = (props: props): React.JSX.Element => {
  const { formId, formGroupId, onChange, formElement } = props;
  const GridColumns: any = formElement.configuration.gridColumns;
  let Element;

  switch (formElement.type) {
    case FORM_ELEMENT_TYPES.TEXT_INPUT:
      Element = (
        <UITextInput
          formId={formId}
          formGroupId={formGroupId}
          onChange={onChange}
          formElement={formElement}
        />
      );
      break;
    case FORM_ELEMENT_TYPES.SWITCH:
      Element = (
        <UISwitch
          formId={formId}
          formGroupId={formGroupId}
          onChange={onChange}
          formElement={formElement}
        />
      );
      break;
    case FORM_ELEMENT_TYPES.SELECT_INPUT:
      Element = (
        <UISelect
          formId={formId}
          formGroupId={formGroupId}
          onChange={onChange}
          formElement={formElement}
        />
      );
      break;
    case FORM_ELEMENT_TYPES.FILE_UPLOAD:
      Element = (
        <UIImageUpload
          formId={formId}
          formGroupId={formGroupId}
          onChange={onChange}
          formElement={formElement}
        />
      );
      break;
    case FORM_ELEMENT_TYPES.HTML_EDITOR:
      Element = (
        <UIHTMLEditor
          formId={formId}
          formGroupId={formGroupId}
          onChange={onChange}
          formElement={formElement}
        />
      );
      break;
    default:
      Element = <span />;
  }

  return (
    <Grid container item xl={GridColumns} lg={GridColumns} md={6} xs={12}>
      <Box
        style={{
          width: "100%",
        }}
        sx={{
          mr: 1,
          ml: 1,
        }}
      >
        {Element}
      </Box>
    </Grid>
  );
};
