import {
	UPDATE_SORTLABEL,
	UPDATE_STATE
} from "../../types/FilterState";

export const updateFilterState = (data:any) : any=> {
        return {
			type:UPDATE_STATE,
 			payload: data
 		};
 	};

export const updateSortLabel = (data:any) : any => {
	return {
		type:UPDATE_SORTLABEL,
		payload: data
	};
};