import React from "react";
import { TablePagination } from "@material-ui/core";
import _ from "lodash";
import { IFormElement } from "../../../../../Forms/interface/IFormElement";
import { IFilter } from "../IFilter";

type props = {
  formElement: IFormElement;
  rowData: any[];
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  filter: IFilter;
};

export const DataGridFooter = (props: props): React.JSX.Element => {
	const {
		rowData,
		handleChangePage,
		handleChangeRowsPerPage,
		filter,
	} = props;

	const { search } = filter;

	const FilteredData = (rowData || [])
		.map((data: any) => ({
			...data,
		}))
		.filter((data: any) => {
			if (_.isEqual(search.length, 0)) {
				return true;
			}
			let isShowRow = false;

			for (const key in data) {
				if (!_.isEqual(key, "id")) {
					isShowRow =
            isShowRow ||
            !_.isEqual(_.lowerCase(data[key]).indexOf(_.lowerCase(search)), -1);
				}
			}
			return isShowRow;
		});

	return (
		<TablePagination
			component="div"
			count={FilteredData.length}
			labelRowsPerPage=""
			rowsPerPage={filter.rowsPerPage}
			page={filter.page}
			onPageChange={handleChangePage}
			onRowsPerPageChange={handleChangeRowsPerPage}
		/>
	);
};
