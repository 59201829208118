import { Theme } from "@material-ui/core/styles";
import COLORS from "../../UI/Theme/colors";

const style = (theme: Theme): any => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		backgroundColor: COLORS.WHITE,
		height: 56,
		width: "100%",
		padding: "0px 24px",
		display: "flex",
		alignItems: "center",
		flexDirection: "row" as const,
		borderBottom: "1px solid #edf0f2"
	},
	headerLogo: {
		cursor:"pointer"
	},
	headerTitle: {
		display: "inline-block",
		verticalAlign: "top",
		color: COLORS.DARK_GRAY,
		fontSize: 16,
		fontWeight: theme.typography.fontWeightBold,
		marginLeft: 19,
		marginBottom: 6
	},
	listContainer: {
		position: "absolute" as const,
		top: 0,
		right: 24,
		display: "flex",
		alignItems: "center"
	},
	navContainer: {
		display: "inline-flex",
		flexDirection: "row" as const,
		padding: "0px 24px",
		height: 55
	},
	listItems: {
		color: COLORS.GRAY,
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
		paddingLeft: 16,
		paddingRight: 16,

		"&.Mui-selected": {
			fontWeight: theme.typography.fontWeightBold,
			backgroundColor: COLORS.WHITE,
			color: COLORS.DARK_GRAY
		},
		"&.Mui-selected:hover": {
			color: COLORS.DARK_BLUE,
			backgroundColor: "#f7f8fa"
		},
		"&.MuiListItem-button:hover": {
			color: `${COLORS.DARK_BLUE} !important`,
			backgroundColor: "#f7f8fa !important"
		}
	},
	rightMenuWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent:"end"
	},
	menuButton:{
		height:"100%",
		backgroundColor:"transparent",
		border:"none",
		cursor:"pointer",
		color: COLORS.GRAY,
		display: "flex",
    	alignItems: "center",
		"&:hover": {
			color: `${COLORS.DARK_BLUE} !important`,
			backgroundColor: "#f7f8fa !important"
		}
	},
	menuItems:{
		padding:"6px 16px 7px 16px",
		fontSize:14,
		color: COLORS.DARK_GRAY,
		"&:hover": {
			color: `${COLORS.DARK_BLUE} !important`,
			backgroundColor: "#f7f8fa !important"
		},
		minHeight:34
	},
	menuIcon:{
		marginLeft:"0.25rem",
		marginTop:"0.25rem"
	},
	menuList:{
		paddingTop:12,
		paddingBottom:12,
		marginTop:1,
		minWidth:102
	},
	selectedMenu:{
		color: "#242c34",
		fontWeight: 600,
		backgroundColor: "#FFFFFF"
	}
});

export default style;