import COLORS from "../../../Theme/colors";

const style:any = (): any => ({
	container:{
		
	},
	containerData:{
		display:"flex",
	},
	root: {
    flexGrow: 1,
  },
  card:{
	borderRadius: 2,
	border: "solid 1px #d0d5da",
	backgroundColor: "rgba(255, 255, 255, 0)",
	cursor:"pointer",
	position:"relative",
  },
	noDataAvailable:{
		color: COLORS.GRAY,
		fontSize: 14,
		fontWeight: 600,
		marginRight: 6,
		textAlign: "center",
		padding: 12,
		height: 94,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width:"100%",
		backgroundColor:COLORS.SHAINCOLOR
	},
  disabledCardBg:{
	position: "absolute",
	top:0,
	left:0,
	right:0,
	bottom:0,
	width: "100%",
	height: "100%",
	zIndex:4
  },
  cardContainer:{
	padding:"8px 16px",
	minHeight:60,
	display:"flex"
  },
  cardTitle:{
	  fontSize:14,
	  fontWeight:500,
	  marginBottom: 8,
	  position:"relative"
  },
  cardSubTitle:{
	fontSize:10,
	fontWeight:600,
	textTransform:"uppercase",
	color:"#6a737c",
	display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
	width:"100%"
  },
	ellipsisContainer:{
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		visibility: "hidden",
		height:0,
		maxHeight:0,
		display:"block",
		width:"100%",
	  },
	  cardImage:{
		  width:"100%",
		  height:180,
		  display:"block"
	  },
	  defaultCursor:{
		  cursor:"default !important"
	  },
	  mediaType:{
		backgroundColor: COLORS.SHAINCOLOR,
		minHeight:20,
		minWidth:50,
		padding:"2px 7.5px",
		textAlign:"center",
		marginLeft:"0.5rem",
		overflow:"hidden"
	  },
	  subtitleText:{
		overflow:"hidden"
	  },
	  checkboxControl:{
		padding:0,
		marginRight:4
	  },
	  checkboxRoot: {
		padding: 0,

		"&:hover": {
		  backgroundColor: "transparent",
		},
	},
	checkboxContainer:{
		width:"10%",
		paddingRight:4
	},
	
	middleTextEllipsis:{
		width: "100%",
		whiteSpace: "nowrap",
		display:"block",
		minHeight:21,
	},
	checkboxIcon: {
		height: 20,
		width: 20,
		borderRadius: 20,
		boxShadow: "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
		backgroundColor: "#FFFF",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
		"$root.Mui-focusVisible &": {
			outline: "2px auto rgba(19,124,189,.6)",
			outlineOffset: 2,
		},
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5",
		},
		"input:disabled ~ &": {
			boxShadow: "none",
			background: "rgba(206,217,224,.5)",
			opacity:"0.5"
		},
	},
	checkedIcon: {
		height: 20,
		width: 20,
		borderRadius: 20,            
		border: "1px solid rgb(58, 145, 232)",
		boxShadow: "0px 1px 4px 0px rgba(21, 118, 214, 0.15)",
		backgroundColor: "#f0f8ff",
		backgroundImage: "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
		"&:before": {
			display: "block",
			height: 20,
			width: 20,
			backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath" +
			" d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='rgb(58, 145, 232)' /%3E%3C/svg%3E\")",
			content: "\"\"",
		},
		"input:hover ~ &": {
			backgroundColor: "#ebf1f5",
		},
	},
	iconPlaceholder:{
		backgroundColor: COLORS.SHAINCOLOR,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	}
});

export default style;