
import * as _ from "lodash";
import { UtilHelper } from "../../Forms/helper/Utils";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { IOption } from "../../Forms/interface/IOption";

export const ifNotChecked = (formElement: IFormElement, value: any, allValues: any): any =>{
	let filterElement: any[] = [];
	if (
        !_.isEqual(value, UtilHelper.allOptionValue) &&
        !_.isEqual(formElement.value.indexOf(UtilHelper.allOptionValue), -1)
      ) {
        filterElement = formElement.dataSource
          .filter(
            (val: any) =>
              !_.isEqual(val.value, value) &&
              !_.isEqual(val.value, UtilHelper.allOptionValue)
          )
          .map((val: any) => val.value);
      } else {
        if (_.isEqual(value, UtilHelper.allOptionValue)) {
          filterElement = [];
        } else {
          filterElement = allValues.filter((val: any) => !_.isEqual(val, value));
        }
      }
	  return filterElement;
};

export const ifChecked = (formElement: IFormElement, value: any): any =>{
	let filterElement: any[] = [];
	if (_.isEqual(value, UtilHelper.allOptionValue)) {
        filterElement = formElement.dataSource.map((val: IOption) => val.value);
      } else {
        if (!_.isNull(formElement.value)) {
          filterElement = [...formElement.value.split(","), value].filter(
            (val: any) => !_.isEqual((val || "").toString().trim().length, 0)
          );

          if (formElement.allow_all) {
            if (
              _.isEqual(formElement.dataSource.length - filterElement.length, 1)
            ) {
              filterElement = formElement.dataSource.map(
                (val: IOption) => val.value
              );
            }
          }
        } else {
          filterElement = [value];
        }
      }
	  return filterElement;
};