import * as _ from "lodash";
import {
    IForm
} from "../../../interface/IForm";
import { IFormElement } from "../../../interface/IFormElement";
import { IFormGroup } from "../../../interface/IFormGroup";
import {
    IOption
} from "../../../interface/IOption";
import { UtilHelper } from "../../Utils";
import {
    ValidationHelper
} from "../Validation";
import {
    FormElementHelper
} from "../../Utils/Element";
import { UI_ELEMENT_TYPES } from "../../../../config";
import { DateDaysElement } from "./DateDaysPicker";
import { MultiSelectHelper } from "./SelectInput/MultiSelect";
import { SingleSelectHelper } from "./SelectInput/SingleSelect";
import { ThumbnailElement } from "./Thumbnail";
import { ImageTextHelper } from "./ImageText";

// Build Form Validation Helper 
export class BuildFormValidationHelper {
    private validationHelper: ValidationHelper;
    private utilHelper: UtilHelper;
    private elementHelper: FormElementHelper;
    private dateDaysElement: DateDaysElement;
    private multiSelect: MultiSelectHelper;
    private singleSelect: SingleSelectHelper;
    private thumbnailElement: ThumbnailElement;
    private imageTextHelper: ImageTextHelper;

    constructor() {
        this.validationHelper = new ValidationHelper();
        this.utilHelper = new UtilHelper();
        this.elementHelper = new FormElementHelper();
        this.dateDaysElement = new DateDaysElement();
        this.multiSelect = new MultiSelectHelper();
        this.singleSelect = new SingleSelectHelper();
        this.thumbnailElement = new ThumbnailElement();
        this.imageTextHelper = new ImageTextHelper();
    }

    /**
     * Validate build form
     *
     * @param {forms} IForm[].
     * @return {Forms} IForm[].
     */
    validateBuildForm = async (forms: IForm[]): Promise<IForm[]> => {

        const Forms: IForm[] = [];

        for (const form of forms) {
            let formIsValid = true;
            let formIsVisible = false;
            let elementInProgress = false;
            const FormGroups: IFormGroup[] = form.groups;
            const Groups: IFormGroup[] = [];
            for (const formGroup of FormGroups) {
                const FormElements: IFormElement[] = formGroup.elements;
                const Elements: IFormElement[] = [];
                let groupIsVisible = false;
                for(const element of FormElements){
                    const Element: IFormElement = await this.validateFormElement(forms, {...element});
                    groupIsVisible = groupIsVisible || Element.is_visible;
                    const isValidElement = !Element.is_visible ? true : Element.isValid;
                    // If bucket upload is in progress
                    elementInProgress = elementInProgress || Element.loading;
                    formIsValid = formIsValid && isValidElement;
                    Elements.push(Element);
                }
                formIsVisible = formIsVisible || groupIsVisible;

                Groups.push({
                    ...formGroup,
                    elements: Elements,
                    isVisible: groupIsVisible
                });
            }
            Forms.push({
                ...form,
                groups: Groups,
                isVisible: formIsVisible,
                isValid: formIsValid,
                loading: elementInProgress
            });
        }
        return Forms;
    }

    validateFormElement = async (forms: IForm[], formElement: IFormElement): Promise<IFormElement> => {

        let isElementVisible = this.isElementVisible(forms, formElement);
        if(!_.isNull(formElement.form_element_auth) && _.isEqual(formElement.value,"")){
            isElementVisible = true;
        }
        else if(!_.isNull(formElement.form_element_auth) && !_.isNull(formElement.value)) {
            isElementVisible = false;
        }

        let isElementValid = true;

        let error = await this.validationHelper.validate(formElement, formElement.value);
        isElementValid = isElementValid && _.isEqual(error.length, 0);

        if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)) {
            error = this.validateTextElement(formElement, error);
        }
        if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.GRID)){
            if(formElement.is_required){
                error = this.validateGridElement(formElement, error);
            }

        }
        let highlight_option_error = "";
        if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)) {
            const {
                highlightOptionError,
                isValid
            } = this.validateSelectElement(formElement, isElementValid);
            highlight_option_error = highlightOptionError;
            isElementValid = isValid;
        }
        const isValidElement = !isElementVisible ? true : isElementValid;

        const touched = this.validateSelectOptionLimit(formElement);

        const IsEmptyElement = this.utilHelper.isEmptyElementValue(formElement, formElement.value);
        return ({
            ...formElement,
            error,
            highlight_option_error,
            touched: !isValidElement && !IsEmptyElement ? true : touched,
            is_visible: isElementVisible,
            isValid: isElementValid
        });
    }

    validateSelectOptionLimit = (formElement: IFormElement): boolean => {
        let touched = false;
        if (!_.isNull(formElement.value)
            && _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)
            && formElement.is_multi
            && !_.isNull(formElement.selection_limit) && _.gte(formElement.value.length, formElement.selection_limit)) {
            touched = true;
        }
        return touched;
    }

    validateTextElement = (formElement: IFormElement, errorMessage: string): string => {
        const ElementValue = (formElement.value || "").toString();
        let error = errorMessage;
        if (_.isEqual(ElementValue.length, 0)
            && formElement.is_required
            && formElement.is_email) {
            error = "";
            return error;
        }
        return error;
    }

    validateGridElement  = (formElement: IFormElement, errorMessage: string): string =>{
        
        let error = errorMessage;
        if(!_.isEqual(formElement.dataSource?.length, 0)
                        && formElement.is_required
                        ){
                            error = "";
            return error;
        }
        return error;
    }

    validateSelectElement = (formElement: IFormElement, isElementValid: boolean): {
        highlightOptionError: string,
        isValid: boolean
    } => {
        let highlightOptionError = "";
        let isValid = isElementValid;
        if (formElement.is_multi && !_.isNull(formElement.value)
            && _.isArray(formElement.value)
            && !_.isEqual(formElement.value.length, 0)) {
            const HighlightOptions = formElement.value.filter((option: IOption) => option.highlightOption);

            if (!_.isEqual(HighlightOptions.length, 0)) {
                highlightOptionError = "Please remove options in red as they no longer exist.";
                isValid = false;
            }
        }
        else if (!formElement.is_multi && !_.isNull(formElement.value)
            && !_.isEqual(formElement.value.length, 0)) {

            if (formElement.value.highlightOption) {
                highlightOptionError = "Please remove the option as it is no longer exist.";
                isValid = false;
            }
        }
        return {
            highlightOptionError,
            isValid
        };
    }

    /**
     * Validate Valaidations URL for Dynamic Configuration
     *
     * @param {forms} IForm[].
     * @return {UpdatedForms} IForm[].
     */
    validateConfigurationApiUrl = async (forms: IForm[]) : Promise<IForm[]> => {
        const UpdatedForms = forms.map((form: IForm)=> ({
            ...form,
            groups: form.groups.map((group: IFormGroup)=>({
                ...group,
                elements: group.elements.map((element: IFormElement)=>({...element}))
            }))
        }));
        for(let iForm=0;iForm<UpdatedForms.length;iForm++){
            const FormGroups = UpdatedForms[iForm].groups;
            for(let iFormGroup=0;iFormGroup<FormGroups.length;iFormGroup++){
                const FormElements = FormGroups[iFormGroup].elements;
                for(let iFormElement=0;iFormElement<FormElements.length;iFormElement++){
                    const FormElement = FormElements[iFormElement];
                    let Element: IFormElement = {...FormElement};
                    if (_.isEmpty(FormElement.parent_elements) && !_.isNull(FormElement.configuration_api_url) && !_.isNull(FormElement.value)){
                    try{
                        const ConfigurationData = await this.elementHelper.getConfigurationData(forms,FormElement);
                        Element={
                            ...FormElement,
                            ...ConfigurationData.data
                        };
                    }
                    catch(error:any){
                        Element.apiError = this.utilHelper.getErrorMessage(error);  
                    }
                    }
                    else{
                        Element={...FormElement};
                    }
                    FormElements[iFormElement] = {
                        ...FormElements[iFormElement],
                        ...Element
                    };
                } 
                FormGroups[iFormGroup] = {
                    ...FormGroups[iFormGroup],
                    elements: FormElements
                };
            }  
            UpdatedForms[iForm]={
                ...UpdatedForms[iForm],
                groups: FormGroups
            };
        }
        return UpdatedForms;
    }

    /**
     * Validate parent and child relationship for date and select
     *
     * @param {forms} IForm[].
     * @return {UpdatedForms} IForm[].
     */
    validateParentChildRelationship = async (forms: IForm[]): Promise<IForm[]> => {
        const UpdatedForms = forms.map((form: IForm) => ({
            ...form,
            groups: form.groups.map((group: IFormGroup) => ({
                ...group,
                elements: group.elements.map((element: IFormElement) => ({ ...element }))
            }))
        }));
        for(let iForm=0;iForm<UpdatedForms.length;iForm++){
            const FormGroups = UpdatedForms[iForm].groups;
            for (let iFormGroup = 0; iFormGroup < FormGroups.length; iFormGroup++) {
                const FormElements = FormGroups[iFormGroup].elements;

                for (let iFormElement = 0; iFormElement < FormElements.length; iFormElement++) {
                    const FormElement = FormElements[iFormElement];
                    let Element: IFormElement = {...FormElement};
                    const ParentElements = Element.parent_elements;
                    if(_.isNull(Element.is_dataset)){
                        if(!_.isEqual(ParentElements.length , 0)){
                            const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(UpdatedForms, ParentElements);
                            const isDisabled = IsAnyParentEmpty;
                            if(!IsAnyParentEmpty &&
                                !this.utilHelper.isEmptyValue(Element.api_url)){
                                let elementValue = "";
                                if(_.isEqual(Element.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)
                                || _.isEqual(Element.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
                                || _.isEqual(Element.type_id, UI_ELEMENT_TYPES.THUMBNAIL)
                                || _.isEqual(Element.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)
                                || _.isEqual(Element.type_id, UI_ELEMENT_TYPES.DOWNLOAD)
                                || _.isEqual(Element.type_id, UI_ELEMENT_TYPES.GRID)){
                                    let dataSource:any = [];
                                    try{
                                        const APIData = await this.elementHelper.getAPIData(UpdatedForms, Element);
                                        dataSource = _.isArray(APIData) ? APIData : [];

                                        if (_.isEqual(Element.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
                                            || _.isEqual(Element.type_id, UI_ELEMENT_TYPES.CHIP)
                                            || _.isEqual(Element.type_id, UI_ELEMENT_TYPES.DOWNLOAD)) {
                                            elementValue = APIData;
                                        }
                                        else if (_.isEqual(Element.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)) {
                                            dataSource = APIData;
                                        }
                                        if (Element.allow_all && !_.isEqual(dataSource.length, 0)) {
                                            dataSource.unshift(UtilHelper.allOptionLblVal);
                                        }
                                    }
                                    catch (error: any) {
                                        dataSource = [];
                                        Element.apiError = this.utilHelper.getErrorMessage(error);
                                    }
                                    Element.dataSource = dataSource;

                                    if (_.isEqual(Element.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
                                        || _.isEqual(Element.type_id, UI_ELEMENT_TYPES.DOWNLOAD)) {
                                        Element.value = elementValue;
                                    }
                                    else if (_.isEqual(Element.type_id, UI_ELEMENT_TYPES.THUMBNAIL)) {
                                        Element = this.thumbnailElement.getThumbnailValue(Element);
                                        Element.hideBasedOnParent = IsAnyParentEmpty;
                                    }
                                    else if (_.isEqual(Element.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)) {
                                        Element = this.imageTextHelper.getElementValue(Element);
                                        Element.hideBasedOnParent = IsAnyParentEmpty;
                                    }
                                    else if(_.isEqual(Element.type_id, UI_ELEMENT_TYPES.GRID)){
                                        Element.isParentCalled = true;
                                        Element.value = Element.dataSource;
                                        Element.hideBasedOnParent = IsAnyParentEmpty;
                                    }
                                    else{
                                        Element = Element.is_multi ? this.multiSelect.getMultiDropdownValue(Element)
                                            : this.singleSelect.getSingleDropdownValue(Element);
                                    }

                                    const Value: any = Element.value;

                                    if (Element.is_multi && !_.isNull(Element.selection_limit)
                                        && _.isEqual(Element.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)
                                        && !_.isNull(Value)
                                        && _.isArray(Value)) {
                                        Element = this.elementHelper.updateSelectionLimit(Element, Value);
                                    }

                                    Element.value = Value;

                                    const APIValueURL = FormElement.api_value_url || "";

                                    if (!_.isEmpty(APIValueURL)) {
                                        const APIValueData = await this.elementHelper.getAPIValueData(UpdatedForms, Element);
                                        Element = FormElement.is_multi ? this.multiSelect.getMultiSelectAPIValue(Element, APIValueData) :
                                            this.singleSelect.getSingleSelectAPIValue(Element, APIValueData);
                                    }

                                    if (( !_.isNull(Element.configuration_api_url) && !_.isEmpty(Element.parent_elements))) {
                                        try {
                                            const ConfigurationData = await this.elementHelper.getConfigurationData(forms, Element);
                                            Element = {
                                                ...Element,
                                                ...ConfigurationData.data
                                            };
                                        }
                                        catch (error: any) {
                                            Element.apiError = this.utilHelper.getErrorMessage(error);
                                            console.log(error);
                                        }
                                    }
                                }
                            }
                            else{
                                Element.value = !_.isNull(Element.value) ? Element.value : null;
                            }
                            Element.touched = !_.isNull(Element.value);
                            Element.disabled = isDisabled || Element.read_only;
                            Element.hideBasedOnParent = IsAnyParentEmpty;
                        }
                        else if (_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.DATETIME_INPUT)) {
                            if (FormElement.is_number) {
                                const UpdatedElement = this.dateDaysElement.validateDaysPickers(FormElement, UpdatedForms);
                                Element = {
                                    ...Element,
                                    ...UpdatedElement
                                };

                            }
                            else {
                                const UpdatedElement = this.dateDaysElement.validateDateTimePickers(FormElement, UpdatedForms);
                                Element = {
                                    ...Element,
                                    ...UpdatedElement
                                };
                            }
                        }
                        else if (_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)) {
                            if (FormElement.is_number) {
                                const UpdatedElement = this.validateNumbersRelationship(FormElement, UpdatedForms);
                                Element = {
                                    ...Element,
                                    ...UpdatedElement
                                };

                            }
                        }
                    }

                    FormElements[iFormElement] = {
                        ...FormElements[iFormElement],
                        ...Element
                    };
                }
                FormGroups[iFormGroup] = {
                    ...FormGroups[iFormGroup],
                    elements: FormElements
                };
            }
            UpdatedForms[iForm] = {
                ...UpdatedForms[iForm],
                groups: FormGroups
            };
        }
        return UpdatedForms;
    }

    /**
     * Validate number relationship
     *
     * @param {formElement} IFormElement.
     * @param {forms} IForm[].
     * @return {Element} IFormElement.
     */
    validateNumbersRelationship = (formElement: IFormElement, forms: IForm[]): IFormElement => {
        const Element = { ...formElement };
        if (!_.isNull(formElement.parent_compare_element_id)) {
            const ParentElement = this.elementHelper.findFormElement(forms, formElement.parent_compare_element_id);
            if (!_.isNull(ParentElement?.value) && !_.isNull(formElement.value)
                && _.lt(parseInt(formElement.value), parseInt(ParentElement?.value))) {
                Element.error = `${formElement.labelName} should be greater or equal to ${ParentElement?.labelName}.`;
                Element.isValid = false;
            }
        }
        else {
            const ChildElement = this.elementHelper.findDateChildFormElement(forms, formElement.id);
            if (!_.isNull(ChildElement)
                && !_.isNull(ChildElement?.value) && !_.isNull(formElement.value)
                && _.gt(parseInt(formElement.value), parseInt(ChildElement?.value))) {
                Element.error = `${formElement.labelName} should be less or equal to ${ChildElement?.labelName}.`;
                Element.isValid = false;
            }
        }
        return Element;
    }

    /**
     * Find Element Visibility
     *
     * @param {formElement} IFormElement.
     * @param {forms} IForm[].
     * @return {Boolean} True/False.
     */
    isElementVisible = (forms: IForm[], element: IFormElement): boolean => {
        for (const form of forms) {
            const FormGroups = form.groups;
            for (const formGroup of FormGroups) {
                const FormElements = formGroup.elements;
                for (const formElement of FormElements) {
                    if (_.isEqual(formElement.id, element.depends_on)) {
                        return this.checkElementVisibility(formElement, element);
                    }
                }
            }
        }
        return element.is_visible;
    }

    /**
     * Check Element Visibility
     *
     * @param {IFormElement} formElement.
     * @param {IFormElement} element.
     * @return {Boolean} True/False.
     */
    checkElementVisibility = (formElement: IFormElement, element: IFormElement): boolean => {
        let elementValue = this.utilHelper.getElementValue(formElement);
        elementValue = _.isNull(elementValue) ? "" : (elementValue || "").toString().toLowerCase();
        const Dependentvalues = _.toLower(element.dependent_values || "").toString().trim();

        if (_.isEqual(elementValue.length, 0)) {
            return false;
        }

        return !_.isEqual(Dependentvalues.indexOf(elementValue), -1);
    }
}
