import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Grid, Theme } from "@material-ui/core";
import { useSelector } from "react-redux";
import style from "./style";
import { MappingPage } from "../Layout/Page";
import { Tile } from "./Tile";
import { FacebookAuth } from "./Auth/Facebook";
import { GoogleAuth } from "./Auth/Google";
import { CircularProgress } from "../../../UI/Progress";
import { Axios } from "../../../api/service/Axios";
import {
  GET_MAPPER_DATA,
  INSERT_GOOGLE_FB_TOKEN,
} from "../../../api/APIPaths/mapping";
import { IMapper } from "./interface/IMapper";
import { useHistory } from "react-router-dom";
import { catchError } from "../Utility/ErrorMessage";
import SnackbarAlert from "../../../UI/Snackbar/SnackbarAlert.component";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

enum AUTH_LOGIN {
  GOOGLE = "Google",
  FACEBOOK = "Facebook",
}

export const MapperTile = (): React.JSX.Element => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [tileData, setTileData] = useState<IMapper[]>([]);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });
  const history = useHistory();
  const bundle = useSelector((state: any) => state.initial.bundle);

  const setErrorAlert = (message: string): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant: "error",
      message,
    });
  };

  const onLogin = async (
    payload: any,
    assignmentId: number,
    platformName: string
  ): Promise<void> => {
    try {
      setLoading(true);
      await Axios.post(INSERT_GOOGLE_FB_TOKEN, payload);
      await getMapperData();
      history.push(
        `/mapping/mapper?assignmentId=${encodeURIComponent(
          assignmentId
        )}&platform=${encodeURIComponent(platformName)}`
      );
      setLoading(false);
    } catch (err) {
      const errorMessage = catchError(
        err,
        bundle.MAPPER_LOGIN_FAILED_ERROR_MSG
      );
      setErrorAlert(errorMessage);
      setLoading(false);
    }
  };

  const getMapperData = async (): Promise<void> => {
    const Response = await Axios.get(GET_MAPPER_DATA);
    setTileData(Response.data);
  };

  const FacebookApp = (mapperData: IMapper): React.JSX.Element => {
    if (_.isNull(mapperData.loginApp)) {
      return <span />;
    }
    return (
      <FacebookAuth
        key={mapperData.mappingAssignmentsId}
        appid={mapperData.loginApp.appId}
        fields={mapperData.loginApp.fields}
        onResponse={(response: any) => {
          if (
            !_.isUndefined(response.accessToken) &&
            !_.isNull(mapperData?.loginApp)
          ) {
            onLogin(
              {
                typeId: mapperData.loginApp.typeId,
                accessToken: response.accessToken,
              },
              mapperData.mappingAssignmentsId,
              mapperData.platformName
            );
            return;
          }
          setLoading(false);
        }}
        onRender={(renderProps: any) => {
          return (
            <Tile
              handleClick={() => {
                setLoading(true);
                renderProps.onClick();
              }}
              mapperData={mapperData}
              loading={false}
            />
          );
        }}
        scope={mapperData.loginApp.scope}
      />
    );
  };

  const GoogleApp = (mapperData: IMapper): React.JSX.Element => {
    if (_.isNull(mapperData.loginApp)) {
      return <span />;
    }
    return (
      <GoogleAuth
        key={mapperData.mappingAssignmentsId}
        appid={mapperData.loginApp.appId}
        onResponse={(response: any) => {
          if (!_.isUndefined(response.code) && !_.isNull(mapperData.loginApp)) {
            onLogin(
              {
                typeId: mapperData.loginApp.typeId,
                authorizationCode: response.code,
              },
              mapperData.mappingAssignmentsId,
              mapperData.platformName
            );
            return;
          }
          if (_.isEqual(response.error, "popup_closed_by_user")) {
            setLoading(false);
            return;
          }

          setLoading(false);
        }}
        scope={mapperData.loginApp.scope || ""}
        onRender={(renderProps: any) => {
          return (
            <Tile
              loading={false}
              handleClick={() => {
                if (!renderProps.disabled) {
                  setLoading(true);
                }
                renderProps.onClick();
              }}
              mapperData={mapperData}
            />
          );
        }}
      />
    );
  };

  const renderTile = (mapperData: IMapper): React.JSX.Element => {
    if (mapperData.isLoginRequire && !_.isNull(mapperData.loginApp)) {
      if (_.isEqual(mapperData.loginApp?.type, AUTH_LOGIN.FACEBOOK)) {
        return FacebookApp(mapperData);
      } else if (_.isEqual(mapperData.loginApp?.type, AUTH_LOGIN.GOOGLE)) {
        return GoogleApp(mapperData);
      }
    }
    return (
      <Tile
        key={mapperData.mappingAssignmentsId}
        handleClick={() => {
          const Id = mapperData.mappingAssignmentsId;
          const PlatformName = mapperData.platformName;
          history.push(
            `/mapping/mapper?assignmentId=${encodeURIComponent(
              Id
            )}&platform=${encodeURIComponent(PlatformName)}`
          );
        }}
        loading={false}
        mapperData={mapperData}
      />
    );
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  useEffect(() => {
    const getInitialData = async (): Promise<void> => {
      try {
        setLoading(true);
        await getMapperData();
        setLoading(false);
      } catch (err) {
        const errorMessage = catchError(
          err,
          bundle.MAPPER_INITIAL_DATA_ERROR_MSG
        );
        setErrorAlert(errorMessage);
        setLoading(false);
      }
    };
    getInitialData();
  }, []);

  return (
    <>
      <Helmet>
        <title>{bundle.MAPPER_TILE_PAGE_TITLE}</title>
      </Helmet>
      <MappingPage
        pageTitle={bundle.MAPPER_TILE_PAGE_TITLE}
        recordCount="none"
        enableContentBgColor={false}
        tabComponent={<></>}
        header={<></>}
        footer={<></>}
        customHeaderStyle={{
          paddingLeft: 36,
        }}
        hideContent={false}
      >
        <Grid
          container
          className={classes.toolBoxWrapper}
          spacing={2}
          direction="row"
        >
          {tileData.map((mapperData: IMapper) => {
            return renderTile(mapperData);
          })}
          {!loading && _.isEmpty(tileData) && (
            <div className={classes.noDataAvailable}>
              <div>No data available</div>
            </div>
          )}
        </Grid>
        {loading && <CircularProgress />}
        <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
        />
      </MappingPage>
    </>
  );
};
