import { Theme } from "@material-ui/core";


const style: any = (theme: Theme): any => {
	return {
		radioGroupLabel: {
			transform: "translate(0, 1.5px) scale(1)",
			transformOrigin: "top left",
			fontSize: "0.9rem",
		},
		error: {
			color: theme.palette.error.main
		},
		formGroup: {
			display: "inline-block"
		},
		tooltip: {
			padding: 0,
			marginTop: -2
		},
	};
};

export default style;