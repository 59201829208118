import React, { Fragment } from "react";
import { Input } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import classNames from "classnames";
import { IFormElement } from "../../../Interface/FormElement/IFormElement";
import UILabel from "../ChildElements/Label";
import { UIErrorOrNoteMessage } from "../ChildElements/ErrorOrNote";
import style from "./style";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));


// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: string;
  formGroupId: string;
};

// UIInput Component
export const UITextInput = (props: props): React.JSX.Element => {
  const { formElement, formId, formGroupId, onChange } = props;
  const classes = useStyles();
  const Disabled = formElement.disabled;
  const Readonly= formElement.readonly;
  const errorMessage = formElement.touched ? formElement.errorMessage : "";
 
  let Props = {};

  if (formElement.configuration.isMulti) {
    Props = {
      ...Props,
      rows: formElement.configuration.multiRows || 5,
      multiline: true,
    };
  }

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <Input
        type="text"
        {...Props}
        disabled={Disabled}
        readOnly={Readonly}
        id={`${formElement.id}`}
        className={classes.container}
        style={{
          height: formElement.configuration.isMulti ? "auto" : 38,
          border: "0"
        }}
        value={formElement.value}
        placeholder={formElement.configuration.placeholder}
        onChange={(event: any): void => {
          const InputValue = event.target.value;
          onChange({ formId, formGroupId, formElement, value: InputValue });
        }}
        onBlur={(): void => {
          onChange({
            formId,
            formGroupId,
            formElement,
            value: formElement.value,
          });
        }}
        inputProps={{
          style: {
            height: formElement.configuration.isMulti ? "auto" : 38,
          },
          className: classNames(classes.input, {
            [classes.disabled]: Disabled,
            [classes.inputElement]:
              !Disabled && _.isEqual(errorMessage.length, 0),
            [classes.error]: !_.isEqual(errorMessage.length, 0),
          }),
        }}
      />
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={errorMessage}
      />
    </Fragment>
  );
};
