import React from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-datepicker/dist/react-datepicker.css";
import MomentUtils from "@date-io/moment";
import { Provider } from "react-redux";
import Theme from "./UI/Theme";
import "./App.css";
import Layout from "./Layout";
import store from "./store";
import { OktaAuthComponent } from "./auth/auth.component";



const App: React.FunctionComponent = (): any => {
  const ReduxProvider: any = Provider;
  return (
    <>
      <ReduxProvider store={store}>
        <BrowserRouter>
          <OktaAuthComponent>
            <MuiThemeProvider theme={Theme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Layout />
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </OktaAuthComponent>
        </BrowserRouter>
      </ReduxProvider>
    </>
  );
};

export default App;
