const style:any = (): any => ({
	root: {
		display: "flex",
		height:"100%"
	},
	content: {
		flexGrow: 1,
		marginTop: 56,
		height: "calc(100vh - 70px)",
	},
	container:{
		height:"100%"
	},
	embedContent: {
		flexGrow: 1,
		height: "calc(100vh - 70px)",
	},
    subContainer: {
		textAlign:"center" as const,
		padding:"50px 5px",
		marginTop: 80,
		display: "flex" as const,
		alignItems: "center",
		alignContent: "center",
		flexDirection: "column" as const
	},
	description:{
		fontSize:"12px"
	}
});

export default style;