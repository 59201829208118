import React, { useEffect, useState } from "react";
import { components } from "react-select";
import * as _ from "lodash";
import AsyncSelect from "react-select/async";
import { CustomStyles } from "./style";
import { IOption } from "../../../../../UI/Elements/Forms/interface/IOption";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { IFormElementConfig } from "../../../Interfaces/Form/FormElement/IFormElementConfig";
import { IFormElementValidation } from "../../../Interfaces/Form/FormElement/IFormElementValidation";
import { isCloseMenuOnSelect } from "./Utility";
import { FormHelper } from "../../Form/Helpers/Forms/Helper";
import { IForm } from "../../../Interfaces/Form/IForm";
import { EditFormHelper } from "../../Form/Helpers/Forms";
import { Axios } from "../../../../../api/service/Axios";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import { FormElementHelper } from "../../Form/Helpers/Element";

type IProps = {
  onChange: any;
  value: IOption | null;
  formElement: IFormElement;
  form: IForm;
  errorMessage: string;
  note: string;
};

const formHelper = new FormHelper();
const formElementHelper = new FormElementHelper();

export const UIAsyncSelect = (props: IProps): any => {
  const { onChange, value, formElement, errorMessage, form, note } = props;
  const [selectValue, setSelectValue] = useState(value);

useEffect(() => {
    setSelectValue(value);
  }, [JSON.stringify(value)]);
  
  const [errorMsg, setErrorMsg] = useState("");

  const ElementConfiguration: IFormElementConfig = formElement.configuration;
  const isError = !_.isEqual(errorMessage.length, 0);
  const ElementValidation: IFormElementValidation = formElement.validations;
  const Disabled = formElement.readOnly || formElement.disabled;
  const Has_ChildElements: boolean = formHelper.ifHasChildElements(form,formElement);

  const IsHighlightOption_Error = !_.isEqual((formElement.highlightRemovedOptionError || "").length,0);

  

  const promiseOptions = _.debounce((inputValue, callback) => {
    const InputValue = (inputValue || "").toString().trim();

    if (!_.isEmpty(InputValue) && _.gt(inputValue.length, 1)) {
      const URL = formElementHelper.getAsyncQueryParams(
        form,
        formElement,
        InputValue
      );
      Axios.get(URL)
        .then((res) => {
          callback(_.isArray(res.data) ? res.data : []);
        })
        .catch((err: any) => {
          console.log(err);
          const errMsg = "Unable to fetch data";
          setErrorMsg(errMsg);
          callback([]);
        });
    } else {
      callback(
        _.isArray(ElementConfiguration.dataSource)
          ? ElementConfiguration.dataSource
          : []
      );
    }
  }, 1000);

  const onSelectChange = (selectOption: any): void => {
    const editFormHelper = new EditFormHelper();
    const SelectValue = editFormHelper.updateSelectValue(
      formElement,
      selectOption
    );
    setSelectValue(SelectValue);
    if (
      !Has_ChildElements ||
      (Has_ChildElements && !ElementConfiguration.isMulti)
    ) {
      onChange(SelectValue);
    }
  };

  return (
    <>
      <AsyncSelect
        closeMenuOnSelect={isCloseMenuOnSelect(formElement)}
        isClearable={!ElementValidation.required}
        value={selectValue}
        isMulti={ElementConfiguration.isMulti}
        isDisabled={Disabled}
        cacheOptions
        onMenuClose={() => {
          if (Has_ChildElements && ElementConfiguration.isMulti) {
            onChange(selectValue);
          }
        }}
        components={{
          DropdownIndicator: (data: any) => {
            return (
              <components.DropdownIndicator
                {...data}
                className="caret-select"
              />
            );
          },
        }}
        styles={CustomStyles(isError, Disabled, IsHighlightOption_Error)}
        onChange={(selectedOption: any) => {
          return onSelectChange(selectedOption);
        }}
        onBlur={(): void => {
          return onSelectChange(selectValue);
        }}
        defaultOptions={
          _.isArray(ElementConfiguration.dataSource)
            ? ElementConfiguration.dataSource
            : []
        }
        loadOptions={promiseOptions}
        placeholder={ElementConfiguration.placeholder}
      />
      <UIErrorOrNoteMessage note={note} errorMessage={errorMsg ? errorMsg : errorMessage} />
    </>
  );
};
