import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../utilstyle";

const style:any = (theme: Theme) => ({
	container: {
		width: "100%",
		margin: "32px 40px",
	},
	form: {
		backgroundColor: "#ffffff",
		padding: "2rem",
		width: "60%",
	},
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	infoWrapper: {
		padding: 12,
		justifyContent: "center"
	},
	contentBoxInfoWrapper: {
		width: "100%",
	},
	toolContentWrapper: {
		padding: "20px 28px 28px 28px",
	},
	toolContent: {
		width: "100%",
		backgroundColor: "#FFFF",
		padding: 31,
		height: "100%",
	},
	addButtonWrapper: {
		display: "flex",
		color: "#005FBE",
		paddingBottom: "20px",
		marginBottom: "30px",
		height: "14px",
	},
	addButton: {
		backgroundColor: "white",
		color: "#005FBE",
		border: "none",
		fontWeight: "bold",
		fontSize: "14px",
		padding: "3px",
		cursor: "pointer",
	},
	deleteButtonContainer: {
		marginBottom: "20px",
	},
	deleteButtonWrapper: {
		display: "flex",
		color: "#005FBE",
		float: "right",
		cursor: "pointer",
	},
	deleteButton: {
		backgroundColor: "white",
		color: "#005FBE",
		border: "none",
		fontWeight: "bold",
		fontSize: "0.875rem",
		padding: "10px",
	},
	additionalFormLine: {
		borderTop: "1px solid lightgrey",
		padding: "10px",
	},
	reviewForm:{
		marginBottom:"2rem",
	},
	reviewStepContainer:{
		display: "flex",
		alignItems: "center",
		marginBottom: "1.125rem"
	},
	reviewFormTitle:{
		fontSize:"1.25rem",
		fontWeight:600,
	},
	reviewFormStepLink:{
		color: "#005fbe",
		fontSize: 14,
		marginLeft: 12,
		fontWeight:500,
		cursor:"pointer"
	},
	reviewFormGroup:{
		marginBottom:"2rem"
	},
	formElement:{
		marginBottom: "1.5rem"
	},
	elementValue:{
		fontSize:"0.875rem",
		minHeight:21,
		whiteSpace:"pre-line",
		wordBreak:"break-word"
	},
	formGroupTitle:{
		fontSize: "1rem",
		fontWeight: 600,
		marginBottom: "1rem"
	},
	elementLabel:{
		minHeight: 21,
		color: "rgb(36, 44, 52)",
		fontSize: "0.875rem",
		"word-break":"break-all",
		"font-weight":"bold",
		display: "flex",
		alignItems: "center",
	},
	chipContainer:{
		marginBottom:"1.5rem",
		display:"flex",
		flexWrap:"wrap",
		marginTop:"0.5rem"
	},
	imageTextContainer:{
		display: "flex",
		alignItems: "center",
	  },
	imageContainer:{
		width: 50,
		height: 50,
		borderRadius: 50,
		marginRight:10,
		position:"relative",
		border: `1px solid ${COLORS.BorderColor}`,
		overflow:"hidden",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
});

export default style;
