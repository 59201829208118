import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";

type Props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

export const UIAddButton = (props: Props): JSX.Element => {
  const { formElement, onChange, formId, formGroupId } = props;
  const val = formElement.value || 0;
  

  let errorMessage = formElement.touched ? formElement.error : "";
  let disabled = formElement.disabled || formElement.read_only;
  if (!_.isEmpty(formElement.apiError)) {
    errorMessage = formElement.apiError;
    disabled = false;
  }

  const linkLabel = formElement.labelName || "Default Label";
  const [clickCount, setClickCount] = useState<number>(val);
  const getDynamicCount = formElement.add_dynamic_elements && formElement.add_dynamic_elements?.count;
  const maxClicks = getDynamicCount ? getDynamicCount : 5;
  const onLinkClicknew = ():any=>{
    onChange({
      formId,
      formGroupId,
      formElement,
      value:clickCount +1 
    });
    setClickCount(clickCount +1);
  };

 useEffect(()=>{
  setClickCount(val);
 },[val]);
  return (
    <>
      <p
        onClick={onLinkClicknew}
        style={{
          textTransform: "none",
          fontWeight: 600,
          color: "blue",
          textDecoration: "none",
          cursor: "pointer",
          display: clickCount >= maxClicks ? "disabled" : "inline-block",
          margin: "10px 0",
          pointerEvents: clickCount >= maxClicks ? "none" : "auto",
          opacity: clickCount >= maxClicks ? 0.2 : 1,
        }}
      >
        {linkLabel}
      </p>
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={disabled ? "" : errorMessage}
      />
    </>
  );
};

export default UIAddButton;