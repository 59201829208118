import React from "react";
import { IForm } from "../../Interfaces/Form/IForm";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IFormGroup } from "../../Interfaces/Form/IFormGroup";
import { FormElement } from "./Element";
import style from "./style";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onChange: any;
  onCreateOption: any;
  form: IForm;
};

export const FormBuilder = (props: props): React.JSX.Element => {
  const { form, onChange, onCreateOption } = props;
  const classes = useStyles();

  const FormGroups = form.groups.map((formGroup: IFormGroup) => {
    const GroupTitle = (formGroup.title || "").toString().trim();

    return (
      <div className={classes.formGroup} key={formGroup.id}>
        {!_.isEqual(GroupTitle.length, 0) && (
          <header className={classes.sectionTitle}>{formGroup.title}</header>
        )}
        {formGroup.elements
          .filter((formElement: IFormElement) => formElement.visible)
          .map((formElement: IFormElement) => (
            <FormElement
            form={form}
            formElement={formElement}
            key={formElement.id}
            onChange={onChange}
            onCreateOption={onCreateOption} 
            formId={0} 
            formGroupId={0}
            
            />
          ))}
      </div>
    );
  });

  return <>{FormGroups}</>;
};
