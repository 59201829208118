import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { IForm } from "../../../../../../../UI/Elements/Forms/interface/IForm";
import { IFormElement } from "../../../../../../../UI/Elements/Forms/interface/IFormElement";
import { IFormGroup } from "../../../../../../../UI/Elements/Forms/interface/IFormGroup";
import style from "./style";
import { UtilHelper } from "../../../../../../../UI/Elements/Forms/helper/Utils";
import { UI_ELEMENT_TYPES } from "../../../../../../../UI/Elements/config";
import { getTextInput } from "./Utility";
import {
  getDefaultElements,
  getChips,
  getFileInput,
  getCheckBoxInput,
  getSelectInput,
  getImageText,
} from "./Helper";
import { UIThumbnailView } from "./Thumbnail";
import { MiddleTextEllipsis } from "../Elements/Text/MiddleEllipsis";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  forms: IForm[];
  jobId: number;
  jobName: string;
};

const utilHelper = new UtilHelper();

export const ReviewForm = (props: props): React.JSX.Element | any => {
  const { forms, jobId, jobName } = props;
  const classes = useStyles();

  return forms.map((form: IForm) => {
    const Groups: any = form.groups
      .map((group: IFormGroup) => {
        const Elements: any = group.elements
          .filter((formElement: IFormElement) => formElement.is_visible)
          .map((formElement: IFormElement) => {
            let ElementValue =
              utilHelper.getElementValueForReviewForm(formElement);

            switch (formElement.type_id) {
              case UI_ELEMENT_TYPES.TEXT_INPUT:
                ElementValue = getTextInput(formElement, ElementValue);
                break;
              case UI_ELEMENT_TYPES.FILE_INPUT:
                ElementValue = getFileInput(formElement, ElementValue);
                break;
              case UI_ELEMENT_TYPES.CHECKBOX_INPUT:
                ElementValue = getCheckBoxInput(classes, ElementValue);
                break;
              case UI_ELEMENT_TYPES.SELECT_INPUT:
                ElementValue = getSelectInput(
                  formElement,
                  classes,
                  ElementValue
                );
                break;
              case UI_ELEMENT_TYPES.CHIP:
                ElementValue = getChips(classes, formElement.value);
                break;
              case UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD:
                ElementValue = getImageText(formElement);
                break;
              case UI_ELEMENT_TYPES.THUMBNAIL:
                ElementValue = <UIThumbnailView formElement={formElement} />;
                break;
              case UI_ELEMENT_TYPES.DOWNLOAD:
                ElementValue = <></>;
                break;
              default:
                ElementValue = getDefaultElements(formElement, ElementValue);
                break;
            }

            if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DOWNLOAD)) {
              return <></>;
            }

            return (
              <div className={classes.formElement} key={formElement.id}>
                {_.isNull(formElement.form_element_auth) && (
                  <div className={classes.elementLabel}>
                    {formElement.labelName}
                  </div>
                )}
                {
                  _.isNull(formElement.form_element_auth) && (
                    <div className={classes.elementValue}>{ElementValue}</div>
                  )
                }

              </div>
            );
          });

        return (
          <div className={classes.reviewFormGroup} key={group.id} style={{ width: "100%" }}>
            <div className={classes.formGroupTitle}>{group.title}</div>
            {Elements}
          </div>
        );
      });

    return (
      <div className={classes.reviewForm} key={form.id} style={{ width: "100%" }}>
        <div className={classes.reviewStepContainer}>
          <div className={classes.reviewFormTitle}>
            {form.title}
          </div>
        </div>
        <div className={classes.formElement} key={jobId}>
          <div className={classes.elementLabel}>
            Job Name
          </div>
          <div>
            <div className={classes.elementValue} style={{ width: "100%" }}>
              <MiddleTextEllipsis title={jobName} showTooltip={true} />
            </div>
            <div className={classes.subValue}>
              {jobId}
            </div>
          </div>
        </div>
        {Groups}
      </div>
    );
  });
};
