import COLORS from "../../UI/Theme/colors";

const style: any = (): any => ({
	toolContentWrapper: {
		padding: "20px 28px 28px 28px",
	},
	infoWrapper: {
		padding: 12,
	},
	toolContent: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.WHITE,
		padding: 31,
	},
	linearLoaderPlaceholer:{
		height:4,
		width:"100%"
	},
	backdrop:{
		position:"fixed",
		top:0,
		left:0,
		right:0,
		bottom:0,
		backgroundColor: COLORS.WHITE,
		opacity:0,
		zIndex:99999999
	}
});

export default style;