import React, { Fragment } from "react";
import { withStyles } from "@material-ui/styles";
import * as _ from "lodash";
import styles from "./style";
import classNames from "classnames";

type props = {
  classes?: any;
  bundle?: any;
  status: string;
};

const StatusIndicator = (props: props): JSX.Element => {
  const { classes, status } = props;
  const STATUS_ID = _.isNull(status) ? "" : status.toLowerCase().replace(" ","");
  return (
    <Fragment>
      <div className={classNames(classes[STATUS_ID], classes.text)}>
        {!_.isEqual(STATUS_ID, "regular") ? status : ""}
      </div>
    </Fragment>
  );
};

export default withStyles(styles)(StatusIndicator);
