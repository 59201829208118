import Colors from "../../utilstyle";
import THEME_COLORS from "../../../Theme/colors";

const style: any = (): any => ({
	container:{
		display: "flex",
		alignItems: "center",
	  },
	inputContainer:{
		width: "100%",
		marginTop:"0.5rem"
	},
	input: {
		backgroundColor: Colors.BackgroundColor,
		border: `1px solid ${Colors.BorderColor}`,
		borderRadius: 2,
		width:"100%",
		fontSize:"0.875rem",
		padding: "7px 12px",
		"box-sizing": "border-box",
	},
	textfield: {
		backgroundColor: Colors.BackgroundColor,
		border: `1px solid ${Colors.BorderColor}`,
		borderRadius: 2,
		width:"100%",
		fontSize:"0.875rem",
		padding: "7px 12px",
		"box-sizing": "border-box",
		marginTop: "-4px",
		marginBottom: "-4px",
	},
	inputElement:{
		"&:hover":{
			border: `1px solid ${Colors.HoverColor}`,
		},
		"&:focus":{
			border: `1px solid ${Colors.FocusColor}`,
		}
	},
	imageContainer:{
		position:"relative",
		overflow:"hidden",
		"&:hover":{
			"& $editIcon":{
				display:"block"
			},
		},
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	  loaderContainer:{
		position: "absolute",
		zIndex: 1,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width:80,
		height:80
	  },
	  loader:{
		position: "relative",
		width: "60px !important",
		height: "60px !important",
        color: THEME_COLORS.GRAY,
	  },
	thumbnail:{
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	  },
	  retry:{
		  position:"absolute",
		  top: 0,
		  bottom: 0,
		  left: 0,
		  right: 0,
		  width: "100%",
		  height: "100%",
		  display: "flex",
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: THEME_COLORS.RED
	  },
	  fileAction:{
		  fontSize:"0.75rem",
		  color: THEME_COLORS.DARK_BLUE,
		  cursor:"pointer",
		  marginLeft: 20
	  },
	  icon:{
		width: 80,
		height: 80,
		borderRadius: 80,
	  },
	  readOnlyText:{
		  marginLeft:"24px"
	  }, 
	  note: {
        color: THEME_COLORS.GRAY,
        fontSize: 10,
        fontWeight: 500,
		position:"absolute",
		marginTop:4
    },
	uploadBtnContainer:{
		display: "flex",
    	alignItems: "center"
	},
	fileSelection:{
		marginLeft:24,
		position:"relative",
		width:"100%"
	},
	iconPlaceholder:{
		width: 80,
		height: 80,
		backgroundColor: "#f7f8fa",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderRadius: 80
	}
});

export default style;