import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Card, Grid, Theme } from "@material-ui/core";
import classnames from "classnames";
import style from "./style";
import { SingleLineText } from "./Content/SingleLine";
import { TwoLineText } from "./Content/TwoLine";
import { TextPlaceholder } from "./Content/TextPlaceholder";
import { IMapper } from "../interface/IMapper";
import { SingleLineEllipsisText } from "../../Layout/Elements/Text/SingleLineEllipsis";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  handleClick: any;
  mapperData: IMapper;
  loading: boolean;
};

export const Tile = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { handleClick, mapperData, loading } = props;

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={4}
      lg={3}
      xl={3}
      justifyContent="center"
      className={classnames({ [classes.loading]: loading })}
    >
      <Card className={classes.cardContainer} onClick={() => handleClick()}>
        <Box display="flex" className={classes.toolLogo}>
          <Box flexGrow={1}>
            <img src={mapperData.platformIcon} width="40px" height="40px" />
          </Box>
        </Box>
        <div className={classes.platformName}>
          <SingleLineEllipsisText text={mapperData.platformName} />
        </div>
        <div className={classes.instructions}>
          <TwoLineText
            text={mapperData.instructions}
            tooltip={mapperData.instructions}
          />
        </div>
        <Grid className={classes.detailContainer} container>
          <Grid container item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextPlaceholder
              labelName="Manager"
              content={
                <SingleLineText
                  text={mapperData.manager.name}
                  tooltip={`${mapperData.manager.name} - ${mapperData.manager.username}`}
                  disableTooltip={false}
                />
              }
            />
          </Grid>
          <Grid container item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextPlaceholder
              labelName="Agency(s)"
              content={
                <TwoLineText
                  text={mapperData.agencies.join(", ")}
                  tooltip={mapperData.agencies.join(", ")}
                />
              }
            />
          </Grid>
        </Grid>
        <Grid className={classes.detailContainer} container>
          <Grid container item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextPlaceholder
              labelName="Region"
              content={<SingleLineEllipsisText text={mapperData.regionName} />}
            />
          </Grid>
          <Grid container item xs={12} sm={6} md={6} lg={6} xl={6}>
            <TextPlaceholder
              labelName="Market(s)"
              content={
                <TwoLineText
                  text={mapperData.markets.join(", ")}
                  tooltip={mapperData.markets.join(", ")}
                />
              }
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
