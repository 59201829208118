import React from "react";
import "./style.css";

type props = {
  content: string;
};

export const AuthContent = (props: props): JSX.Element => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: props.content,
      }}
      className="auth-container"
    ></div>

  );
};
