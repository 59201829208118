import * as _ from "lodash";
import { ALL_OPTION_OBJ, ELEMENT_TYPES } from "../../../../configuration";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";
import { IFormElementUpdate } from "../../../../Interfaces/Form/FormElement/IFormElementUpdate";
import { IForm } from "../../../../Interfaces/Form/IForm";
import { IFormGroup } from "../../../../Interfaces/Form/IFormGroup";
import { FormElementHelper } from "../Element";
import { UtilHelper } from "../Utility";
import { ValidationHelper } from "../Validations";

// Parent Child Element Util
export class ParentChildElementUtil {

	private validationHelper: ValidationHelper;
	private elementHelper: FormElementHelper;
	private utilHelper: UtilHelper;

	constructor() {
		this.validationHelper = new ValidationHelper();
		this.elementHelper = new FormElementHelper();
		this.utilHelper = new UtilHelper();
	}

	private findParentElementsForSelect = (form: IForm, formElement: IFormElement, element: IFormElementUpdate): IFormElement => {
		let Element = { ...formElement };
		const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(form, Element.configuration.parentElements);

		if (IsAnyParentEmpty) {

			let errorMessage = "";
			if (Element.validations.required) {
				errorMessage = this.validationHelper.validate(Element,
					_.isEqual(Element.type, ELEMENT_TYPES.TEXT_INPUT) ? "" : null);
			}


			Element = {
				...Element,
				disabled: true,
				value: !_.isUndefined(formElement.configuration.selectValueIfEmpty) ? formElement.configuration.selectValueIfEmpty.value : null,
				configuration: {
					...Element.configuration,
					dataSource: []
				},
				errorMessage,
				isValid: _.isEqual(errorMessage.length, 0),
				touched: _.isEqual(Element.id, element.formElement.id) ? true : formElement.touched,
			};

		}
		else {

			let errorMessage = "";
			if (formElement.validations.required) {
				errorMessage = this.validationHelper.validate(Element,
					Element.value);
			}


			Element = {
				...Element,
				errorMessage,
				isValid: _.isEqual(errorMessage.length, 0),
				touched: _.isEqual(Element.id, element.formElement.id) ? true : Element.touched,
			};
		}

		return Element;
	}
	/**
	 * Find parent element
	 *
	 * @param {IFormElement} formElement.
	 * @param {IElementUpdate} element.
	 * @return {IForms[]} forms.
	 */
	public findParentElements = async (form: IForm, element: IFormElementUpdate): Promise<IForm> => {
		const Form: IForm = ({
			...form, groups:
				form.groups.map((group: IFormGroup) => ({
					...group,
					elements: group.elements.map((formElement: IFormElement) => ({
						...formElement
					}))
				}))
		});

		const Groups = Form.groups;
		for (let iFormGroup = 0; iFormGroup < Form.groups.length; iFormGroup++) {
			const Elements = Groups[iFormGroup].elements;
			for (let iFormElement = 0; iFormElement < Elements.length; iFormElement++) {
				const FormElement: IFormElement = Elements[iFormElement];
				if (!_.isEqual(FormElement.configuration.parentElements.length, 0)
					&& (_.isEqual(FormElement.type, ELEMENT_TYPES.SELECT_INPUT))) {
					Elements[iFormElement] = this.findParentElementsForSelect(form, FormElement,
						element);
				}
				else {
					Elements[iFormElement] = {
						...FormElement,
						touched: _.isEqual(FormElement.id, element.formElement.id) ? true : FormElement.touched,
					};
				}
			}
			Groups[iFormGroup].elements = Elements;
		}
		form.groups = Groups;
		return Form;
	}

	/**
	 * Find child elements
	 *
	 * @param {IFormElement} formElement.
	 * @param {IElementUpdate} element.
	 * @return {IForms[]} forms.
	 */
	public findChildElements = async (form: IForm, element: IFormElementUpdate)
		: Promise<IForm> => {
		const FormGroups: IFormGroup[] = await this.apiGetFormGroups(form, form.groups, element);

		return ({
			...form,
			groups: FormGroups
		});
	}

	/**
	 * Find form groups
	 *
	 * @param {IFormElement} formElement.
	 * @param {IElementUpdate} element.
	 * @return {IFormGroup[]} FormGroups.
	 */
	private apiGetFormGroups = async (form: IForm, formGroups: IFormGroup[], element: IFormElementUpdate):
		Promise<IFormGroup[]> => {
		const FormGroups: IFormGroup[] = [];
		for (const formGroup of formGroups) {
			const FormElementArray = [];
			for (const formElement of formGroup.elements) {
				const Element: IFormElement = await this.apiUpdateElement(form, formElement, element);
				FormElementArray.push(Element);
			}
			FormGroups.push({
				...formGroup,
				elements: FormElementArray
			});
		}
		return FormGroups;
	}

	private ifParentElementNotEmpty = async (form: IForm, element: IFormElement): Promise<any> => {

		const Element: IFormElement = { ...element };
		try {
			const APIData = await this.elementHelper.getAPIData(form, element);

			const DataSource = _.isArray(APIData) ? APIData : [];
			if (Element.configuration.allowAll) {
				DataSource.unshift(ALL_OPTION_OBJ);
			}

			Element.configuration.dataSource = DataSource;

			if (_.isEqual(Element.type, ELEMENT_TYPES.SELECT_INPUT)
				&& Element.configuration.readOnly) {

				Element.value = DataSource[0];
			}

			Element.apiError = "";
			return {
				Element,
				dataSource: DataSource
			};
		}
		catch (error: any) {
			Element.configuration.dataSource = [];
			Element.apiError = this.utilHelper.getErrorMessage(error);
			return {
				Element,
				dataSource: []
			};
		}
	}

	/**
	 * Find if the current element is a parent and call the API
	 *
	 * @param {IFormElement} formElement.
	 * @param {IElementUpdate} element.
	 * @return {IFormElement} Element.
	 */
	private apiUpdateElement = async (form: IForm, formElement: IFormElement, element: IFormElementUpdate)
		: Promise<IFormElement> => {
		let Element: IFormElement = { ...formElement };

		if (!_.isEqual(Element.configuration.parentElements.length, 0) && !_.isEqual(Element.configuration.parentElements.indexOf(element.formElement.id), -1)) {
			const ElementValue = this.utilHelper.isEmptyValue(element.value) ? null : element.value;
			let dataSource = [];
			let elementValue = null;
			const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(form, Element.configuration.parentElements);

			if (!_.isNull(ElementValue) && !this.utilHelper.isEmptyValue(Element.configuration.apiURL || "")
				&& !IsAnyParentEmpty) {
				const _element = await this.ifParentElementNotEmpty(form, Element);
				dataSource = _element.dataSource;
				if (_.isEqual(Element.type, ELEMENT_TYPES.SELECT_INPUT)
					&& Element.configuration.readOnly) {
					elementValue = _element.Element.value || {};
				}

				Element = {
					..._element.Element
				};
			}

			if (!_.isUndefined(formElement.configuration.selectValueIfEmpty)) {
				elementValue = _.isEmpty(elementValue) ? formElement.configuration.selectValueIfEmpty.value : elementValue;
			}

			let errorMessage = "";
			if (Element.validations.required) {
				errorMessage = this.validationHelper.validate(Element,
					null);
			}

			return {
				...Element,
				disabled: IsAnyParentEmpty || formElement.readOnly,
				value: elementValue,
				configuration: {
					...Element.configuration,
					dataSource
				},
				errorMessage,
				touched: false,
				isValid: _.isEqual(errorMessage.length, 0),
			};
		}
		return Element;
	}

}