import { createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import COLORS from "./colors";

// Site theme
const primaryColor = COLORS.BLUE;
const secondaryColor = COLORS.WHITE;

const fontSize = 14;
const fontFamily = "'Inter', sans-serif";

const MuiTheme = createTheme({
	palette: {
		primary: {
			main: primaryColor,
		},
		secondary: {
			main: secondaryColor
		},

		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.2,
	},
	typography: {
		fontSize,
		fontFamily,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 600,
	},
	overrides: {
		MuiTypography: {
			body1: {
				fontSize: 14,
				fontWeight: "normal" as const,
				lineHeight: 1.43
			}
		},
		MuiContainer: {
			root: {
				padding: "0 !important",
				maxWidth: "1600px !important",
				width: "auto",
				// minWidth: "1200px"
			}
		},
		MuiButton: {
			root: {
				fontSize,
				padding: "7px 16px",
				textTransform: "capitalize",
				boxShadow: "none !important",
				borderRadius: 2,
				height: 36,
				"&.Mui-disabled":{
					opacity:"0.4",
					color:"unset"
				}
			},
			contained:{
				"&.Mui-disabled":{
					opacity:"0.4",
					color:COLORS.SHAINCOLOR,
					backgroundColor: primaryColor,
					fontWeight:"normal"
				}
				
			}
			
		},
		MuiCircularProgress: {
			root: {
				position: "fixed",
				margin: "auto !important",
				top: 0,
				left: 0,
				bottom: 0,
				right: 0
			}
		},
		MuiLinearProgress:{
			root:{
				width:"100%",
				height:4
			},
			bar:{
				backgroundColor: `${COLORS.LIGHT_ORANGE} !important`
			},
			determinate:{
				backgroundColor: COLORS.PROGRESS_PENDING_BAR
			}
		},
		MuiSnackbar: {
			root: {
				top: "16px !important",
			}
		},
		MuiSnackbarContent: {
			root: {
				minWidth: "600px !important",
				minHeight: "56px",
				padding: 0,
				borderRadius: 2,
				boxShadow: "0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)",
			},
			message: {
				padding: 0,
				display: "inline-flex",
				alignItems: "center",
			},
			action: {
				marginRight: 0,
				paddingRight: 7
			}
		},
		MuiList: {
			padding: {
				paddingTop: 0,
				paddingBottom: 0
			}
		},
		MuiMenuItem: {
			root: {
				padding: 0,
				fontSize,
				position: "relative",
				zIndex: 999999,
				lineHeight: "normal",
				paddingBottom: 0,
				minHeight: "auto",
			},
			gutters: {
				padding: 5,
				paddingLeft: 5,
				paddingRight: 5,
				color: COLORS.BLACK
			}
		},
		MuiTooltip:{
			tooltip:{
				backgroundColor: "#3e464f",
				color: COLORS.WHITE,
				padding:8,
				fontSize:"0.75rem",
				borderRadius:2,
				boxShadow: `0px 4px 6px -1px rgba(0, 0, 0, 0.1),
                            0px 2px 4px -1px rgba(0, 0, 0, 0.06)`,
			},
			popper:{
				zIndex:99999999,
			}
		},
		MuiInputLabel: {
			shrink: {
				transform: "translate(0, 1.5px) scale(1)",
				color: COLORS.BLACK,
				fontSize
			}
		},
		MuiInputBase:{
			input:{
				fontSize:"0.875rem"
			},
			multiline:{
				padding:0
			}
		},
		MuiListItemIcon: {
			root: {
				marginRight: 8,
				minWidth: "auto"
			}
		},
		MuiListItem: {
			gutters: {
				paddingLeft: 10,
				paddingRight: 10,
			},
			button: {
				transition: "none",
				backgroundColor: COLORS.WHITE,
				color: "rgb(106, 115, 124)",
			}
		},
		MuiListItemText: {
			root: {
				padding: "0 8px"
			},
			inset: {
				paddingLeft: 0
			}
		},
		MuiPaper: {
			elevation4: {
				boxShadow: "none",
			},
			elevation1: {
				boxShadow: "0px 2px 1px -1px rgb(0 0 0 / 1%), 0px 1px 1px 0px rgb(0 0 0 / 1%), 0px 1px 3px 0px rgb(0 0 0 / 6%)",
			},
			elevation8: {
				boxShadow: "0px 10px 15px -3px rgb(0 0 0 / 10%), 0px 4px 6px -2px rgb(0 0 0 / 5%)",
			},
			rounded: {
				borderRadius: 2
			}
		},
		MuiTab: {
			root: {
				padding: "5px 0px 23px",
				fontWeight: 500,
				fontSize: 16,
			},
		},
		MuiFormControlLabel: {
			root: {
				marginLeft: "-7px",
			}
		},
		MuiDrawer: {
			paper: {
				boxShadow: `0px 10px 15px -3px rgba(0, 0, 0, 0.1),
                0px 4px 6px -2px rgba(0, 0, 0, 0.05) !important`,
			}
		},
		MuiBadge: {
			anchorOriginTopRightRectangle: {
				top: 4,
				right: 7,
				backgroundColor: "#e98734",
				border: "2px solid white",
				transform: "none",      
			},
			dot: {
				height: 12,
				minWidth: 12,
				borderRadius: 9
			}
		},
		MuiBackdrop: {
			root: {
				opacity: "50% !important",
				backgroundColor: "#d0d5da"
			}
		},
		MuiTableSortLabel:{
			icon:{
				fontSize:"1rem"
			}
		},
		MuiTablePagination: {
			input: {
				display: "none"
			},
			caption:{
				fontSize:"0.75rem",
				fontWeight:500
			},
			actions:{
				marginLeft:10,
				"& .MuiButtonBase-root:not(.Mui-disabled)":{
					color:"#6a737c"
				}
			}
			// caption: {
			//     display: "none"
			// }
		}
	}
});

export default MuiTheme;
