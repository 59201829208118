import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import classNames from "classnames";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
    onClick: any;
    tooltipText: string;
    disabled:any
};

// PlayButton Component
export const IconPlay = (props: props): JSX.Element => {
    const classes = useStyles();
    const { onClick, tooltipText,disabled} = props;

    return (
        <Tooltip title={tooltipText} placement="top">
        <IconButton
            size={"small"}
            className={classNames(classes.iconButtonMedium)}
            onClick={
                onClick
            }
            disabled={disabled}
        >
            <svg xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                className={classNames(disabled && classes.disabledDownloadIcon)}
                >
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z" fill="rgba(106,115,124,1)" />
            </svg>
        </IconButton>
        </Tooltip>
    );
};

export default IconPlay;
