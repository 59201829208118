import * as _ from "lodash";
import {
	Axios
} from "../../../../../../../api/service/Axios";
import { UI_ELEMENT_API_METHODS } from "../../../../../config";
import { IFormElement } from "../../../../interface/IFormElement";
import {
	IOption
} from "../../../../interface/IOption";
import { UtilHelper } from "../../../Utils";
import {
	FormElementHelper
} from "../../../Utils/Element";
import { MultiSelectHelper } from "./MultiSelect";
import { SingleSelectHelper } from "./SingleSelect";

// Select Element Helper 
export class SelectElement{
    private utilHelper: UtilHelper;
    private elementHelper:FormElementHelper;
	private singleSelect: SingleSelectHelper;
	private multiSelect: MultiSelectHelper;

    constructor(){
    	this.utilHelper = new UtilHelper();
    	this.elementHelper = new FormElementHelper();
		this.singleSelect = new SingleSelectHelper();
		this.multiSelect = new MultiSelectHelper();
    }

    /**
     * Validate select and generate select element
     *
     * @param {forms} IForm[].
     * @param {formElement} IFormElement.
     * @return {element} IFormElement.
     */
    generateSelect = async (formElement: IFormElement): Promise<IFormElement> =>{

    	let element = {...formElement};
    	// API Dropdown
    	if(!this.utilHelper.isEmptyValue(formElement.api_url)){
    		element = await this.buildAPIDropdownList(formElement);
    	}
    	else if(!this.utilHelper.isEmptyValue(formElement.list_names)
         && !this.utilHelper.isEmptyValue(formElement.list_values)){
    		element = this.buildDropdownList(formElement);
    	}
    	return element;
    }

    /**
     * Build dropdown API list
     *
     * @param {forms} IForm[].
     * @param {formElement} IFormElement.
     * @return {Element} IFormElement.
     */
    buildAPIDropdownList = async (formElement: IFormElement)
    :Promise<IFormElement> =>{

    	let Element = {...formElement};
    	const isDisabled = !_.isEqual(Element.parent_elements.length, 0);

    	let dataSource:IOption[] = [];

    	if(_.isEqual(Element.parent_elements.length, 0)){
    		try{
    			let Data:any;
    			if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.GET)){
    				Data = await Axios.get(formElement.api_url);
    			}
    			else if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.POST)){
    				Data = await Axios.post(formElement.api_url, {});
    			}
    			dataSource = !_.isArray(Data.data) ? [] : Data.data;
    			if(Element.allow_all && !_.isEqual(dataSource.length, 0)){
    				dataSource.unshift(UtilHelper.allOptionLblVal);
    			}
                
    			Element.apiError = "";
    		}
    		catch(error:any){
    			dataSource = [];
    			Element.apiError = this.utilHelper.getErrorMessage(error);
    		}
    	}

    	Element.dataSource = dataSource;

    	if(!_.isEqual(Element.parent_elements.length, 0)){
    		return {...Element};
    	}

		const ValueAPIURL = Element.api_value_url || "";

		if(!_.isEmpty(ValueAPIURL)){
			Element = formElement.is_multi ? await this.multiSelect.getMultiAPIDropdownValue(Element)
    		: await this.singleSelect.getSingleAPIDropdownValue(Element);
		}else{
			Element = formElement.is_multi ? this.multiSelect.getMultiDropdownValue(Element)
    		:this.singleSelect.getSingleDropdownValue(Element);
		}

    	if(Element.is_multi && !_.isNull(Element.selection_limit)
                                && !_.isNull(Element.value)){
    		Element = this.elementHelper.updateSelectionLimit(Element, Element.value);
    	}

    	return {
    		...Element,
    		disabled: isDisabled,
    		touched: !_.isNull(Element.value),
    		value: Element.value,
    	};
    }

    /**
     * Created dropdown list and return element
     *
     * @param {formElement} IFormElement.
     * @return IFormElement
     */
    buildDropdownList = (formElement: IFormElement) :
    IFormElement =>{
    	const ListNames = (formElement.list_names || "").split(",");
    	const ListValues = (formElement.list_values || "").split(",");

    	if(!_.isEqual(ListNames.length, ListValues.length)) return {
    		...formElement,
    		value: null,
    		dataSource: []
    	};

    	const DataSource:IOption[]= [];
    	for(let i=0;i<ListNames.length;i++){
    		DataSource.push({
    			label: (ListNames[i] || "").toString().trim(),
    			value: (ListValues[i] || "").toString().trim()
    		});
    	}

    	const isDisabled = !_.isEqual(formElement.parent_elements.length, 0) || _.isEqual(formElement.disabled, 1);
        
    	if(formElement.allow_all){
    		DataSource.unshift(UtilHelper.allOptionLblVal);
    	}

    	let Element = {
    		...formElement,
    		disabled: isDisabled,
    		dataSource: DataSource,
    	};

    	Element = Element.is_multi ? this.multiSelect.getMultiDropdownValue(Element)
    		:this.singleSelect.getSingleDropdownValue(Element);

    	const Value: any = Element.value;

    	if(Element.is_multi && !_.isNull(Element.selection_limit)
                                && !_.isNull(Value)){
    		Element = this.elementHelper.updateSelectionLimit(Element, Value);
    	}

    	return {
    		...Element,
    	};
    }
}
