import React, { Fragment } from "react";
import { Grid, FormControl, Input } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import { InputLabelTooltip } from "./Input";
import ElementTypes from "./ElementTypes";
import { CustomSwitch } from "./SelectionControls";
import ToggleButtons from "./ToggleButton";
import Checkbox from "./CheckboxControls/Checkbox";

// InputElement functional component
function InputElement(props: any): JSX.Element {
  let inputElement = null;

  const { classes, handleChange, fieldConfig } = props;

  const {
    value,
    visible,
    valid,
    touched,
    elementConfig,
    controlConfig,
    elementType,
    errorMessage,
  } = fieldConfig;

  switch (elementType) {
    case ElementTypes.SWITCH:
      inputElement = (
        <Fragment>
          <div style={controlConfig.customLabelControlStyle}>
            <div
              style={{
                opacity: elementConfig.disabled ? 0.5 : 1,
                width: "100%",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%" }}>
                <InputLabelTooltip
                  label={elementConfig.label}
                  tooltip={elementConfig.tooltip}
                  required={false}
                />
              </div>
              <div
                className={classes.inlineInput}
                style={controlConfig.customInputControlStyle}
              >
                <CustomSwitch
                  checked={value}
                  onChange={handleChange}
                  value={value}
                  disabled={elementConfig.disabled}
                />
              </div>
            </div>
          </div>
          <div className={classes.errorMessage}></div>
        </Fragment>
      );
      break;
    case ElementTypes.TOGGLE_BUTTON:
      inputElement = (
        <Fragment>
          <div style={controlConfig.customLabelControlStyle}>
            <InputLabelTooltip
              label={elementConfig.label || ""}
              tooltip={elementConfig.tooltip || ""}
              required={elementConfig.required}
            />
          </div>
          <div style={controlConfig.customInputControlStyle}>
            <ToggleButtons
              handleChange={handleChange}
              touched={touched}
              valid={valid}
              value={value}
              exclusive={true}
              {...elementConfig}
            />
          </div>
        </Fragment>
      );
      break;
    case ElementTypes.CHECKBOX:
      inputElement = (
        <Fragment>
          <div style={controlConfig.customLabelControlStyle}>
            <InputLabelTooltip
              label={elementConfig.label || ""}
              tooltip={elementConfig.tooltip || ""}
              required={elementConfig.required}
            />
            <div
              className={classes.inlineInput}
              style={controlConfig.customInputControlStyle}
            >
              <Checkbox
                handleChange={handleChange}
                value={value}
                tooltip={elementConfig.tooltip}
                valid={valid}
                touched={touched}
                {...elementConfig}
              />
            </div>
            <div className={classes.errorMessage}>{!valid && errorMessage}</div>
          </div>
        </Fragment>
      );
      break;

    default:
      inputElement = (
        <Fragment>
          <div className={classes.inlineInputContainer}>
            <div
              className={classes.inlineInputLabel}
              style={controlConfig.customLabelControlStyle}
            >
              <div>
                <InputLabelTooltip
                  label={elementConfig.label || ""}
                  tooltip={elementConfig.tooltip || ""}
                  required={elementConfig.required}
                />
              </div>
              <div>&nbsp;:</div>
            </div>
            <div
              className={classes.inlineInput}
              style={controlConfig.customInputControlStyle}
            >
              <Input
                error={!valid && touched}
                onChange={handleChange}
                value={value}
                fullWidth
                {...elementConfig}
              />
            </div>
          </div>
          <div className={classes.inlineInputContainer}>
            <div
              className={classes.inlineInputLabel}
              style={controlConfig.customLabelControlStyle}
            ></div>
            <div className={classes.inlineInput}>
              <div className={classes.errorMessage}>
                {!valid && touched && errorMessage}
              </div>
            </div>
          </div>
        </Fragment>
      );
  }

  const FormControlContainer = controlConfig.isFullWidth ? (
    <FormControl
      fullWidth
      className={classes.formControl}
      style={controlConfig.customInputContainerStyle}
    >
      {inputElement}
    </FormControl>
  ) : (
    <FormControl
      className={classes.formControl}
      style={controlConfig.customInputContainerStyle}
    >
      {inputElement}
    </FormControl>
  );

  if (!visible) return <div />;

  if (controlConfig.formControlGridStyle) {
    return (
      <Grid
        item
        lg={controlConfig.columns}
        md={controlConfig.columns}
        sm={12}
        xs={12}
        style={controlConfig.formControlGridStyle}
      >
        {FormControlContainer}
      </Grid>
    );
  }

  return (
    <Grid
      item
      lg={controlConfig.columns}
      md={controlConfig.columns}
      sm={12}
      xs={12}
      className={classes.formControlContainer}
    >
      {FormControlContainer}
    </Grid>
  );
}

InputElement.defaultProps = {
  errorMessage: "",
};

export default withStyles(styles)(InputElement);
