import React, { Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import { CircularProgress } from "@material-ui/core";
import style from "../style";
import { IFileUpload } from "../../Elements/Forms/interface/File/IFileUpload";
import {
  ErrorIcon,
} from "../../Elements/FormElements/UploadFile/Icons";
import { UIFileComponent } from "./FileComponent";
import { IFormElement } from "../../Elements/Forms/interface/IFormElement";
import ImageIcon from "../../Icons/ImageIcon";
import VideoIcon from "../../Icons/VideoIcon";
import IconFile from "../../Icons/Files";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  file: IFileUpload;
  onDelete: any;
  onRetry: any;
  onCancel: any;
  status: string;
  loading: boolean;
  formElement: IFormElement | null;
  onInputChange: any;
  showTextBox: boolean;
};

const isImage = (file: IFileUpload): boolean => {
  const fileType = file.type;
  return fileType.includes("image");
};

const isVideo = (file: IFileUpload): boolean => {
  const fileType = file.type;
  return fileType.includes("video");
};

// Linear progress loader
export const UILinearProgress = (props: props): JSX.Element => {
  const classes = useStyles();
  const { file, loading, status, formElement, onInputChange, showTextBox } = props;
  let FileIcon = <span />;

  if (_.isEqual(status, "error") && !loading) {
    FileIcon = ErrorIcon;

  } else if (_.isEqual(status, "success") && !loading) {
    const imageFile = isImage(props.file);
    const videoFile = isVideo(props.file);

    if (imageFile) {
      FileIcon = <ImageIcon />;
    }
    else if (videoFile) {
      FileIcon = <VideoIcon />;
    }
    else {
      FileIcon = <IconFile />;
    }

  }
  else if (_.isEqual(status, "uploading")) {
    FileIcon = <CircularProgress className={classes.fileLoader} />;
  }

  return (
    <Fragment>
      <UIFileComponent
        {...props}
        fileIcon={FileIcon}
        isError={_.isEqual(file.status, "error")}
        showRetryOption={_.isEqual(file.status, "error")}
        isPending={_.isEqual(file.status, "pending")}
        isUploading={_.isEqual(status, "uploading")}
        formElement={formElement}
        onInputChange={onInputChange}
        isRejectFile={false}
        showTextBox={showTextBox}
      />
    </Fragment>
  );
};
