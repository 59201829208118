import {
	getResource
} from "../../../api/services";
import {
	GET_TOOL_LIST_REQUEST,
	GET_TOOL_LIST_SUCCESS,
	GET_TOOL_LIST_FAILED,
	RESET_TOOL_LIST,
	UPDATE_TOOL_LIST
} from "../../types";
import {
	GET_TOOL_LIST
} from "../../../api/paths";
import * as _ from "lodash";
import { IToolList } from "../../reducers/interfaces/IToolList";

// Tool List request initiate
const getToolListRequest = (): any => ({ type: GET_TOOL_LIST_REQUEST });

// Tool List request successful
const getToolListSuccess = (data: any): any => ({ type: GET_TOOL_LIST_SUCCESS, payload: data });

// Tool List request failed
const getToolListFailed = (): any => ({ type: GET_TOOL_LIST_FAILED });

// Reset Tool List State
export const resetToolList = (): any => ({ type: RESET_TOOL_LIST });

// Tool List
export const getToolList = (ToolListParameters: IToolList) => (dispatch: any) => {
		dispatch(getToolListRequest());

		getResource(dispatch, GET_TOOL_LIST(ToolListParameters),
			(response: any) => {
				if (_.isEqual(ToolListParameters.page_number, 0)) {
					dispatch(resetToolList());
				}
				dispatch(getToolListSuccess(response.data));
			},
			() => dispatch(getToolListFailed())
		);
	};

//Update Tool list for Favorite

export const updateToolState = (ToolKey:any) : any=> {
        return {
			type:UPDATE_TOOL_LIST,
 			payload: ToolKey
 		};
 	};