import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import UITooltip from "../Tooltip";

type props = {
  text: string;
};

export const SingleLineEllipsisText = (props: props): JSX.Element => {
  const { text } = props;
  const [hoverStatus, setHover] = useState(false);
  const textElementRef: any = useRef();
  const compareSize = (): any => {
    if (_.isNull(textElementRef) || _.isUndefined(textElementRef.current)) {
      return;
    }
    const compare = _.gt(
      textElementRef.current.scrollWidth,
      textElementRef.current.clientWidth
    );
    setHover(compare);
  };

  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  return (
    <UITooltip title={text || ""} disableTooltip={!hoverStatus}>
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </div>
    </UITooltip>
  );
};
