import React from "react";
import style from "./style";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import UITooltip from "../../../../../../UI/Elements/FormElements/Child/Tooltip";
import commonStyle from "../../../../../../UI/Elements/FormElements/sharedStyle";
import { IFormElementConfig } from "../../../../Interfaces/Form/FormElement/IFormElementConfig";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";

import { IFormElementValidation } from "../../../../Interfaces/Form/FormElement/IFormElementValidation";
// Create styles

const utilStyles = makeStyles(() => createStyles(commonStyle()));
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
};

// UILabel Component
export const UILabel = (props: props): React.JSX.Element => {
  const { formElement } = props;
  
  const utilClasses = utilStyles();
  const classes = useStyles();
  
  const ElementConfiguration: IFormElementConfig = formElement.configuration;
  const ElementValidation: IFormElementValidation = formElement.validations;

  return (
    <label htmlFor={`${formElement.id}`} className={utilClasses.elementLabel}>

      {ElementConfiguration.title}

      <UITooltip title={ElementConfiguration.tooltip || ""} />

      {!ElementValidation.required && !ElementConfiguration.readOnly && (

        <span className={classes.optional}>Optional</span>
      )}
    </label>
  );
};
