import * as _ from "lodash";
import {
	Axios
} from "../../../../../../api/service/Axios";
import { IFormElement } from "../../../interface/IFormElement";
import {
	UtilHelper
} from "../../Utils";

// Chip Element
export class ChipElement{

    private utilHelper: UtilHelper;
    constructor(){
    	this.utilHelper = new UtilHelper();
    }

    /**
     * Generate Chip Element
     *
     * @param {forms} IForm[].
     * @param {formElement} IFormElement.
     * @return {element} IFormElement.
     */
      generateChip = async (formElement: IFormElement): Promise<IFormElement> =>{

      	const Element = {...formElement};
      	if(_.isEqual(Element.parent_elements.length, 0)
        && !_.isNull(Element.api_url)){
      		try{
      			let Data:any;
      			if(_.isEqual(formElement.form_element_methodid, 1)){
      				Data = await Axios.get(formElement.api_url);
      			}
      			else if(_.isEqual(formElement.form_element_methodid, 2)){
      				Data = await Axios.post(formElement.api_url, {});
      			}
      			Element.value = _.isArray(Data.data) ? Data.data : [];
      			Element.apiError = "";
      		}
      		catch(error:any){
      			Element.apiError = this.utilHelper.getErrorMessage(error);
      		}
      	}
      	return Element;
      }
}
