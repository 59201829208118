import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";
import * as _ from "lodash";
import { withErrorHandler, withSnackbar } from "../HOC";
import styles from "./style";
import { SiteHeader } from "./Header";
import { AppRoutes } from "./Routes"; 
import { idEmbedurl } from "../store/actions/Initial";

type props = {
  classes: any;
  getBundle: any;
};

function Layout(props: props): React.JSX.Element {
  const { classes } = props;
  const { authState } = useOktaAuth();
  const initial = useSelector((state: any) => state.initial);
  const dispatch = useDispatch();

  useEffect(() => {
    const embedFlag = localStorage.getItem("isEmbed");
    if (_.isNull(embedFlag) && (window.location.href.includes("embed=true") || window.location.href.includes("embedtool=true"))) {
      localStorage.setItem("isEmbed", "true");
      dispatch(idEmbedurl(window.location.href.includes("embed=true") || window.location.href.includes("embedtool=true")));
    } else if(!_.isNull(embedFlag)) {
      dispatch(idEmbedurl(true));
    }
  }, []);

  const isEmbed = localStorage.getItem("isEmbed");
  const UserInfo: any = initial.userInfo || null;
  const IsValidProfile =  !_.isNull(UserInfo) && !_.isEqual(UserInfo.isValidProfile, 0);
  const isValidProfilecheck =  initial.isExternalUser ? true : IsValidProfile;
  const ShowHeader = _.isNull(isEmbed)  ? authState && !initial.loading && isValidProfilecheck && authState.isAuthenticated : false;
  return (
      <div className={classes.root}>
        <CssBaseline />
        {ShowHeader && (
          <SiteHeader />
        )}

        <main className={!_.isNull(isEmbed) ? classes.embedContent : classes.content}>
          <AppRoutes />
        </main>
      </div>
    
  );
}

export default withStyles(styles)(withErrorHandler(withSnackbar(Layout)));
