import React, { Fragment, useEffect, useState, useRef } from "react";
import moment, { Moment } from "moment";
import * as _ from "lodash";
import ReactGA4 from "react-ga4";
import { useSelector, useDispatch } from "react-redux";

import { useOktaAuth, LoginCallback, SecureRoute } from "@okta/okta-react";

import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import {
  DashboardComponent,
  AdminComponent,
  HistoryComponent,
  ToolsPageComponent,
  MappingAdminComponent,
  ToolTypeComponent,
  MappingManagerComponent,
  MappingMapperComponent,
  MappingReviewComponent,
  UserProfileComponent,
  RequiredInfoComponent,
} from "./config";
import { CircularProgress } from "../../UI/Progress";
import LoginComponent from "../Login";
import { SessionTimeoutAlert } from "../SessionTimeout";
import { getInitialData } from "../../store/actions/Initial";
import { AccessComponent } from "../../UI/Access";
import { getEmailMessageForPortalError } from "../Utility";
import { TiktokCallback } from "./TiktokCallback";
import TiktokPoupHandler from "./TiktokPoupHandler";

export const AppRoutes = (): React.JSX.Element => {
  console.log("process.env.REACT_APP_ENV_NAME",process.env.REACT_APP_ENV_NAME);
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();
  const [currentUrl, setCurrentUrl] = useState("");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(0);
  const sessionTimer: any = useRef();
  const history = useHistory();
  const location = useLocation();

  const initial = useSelector((state: any) => state.initial);
  const extendUserSession = (): Promise<any> => {
    clearInterval(sessionTimer.current);
    return new Promise((resolve: any, reject: any) => {
      oktaAuth.token
        .getWithoutPrompt()
        .then((res: any) => {
          setShowDialog(false);
          oktaAuth.tokenManager.setTokens(res.tokens);
          oktaAuth.authStateManager.updateAuthState();
          startSessionTimer(res.tokens);
          resolve();
        })
        .catch((err: any) => {
          reject(err);
          console.log(err);
        });
    });
  };

  const getTokenExpiryTime = async (tokens: any): Promise<any> => {
    if (
      !authState?.isAuthenticated ||
      _.isEmpty(tokens) ||
      _.isUndefined(tokens.idToken)
    ) {
      return null;
    }
    const ExpiresAt: Moment = moment.unix(tokens.idToken.expiresAt);
    const CurrentTS: number = moment().unix();
    return ExpiresAt.diff(moment.unix(CurrentTS));
  };

  const validateToken = async (callback?: any): Promise<void> => {
    const tokens: any = await oktaAuth.tokenManager.getTokens();
    const TokenExpiryTime = await getTokenExpiryTime(tokens);

    if (_.isNull(TokenExpiryTime)) {
      return;
    }

    try {
      await extendUserSession();
    } catch (err: any) {
      console.log(err);
    }
    callback();
  };

  const startSessionTimer = (tokens: any): void => {
    sessionTimer.current = setInterval(async () => {
      const TokenExpiryTime = await getTokenExpiryTime(tokens);
      if (_.isNull(TokenExpiryTime)) {
        return;
      }
      const Seconds: number = moment.duration(TokenExpiryTime).asSeconds();

      if (_.lt(Seconds, 600)) {
        setShowDialog(true);
        clearInterval(sessionTimer.current);
      }
    }, 1000);
  };

  // Clear session internal and timeout
  const cleanupAcitvity = (): void => {
    clearInterval(sessionTimer.current);
    clearTimeout(timer);
  };

  useEffect(() => {
    if (authState?.isAuthenticated) {
      validateToken(() => {
        dispatch(getInitialData());
      });

      const timeout: any = setTimeout(async () => {
        clearInterval(sessionTimer.current);
        const tokens: any = await oktaAuth.tokenManager.getTokens();
        startSessionTimer(tokens);
      }, 5 * 60 * 1000);
      setTimer(timeout);
    } else {
      cleanupAcitvity();
    }

    return () => cleanupAcitvity();
  }, [authState?.isAuthenticated]);

    useEffect(() => {
    setCurrentUrl(window.location.href);
  }, []);

  useEffect(() => {
    const { pathname, search } = location;
    const isExtendpresentInURL = currentUrl.includes("?embed=true");
    const regex = /embed=true/g;
    const matches = search.match(regex);
    const count = matches ? matches.length : 0;

    if (isExtendpresentInURL && !_.gt(count, 0)) {
      const newSearch = search ? `${search}&embed=true` : "?embed=true";
      const newLocation = `${pathname}${newSearch}`;
      window.history.replaceState({}, "", newLocation);
    }
  }, [location]);



  useEffect(() => {
    if (authState?.isAuthenticated && !initial.loading && !initial.error) {
      const GA_MEASUREMENT_ID: any = process.env.REACT_APP_GA_MEASUREMENT_ID;

      ReactGA4.initialize(GA_MEASUREMENT_ID, {
        gaOptions: {
          userId: initial.userInfo.userId,
        },
      });
      history.listen((loca: any) => {
        ReactGA4.send({
          hitType: "pageview",
          page: loca.pathname + loca.search,
        });
      });
    }
  }, [initial.loading, authState?.isAuthenticated]);

  if (authState?.isAuthenticated && initial.loading) {
    return <CircularProgress />;
  }



  if (!initial.isAuthenticated && !initial.loading) {
    if (!initial.error) {
      return (
        <AccessComponent
          heading={"Unauthorized Access."}
          description={`You are not authorized to use Automations Portal, please contact the support at <a href="${getEmailMessageForPortalError(
            "Unauthorized Access.",
            " error"
          )}">gdt-automations-support@groupm.tech</a>.`}
        />
      );
    }
    return (
      <AccessComponent
        heading={"Server Error."}
        description={`Please refresh or try again later. If the error persists, please contact the support at <a href="${getEmailMessageForPortalError(
          "Server Error."
        )}">gdt-automations-support@groupm.tech</a>.`}
      />
    );
  }

  return (
    <Fragment>
      <Switch>
        <Route
          path="/login/callback"
          render={(props: any) => {
            return <LoginCallback {...props} />;
          }}
        />
        <Route path="/login" component={LoginComponent} />
        <Route path="/tt/callback" component={TiktokCallback} />
        <Route path="/tiktokhandler" component={TiktokPoupHandler} />
        <SecureRoute path="/profile" exact component={UserProfileComponent} />
        <SecureRoute path="/" exact component={ToolsPageComponent} />
        <SecureRoute path="/dashboard" exact component={DashboardComponent} />
        <SecureRoute path="/admin" exact component={AdminComponent} />
        <SecureRoute path="/required-info" exact component={RequiredInfoComponent} />
        <SecureRoute path="/tool" exact component={ToolTypeComponent} />
        <SecureRoute
          path="/mapping/admin"
          exact
          component={MappingAdminComponent}
        />
        <SecureRoute
          path="/mapping/manager"
          exact
          component={MappingManagerComponent}
        />
        <SecureRoute
          path="/mapping/mapper"
          exact
          component={MappingMapperComponent}
        />
        <SecureRoute
          path="/mapping/review"
          exact
          component={MappingReviewComponent}
        />
        <SecureRoute path={"/history"} exact component={HistoryComponent} />
        <Route
          render={() => {
            return <Redirect to={"/"} />;
          }}
        />
      </Switch>

      {showDialog && (
        <SessionTimeoutAlert
          onContinueSignedIn={() => {
            setShowDialog(false);
            extendUserSession();
          }}
          timeoutSeconds={300}
          onLogout={() => {
            setShowDialog(false);
            oktaAuth.signOut();
          }}
        />
      )}
    </Fragment>
  );
};
