import React, { Fragment } from "react";
import { Container, Grid } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "./style";
import { ITool } from "../interface/ITool";
import {
  ToolHeader,
  ToolInfo,
} from "../../../../components/Toolkit/Tool/Type/Layout";
import { AuthContent } from "../Auth/AuthContent";
const useStyles = makeStyles(() => createStyles(style()));

type props = {
  tool: ITool;
  authComponent?: any;
  handleBackButton: any;
};

export const DefaultForm = (props: props): JSX.Element => {
  const classes = useStyles();
  const { tool, authComponent, handleBackButton } = props;

  const ToolData = {
    data: { ...tool },
  };

  return (
    <Fragment>
      <ToolHeader handleBackButton={handleBackButton} signoutConfig={{is_signout_button: false}} {...ToolData} />
      <Container maxWidth="lg">
        <Grid container className={classes.toolContentWrapper}>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            className={classes.infoWrapper}
          >
            <div className={classes.toolContent}
            >
              {/* <div> */}
              {!_.isNull(authComponent) ? (
                <Fragment>
                  <div className={classes.authComponent}>
                      <div className={classes.authComponentDiv}>
                        {authComponent}
                      </div>
                    <div className={classes.authContentDiv}>
                      <AuthContent content={tool.auth_login_content} />
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div>Tool Form configuration is missing.</div>
              )}

            </div>

          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            xl={4}
            justifyContent="center"
            className={classes.infoWrapper}
          >
            <ToolInfo {...ToolData} />
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

DefaultForm.defaultProps = {
  authComponent: null,
};
