import React, { useEffect, useRef, useState } from "react";
import ShowMoreText from "react-show-more-text";
import * as _ from "lodash";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import style from "./style";
import classNames from "classnames";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  data: any;
};

//Show or Hide items
export const ShowMoreComponent = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const cellRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    const ResizeEL = (): void => {
      const CellElement = cellRef.current;
      const scrollHeight = CellElement?.scrollHeight;
      setExpanded(_.lt(42, scrollHeight));
    };
    window.addEventListener("resize", ResizeEL);
    ResizeEL();

    return () => {
      window.removeEventListener("resize", ResizeEL);
    };
  }, []);

  return (
    <div ref={cellRef}>
      <ShowMoreText
        lines={2}
        more="Show"
        less="Hide"
        className={classes.content}
        anchorClass={classNames(classes.link)}
        expanded={expanded}
        truncatedEndingComponent={"..."}
      >
        {props.data}
      </ShowMoreText>
    </div>
  );
};
