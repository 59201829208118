import React, { Fragment } from "react";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { UILabel } from "../Child/Label";
import { MiddleTextEllipsis } from "../Text/MiddleEllipsis";

// Props type
type props = {
  formElement: IFormElement;
};

// UIInput Component
export const UITextEllipses = (props: props): React.JSX.Element => {
  const { formElement } = props;

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <MiddleTextEllipsis title={formElement.value} showTooltip={true} />
    </Fragment>
  );
};
