const TEXT_INPUT = 1;
const DATETIME_INPUT = 2; 
const CHECKBOX_INPUT = 3;
const RADIO_INPUT = 4; 
const DATERANGE_INPUT = 5; 
const SWITCH_INPUT = 6; 
const SELECT_INPUT = 7; 
const FILE_INPUT = 8;
const CHIP = 9;
const THUMBNAIL = 10;
const IMAGE_TEXT_FIELD = 11;
const DOWNLOAD = 12;
const GRID = 14;
const ADD_BUTTON = 15;

export const UI_ELEMENT_TYPES = {
	TEXT_INPUT,
	CHECKBOX_INPUT,
	RADIO_INPUT,
	DATERANGE_INPUT,
	SWITCH_INPUT,
	SELECT_INPUT,
	DATETIME_INPUT,
	FILE_INPUT,
	CHIP,
	THUMBNAIL,
	IMAGE_TEXT_FIELD,
	DOWNLOAD,
	GRID,
	ADD_BUTTON
};

export const UI_ELEMENT_AUTH_TYPES = {
	GOOGLE: "Google",
	FACEBOOK: "Facebook",
	TIKTOK: "Tiktok"
};

export const UI_ELEMENT_API_METHODS = {
	GET: 1,
	POST: 2
};

export const UI_AUTH_HEADER_NAMES = {
	GOOGLE: "x-portal-google-auth-refresh-token",
	FACEBOOK: "x-portal-facebook-auth-access-token",
	TIKTOK: "x-portal-tiktok-access-token"
};

export const UI_DYNAMIC_CONFIG_ELEMENT_TYPES = {
	METRICS:"metrics",
	DATA_GRID:"data_grid"
};

export const UI_BTN_ACTIONS = {
	ADD:"add",
	EDIT:"edit", 
	DELETE: "delete", 
	UPLOAD: "upload"
};

export const UI_DYNAMIC_ELE_TYPES = {
	ELEMENT: "element",
	SORT: "sort"
};

export const UI_GRID_CELL_EDITTOR_TYPES = {
	DROPDOWN: "dropdown",
	TEXT: "text"
};

export const UI_GRID_CELL_EDITOR_COMPONENTS = {
	DROPDOWN: "agSelectCellEditor"
};

export const ALL_OPTION_LABEL = "All";
export const ALL_OPTION_VALUE = "all";

export const ALL_OPTION_OBJ = {
	label:ALL_OPTION_LABEL,
	value:ALL_OPTION_VALUE
};

export const ADD_FORM_ELEMENTS = [
	"create_campaign",
];

export const MULTI_FORM_VERSION = 0.1;