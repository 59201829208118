import COLORS from "../Theme/colors";

const style: any = (): any => {
	return {
		loaderContainer: {
			position: "fixed" as const,
			left: "0px",
			top: "0px",
			width: "100%",
			height: "100%",
			"z-index": 99999999999999,
			backgroundColor: "rgba(0, 0, 0, 0.5)"
		},
		uploadText:{
			fontSize:"0.875rem",
			color: COLORS.DARK_GRAY,
			marginTop:10,
			width:"100%"
		},
		uploadBucketfileContainer:{
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			flex:1,
			marginTop:8
		},
		fileInfo:{
			display:"flex",
			alignItems:"center",
			marginBottom:12,
			marginTop:12,
			whiteSpace:"nowrap",
			flex:1,
		},
		uploadBucketFileInfo:{
			display:"flex",
			alignItems:"center",
			whiteSpace:"nowrap",
			flex: "0 0 14rem"
		},
		fileIcon:{
			display:"flex",
			alignItems:"center",
			width:20,
			marginRight:"7px"
		},
		fileName:{
			fontSize:"0.875rem",
			color: COLORS.DARK_GRAY,
			fontWeight:500
		},
		bucketUploadfileName:{
			fontSize:"0.875rem",
			color: COLORS.DARK_GRAY,
		},
		fileSize:{
			fontSize:"0.75rem",
			color: COLORS.GRAY,
			marginLeft:8,
			display:"inline-block"
		},
		fileActionContainer:{
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			marginLeft:8,
		},
		fileAction:{
			fontSize:"0.75rem",
			color: COLORS.DARK_BLUE,
			cursor:"pointer",
			marginLeft: 20
		},

		fileLoader:{
			position: "relative",
			width: "13px !important",
			height:"13px !important",
			color: COLORS.GRAY
		  },
		  fileLoaderContainer:{
			paddingRight: 7
		  },
		  fileUploadText:{
			  margin:"0 10px",
			  width:"100%"
		  },
		  fileIconStyle:{
			marginRight: "10px" 
		  }
		  
	};
};

export default style;
