
import _ from "lodash";
import { ELEMENT_TYPES } from "../../../../configuration";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../../../Interfaces/Form/IForm";
import { IFormGroup } from "../../../../Interfaces/Form/IFormGroup";

export class SubmitFormHelper {

  public getSubmitForm(form: IForm): IForm {
    const SubmitData: any = {};

	form.groups.forEach((formGroup: IFormGroup)=>{

		formGroup.elements.forEach((formElement: IFormElement)=>{
			SubmitData[formElement.id] = this.getElementValue(formElement);
		});
	});

    return SubmitData;
  }

  public getElementValue = (
    formElement: IFormElement
  ): IFormElement => {
    let elementValue = formElement.value;

    switch (formElement.type) {
      case ELEMENT_TYPES.SELECT_INPUT:{
        elementValue =  this.getSelectValue(formElement);
        break;
	}
	case ELEMENT_TYPES.SWITCH:
		elementValue = formElement.value ? 1 : 0;
		break;
	}
    return elementValue;
  };

  private getSelectValue = (formElement: IFormElement): any =>{
	
	if(_.isNull(formElement.value)){
		return null;
	}

	if(!formElement.configuration.isMulti){
		return formElement.value.value;
	}
	return formElement.value;
  }
}
