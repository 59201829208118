import { ELEMENT_TYPES, DAY_OF_MONTH, TIME } from "./configuration";
import { IFormElement } from "./Interfaces/Form/FormElement/IFormElement";
import { IForm } from "./Interfaces/Form/IForm";

export const getScheduleFormConfig = (formData: any): IForm => {

    const JobId: IFormElement = {
        id: "jobId",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.DISPLAY_TEXT,
        
        errorMessage: "",
        value: formData.JobName || null,
        visible: true,
        configuration: {
            dataSource: [],
            dataSourceKey: "jobId",
            isVisible: true,
            isMulti: false,
            multiRows: 2,
            defaultValue: "",
            title: "Job ID",
            allowAll: true,
            apiURL: "",
            parentElements: [],
            dependsOn: [],
            disabledBasedOn: [],
            dependentValue: "",
            disabledBasedOnValue: false,
            placeholder: "",
        },
        validations: {
            isNumber: false,
            required: true,
            isEmail: false,
        },
        apiError: "",
        highlightRemovedOptionError: "",
        readOnly: false,
        highlightRemovedOption: false
    };

    const JobName: IFormElement = {
        id: "job",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.TEXT_INPUT,
        errorMessage: "",
        visible: true,
        value: formData.JobName || null,
        validations: {
            required: true,
            isEmail: false,
            isNumber: false,
        },
        configuration: {
            dataSourceKey: "job",
            dataSource: [],
            isVisible: true,
            multiRows: 2,
            isMulti: false,
            placeholder: "",
            defaultValue: "",
            title: "Job Name",
            disabledBasedOn: [],
            disabledBasedOnValue: false,
            allowAll: true,
            parentElements: [],
            dependsOn: [],
            apiURL: "",
            dependentValue: "",
            gridColumns : 12
        },
        readOnly: false,
        readOnlyOptions: {
            subtitle: "",
            title: "",
            keys: {
                subtitle: "jobId",
                title: "job",
            }
        },
        apiError: "",
        highlightRemovedOption: false,
        highlightRemovedOptionError: "",
    };
    const IsActive: IFormElement = {
        id: "active",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.SWITCH,
        validations: {
            isEmail: false,
            isNumber: false,
            required: true
        },
        visible: true,
        errorMessage: "",
        value: formData.active || true,
        configuration: {
            dataSource: [],
            isMulti: false,
            isVisible: true,
            multiRows: 2,
            placeholder: "",
            title: "Active",
            defaultValue: true,
            dataSourceKey: "",
            allowAll: false,
            parentElements: [],
            dependsOn: [],
            dependentValue: "",
            disabledBasedOn: [],
            disabledBasedOnValue: "",
            gridColumns : 12
        },
        readOnly: false,
        apiError: "",
        highlightRemovedOptionError: "",
        highlightRemovedOption: false
    };
    const Frequency: IFormElement = {
        id: "type",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.TOGGLE_INPUT,
        validations: {
            isEmail: false,
            isNumber: false,
            required: true
        },
        errorMessage: "",
        visible: true,
        value: formData.type || null,
        configuration: {
            dataSourceKey: "",
            dataSource: ["Daily", "Weekly", "Monthly"],
            isMulti: false,
            isVisible: true,
            multiRows: 2,
            placeholder: "",
            title: "Frequency",
            defaultValue: "Daily",
            allowAll: false,
            parentElements: [],
            isCreatableSelect: true,
            dependsOn: [],
            dependentValue: "",
            disabledBasedOn: ["active"],
            disabledBasedOnValue: false,
            gridColumns : 12
        },
        readOnly: false,
        apiError: "",
        highlightRemovedOptionError: "",
        highlightRemovedOption: false
    };
    const Weekly: IFormElement = {
        id: "schedule_week_day",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.SELECT_INPUT,
        validations: {
            isEmail: false,
            isNumber: false,
            required: true
        },
        errorMessage: "",
        visible: false,
        value: formData.schedule_week_day || null,
        configuration: {
            dataSourceKey: "",
            dataSource: [
                { value: "Monday", label: "Monday" },
                { value: "Tuesday", label: "Tuesday" },
                { value: "Wednesday", label: "Wednesday" },
                { value: "Thursday", label: "Thursday" },
                { value: "Friday", label: "Friday" },
                { value: "Saturday", label: "Saturday" },
                { value: "Sunday", label: "Sunday" }
            ],
            isMulti: false,
            isVisible: true,
            multiRows: 2,
            placeholder: "Select",
            title: "Weekday",
            defaultValue: "",
            allowAll: true,
            parentElements: [],
            dependsOn: ["type"],
            dependentValue: "Weekly",
            disabledBasedOn: ["active"],
            disabledBasedOnValue: false,
            gridColumns : 6
        },
        readOnly: false,
        apiError: "",
        highlightRemovedOptionError: "",
        highlightRemovedOption: false
    };
    const Day: IFormElement = {
        id: "schedule_month",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.SELECT_INPUT,
        validations: {
            isEmail: false,
            isNumber: false,
            required: true
        },
        errorMessage: "",
        visible: false,
        value: formData.schedule_month || null,
        configuration: {
            dataSourceKey: "",
            dataSource: DAY_OF_MONTH(),
            isMulti: false,
            isVisible: false,
            multiRows: 2,
            placeholder: "Select",
            title: "Day Of Month",
            defaultValue: "Daily",
            allowAll: true,
            parentElements: [],
            dependsOn: ["type"],
            dependentValue: "Monthly",
            disabledBasedOn: ["active"],
            disabledBasedOnValue: false,
            gridColumns : 6
        },
        readOnly: false,
        apiError: "",
        highlightRemovedOptionError: "",
        highlightRemovedOption: false
    };

    const Time: IFormElement = {
        id: "time",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.SELECT_INPUT,
        validations: {
            isEmail: false,
            isNumber: false,
            required: false
        },
        errorMessage: "",
        visible: true,
        value: formData.time || null,
        configuration: {
            dataSourceKey: "",
            dataSource: TIME(),
            isMulti: false,
            isVisible: true,
            multiRows: 1,
            placeholder: "Select",
            title: "Time (UTC)",
            defaultValue: "",
            allowAll: true,
            parentElements: [],
            dependsOn: [],
            dependentValue: "",
            disabledBasedOn: ["active"],
            disabledBasedOnValue: false,
            gridColumns : 6
        },
        readOnly: false,
        apiError: "",
        highlightRemovedOptionError: "",
        highlightRemovedOption: false,
    };

    return {
        id: "scheduleForm",
        title: "",
        isValid: false,
        isVisible: true,
        groups: [{
            id: "scheduleForm-group1",
            elements: [JobId, JobName, IsActive, Frequency, Weekly, Day, Time],
            isVisible: true,
            title: ""
        }]
    };
};


