const style: any = (): any => ({
   
    note:{
        minHeight: "1.25rem",
        fontSize:"0.75rem",
        marginTop:"0.25rem",
        "word-break":"break-all",
        color:"#6a737c",
        "font-weight":"normal",
        width:"100%",
        "& ul, ol":{
            marginTop: "0.25rem",
            paddingLeft: "1.5rem",
        },
        "& a":{
            textDecoration:"none",
            color:"#005fbe",
        }
    },
	noteErrorMargin:{
		marginBottom:"1.5rem"
	},
    error:{
        minHeight: "1.25rem",
        color: "#b82533",
        marginTop:"0.25rem",
        width:"100%",
        fontSize: "0.75rem",
        "font-weight":"normal",
        "word-break":"break-all",
    },
});

export default style;