import React from "react";
import { Button, Grid, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import * as _ from "lodash";
import Select, { components } from "react-select";
import classNames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import formSelectionStyle from "./style";
import { ENUMS } from "../../ElementTypes";
import commonStyle from "../../../../../UI/Elements/FormElements/sharedStyle";
import { CustomStyles } from "../SelectInput/style";

// Create styles
const useStyles = makeStyles(() => createStyles(formSelectionStyle()));
const utilStyles = makeStyles(() => createStyles(commonStyle()));

// Props type
type props = {
  onToolOptionSelection: any;
  disableExistingToolOption: boolean;
  disableNewToolOption: boolean;
  toolSelectionType: string;
  dataSource: any[];
  toolName: any;
  onToolChange: any;
};

export const UIFormSelection = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const utilClasses = utilStyles();
  const bundle = useSelector((state: any) => state.initial.bundle);

  const {
    disableExistingToolOption,
    disableNewToolOption,
    onToolOptionSelection,
    toolSelectionType,
    dataSource,
    toolName,
    onToolChange,
  } = props;

  const IS_NEW_TOOL_SELECTION = _.isEqual(
    ENUMS.TOOL_SELECTION_TYPES.NEW,
    toolSelectionType
  );
  const IS_EXISTING_TOOL_SELECTION = _.isEqual(
    ENUMS.TOOL_SELECTION_TYPES.EXISTING,
    toolSelectionType
  );

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item xs={12} xl={12} md={12} sm={12}>
        <label className={utilClasses.elementLabel}>Configuration</label>
      </Grid>
      <div className={classes.container}>
        <Button
          disabled={disableExistingToolOption}
          variant={IS_EXISTING_TOOL_SELECTION ? "outlined" : "text"}
          size="medium"
          color={IS_EXISTING_TOOL_SELECTION ? "primary" : "default"}
          className={classNames(classes.button, {
            [classes.unSelectedOption]: !IS_EXISTING_TOOL_SELECTION,
          })}
          onClick={() =>
            onToolOptionSelection(ENUMS.TOOL_SELECTION_TYPES.EXISTING)
          }
        >
          Existing Tools
        </Button>
        <Button
          disabled={disableNewToolOption}
          variant={IS_NEW_TOOL_SELECTION ? "outlined" : "text"}
          size="medium"
          color={IS_NEW_TOOL_SELECTION ? "primary" : "default"}
          className={classNames(classes.button, {
            [classes.unSelectedOption]: !IS_NEW_TOOL_SELECTION,
          })}
          onClick={() => onToolOptionSelection(ENUMS.TOOL_SELECTION_TYPES.NEW)}
        >
          {bundle.NEW_TOOL_BUTTON_TITLE}
        </Button>
      </div>
      {IS_EXISTING_TOOL_SELECTION && (
        <Grid container item xl={12} lg={12} md={12} xs={12}>
          <Grid container item md={6} xs={12} xl={4} lg={4}>
            <Box
              style={{
                width: "100%",
              }}
              sx={{
                mr: 2,
                mt: 2,
              }}
            >
              <Select
                value={toolName}
                components={{
                  DropdownIndicator: (data: any) => {
                    return (
                      <components.DropdownIndicator
                        {...data}
                        className="caret-select"
                      />
                    );
                  },
                }}
                styles={CustomStyles(false, false, false)}
                onChange={(selectedOption: any) => {
                  onToolChange(selectedOption);
                }}
                options={dataSource}
                placeholder={"Select a tool"}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
