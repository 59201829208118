import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ListItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import style from "./style";
import { LogService } from "../../api/service/Log";
import { GAEvent } from "../../helpers/GA";
import {
  GOOGLE_ANALYSTICS_ACTIONS,
  GOOGLE_ANALYSTICS_CATEGORIES,
} from "../../helpers/GA/GoogleAnalysticsUtils";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));
const NavListItems: any = (props: any) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <ListItem
        button
        selected={_.isEqual(props.selectedIndex, props.indexValue)}
        onClick={(event) => {
          LogService({
            action: "click",
            name: props.name,
          });
          GAEvent(
            GOOGLE_ANALYSTICS_CATEGORIES.NAVIGATION,
            GOOGLE_ANALYSTICS_ACTIONS.LINK_CLICKED,
            props.name
          );
          props.handleListItemClick(event, props.indexValue);
          history.push(props.history);
        }}
        className={classes.listItems}
      >
        {props.navName}
      </ListItem>
    </>
  );
};

export default NavListItems;
