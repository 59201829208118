import * as _ from "lodash";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UtilHelper } from "../../Forms/helper/Utils";
import { IOption } from "../../Forms/interface/IOption";


export const isCloseMenuOnSelect = (formElement: IFormElement): boolean =>{
	const Value = _.isNull(formElement.value) ? [] : formElement.value;
	const DataSource = formElement.dataSource || [];
	let closeMenuOnSelect = true;
	if (formElement.is_multi) {
		if (
		  !_.isEqual(DataSource.length, Value.length + 1) ||
		  formElement.allow_all
		) {
		  closeMenuOnSelect = false;
		}
	
		if (
		  !_.isNull(formElement.selection_limit) &&
		  !_.isNull(Value) &&
		  _.isArray(Value)
		) {
		  const IsAll = Value.filter((option: IOption) =>
			_.isEqual(option.value, UtilHelper.allOptionValue)
		  );
	
		  if (formElement.allow_all) {
			const ExceptAll = formElement.dataSource.filter(
			  (option: IOption) =>
				!_.isEqual(option.value, UtilHelper.allOptionValue)
			);
	
			closeMenuOnSelect =
			  !_.isEqual(ExceptAll.length, formElement.selection_limit) &&
			  _.isEqual(Value.length + 1, formElement.selection_limit);
		  } else {
			closeMenuOnSelect =
			  _.isEqual(Value.length + 1, formElement.selection_limit) &&
			  _.isEqual(IsAll.length, 0);
		  }
		}
	  }
	  return closeMenuOnSelect;
};

export const createOption = (label: string): any => ({
	label: label,
	value: Math.floor(Math.random() * 900000) -9999999,
	type:"New Option"
  });

export const getErrorMessage = (formElement: IFormElement): string =>{
	let errorMessage = "";
	if (formElement.apiError) {
		errorMessage = formElement.apiError;
	  } else {
		errorMessage = formElement.touched ? formElement.error : "";
	  }
	if (formElement.disabled || formElement.read_only) {
		errorMessage = "";
	  } else {
		const HighlightOptionError = formElement.highlight_option_error;
		errorMessage = !_.isEqual(HighlightOptionError.length, 0)
		  ? HighlightOptionError
		  : errorMessage;
	  }
	  return errorMessage;
};