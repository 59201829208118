import React from "react";
import * as _ from "lodash";
import { IFormElement } from "../../../../../../../UI/Elements/Forms/interface/IFormElement";
import { IOption } from "../../../../../../../UI/Elements/Forms/interface/IOption";
import * as ElementDS from "../../../../../../../UI/ElementDS";
import { Chip } from "./Chip/Chip";
import PreviewGrid from "../../../../../../../UI/Elements/FormElements/DataGrid/Preview";
import { getPasswordInput } from "./Utility";
import { UIFileTextInputView } from "./FileInput";
import { UIImageTextReadOnlyPlaceholder } from "../../../../../../../UI/Elements/FormElements/ImageText/ReadOnlyInput/Placeholder";

export const getDynamicElements = (
  formElement: IFormElement
): React.JSX.Element[] => {
  const DynamicElements = (formElement.dynamic_fields_values || []).filter(
    (option: IOption) => !_.isNull(option.value)
  );
  const Values = [];
  for (let i = 0; i < DynamicElements.length; i++) {
    const Label = _.isEqual(i, 0) ? "Order by" : "...Then by";
    const Value = DynamicElements[i].value.label;

    const sortByValue = ElementDS.getOrderLabel(DynamicElements[i].sortby);

    Values.push(
      <div
        key={i}
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginRight: 20,
            minWidth: 65,
          }}
        >
          {Label}
        </div>
        <div
          style={{
            width: 100,
            fontWeight: 600,
          }}
        >
          {Value}
        </div>
        <div>{sortByValue}</div>
      </div>
    );
  }
  return Values;
};

export const getSelectInput = (
  formElement: IFormElement,
  classes: any,
  value: any
): any => {
  if (!_.isNull(formElement.dynamic_configuration)) {
    return getDynamicElements(formElement);
  }
  if (formElement.is_multi) {
    return getChips(classes, formElement.value);
  }
  return value;
};

export const getDefaultElements = (
  formElement: IFormElement,
  value: any
): any => {
  if (!_.isNull(formElement.dynamic_configuration)) {
    return getDynamicElements(formElement);
  } else if (formElement.is_password) {
    return getPasswordInput(value);
  }
  return value;
};

export const getChips = (classes: any, value: any): React.JSX.Element => {
  const ChipData = _.sortBy(
    (_.isArray(value) ? value : []).map((option: IOption) => {
      return (option.label || "").toString().trim();
    })
  );

  return (
    <div className={classes.chipContainer}>
      {ChipData.map((chip: string, i: number) => (
        <Chip  key={i} chip={chip}></Chip>
      ))}
    </div>
  );
};

export const getFileInput = (formElement: IFormElement, value: any): any => {
  if (formElement.load_data_to_grid) {
    return !_.isEqual(formElement.dynamic_fields_values.length, 0) ? (
      <PreviewGrid formElement={formElement} />
    ) : (
      <span />
    );
  } else if (!_.isNull(value) && _.isArray(value)) {
    if (!_.isNull(formElement.customConfiguration)) {
      return <UIFileTextInputView formElement={formElement} value={value} />;
    } else {
      return value.map((file: File) => file.name).join(", ");
    }
  }
  return value;
};

export const getCheckBoxInput = (classes: any, value: any): React.JSX.Element => {
  if (_.isEmpty(value)) {
    return value;
  }
  const CheckboxData = value.split(",");
  return (
    <div className={classes.chipContainer}>
      {CheckboxData.map((chip: string, i: number) => (
        <Chip key={i} chip={chip}></Chip>
      ))}
    </div>
  );
};

export const getImageText = (formElement: IFormElement): React.JSX.Element => {
  return <UIImageTextReadOnlyPlaceholder formElement={formElement} />;
};
