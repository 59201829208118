import * as _ from "lodash";
import ElementTypes from "../../../UI/ElementTypes";
import FormHelper from "../../../helpers/FormHelper";
import { UserPermissionHelper } from "../../../Layout/Routes/Permissions/Helper";


// Filter Configuration
const FormConfig = (bundle: any = {}, state: any = {}, dropdownData: any = {}, flag = false, userInfo: any = {}): any => {
	const FORM_HELPER = new FormHelper();
    const userPermissionHelper = new UserPermissionHelper();

	return {
		tooltype_id: {
			elementType: ElementTypes.TOGGLE_BUTTON,
			elementConfig: {
				id: "tooltype_id",
				required: false,
				label: bundle.FILTER_TYPE_TITLE,
				tooltip: bundle.FILTER_TYPE_TOOLTIP,
				disabled: false,
				data: dropdownData.type
			},
			controlConfig: {
				isFullWidth: true,
				columns: 12,
				customLabelControlStyle: {
					paddingBottom: 8
				}
			},
			value: state.tooltype_id,
			errorMessage: "",
			touched: false,
			validation: {},
			visible: true,
			valid: true
		},
		platform_id: {
			elementType: ElementTypes.CHECKBOX,
			elementConfig: {
				required: false,
				id: "platform_id",
				label: bundle.FILTER_PLATFORM_TITLE,
				data: flag ? FORM_HELPER.getUpdatedCheckboxValue(FORM_HELPER.getCheckedValue(dropdownData.platforms, state.platform_id), null) : dropdownData.platforms,
				tooltip: bundle.FILTER_PLATFORM_TOOLTIP,
				disabled: false
			},
			controlConfig: {
				isFullWidth: true,
				columns: 12,
				customLabelControlStyle: {
					marginTop: 24,
				},
				customInputControlStyle: {
					paddingTop: 8
				}
			},
			value: [],
			touched: true,
			validation: {},
			errorMessage: "",
			visible: true,
			valid: false,
		},
		include_favorite: {
			elementType: ElementTypes.SWITCH,
			elementConfig: {
				required: false,
				id: "include_favorite",
				label: bundle.FILTER_FAVORITE_TITLE,
				tooltip: "",
				disabled: false
			},
			controlConfig: {
				isFullWidth: true,
				columns: 12,
				customInputControlStyle: {
					display: "contents"
				},
				customLabelControlStyle: {
					marginTop: 5
				}
			},
			value: _.isEqual(state.include_favorite, 0) ? false : true,
			touched: false,
			validation: {},
			visible: true,
			valid: true,
		},
		toollabel_id: {
			elementType: ElementTypes.SWITCH,
			elementConfig: {
				required: false,
				id: "toollabel_id",
				label: bundle.FILTER_LABEL_TITLE,
				tooltip: "",
				disabled: false
			},
			controlConfig: {
				isFullWidth: true,
				columns: 12,
				customInputControlStyle: {
					display: "contents"
				},
				customLabelControlStyle: {
					marginTop: 5
				}
			},
			value: _.isEqual(state.toollabel_id, 0) ? 0 : 2,
			touched: false,
			validation: {},
			visible: true,
			valid: true,
		},
		include_beta: {
			elementType: ElementTypes.SWITCH,
			elementConfig: {
				required: false,
				id: "include_beta",
				label: bundle.FILTER_STATUS_TITLE,
				tooltip: bundle.FILTER_STATUS_TOOLTIP,
				disabled: userPermissionHelper.isOnlyRegularUser(userInfo)
			},
			controlConfig: {
				isFullWidth: true,
				columns: 12,
				customInputControlStyle: {
					display: "contents"
				},
				customLabelControlStyle: {
					marginTop: 5
				}
			},
			value: !(_.isEqual(state.include_beta, 0) || userPermissionHelper.isOnlyRegularUser(userInfo)),
			touched: false,
			validation: {},
			visible: true,
			valid: true,
		},
	};

};

export default FormConfig;