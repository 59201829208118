import * as _ from "lodash";
import { IOption } from "../../../../../UI/Elements/Forms/interface/IOption";
import { ALL_OPTION_VALUE } from "../../../configuration";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";


export const isCloseMenuOnSelect = (formElement: IFormElement): boolean =>{
	const Value = _.isNull(formElement.value) ? [] : formElement.value;
	const {
		isMulti,
		dataSource,
		allowAll,
		selectionLimit
	} = formElement.configuration;


	const DataSource = dataSource || [];
	let closeMenuOnSelect = true;
	
	if (isMulti) {
		if (
		  !_.isEqual(DataSource.length, Value.length + 1) ||
		  allowAll
		) {
		  closeMenuOnSelect = false;
		}
	
		if (
		  !_.isUndefined(selectionLimit) &&
		  !_.isNull(Value) &&
		  _.isArray(Value)
		) {
		  const IsAll = Value.filter((option: IOption) =>
			_.isEqual(option.value, ALL_OPTION_VALUE)
		  );
	
		  if (allowAll) {
			const ExceptAll = DataSource.filter(
			  (option: IOption) =>
				!_.isEqual(option.value, ALL_OPTION_VALUE)
			);
	
			closeMenuOnSelect =
			  !_.isEqual(ExceptAll.length, selectionLimit) &&
			  _.isEqual(Value.length + 1, selectionLimit);
		  } else {
			closeMenuOnSelect =
			  _.isEqual(Value.length + 1, selectionLimit) &&
			  _.isEqual(IsAll.length, 0);
		  }
		}
	  }
	  return closeMenuOnSelect;
};


export const createOption = (label: string): IOption => ({
	label: label,
	value: label,
  });