import COLORS from "../../../../UI/Theme/colors";

const style: any = (): any => ({
	toolContentWrapper: {
		padding: "20px 28px 28px 28px",
	},
	infoWrapper: {
		padding: 12,
	},
	toolContent: {
		width: "100%",
		height: "100%",
		backgroundColor: COLORS.WHITE,
		padding: 31,
	},
	bodybgColor: {
		backgroundColor: COLORS.WHITE,
	},
	linearLoaderPlaceholer: {
		height: 4,
		width: "100%"
	},
	backdrop: {
		position: "fixed",
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: COLORS.WHITE,
		opacity: 0,
		zIndex: 99999999
	},
	toolContentHeader: {
		margin: "0.25rem 0 1.75rem 0",
		display: "flex",
		width: "100%"
	},
	cancelText: {
		color: "black",
		fontWeight: "bolder"
	}
});

export default style;