export const FORM_ELEMENT_TYPES = {
    SELECT_INPUT:"SELECT",
    TEXT_INPUT:"TEXTBOX",
    HTML_EDITOR:"HTML_EDITOR",
    TEXTAREA: "TEXTAREA",
    CHECKBOX_INPUT: "CHECKBOX",
	SWITCH:"SWITCH",
	RADIO_INPUT:"RADIO_INPUT",
	FILE_UPLOAD:"FILE_UPLOAD"
};

enum TOOL_SELECTION_TYPES {
	NEW=1,
	EXISTING=2
}

export const ENUMS = {
	TOOL_SELECTION_TYPES
};