const style:any = () => ({
	container: {
		backgroundColor:"#ffffff",
		display:"flex",
		alignItems: "center",
		position: "absolute",
		bottom: 20
	},
	stepContainer:{
		display: "flex",
		alignItems: "center",
		minWidth:113,
		height:44,
		borderTop:"4px solid transparent"
	},
	stepRoot:{
		display:"flex",
		alignItems:"center",
		margin:"0px 14px 0px 0px",
		justifyContent:"center"
	},
	stepContainerActive:{
		borderTop:"4px solid #F5A855 !important"
	},
	stepNumber:{
		width: 18,
		height: 18,
		borderRadius: 18,
		border: "2px solid rgb(208, 213, 218)",
		textAlign: "center",
		marginRight:"0.625rem",
		fontSize: "0.688rem",
		fontWeight: "bold",
		color: "#ffffff"
	},
	stepNumberActive:{
		border: "2px solid #F5A855 !important",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	stepNumberActiveChild:{
		backgroundColor: "#F5A855 !important",
		width: 8,
		height: 8,
		borderRadius: 8
	},
	completedStep:{
		border: "2px solid #F5A855 !important",
		backgroundColor:"#F5A855 !important"
	},
	checkIcon:{
		fontSize:"0.9rem"
	},
	stepNameActive:{
		color:"rgb(36, 44, 52) !important"
	},
	stepName:{
		fontSize:"0.875rem",
		fontWeight:"bold",
		color:"rgb(106, 115, 124)"
	}
});

export default style;