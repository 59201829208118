import React from "react";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UIGridFileUpload } from "./GridUpload";
import { UIBucketUpload } from "./BucketUpload";

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

// UI File Upload Component
export const FileInput = (props: props): JSX.Element => {
  const { formElement } = props;
  if (formElement.load_data_to_grid) {
    return <UIGridFileUpload {...props} />;
  }
  return <UIBucketUpload {...props} />;
};
