import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import style from "./style";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  labelName: string;
  content: any;
};

export const TextPlaceholder = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { labelName, content } = props;

  return (
    <div className={classes.labelContainer}>
      <div className={classes.label}>{labelName}</div>
      <div className={classes.content}>{content}</div>
    </div>
  );
};
