
import { IOption } from "../../../../UI/Elements/Forms/interface/IOption";
import { TEXT_ALIGNMENT, ELEMENT_TYPES, ALL_OPTION_OBJ, GRID_CELL_ELEMENT } from "../../configuration";
import {
	GET_BRANDS_BY_CLIENT,
	GET_SUBAGENCIES_BY_AGENCY,
	GET_VERTICAL_BY_BRAND
} from "../../../../api/APIPaths/mapping";

import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../Interfaces/Form/IForm";
import { IGridHeader } from "../../Interfaces/Grid/IGridHeader";

export const getGoogleAdsFormConfig_review = (formData: any): IForm => {

	const ManagerAccount_review: IFormElement = {
		disabled: false,
		isValid: true,
		type: ELEMENT_TYPES.TEXT_INPUT,
		id: "manager",
		touched: false,
		
		visible: true,
		errorMessage: "",
		value: formData.manager || null,
		
		readOnly: true,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		readOnlyOptions: {
			title: "",
			subtitle: "",
			keys: {
				title: "managerName",
				subtitle: "managerId",
			}
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false,
		configuration: {
			dataSourceKey: "",
			multiRows: 2,
			dataSource: [],
			isMulti: true,
			placeholder: "",
			defaultValue: [],
			title: "Manager Account",
			parentElements: [],
			isVisible: true,
			allowAll: false,
			apiURL: "",
			readOnly: {
				editForm: true,
				newForm: false,
			},
		}
	};

	const Account_review: IFormElement = {
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.TEXT_INPUT,
		validations: {
			isEmail: false,
			required: false,
			isNumber: false,
		},
		errorMessage: "",
		id: "account",
		visible: true,
		value: formData.account || null,
		configuration: {
			dataSource: [],
			isMulti: true,
			dataSourceKey: "",
			isVisible: true,
			placeholder: "",
			multiRows: 2,
			title: "Account",
			allowAll: false,
			defaultValue: [],
			apiURL: "",
			parentElements: [],
			readOnly: {
				newForm: false,
				editForm: true,
			}
		},
		readOnly: true,
		
		apiError: "",
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
		readOnlyOptions: {
			subtitle: "",
			title: "",
			keys: {
				subtitle: "accountId",
				title: "accountName",
			}
		},
	};


	const Agency_review: IFormElement = {
		disabled: false,
		id: "agency",
		touched: false,
		isValid: true,
		type: ELEMENT_TYPES.SELECT_INPUT,
		
		errorMessage: "",
		visible: true,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		value: formData.agency || null,
		
		readOnly: false,
		configuration: {
			dataSourceKey: "agencies",
			isMulti: false,
			dataSource: [],
			isVisible: true,
			placeholder: "Select Agency",
			title: "Agency",
			multiRows: 2,
			defaultValue: [],
			parentElements: [],
			apiKey: "agency_id",
			allowAll: false,
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const SubAgency_review: IFormElement = {
		disabled: false,
		id: "subagency",
		isValid: true,
		type: ELEMENT_TYPES.SELECT_INPUT,
		touched: false,
		validations: {
			isNumber: false,
			isEmail: false,
			required: false
		},
		errorMessage: "",
		value: formData.subagency || null,
		visible: true,
		configuration: {
			dataSource: [],
			dataSourceKey: "subagencies",
			isMulti: false,
			multiRows: 2,
			isVisible: true,
			title: "SubAgency",
			placeholder: "Select SubAgency",
			defaultValue: null,
			parentElements: ["agency"],
			allowAll: false,
			apiURL: GET_SUBAGENCIES_BY_AGENCY
		},
		highlightRemovedOptionError: "",
		highlightRemovedOption: false,
		readOnly: false,
		apiError: "",
	};

	const Market_review: IFormElement = {
		id: "market",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		
		errorMessage: "",
		visible: true,
		value: formData.market || null,
		configuration: {
			dataSourceKey: "markets",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Market",
			title: "Market",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
		},
		highlightRemovedOptionError: "",
		highlightRemovedOption: false,
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		readOnly: false,
		apiError: "",
	};

	const Client_review: IFormElement = {
		isValid: true,
		disabled: false,
		touched: false,
		id: "client",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			required: true,
			isEmail: false,
			isNumber: false,
		},
		value: formData.client || null,
		errorMessage: "",
		visible: true,
		configuration: {
			dataSourceKey: "clients",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			title: "Client",
			placeholder: "Select Client",
			defaultValue: null,
			tooltip: "This field displays 2500 clients by default. To search a client, type the client's name and select from the dropdown.",
			parentElements: [],
			allowAll: false,
			apiKey: "client_id",
			note: "For adding new clients please contact to <a href='mailTo:gdt-automations-support@groupm.tech'>gdt-automations-support@groupm.tech</a>",
			isAsyncSelect: true,
			isCreatableSelect: false,
			asyncRequestKey: "client_name",
			apiURL: "api/mapping/v2/client/",
		},
		readOnly: false,
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
		apiError: "",
	};

	const Brand_review: IFormElement = {
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		id: "brand",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		
		errorMessage: "",
		visible: true,
		value: formData.brand || null,
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		configuration: {
			dataSourceKey: "brands",
			isMulti: false,
			dataSource: [],
			isVisible: true,
			placeholder: "Select Brand",
			title: "Brand",
			multiRows: 2,
			allowAll: false,
			parentElements: ["client"],
			apiKey: "brand_id",
			apiURL: GET_BRANDS_BY_CLIENT,
			defaultValue: null,
		},
		
		highlightRemovedOption: false
	};

	const Vertical_review: IFormElement = {
		id: "vertical",
		disabled: false,
		isValid: true,
		configuration: {
			dataSourceKey: "verticals",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Vertical",
			title: "Vertical",
			defaultValue: [],
			allowAll: false,
			parentElements: ["brand"],
			apiURL: GET_VERTICAL_BY_BRAND,
			
			selectValueIfEmpty: {
				valueLabelKey: "None",
				value: null,
			},
			readOnly: {
				editForm: true,
				newForm: true
			},
		},
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.vertical || null,
		
		readOnly: true,
		readOnlyOptions: {
			title: "",
			subtitle: "",
			keys: {
				title: "label",
				subtitle: "",
			}
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const IncludeInAdoptionStats_review: IFormElement = {
		id: "includeInAdoptionStats",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SWITCH,
		configuration: {
			dataSource: [],
			isVisible: true,
			isMulti: false,
			multiRows: 2,
			title: "Include in Adoption Statistics as part of Usage Dashboard",
			placeholder: "",
			dataSourceKey: "",
			defaultValue: 1,
			parentElements: [],
			allowAll: false
		},
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		visible: true,
		value: formData.includeInAdoptionStats || 1,
		errorMessage: "",
		
		readOnly: false,
		highlightRemovedOptionError: "",
		apiError: "",
		highlightRemovedOption: false
	};

	return {
		id: "mappingMapperForm",
		title: "",
		isValid: true,
		isVisible: true,
		groups: [{
			id: "mappingMapperFormGroup",
			elements: [ManagerAccount_review, Account_review, Agency_review, SubAgency_review, Market_review, Client_review, Brand_review, Vertical_review, IncludeInAdoptionStats_review],
			isVisible: true,
			title: ""
		}]
	};
};


export const getFilterFormConfig = (formData: any): IForm => {

	const Agency_review_config: IFormElement = {
		configuration: {
			dataSource: [],
			dataSourceKey: "agencies",
			isVisible: true,
			isMulti: true,
			multiRows: 2,
			placeholder: "Select Agency(s)",
			title: "Agency(s)",
			allowAll: true,
			defaultValue: [ALL_OPTION_OBJ],
			apiKey: "agency_id",
			parentElements: []

		},
		id: "agencies",
		isValid: true,
		disabled: false,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			required: true,
			isNumber: false
		},
		visible: true,
		value: formData.agency || null,
		errorMessage: "",
		
		readOnly: false,
		highlightRemovedOptionError: "",
		highlightRemovedOption: false,
		apiError: ""
	};

	const SubAgency_review_config: IFormElement = {
		isValid: true,
		disabled: false,
		touched: false,
		id: "subAgencies",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		visible: true,
		errorMessage: "",
		value: formData.agency || null,
		configuration: {
			dataSource: [],
			isMulti: true,
			multiRows: 2,
			isVisible: true,
			dataSourceKey: "subagencies",
			placeholder: "Select SubAgency(s)",
			title: "SubAgency(s)",
			defaultValue: [ALL_OPTION_OBJ],
			parentElements: ["agencies"],
			apiURL: GET_SUBAGENCIES_BY_AGENCY,
			allowAll: true,
		},
		readOnly: false,
		highlightRemovedOption: false,
		apiError: "",
		highlightRemovedOptionError: "",
	};

	const Market_review_config: IFormElement = {
		disabled: false,
		id: "markets",
		type: ELEMENT_TYPES.SELECT_INPUT,
		touched: false,
		isValid: true,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		errorMessage: "",
		value: formData.market || null,
		visible: true,
		configuration: {
			dataSourceKey: "markets",
			dataSource: [],
			isVisible: true,
			isMulti: true,
			placeholder: "Select Market(s)",
			multiRows: 2,
			defaultValue: [ALL_OPTION_OBJ],
			title: "Market(s)",
			parentElements: [],
			allowAll: true,
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Client_review_config: IFormElement = {
		id: "clients",
		disabled: false,
		isValid: true,
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		type: ELEMENT_TYPES.SELECT_INPUT,
		touched: false,
	
		errorMessage: "",
		value: formData.client || null,
		visible: true,
		configuration: {
			dataSource: [],
			dataSourceKey: "clients",
			isMulti: true,
			multiRows: 2,
			isVisible: true,
			placeholder: "Select Client(s)",
			title: "Client(s)",
			apiURL: "api/mapping/v2/client/",
			apiKey: "client_id",
			allowAll: true,
			tooltip: "This field displays 2500 clients by default. To search a client, type the client's name and select from the dropdown.",
			defaultValue: [ALL_OPTION_OBJ],
			parentElements: [],
			asyncRequestKey: "client_name",
			isAsyncSelect: true,
		},
		readOnly: false,
		highlightRemovedOption: false,
		apiError: "",
		highlightRemovedOptionError: "",
	};
	const Brand_review_config: IFormElement = {
		id: "brands",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		
		errorMessage: "",
		visible: true,
		value: formData.client || null,
		
		readOnly: false,
		apiError: "",
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		configuration: {
			placeholder: "Select Brand(s)",
			dataSourceKey: "brands",
			dataSource: [],
			isMulti: true,
			defaultValue: [ALL_OPTION_OBJ],
			isVisible: true,
			multiRows: 2,
			title: "Brand(s)",
			allowAll: true,
			apiURL: "/api/mapping/v2/brand/",
			parentElements: ["clients"],
			tooltip: "This field displays 2500 brands by default. To search a brand, type the brand's name and select from the dropdown.",
			isAsyncSelect: true,
			apiKey: "brand_id",
			asyncRequestKey: "brand_name",
		},
	};
	const Vertical_review_config: IFormElement = {
		disabled: false,
		touched: false,
		isValid: true,
		id: "verticals",
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		type: ELEMENT_TYPES.SELECT_INPUT,
		errorMessage: "",
		visible: true,
		value: formData.client || null,
		readOnly: false,
		configuration: {
			dataSource: [],
			apiURL: GET_VERTICAL_BY_BRAND,
			isMulti: true,
			isVisible: true,
			placeholder: "Select Vertical(s)",
			multiRows: 2,
			title: "Vertical(s)",
			defaultValue: [ALL_OPTION_OBJ],
			allowAll: true,
			parentElements: ["brands"],
			dataSourceKey: "verticals",


		},
		highlightRemovedOptionError: "",
		apiError: "",
		highlightRemovedOption: false
	};

	const MappedBy_review_config: IFormElement = {
		disabled: false,
		id: "mappedby",
		touched: false,
		isValid: true,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		type: ELEMENT_TYPES.SELECT_INPUT,
		visible: true,
		value: formData.mappedby || null,
		errorMessage: "",
		configuration: {
			dataSourceKey: "mappedBy",
			isMulti: true,
			dataSource: [],
			isVisible: true,
			placeholder: "Select Mapped By",
			multiRows: 2,
			title: "Mapped By",
			allowAll: true,
			defaultValue: [ALL_OPTION_OBJ],
			parentElements: []
		},
		apiError: "",
		readOnly: false,
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
	};

	const MappingManager_review_config: IFormElement = {
		isValid: true,
		disabled: false,
		id: "mappingManager",
		type: ELEMENT_TYPES.SELECT_INPUT,
		touched: false,
		errorMessage: "",
		visible: true,
		value: formData.mappedby || null,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		configuration: {
			dataSource: [],
			dataSourceKey: "mappingManagers",
			isVisible: true,
			isMulti: true,
			placeholder: "Select Mapping Manager(s)",
			multiRows: 2,
			defaultValue: [ALL_OPTION_OBJ],
			title: "Mapping Manager(s)",
			parentElements: [],
			allowAll: true,
		},
		readOnly: false,
		highlightRemovedOptionError: "",
		apiError: "",
		highlightRemovedOption: false
	};

	const IncludeInAdoptionStats_review_config: IFormElement = {
		disabled: false,
		isValid: true,
		id: "includeInAdoptionStats",
		type: ELEMENT_TYPES.SELECT_INPUT,
		touched: false,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		visible: true,
		value: formData.includeInAdoptionStats || null,
		errorMessage: "",
		configuration: {
			dataSource: [],
			dataSourceKey: "includeInAdoptionStats",
			isMulti: true,
			multiRows: 2,
			isVisible: true,
			placeholder: "Select Include In Adoption Stats",
			defaultValue: [ALL_OPTION_OBJ],
			title: "Include in Adoption Statistics as part of Usage Dashboard",
			parentElements: [],
			allowAll: true,
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
	};

	return {
		title: "",
		id: "mappingMapperFilter",
		isVisible: true,
		isValid: true,
		groups: [{
			id: "group1",
			isVisible: true,
			elements: [Agency_review_config, SubAgency_review_config, Market_review_config, Client_review_config, Brand_review_config, Vertical_review_config, MappedBy_review_config, MappingManager_review_config, IncludeInAdoptionStats_review_config],
			title: ""
		}]
	};
};

export const GRID_GOOGLEADS_HEADERS: IGridHeader[] = [{
	defaultSortBy: false,
	visible: false,
	title: "",
	id: "id",
	isMulti: false,
	type: ELEMENT_TYPES.ID,
	sortingKey: "id",
	disableSorting: true,
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
}, {
	defaultSortBy: true,
	visible: true,
	title: "Manager Account",
	id: "manager",
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "manager",
	isMulti: false,
	disableSorting: false,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellTwoLiner: {
		titleKey: "managerName",
		subtitleKey: "managerId"
	}
}, {
	id: "account",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	title: "Account",
	isMulti: false,
	disableSorting: false,
	sortingKey: "account",
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLiner: {
		titleKey: "accountName",
		subtitleKey: "accountId"
	}
},
{
	id: "agency",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	title: "Agency / SubAgency",
	isMulti: false,
	sortingKey: "agency",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	disableSorting: false,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLiner: {
		subtitleKey: "subagencyName",
		titleKey: "agencyName",
	}
}, {
	id: "market",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	title: "Market",
	sortingKey: "market",
	isMulti: false,
	disableSorting: false,
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLiner: {
		subtitleKey: "marketCode",
		titleKey: "marketName",
	}
}, {
	defaultSortBy: true,
	visible: true,
	id: "client",
	title: "Client / Brand",
	isMulti: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "client",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	disableSorting: false,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	showHideText: true,
	cellTwoLiner: {
		subtitleKey: "brandName",
		titleKey: "clientName",
	}
},
{
	id: "vertical",
	defaultSortBy: true,
	title: "Vertical",
	visible: true,
	isMulti: false,
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "vertical",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	disableSorting: false,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLiner: {
		subtitleKey: "",
		titleKey: "verticalName",
	}

}, {
	defaultSortBy: true,
	visible: true,
	id: "mappingManager",
	title: "Mapping Manager",
	isMulti: false,
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "mappingManager",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	disableSorting: false,
	width: 150,
	showHideText: false,
	enableTooltip: {
		displayKey: "name",
		tooltipKey: "username",
	},
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
},
{
	defaultSortBy: true,
	visible: true,
	id: "mappedby",
	title: "Mapped by",
	isMulti: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "mappedby",
	disableSorting: false,
	width: 125,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	showHideText: false,
	cellTwoLinerTooltip: true,
	cellTwoLiner: {
		subtitleKey: "mappedDate",
		titleKey: "name",
		tooltip: "email"
	},

}, {
	id: "includeInAdoptionStats",
	defaultSortBy: true,
	visible: true,
	width: 105,
	title: "Dashboard",
	type: ELEMENT_TYPES.SWITCH,
	sortingKey: "includeInAdoptionStats",
	isMulti: false,
	disableSorting: true,
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT,
	tooltip: "Include in Adoption Statistics as part of Usage Dashboard",
}

];


export const getAccountsDropdown = (value: any, dataSource: IOption[]): IFormElement => {
	return {
		disabled: false,
		id: "accounts",
		touched: false,
		isValid: false,
		errorMessage: "",
		type: ELEMENT_TYPES.SELECT_INPUT,
		
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		value: value || null,
		visible: true,
		configuration: {
			dataSource: dataSource,
			isMulti: false,
			dataSourceKey: "agencies",
			isVisible: true,
			title: "Agency",
			placeholder: "Select Agency",
			defaultValue: null,
			parentElements: [],
			multiRows: 2,
			allowAll: false,
		},
		highlightRemovedOption: false,
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
	};
};



