import COLORS from "../../../../../UI/Theme/colors";

const style: any = (): any => ({
	link: {
		color: COLORS.DARK_BLUE,
		fontSize: "0.75rem",
		cursor: "pointer",
		textDecoration: "none",
		"&:hover": {
			textDecoration: "underline"
		}
	},
	content: {
		width: "100%"
	},
	hideLink: {
		display: "none"
	}
});

export default style;