import React, { useEffect, useRef, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import _ from "lodash";
import style from "./style";
import { IconHelp, TableSortIcons } from "../../../../../../../UI/Icons";
import classnames from "classnames";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

const SCHEDULED_HEADERS: any = [
    {
        title: "Job Name / Job ID",
        value: "job",
        visible: true,
        width: "400px",
        minWidth: "600px"
    },
    { title: "Last Execution Time (UTC)", value: "lastExecutionDate", visible: true, minWidth: "260px", tooltip: "Represents latest execution time specification for a job. For very first execution, this will be blank." },
    { title: "Job Status", value: "jobStatus", visible: true, minWidth: "150px" },
    { title: "Next Execution Date (UTC)", value: "nextExecutionDate", visible: true, minWidth: "300px", tooltip: "For scheduled job, this represents the next estimated execution date calculation based on the respective job schedule." },
    { title: "", value: "", visible: true, minWidth: "400px" },
];

const NOTSCHEDULED_HEADERS: any = [
    {
        title: "Job Name / Job ID",
        value: "job",
        visible: true,
        width: "550px",
        minWidth: "600px"
    },

    { title: "Last Execution Time (UTC)", value: "lastExecutionDate", visible: true, minWidth: "260px",  tooltip: "Represents latest execution time specification for a job. For very first execution, this will be blank." },
    { title: "Job Status", value: "jobStatus", visible: true, minWidth: "150px" },
    { title: "", value: "", visible: true, minWidth: "400px" },
];

// create table head cells
const createTableHeadCells = (props: any, classes: any): React.JSX.Element[] => {
    const { orderBy, order, createSortHandler, isSchedule } = props;


    const getGridHeaders = (): void => {
        if (_.isEqual(isSchedule, 1)) {
            return SCHEDULED_HEADERS;
        }
        return NOTSCHEDULED_HEADERS;
    };

    const HEADERS: any = getGridHeaders();

    const TableCells: React.JSX.Element[] = [];
    for (const key in HEADERS) {
        if (HEADERS[key].visible) {
            let sortIcon = TableSortIcons.DEFAULT;
            if (_.isEqual(orderBy, HEADERS[key].value) && _.isEqual(order, "desc")) {
                sortIcon = TableSortIcons.UP;
            }
            else if (
                _.isEqual(orderBy, HEADERS[key].value) &&
                _.isEqual(order, "asc")
            ) {
                sortIcon = TableSortIcons.DOWN;
            }
            TableCells.push(
                <TableCell
                    key={key}
                    style={{
                        width: HEADERS[key].width,
                        minWidth: HEADERS[key].width,
                    }}
                    className={classes.headers}
                    sortDirection={_.isEqual(orderBy, HEADERS[key].value) ? order : false}
                >
                    <div
                        onClick={() => {
                            createSortHandler(HEADERS[key].value);
                        }}
                        style={{ cursor: "pointer" }}
                    >
                        {HEADERS[key].title}
                        {!_.isEmpty(HEADERS[key].tooltip) && <span onClick={(e) => { e.stopPropagation(); }}>
                             <IconHelp title={!_.isEmpty(HEADERS[key].tooltip) && HEADERS[key].tooltip} />
                            </span>}

                        {!_.isEmpty(HEADERS[key].value) && 
                            <span className={classes.sortingIcons}>{sortIcon}</span>}

                    </div>
                </TableCell>
            );
        }
    }
    return TableCells;
};

export default function GridHeader(props: any): React.JSX.Element {
    const [touched, setTouched] = useState(false);
    const theadRef: any = useRef();
    const classes = useStyles();

    const onScroll = (): void => {
        if (_.isNull(theadRef) || _.isUndefined(theadRef.current)) {
            return;
        }
        const element = theadRef.current.getBoundingClientRect();

        setTouched(_.isEqual(element.top - 56, 0));
    };

    useEffect(() => {
        document.addEventListener("scroll", onScroll);

        return () => {

            document.removeEventListener("scroll", onScroll);
        };
    }, []);
    const TableCells = createTableHeadCells(props, classes);
    return (
        <thead
        className={classnames(classes.gridHeader, {
            [classes.gridHeaderBoxShadow]: touched,
        })}
        ref={theadRef}
        >
            <tr>{TableCells}</tr>
        </thead>
    );
}
