import * as _ from "lodash";
import { IFormElementUpdate } from "../../Interfaces/Form/FormElement/IFormElementUpdate";
import { IOption } from "./../../../../UI/Elements/Forms/interface/IOption";
import { ALL_OPTION_OBJ, ALL_OPTION_VALUE, ELEMENT_TYPES } from "./../../configuration";
import { IFormElement } from "./../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "./../../Interfaces/Form/IForm";
import { IFormGroup } from "./../../Interfaces/Form/IFormGroup";
import { ValidationHelper } from "../Validations";

// Edit form class
export class EditFormHelper {

	public updateElementDataSourceHist(formhist: IForm, elementhist: IFormElement, option: IOption): IForm {
		const validationHelper = new ValidationHelper();
		let updatedFormhist = {
			...formhist,
			groups: formhist.groups.map((formGroup: IFormGroup) => {
				return {
					...formGroup,
					elements: formGroup.elements.map((formElementhist: IFormElement) => {
						if (_.isEqual(formElementhist.id, elementhist.id)
							&& _.isEqual(ELEMENT_TYPES.SELECT_INPUT, elementhist.type)) {
							const ErrorMessage = validationHelper.validate(formElementhist, option);
							let updatedDataSource = [...formElementhist.configuration.dataSource,
								option];
							updatedDataSource = _.orderBy(updatedDataSource, [(op: IOption) => op.label.toLowerCase()], ["asc"]);
							return {
								...formElementhist,
								configuration: {
									...formElementhist.configuration,
									dataSource: updatedDataSource
								},
								value: option,
								isValid: _.isEqual(ErrorMessage.length, 0),
								errorMessage: ErrorMessage
							};
						}
						return {
							...formElementhist
						};
					})
				};
			})
		};
		updatedFormhist = validationHelper.isValidForm(updatedFormhist);
		return updatedFormhist;
	}

	public updateFormElementDataSourceinhist (forminhist: IForm, id: string, dataSourceinhist: IOption[]): IForm {
		const validationHelper = new ValidationHelper();
		let updatedForminhist =  {
			...forminhist,
			groups: forminhist.groups.map((formGroupinhist: IFormGroup)=>{
				return {
					...formGroupinhist,
					elements: formGroupinhist.elements.map((formElement: IFormElement)=>{
						if(_.isEqual(formElement.id, id) 
						&& _.isEqual(ELEMENT_TYPES.SELECT_INPUT, formElement.type)){
								const DataSourceinhist = dataSourceinhist || [];
								if(formElement.configuration.allowAll && !_.isEqual(DataSourceinhist.length, 0)){
									DataSourceinhist.unshift(ALL_OPTION_OBJ);
								}
								return {
								...formElement,
								configuration:{
									...formElement.configuration,
									dataSource: DataSourceinhist
								},
							};
						}
						return {
							...formElement
						};
					})
				};
			})
		};
		updatedForminhist = validationHelper.isValidForm(updatedForminhist);
		return updatedForminhist;
	}

	public updateForminHist = async (form: IForm, element: IFormElementUpdate): Promise<IForm> => {
		const validationHelperhist = new ValidationHelper();
		const FormGroupsinhist = [];
		for (const formGroup of form.groups) {
			const FormElementArray = [];

			for (const formElement of formGroup.elements) {
				const Element = await this.updateElementhist(formElement, element);
				FormElementArray.push(Element);
			}
			FormGroupsinhist.push({
				...formGroup,
				elements: FormElementArray,
			});
		}
		let updatedForm = {
			...form,
			groups: FormGroupsinhist
		};
		updatedForm = validationHelperhist.isValidForm(updatedForm);
		return updatedForm;
	}

	private updateElementhist = async (formElement_hist: IFormElement, element: IFormElementUpdate, ): Promise<IFormElement> => {
		let updatedElement_hist = {
			...formElement_hist,
		};

		if (_.isEqual(formElement_hist.id, element.formElement.id)) {
			let value = element.value;

			if (_.isEqual(formElement_hist.type, ELEMENT_TYPES.SELECT_INPUT)) {
				value = this.updateSelectValue_hist(formElement_hist, value);
			}

			const validationHelper = new ValidationHelper();
			const ErrorMessage = validationHelper.validate(formElement_hist, value);
			const IsValid = _.isEqual((ErrorMessage || "").length, 0);

			updatedElement_hist = {
				...updatedElement_hist,
				touched: true,
				value,
				errorMessage: ErrorMessage,
				isValid: IsValid,
			};
		}
		else if (_.isEqual(formElement_hist.type, ELEMENT_TYPES.SELECT_INPUT)
			&& !_.isEqual(formElement_hist.configuration.parentElements.indexOf(element.formElement.id), -1)) {
			const errorMessage = "";
			updatedElement_hist = {
				...formElement_hist,
				value: null,
				touched: false,
				errorMessage,
				isValid: _.isEqual(errorMessage.length, 0),
			};
		}
		return {
			...updatedElement_hist
		};
	}

	updateSelectValue_hist = (formElement_hist: IFormElement, value: any): any => {

		const {
			isMulti,
			allowAll
		} = formElement_hist.configuration;

		if (isMulti && allowAll) {
			const Value = value || [];
			const IsAllOptionSelected_hist = (Value).findIndex(
				(option_hist: IOption) => {
					return _.isEqual(option_hist.value, ALL_OPTION_VALUE);
				}
			);

			if (
				_.isEqual(IsAllOptionSelected_hist, Value.length - 1) &&
				_.gt(Value.length, 1)
			) {
				return [ALL_OPTION_OBJ];
			} else if (
				!_.isEqual(IsAllOptionSelected_hist, -1) &&
				_.gt(Value.length, 1)
			) {
				return Value.filter(
					(option: IOption) => !_.isEqual(option.value, ALL_OPTION_VALUE)
				);
			}
		}
		return value;
	}

}