import * as _ from "lodash";
import { USER_PERMISSIONS_CODES, USER_PERMISSIONS } from "./UserPermissions";
import { IUserPermission } from "./IUserPermission";

export class UserPermissionHelper{

	isAccessAllowed(userPermissions: string[], allowedPermissions: string[]): boolean{
		let isAllowed = false;
		userPermissions.every((permission: string)=>{

			if(_.includes(allowedPermissions, permission)){
				isAllowed = true;
				return false;
			}
			return true;
		});

		return isAllowed;
	}

	findPermission = (permissions: any, inputPermission: string): boolean =>{
		const AllowedAccess = permissions.find((permission: string)=> _.isEqual(permission, inputPermission));
		return !_.isUndefined(AllowedAccess);
	}

	isAllowedPermission = (user: any, authPermission: string): boolean=>{
		const Permissions: any = user.userPermissions || [];
		const AllowedAccess =Permissions.find((permission: string)=> _.isEqual(permission, authPermission));
		return !_.isUndefined(AllowedAccess);
	}

	isOnlyRegularUser = (user: any): boolean =>{
		const Permissions: any = user.userPermissions || [];

		if(this.findPermission(Permissions, USER_PERMISSIONS_CODES.AP_VIEW_BETA_TOOLS)){
			return false;
		}
		if(this.findPermission(Permissions, USER_PERMISSIONS_CODES.AP_VIEW_REGULAR_TOOLS)){
			return true;
		}
		return false;
	}

	isUserAllowedRouteAccess = (user: any, routeUrl: string): boolean=>{
		let allowedRouteAccess = false;
		
		user.routePermissions.forEach((permission: IUserPermission)=>{
			
			if(_.isEqual(routeUrl, permission.url) && permission.allowedAccess){
				allowedRouteAccess = true;
			}

			permission.children.forEach((childPermission: IUserPermission)=>{
				if(_.isEqual(routeUrl, childPermission.url) && childPermission.allowedAccess){
					allowedRouteAccess = true;
				}
			});
		});

		return allowedRouteAccess;
	};

	getCurrentRouteIndex = (userPermissions: IUserPermission[], currentRouteUrl: string): number =>{
		let routeIndex = 0;
		userPermissions.forEach((permission: IUserPermission, parentIndex: number)=>{
			
			if(_.isEqual(currentRouteUrl, permission.url)){
				routeIndex = parentIndex;
			}

			permission.children.forEach((childPermission: IUserPermission)=>{
				if(_.isEqual(currentRouteUrl, childPermission.url)){
					routeIndex = parentIndex;
				}
			});
		});
	
		return routeIndex;
	}

	getUserPermissions = (user: any): any =>{
		const Permissions: any = user.userPermissions || [];

		return USER_PERMISSIONS.map((permission: IUserPermission)=>{
			const IsParentAcessAllowed: boolean =  this.isAccessAllowed(Permissions, permission.permissionCodes);
			const ChildrenPermissions: IUserPermission[]  = permission.children.map((childrenPermission: IUserPermission)=>{
				const IsChildAccessAllowed: boolean =  this.isAccessAllowed(Permissions, childrenPermission.permissionCodes);

				return {
					...childrenPermission,
					allowedAccess: IsChildAccessAllowed
				};
			});

			return {
				...permission,
				children: ChildrenPermissions,
				allowedAccess:IsParentAcessAllowed 
			};
		});
	};
}
