import { IOption } from "../../../../UI/Elements/Forms/interface/IOption";
import { TEXT_ALIGNMENT, ELEMENT_TYPES, ALL_OPTION_OBJ, GRID_CELL_ELEMENT } from "../../configuration";

import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../Interfaces/Form/IForm";
import { IGridHeader } from "../../Interfaces/Grid/IGridHeader";
import {
	GET_SUBAGENCIES_BY_AGENCY, GET_BRANDS_BY_CLIENT,
	GET_VERTICAL_BY_BRAND
} from "../../../../api/APIPaths/mapping";
import { IStatusIndicator } from "../interface/IStatusIndicator";

export const getGoogleAdsFormConfig_mapper = (formData: any): IForm => {

	const Account_mapper: IFormElement = {
		type: ELEMENT_TYPES.DISPLAY_ACCOUNT,
		disabled: false,
		isValid: true,
		touched: false,
		validations: {
			isEmail: false,
			required: false,
			isNumber: false,
		},
		id: "account",
		visible: true,
		errorMessage: "",
		value: formData.account || null,
		configuration: {
			readOnly: {
				editForm: true,
				newForm: false
			},
			dataSource: [],
			dataSourceKey: "",
			isMulti: true,
			multiRows: 2,
			isVisible: true,
			placeholder: "",
			defaultValue: [],
			title: "",
			allowAll: false,
			parentElements: [],
			apiURL: "",
			
		},
		readOnly: true,
		notification: {
			managerId: "managerId",
			label: "Manager Account",
			managerName: "managerName"
		},
		readOnlyOptions: {
			subtitle: "",
			title: "",
			keys: {
				subtitle: "accountId",
				title: "accountName",
			}
		},
		highlightRemovedOption: false,
		apiError: "",
		highlightRemovedOptionError: "",
	};


	const Agency_mapper: IFormElement = {
		disabled: false,
		id: "agency",
		isValid: true,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		touched: false,
		errorMessage: "",
		value: formData.agency || null,
		visible: true,
		highlightRemovedOption: false,
		configuration: {
			dataSource: [],
			isMulti: false,
			dataSourceKey: "agencies",
			multiRows: 2,
			isVisible: true,
			placeholder: "Select Agency",
			defaultValue: null,
			title: "Agency",
			allowAll: false,
			apiKey: "agency_id",
			parentElements: [],
		},
		apiError: "",
		readOnly: false,
		highlightRemovedOptionError: "",
	};

	const SubAgency_mapper: IFormElement = {
		errorMessage: "",
		disabled: false,
		isValid: true,
		id: "subagency",
		type: ELEMENT_TYPES.SELECT_INPUT,
		touched: false,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		highlightRemovedOption: false,
		visible: true,
		value: formData.subagency || null,
		configuration: {
			dataSource: [],
			multiRows: 2,
			isMulti: false,
			isVisible: true,
			dataSourceKey: "subagencies",
			title: "SubAgency",
			placeholder: "Select SubAgency",
			defaultValue: null,
			parentElements: ["agency"],
			allowAll: false,
			apiURL: GET_SUBAGENCIES_BY_AGENCY
		},
		highlightRemovedOptionError: "",
		apiError: "",
		readOnly: false,
	};

	const Market_mapper: IFormElement = {
		disabled: false,
		touched: false,
		isValid: true,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		id: "market",
		errorMessage: "",
		value: formData.market || null,
		visible: true,
		
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false,
		configuration: {
			dataSourceKey: "markets",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Market",
			title: "Market",
			defaultValue: null,
			parentElements: [],
			allowAll: false,
		},
	};

	const Client_mapper: IFormElement = {
		isValid: true,
		id: "client",
		touched: false,
		disabled: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		errorMessage: "",
		visible: true,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		value: formData.client || null,
		highlightRemovedOption: false,
		readOnly: false,
		highlightRemovedOptionError: "",
		apiError: "",
		configuration: {
			dataSource: [],
			dataSourceKey: "clients",
			isMulti: false,
			tooltip: "This field displays 2500 clients by default. To search a client, type the client's name and select from the dropdown.",
			note: "For adding new clients please contact to <a href='mailTo:gdt-automations-support@groupm.tech'>gdt-automations-support@groupm.tech</a>",
			isVisible: true,
			placeholder: "Select Client",
			multiRows: 2,
			defaultValue: null,
			title: "Client",
			parentElements: [],
			allowAll: false,
			apiKey: "client_id",
			asyncRequestKey: "client_name",
			isAsyncSelect: true,
			apiURL: "api/mapping/v2/client/",
			isCreatableSelect: false,
		},
		
	};
	const Brand_mapper: IFormElement = {
	configuration: {
			dataSourceKey: "brands",
			isMulti: false,
			dataSource: [],
			isVisible: true,
			placeholder: "Select Brand",
			multiRows: 2,
			title: "Brand",
			allowAll: false,
			defaultValue: null,
			parentElements: ["client"],
			apiKey: "brand_id",
			apiURL: GET_BRANDS_BY_CLIENT,
		},
		
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		errorMessage: "",
		visible: true,
		value: formData.brand || null,
		
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false,
		id: "brand",
		disabled: false,
	};

	const Vertical_mapper: IFormElement = {
		id: "vertical",
		disabled: false,
		isValid: true,
		touched: false,
		validations: {
			isNumber: false,
			isEmail: false,
			required: false
		},
		type: ELEMENT_TYPES.SELECT_INPUT,
		errorMessage: "",
		value: formData.vertical || null,
		visible: true,
		configuration: {
		selectValueIfEmpty: {
				valueLabelKey: "None",
				value: null,
			},
			dataSourceKey: "verticals",
			isMulti: false,
			dataSource: [],
			isVisible: true,
			multiRows: 2,
			title: "Vertical",
			placeholder: "Select Vertical",
			defaultValue: [],
			allowAll: false,
			apiURL: GET_VERTICAL_BY_BRAND,
			parentElements: ["brand"],
			readOnly: {
				newForm: true,
				editForm: true,
			},
			
		},
		readOnly: true,
		readOnlyOptions: {
			keys: {
				subtitle: "",
				title: "label",
			},
			subtitle: "",
			title: "",
		},
		apiError: "",
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
	};


	const IncludeInAdoptionStats_mapper: IFormElement = {
		id: "includeInAdoptionStats",
		isValid: true,
		disabled: false,
		type: ELEMENT_TYPES.SWITCH,
		touched: false,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		visible: true,
		errorMessage: "",
		value: formData.includeInAdoptionStats || true,
		configuration: {
			isMulti: false,
			dataSource: [],
			isVisible: true,
			placeholder: "",
			multiRows: 2,
			title: "Include in Adoption Statistics as part of Usage Dashboard",
			dataSourceKey: "",
			defaultValue: 1,
			allowAll: false,
			parentElements: []
		},
		apiError: "",
		readOnly: false,
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
	};

	return {
		id: "mappingMapperForm",
		title: "",
		isVisible: true,
		isValid: true,
		groups: [{
			id: "mappingMapperFormGroup",
			isVisible: true,
			elements: [Account_mapper, Agency_mapper, SubAgency_mapper, Market_mapper, Client_mapper, Brand_mapper, Vertical_mapper, IncludeInAdoptionStats_mapper],
			title: ""
		}]
	};
};

export const getGoogleAdsBulkEditFormConfig = (formData: any): IForm => {

	const Account: IFormElement = {
		id: "account",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.DISPLAY_ACCOUNT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.account || null,
		configuration: {
			dataSourceKey: "",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "",
			title: "",
			defaultValue: [],
			allowAll: false,
			parentElements: [],
			apiURL: "",
			readOnly: {
				editForm: true,
				newForm: false
			}
		},
		notification: {
			label: "Manager Account",
			managerId: "managerId",
			managerName: "managerName"
		},
		readOnly: true,
		readOnlyOptions: {
			title: "",
			subtitle: "",
			keys: {
				title: "accountName",
				subtitle: "accountId",
			}
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};


	const Agency: IFormElement = {
		id: "agency",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group1",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.agency || null,
		configuration: {
			dataSourceKey: "agencies",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Agency",
			title: "Agency",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
			apiKey: "agency_id",
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const SubAgency: IFormElement = {
		id: "subagency",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group1",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.subagency || null,
		configuration: {
			dataSourceKey: "subagencies",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select SubAgency",
			title: "SubAgency",
			defaultValue: null,
			allowAll: false,
			parentElements: ["agency"],
			apiURL: GET_SUBAGENCIES_BY_AGENCY
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Market: IFormElement = {
		id: "market",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group2",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.market || null,
		configuration: {
			dataSourceKey: "markets",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Market",
			title: "Market",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Client: IFormElement = {
		id: "client",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group3",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.client || null,
		configuration: {
			dataSourceKey: "clients",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Client",
			title: "Client",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
			isAsyncSelect: true,
			apiKey: "client_id",
			asyncRequestKey: "client_name",
			apiURL: "api/mapping/v2/client/",
			isCreatableSelect: false,
			tooltip: "This field displays 2500 clients by default. To search a client, type the client's name and select from the dropdown.",
			note: "For adding new clients please contact to <a href='mailTo:gdt-automations-support@groupm.tech'>gdt-automations-support@groupm.tech</a>"
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};
	const Brand: IFormElement = {
		id: "brand",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group3",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.brand || null,
		configuration: {
			dataSourceKey: "brands",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Brand",
			title: "Brand",
			defaultValue: null,
			allowAll: false,
			parentElements: ["client"],
			apiURL: GET_BRANDS_BY_CLIENT,
			apiKey: "brand_id",
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Vertical: IFormElement = {
		id: "vertical",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group3",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.vertical || null,
		configuration: {
			dataSourceKey: "verticals",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Vertical",
			title: "Vertical",
			defaultValue: [],
			allowAll: false,
			parentElements: ["brand"],
			apiURL: GET_VERTICAL_BY_BRAND,
			readOnly: {
				editForm: true,
				newForm: true
			},
			selectValueIfEmpty: {
				valueLabelKey: "None",
				value: null,
			}
		},
		readOnly: true,
		readOnlyOptions: {
			title: "",
			subtitle: "",
			keys: {
				title: "label",
				subtitle: "",
			}
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};


	const IncludeInAdoptionStats: IFormElement = {
		id: "includeInAdoptionStats",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group4",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		visible: true,
		errorMessage: "",
		value: formData.includeInAdoptionStats || null,
		configuration: {
			dataSourceKey: "includeInAdoptionStats",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Include in Adoption Statistics",
			title: "Include in Adoption Statistics as part of Usage Dashboard",
			defaultValue: null,
			allowAll: false,
			parentElements: []
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	return {
		id: "mappingMapperForm",
		title: "",
		isValid: true,
		isVisible: true,
		groups: [{
			id: "mappingMapperFormGroup",
			elements: [Account, Agency, SubAgency, Market, Client, Brand, Vertical, IncludeInAdoptionStats],
			isVisible: true,
			title: ""
		}]
	};
};

export const getGoogleAdsFilterFormConfig = (formData: any): IForm => {

	const Status_mapper_config: IFormElement = {
		disabled: false,
		id: "status",
		touched: false,
		isValid: true,
		type: ELEMENT_TYPES.SELECT_INPUT,
		
		errorMessage: "",
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		value: formData.status || null,
		visible: true,
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
		configuration: {
			dataSource: [],
			isMulti: true,
			multiRows: 2,
			dataSourceKey: "status",
			placeholder: "Select Status(s)",
			defaultValue: [ALL_OPTION_OBJ],
			allowAll: true,
			title: "Status(s)",
			isVisible: true,
			parentElements: [],
			apiURL: ""
		},
		apiError: "",
		readOnly: false,
		
	};


	const Agency_mapper_config: IFormElement = {
		value: formData.agency || null,
		isValid: true,
		touched: false,
		disabled: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		errorMessage: "",
		visible: true,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		id: "agencies",
		apiError: "",
		readOnly: false,
		highlightRemovedOptionError: "",
		configuration: {
			dataSourceKey: "agencies",
			dataSource: [],
			apiKey: "agency_id",
			isVisible: true,
			isMulti: true,
			multiRows: 2,
			title: "Agency(s)",
			placeholder: "Select Agency(s)",
			defaultValue: [ALL_OPTION_OBJ],
			parentElements: [],
			allowAll: true,

		},
		highlightRemovedOption: false
	};

	const SubAgency_mapper_config: IFormElement = {
		disabled: false,
		id: "subAgencies",
		isValid: true,
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		type: ELEMENT_TYPES.SELECT_INPUT,
		touched: false,
		
		errorMessage: "",
		visible: true,
		value: formData.agency || null,
		
		apiError: "",
		readOnly: false,
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
		configuration: {
			dataSource: [],
			dataSourceKey: "subagencies",
			isMulti: true,
			multiRows: 2,
			isVisible: true,
			placeholder: "Select SubAgency(s)",
			defaultValue: [ALL_OPTION_OBJ],
			title: "SubAgency(s)",
			allowAll: true,
			parentElements: ["agencies"],
			apiURL: GET_SUBAGENCIES_BY_AGENCY
		},
	};

	const Market_mapper_config: IFormElement = {
		isValid: true,
		touched: false,
		id: "market",
		disabled: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		errorMessage: "",
		visible: true,
		validations: {
			isNumber: false,
			required: true,
			isEmail: false,
		},
		value: formData.market || null,
		configuration: {
			dataSource: [],
			dataSourceKey: "markets",
			isMulti: true,
			multiRows: 2,
			isVisible: true,
			placeholder: "Select Market(s)",
			defaultValue: [ALL_OPTION_OBJ],
			title: "Market(s)",
			parentElements: [],
			allowAll: true,
		},
		highlightRemovedOptionError: "",
		readOnly: false,
		apiError: "",
		highlightRemovedOption: false
	};

	const Client_mapper_config: IFormElement = {
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		highlightRemovedOptionError: "",
		apiError: "",
		visible: true,
		errorMessage: "",
		value: formData.client || null,
		id: "clients",
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		configuration: {
			tooltip: "This field displays 2500 clients by default. To search a client, type the client's name and select from the dropdown.",
			dataSourceKey: "clients",
			isMulti: true,
			dataSource: [],
			isVisible: true,
			placeholder: "Select Client(s)",
			title: "Client(s)",
			multiRows: 2,
			parentElements: [],
			allowAll: true,
			defaultValue: [ALL_OPTION_OBJ],
			isAsyncSelect: true,
			apiURL: "api/mapping/v2/client/",
			asyncRequestKey: "client_name",
			apiKey: "client_id",
		},
		readOnly: false,
		highlightRemovedOption: false,
		

	};
	const Brand_mapper_config: IFormElement = {
	configuration: {
			dataSource: [],
			dataSourceKey: "brands",
			isMulti: true,
			multiRows: 2,
			isVisible: true,
			placeholder: "Select Brand(s)",
			title: "Brand(s)",
			allowAll: true,
			defaultValue: [ALL_OPTION_OBJ],
			parentElements: ["clients"],
			asyncRequestKey: "brand_name",
			isAsyncSelect: true,
			apiURL: GET_BRANDS_BY_CLIENT,
			tooltip: "This field displays 2500 brands by default. To search a brand, type the brand's name and select from the dropdown.",
			apiKey: "brand_id",
		},
		disabled: false,
		id: "brands",
		isValid: true,
		type: ELEMENT_TYPES.SELECT_INPUT,
		touched: false,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true
		},
		visible: true,
		errorMessage: "",
		value: formData.client || null,
		
		readOnly: false,
		highlightRemovedOption: false,
		apiError: "",
		highlightRemovedOptionError: "",
	};
	const Vertical_mapper_config: IFormElement = {
	
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.client || null,
		configuration: {
			dataSource: [],
			dataSourceKey: "verticals",
			multiRows: 2,
			isMulti: true,
			isVisible: true,
			placeholder: "Select Vertical(s)",
			defaultValue: [ALL_OPTION_OBJ],
			title: "Vertical(s)",
			allowAll: true,
			apiURL: GET_VERTICAL_BY_BRAND,
			parentElements: ["brands"],


		},
		id: "verticals",
		disabled: false,
		isValid: true,
		highlightRemovedOption: false,
		touched: false,
		highlightRemovedOptionError: "",
		type: ELEMENT_TYPES.SELECT_INPUT,
		readOnly: false,
		apiError: "",
	};

	const MappedBy_mapper_config: IFormElement = {
		disabled: false,
		
		id: "mappedby",
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		type: ELEMENT_TYPES.SELECT_INPUT,
		errorMessage: "",
		value: formData.mappedby || null,
		visible: true,
		configuration: {
			dataSource: [],
			dataSourceKey: "portalUsers",
			allowAll: true,
			multiRows: 2,
			isMulti: true,
			placeholder: "Select Mapped By",
			title: "Mapped By",
			defaultValue: [ALL_OPTION_OBJ],
			parentElements: [],
			isVisible: true,
		},
		highlightRemovedOption: false,
		readOnly: false,
		highlightRemovedOptionError: "",
		apiError: "",
		isValid: true,
		touched: false,
	};

	const IncludeInAdoptionStats_mapper_config: IFormElement = {
		
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		type: ELEMENT_TYPES.SELECT_INPUT,
		disabled: false,
		touched: false,
		isValid: true,
		id: "includeInAdoptionStats",
		visible: true,
		value: formData.includeInAdoptionStats || null,
		readOnly: false,
		apiError: "",
		configuration: {
			dataSource: [],
			isMulti: true,
			dataSourceKey: "includeInAdoptionStats",
			isVisible: true,
			placeholder: "Select Include In Adoption Stats",
			title: "Include in Adoption Statistics as part of Usage Dashboard",
			multiRows: 2,
			defaultValue: [ALL_OPTION_OBJ],
			parentElements: [],
			allowAll: true,
		},
		
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
	};


	return {
	groups: [{
			elements: [Status_mapper_config, Agency_mapper_config, SubAgency_mapper_config, Market_mapper_config, Client_mapper_config, Brand_mapper_config, Vertical_mapper_config, MappedBy_mapper_config, IncludeInAdoptionStats_mapper_config],
			id: "mappingMapperFilter-group1",
			title: "",
			isVisible: true,
		}],
		title: "",
		isVisible: true,
		isValid: true,
		id: "mappingMapperFilter",
		
	};
};

export const GOOGLEADS_GRID_HEADERS: IGridHeader[] = [{
	id: "id",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	visible: false,
	type: ELEMENT_TYPES.ID,
	title: "",
	sortingKey: "id",
	isMulti: false,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT,
	disableSorting: true,
	defaultSortBy: false,
	showHideText: false,
}, {
	id: "account",
	visible: true,
	defaultSortBy: true,
	title: "Account",
	isMulti: false,
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "account",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	disableSorting: false,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	showHideText: false,
	cellTwoLiner: {
		subtitleKey: "accountId",
		titleKey: "accountName",
	}
},
{
	id: "status",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	title: "Status",
	isMulti: false,
	sortingKey: "status",
	width: 80,
	disableSorting: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	statusIndicator: true,
	showHideText: false,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT
}, {
	id: "agency",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	title: "Agency / SubAgency",
	isMulti: false,
	disableSorting: false,
	sortingKey: "agency",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	showHideText: false,
	cellTwoLiner: {
		subtitleKey: "subagencyName",
		titleKey: "agencyName",
	}
},  {
	id: "client",
	visible: true,
	defaultSortBy: true,
	title: "Client / Brand",
	isMulti: false,
	sortingKey: "client",
	type: ELEMENT_TYPES.TEXT_INPUT,
	disableSorting: false,
	showHideText: true,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellTwoLiner: {
		subtitleKey: "brandName",
		titleKey: "clientName",
	}
},
{
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	id: "market",
	defaultSortBy: true,
	visible: true,
	title: "Market",
	isMulti: false,
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "market",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	disableSorting: false,
	showHideText: false,
	cellTwoLiner: {
		titleKey: "marketName",
		subtitleKey: "marketCode"
	}
},
{
    cellTwoLiner: {
		titleKey: "verticalName",
		subtitleKey: ""
	},
	id: "vertical",
	visible: true,
	defaultSortBy: true,
	title: "Vertical",
	isMulti: false,
	type: ELEMENT_TYPES.TEXT_INPUT,
	sortingKey: "vertical",
	disableSorting: false,
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	
}, {
	id: "mappedby",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	isMulti: true,
	title: "Mapped by",
	sortingKey: "mappedby",
	disableSorting: false,
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	width: 125,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLiner: {
		titleKey: "name",
		subtitleKey: "mappedDate",
		tooltip: "email"
	},
	cellTwoLinerTooltip: true,

}, {
	id: "includeInAdoptionStats",
	defaultSortBy: true,
	visible: true,
	width: 105,
	type: ELEMENT_TYPES.SWITCH,
	title: "Dashboard",
	tooltip: "Include in Adoption Statistics as part of Usage Dashboard",
	disableSorting: true,
	isMulti: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	sortingKey: "includeInAdoptionStats",
	cellStyle: GRID_CELL_ELEMENT.DEFAULT,
	showHideText: false,
}
];

export const getAccountsDropdown = (value: any, dataSource: IOption[]): IFormElement => {
	return {
		disabled: false,
		touched: false,
		isValid: false,
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		id: "accounts",
		type: ELEMENT_TYPES.SELECT_INPUT,
		highlightRemovedOptionError: "",
		readOnly: false,
		visible: true,
		errorMessage: "",
		value: value || null,
		configuration: {
			dataSource: dataSource,
			isMulti: false,
			dataSourceKey: "agencies",
			isVisible: true,
			placeholder: "Select Agency",
			parentElements: [],
			multiRows: 2,
			title: "Agency",
			defaultValue: null,
			allowAll: false,
		},
		
		highlightRemovedOption: false,
		apiError: "",
	};
};

export const UI_AUTH_HEADER_NAMES = {
	FACEBOOK: "x-portal-facebook-auth-access-token",
	GOOGLE: "x-portal-google-auth-refresh-token",
};
export const statusIndicator = (statusName: string): string => {
	const StatusConfig: IStatusIndicator | any = {
		Mapped: "Indicates that the account is mapped.",
		"Mapped*": "Indicates the account is mapped but the account mapping has discrepancy. Click the edit action to correct.",
		Inactive: "Indicates that the account is removed from the selected Manager Account.",
		Unmapped: "Indicates that the account is not mapped.",
	};
	return StatusConfig[statusName];
};
