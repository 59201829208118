import { ELEMENT_TYPES } from "./../../configuration";
import * as _ from "lodash";
import { IReadOnly } from "./../../Interfaces/Form/FormElement/IReadOnly";
import { IForm } from "./../../Interfaces/Form/IForm";
import { IFormElement } from "./../../Interfaces/Form/FormElement/IFormElement";

export class FormHelper {
	

	public getReadOnlyValues = (
		formElement: IFormElement,
		value: any
	): IReadOnly => {
		let readOnlyData: IReadOnly = {title: "",subtitle: ""};
		if (_.isUndefined(formElement.readOnlyOptions)) {
			return readOnlyData;
		}
		if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)) {
			if (!_.isNull(value) && !_.isUndefined(value)) {
				if (!_.isNull(formElement.readOnlyOptions?.keys.title)) {
					readOnlyData = {
						...readOnlyData,title: value[formElement.readOnlyOptions?.keys.title]
					};
				}
				if (!_.isNull(formElement.readOnlyOptions?.keys.subtitle)) {
					readOnlyData = {
						...readOnlyData,subtitle: value[formElement.readOnlyOptions?.keys.subtitle]
					};
				}
			}
		} else {
			readOnlyData = {...readOnlyData,title: value || ""};
		}
		return readOnlyData;
	};

	public ifHasChildElements = (form: IForm, formElement: IFormElement): boolean => {
		const hasChildElements = false;
		for (const group of form.groups) {
			for (const element of group.elements) {
				if (!_.isEqual(element.configuration.parentElements.indexOf(formElement.id), -1)) {
					return true;
				}
			}
		}
		return hasChildElements;
	}


}
