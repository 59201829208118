import { ELEMENT_TYPES, ALL_OPTION_OBJ } from "./configuration";
import { IFormElement } from "./Interfaces/Form/FormElement/IFormElement";
import { IForm } from "./Interfaces/Form/IForm";

export const getHistoryFilterFormConfig = (formData: any): IForm => {
    const ToolName: IFormElement = {
        id: "toolName",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.SELECT_INPUT,
        validations: {
            isEmail: false,
            isNumber: false,
            required: true
        },
        errorMessage: "",
        visible: true,
        value: formData.toolName || null,
        configuration: {
            dataSourceKey: "toolName",
            dataSource: [],
            isMulti: true,
            isVisible: true,
            multiRows: 2,
            placeholder: "Select Tool Name - Version",
            title: "Tool Name(s)",
            defaultValue: [ALL_OPTION_OBJ],
            allowAll: true,
            parentElements: [],

        },
        readOnly: false,
        apiError: "",
        highlightRemovedOptionError: "",
        highlightRemovedOption: false
    };

    const JobName: IFormElement = {
        id: "jobName",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.SELECT_INPUT,
        validations: {
            isEmail: false,
            isNumber: false,
            required: true
        },
        errorMessage: "",
        visible: true,
        value: formData.jobName || null,
        configuration: {
            dataSourceKey: "jobName",
            dataSource: [],
            isMulti: true,
            isVisible: true,
            multiRows: 2,
            placeholder: "Select Job Name - Job ID",
            title: "Job Name(s)",
            defaultValue: [ALL_OPTION_OBJ],
            allowAll: true,
            parentElements: [],
            apiURL: "",
            tooltip: "If a Job Name / Job ID is not available (NA) for a selected date range, then this field will display the \"All\" option only."
        },
        readOnly: false,
        apiError: "",
        highlightRemovedOptionError: "",
        highlightRemovedOption: false
    };

    const Status: IFormElement = {
        id: "status",
        disabled: false,
        isValid: true,
        touched: false,
        type: ELEMENT_TYPES.SELECT_INPUT,
        validations: {
            isEmail: false,
            isNumber: false,
            required: true
        },
        errorMessage: "",
        visible: true,
        value: formData.status || null,
        configuration: {
            dataSourceKey: "status",
            dataSource: [],
            isMulti: true,
            isVisible: true,
            multiRows: 2,
            placeholder: "Select Status",
            title: "Status(s)",
            defaultValue: [ALL_OPTION_OBJ],
            allowAll: true,
            parentElements: [],
        },
        readOnly: false,
        apiError: "",
        highlightRemovedOptionError: "",
        highlightRemovedOption: false
    };



    return {
        id: "historyFilter",
        title: "",
        isValid: true,
        isVisible: true,
        groups: [{
            id: "historyFilter-group1",
            elements: [ToolName, JobName, Status],
            isVisible: true,
            title: ""
        }]
    };
};


