import * as _ from "lodash";

import { IFormElement } from "../../Forms/interface/IFormElement";

export const getErrorMessage = (formElement: IFormElement): string =>{
	let errorMessage = formElement.touched ? formElement.error : "";
	if(!_.isEmpty(formElement.apiError)){
		errorMessage = formElement.apiError;
	}
	const HighlightOptionError = formElement.highlight_option_error;
	if (formElement.disabled) {
	  errorMessage = "";
	} else if (!_.isEqual(HighlightOptionError.length, 0)) {
	  errorMessage = HighlightOptionError;
	}
	
	return errorMessage;
};

export const getElementValue = (formElement: IFormElement): any =>{
	return _.isNull(formElement.value) ||
    _.isEqual(formElement.value, 1) ||
    _.isObject(formElement.value)
      ? ""
      : formElement.value;
};