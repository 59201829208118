
// Get Filter Options action types
export const GET_FILTER_OPTIONS_REQUEST = "GET_FILTER_OPTIONS_REQUEST";
export const GET_FILTER_OPTIONS_SUCCESS = "GET_FILTER_OPTIONS_SUCCESS";
export const GET_FILTER_OPTIONS_FAILED = "GET_FILTER_OPTIONS_FAILED";


// Get Tool List action types
export const GET_TOOL_LIST_REQUEST = "GET_TOOL_LIST_REQUEST";
export const GET_TOOL_LIST_SUCCESS = "GET_TOOL_LIST_SUCCESS";
export const GET_TOOL_LIST_FAILED = "GET_TOOL_LIST_FAILED";
export const RESET_TOOL_LIST = "RESET_TOOL_LIST";
export const UPDATE_INITIAL_STATE = "UPDATE_INITIAL_STATE";
export const UPDATE_TOOL_LIST = "UPDATE_TOOL_LIST";

// Get Initial Data
export const INITIAL_DATA_REQUEST = "INITIAL_DATA_REQUEST";
export const INITIAL_DATA_SUCCESS = "INITIAL_DATA_SUCCESS";
export const INITIAL_DATA_FAILED = "INITIAL_DATA_FAILED";
export const EMBED_CASE = "EMBED_CASE";
