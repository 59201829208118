import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import * as _ from "lodash";

// Handles user profile redirection
export default (WrappedComponent: any): any => {
  class withProfileRedirectHOC extends Component<any> {
    constructor(props: any) {
      super(props);
    }
    render(): JSX.Element {
      const isExternalUser = this.props.initial.isExternalUser;
      if (
        !_.isNull(this.props.userInfo) &&
        _.isEqual(this.props.userInfo.isValidProfile, 0) &&
        !isExternalUser
      ) {
        return <Redirect to="/required-info" />;
      }
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToProps = (state: any): any => ({
    bundle: state.initial.bundle,
    userInfo: state.initial.userInfo,
    initial: state.initial,
  });
  return connect(mapStateToProps, null)(withRouter(withProfileRedirectHOC));
};
