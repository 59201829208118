import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import style from "./style";
import UITooltip from "../../../Elements/Tooltip";

type props = {
  data: string;
};

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const SingleLinerTextElement = (props: props): React.JSX.Element => {
  const { data } = props;
  const [hoverStatus, setHover] = useState(false);
  const classes = useStyles();
  const textElementRef: any = useRef();

  const compareSize = (): void => {
    if (_.isNull(textElementRef) || _.isUndefined(textElementRef.current)) {
      return;
    }
    const compare = _.gt(
      textElementRef.current.scrollWidth,
      textElementRef.current.clientWidth
    );
    setHover(compare);
  };
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  return (
    <div>
      <UITooltip title={data || ""} disableTooltip={!hoverStatus}>
        <span ref={textElementRef} className={classes.singleLineContainer}>
          {data || ""}
        </span>
      </UITooltip>
    </div>
  );
};
