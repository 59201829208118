import { FORM_ELEMENT_TYPES } from "../../../../components/UserProfile/Form/ElementTypes";
import { IFormElement } from "../../../../components/UserProfile/Interface/FormElement/IFormElement";

// Agency
export const Agency: IFormElement = {
	id: "agencyid",
	configuration: {
		dataSource: [],
		isMulti: false,
		note: "",
		tooltip: "This information is automatically populated based on your email address.",
		title: "Agency",
		hideLabel: false,
		removeLabelHeight: false,
		gridColumns: 6,
		placeholder:"Select Agency",
		inlineElement: false,
		hideOptionalText: false,
		dependsOn: [],
		parentElements:[],
		dependentValue: "",
		disabledBasedOn: [],
		disabledBasedOnValue: ""
	},
	disabled: false,
	readonly: false,
	errorMessage: "",
	isValid: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations: {
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: "",
	visible: true,
	touched: false,
	loading: false
};

// SubAgency
export const SubAgency: IFormElement = {
	id: "subagencyid",
	configuration: {
		dataSource: [],
		isMulti: false,
		note: "",
		tooltip: "This information is automatically populated based on your email address.",
		title: "Sub-agency",
		hideLabel: false,
		removeLabelHeight: false,
		gridColumns: 6,
		placeholder:"Select Sub-agency",
		inlineElement: false,
		hideOptionalText: false,
		dependsOn: [],
		parentElements:["agencyid"],
		dependentValue: "",
		disabledBasedOn: [],
		disabledBasedOnValue: "",
		apiURL:"/api/subagencies/",
		apiKey:"agencyid"
	},
	disabled: false,
	readonly: false,
	errorMessage: "",
	isValid: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations: {
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: "",
	visible: true,
	touched: false,
	loading: false
};

/// Channels Column
export const Channels: IFormElement = {
	id:"channel",
	configuration:{
		dataSource:[],
		isMulti: true,
		selectMetadata:{
			label:"label",
			value:"value"
		},
		note:"",
		hideLabel: false,
		parentElements:[],
		removeLabelHeight: false,
		inlineElement: false,
		tooltip: "Select the activation channel you work in.",
		title:"Channel",
		gridColumns:6,
		placeholder: "Select Channel",
		hideOptionalText: false,
		dependsOn:[],
		dependentValue:"",
		disabledBasedOn: ["workForActivation"],
		disabledBasedOnValue: false
	},
	disabled: false,
	errorMessage:"",
	isValid: false,
	readonly: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations:{
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: "",
	visible: true,
	touched: false,
	loading: false
};

// ToolType Column
export const OfficeLocation: IFormElement = {
	id:"officeLocation",
	configuration:{
		dataSource:[],
		isMulti: false,
		parentElements:[],
		note:"",
		tooltip: "Select your official office location.",
		hideLabel: false,
		removeLabelHeight: false,
		inlineElement: false,
		title:"Office Location",
		gridColumns:6,
		placeholder:"Select Office Location",
		hideOptionalText: false,
		dependsOn:[],
		dependentValue:"",
		disabledBasedOn: [],
		disabledBasedOnValue:"",
		isFullWidth: true
	},
	disabled: false,
	errorMessage:"",
	isValid: false,
	readonly: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations:{
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: "",
	visible: true,
	touched: false,
	loading: false
};

//IsActive Column
export const WorkForActivation: IFormElement = {
	id:"workForActivation",
	configuration:{
		dataSource:[],
		isMulti: false,
		note:"",
		tooltip: "Select \"Works for Activation\" if you work in a local, regional or offshore activation team.",
		parentElements:[],
		hideLabel: false,
		removeLabelHeight: false,
		inlineElement: false,
		title:"Works for Activation",
		gridColumns:6,
		placeholder:"",
		hideOptionalText: false,
		defaultValue: true,
		dependsOn:[],
		dependentValue:"",
		disabledBasedOn: [],
		disabledBasedOnValue:"",
	},
	disabled: false,
	readonly: false,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations:{
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: true,
	visible: true,
	touched: false,
	loading: false
};

//WorksForHub Column
export const WorkForHub: IFormElement = {
	id: "workForHub",
	configuration: {
		dataSource: [],
		isMulti: false,
		note: "",
		tooltip: "Select \"Works for Hub\" if you work in one of the Global Offshore teams.",
		title: "Works for Hub",
		hideLabel: false,
		removeLabelHeight: false,
		gridColumns: 6,
		placeholder: "",
		inlineElement: false,
		hideOptionalText: false,
		defaultValue: true,
		dependsOn: [],
		parentElements:[],
		dependentValue: "",
		disabledBasedOn: [],
		disabledBasedOnValue: "",
	},
	disabled: false,
	readonly: false,
	errorMessage: "",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations: {
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: true,
	visible: true,
	touched: false,
	loading: false
};

// HubLocation Column
export const HubLocation: IFormElement = {
	id:"hubLocation",
	configuration:{
		dataSource:[],
		isMulti: false,
		note:"",
		tooltip:"Only if you work in one of the Global Offshore Hubs select the offshore location you are working in.",
		hideLabel: false,
		removeLabelHeight: false,
		parentElements:[],
		inlineElement: false,
		title:"Hub Location",
		gridColumns:6,
		placeholder:"Select a Location",
		hideOptionalText: false,
		dependsOn: [],
		dependentValue: "",
		disabledBasedOn: ["workForHub"],
		disabledBasedOnValue: false
	},
	disabled: false,
	readonly: false,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations:{
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: null,
	visible: true,
	touched: false,
	loading: false
};













































