import {
	ADD_ERROR,
	REMOVE_ERROR
} from "../types/Error";

export const addError = (error:string): any => ({
	type: ADD_ERROR,
	payload: error
});

export const removeError = (): any => ({
	type: REMOVE_ERROR
});