import React from "react";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import style from "./style";
import { IFormElement } from "../../../../Interface/FormElement/IFormElement";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
  errorMessage: string;
};

// UIErrorOrNoteMessage Component
export const UIErrorOrNoteMessage = (props: props): React.JSX.Element => {
  const { errorMessage, formElement } = props;
  const classes = useStyles();
  const Error = (errorMessage || "").toString().trim();
  const Note = (formElement.configuration.note || "").toString().trim();

  if (_.isEqual(Error.length, 0)) {
    return (
      <div
        className={classes.note}
        dangerouslySetInnerHTML={{
          __html: Note,
        }}
      ></div>
    );
  }
  return (
    <div
      className={classes.error}
    >
      {Error}
    </div>
  );
};
