import * as _ from "lodash";
import { Axios } from "../../../../../../api/service/Axios";
import { IFormElementValue } from "../../../../../../UI/Elements/Forms/interface/IFormElementValue";
import { IOption } from "../../../../../../UI/Elements/Forms/interface/IOption";
import {
  ALL_OPTION_OBJ,
  ALL_OPTION_VALUE,
  ELEMENT_TYPES,
  STATUS_TYPE
} from "../../../../configuration";
import { IFormElement } from "../../../../Interfaces/Form/FormElement/IFormElement";
import { IFormElementUpdate } from "../../../../Interfaces/Form/FormElement/IFormElementUpdate";
import { IForm } from "../../../../Interfaces/Form/IForm";
import { IFormGroup } from "../../../../Interfaces/Form/IFormGroup";
import { FormElementHelper } from "../Element";
import { UtilHelper } from "../Utility";
import { ValidationHelper } from "../Validations";
import { FormHelper } from "./Helper";
import { MultiSelectHelper } from "./SelectInput/MultiSelect";
import { SingleSelectHelper } from "./SelectInput/SingleSelect";

// Edit form class
export class EditFormHelper {
  private validationHelper: ValidationHelper;
  private elementHelper: FormElementHelper;
  private utilHelper: UtilHelper;
  private multiSelect: MultiSelectHelper;
  private singleSelect: SingleSelectHelper;

  constructor() {
    this.validationHelper = new ValidationHelper();
    this.utilHelper = new UtilHelper();
    this.elementHelper = new FormElementHelper();
    this.singleSelect = new SingleSelectHelper();
    this.multiSelect = new MultiSelectHelper();
  }

  public updateElementDataSource(
    form: IForm,
    element: IFormElement,
    option: IOption
  ): IForm {
    const validationHelper = new ValidationHelper();
    let updatedForm = {
      ...form,
      groups: form.groups.map((formGroup: IFormGroup) => {
        return {
          ...formGroup,
          elements: formGroup.elements.map((formElement: IFormElement) => {
            if (
              _.isEqual(formElement.id, element.id) &&
              _.isEqual(ELEMENT_TYPES.SELECT_INPUT, element.type)
            ) {
              const ErrorMessage = validationHelper.validate(
                formElement,
                option
              );
              let updatedDataSource = [
                ...formElement.configuration.dataSource,
                option,
              ];
              updatedDataSource = _.orderBy(
                updatedDataSource,
                [(op: IOption) => op.label.toLowerCase()],
                ["asc"]
              );

              return {
                ...formElement,
                configuration: {
                  ...formElement.configuration,
                  dataSource: updatedDataSource,
                },
                value: option,
                isValid: _.isEqual(ErrorMessage.length, 0),
                errorMessage: ErrorMessage,
              };
            }
            return {
              ...formElement,
            };
          }),
        };
      }),
    };
    updatedForm = validationHelper.isValidForm(updatedForm);
    return updatedForm;
  }

  public updateFormElementDataSource(
    form: IForm,
    id: string,
    dataSource: IOption[]
  ): IForm {
    const validationHelper = new ValidationHelper();
    let updatedForm = {
      ...form,
      groups: form.groups.map((formGroup: IFormGroup) => {
        return {
          ...formGroup,
          elements: formGroup.elements.map((formElement: IFormElement) => {
            if (
              _.isEqual(formElement.id, id) &&
              _.isEqual(ELEMENT_TYPES.SELECT_INPUT, formElement.type)
            ) {
              const DataSource = dataSource || [];
              if (
                formElement.configuration.allowAll &&
                !_.isEqual(DataSource.length, 0)
              ) {
                DataSource.unshift(ALL_OPTION_OBJ);
              }
              return {
                ...formElement,
                configuration: {
                  ...formElement.configuration,
                  dataSource: DataSource,
                },
              };
            }
            return {
              ...formElement,
            };
          }),
        };
      }),
    };
    updatedForm = validationHelper.isValidForm(updatedForm);
    return updatedForm;
  }

  /**
   * Update the form
   *
   * @param {IForm[]} forms.
   * @param {IElementUpdate} element.
   * @param {boolean} isDelayed DEFAULT false. - Validate the field when use stop typing
   * @return {IForm[]} forms.
   */
  public updateBulkEditForm = async (
    form: IForm,
    status: any
  ): Promise<IForm> => {
    const validationHelper = new ValidationHelper();
    if (_.isEqual(status, STATUS_TYPE.UNMAPPED)) {
      const FormGroups = [];
      for (const formGroup of form.groups) {
        const FormElementArray = [];
        for (const formElement of formGroup.elements) {
          const Element = await this.updateBulkElement(formElement);
          FormElementArray.push(Element);
        }
        FormGroups.push({
          ...formGroup,
          elements: FormElementArray,
        });
      }
      let updatedForm = {
        ...form,
        groups: FormGroups,
      };
      updatedForm = validationHelper.isValidForm(updatedForm);
      return updatedForm;
    } else {
      let updatedForm = this.updateStatusWiseBulkEditForm(form, false);
      updatedForm = validationHelper.isValidBulkEditForm(updatedForm);
      return updatedForm;
    }
  };

  public updateStatusWiseBulkEditForm = (
    form: IForm,
    OnChangeFlag: boolean
  ): IForm => {
    let isGroup1Valid = false;
    let isGroup2Valid = false;
    let isGroup3Valid = false;
    let isGroup4Valid = false;

    const FormGroups = [];
    for (const formGroup of form.groups) {
      const FormElementArray = [];
      for (const formElement of formGroup.elements) {
        switch (formElement.groupCategory) {
          case "Group1":
            {
              if (
                formElement.validations.required &&
                !_.isNull(formElement.value)
              ) {
                isGroup1Valid = true;
              }
            }
            break;
          case "Group2":
            {
              if (
                formElement.validations.required &&
                !_.isNull(formElement.value)
              ) {
                isGroup2Valid = true;
              }
            }
            break;
          case "Group3":
            {
              if (
                formElement.validations.required &&
                !_.isNull(formElement.value)
              ) {
                isGroup3Valid = true;
              }
            }
            break;
          case "Group4":
            {
              if (
                formElement.validations.required &&
                !_.isNull(formElement.value) &&
                formElement.touched
              ) {
                isGroup4Valid = true;
              }
            }
            break;
        }
      }

      for (const formElement of formGroup.elements) {
        let errorMessage = "";
        const ELEMENT_CONFIG = formElement.configuration;
        let updatedElement = {
          ...formElement,
        };
        if (
          !isGroup1Valid &&
          !isGroup2Valid &&
          !isGroup3Valid &&
          !isGroup4Valid
        ) {
          if (
            _.isNull(formElement.value) &&
            _.isEqual(formElement.disabled, false) &&
            !_.isEqual(formElement.readOnly, true) &&
            _.isEqual(formElement.validations.required, true)
          ) {
            errorMessage = `${ELEMENT_CONFIG.title} is required.`;
          }
        }
        if (_.isEqual(formElement.groupCategory, "Group3")) {
          if (
            formElement.validations.required &&
            _.isNull(formElement.value) &&
            _.isEqual(formElement.disabled, false) &&
            !_.isEmpty(formElement.configuration.parentElements)
          ) {
            errorMessage = `${ELEMENT_CONFIG.title} is required.`;
          }
        }
        const IsValid = _.isEqual((errorMessage || "").length, 0);
        if (OnChangeFlag) {
          updatedElement = {
            ...updatedElement,
            errorMessage: errorMessage,
            isValid: IsValid,
          };
        } else {
          updatedElement = {
            ...updatedElement,
            errorMessage: errorMessage,
            isValid: IsValid,
            touched: _.isEmpty(errorMessage) ? false : true,
          };
        }
        FormElementArray.push(updatedElement);
      }
      FormGroups.push({
        ...formGroup,
        elements: FormElementArray,
      });
    }
    const updatedForm = {
      ...form,
      groups: FormGroups,
    };
    return updatedForm;
  };

  public updateForm = async (
    form: IForm,
    element: IFormElementUpdate,
    elementValue: IFormElementValue
  ): Promise<IForm> => {
    const validationHelper = new ValidationHelper();
    const FormGroups = [];
    for (const formGroup of form.groups) {
      const FormElementArray = [];
      for (const formElement of formGroup.elements) {
        const Element = await this.updateElement(
          formElement,
          element,
          elementValue
        );
        FormElementArray.push(Element);
      }
      FormGroups.push({
        ...formGroup,
        elements: FormElementArray,
      });
    }
    let updatedForm = {
      ...form,
      groups: FormGroups,
    };
    updatedForm = validationHelper.isValidForm(updatedForm);
    return updatedForm;
  };

  /**
   * Update the element
   *
   * @param {IFormElement} formElement.
   * @param {IElementUpdate} element.
   * @param {boolean} isDelayed DEFAULT false.
   * @return {IForm[]} forms.
   */
  private updateBulkElement = async (
    formElement: IFormElement
  ): Promise<IFormElement> => {
    let updatedElement = {
      ...formElement,
    };
    const validationHelper = new ValidationHelper();
    const ErrorMessage = validationHelper.validateBulkEdit(formElement);

    const IsValid = _.isEqual((ErrorMessage || "").length, 0);

    updatedElement = {
      ...updatedElement,
      errorMessage: ErrorMessage,
      isValid: IsValid,
      touched: _.isEmpty(ErrorMessage) ? false : true,
    };
    return {
      ...updatedElement,
    };
  };

  private updateElement = async (
    formElement: IFormElement,
    element: IFormElementUpdate,
    elementValue: IFormElementValue
  ): Promise<IFormElement> => {
    let updatedElement = {
      ...formElement,
    };

    if (_.isEqual(formElement.id, element.formElement.id)) {
      let value = element.value;

      if (_.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT)) {
        value = this.updateSelectValue(formElement, value);
      }

      const validationHelper = new ValidationHelper();
      const ErrorMessage = validationHelper.validate(formElement, value);
      const IsValid = _.isEqual((ErrorMessage || "").length, 0);

      updatedElement = {
        ...updatedElement,
        touched: true,
        value,
        errorMessage: ErrorMessage,
        isValid: IsValid,
      };
    } else if (
      _.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT) &&
      !this.validationHelper.isPreviousAndCurrentValueAreSame(
        element.formElement,
        elementValue
      ) &&
      !_.isEqual(
        formElement.configuration.parentElements.indexOf(
          element.formElement.id
        ),
        -1
      )
    ) {
      let errorMessage = "";
      if (formElement.validations.required) {
        errorMessage = this.validationHelper.validate(formElement, null);
      }

      let elementVal = null;

      if (
        !_.isUndefined(formElement.configuration.selectValueIfEmpty) &&
        _.isNull(element.value)
      ) {
        elementVal = formElement.configuration.selectValueIfEmpty.value;
      }

      updatedElement = {
        ...formElement,
        value: elementVal,
        touched: false,
        errorMessage,
        isValid: _.isEqual(errorMessage.length, 0),
      };
    } else if (
      _.isEqual(formElement.type, ELEMENT_TYPES.SELECT_INPUT) &&
      !_.isUndefined(formElement.configuration.selectValueIfEmpty) &&
      _.isNull(formElement.value)
    ) {
      updatedElement = {
        ...formElement,
        value: formElement.configuration.selectValueIfEmpty.value,
      };
    }
    return {
      ...updatedElement,
    };
  };

  public async buildEditForm(
    form: any,
    dataSources: any,
    formData: any
  ): Promise<IForm> {
    let editForm: IForm = this.buildForm(form, dataSources);
    const Groups = editForm.groups;

    for (let iFormGroup = 0; iFormGroup < Groups.length; iFormGroup++) {
      const FormElements = Groups[iFormGroup].elements;
      for (
        let iFormElement = 0;
        iFormElement < FormElements.length;
        iFormElement++
      ) {
        const FormElement: IFormElement = FormElements[iFormElement];
        let element: IFormElement = await this.buildEditFormElement(
          editForm,
          FormElement,
          formData,
          dataSources
        );
        const _validationHelper = new ValidationHelper();
        const ErrorMessage = _validationHelper.validate(
          FormElement,
          element.value
        );
        const IsValid =
          _.isEqual((ErrorMessage || "").length, 0) &&
          _.isEqual((element.highlightRemovedOptionError || "").length, 0);

        const formHelper = new FormHelper();
        if (!_.isUndefined(element.configuration.readOnly)) {
          const ReadOnlyValues: any = formHelper.getReadOnlyValues(
            FormElement,
            element.value
          );

          element = {
            ...element,
            readOnlyOptions: {
              ...element.readOnlyOptions,
              ...ReadOnlyValues,
            },
          };
        }
        FormElements[iFormElement] = {
          ...element,
          errorMessage: ErrorMessage,
          isValid: IsValid,
          touched: false,
          readOnly: !_.isUndefined(element.configuration.readOnly)
            ? element.configuration.readOnly.editForm
            : element.readOnly,
        };
      }

      Groups[iFormGroup].elements = FormElements;
    }

    editForm.groups = Groups;

    editForm = await this.validateParentChildRelationship(editForm);
    const validationHelper = new ValidationHelper();
    editForm = validationHelper.isValidForm(editForm);
    return editForm;
  }

  public buildForm(form: IForm, dataSources: any): IForm {
    return {
      ...form,
      groups: form.groups.map((formGroup: IFormGroup) => {
        return {
          ...formGroup,
          elements: formGroup.elements.map((formElement: IFormElement) => {
            const element = this.buildNewEditFormElement(
              formElement,
              dataSources
            );

            return {
              ...element,
              isValid: element.validations.required ? element.isValid : true,
              touched: false,
              readOnly: !_.isUndefined(element.configuration.readOnly)
                ? element.configuration.readOnly.newForm
                : element.readOnly,
            };
          }),
        };
      }),
    };
  }

  public buildNewEditFormElement = (
    formElement: IFormElement,
    dataSources: any
  ): IFormElement => {
    let element: any = { ...formElement };
    const DefaultValue = formElement.configuration.defaultValue;

    const DATA_SOURCES: any = {};
    for (const key in dataSources) {
      DATA_SOURCES[key] = dataSources[key].map((option: any) => ({
        ...option,
      }));
    }

    switch (formElement.type) {
      case ELEMENT_TYPES.SELECT_INPUT: {
        const DataSource = _.isUndefined(
          DATA_SOURCES[formElement.configuration.dataSourceKey]
        )
          ? []
          : DATA_SOURCES[formElement.configuration.dataSourceKey];

        if (Array.isArray(DataSource)) {
          if (
            element.configuration.allowAll &&
            !_.isEqual(DataSource.length, 0)
          ) {
            DataSource.unshift(ALL_OPTION_OBJ);
          }
        }

        element = {
          ...formElement,
          configuration: {
            ...formElement.configuration,
            dataSource: DataSource,
          },
          value: DefaultValue || null,
        };
        break;
      }
      case ELEMENT_TYPES.SWITCH:
        element = {
          ...formElement,
          value: DefaultValue || true,
        };
        break;
      default:
        element = {
          ...formElement,
          value: DefaultValue || "",
        };
        break;
    }
    return {
      ...element,
    };
  };

  public buildEditFormElement = async (
    form: IForm,
    formElement: IFormElement,
    formData: any,
    dataSources: any
  ): Promise<IFormElement> => {
    let element: IFormElement = { ...formElement };

    switch (formElement.type) {
      case ELEMENT_TYPES.SELECT_INPUT: {
        if (!_.isUndefined(formElement.configuration.selectValueIfEmpty)) {
          const Option = (
            dataSources[formElement.configuration.dataSourceKey] || []
          ).find((option: IOption) =>
            _.isEqual(
              formElement.configuration.selectValueIfEmpty?.valueLabelKey,
              option.label
            )
          );

          element = {
            ...element,
            configuration: {
              ...element.configuration,
              selectValueIfEmpty: {
                valueLabelKey:
                  formElement.configuration.selectValueIfEmpty.valueLabelKey,
                value: Option,
              },
            },
          };
        }

        element = {
          ...element,
          value: _.isEmpty(element.configuration.parentElements)
            ? await this.getSelectValue(form, element, formData)
            : formData[element.id],
        };

        break;
      }
      case ELEMENT_TYPES.SWITCH:
        element = {
          ...formElement,
          value: _.isEqual(formData[formElement.id], 1),
        };
        break;
      case ELEMENT_TYPES.DISPLAY_ACCOUNT:
        if (_.isEqual(formData.status, "Mapped*")) {
          element = this.getAccountInformation(
            formElement,
            formData,
            dataSources
          );
          break;
        }
        element = {
          ...formElement,
          value: formData[formElement.id],
        };
        break;
      default:
        element = {
          ...formElement,
          value: formData[formElement.id],
        };
        break;
    }

    return {
      ...element,
    };
  };

  private findValueInArray = (array: IOption[], value: any): boolean => {
    if (_.isNull(value)) {
      return true;
    }

    const IsAgencyExists = array.find((option: IOption) => {
      const OptionValue = (option.value || "").toString().trim().toLowerCase();
      const Value = (value.value || "").toString().trim().toLowerCase();
      return _.isEqual(OptionValue, Value);
    });
    return !_.isUndefined(IsAgencyExists);
  };

  private getAccountInformation = (
    formElement: IFormElement,
    formData: any,
    dataSources: any
  ): IFormElement => {
    const element = { ...formElement };
    const AssignedAgencies = dataSources.agencies || [];
    const AssignedMarkets = dataSources.markets || [];

    const IsAgencyExists = this.findValueInArray(
      AssignedAgencies,
      formData.agency
    );
    const IsMarketExists = this.findValueInArray(
      AssignedMarkets,
      formData.market
    );

    let agencyMarketPlaceholder = "";

    if (!IsMarketExists) {
      agencyMarketPlaceholder =
        "<br/>Current mapped market needs to be updated. Please select the new one.";
    }

    if (!IsAgencyExists) {
      agencyMarketPlaceholder =
        "<br/>Current mapped agency needs to be updated. Please select the new one.";
    }

    if (!IsAgencyExists && !IsMarketExists) {
      agencyMarketPlaceholder =
        "<br/>Current mapped agency and market need to be updated. Please select the new one.";
    }

    const AgencyName = _.isNull(formData.agency) ? "" : formData.agency.label;
    const MarketName = _.isNull(formData.market) ? "" : formData.market.label;

    const MappedBy = (formData.mappedBy || "").toString().trim();
    const MappedByMessage = _.isEmpty(MappedBy)
      ? "This account is already mapped."
      : `This account is already mapped by ${MappedBy}.`;
    const ManagerName = formElement.notification?.managerName || "";
    const ManagerId = formElement.notification?.managerId || "";
    return {
      ...element,
      value: formData[formElement.id],
      showNotification: true,
      notification: {
        mappedByMessage: MappedByMessage,
        label: formElement.notification?.label || "",
        value:
          `${formData.manager[ManagerName]} (${formData.manager[ManagerId]})` ||
          "",
        agencyName: AgencyName,
        marketName: MarketName,
        agencyMarketPlaceholder,
        mappedDate: formData.date || "",
      },
    };
  };

  private getQueryParamsForAsync = (
    formElement: IFormElement,
    formData: any
  ): any => {
    const EleValue = !_.isUndefined(formData[formElement.id])
      ? formData[formElement.id]
      : null;

    if (formElement.configuration.isMulti) {
      const ElementValue = _.isArray(EleValue) ? EleValue : null;

      if (_.isNull(ElementValue)) {
        return null;
      }

      const QueryParams: string[] = [];
      ElementValue.forEach((option: IOption) => {
        const Label = (option.label || "").toString().trim();
        QueryParams.push(
          `${formElement.configuration.asyncRequestKey}=${encodeURIComponent(
            Label
          )}`
        );
      });
      return QueryParams.join("&");
    }

    const ElementValue: any = _.isObject(EleValue) ? EleValue : null;

    if (_.isNull(ElementValue)) {
      return null;
    }
    const Label = (ElementValue.label || "").toString().trim();

    return `${formElement.configuration.asyncRequestKey}=${encodeURIComponent(
      Label
    )}`;
  };

  private getSelectValue = async (
    form: IForm,
    formElement: IFormElement,
    formData: any
  ): Promise<any> => {
    if (_.isNull(formData[formElement.id])) {
      return null;
    }

    let element = { ...formElement };

    if (formElement.configuration.isAsyncSelect) {
      let DataSource = element.configuration.dataSource;
      const ParentElements = formElement.configuration.parentElements;
      const GETParams = this.elementHelper.getParentValuesForGETRequest(
        form,
        ParentElements
      );
      const QueryParams = _.isEqual(GETParams.length, 0) ? "" : `?${GETParams}`;
      const AsyncQueryParams = this.getQueryParamsForAsync(element, formData);

      let URL = `${formElement.configuration.apiURL}${QueryParams}`;

      if (!_.isNull(AsyncQueryParams)) {
        URL = _.isEqual(GETParams.length, 0)
          ? `${URL}?${AsyncQueryParams}`
          : `${URL}&${AsyncQueryParams}`;
        const Data = await Axios.get(URL);
        DataSource = _.uniqBy(
          _.orderBy(
            _.concat(DataSource, _.isArray(Data.data) ? Data.data : []),
            ["label"],
            ["asc"]
          ),
          (option: IOption) => option.value
        );

        element = {
          ...element,
          configuration: {
            ...element.configuration,
            dataSource: DataSource,
          },
        };
      }
    }

    if (formElement.configuration.isMulti) {
      return this.getMultiSelectValue(element, formData);
    }
    return this.getSingleSelectValue(element, formData);
  };

  getMultiSelectValue = (
    formElement: IFormElement,
    formData: any
  ): IOption[] | null => {
    return formData[formElement.id]
      .map((valOption: IOption) => {
        return formElement.configuration.dataSource.find((option: IOption) =>
          _.isEqual(valOption.value, option.value)
        );
      })
      .filter((valOption: IOption) => !_.isUndefined(valOption));
  };

  getSingleSelectValue = (
    formElement: IFormElement,
    formData: any
  ): IOption | null => {
    if (
      !_.isUndefined(formElement.configuration.readOnly) &&
      formElement.configuration.readOnly.editForm
    ) {
      return formData[formElement.id];
    }

    if (formElement.highlightRemovedOption) {
      const DataSourceOptions = formElement.configuration.dataSource.map(
        (option: any) => option.value.toString().trim()
      );

      const RemovedOptionIndex: number = DataSourceOptions.indexOf(
        (formData[formElement.id].value || "").toString().trim()
      );

      if (_.isEqual(RemovedOptionIndex, -1)) {
        return _.isNull(formData[formElement.id])
          ? null
          : { ...formData[formElement.id], highlightOption: true };
      }
    }

    const ElementValue = formElement.configuration.dataSource.find(
      (option: IOption) =>
        _.isEqual(option.value, formData[formElement.id].value)
    );

    return _.isUndefined(ElementValue) ? null : ElementValue;
  };

  updateSelectValue = (formElement: IFormElement, value: any): any => {
    const { isMulti, allowAll } = formElement.configuration;

    if (isMulti && allowAll) {
      const Value = value || [];
      const IsAllOptionSelected = Value.findIndex((option: IOption) => {
        return _.isEqual(option.value, ALL_OPTION_VALUE);
      });

      if (
        _.isEqual(IsAllOptionSelected, Value.length - 1) &&
        _.gt(Value.length, 1)
      ) {
        return [ALL_OPTION_OBJ];
      } else if (!_.isEqual(IsAllOptionSelected, -1) && _.gt(Value.length, 1)) {
        return Value.filter(
          (option: IOption) => !_.isEqual(option.value, ALL_OPTION_VALUE)
        );
      }
    }
    return value;
  };

  private getAPIData = async (
    updatedForm: IForm,
    element: IFormElement
  ): Promise<any> => {
    const Element = { ...element };

    try {
      const APIData = await this.elementHelper.getAPIData(updatedForm, Element);

      const DataSource = _.isArray(APIData) ? APIData : [];

      if (Element.configuration.allowAll && !_.isEqual(DataSource.length, 0)) {
        DataSource.unshift(ALL_OPTION_OBJ);
      }
      Element.configuration.dataSource = DataSource;

      if (
        _.isEqual(Element.type, ELEMENT_TYPES.SELECT_INPUT) &&
        Element.configuration.readOnly
      ) {
        Element.value = DataSource[0];
      }
      return Element;
    } catch (error: any) {
      Element.apiError = this.utilHelper.getErrorMessage(error);
      Element.configuration.dataSource = [];
      return Element;
    }
  };

  private ifParentNotEmpty = async (
    UpdatedForm: IForm,
    parentElements: any,
    formElement: IFormElement
  ): Promise<any> => {
    const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(
      UpdatedForm,
      parentElements
    );

    let Element = { ...formElement };

    const isDisabled = IsAnyParentEmpty;
    if (
      !IsAnyParentEmpty &&
      !this.utilHelper.isEmptyValue(Element.configuration.apiURL)
    ) {
      if (_.isEqual(Element.type, ELEMENT_TYPES.SELECT_INPUT)) {
        Element = await this.getAPIData(UpdatedForm, Element);

        Element = Element.configuration.isMulti
          ? this.multiSelect.getMultiDropdownValue(Element)
          : this.singleSelect.getSingleDropdownValue(Element);
      } else {
        Element = await this.getAPIData(UpdatedForm, Element);
      }
    } else {
      if (formElement.configuration.selectValueIfEmpty) {
        Element.value = formElement.configuration.selectValueIfEmpty.value;
      } else {
        Element.value = null;
      }
    }
    Element.touched = !_.isNull(Element.value);
    Element.disabled = isDisabled || Element.readOnly;

    return Element;
  };

  /**
   * Validate parent and child relationship for date and select
   *
   * @param {forms} IForm[].
   * @return {UpdatedForms} IForm[].
   */
  validateParentChildRelationship = async (form: IForm): Promise<IForm> => {
    let UpdatedForm = {
      ...form,
      groups: form.groups.map((group: IFormGroup) => ({
        ...group,
        elements: group.elements.map((element: IFormElement) => ({
          ...element,
        })),
      })),
    };

    const FormGroups = UpdatedForm.groups;
    for (let iFormGroup = 0; iFormGroup < FormGroups.length; iFormGroup++) {
      const FormElements = FormGroups[iFormGroup].elements;

      for (
        let iFormElement = 0;
        iFormElement < FormElements.length;
        iFormElement++
      ) {
        const FormElement = FormElements[iFormElement];

        let Element: IFormElement = { ...FormElement };
        const ParentElements = Element.configuration.parentElements;

        if (!_.isEqual(ParentElements.length, 0)) {
          Element = await this.ifParentNotEmpty(
            UpdatedForm,
            ParentElements,
            Element
          );
        }

        FormElements[iFormElement] = {
          ...FormElements[iFormElement],
          ...Element,
        };
      }
      FormGroups[iFormGroup] = {
        ...FormGroups[iFormGroup],
        elements: FormElements,
      };
    }
    UpdatedForm = {
      ...UpdatedForm,
      groups: FormGroups,
    };

    return UpdatedForm;
  };
}
