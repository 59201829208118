import React from "react";
import { UIDatePicker } from "../DatePicker";
import { UINumberInput } from "../Number";

export const getDatePicker = (props: any): JSX.Element => {
  const { formId, formGroupId, onChange, formElement } = props;
  if (formElement.is_number) {
    // Number input
    return (
      <UINumberInput
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        onValidateForms={() => {
          console.log("Validating forms");
        }}
        formElement={formElement}
      />
    );
  } else {
    return (
      <UIDatePicker
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  }
};
