import React from "react";
import * as _ from "lodash";
import classnames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import style from "./style";
import { ErrorIcon } from "../../Elements/FormElements/UploadFile/Icons";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  errorMessage: string;
  note?: string;
};

// File error component
export const UIFileError = (props: props): JSX.Element => {
  const classes = useStyles();
  const { errorMessage, note } = props;
  const Error = (errorMessage || "").toString().trim();
  const Note = (note || "").toString().trim();

  if (_.isEqual(Error.length, 0)) {
    return (
      <div
        className={classes.note}
        dangerouslySetInnerHTML={{
          __html: Note,
        }}
      ></div>
    );
  }

  return (
    <div className={classnames(classes.fileErrorContainer)}>
      <div className={classes.filInfo}>
        <div className={classes.fileIcon}>{ErrorIcon}</div>
        <div className={classes.fileName}>{errorMessage}</div>
      </div>
    </div>
  );
};
