import * as _ from "lodash";
import { Axios } from "../../../../../../../api/service/Axios";
import { UI_ELEMENT_API_METHODS } from "../../../../../config";
import { IFormElement } from "../../../../interface/IFormElement";
import {
    IOption
} from "../../../../interface/IOption";
import { UtilHelper } from "../../../Utils";

// Select Element Helper 
export class MultiSelectHelper{
    private utilHelper: UtilHelper;
    constructor(){
        this.utilHelper = new UtilHelper();
    }

    ifMultiSelectValueNotArray = (formElement: IFormElement, elementValue: any): IFormElement =>{
        let Element = {...formElement};
        const Options = _.isObject(formElement.value) ? [] : elementValue.split(",").map((option:any) => option.toString().trim());
        const ValueOptions = _.isObject(formElement.value) ? [] : elementValue.split(",").map((option:any) => ({
            label:option.toString().trim(),
            value: option.toString().trim()
        }));

        const DSOptions = formElement.dataSource.map((option:IOption) => option.value.toString().trim());

        const MultiOptions:IOption[] = ValueOptions.filter((option: IOption)=> {
            const Index = DSOptions.indexOf((option.value || "").toString().trim());
            return !_.isEqual(Index, -1);
        })!;

        if(formElement.highlight_removed_option){
            Element = this.getMultiSelectNotArrayHighligher(formElement, elementValue, MultiOptions, Options);
        }
        else{
            if(_.isEqual(Options.length, 0)){
                Element.value = null;
            }else{
                Element.value =  _.isEqual(MultiOptions.length, 0) ? null : MultiOptions;
            }
        }
        Element.touched = true;
        return Element;
    }

    getMultiSelectNotArrayHighligher = (formElement: IFormElement, elementValue: any, multiOptions: IOption[], options: any): IFormElement =>{
        const Element = {...formElement};
        const DataSourceOptions = formElement.dataSource.map((option:any) => option.value.toString().trim());
        const RemovedOptions:IOption[] = elementValue.split(",").filter((value:any) => {
            const Index = DataSourceOptions.indexOf((value || "").toString().trim());
            return _.isEqual(Index, -1);
        }).map((value: any) => ({label: value, value: value, highlightOption: true}));
        Element.value = _.isEqual(options.length, 0) ?  null : [...multiOptions, ...RemovedOptions];
        return Element;
    }

    ifMultiSelectValueIsArray = (formElement: IFormElement, elementValue: any): IFormElement =>{
        let Element = {...formElement};
        const Options = elementValue.map((option:any) => option.value.toString().trim());
        const DSOptions = formElement.dataSource.map((option:any) => option.value.toString().trim());

        const MultiOptions:IOption[] = elementValue.filter((option: IOption)=> {
            const Index = DSOptions.indexOf((option.value || "").toString().trim());
            return !_.isEqual(Index, -1);
        })!;

        if(formElement.highlight_removed_option){
            Element = this.getMultiSelectArrayHighligher(formElement, elementValue,MultiOptions,  Options);
        }
        else{
            if(_.isEqual(Options.length, 0)){
                Element.value = _.isEqual(Options.length, 0);
            }else{
                Element.value = _.isEqual(MultiOptions.length, 0) ? null : MultiOptions;
            }
        }
        Element.touched = true;
        return Element;
    }

    getMultiSelectArrayHighligher = (formElement: IFormElement, elementValue: any, multiOptions: IOption[], options: any): IFormElement =>{
        const Element = {...formElement};
        const DataSourceOptions = formElement.dataSource.map((option:any) => option.value.toString().trim());
                    
            const RemovedOptions:IOption[] = elementValue.filter((option:IOption) => {
                const Index = DataSourceOptions.indexOf((option.value || "").toString().trim());
                return _.isEqual(Index, -1);
            }).map((option: IOption) => ({...option, highlightOption: true}));

            Element.value = _.isEqual(options.length, 0) ?  null : [...multiOptions, ...RemovedOptions];
        return Element;
    }
    /**
     * Get Multi-select value
     *
     * @param {formElement} IFormElement.
     * @return {formElement} IFormElement.
     */
    getMultiDropdownValue = (formElement: IFormElement) : IFormElement =>{
        let Element = {...formElement};
        if(_.isNull(formElement.value)){
            Element.value = null;
            return Element;
        }

        const elementValue = !_.isArray(formElement.value) ? 
            (formElement.value || "").toString().trim() : formElement.value;
        
        if(!_.isEqual((formElement.value || "").toString().trim().length, 0) && _.isArray(formElement.dataSource)){
           
            if(!_.isArray(elementValue)){
                Element = this.ifMultiSelectValueNotArray(formElement, elementValue);
            
            }else{
                Element = this.ifMultiSelectValueIsArray(formElement, elementValue);
            }
            return Element;
        }
        Element.value = !_.isNull(Element.value)? Element.value : null;
        return Element;
    }

     /**
     * Set multi-select value using API datasource
     *
     * @param {formElement} IFormElement.
     * @return {formElement} IFormElement.
     */
     getMultiAPIDropdownValue = async (formElement: IFormElement) : Promise<IFormElement> =>{

        let Element: IFormElement = {...formElement};

        let value:IOption[] | null = null;
        try{
            let Data:any;

            const APIValueURL = (formElement.api_value_url || "").trim().toLowerCase().trim();
            const APIURL = (formElement.api_url || "").trim().toLowerCase().trim();

            if(!_.isEqual(APIValueURL, APIURL)){
                if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.GET)){
                    Data = await Axios.get(formElement.api_value_url);
                }
                else if(_.isEqual(formElement.form_element_methodid, UI_ELEMENT_API_METHODS.POST)){
                    Data = await Axios.post(formElement.api_value_url, {});
                }
                value = !_.isArray(Data.data) ? null : Data.data;
            }else{
                value = formElement.dataSource;
            }
     
            
            Element.apiError = "";
        }
        catch(error:any){
            value = null;
            Element.apiError = this.utilHelper.getErrorMessage(error);
        }

        Element = this.getMultiSelectAPIValue(formElement, value);

        return Element;
    }

    getMultiSelectAPIValue = (formElement: IFormElement, options: IOption[] | null): IFormElement=>{
        const Options = !_.isArray(options) ? null : options;
        let Element = {...formElement};
        Element.valueDataSource = options;

        if(_.isNull(Options)){
            Element.value = null;
            return Element;
        }

        Element = this.ifMultiSelectValueIsArray(Element, Options);
        return Element;
    }
}
