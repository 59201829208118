import React from "react";
import classNames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onClick: any;
  title: string;
};

// Close Icon functional component
export const IconClose = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onClick, title } = props;

  return (
    <Tooltip title={title} onClick={onClick}>
      <IconButton
        aria-label={title}
        size={"small"}
        className={classNames(classes.iconButton, classes.closeIcon)}
      >
        <CloseIcon style={{ width: "0.8em", height: "0.8em" }} />
      </IconButton>
    </Tooltip>
  );
};
