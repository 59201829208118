import * as _ from "lodash";
import { IFormElement } from "../../../../../../../UI/Elements/Forms/interface/IFormElement";
import { IForm } from "../../../../../../../UI/Elements/Forms/interface/IForm";
import { IFormGroup } from "../../../../../../../UI/Elements/Forms/interface/IFormGroup";



export const getForm = (forms: IForm[], PortalData: any, jobName: string, FormType?: string): any => {
    return forms.map((form: IForm) => ({
        ...form,
        groups: form.groups.map((group: IFormGroup) => ({
            ...group,
            elements: group.elements.map((formElement: IFormElement) => {
                let elementValue = "";
                let isVisible = true;
                PortalData.map((PortalDataform: IForm) => ({
                    ...PortalDataform,
                    groups: PortalDataform.groups.map((PortalDatagroup: IFormGroup) => ({
                        ...PortalDatagroup,
                        elements: PortalDatagroup.elements.map((PortalDataformElement: any) => {
                            if (_.isEqual(PortalDataformElement.labelId, "GoogleUserRefreshToken")) {
                                elementValue = formElement.value;
                            }
                            else if (_.isEqual(formElement.labelId, "job_name")) {
                                if (_.isEqual(FormType, "Detail")) {
                                    elementValue = jobName;
                                    isVisible = false;
                                }
                                elementValue = jobName;
                            }
                            else if (_.isEqual(PortalDataformElement.labelId, formElement.labelId)) {
                                elementValue = PortalDataformElement.value;
                                isVisible = formElement.is_visible;
                            }
                        })
                    }))
                }));
                return {
                    ...formElement,
                    value: elementValue,
                    is_visible: isVisible
                };
            })
        }))
    }));
};



