import React from "react";
import * as _ from "lodash";
import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { UITextInput } from "../Elements/TextInput";
import { UISelect } from "../Elements/Dropdown";
import { UICreatableSelect } from "../Elements/Dropdown/Creatable";
import { ELEMENT_TYPES } from "../../configuration";
import { UIErrorOrNoteMessage } from "../Elements/Child/ErrorOrNote";
import { UILabel } from "../Elements/Child/Label";
import { IForm } from "../../Interfaces/Form/IForm";

const getErrorMessage = (formElement: IFormElement): string => {
  let message_error = "";
  if (formElement.apiError) {

    message_error = formElement.apiError;

  } else {

    message_error = formElement.touched ? formElement.errorMessage : "";

  }
  if (formElement.disabled || formElement.readOnly) {

    message_error = "";

  } else {
    const HighlightOptionError = formElement.highlightRemovedOptionError;

    message_error = !_.isEqual(HighlightOptionError.length, 0) ? HighlightOptionError : message_error;
  }
  return message_error;
};
// Create styles

type props = {
  onChange: any;
  formElement: IFormElement;
  form: IForm;
  onCreateOption: any;
  formGroupId: number;
  formId: number;
};

export const FormElement = (props: props): React.JSX.Element => {
  const { formElement,
    onChange,
    form
  } = props;

  const Error_Message = getErrorMessage(formElement);
  const Note = (formElement.configuration.note || "").toString().trim();

  const onElementChange = (value: any): void => {
    onChange({ formElement: formElement, value, });
  };

  const onCreateOption = (option: any): void => {
    props.onCreateOption(formElement,
      option);
  };

  let element = <></>;
  switch (formElement.type) {
    case ELEMENT_TYPES.SELECT_INPUT: {
      if (formElement.configuration.isCreatableSelect) {
        element = (
          <UICreatableSelect
            onChange={onElementChange}
            value={formElement.value}
            errorMessage={Error_Message}
            formElement={formElement}
            form={form}
            onCreateOption={onCreateOption}
          />
        );
        break;
      }
      element = (
        <UISelect
          onChange={onElementChange}
          form={form}
          formElement={formElement}
          errorMessage={Error_Message}
          value={formElement.value}
        />
      );
      break;
    }
    case ELEMENT_TYPES.TEXT_INPUT: {
      element = (
        <UITextInput
          onChange={onElementChange}
          errorMessage={Error_Message}
          formElement={formElement}
        />
      );
      break;
    }
  }

  return (
    <>
      <UILabel formElement={formElement} />
      {element}
      <UIErrorOrNoteMessage note={Note} errorMessage={Error_Message} />
    </>
  );
};
