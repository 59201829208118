import React from "react";
import { GoogleLogin } from "react-google-login";

type props = {
  onResponse: any;
  appid: string;
  scope: string;
  onRender: any;
};

export const GoogleAuth = (props: props): React.JSX.Element => {
  const { onResponse, appid, scope, onRender } = props;

  return (
    <GoogleLogin
      clientId={appid}
      scope={scope}
      accessType="offline"
      prompt="consent"
      responseType="code"
      onSuccess={onResponse}
      onFailure={onResponse}
      cookiePolicy={"single_host_origin"}
      render={onRender}
    />
  );
};
