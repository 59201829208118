
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { FORM_ELEMENT_TYPES } from "../ElementTypes";

// ToolName Column
export const ToolName: IFormElement = {
	id:"toolname",
	configuration:{
		isMulti: false,
		dataSource:[],
		tooltip:"",
		note:"",
		title:"Tool Name",
		placeholder:"",
		gridColumns:4,
		hideOptionalText: false,
		dependentValue:"",
		dependsOn:[],
		disabledBasedOnValue:"",
		disabledBasedOn: [],
	},
	errorMessage:"",
	disabled: false,
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	isValid: false,
	validations:{
		isURL: false,
		isEmail: false,
		isNumber: false,
		required: true,
	},
	value: "",
	touched: false,
	visible: true,
	loading: false
};

// ToolVersion Column
export const ToolVersion: IFormElement = {
	configuration:{
		dataSource:[],
		isMulti: false,
		disabledBasedOn: [],
		note:"",
		title:"Tool Version",
		gridColumns:2,
		placeholder:"",
		tooltip:"",
		hideOptionalText: false,
		dependentValue:"",
		dependsOn:[],
		disabledBasedOnValue:""
	},
	id:"version",
	errorMessage:"",
	disabled: false,
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	isValid: false,
	validations:{
		isEmail: false,
		isNumber: false,
		required: true,
		isURL: false,
	},
	value: "",
	loading: false,
	visible: true,
	touched: false,
};

// ToolType Column
export const ToolType: IFormElement = {
	id:"tooltypeid",
	configuration:{
		dataSource:[],
		note:"",
		tooltip:"",
		isMulti: false,
		title:"Tool Type",
		gridColumns:4,
		hideOptionalText: false,
		dependsOn:[],
		placeholder:"",
		dependentValue:"",
		disabledBasedOnValue:"",
		disabledBasedOn: [],
	},
	disabled: false,
	isValid: false,
	errorMessage:"",
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	
	loading: false,
	value: "",
	visible: true,
	touched: false,
	validations:{
		isURL: false,
		isEmail: false,
		required: true,
		isNumber: false,
	},
};


// ToolLifeCycle Column
export const ToolLifeCycle: IFormElement = {
	id:"toollifecycleid",
	configuration:{
		note:"",
		dataSource:[],
		isMulti: false,
		tooltip:"",
		gridColumns:4,
		title:"Tool Lifecycle",
		placeholder:"",
		hideOptionalText: false,
		dependsOn:[],
		dependentValue:"",
		disabledBasedOn: [],
		disabledBasedOnValue:""
	},
	value: "",
	touched: false,
	visible: true,
	loading: false,
	disabled: false,
	errorMessage:"",
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	isValid: false,
	validations:{
		isURL: false,
		isEmail: false,
		required: true,
		isNumber: false,
	},
	
};


// IsActive Column
export const IsActive: IFormElement = {
	configuration:{
		dataSource:[],
		note:"",
		tooltip:"",
		isMulti: false,
		title:"Is Portal Active",
		placeholder:"",
		hideOptionalText: false,
		gridColumns:2,
		defaultValue: true,
		dependentValue:"",
		disabledBasedOn: [],
		dependsOn:[],
		disabledBasedOnValue:""
	},
	disabled: false,
	id:"isactive",
	errorMessage:"",
	value: true,
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations:{
		isURL: false,
		isNumber: false,
		required: true,
		isEmail: false,
	},
	visible: true,
	loading: false,
	touched: false,
};

// IsWppActive Column
export const IsWppActive: IFormElement = {
	configuration:{
		dataSource:[],
		note:"",
		tooltip:"",
		isMulti: false,
		title:"Is Wpp Active",
		placeholder:"",
		hideOptionalText: false,
		gridColumns:2,
		defaultValue: true,
		dependentValue:"",
		disabledBasedOn: [],
		dependsOn:[],
		disabledBasedOnValue:""
	},
	disabled: false,
	id:"iswppactive",
	errorMessage:"",
	value: true,
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations:{
		isURL: false,
		isNumber: false,
		required: true,
		isEmail: false,
	},
	visible: true,
	loading: false,
	touched: false,
};

// IsMaintenance Column
export const IsMaintenance: IFormElement = {
	id:"undermaintenance",
	configuration:{
		dataSource:[],
		disabledBasedOnValue:"",
		note:"",
		tooltip:"",
		isMulti: false,
		title:"Under Maintenance",
		placeholder:"",
		hideOptionalText: false,
		gridColumns:2,
		dependsOn:[],
		dependentValue:"",
		defaultValue: false,
		disabledBasedOn: [],
	},
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations:{
		isEmail: false,
		required: true,
		isURL: false,
		isNumber: false,
	},
	disabled: false,
	loading: false,
	errorMessage:"",
	value: false,
	touched: false,
	visible: true,
};

// IsMaintenance Column
export const ResetAuthorization: IFormElement = {
	id:"resetauthorization",
	
	disabled: false,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations:{
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: true,
	touched: false,
	visible: true,
	loading: false,
	configuration:{
		dataSource:[],
		title:"Revoke Users Token",
		isMulti: false,
		tooltip:"",
		note:"",
		gridColumns:2,
		placeholder:"",
		visibility:{
			newForm: false,
			editForm: true,
		},
		hideOptionalText: false,
		disabledBasedOn: [],
		defaultValue: false,
		dependsOn:[],
		disabledBasedOnValue:"",
		dependentValue:"",
	},
};


// Channels Column
export const Channels: IFormElement = {
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	errorMessage:"",
	id:"channelid",
	configuration:{
		isMulti: false,
		dataSource:[],
		note:"",
		title:"Channel",
		gridColumns:4,
		tooltip:"",
		placeholder:"",
		dependsOn:[],
		dependentValue:"",
		hideOptionalText: false,
		disabledBasedOnValue:"",
		disabledBasedOn: [],
	},
	disabled: false,
	isValid: false,
	validations:{
		required: true,
		isEmail: false,
		isURL: false,
		isNumber: false,
	},
	visible: true,
	value: "",
	touched: false,
	loading: false
};

// ToolState Column
export const ToolState: IFormElement = {
	id:"toolstateid",
	configuration:{
		isMulti: false,
		hideOptionalText: false,
		note:"",
		title:"Tool State",
		tooltip:"",
		gridColumns:4,
		placeholder:"",
		dependsOn:[],
		disabledBasedOnValue:"",
		dependentValue:"",
		disabledBasedOn: [],
		dataSource:[],
	},
	disabled: false,
	isValid: false,
	errorMessage:"",
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations:{
		isEmail: false,
		required: true,
		isNumber: false,
		isURL: false,
	},
	visible: true,
	touched: false,
	loading: false,
	value: null,
};

// ToolStatus Column
export const ToolStatus: IFormElement = {
	id:"toolstatusid",
	value: null,
	visible: true,
	touched: false,
	loading: false,
	configuration:{
		isMulti: false,
		tooltip:"",
		note:"",
		placeholder:"",
		dataSource:[],
		title:"Tool Status",
		gridColumns:4,
		hideOptionalText: false,
		dependentValue:"",
		dependsOn:[],
		disabledBasedOnValue:"",
		disabledBasedOn: [],
	},
	validations:{
		isURL: false,
		isNumber: false,
		isEmail: false,
		required: true,
	},
	disabled: false,
	isValid: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	errorMessage:"",
	
	
};
// ToolLabel Column
export const ToolLabel: IFormElement = {
	id:"toollabelid",
	configuration:{
		note:"",
		dataSource:[],
		tooltip:"",
		title:"Tool Label",
		isMulti: false,
		gridColumns:4,
		hideOptionalText: false,
		dependsOn:[],
		placeholder:"",
		dependentValue:"",
		disabledBasedOnValue:"",
		disabledBasedOn: [],
	},
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	disabled: false,
	isValid: false,
	errorMessage:"",
	loading: false,
	validations:{
		isURL: false,
		isEmail: false,
		isNumber: false,
		required: true,
	},
	value: null,
	touched: false,
	visible: true,
};
// Client Column
export const Client: IFormElement = {
	configuration:{
		note:"",
		dataSource:[],
		isMulti: false,
		tooltip:"",
		gridColumns:4,
		title:"Client",
		placeholder:"",
		dependentValue:"",
		hideOptionalText: false,
		dependsOn:[],
		disabledBasedOnValue:"",
		disabledBasedOn: [],
	},
	errorMessage:"",
	disabled: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	isValid: true,
	validations:{
		isNumber: false,
		isEmail: false,
		isURL: false,
		required: false,
	},
	visible: true,
	value: null,
	touched: false,
	loading: false,
	id:"clientid",
};

// ToolPlatforms Column
export const ToolPlatforms: IFormElement = {
	id:"ToolPlatforms",
	configuration:{
		isMulti: true,
		dataSource:[],
		note:"",
		title:"Tool Platforms",
		gridColumns:8,
		tooltip:"",
		placeholder:"",
		selectMetadata:{
			value:"platformid",
			label:"platformName",
		},
		dependsOn:[],
		hideOptionalText: false,
		dependentValue:"",
		disabledBasedOnValue:"",
		disabledBasedOn: [],
	},
	errorMessage:"",
	disabled: false,
	isValid: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations:{
		isURL: false,
		isEmail: false,
		isNumber: false,
		required: true,
	},
	visible: true,
	value: null,
	loading: false,
	touched: false,
};

// ToolAZGroups Column
export const ToolAZGroups: IFormElement = {
	id:"ToolGroupMapping",
	
	disabled: false,
	errorMessage:"",
	isValid: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	
	value: null,
	visible: true,
	touched: false,
	loading: false,
	configuration:{
		isMulti: true,
		dataSource:[],
		note:"",
		tooltip:"",
		title:"Tool Authorized Groups",
		placeholder:"",
		gridColumns:12,
		selectMetadata:{
			value:"groupid",
			label:"label",
		},
		hideOptionalText: false,
		dependentValue:"",
		dependsOn:[],
		disabledBasedOn: [],
		disabledBasedOnValue:""
	},
	validations:{
		isURL: false,
		isEmail: false,
		isNumber: false,
		required: true,
	},
};

// Agency Column
export const Agency: IFormElement = {
	id:"agencyid",
	configuration:{
		dataSource:[],
		isMulti: false,
		note:"",
		tooltip:"",
		title:"Agency",
		gridColumns:4,
		placeholder:"",
		hideOptionalText: false,
		dependsOn:[],
		dependentValue:"",
		disabledBasedOn: [],
		disabledBasedOnValue:""
	},
	disabled: false,
	errorMessage:"",
	isValid: true,
	value: null,
	visible: true,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations:{
		required: false,
		isEmail: false,
		isURL: false,
		isNumber: false,
	},
	
	loading: false,
	touched: false,
};

// Region Column
export const Region: IFormElement = {
	id:"regionid",
	configuration:{
		isMulti: false,
		dataSource:[],
		note:"",
		title:"Region",
		tooltip:"",
		gridColumns:4,
		hideOptionalText: false,
		dependsOn:[],
		dependentValue:"",
		disabledBasedOn: [],
		disabledBasedOnValue:"",
		placeholder:"",
	},
	isValid: true,
	errorMessage:"",
	disabled: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations:{
		isEmail: false,
		required: false,
		isNumber: false,
		isURL: false,
	},
	touched: false,
	visible: true,
	value: null,
	loading: false
};

// Market Column
export const Market: IFormElement = {
	configuration:{
		isMulti: false,
		dataSource:[],
		tooltip:"",
		note:"",
		disabledBasedOnValue:"",
		title:"Market",
		disabledBasedOn: [],
		gridColumns:4,
		hideOptionalText: false,
		placeholder:"",
		dependsOn:[],
		dependentValue:"",
	},
	errorMessage:"",
	disabled: false,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	isValid: true,
	id:"marketid",
	validations:{
		isEmail: false,
		isNumber: false,
		required: false,
		isURL: false,
	},
	loading: false,
	value: null,
	touched: false,
	visible: true,
};

// Madeby Column
export const Madeby: IFormElement = {
	id:"madeby",
	disabled: false,
	errorMessage:"",
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	isValid: false,
	validations:{
		isURL: false,
		isEmail: false,
		required: true,
		isNumber: false,
	},
	configuration:{
		isMulti: false,
		note:"",
		dataSource:[],
		tooltip:"",
		gridColumns:4,
		placeholder:"",
		title:"Made By",
		hideOptionalText: false,
		dependentValue:"",
		disabledBasedOn: [],
		dependsOn:[],
		disabledBasedOnValue:""
	},
	value: "",
	touched: false,
	visible: true,
	loading: false
};

// Shortdescription Column
export const ShortDescription: IFormElement = {
	id:"shortdescription",
	configuration:{
		multiRows:3,
		dataSource:[],
		note:"",
		isMulti: true,
		disabledBasedOn: [],
		tooltip:"",
		gridColumns:12,
		title:"Short Description",
		placeholder:"",
		dependsOn:[],
		hideOptionalText: false,
		dependentValue:"",
		disabledBasedOnValue:""
	},
	disabled: false,
	errorMessage:"",
	isValid: false,
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	validations:{
		isURL: false,
		required: true,
		isEmail: false,
		isNumber: false,
	},
	value: "",
	touched: false,
	visible: true,
	loading: false
};

// Longdescription Column
export const LongDescription: IFormElement = {
	id:"longdescription",
	configuration:{
		hideOptionalText: false,
		dataSource:[],
		isMulti: true,
		note:"",
		title:"Long Description",
		gridColumns:12,
		tooltip:"",
		placeholder:"",
		dependentValue:"",
		disabledBasedOn: [],
		dependsOn:[],
		disabledBasedOnValue:""
	},
	disabled: false,
	type: FORM_ELEMENT_TYPES.HTML_EDITOR,
	errorMessage:"",
	isValid: false,
	validations:{
		isURL: false,
		required: true,
		isNumber: false,
		isEmail: false,
	},
	touched: false,
	value: "",
	visible: true,
	loading: false
};

// CreatedDate Column
export const CreatedDate: IFormElement = {
	id:"createddate",
	configuration:{
		dataSource:[],
		note:"",
		isMulti: false,
		tooltip:"UTC Timezone",
		gridColumns:4,
		title:"Created Date",
		placeholder:"",
		visibility:{
			newForm: false,
			editForm: true,
		},
		hideOptionalText: true,
		dependentValue:"",
		dependsOn:[],
		disabledBasedOnValue:"",
		disabledBasedOn: [],
	},
	errorMessage:"",
	disabled: true,
	isValid: false,
	validations:{
		isURL: false,
		isEmail: false,
		isNumber: false,
		required: false,
	},
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	value: "",
	visible: true,
	loading: false,
	touched: false,
};

// CreatedBy Column
export const CreatedBy: IFormElement = {
	configuration:{
		dataSource:[],
		note:"",
		isMulti: false,
		tooltip:"",
		gridColumns:4,
		title:"Created By",
		placeholder:"",
		visibility:{
			newForm: false,
			editForm: true,
		},
		dependsOn:[],
		hideOptionalText: true,
		disabledBasedOn: [],
		dependentValue:"",
		disabledBasedOnValue:""
	},
	disabled: true,
	isValid: false,
	errorMessage:"",
	id:"createdby",
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	validations:{
		isEmail: false,
		required: false,
		isNumber: false,
		isURL: false,
	},
	loading: false,
	value: "",
	visible: true,
	touched: false,
};

// ModifiedDate Column
export const ModifiedDate: IFormElement = {
	id:"modifieddate",
	
	disabled: true,
	errorMessage:"",
	isValid: false,
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	validations:{
		isEmail: false,
		isURL: false,
		required: false,
		isNumber: false,
	},
	value: "",
	configuration:{
		dataSource:[],
		isMulti: false,
		tooltip:"UTC Timezone",
		note:"",
		title:"Modified Date",
		gridColumns:4,
		placeholder:"",
		visibility:{
			newForm: false,
			editForm: true,
		},
		dependsOn:[],
		hideOptionalText: true,
		disabledBasedOn: [],
		dependentValue:"",
		disabledBasedOnValue:""
	},
	visible: true,
	touched: false,
	loading: false
};

// ModifiedBy Column
export const ModifiedBy: IFormElement = {
	id:"modifiedby",
	configuration:{
		isMulti: false,
		dataSource:[],
		note:"",
		title:"Modified By",
		tooltip:"",
		gridColumns:4,
		placeholder:"",
		visibility:{
			newForm: false,
			editForm: true,
		},
		dependsOn:[],
		hideOptionalText: true,
		disabledBasedOn: [],
		dependentValue:"",
		disabledBasedOnValue:""
	},
	errorMessage:"",
	disabled: true,
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	isValid: false,
	validations:{
		isURL: false,
		required: false,
		isEmail: false,
		isNumber: false,
	},
	value: "",
	visible: true,
	loading: false,
	touched: false,
};

// ToolURL Column
export const ToolURL: IFormElement = {
	id:"toolurl",
	disabled: false,
	errorMessage:"",
	isValid: false,
	configuration:{
		dataSource:[],
		note:"",
		isMulti: true,
		tooltip:"",
		gridColumns:12,
		placeholder:"",
		title:"Tool URL",
		hideOptionalText: false,
		dependsOn:[],
		multiRows:2,
		disabledBasedOn: [],
		disabledBasedOnValue:"",
		dependentValue:"",
	},
	
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	validations:{
		isEmail: false,
		isNumber: false,
		isURL: true,
		required: false,
	},
	value: "",
	touched: false,
	visible: true,
	loading: false
};

// ToolInfoURL Column
export const ToolInfoURL: IFormElement = {
	id:"toolsinfourl",
	configuration:{
		isMulti: true,
		note:"",
		dataSource:[],
		tooltip:"",
		gridColumns:12,
		disabledBasedOnValue:"",
		placeholder:"",
		title:"Tool Info URL",
		dependsOn:[],
		hideOptionalText: false,
		dependentValue:"",
		multiRows:2,
		disabledBasedOn: [],
	},
	
	validations:{
		isEmail: false,
		required: false,
		isURL: true,
		isNumber: false,
	},
	value: "",
	visible: true,
	touched: false,
	disabled: false,
	errorMessage:"",
	isValid: false,
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	loading: false
};

// ToolThumbnail Column
export const ToolThumbnail: IFormElement = {
	id:"toolthumbnail",
	configuration:{
		
		title:"Tool Thumbnail",
		gridColumns:12,
		placeholder:"",
		dataSource:[],
		isMulti: false,
		note:"",
		tooltip:"",
		hideOptionalText: false,
		file:{
			maxSize:1048576,
			allowedFileTypes:".png",
		},
		disabledBasedOnValue:"",
		dependsOn:[],
		dependentValue:"",
		disabledBasedOn: [],
	},
	isValid: false,
	disabled: false,
	errorMessage:"",
	type: FORM_ELEMENT_TYPES.FILE_UPLOAD,
	validations:{
		isEmail: false,
		isNumber: false,
		isURL: false,
		required: false,
	},
	value: "",
	visible: true,
	loading: false,
	touched: false,
};

// ToolThumbnail Column
export const ToolScriptPath: IFormElement = {
	id:"templatescriptpath",
	
	disabled: false,
	errorMessage:"",
	isValid: false,
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	validations:{
		isEmail: false,
		isURL: true,
		required: false,
		isNumber: false,
	},
	value: "",
	touched: false,
	visible: true,
	loading: false,
	configuration:{
		hideOptionalText: false,
		dataSource:[],
		note:"",
		tooltip:"",
		isMulti: true,
		gridColumns:12,
		title:"Template Script Path",
		placeholder:"",
		dependentValue:"",
		multiRows:2,
		dependsOn:[],
		disabledBasedOnValue:"",
		disabledBasedOn: [],
	},
};

// AuthLoginContent Column
export const AuthLoginContent: IFormElement = {
	id:"authlogincontent",
	errorMessage:"",
	disabled: false,
	isValid: false,
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	
	validations:{
		isEmail: false,
		isURL: false,
		required: false,
		isNumber: false,
	},
	value: "",
	visible: false,
	touched: false,
	configuration:{
		dataSource:[],
		isMulti: true,
		note:"",
		title:"Login Button Description HTML",
		tooltip:"",
		gridColumns:12,
		multiRows:5,
		hideOptionalText: false,
		dependsOn:["toolstateid"],
		placeholder:"",
		disabledBasedOn: [],
		disabledBasedOnValue:"",
		dependentValue:"2",
	},
	
	loading: false
};

// FormSubmitAPIEndpoint Column
export const FormSubmitAPIEndpoint: IFormElement = {
	configuration:{
		dataSource:[],
		isMulti: false,
		tooltip:"",
		title:"Form Submit API Endpoint",
		note:"",
		gridColumns:6,
		hideOptionalText: false,
		dependsOn:["toolstateid"],
		dependentValue:"2",
		disabledBasedOn: [],
		placeholder:"",
		disabledBasedOnValue:""
	},
	disabled: false,
	errorMessage:"",
	isValid: false,
	id:"formsubmitapiendpoint",
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	
	value: "",
	visible: false,
	touched: false,
	validations:{
		required: true,
		isEmail: false,
		isNumber: false,
		isURL: true,
	},
	loading: false
};

// FormSubmitAPIEndpoint Column
export const FormUpdateAPIEndpoint: IFormElement = {
	id:"formupdateapiendpoint",
	configuration:{
		dataSource:[],
		isMulti: false,
		note:"",
		tooltip:"",
		title:"Form Update API Endpoint",
		gridColumns:6,
		placeholder:"",
		hideOptionalText: false,
		dependsOn:["toolstateid"],
		dependentValue:"2",
		disabledBasedOn: [],
		disabledBasedOnValue:""
	},
	disabled: false,
	errorMessage:"",
	isValid: false,
	type: FORM_ELEMENT_TYPES.TEXT_INPUT,
	validations:{
		isEmail: false,
		isURL: true,
		required: false,
		isNumber: false,
	},
	value: "",
	visible: false,
	touched: false,
	loading: false
};


// IsFileDownloadAPI Column
export const IsFileDownloadAPI: IFormElement = {
	id:"isfiledownloadapi",
	configuration:{
		dataSource:[],
		isMulti: false,
		note:"",
		tooltip:"",
		title:"Is File Download API",
		gridColumns:2,
		placeholder:"",
		hideOptionalText: false,
		dependsOn:["toolstateid"],
		dependentValue:"2",
		disabledBasedOn: [],
		disabledBasedOnValue:""
	},
	disabled: false,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations:{
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: false,
	visible: false,
	touched: false,
	loading: false
};

// LoadLastSavedData Column
export const LoadLastSavedData: IFormElement = {
	id:"loadlastsaveddata",
	configuration:{
		note:"",
		tooltip:"",
		isMulti: false,
		dataSource:[],
		title:"Load Last Saved Data",
		hideOptionalText: false,
		placeholder:"",
		dependsOn:["toolstateid"],
		disabledBasedOn: [],
		gridColumns:4,
		disabledBasedOnValue:"",
		dependentValue:"2",
	},
	validations:{
		required: true,
		isEmail: false,
		isNumber: false,
		isURL: false,
	},
	disabled: false,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	
	touched: false,
	value: true,
	visible: false,
	loading: false
};

// IsGrid Column
export const IsGrid: IFormElement = {
	id:"isgrid",
	
	disabled: false,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations:{
		isEmail: false,
		required: true,
		isURL: false,
		isNumber: false,
	},
	visible: false,
	value: true,
	touched: false,
	configuration:{
		dataSource:[],
		isMulti: false,
		tooltip:"",
		note:"",
		title:"Is Grid",
		placeholder:"",
		hideOptionalText: false,
		gridColumns:4,
		dependsOn:["toolstateid"],
		disabledBasedOn: [],
		dependentValue:"2",
		disabledBasedOnValue:""
	},
	loading: false
};

// IsSchedule Column
export const IsSchedule: IFormElement = {
	id:"isschedule",
	configuration:{
		dataSource:[],
		isMulti: false,
		note:"",
		tooltip:"",
		title:"Is Schedule",
		gridColumns:2,
		placeholder:"",
		hideOptionalText: false,
		dependsOn:["toolstateid"],
		dependentValue:"2",
		disabledBasedOn: ["isgrid"],
		disabledBasedOnValue: false
	},
	disabled: true,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations:{
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: true,
	visible: false,
	touched: false,
	loading: false
};

// Tool Persist Buttons Column
export const ToolPersistButton: IFormElement = {
	id:"ToolPersistButtons",
	configuration:{
		dataSource:[],
		isMulti: true,
		note:"",
		tooltip:"",
		title:"Tool Persist Buttons",
		gridColumns:6,
		placeholder:"",
		selectMetadata:{
			label:"persistbuttonName",
			value:"persistbuttonid"
		},
		hideOptionalText: false,
		dependsOn:["toolstateid"],
		dependentValue:"2",
		disabledBasedOn: ["isgrid"],
		disabledBasedOnValue:false,
	},
	disabled: true,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations:{
		isEmail: false,
		isURL: false,
		required: false,	
		isNumber: false,
	},
	value: null,
	visible: false,
	touched: false,
	loading: false
};

// ToolFormType Column
export const ToolFormType: IFormElement = {
	id:"toolformtypeid",
	configuration:{
		dataSource:[],
		isMulti: false,
		note:"",
		tooltip:"",
		title:"Tool Form Type",
		gridColumns:4,
		placeholder:"",
		hideOptionalText: false,
		dependsOn:["toolstateid"],
		dependentValue:"2",
		disabledBasedOn: [],
		disabledBasedOnValue:""
	},
	disabled: false,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SELECT_INPUT,
	validations:{
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: null,
	visible: false,
	touched: false,
	loading: false
};


// IsServiceAccountAuth Column
export const IsServiceAccountAuth: IFormElement = {
	id:"isserviceaccountauth",
	configuration:{
		dataSource:[],
		isMulti: false,
		note:"",
		tooltip:"If the tool requires Service Account ID token",
		title:"Is Service Account Auth",
		gridColumns:4,
		placeholder:"",
		hideOptionalText: false,
		defaultValue: false,
		dependsOn:["toolstateid"],
		disabledBasedOn: [],
		disabledBasedOnValue:"",
		dependentValue:"2",

	},
	disabled: false,
	errorMessage:"",
	isValid: true,
	type: FORM_ELEMENT_TYPES.SWITCH,
	validations:{
		isEmail: false,
		isURL: false,
		required: true,
		isNumber: false,
	},
	value: false,
	visible: false,
	touched: false,
	loading: false
};




