import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { Theme } from "@material-ui/core/styles";
import { makeStyles, createStyles } from "@material-ui/core";
import style from "./style";
import UITooltip from "../Tooltip";
import EllipsisText from "react-text-overflow-middle-ellipsis";

type props = {
  title: string;
  showTooltip: boolean;
};

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const MiddleTextEllipsis = (props: props): React.JSX.Element => {
  const { title, showTooltip } = props;
  const classes = useStyles();
  const [hoverStatus, setHover] = useState(false);
  const textElementRef: any = useRef();
  const Title = (title || "").toString().trim();

  const compareSize = (): any => {
    if (_.isNull(textElementRef) || _.isUndefined(textElementRef.current)) {
      return;
    }
    
    const compare = _.gt(
      textElementRef.current?.scrollWidth,
      textElementRef.current?.clientWidth
    );
    _.isEqual(showTooltip, true) ?
      setHover(compare) : setHover(false);
  };
  useEffect(() => {
    callFuninUseEffect();
  }, [Title]);

  useEffect(() => {
    callFuninUseEffect();
  }, []);
   const callFuninUseEffect=():any=>{
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
   };
  return (
    <UITooltip title={Title || ""} disableTooltip={!hoverStatus}>
      <span >
        <span ref={textElementRef} className={classes.ellipsisContainer}>
          {Title}
        </span>
        <EllipsisText text={Title} />

      </span>
    </UITooltip>
  );
};
