import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../../../../../UI/Elements/utilstyle";

const style: any = (theme: Theme) => ({
	container: {
		width: "100%",
		margin: "32px 40px",
	},
	form: {
		backgroundColor: "#ffffff",
		padding: "2rem",
		width: "60%",
	},
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	infoWrapper: {
		padding: 12,
		justifyContent: "center"
	},
	contentBoxInfoWrapper: {
		width: "100%",
	},
	toolContentWrapper: {
		padding: "20px 28px 28px 28px",
	},
	toolContent: {
		width: "100%",
		backgroundColor: "#FFFF",
		padding: 31,
	},
	reviewForm: {
		marginBottom: "2rem",
	},
	reviewStepContainer: {
		display: "flex",
		alignItems: "center",
		marginBottom: "1.125rem"
	},
	reviewFormTitle: {
		fontSize: "1.25rem",
		fontWeight: 600,
	},
	reviewFormStepLink: {
		color: "#005fbe",
		fontSize: 14,
		marginLeft: 12,
		fontWeight: 500,
		cursor: "pointer"
	},
	reviewFormGroup: {
		marginBottom: "2rem"
	},
	formElement: {
		marginBottom: "1.5rem"
	},
	elementValue: {
		fontSize: "0.875rem",
		minHeight: 21,
		whiteSpace: "pre-line",
		wordBreak: "break-word",
		width: "100%"
	},
	subValue: {
		color: "#6a737c",
		fontSize: "0.75rem",
		fontWeight: "500"
	},
	formGroupTitle: {
		fontSize: "1rem",
		fontWeight: 600,
		marginBottom: "1rem"
	},
	elementLabel: {
		minHeight: 21,
		color: "rgb(36, 44, 52)",
		fontSize: "0.875rem",
		"word-break": "break-all",
		"font-weight": "bold",
		display: "flex",
		alignItems: "center",
	},
	chipContainer: {
		marginBottom: "1.5rem",
		display: "flex",
		flexWrap: "wrap",
	},
	imageTextContainer: {
		display: "flex",
		alignItems: "center",
	},
	imageContainer: {
		width: 50,
		height: 50,
		borderRadius: 50,
		marginRight: 10,
		position: "relative",
		border: `1px solid ${COLORS.BorderColor}`,
		overflow: "hidden",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
});

export default style;
