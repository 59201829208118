import React, { useEffect, useRef, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import classnames from "classnames";
import TableCell from "@material-ui/core/TableCell";
import style from "./style";
import { TableSortIcons } from "../../../UI/Icons";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

const HEADERS: any = [
  {
    title: "Executed On (UTC)",
    value: "executionendtime",
    visible: true,
    width: "215px",
    minWidth: "215px"
  },
  { title: "Status", value: "statusid__statusname", visible: true, width: "110px", minWidth: "110px"  },
  { title: "Tool Name / Tool Version", value: "toolid__toolname", visible: true, width: "210px", minWidth: "210px" },
  { title: "Job Name / Job ID", value: "jobname", visible: true, width: "190px", minWidth: "190px" },
  { title: "Message", value: "statusmessage", visible: true, width: "auto", minWidth:"150px" },
  { title: "", value: "", visible: true },
];

// create table head cells
const createTableHeadCells = (props: any, classes: any): React.JSX.Element[] => {
  const { orderBy, order, createSortHandler } = props;

  const TableCells: React.JSX.Element[] = [];
  for (const key in HEADERS) {
    if (HEADERS[key].visible) {
      let sortIcon = TableSortIcons.DEFAULT;
      if (_.isEqual(orderBy, HEADERS[key].value) && _.isEqual(order, "desc")) {
        sortIcon = TableSortIcons.UP;
      } else if (
        _.isEqual(orderBy, HEADERS[key].value) &&
        _.isEqual(order, "asc")
      ) {
        sortIcon = TableSortIcons.DOWN;
      }
      TableCells.push(
        <TableCell
          key={key}
          style={{
            width: HEADERS[key].width,
            minWidth: HEADERS[key].minWidth
          }}
          className={classes.headers}
          sortDirection={_.isEqual(orderBy, HEADERS[key].value) ? order : false}
        >
          <div
            onClick={() => {
              createSortHandler(HEADERS[key].value);
            }}
            style={{ cursor: "pointer" }}
          >
            {HEADERS[key].title}
            {!_.isEqual(HEADERS[key].title.length, 0) && <span className={classes.sortingIcons}>{sortIcon}</span>}
          </div>
        </TableCell>
      );
    }
  }
  return TableCells;
};

export default function GridHeader(props: any): React.JSX.Element {
  const classes = useStyles();
  const theadRef: any = useRef();
  const [touched, setTouched] = useState(false);

  const onScroll = (): void => {
    if (_.isNull(theadRef) || _.isUndefined(theadRef.current)) {
      return;
    }
    const element = theadRef.current.getBoundingClientRect();

    setTouched(_.isEqual(element.top - 56, 0));
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);
  const TableCells = createTableHeadCells(props, classes);

  return (
    <thead
      ref={theadRef}
      className={classnames(classes.gridHeader, {
        [classes.gridHeaderBoxShadow]: touched,
      })}
    >
      <tr>{TableCells}</tr>
    </thead>
  );
}
