import React from "react";
import Select, { components } from "react-select";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { CustomStyles, style } from "./style";
import { IFormElement } from "../../../Interface/FormElement/IFormElement";
import UILabel from "../ChildElements/Label";
import { UIErrorOrNoteMessage } from "../ChildElements/ErrorOrNote";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  formElement: IFormElement;
  onChange: any;
  formId: string;
  formGroupId: string;
};

export const UISelect = (props: props): React.JSX.Element => {
  const { formElement, onChange, formId, formGroupId } = props;
  const classes = useStyles();
  const errorMessage = formElement.touched ? formElement.errorMessage : "";

  const IsError = !_.isEqual(errorMessage.length, 0);
  const Disabled = formElement.disabled;

  return (
    <div className={classes.width100}>
      <UILabel formElement={formElement} />
      <div>
        <span className={classes.width100}>
          <Select
            isMulti={formElement.configuration.isMulti}
            isClearable={!formElement.validations.required}
            value={formElement.value}
            isDisabled={Disabled}
            components={{
              DropdownIndicator: (data: any) => {
                return (
                  <components.DropdownIndicator
                    {...data}
                    className="caret-select"
                  />
                );
              },
            }}
            onBlur={(): void => {
              onChange({
                formId,
                formGroupId,
                formElement,
                value: formElement.value,
              });
            }}
            styles={CustomStyles(IsError, Disabled, false)}
            onChange={(selectedOption: any) => {
              return onChange({
                formId,
                formGroupId,
                formElement,
                value: selectedOption,
              });
            }}
            options={formElement.configuration.dataSource}
            placeholder={formElement.configuration.placeholder}
          />
        </span>
        <UIErrorOrNoteMessage
          formElement={formElement}
          errorMessage={errorMessage}
        />
      </div>
    </div>
  );
};
