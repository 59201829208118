import * as _ from "lodash";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";

export class NewFormHelper {
  public buildNewForm(dataSources: any, toolForms: IForm[]): IForm[] {
    return toolForms.map((form: IForm) => {
      return {
        ...form,
        groups: form.groups.map((formGroup: IFormGroup) => {
          return {
            ...formGroup,
            elements: formGroup.elements.map((formElement) => {
              const Element = this.buildNewFormElement(formElement, dataSources);
              return {
                ...Element,
                isValid: Element.validations.required ? Element.isValid : true,
                touched: false,
              };
            }),
          };
        }),
      };
    });
  }

  public buildNewFormElement = (
    formElement: IFormElement,
    dataSources: any
  ): IFormElement => {
    let element;
    const DefaultValue = formElement.configuration.defaultValue;
    const ElementVisibility = formElement.configuration.visibility;

    switch (formElement.type) {
      case FORM_ELEMENT_TYPES.SELECT_INPUT:
        element = {
          ...formElement,
          configuration: {
            ...formElement.configuration,
            dataSource: dataSources[formElement.id],
          },
          value: DefaultValue || null,
        };
        break;
      case FORM_ELEMENT_TYPES.SWITCH:
        element = {
          ...formElement,
          value: DefaultValue || false,
        };
        break;
      default:
        element = {
          ...formElement,
          value: DefaultValue || "",
        };
        break;
    }
    return {
      ...element,
      visible: _.isUndefined(ElementVisibility) ? element.visible : ElementVisibility.newForm
    };
  };

}
