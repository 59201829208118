import * as _ from "lodash";
import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { IOption } from "../../Interface/IOption";
import { ValidationHelper } from "../Validations";
import { IFormElementUpdate } from "../../Interface/FormElement/IFormElementUpdate";
import { NewFormHelper } from "./New";
import { UtilHelper } from "../Utility";
import { FormElementHelper } from "./Element";
import { SingleSelectHelper } from "./SelectInput/SingleSelect";
import { MultiSelectHelper } from "./SelectInput/MultiSelect";
import { IFormElementValue } from "../../Interface/FormElement/IFormElementValue";

// Edit form class
export class EditFormHelper {
	private utilHelper: UtilHelper;
	private elementHelper: FormElementHelper;
	private singleSelect: SingleSelectHelper;
	private multiSelect: MultiSelectHelper;
	private newFormHelper: NewFormHelper;
	private validationHelper: ValidationHelper;
  
	constructor(){
	  this.utilHelper = new UtilHelper();
	  this.newFormHelper = new NewFormHelper();
	  this.elementHelper = new FormElementHelper();
	  this.singleSelect = new SingleSelectHelper();
	  this.multiSelect = new MultiSelectHelper();
	  this.validationHelper = new ValidationHelper();
	}

	/**
	 * Update the form
	 *
	 * @param {IForm[]} forms.
	 * @param {IElementUpdate} element.
	 * @param {boolean} isDelayed DEFAULT false. - Validate the field when use stop typing
	 * @return {IForm[]} forms.
	 */
	public updateForms = (forms: IForm[], element: IFormElementUpdate, elementValue: IFormElementValue): IForm[] => {
		const Forms: IForm[] = [];

		for (const form of forms) {
			const FormGroups = [];
			for (const formGroup of form.groups) {
				const FormElementArray = [];

				for (const formElement of formGroup.elements) {
					const Element = this.updateElement(formElement, element, elementValue);
					FormElementArray.push(Element);
				}
				FormGroups.push({
					...formGroup,
					elements: FormElementArray,
				});
			}
			Forms.push({
				...form,
				groups: FormGroups
			});
		}
		return Forms;
	}

	/**
	 * Update the element
	 *
	 * @param {IFormElement} formElement.
	 * @param {IElementUpdate} element.
	 * @param {boolean} isDelayed DEFAULT false.
	 * @return {IForm[]} forms.
	 */
	private updateElement = (formElement: IFormElement, element: IFormElementUpdate, elementValue: IFormElementValue): IFormElement => {
		let updatedElement = {
			...formElement,
		};


		if (_.isEqual(formElement.id, element.formElement.id)) {
			const value = element.value;

			updatedElement = {
				...updatedElement,
				touched: true,
				value,
				loading: element.loading
			};
		}
		else if(_.isEqual(formElement.type, FORM_ELEMENT_TYPES.SELECT_INPUT)
        &&  !this.validationHelper.isPreviousAndCurrentValueAreSame(
        	element.formElement,
        	elementValue
        )
        && !_.isEqual(formElement.configuration.parentElements.indexOf(element.formElement.id), -1)){
    		let errorMessage = "";
    		if(formElement.validations.required){
    			errorMessage = this.validationHelper.validate(formElement, 
    				null);
    		}
    		updatedElement = {
    			...formElement,
    			value : null,
    			touched: false,
    			errorMessage,
    			isValid: _.isEqual(errorMessage.length, 0),
    		};
    	}
		return {
			...updatedElement
		};
	}


	public async buildEditForm(dataSources: any, formData: any, user: string, profileForms: IForm[]): Promise<IForm[]> {
		let Forms: IForm[] = this.newFormHelper.buildNewForm(dataSources, profileForms).map((form: IForm) => ({
				...form,
				groups: form.groups.map((formGroup: IFormGroup) => ({
					...formGroup,
					elements: formGroup.elements.map((formElement: IFormElement) => {
						const Element = this.buildEditFormElement(formElement, formData);
						const validateHelper = new ValidationHelper();
						const ErrorMessage = validateHelper.validate(formElement, Element.value);

						return {
							...Element,
							errorMessage: ErrorMessage,
							isValid: _.isEqual(ErrorMessage.length, 0),
							touched: false,
						};
					})
				}))
			}));

		Forms = await this.validateParentChildRelationship(Forms);
		
		return Forms;
	}

	public buildEditFormElement = (
		formElement: IFormElement,
		formData: any,
	): IFormElement => {
		let element;
		const ElementVisibility = formElement.configuration.visibility;
		const DefaultValue = formElement.configuration.defaultValue;

		switch (formElement.type) {
			case FORM_ELEMENT_TYPES.SELECT_INPUT: {
				element = {
					...formElement,
					value: this.getSelectValue(formElement, formData),
				};
				break;
			}
			case FORM_ELEMENT_TYPES.SWITCH:
				element = {
					...formElement,
					value: _.isEqual(formData[formElement.id], 1),

				};
				break;
			case FORM_ELEMENT_TYPES.RADIO_INPUT: {
				element = {
					...formElement,
					value: formData[formElement.id] || DefaultValue,
				};
				break;
			}
			default:
				element = {
					...formElement,
					value: formData[formElement.id]
				};
				break;
		}
		return {
			...element,
			visible: _.isUndefined(ElementVisibility) ? element.visible : ElementVisibility.editForm
		};
	};

	private getSelectValue = (formElement: IFormElement, formData: any): any => {
		if (_.isNull(formData[formElement.id])) {
			return null;
		}
		if (!_.isUndefined(formElement.configuration.selectMetadata)
			&& formElement.configuration.isMulti) {
			const OptionValueKey: any = formElement.configuration.selectMetadata?.value;
			return formData[formElement.id].map((valOption: any) => {
				return formElement.configuration.dataSource.find((option: IOption) =>
					_.isEqual(valOption[OptionValueKey], option.value));
			});
		}
		const value: any = formElement.configuration.dataSource.find((option: IOption) =>
			_.isEqual(option.value, formData[formElement.id].value));
		return _.isUndefined(value) ? null : value;

	}

	private getAPIData = async (updatedForm: IForm,  element: IFormElement): Promise<any>=>{
		const Element = {...element};
		try{
		const APIData = await this.elementHelper.getAPIData(updatedForm, Element);
		const DataSource = _.isArray(APIData) ? APIData : [];
		Element.configuration.dataSource = DataSource;
		return Element;
		}
		catch(error:any){
		Element.apiError = this.utilHelper.getErrorMessage(error);
		Element.configuration.dataSource = [];
		return Element;
		}
	}

	private ifParentNotEmpty = async (UpdatedForm: IForm, parentElements: any, formElement: IFormElement): Promise<any>=>{
	  const IsAnyParentEmpty = this.elementHelper.isAnyParentEmpty(UpdatedForm, parentElements);
		  let Element = {...formElement};
		  const isDisabled = IsAnyParentEmpty;
		  if(!IsAnyParentEmpty &&
			  !this.utilHelper.isEmptyValue(Element.configuration.apiURL)){
			  
			  if(_.isEqual(Element.type, FORM_ELEMENT_TYPES.SELECT_INPUT)){
				  Element = await this.getAPIData(UpdatedForm, Element);
				  Element = Element.configuration.isMulti ? this.multiSelect.getMultiDropdownValue(Element)
						  :this.singleSelect.getSingleDropdownValue(Element);
			  }
		  }
		  else{
			  Element.value = null;
		  }
		  Element.touched = !_.isNull(Element.value);
		  Element.disabled = isDisabled || Element.readonly;
		  return Element;
	}

	         /**
     * Validate parent and child relationship for date and select
     *
     * @param {forms} IForm[].
     * @return {UpdatedForms} IForm[].
     */
             validateParentChildRelationship = async (forms: IForm[]): Promise<IForm[]> =>{
				const UPDATED_FORMS = [];
				for(let iForm=0;iForm<forms.length;iForm++){
				  let UpdatedForm = ({
					...forms[iForm],
					groups: forms[iForm].groups.map((group: IFormGroup)=>({
					  ...group,
					  elements: group.elements.map((element: IFormElement)=>({...element}))
					}))
				  });
				 
				  const FormGroups = UpdatedForm.groups;
					for(let iFormGroup=0;iFormGroup<FormGroups.length;iFormGroup++){
					  const FormElements = FormGroups[iFormGroup].elements;
			  
					  for(let iFormElement=0;iFormElement<FormElements.length;iFormElement++){
						const FormElement = FormElements[iFormElement];
			  
						let Element: IFormElement = {...FormElement};
						const ParentElements = Element.configuration.parentElements;
						
						if(!_.isEqual(ParentElements.length , 0)){
						  Element = await this.ifParentNotEmpty(UpdatedForm, ParentElements, Element);
						}
			  
						FormElements[iFormElement] = {
						  ...FormElements[iFormElement],
						  ...Element
						};
					  } 
					  FormGroups[iFormGroup] = {
						...FormGroups[iFormGroup],
						elements: FormElements
					  };
					}  
					UpdatedForm={
					  ...UpdatedForm,
					  groups: FormGroups
					};
					UPDATED_FORMS.push(UpdatedForm);
				}
				return UPDATED_FORMS;
			  }
}