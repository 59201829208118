import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "./style";
import { RadioButtons } from "../Radio";
import { CheckboxesGroup } from "../Checkbox";
import { UIDateRangePicker } from "../DateRangePicker";
import { UISwitch } from "../Switch";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UI_ELEMENT_TYPES } from "../../config";
import { FileInput } from "../UploadFile";
import { IForm } from "../../Forms/interface/IForm";
import { UIChips } from "../Chip";
import { UIThumbnail } from "../Thumbnail";
import { getTextInputElement } from "./TextInput";
import { getSelectElement } from "./Select";
import { getDatePicker } from "./DatePicker";
import { UIImageTextField } from "../ImageText";
import { UIDownload } from "../Download";
import {UILoadingCells} from "../LoadingCells";
import { GridDataSet } from "../GridDataSet";
import UIAddButton from "../AddButton";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  onValidateForms: any;
  formId: number;
  formGroupId: number;
  form: IForm;
  loading:boolean;
  ishavingParent:any;
};

// UIInput Component
export const UIInput = (props: props): JSX.Element => {
  const { formId, formGroupId, onChange, formElement,loading,ishavingParent} = props;

  const classes = useStyles();
  const Element = [];

  if(!_.isNull(formElement.configuration_api_url) && loading && formElement.loadingCells) {
    Element.push(
      <UILoadingCells formElement={formElement} key={formElement.id}/>
    );
  }
  // Text input
  else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)) {
    Element.push(getTextInputElement(props));
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.CHECKBOX_INPUT)) {
    // Checkbox input
    Element.push(
      <CheckboxesGroup
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.RADIO_INPUT)) {
    // Radio input
    Element.push(
      <RadioButtons
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DATERANGE_INPUT)) {
    // Daterange picker
    Element.push(
      <UIDateRangePicker
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SWITCH_INPUT)) {
    // Switch
    Element.push(
      <UISwitch
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)) {
    // Select input
    Element.push(getSelectElement(props));
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DATETIME_INPUT)) {
    // Date/datetime picker
    Element.push(getDatePicker(props));
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.FILE_INPUT)) {
    // File Upload
    Element.push(
      <FileInput
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.CHIP)) {
    // CHIP
    Element.push(<UIChips key={formElement.id} formElement={formElement} />);
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.THUMBNAIL)) {
    // Thumbnail
    Element.push(
      <UIThumbnail
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
      />
    );
  } else if (
    _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)
  ) {
    // Image Input
    Element.push(
      <UIImageTextField
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        onValidateForms={props.onValidateForms}
        formElement={formElement}
      />
    );
  } else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DOWNLOAD)) {
    // Hyperlink
    Element.push(<UIDownload key={formElement.id} formElement={formElement} />);
  }
  else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.GRID)) {
    // Hyperlink
    Element.push(
      <GridDataSet 
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        formElement={formElement}
        loading={loading}
        ishavingParent={ishavingParent}
        ></GridDataSet>
    );
  }
  else if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.ADD_BUTTON)) {
    Element.push(<UIAddButton
      key={formElement.id}
      formId={formId}
      formGroupId={formGroupId}
      formElement={formElement}
      onChange={onChange}
    />);
  }
  return (
    <div
      style={{
        width: `${formElement.width}%`,
        display: `${formElement.is_visible ? "block" : "none"}`,
      }}
      className={classes.input}
    >
      {Element}
    </div>
  );
};
