import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../UI/Theme/colors";

const style: any = (theme: Theme): any => ({
	toolName: {
		alignItems: "center",
		fontSize: 24,
		fontWeight: theme.typography.fontWeightBold,
		display: "flex",
	},
	toolHeader: {
		display: "block",
		padding: "32px 40px 24px 40px",
		height: 92,
		backgroundColor: COLORS.WHITE,
		alignItems: "center",
	},

});

export default style;