import { IFormElement } from "../../../interface/IFormElement";

// Validation helper for Regular Expression
export class RegexValidation{

    /**
     * Validate with regular expression
     *
     * @param {IFormElement} element.
     * @return {string} errorMessage.
     */
    validate = (formElement: IFormElement, elementValue: any) : string =>{

    	const RegExpression = new RegExp(formElement.regexp_pattern);

    	let errorMessage = "";
        
    	if(!RegExpression.test(elementValue)){
    		errorMessage = formElement.regexp_message; 
    	}
    	return errorMessage;
    }
}