import Colors from "../../utilstyle";

const style: any = () => ({
	inputElement:{
		border: `1px solid ${Colors.BorderColor} !important`,
		"&:hover": {
			border: `1px solid ${Colors.HoverColor} !important`,
		},
		"&.Mui-focused": {
			border: `1px solid ${Colors.FocusColor} !important`,
			color: Colors.FocusColor,
			"& > div > button > span > svg": {
				fill: Colors.FocusColor,
			},
		},
	},
});

export default style;
