import * as _ from "lodash";
import { IFormElement } from "../../../interface/IFormElement";
import { UtilHelper } from "../../Utils";
import {
	UI_DYNAMIC_CONFIG_ELEMENT_TYPES,
	UI_ELEMENT_TYPES
} from "../../../../config";
import { IFileUpload } from "../../../interface/File/IFileUpload";

// Validation helper for required field
export class RequiredValidation {
	private utilHelper: UtilHelper;

	constructor() {
		this.utilHelper = new UtilHelper();
	}

	/**
	 * If the element is empty or not
	 *
	 * @param {IFormElement} element.
	 * @param {any} elementValue.
	 * @return {string} errorMessages.
	 */
	validate = (formElement: IFormElement, elementValue: any): string => {
		let errorMessage = "";
		const RequiredMessage = `${formElement.labelName} is required.`;

		switch (formElement.type_id) {
			// Text box
			case UI_ELEMENT_TYPES.TEXT_INPUT:
			// Checkbox
			/* falls through */
			case UI_ELEMENT_TYPES.CHECKBOX_INPUT:
			// Radio
			/* falls through*/
			case UI_ELEMENT_TYPES.RADIO_INPUT:
				errorMessage = this.validateInputElements(formElement, elementValue, RequiredMessage);
				break;
			// Daterange picker
			case UI_ELEMENT_TYPES.DATERANGE_INPUT: {
				errorMessage = this.validateDateRange(elementValue, RequiredMessage);
				break;
			}
			// Select
			case UI_ELEMENT_TYPES.SELECT_INPUT: {
				errorMessage = this.validateSelectInput(formElement, elementValue, RequiredMessage);
				break;
			}
			// Thubmnail
			case UI_ELEMENT_TYPES.THUMBNAIL: {
				errorMessage = this.validateThubmbnailInput(elementValue, RequiredMessage);
				break;
			}
			// Image with Text field
			case UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD: {
				const {
					message
				} = this.validateImageInput(elementValue, formElement.labelName);
				errorMessage = message;
				break;
			}
    		// Date picker 
    	case UI_ELEMENT_TYPES.DATETIME_INPUT:
    		errorMessage = _.isNull(elementValue) ? RequiredMessage : "";
    		break;
    		// File Upload 
    	case UI_ELEMENT_TYPES.FILE_INPUT:
    		errorMessage = this.validateFileinput(formElement, elementValue, RequiredMessage);
			break;
		case UI_ELEMENT_TYPES.GRID:
			errorMessage = this.validateGrid(elementValue, RequiredMessage);
			break;
    	}
    	return errorMessage;
    }
	validateGrid = (elementValue: any, requiredMessage: string): any =>{
              if(_.isNull(elementValue) ||_.isEmpty(elementValue)){
				return requiredMessage;
			  }
			  else{
				return "";
			  }
	}
	validateInputElements = (formElement: IFormElement, elementValue: any, requiredMessage: string): string =>{
		if(formElement.is_number){
			if(_.isEqual((elementValue || "").toString().trim().length, 0)){
				return requiredMessage;
			}
			if (formElement.is_decimal) {
				return "";
			}
			return _.isNull(this.utilHelper.getValidNumber(elementValue, formElement))
				? requiredMessage : "";
		}
		return _.isEqual((elementValue || "").toString().trim().length, 0)
			? requiredMessage : "";
	}

	validateDateRange = (elementValue: any, requiredMessage: string): string => {
		if (_.isNull(elementValue)) {
			return requiredMessage;
		}
		const DateRange = (elementValue || []).filter((value: any) => _.isNull(value));
		return _.isEqual(DateRange.length, 2) ? requiredMessage : "";
	}

	validateSelectInput = (formElement: IFormElement, elementValue: any, requiredMessage: string): string => {
		if (!_.isNull(formElement.dynamic_configuration)
			&& _.isEqual(formElement.dynamic_configuration.elementType, UI_DYNAMIC_CONFIG_ELEMENT_TYPES.METRICS)) {
			return !_.isNull(formElement.value) && !_.isEqual((formElement.value || []).length, 0)
				? "" : requiredMessage;
		}
		return !_.isNull(elementValue) && !_.isEqual((elementValue || []).length, 0)
			? "" : requiredMessage;
	}

	validateThubmbnailInput = (elementValue: any, requiredMessage: string): string => {
		return !_.isNull(elementValue)
			? "" : requiredMessage;
	}

	validateImageInput = (elementValue: any, labelName: string): any => {

		if (_.isNull(elementValue)) {
			return {
				message: `${labelName} is required.`,
				error: "both"
			};
		}
		const TextField = (elementValue.text || "").toString().trim();

		if (_.isEmpty(TextField) && _.isNull(elementValue.file)) {
			return {
				message: `${labelName} is required.`,
				error: "both"
			};
		}
		if (_.isEmpty(TextField)) {
			return {
				message: `Please enter ${labelName}.`,
				error: "text"
			};
		}
		if (_.isNull(elementValue.file)) {
			return {
				message: "Please upload an image.",
				error: "file"
			};
		}
		return {
			message: "",
			error: ""
		};

	}

	validateFileinput = (formElement: IFormElement, elementValue: any, requiredMessage: string): string => {
		if (formElement.load_data_to_grid) {

			if (_.isNull(elementValue)) {
				return "Please upload a file.";
			}
			if (!_.isEmpty(elementValue.rejectedFiles)) {
				return formElement.error;
			}
			if (_.isEqual(formElement.dynamic_fields_values.length, 0)) {
				return "Please upload a file.";
			}

		}

		if (!_.isNull(elementValue)) {

			if (!_.isEmpty(elementValue.rejectedFiles)) {
				return "";
			}
			if (_.isEqual(elementValue.isFileLimitExceeded, true) && _.isEmpty(elementValue.rejectedFiles) && _.isEmpty(elementValue.acceptedFiles)) {
				return "";
			}
			const AcceptedFiles = elementValue.acceptedFiles.filter((file: IFileUpload) => !_.isEqual(file.status, "error"));

			return _.isEqual(AcceptedFiles.length, 0) ? requiredMessage : "";
		}
		return requiredMessage;
	}
}
