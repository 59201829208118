import Colors from "../../utilstyle";

const style:any = () => ({
	inputAdornment:{
		position:"relative",
	},
	arrowUp:{
		position: "absolute",
		top: -16,
		right: -6,
		cursor:"pointer",
		color: Colors.HoverColor
	},
	arrowDown:{
		position: "absolute",
		top: -3,
		right: -6,
		cursor:"pointer",
		color:Colors.HoverColor
	},
});

export default style;