import React, { Fragment } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import FormGroup from "@material-ui/core/FormGroup";
import * as _ from "lodash";
import classNames from "classnames";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { UILabel } from "../Child/Label";
import { UIErrorOrNoteMessage } from "../Child/ErrorOrNote";
import style from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { ifChecked, ifNotChecked } from "./Utility";
// Create styles
const useStyles = makeStyles(() => createStyles(style()));

type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

export function CheckboxesGroup(props: props): JSX.Element {
  const classes = useStyles();
  const { formElement, onChange, formId, formGroupId } = props;
  const ErrorMessage = formElement.touched ? formElement.error : "";
  const Disabled = formElement.disabled || formElement.read_only;

  const Values = (formElement.value || "")
    .split(",")
    .filter((val: any) => !_.isEqual(val.length, 0));

  const handleChange = (event: any): any => {
    const { value, checked } = event.target;
    let filterElement: any[] = [];

    if (checked) {
      filterElement = ifChecked(formElement, value);
    } else {
      filterElement = ifNotChecked(formElement, value, Values);
    }
    return onChange({
      formId,
      formGroupId,
      formElement,
      value: _.uniq(filterElement).toString(),
    });
  };

  const CheckboxDS = formElement.dataSource.map(
    (checkbox: any, index: number) => (
      <FormControlLabel
        key={`${checkbox.value}${index}`}
        value={checkbox.value}
        control={
          <Checkbox
            onChange={handleChange}
            name={checkbox.label}
            checked={!_.isEqual(Values.indexOf(checkbox.value), -1)}
            className={classes.root}
            disableRipple
            disabled={Disabled}
            color="primary"
            checkedIcon={
              <span className={classNames(classes.icon, classes.checkedIcon)} />
            }
            icon={<span className={classes.icon} />}
            inputProps={{ "aria-label": "decorative checkbox" }}
          />
        }
        label={checkbox.label}
        labelPlacement="end"
      />
    )
  );

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <FormGroup className={classes.formGroup}>{CheckboxDS}</FormGroup>
      <UIErrorOrNoteMessage
        formElement={formElement}
        errorMessage={Disabled ? "" : ErrorMessage}
      />
    </Fragment>
  );
}
