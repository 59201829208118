import { IOption } from "../../../../UI/Elements/Forms/interface/IOption";
import { TEXT_ALIGNMENT, ELEMENT_TYPES, GRID_CELL_ELEMENT } from "../../configuration";

import { IFormElement } from "../../Interfaces/Form/FormElement/IFormElement";
import { IForm } from "../../Interfaces/Form/IForm";
import { IGridHeader } from "../../Interfaces/Grid/IGridHeader";
import {
	GET_SUBAGENCIES_BY_AGENCY, GET_BRANDS_BY_CLIENT,
	GET_VERTICAL_BY_BRAND
} from "../../../../api/APIPaths/mapping";
import { IStatusIndicator } from "../interface/IStatusIndicator";

export const getMetaFormConfig = (formData: any): IForm => {

	const Account: IFormElement = {
		validations: {
			isNumber: false,
			isEmail: false,
			required: false
		},
		disabled: false,
		id: "account",
		touched: false,
		isValid: true,
		type: ELEMENT_TYPES.DISPLAY_ACCOUNT,
		
		errorMessage: "",
		value: formData.account || null,
		visible: true,
		configuration: {
			dataSource: [],
			dataSourceKey: "",
			isVisible: true,
			isMulti: true,
			multiRows: 2,
			title: "",
			placeholder: "",
			allowAll: false,
			defaultValue: [],
			parentElements: [],
			readOnly: {
				newForm: false,
				editForm: true,
			},
			apiURL: "",
			
		},
		notification: {
			managerId: "businessmanagerId",
			label: "Business Manager Account",
			managerName: "businessmanagerName"

		},
		readOnlyOptions: {
			subtitle: "",
			title: "",
			keys: {
				subtitle: "adaccountId",
				title: "adaccountName",
			}
		},
		readOnly: true,
		apiError: "",
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
	};


	const Agency: IFormElement = {
		disabled: false,
		isValid: true,
		id: "agency",
		type: ELEMENT_TYPES.SELECT_INPUT,
		touched: false,
		errorMessage: "",
		visible: true,
		value: formData.agency || null,
		validations: {
			isNumber: false,
			required: true,
			isEmail: false,
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
		configuration: {
			dataSourceKey: "agencies",
			dataSource: [],
			isVisible: true,
			multiRows: 2,
			isMulti: false,
			placeholder: "Select Agency",
			defaultValue: null,
			allowAll: false,
			title: "Agency",
			apiKey: "agency_id",
			parentElements: [],
		},
		
	};

	const SubAgency: IFormElement = {
		disabled: false,
		id: "subagency",
		touched: false,
		validations: {
			isNumber: false,
			isEmail: false,
			required: false,
		},
		isValid: true,
		type: ELEMENT_TYPES.SELECT_INPUT,
		visible: true,
		errorMessage: "",
		value: formData.subagency || null,
		configuration: {
			dataSource: [],
			dataSourceKey: "subagencies",
			isMulti: false,
			multiRows: 2,
			isVisible: true,
			placeholder: "Select SubAgency",
			apiURL: GET_SUBAGENCIES_BY_AGENCY,
			defaultValue: null,
			allowAll: false,
			title: "SubAgency",
			parentElements: ["agency"],
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
	};

	const Market: IFormElement = {
		isValid: true,
		disabled: false,
		id: "market",
		touched: false,
		validations: {
			required: true,
			isEmail: false,
			isNumber: false,
		},
		type: ELEMENT_TYPES.SELECT_INPUT,
		visible: true,
		errorMessage: "",
		value: formData.market || null,
		configuration: {
			dataSource: [],
			dataSourceKey: "markets",
			isMulti: false,
			multiRows: 2,
			placeholder: "Select Market",
			isVisible: true,
			title: "Market",
			allowAll: false,
			defaultValue: null,
			parentElements: [],
		},
		readOnly: false,
		highlightRemovedOption: false,
		apiError: "",
		highlightRemovedOptionError: "",
	};

	const Client: IFormElement = {
		type: ELEMENT_TYPES.SELECT_INPUT,
		disabled: false,
		id: "client",
		touched: false,
		isValid: true,
		
		errorMessage: "",
		visible: true,
		value: formData.client || null,
		configuration: {
			dataSourceKey: "clients",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Client",
			note: "For adding new clients please contact to <a href='mailTo:gdt-automations-support@groupm.tech'>gdt-automations-support@groupm.tech</a>",
			title: "Client",
			defaultValue: null,
			allowAll: false,
			tooltip: "This field displays 2500 clients by default. To search a client, type the client's name and select from the dropdown.",
			parentElements: [],
			isAsyncSelect: true,
			asyncRequestKey: "client_name",
			apiKey: "client_id",
			isCreatableSelect: false,
			apiURL: "api/mapping/v2/client/",
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
		validations: {
			required: true,
			isNumber: false,
			isEmail: false,
		},
	};
	const Brand: IFormElement = {
		errorMessage: "",
		id: "brand",
		disabled: false,
		touched: false,
		isValid: true,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		visible: true,
		value: formData.brand || null,
		highlightRemovedOptionError: "",
		apiError: "",
		configuration: {
			dataSourceKey: "brands",
			isMulti: false,
			dataSource: [],
			isVisible: true,
			placeholder: "Select Brand",
			title: "Brand",
			multiRows: 2,
			defaultValue: null,
			parentElements: ["client"],
			allowAll: false,
			apiURL: GET_BRANDS_BY_CLIENT,
			apiKey: "brand_id",
		},
		readOnly: false,
		highlightRemovedOption: false
	};

	const Vertical: IFormElement = {
	
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.vertical || null,
		configuration: {
			dataSource: [],
			dataSourceKey: "verticals",
			isMulti: false,
			multiRows: 2,
			isVisible: true,
			placeholder: "Select Vertical",
			defaultValue: [],
			title: "Vertical",
			allowAll: false,
			parentElements: ["brand"],
			apiURL: GET_VERTICAL_BY_BRAND,
			
			selectValueIfEmpty: {
				value: null,
				valueLabelKey: "None",
			},
			readOnly: {
				newForm: true,
				editForm: true,
			},
		},
		readOnly: true,
		readOnlyOptions: {
			keys: {
				subtitle: "",
				title: "label",
			},
			subtitle: "",
			title: "",
		},
		apiError: "",
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
		id: "vertical",
	};


	const IncludeInAdoptionStats: IFormElement = {
		disabled: false,
		type: ELEMENT_TYPES.SWITCH,
		isValid: true,
		touched: false,
		validations: {
			isEmail: false,
			required: true,
			isNumber: false,
		},
		id: "includeInAdoptionStats",
		value: formData.includeInAdoptionStats || true,
		visible: true,
		errorMessage: "",
		configuration: {
			dataSource: [],
			isVisible: true,
			multiRows: 2,
			placeholder: "",
			defaultValue: 1,
			isMulti: false,
			title: "Include in Adoption Statistics as part of Usage Dashboard",
			dataSourceKey: "",
			parentElements: [],
			allowAll: false,
		},
		apiError: "",
		readOnly: false,
		highlightRemovedOption: false,
		highlightRemovedOptionError: "",
	};
	

	return {
		id: "mappingMapperForm",
		title: "",
		isValid: true,
		isVisible: true,
		groups: [{
			id: "mappingMapperFormGroup",
			elements: [Account, Agency, SubAgency, Market, Client, Brand, Vertical, IncludeInAdoptionStats],
			isVisible: true,
			title: ""
		}]
	};
};

export const getMetaBulkEditFormConfig = (formData: any): IForm => {

	const Account: IFormElement = {
		id: "account",
		disabled: false,
		isValid: true,
		touched: false,
		type: ELEMENT_TYPES.DISPLAY_ACCOUNT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.account || null,
		configuration: {
			dataSourceKey: "",
			dataSource: [],
			isMulti: true,
			isVisible: true,
			multiRows: 2,
			placeholder: "",
			title: "",
			defaultValue: [],
			allowAll: false,
			parentElements: [],
			apiURL: "",
			readOnly: {
				editForm: true,
				newForm: false
			}
		},
		notification: {
			label: "Business Manager Account",
			managerId: "businessmanagerId",
			managerName: "businessmanagerName"

		},
		readOnly: true,
		readOnlyOptions: {
			title: "",
			subtitle: "",
			keys: {
				title: "adaccountName",
				subtitle: "adaccountId",
			}
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};


	const Agency: IFormElement = {
		id: "agency",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group1",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.agency || null,
		configuration: {
			dataSourceKey: "agencies",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Agency",
			title: "Agency",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
			apiKey: "agency_id",
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const SubAgency: IFormElement = {
		id: "subagency",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group1",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.subagency || null,
		configuration: {
			dataSourceKey: "subagencies",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select SubAgency",
			title: "SubAgency",
			defaultValue: null,
			allowAll: false,
			parentElements: ["agency"],
			apiURL: GET_SUBAGENCIES_BY_AGENCY
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Market: IFormElement = {
		id: "market",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group2",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.market || null,
		configuration: {
			dataSourceKey: "markets",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Market",
			title: "Market",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Client: IFormElement = {
		id: "client",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group3",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.client || null,
		configuration: {
			dataSourceKey: "clients",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Client",
			title: "Client",
			defaultValue: null,
			allowAll: false,
			parentElements: [],
			isAsyncSelect: true,
			apiKey: "client_id",
			asyncRequestKey: "client_name",
			apiURL: "api/mapping/v2/client/",
			isCreatableSelect: false,
			tooltip: "This field displays 2500 clients by default. To search a client, type the client's name and select from the dropdown.",
			note: "For adding new clients please contact to <a href='mailTo:gdt-automations-support@groupm.tech'>gdt-automations-support@groupm.tech</a>"
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};
	const Brand: IFormElement = {
		id: "brand",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group3",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		errorMessage: "",
		visible: true,
		value: formData.brand || null,
		configuration: {
			dataSourceKey: "brands",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Brand",
			title: "Brand",
			defaultValue: null,
			allowAll: false,
			parentElements: ["client"],
			apiURL: GET_BRANDS_BY_CLIENT,
			apiKey: "brand_id",
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};

	const Vertical: IFormElement = {
		id: "vertical",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group3",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: false
		},
		errorMessage: "",
		visible: true,
		value: formData.vertical || null,
		configuration: {
			dataSourceKey: "verticals",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Vertical",
			title: "Vertical",
			defaultValue: [],
			allowAll: false,
			parentElements: ["brand"],
			apiURL: GET_VERTICAL_BY_BRAND,
			readOnly: {
				editForm: true,
				newForm: true
			},
			selectValueIfEmpty: {
				valueLabelKey: "None",
				value: null,
			}
		},
		readOnly: true,
		readOnlyOptions: {
			title: "",
			subtitle: "",
			keys: {
				title: "label",
				subtitle: "",
			}
		},
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};


	const IncludeInAdoptionStats: IFormElement = {
		id: "includeInAdoptionStats",
		disabled: false,
		isValid: true,
		touched: false,
		groupCategory: "Group4",
		type: ELEMENT_TYPES.SELECT_INPUT,
		validations: {
			isEmail: false,
			isNumber: false,
			required: true
		},
		visible: true,
		errorMessage: "",
		value: formData.includeInAdoptionStats || null,
		configuration: {
			dataSourceKey: "includeInAdoptionStats",
			dataSource: [],
			isMulti: false,
			isVisible: true,
			multiRows: 2,
			placeholder: "Select Include in Adoption Statistics",
			title: "Include in Adoption Statistics as part of Usage Dashboard",
			defaultValue: null,
			allowAll: false,
			parentElements: []
		},
		readOnly: false,
		apiError: "",
		highlightRemovedOptionError: "",
		highlightRemovedOption: false
	};
	

	return {
		id: "mappingMapperForm",
		isVisible: true,
		title: "",
		isValid: true,
		groups: [{
			elements: [Account, Agency, SubAgency, Market, Client, Brand, Vertical, IncludeInAdoptionStats],
			id: "mappingMapperFormGroup",
			title: "",
			isVisible: true,
		}]
	};
};

export const META_GRID_HEADERS: IGridHeader[] = [{
	id: "id",
	visible: false,
	type: ELEMENT_TYPES.ID,
	title: "",
	sortingKey: "id",
	isMulti: false,
	disableSorting: true,
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT,
	defaultSortBy: false,
}, {
	id: "account",
	visible: true,
	defaultSortBy: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	isMulti: false,
	sortingKey: "account",
	title: "Ad Account",
	disableSorting: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLiner: {
		subtitleKey: "adaccountId",
		titleKey: "adaccountName",
	},
	showHideText: false,
},
{
	id: "status",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT,
	isMulti: false,
	width: 80,
	title: "Status",
	sortingKey: "status",
	disableSorting: false,
	statusIndicator: true,
	showHideText: false,
}, {
	id: "agency",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	title: "Agency / SubAgency",
	isMulti: false,
	disableSorting: false,
	sortingKey: "agency",
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLiner: {
		titleKey: "agencyName",
		subtitleKey: "subagencyName"
	},
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
}, {
	id: "market",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	isMulti: false,
	cellTwoLiner: {
		subtitleKey: "marketCode",
		titleKey: "marketName",
	},
	sortingKey: "market",
	title: "Market",
	disableSorting: false,
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
}, {
	id: "client",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	title: "Client / Brand",
	sortingKey: "client",
	disableSorting: false,
	isMulti: false,
	showHideText: true,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLiner: {
		subtitleKey: "brandName",
		titleKey: "clientName",
	},
	alignment: TEXT_ALIGNMENT.DEFAULT,
},
{
	cellTwoLiner: {
		titleKey: "verticalName",
		subtitleKey: ""
	},
	id: "vertical",
	defaultSortBy: true,
	visible: true,
	type: ELEMENT_TYPES.TEXT_INPUT,
	title: "Vertical",
	isMulti: false,
	disableSorting: false,
	sortingKey: "vertical",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	showHideText: false,
}, {
	id: "mappedby",
	visible: true,
	title: "Mapped by",
	type: ELEMENT_TYPES.TEXT_INPUT,
	defaultSortBy: true,
	isMulti: true,
	width: 125,
	sortingKey: "mappedby",
	alignment: TEXT_ALIGNMENT.DEFAULT,
	disableSorting: false,
	showHideText: false,
	cellTwoLinerTooltip: true,
	cellStyle: GRID_CELL_ELEMENT.TWO_LINER_TITLE_SUBTITLE,
	cellTwoLiner: {
		subtitleKey: "mappedDate",
		tooltip: "email",
		titleKey: "name",
	},

}, {
	defaultSortBy: true,
	id: "includeInAdoptionStats",
	title: "Dashboard",
	width: 105,
	visible: true,
	type: ELEMENT_TYPES.SWITCH,
	sortingKey: "includeInAdoptionStats",
	isMulti: false,
	disableSorting: true,
	showHideText: false,
	alignment: TEXT_ALIGNMENT.DEFAULT,
	cellStyle: GRID_CELL_ELEMENT.DEFAULT,
	tooltip: "Include in Adoption Statistics as part of Usage Dashboard",
}
];

export const getAccountsDropdown = (value: any, dataSource: IOption[]): IFormElement => {
	return {
		id: "accounts",
		disabled: false,
		isValid: false,
		touched: false,
		validations: {
			isNumber: false,
			isEmail: false,
			required: true,
		},
		type: ELEMENT_TYPES.SELECT_INPUT,
		errorMessage: "",
		value: value || null,
		visible: true,
		configuration: {
			dataSource: dataSource,
			dataSourceKey: "agencies",
			isMulti: false,
			multiRows: 2,
			placeholder: "Select Agency",
			isVisible: true,
			allowAll: false,
			title: "Agency",
			parentElements: [],
			defaultValue: null,
		},
		highlightRemovedOptionError: "",
		highlightRemovedOption: false,
		readOnly: false,
		apiError: "",
	};
};

export const statusIndicator = (statusName: string): string => {
	const StatusConfig: IStatusIndicator | any = {
		"Mapped*": "Indicates the account is mapped but the account mapping has discrepancy. Click the edit action to correct.",
		Mapped: "Indicates that the account is mapped.",
		Unmapped: "Indicates that the account is not mapped.",
		Inactive: "Indicates that the account is removed from the selected Manager Account."
	};
	return StatusConfig[statusName];
};

export const UI_AUTH_HEADER_NAMES = {
	GOOGLE: "x-portal-google-auth-refresh-token",
	FACEBOOK: "x-portal-facebook-auth-access-token"
};