import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import style from "./style";
import Grid from "@material-ui/core/Grid";
import { TextPlaceholder } from "../../Tile/Content/TextPlaceholder";
import { SingleLineText } from "../../Tile/Content/SingleLine";
import { TwoLineText } from "../../Tile/Content/TwoLine";
import { SingleLineEllipsisText } from "../../../Layout/Elements/Text/SingleLineEllipsis";

// Defined input props types
type props = {
  mapperHeaderData?: any;
};

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const MapperHeader = (props: props): React.JSX.Element => {
  const { mapperHeaderData } = props;

  const classes = useStyles();
  return (
    <>
      <Grid
        container
        className={classes.gridContainer}
        justifyContent="space-between"
      >
        <Grid
          item
          lg={5}
          md={12}
          sm={12}
          xs={12}
          className={classes.instructions}
        >
          <TwoLineText
            text={mapperHeaderData.instructions}
            tooltip={mapperHeaderData.instructions}
          />
        </Grid>
        <Grid
          item
          lg={6}
          md={12}
          sm={12}
          xs={12}
          className={classes.flexContainer}
          justifyContent="space-between"
        >
          <div className={classes.container}>
            <div className={classes.multiDataWidth}>
              <TextPlaceholder
                labelName="Manager"
                content={
                  <SingleLineText
                    text={mapperHeaderData.manager.label}
                    tooltip={`${mapperHeaderData.manager.label} - ${mapperHeaderData.manager.value}`}
                    disableTooltip={false}
                  />
                }
              />
            </div>
            <div className={classes.multiDataWidth}>
              <TextPlaceholder
                labelName="Agency(s)"
                content={
                  <SingleLineEllipsisText
                    text={mapperHeaderData.agencies.join(", ")}
                  />
                }
              />
            </div>
            <div className={classes.regionDataWidth}>
              <TextPlaceholder
                labelName="Region"
                content={
                  <SingleLineEllipsisText
                    text={mapperHeaderData.region.value}
                  />
                }
              />
            </div>
            <div className={classes.multiDataWidth}>
              <TextPlaceholder
                labelName="Market(s)"
                content={
                  <SingleLineEllipsisText
                    text={mapperHeaderData.markets.join(", ")}
                  />
                }
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
