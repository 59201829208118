import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GridTable } from "../Layout/Grid";
import { FORM_TITLE, NOTIFICATION_TYPE, SORT_ORDER } from "../configuration";
import { MappingPage } from "../Layout/Page";
import { getFormConfig, GRID_HEADERS } from "./configuration";
import { IForm } from "../Interfaces/Form/IForm";
import { SidebarComponent } from "../Layout/Grid/Sidebar";
import { EditFormHelper, NewFormHelper } from "../Layout/Form/Helpers/Forms";
import { UIOutlineButton } from "../../../UI/Buttons";
import COLORS from "../../../UI/Theme/colors";
import style from "./style";
import { Axios } from "../../../api/service/Axios";
import {
  GET_ALL_MAPPING_ADMIN_DS,
  GET_MAPPING_CHANNEL_DATA,
  GET_MAPPING_CHANNEL_BY_ID,
  CREATE_MAPPING_CHANNEL,
  UPDATE_MAPPING_CHANNEL_BY_ID,
} from "../../../api/APIPaths/mapping";
import { CircularProgress } from "../../../UI/Progress";
import { IGridFilter } from "../Interfaces/Filter/IGridFilter";
import * as AdminHelper from "./Helper";
import { SnackbarAlert } from "../../../UI/Snackbar";
import { catchError, catchSuccessMessage } from "../Utility/ErrorMessage";
import { ISidebar } from "../Interfaces/Filter/IGridSidebar";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

//Mapping Admin Component
const MappingAdmin = (): React.JSX.Element => {
  const classes = useStyles();
  const [gridData, setGridData] = useState<any>([]);
  const [recordCount, setRecordCount]=useState<any>();
  const [filter, setFilter] = useState<IGridFilter>({
    order: SORT_ORDER.ASC,
    orderBy: "channelName",
    pageNumber: 0,
    pageSize: 12,
  });
  const bundle = useSelector((state: any) => state.initial.bundle);

  const [initLoader, setInitLoader] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  const [sidebar, setSidebar] = useState<ISidebar | null>(null);

  const getGridData = async (): Promise<any> => {
    const RESPONSE = await Axios.get(GET_MAPPING_CHANNEL_DATA);
    const UPDATED_GRID_DATA = AdminHelper.formatGridData(RESPONSE.data, filter);
    setGridData(UPDATED_GRID_DATA);
    setRecordCount(UPDATED_GRID_DATA.length);
  };

  const setAlert = (message: string, variant: NOTIFICATION_TYPE): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const getInitData = async (): Promise<void> => {
    const DATASOURCES = await Axios.get(GET_ALL_MAPPING_ADMIN_DS);
    const newFormHelper = new NewFormHelper();
    const form = newFormHelper.buildNewForm(
      getFormConfig({}),
      DATASOURCES.data
    );
    setSidebar((prevState: any): any => ({
      ...prevState,
      dataSources: DATASOURCES.data,
      form,
      title: FORM_TITLE.NEW,
      open: false,
    }));

    await getGridData();
  };

  useEffect(() => {
    const getInitialData = async (): Promise<void> => {
      setInitLoader(true);
      try {
        await getInitData();
        setInitLoader(false);
      } catch (err: any) {
        const errorMessage = catchError(
          err,
          bundle.MAPPING_ADMIN_INITIAL_DATA_ERROR_MSG
        );
        setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        setInitLoader(false);
      }
    };
    getInitialData();
  }, []);

  const setNewForm = async (): Promise<void> => {
    const newFormHelper = new NewFormHelper();
    const form = await newFormHelper.buildNewForm(
      getFormConfig({}),
      sidebar!.dataSources
    );

    setSidebar((prevState: any): any => {
      return {
        ...prevState,
        form,
        title: FORM_TITLE.NEW,
        open: true,
      };
    });
  };

  const sortHandler = (orderBy: any, order: SORT_ORDER): void => {
    const UPDATED_GRID_DATA = _.orderBy(gridData, [orderBy], [order]);
    setFilter((prevState: IGridFilter) => ({
      ...prevState,
      order,
      orderBy,
    }));
    setGridData(UPDATED_GRID_DATA);
  };

  const onEdit = (id: number): void => {
    setLoading(true);
    Axios.get(GET_MAPPING_CHANNEL_BY_ID(id))
      .then(async (response: any) => {
        const editFormHelper = new EditFormHelper();
        const FormData = AdminHelper.buildFormData(response.data);
        const form = await editFormHelper.buildEditForm(
          getFormConfig({}),
          sidebar?.dataSources,
          FormData
        );

        setSidebar((prevState: ISidebar | null): any => {
          return {
            ...prevState,
            title: FORM_TITLE.EDIT,
            id,
            open: true,
            form,
          };
        });
        setLoading(false);
      })
      .catch((err: string) => {
        const errorMessage = catchError(
          err,
          bundle.MAPPING_ADMIN_GET_CHANNEL_ERROR_MSG
        );
        setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        setLoading(false);
      });
  };

  const onSave = async (form: IForm): Promise<void> => {
    if (_.isNull(sidebar)) {
      return;
    }

    try {
      setLoading(true);
      const FormData = AdminHelper.buildSubmitFormData(form);
      if (_.isEqual(sidebar.title, FORM_TITLE.NEW)) {
        try {
          const Response = await Axios.post(CREATE_MAPPING_CHANNEL, FormData);
          await getInitData();
          const SuccessMessage = catchSuccessMessage(
            Response,
            bundle.MAPPING_ADMIN_CREATE_SUCCESS_MSG
          );
          setAlert(
            SuccessMessage,
            Response.data.warning
              ? NOTIFICATION_TYPE.WARNING
              : NOTIFICATION_TYPE.SUCCESS
          );
        } catch (err: any) {
          const errorMessage = catchError(
            err,
            bundle.MAPPING_ADMIN_CREATE_ERROR_MSG
          );
          setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        }
      } else {
        try {
          const Response = await Axios.put(
            UPDATE_MAPPING_CHANNEL_BY_ID(sidebar.id || 0),
            FormData
          );
          await getInitData();
          const SuccessMessage = catchSuccessMessage(
            Response,
            bundle.MAPPING_ADMIN_UPDATE_SUCCESS_MSG
          );

          setAlert(
            SuccessMessage,
            Response.data.warning
              ? NOTIFICATION_TYPE.WARNING
              : NOTIFICATION_TYPE.SUCCESS
          );
        } catch (err: any) {
          const errorMessage = catchError(
            err,
            bundle.MAPPING_ADMIN_UPDATE_ERROR_MSG
          );
          setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        }
      }
      setLoading(false);
    } catch (err: any) {
      const errorMessage = catchError(err, "Error occured");
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  if (initLoader) {
    return <CircularProgress />;
  }

  let addBtn = _.isNull(sidebar) ? (
    <span />
  ) : (
    <UIOutlineButton
      cssStyle={{
        backgroundColor: COLORS.WHITE,
      }}
      isOutline={true}
      onClick={() => setNewForm()}
      btnText="Add"
    />
  );

  if (!_.isNull(sidebar) && _.isEmpty(sidebar.dataSources.channels)) {
    addBtn = <span />;
  }

  return (
    <>
      <Helmet>
        <title>{bundle.MAPPING_ADMIN_PAGE_TITLE}</title>
      </Helmet>
      <MappingPage
        pageTitle={bundle.MAPPING_ADMIN_PAGE_TITLE}
        recordCount={recordCount}
        enableContentBgColor={true}
        header={<div className={classes.headerFooterContainer}>{addBtn}</div>}
        footer={<></>}
        tabComponent={<><br/></>}
        hideContent={false}
      >
        <GridTable
          filter={filter}
          onEdit={onEdit}
          headers={GRID_HEADERS}
          gridData={gridData}
          sortHandler={sortHandler}
          loading={false}
          isGridAction={false}
          loadHeader={false}
        />
        {!_.isNull(sidebar) && (
          <SidebarComponent
            width={624}
            formTitle={sidebar.title}
            btnSaveText="Save"
            btnCancelText="Cancel"
            onSave={onSave}
            onCancel={() => {
              setSidebar((prevState: ISidebar | null): any => ({
                ...prevState,
                open: false,
              }));
            }}
            formData={sidebar.form}
            open={sidebar.open}
          />
        )}
        {loading && <CircularProgress />}
        <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
        />
      </MappingPage>
    </>
  );
};

export default MappingAdmin;
