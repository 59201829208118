import React, { Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "../style";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import { IconPlaceholer } from "../IconPlaceholder";

// Create styles
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
};

// UIImageTextReadOnlyPlaceholder Component
export const UIImageTextReadOnlyPlaceholder = (props: props): JSX.Element => {
  const { formElement } = props;
  const Value = formElement.value || "";
  const classes = useStyles();

  if (formElement.hideBasedOnParent || _.isEmpty(Value.text || "")) {
    return <></>;
  }
  const notImageFile = (): boolean => {
    if (!_.isNull(formElement.value)) {
      return _.isNull(formElement.value.file);
    }
    return true;
  };

  const IfNotImageFile = notImageFile();
  let imageUrl = null;
  if (!IfNotImageFile) {
    imageUrl = !_.isNull(formElement.value.file.publicURL)
      ? formElement.value.file.publicURL
      : null;
  }

  let imagePlaceholder = <IconPlaceholer />;
  if (!_.isNull(imageUrl)) {
    imagePlaceholder = <img src={imageUrl} className={classes.icon} />;
  }

  return (
    <div className={classes.container}>
      <div className={classes.thumbnail}>
        <div className={classes.imageContainer}>{imagePlaceholder}</div>
      </div>
      <div className={classes.readOnlyText}>{Value.text || ""}</div>
    </div>
  );
};
