import React, { useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import * as _ from "lodash";
import { IGridFilter } from "../../Interfaces/Filter/IGridFilter";
import { GridBody } from "./Body";
import GridHeader from "./GridHeader";
import style from "./style";
import GridLoader from "./GridLoader";



type props = {//NOSONAR
  gridData: any[];
  filter: IGridFilter;
  sortHandler: any;
  loading: boolean;
  onEdit: any;
  loadHeader: boolean;
  isGridAction: boolean;
  headers: any;
  bulkEditMode?: boolean | undefined;//NOSONAR
  bulkEditfilter?: any;
  checkedRecordCount?: any;
  setSelectedData?: any;
  snackbar?: any;
  setPageNumber?: any;
};

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const GridTable = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const {
    filter,
    loadHeader,
    isGridAction,
    sortHandler,
    loading,
    onEdit,
    gridData,
    headers,
    bulkEditMode,
    bulkEditfilter,
    checkedRecordCount,
    setSelectedData,
    snackbar,
    setPageNumber
  } = props;
  const [allSelected, setAllSelected] = useState(false);
  const [selected, setSelected] = useState<any>({});

  useEffect(() => {
    if (!bulkEditMode) {
      setSelected({});
      setAllSelected(false);
    }
  }, [bulkEditMode]);

  useEffect(() => {
    if (bulkEditMode) {
      if (_.isEqual(snackbar.variant, "success") && _.isEqual(snackbar.open, true)) {
        setSelected({});
        setAllSelected(false);
      }
    }

  }, [snackbar]);

  const getSelectedData = (): any => {
    const selectedData: any = [];
    Object.entries(selected).forEach(([key, val]: any) => {
      if (_.isEqual(val, true)) {
        const data = getBulkEditData({ isSelectAll: true });
        const found = data.find((obj: any) => {
          return _.isEqual(obj.id, Number(key));
        });
        selectedData.push(found);
      }
    });
    return selectedData;
  };

  useEffect(() => {
    if (bulkEditMode) {
      const data = getSelectedData();
      setSelectedData(data);
    }
  }, [selected]);


  if (_.isEmpty(gridData) && !loading) {
    return (
      <div className={classes.noDataAvailable}>
        {
          bulkEditMode ? <div>No data available for selected criteria. Please click on Exit Edit Mode to exit from Bulk Edit mode.</div> : <div>No data available for selected criteria</div>
        }
      </div>
    );
  }

  const getIsSelected = (id: any): any => {
    let isSelect = false;
    Object.entries(selected).forEach(([key, val]: any) => {
      if (_.isEqual(Number(key), id)) {
        if (_.isEqual(val, true)) {
          isSelect = true;
        }
        else {
          isSelect = false;
        }
      }
      else {
        isSelect = false;
      }
      return isSelect;
    });
    return isSelect;
  };

  const getBulkEditData = ({ isSelectAll }: any): any => {
    if (isSelectAll) {
      return gridData;
    }
    else {
      const sliceData = gridData.slice(
        bulkEditfilter.pageNumber * bulkEditfilter.pageSize,
        bulkEditfilter.pageNumber * bulkEditfilter.pageSize + bulkEditfilter.pageSize
      );
      const data: any = [];
      sliceData.forEach((row: any) => {
        const bulkEditRow = {
          ...row,
          isSelected: getIsSelected(row.id)
        };
        data.push(bulkEditRow);
      });

      if (_.isEmpty(data) && bulkEditMode) {
        const pageNum = (bulkEditfilter.pageNumber - 1);
        setPageNumber(pageNum);
        const sliceDataset = gridData.slice(
          pageNum * bulkEditfilter.pageSize,
          pageNum * bulkEditfilter.pageSize + bulkEditfilter.pageSize
        );
        const dataSet: any = [];
        sliceDataset.forEach((row: any) => {
          const bulkEditRow = {
            ...row,
            isSelected: getIsSelected(row.id)
          };
          dataSet.push(bulkEditRow);
        });
        return dataSet;
      }
      else {
        return data;
      }

    }
  };

  const handleCheckChange = (event: any, rowID: any): void => {
    if (!event.target.checked) {
      setAllSelected(false);
    }
    setSelected((select: any) => ({
      ...select,
      [rowID]: !selected[rowID]
    }));
  };


  const handleAllChange = (event: any): void => {
    const { checked } = event.target;
    setAllSelected(checked);
    const Data = getBulkEditData({ isSelectAll: true });
    Data &&
      setSelected(
        Data.reduce(
          (selects: any, { id }: any) => ({
            ...selects,
            [id]: checked
          }),
          {}
        )
      );
  };

  const ROW_DATA = gridData;

  let isAllSelected = false;
  let isIndeterminate = null;

  if (bulkEditMode) {
    const selectedCount = Object.values(selected).filter(Boolean).length;
    checkedRecordCount(selectedCount);
    isAllSelected = _.isEqual(selectedCount, getBulkEditData({ isSelectAll: true }).length);
    const gridBulkData = getBulkEditData({ isSelectAll: true });
    isIndeterminate =
      selectedCount && !_.isEqual(selectedCount, gridBulkData.length);
  }

  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <table className={classes.gridTable}>
        <GridHeader
          order={bulkEditMode ? bulkEditfilter.order : filter.order}
          orderBy={bulkEditMode ? bulkEditfilter.orderBy : filter.orderBy}
          sortHandler={sortHandler}
          headers={headers}
          loading={loading}
          bulkEditMode={bulkEditMode}
          handleAllChange={handleAllChange}
          isAllSelected={isAllSelected}
          allSelected={allSelected}
          isIndeterminate={isIndeterminate}
        />

        {!loading && !_.isEmpty(gridData) && (
          <GridBody onEdit={onEdit} allSelected={allSelected} selected={selected} headers={headers} rowData={bulkEditMode ? getBulkEditData({ isSelectAll: false }) : ROW_DATA} bulkEditMode={bulkEditMode} handleCheckChange={handleCheckChange} />
        )}
        {loading && (
          <GridLoader
            loadHeader={loadHeader}
            rows={filter.pageSize}
            headers={headers}
            isGridAction={isGridAction}
          />
        )}
      </table>
    </Grid>
  );
};
