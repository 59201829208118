import React from "react";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { Grid } from "@material-ui/core";
import * as _ from "lodash";
import { UI_BTN_ACTIONS } from "../../config";
import { UIDataGridset } from "./Gridset";
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
  loading:boolean;
  ishavingParent:any;
};

// UI File Upload Component
export const GridDataSet = (props: props): React.JSX.Element => {
    const { formElement, onChange, formId, formGroupId,loading,ishavingParent} = props;
    
    let errorMessage = "";

    if (formElement.apiError) {
      errorMessage = formElement.apiError;
    } else {
      errorMessage = formElement.touched ? formElement.error : "";
    }
    const onGridChange = (gridData: any): void => {
        onChange({
          formId,
          formGroupId,
          formElement,
          dynamicElement: {
            action: UI_BTN_ACTIONS.EDIT,
            value: gridData,
          },
          value:( _.isNull(formElement.value) || _.isEqual(formElement.value,0)) ? null : { ...formElement.value },
        });
      };
    
  return (
    <>
 <Grid container item lg={12} md={12} sm={12} xs={12} >
        <Grid container direction="column" item lg={12} md={12} sm={12} xs={12}>
          <UIDataGridset
            errorMessage={errorMessage}
            formElement={formElement}
            onChange={onGridChange}
            loading={loading}
            ishavingParent={ishavingParent}
          />
        </Grid>
    </Grid>
   </>
  );
};
