import * as _ from "lodash";

export function catchError(error: any, defaultErrorMessage: string): any {
	if (!_.isUndefined(error.response)) {
	  if (!_.isUndefined(error.response.data.message)) {
		return error.response.data.message;
	  }
	  return defaultErrorMessage;
	}
  
	// Show Error (Error Message)
	return error.message;
  }


  export function catchSuccessMessage(response: any, defaultSuccessMessage: string): any {
	if (!_.isUndefined(response.data)) {
	  if (!_.isUndefined(response.data.message)) {
		return response.data.message;
	  }
	}
	return defaultSuccessMessage;
  }