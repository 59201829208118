import React, {
  useEffect,
  useState,
} from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, IconButton, Input, InputAdornment } from "@material-ui/core";
import style from "./style";
import sharedStyle from "../../style";
import { ToolHeader } from "../../Layout";
import { UIOutlineButton } from "../../../../../../UI/Buttons";
import colors from "../../../../../../UI/Theme/colors";
import { IconSearch } from "../../../../../../UI/Icons";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Clear";
import ToggleButton from "../ToggleButton";
import GridTable from "../Grid/Grid Table";
import { SORT_ORDER } from "../../../../../History/configuration";
import _ from "lodash";
import { IGridData } from "../../../../../Mapping/Interfaces/Grid/IGridData";
import {IScheduleSelectedJobInfo} from "../Interfaces/IScheduleSelectedJobInfo";
import { useSelector } from "react-redux";
import { Axios } from "../../../../../../api/service/Axios";
import { catchError } from "../../../../../../helpers/ErrorHandler.component";
import { NOTIFICATION_TYPE } from "../../../../../Mapping/configuration";
import { CircularProgress } from "../../../../../../UI/Progress";
import { IGridSchedulePane } from "../Interfaces/IGridSchedulePane";
import { INTERNAL_TOOL_EXECUTE_URL, GENERATE_GOOGLE_SIGNED_URL, FILE_DOWNLOAD_API_URL, UPDATE_SCHEDULE_INFO, GET_JOB_API } from "../../../../../../api/paths";
import { SidebarComponent } from "../Layout/Sidebar";
import { getScheduleFormConfig } from "../ScheduleConfig";
import { EditFormHelper } from "../Helpers/Forms";
import { FormElementHelper } from "../Helpers/Forms/Element";
import { IViewDetailPane } from "../Interfaces/IViewDetailPane";
import { ViewDetail } from "../Layout/Sidebar/ViewDetail";
import { SnackbarAlert } from "../../../../../../UI/Snackbar";
import * as ScheduleHelper from "../Helpers/Forms/Helper";
import moment from "moment";
import { IForm } from "../Interfaces/Form/IForm";
import { ISignout } from "../../../../../../UI/Elements/Forms/interface/ISignout";
import { IFormGroup } from "../Interfaces/Form/IFormGroup";
import { IFormElement } from "../Interfaces/Form/FormElement/IFormElement";
import { getForm } from "../Helpers/Forms/CloneHelper";
import { BuildNewFormHelper } from "../../../../../../UI/Elements/Forms/helper/Forms/BuildForm";
import { catchErrors } from "../ErrorHandler";

type props = {
  toolFormData: any;
  authComponent?: any;
  handleBackButton: any;
  onAdd: any;
  onCloneChange: any;
  onEditChange: any;
  signoutConfig: ISignout;
};
interface IRowData {
  job: string;
  state: any;
  lastExecutionDate: any;
  nextExecutionDate: any;
  jobId: any;
  jobStatus: any;
  lastExecutionTime: any;
  nextExecutionTime: any;
  failureReason: any;
  reportDetails: any;

}

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Create styles
const useSharedStyles = makeStyles((theme: Theme) =>
  createStyles(sharedStyle(theme))
);

// UIGrid component
const UIGrid = (props: props): React.JSX.Element => {
  const { toolFormData, handleBackButton, onAdd, onCloneChange, onEditChange, authComponent, signoutConfig } = props;
  const classes = useStyles();
  const DefaultSorting = {
    order: SORT_ORDER.DESC,
    orderBy: "lastExecutionDate",
  };

  const [schedulePane, setSchedulePane] = useState<IGridSchedulePane>({
    sidebarFilter: null,
  });
  const [viewDetailPane, setViewDetailPane] = useState<IViewDetailPane>({
    sidebarDetail: null,
  });

  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });

  const setAlert = (message: string, variant: NOTIFICATION_TYPE): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant,
      message,
    });
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const [filters, setFilters] = useState({
    page_number: 0,
    page_size: 20,
    searchText: "",
    search: {
      text: "",
      typingTimeout: 0,
    },
    execution_status: _.toLower("All"),
    ...DefaultSorting
  });
  const [searchText, setSearchText] = useState("");
  const [gridData, setGridData] = useState<IGridData>({
    data: [],
    count: 0
  });
  const [circularLoading, setCircularLoading] = useState(false);
  const [initLoader, setInitLoader] = useState<boolean>(false);
  const REDUX_STATE = useSelector((state: any) => ({
    USER_INFO: state.initial.userInfo,
    BUNDLE: state.initial.bundle
  }));
   const [selectedJobScheduleInfo, setselectedJobScheduleInfo] = useState<IScheduleSelectedJobInfo | null>(null);

  const [loading, setLoading] = useState(false);
  const sharedClasses = useSharedStyles();
  const propsToToolInfo = {
    data: toolFormData,
    classes: sharedClasses,
  };

  const errorAlert = (message: string): void => {
    setSnackbar({
      ...snackbar,
      open: true,
      variant: "error",
      message,
    });
  };

  useEffect(() => {
    const getInitialGridData = async (): Promise<void> => {
      setInitLoader(true);
      try {
        const InitData = await Axios.get(GET_JOB_API(toolFormData.tool_id, REDUX_STATE.USER_INFO.userId));
        setGridData({
          data: InitData.data.data,
          count: InitData.data.data.length,
        });

        const UpdatedGridData: any[] = sortData(
          DefaultSorting.orderBy,
          DefaultSorting.order,
          InitData.data.data
        );
        setGridData({
          data: UpdatedGridData,
          count: UpdatedGridData.length,
        });

        setInitLoader(false);
      }
      catch (err: any) {
        const errorMessage = catchErrors(err, REDUX_STATE.BUNDLE.DEFAULT_ERROR_MESSAGE);
        setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
        setInitLoader(false);
      }
    };
    getInitialGridData();
    return () => {
      setGridData({
        data: [],
        count: 0
      });
    };
  }, []);

  const onfilterTabChange = (event: any, newValue: any): any => {
    if (!_.isNull(newValue)) {
      setFilters((prevState: any) => ({
        ...prevState,
        execution_status: _.toLower(newValue),
        page_number: 0
      }));
    }
  };

  const onRefreshClick = async (): Promise<void> => {
    setCircularLoading(true);
    try {
      const InitData = await Axios.get(GET_JOB_API(toolFormData.tool_id, REDUX_STATE.USER_INFO.userId));
      setGridData({
        data: InitData.data.data,
        count: InitData.data.data.length,
      });

      const UpdatedGridData: any[] = sortData(
        filters.orderBy,
        filters.order,
        InitData.data.data
      );
      setGridData({
        data: UpdatedGridData,
        count: UpdatedGridData.length,
      });

      setCircularLoading(false);
    }
    catch (err: any) {
      const errorMessage = catchErrors(err, REDUX_STATE.BUNDLE.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setCircularLoading(false);
    }
  };

  const handleChangePage = (offset: number, page: number): void => {
    setFilters((prevState: any) => ({
      ...prevState,
      page_number: page,
    }));
  };

  const sortData = (
    orderBy: any,
    order: any,
    griddata: any
  ): any => {
    let updatedGridData: any[] = [];
    if (_.isEqual(orderBy, "lastExecutionDate")) {
      updatedGridData = griddata.sort((a: any, b: any) => _.isEqual(order, SORT_ORDER.DESC) ? (new Date(a["lastExecutionDate"] + " " + a["lastExecutionTime"]) < new Date(b["lastExecutionDate"] + " " + b["lastExecutionTime"]) ? 1 : (new Date(b["lastExecutionDate"] + " " + b["lastExecutionTime"]) < new Date(a["lastExecutionDate"] + " " + a["lastExecutionTime"])) ? -1 : 0) :  //NOSONAR
        (new Date(a["lastExecutionDate"] + " " + a["lastExecutionTime"]) > new Date(b["lastExecutionDate"] + " " + b["lastExecutionTime"]) ? 1 : (new Date(b["lastExecutionDate"] + " " + b["lastExecutionTime"]) > new Date(a["lastExecutionDate"] + " " + a["lastExecutionTime"])) ? -1 : 0)); //NOSONAR
    }
    else {
      updatedGridData = _.orderBy(
        griddata,
        [
          (record) => {
            const sortValue: any = record[orderBy] || "";
            return sortValue.toLowerCase();
          }
        ],
        [order]
      );
    }
    return updatedGridData;
  };


  const createSortHandler = (headerKey: string): void => {
    const orderBy = headerKey;
    let order = "desc";

    if (_.isEqual(filters.order, "desc")) {
      order = "asc";
    }

    const UpdatedGridData: any[] = sortData(
      orderBy,
      order,
      gridData?.data
    );

    setGridData({
      data: UpdatedGridData,
      count: UpdatedGridData.length
    }
    );

    setFilters((prevState: any) => ({
      ...prevState,
      orderBy: orderBy,
      order: order,
    }));
    setLoading(false);
  };

  const handleExecuteChange = async (jobId: number): Promise<any> => {
    try {
      setCircularLoading(true);
      const Response = await Axios.post(INTERNAL_TOOL_EXECUTE_URL, {
        jobid: jobId,
        toolname: toolFormData.tool_name,
        toolid: toolFormData.tool_id
      });
      const InitData = await Axios.get(GET_JOB_API(toolFormData.tool_id, REDUX_STATE.USER_INFO.userId));
      setGridData({
        data: InitData.data.data,
        count: InitData.data.data.length,
      });

      const UpdatedGridData: any[] = sortData(
        filters.orderBy,
        filters.order,
        InitData.data.data
      );
      setGridData({
        data: UpdatedGridData,
        count: UpdatedGridData.length,
      });
      setCircularLoading(false);
      setAlert(Response.data.message, NOTIFICATION_TYPE.SUCCESS);
    }
    catch (err: any) {
      setCircularLoading(false);
      const errorMessage = catchErrors(err, REDUX_STATE.BUNDLE.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
    }
  };

  // expiry date check
  const expiryDateCheck = (expirytime: any): any => {
    const now = moment().utc().format("YYYY-MM-DD HH:mm:ss");
    return (moment(now).isBefore(expirytime));
  };

  // download grid
  const onDownloadHandler = async (fileName: any, bucketName: any, expirytime: any): Promise<void> => {

    setCircularLoading(true);
    if (expiryDateCheck(expirytime)) {
      try {
        const signedUrl = await Axios.post(GENERATE_GOOGLE_SIGNED_URL, {
          "blob_name": fileName,
          "bucket_name": bucketName
        });

        if (signedUrl) {
          window.location.href = `${process.env.REACT_APP_BASE_URL
            }${FILE_DOWNLOAD_API_URL}?filename=${fileName}&fileurl=${encodeURIComponent(
              signedUrl.data
            )}`;
        }
        setCircularLoading(false);
      } catch (err) {
        const errorMessage = catchError(err);
        errorAlert(errorMessage);
        setCircularLoading(false);
      }
    } else {
      const errorMessage = "File does not exist";
      errorAlert(errorMessage);
      setCircularLoading(false);
    }
  };



  const getSearchedData = (SEARCH_TEXT: string): void => {
    setFilters((prevState: any) => ({
      ...prevState,
      searchText: SEARCH_TEXT,
      page_number: 0
    }));
  };



  // Search handler
  const onSearchChange = (event: any): void => {
    const SEARCH_TEXT = event.target.value || "";

    if (filters.search.typingTimeout) {
      clearTimeout(filters.search.typingTimeout);
    }

    setSearchText(SEARCH_TEXT);
    if (_.isEqual(event.code, "Enter")) {
      setFilters((prevState: any) => ({
        ...prevState,
        searchText: SEARCH_TEXT
      }));
    }
    else {
      setSearchText(SEARCH_TEXT);
      setLoading(true);
      setFilters((prevState: any) => ({
        ...prevState,
        search: {
          ...prevState.search,
          typingTimeout: setTimeout(() => {
            getSearchedData(SEARCH_TEXT);
          }, 1000),
        },

      }));
      setLoading(false);
    }
  };

  //On Save handler
  const onSave = async (form: IForm): Promise<void> => {
    let jobID: any = "";
    form.groups.forEach((formGroup: IFormGroup) => {
      formGroup.elements.forEach((formElement: IFormElement) => {
        if (_.isEqual(formElement.id, "job")) {
          jobID = formElement.readOnlyOptions?.subtitle;
        }

      });
    });

    if (_.isNull(schedulePane.sidebarFilter)) {
      return;
    }
    const FormData = ScheduleHelper.buildSubmitFormData(
      form,
    );
    setCircularLoading(true);
    try {
      const Response = await Axios.put(UPDATE_SCHEDULE_INFO(jobID, true), FormData);
      const GridData = await Axios.get(GET_JOB_API(toolFormData.tool_id, REDUX_STATE.USER_INFO.userId));
      setGridData({
        data: GridData.data.data,
        count: GridData.data.data.length,
      });
      const UpdatedGridData: any[] = sortData(
        filters.orderBy,
        filters.order,
        GridData.data.data
      );
      setGridData({
        data: UpdatedGridData,
        count: UpdatedGridData.length,
      });

      setSchedulePane((prevState: IGridSchedulePane): any => ({
        ...prevState,
        sidebarFilter: {
          open: false,
        },
      }));

      setCircularLoading(false);
      setAlert(Response.data.message, NOTIFICATION_TYPE.SUCCESS);
    }
    catch (err) {
      setCircularLoading(false);
      const errorMessage = catchErrors(err, REDUX_STATE.BUNDLE.DEFAULT_ERROR_MESSAGE);
      setAlert(errorMessage, NOTIFICATION_TYPE.ERROR);
      setLoading(false);
    }
  };

  const onSchedule = async (id: number, rowData: any): Promise<void> => {
    const editFormHelper = new EditFormHelper;
    const formElementHelper = new FormElementHelper();
    let form = await editFormHelper.buildEditForm(
      getScheduleFormConfig({}),
      rowData
    );
    setselectedJobScheduleInfo(rowData.jobScheduleInfo);

    form = formElementHelper.findDisabledChildElements(form);
    form = formElementHelper.findDependentParentElements(form);
    setSchedulePane((prevState: IGridSchedulePane): any => ({
      ...prevState,
      sidebarFilter: {
        open: true,
        form
      },
    }));

  };

  const onClone = (jobId: any, jobName: string): void => {
    const Data = gridData.data;

    const CloneInfo = Data.find(obj => {
      return _.isEqual(obj.jobId, jobId);
    });
    onCloneChange(CloneInfo.jobPayload.portaldata, jobName);
  };

  const onEdit = (jobId: any, jobName: string): void => {
    const Data = gridData.data;

    const CloneInfo = Data.find(obj => {
      return _.isEqual(obj.jobId, jobId);
    });
    onEditChange(CloneInfo.jobPayload.portaldata, jobId, jobName);
  };

  function strToLowerCase(input: any): any {
    return (input || "").toLowerCase();
  }


  const UpdatedData = gridData.data.filter((row: IRowData) => {
    const jobId: string = row.jobId.toString();
    const SearchText: string = strToLowerCase(filters.searchText);
    const JobId: string = strToLowerCase(jobId);
    const JobName: string = strToLowerCase(row.job);

    let isValid = !_.isEqual(JobId.indexOf(SearchText), -1) || !_.isEqual(JobName.indexOf(SearchText), -1);
    isValid = isValid && (_.isEqual(filters.execution_status, _.toLower("All")) ? true : _.isEqual(_.toLower(filters.execution_status), _.toLower(row.jobStatus)));
    return isValid;
  });



  const onDetailClick = async (jobId: any, jobName: string): Promise<void> => {
    setCircularLoading(true);
    const Data = gridData.data;

    const DetailesInfo = Data.find(obj => {
      return _.isEqual(obj.jobId, jobId);
    });
    const buildNewForm = new BuildNewFormHelper();
    const UpdatedForms = await buildNewForm.form(props.toolFormData.forms);
    const Detail_Form = getForm(UpdatedForms, DetailesInfo.jobPayload.portaldata, jobName, "Detail");
    setViewDetailPane((prevState: IViewDetailPane): any => ({
      ...prevState,
      sidebarDetail: {
        open: true,
        form: Detail_Form,
        jobId: DetailesInfo.jobId,
        jobName: DetailesInfo.job
      },
    }));
    setCircularLoading(false);
  };

  if (initLoader) {
    return <CircularProgress />;
  }

  return (
    <>
      <ToolHeader handleBackButton={handleBackButton} signoutConfig = {signoutConfig} {...propsToToolInfo} />
      <Container maxWidth="lg">
        <Grid container className={classes.root} alignItems="center">
          <Grid item xs={12} sm={4} md={4} lg={4} style={{ marginBottom: "15px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onAdd}
            >
              Add
            </Button>
            <UIOutlineButton
              cssStyle={{
                backgroundColor: colors.WHITE,
                marginLeft: "10px"
              }}
              isOutline={true}
              onClick={onRefreshClick}
              btnText="Refresh"
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={8}
            md={8}
            lg={8}
            className={classes.gridContainer}
          >
            <div className={classes.search}>
              <div
                className={classes.searchIcon}
                style={{ opacity: loading ? 0.5 : 1 }}>
                <IconSearch title={""} />
              </div>
              <Input
                type="input"
                onKeyDown={(event) => {
                  if (_.isEqual(event.code, "Enter")) {
                    onSearchChange(event);
                  }
                }}
                title=""
                placeholder="Search by Job Name or Job ID"
                onChange={(event) => onSearchChange(event)}
                value={searchText}
                className={classNames(
                  classes.inputInput,
                  classes.inputElement
                )}
                inputProps={{ "aria-label": "search" }}
                disabled={loading}
                disableUnderline={true}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      style={{
                        visibility:
                          loading || _.isEqual(searchText, "")
                            ? "hidden"
                            : "visible",
                      }}
                      size={"small"}
                      onClick={(event) => {
                        return onSearchChange(event);
                      }}
                    >
                      <CloseIcon
                        fontSize="small"
                        className={classes.closeButton}
                      />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </div>


            <div style={{ paddingLeft: 12 }}>
              <ToggleButton
                id={"filterTab"}
                handleChange={onfilterTabChange}
                data={[
                  { label: "All", value: _.toLower("All") },
                  { label: "Failed", value: _.toLower("Failed") },
                ]}
                loading={loading}
                value={filters.execution_status}
                exclusive={true}
                disabled={false}
              />
            </div>
          </Grid>
        </Grid>
        {!_.isNull(schedulePane.sidebarFilter) && (
          <SidebarComponent
            width={420}
            formTitle={"Schedule"}
            btnSaveText="Save"
            btnCancelText="Cancel"
            onSave={onSave}
            onCancel={() => {
              setSchedulePane((prevState: IGridSchedulePane): any => ({
                ...prevState,
                sidebarFilter: {
                  ...prevState.sidebarFilter,
                  open: false,
                },
              }));
            }}
            formData={schedulePane.sidebarFilter!.form}
            open={schedulePane.sidebarFilter!.open}
            isFilterForm={true}
            selectedScheudledInfo = {selectedJobScheduleInfo}
          />
        )}
        {!_.isNull(viewDetailPane.sidebarDetail) && (
          <ViewDetail
            width={570}
            formTitle={"View Details"}
            btnCancelText="Cancel"
            formData={viewDetailPane.sidebarDetail?.form}
            jobId={viewDetailPane.sidebarDetail?.jobId}
            jobName={viewDetailPane.sidebarDetail?.jobName}
            onCancel={() => {
              setViewDetailPane((prevState: IViewDetailPane): any => ({
                ...prevState,
                sidebarDetail: {
                  ...prevState.sidebarDetail,
                  open: false,
                },
              }));
            }}
            open={viewDetailPane.sidebarDetail!.open}
          />
        )}

        {_.isEmpty(gridData.data) && (
          <div className={classes.nojobMessage}>
            <div className={classes.nojobMessageContainer}>
              <div style={{ paddingTop: "45px" }}>No job execution available for selected criteria</div>
            </div>
          </div>
        )}
        {!_.isEmpty(gridData.data) && (
          <GridTable
            toolData={props.toolFormData}
            data={gridData.data}
            handleChangePage={handleChangePage}
            filters={filters}
            createSortHandler={createSortHandler}
            handleExecuteChange={handleExecuteChange}
            loading={loading}
            isSchedule={toolFormData.is_schedule}
            onSchedule={onSchedule}
            onDetailClick={onDetailClick}
            handleDownloadChange={onDownloadHandler}
            onClone={onClone}
            onEdit={onEdit}
            UpdatedGrid={UpdatedData}
            expiryDateCheck={expiryDateCheck}
          />)}
        <SnackbarAlert
          open={snackbar.open}
          message={snackbar.message}
          variant={snackbar.variant}
          onOpenCloseAlert={handleCloseAlert}
        />
        {authComponent}
        {(loading || circularLoading) && <CircularProgress />}
      </Container>
    </>
  );
};

UIGrid.defaultProps = {
  authComponent: null,
};

export default UIGrid;