import React, { Fragment, forwardRef } from "react";
import classNames from "classnames";
import * as _ from "lodash";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import { ThumbUpAlt, InfoOutlined, Error } from "@material-ui/icons";
import { IconClose } from "../Icons";

type props = {
  classes: any;
  className?: any;
  message: string;
  onClose: any;
  variant: any;
  isOpen: boolean;
};

const SnackbarComponent = (props: props, ref: any): JSX.Element => {
  const { classes, message, onClose, variant, isOpen, ...other } = props;

  const Icons = (Varient: string): any => {
    if (_.isEqual(Varient, "success")) {
      return <ThumbUpAlt width="24" height="24" viewBox="0 0 22 22" />;
    } else if (_.isEqual(Varient, "error")) {
      return <Error />;
    } else if (_.isEqual(Varient, "info")) {
      return <InfoOutlined style={{ fill: "#868e96" }} />;
    } else if (_.isEqual(Varient, "warning")) {
      return <Error />;
    }
  };

  return (
    <SnackbarContent
      ref={ref}
      className={classNames(
        classes[variant],
        classes[`${variant}Border`],
        isOpen ? classes.onOpen : classes.onClose
      )}
      key={`${variant}-Key+${ref}`}
      aria-describedby="client-snackbar"
      message={
        <Fragment>
          <div className={classNames(classes[variant], classes.iconIndicator)}>
            {Icons(variant)}
          </div>
          <span
            className={classes.message}
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          ></span>
          <div className={classes.close}>
            <IconClose onClick={onClose} title="" />
          </div>
        </Fragment>
      }
      {...other}
    />
  );
};

// Snackbar Content functional component
const SnackbarContentComponent = forwardRef(SnackbarComponent);
export default withStyles(styles)(SnackbarContentComponent);
