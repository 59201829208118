import COLORS from "../../UI/Theme/colors";

const style: any = (): any => ({
	userProfileImage: {
		width: 32,
		height: 32,
		borderRadius: 50,
		textAlign: "center" as const,
		backgroundColor: "#edf0f2",
		color: COLORS.GRAY,
		fontSize: 14,
		fontWeight: 600,
		marginLeft: 12,
		cursor: "pointer",
	},
	userProfileAvatar: {
		position: "relative" as const,
		top: 7,
		height: 25,
	},
	popoverContainer: {
		padding: 24,
		width: 224
	},
	userName: {
		fontSize: 16,
		fontWeight: 600,
		whiteSpace: "nowrap" as const,
	},
	userEmail: {
		fontSize: 12,
		fontWeight: "normal" as const,
		whiteSpace: "nowrap" as const,
	},
	signOut: {
		paddingTop: 8
	},
	signOutButton: {
		height: 36,
		width: 176
	},
	editButton: {
		height: 36,
		width: 176,
		justifyContent: "center"
	},
	popoverInfoPaper: {
		top: "12px !important",
		left: "-9px !important",
	},
	hr: {
		marginTop: "1rem"
	},
});

export default style;