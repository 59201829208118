import { Theme } from "@material-ui/core/styles";
import COLORS from "../../../UI/Theme/colors";

const style: any = (theme: Theme) => ({
	tableContainer: {
		verticalAlign: "top",
		padding: "0px 40px 40px",
	},
	tableWrapper: {
		padding: "16px 12px",
		backgroundColor: COLORS.WHITE,
	},
	table: {
		width: "100%",
		borderCollapse: "collapse",
	},
	headerTitle: {
		verticalAlign: "top",
		fontSize: "0.75rem",
		color: "#6a737c",
		fontWeight: "bold"
	},
	tableRow: {
		padding: "14px 40px 14px 0px",
		"&:hover": {
			backgroundColor: "#F0F8FF"
		}
	},
	tableColumn: {
		verticalAlign: "top",
		borderBottom: 0,
		padding: "14px 20px 13px"
	},
	status: {
		fontWeight: 500,
		fontSize: "0.625rem",
		textTransform: "uppercase",
		padding: "2px 8px"
	},
	executedTime: {
		paddingLeft: "1rem"
	},
	tableCellContent: {
		fontSize: "0.875rem",
		color: "#242c34",
		fontWeight: "normal",
		opacity: "0.9"
	},
	version: {
		fontSize: "0.875rem",
		color: "#6a737c",
		fontWeight: 500,
		display: "inline-block",
		opacity: "0.7"
	},
	loadingCell: {
		minHeight: 17,
		width: "100%",
		borderRadius: 2,
		display: "inline-block"
	},
	gradient: {
		backgroundImage: "linear-gradient(90deg, #F3F5F7 0px, #F7F8FA 40px, #F3F5F7 80px)",
		backgroundSize: 600,
		animation: "shine-lines 1.6s infinite linear",
		backgroundColor: COLORS.BASECOLOR
	},
	headers: {
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
		color: "#6a737c",
		padding: "11px 20px",
	},
	statusMessage: {
		wordBreak: "keep-all"
	},
	sortingIcons: {
		padding: "0px 5px",
		verticalAlign: "middle"
	},
	noHistoryMessage: {
		color: "#6a737c",
		fontSize: 16,
		fontWeight: 600,
		marginRight: 6,
		textAlign: "center",
		padding: "0px 40px 40px",
		height: 94,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "100%",
		paddingTop: "65px"
	},
	noHistoryMessageContainer: {
		paddingLeft: 12,
		paddingRight: 12,
		backgroundColor: "#ffffff",
		height: "120px",
		width: "100%",
	},
	ellipsisContainer: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		visibility: "hidden",
		height: 0,
		maxHeight: 0,
		display: "block"
	},
	middleTextEllipsis: {
		width: "100%",
		whiteSpace: "nowrap",
		display: "block",
		minHeight: 21,
	},
	gridHeader: {
		width: "100%",
		height: 40,
		borderBottom: "1px solid rgba(224, 224, 224, 1)",
		position: "sticky",
		top: 56,
		backgroundColor: COLORS.WHITE,
		zIndex: 1
	},
	gridHeaderBoxShadow: {
		boxShadow: "0 2px 2px -2px #b3b3b3"
	},
});

export default style;
