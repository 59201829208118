import * as _ from "lodash";
import { Axios } from "../../../../../../../api/service/Axios";
import { IFormElement } from "../../../../interface/IFormElement";
import { IOption } from "../../../../interface/IOption";
import { UtilHelper } from "../../../Utils";

// Download Element Helper
export class DownloadHelper {
  private utilHelper: UtilHelper;

  constructor() {
    this.utilHelper = new UtilHelper();
  }

  /**
   * Generate Download element
   *
   * @param {forms} IForm[].
   * @param {formElement} IFormElement.
   * @return {element} IFormElement.
   */
  generateDownload = async (
    formElement: IFormElement
  ): Promise<IFormElement> => {
    let element = { ...formElement };
    // API Dropdown
    if (!this.utilHelper.isEmptyValue(formElement.api_url)) {
      element = await this.getAPIData(formElement);
    } else if (!_.isEmpty(formElement.value) && !_.isNull(formElement.value)) {
      element.value = _.isObject(formElement.value)
        ? formElement.value
        : JSON.parse(formElement.value);
    }
    return element;
  };

  /**
   * Get API Data
   *
   * @param {forms} IForm[].
   * @param {formElement} IFormElement.
   * @return {Element} IFormElement.
   */
  getAPIData = async (formElement: IFormElement): Promise<IFormElement> => {
    const Element = { ...formElement };
    let dataSource: IOption[] = [];

    if (_.isEqual(Element.parent_elements.length, 0)) {
      try {
        let Data: any;
        if (_.isEqual(formElement.form_element_methodid, 1)) {
          Data = await Axios.get(formElement.api_url);
        } else if (_.isEqual(formElement.form_element_methodid, 2)) {
          Data = await Axios.post(formElement.api_url, {});
        }

        Element.value = Data.data || null;

        Element.apiError = "";
      } catch (error: any) {
        dataSource = [];
        Element.apiError = this.utilHelper.getErrorMessage(error);
        Element.value = null;
      }
      return {
        ...Element,
        dataSource,
        value: Element.value,
      };
    }
    return {
      ...Element,
    };
  };
}
