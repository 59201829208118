import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";
import * as _ from "lodash";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { SnackbarAlert } from "../../../../../UI/Snackbar";
import style from "./style";
import { IconClose } from "../../../../../UI/Icons";
import { UIOutlineButton } from "../../../../../UI/Buttons";
import { IFormElementUpdate } from "../../../Interfaces/Form/FormElement/IFormElementUpdate";
import { EditFormHelper } from "../../Form/Helpers/Forms";
import { FormBuilder } from "../../Form/Builder";
import { IForm } from "../../../Interfaces/Form/IForm";
import { ValidationHelper } from "../../Form/Helpers/Validations";
import { IFormElementValue } from "../../../../../UI/Elements/Forms/interface/IFormElementValue";
import { ParentChildElementUtil } from "../../Form/Helpers/Utils/ParentChildElement";
import { CircularProgress } from "../../../../../UI/Progress";
import { FormHelper } from "../../Form/Helpers/Forms/Helper";
import { IFormElement } from "../../../Interfaces/Form/FormElement/IFormElement";
import { IOption } from "../../../../../UI/Elements/Forms/interface/IOption";
import * as MapperHelper from "../../../Mapper/Grid/Helper";
import { STATUS_TYPE, ELEMENT_TYPES } from "../../../configuration";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  formTitle: string;
  toggleDrawer: any;
  btnSaveText: string;
  btnCancelText: string;
  onSave: any;
  onCancel: any;
  onReset?: any;
  formData: any;
  width?: number;
  isFilterForm: boolean;
  bulkEditMode?: boolean;
  checkedCount?: number;
  statusValue?: any;
};

export const SidebarFormComponent: any = (props: props): React.JSX.Element => {//NOSONAR
  const {
    formTitle,
    formData,
    btnSaveText,
    btnCancelText,
    onSave,
    onCancel,
    onReset,
    width,
    bulkEditMode,
    checkedCount,
    statusValue,
  } = props;
  const classes = useStyles();
  const [form, setForm] = useState<IForm>(formData);
  const [snackbar, setSnackbar] = useState({
    message: "",
    open: false,
    variant: "success",
  });
  const [loading, setLoading] = useState<boolean>(false);

  const ParentChildElementValidation = async (
    updatedForms: any,
    element: any
  ): Promise<any> => {
    const validationHelper: ValidationHelper = new ValidationHelper();
    const parentChildEle: ParentChildElementUtil = new ParentChildElementUtil();
    setLoading(true);

    // Call API if any child is associated
    let UpdatedForm: any = await parentChildEle.findParentElements(
      updatedForms,
      element
    );

    setForm(UpdatedForm);

    UpdatedForm = await parentChildEle.findChildElements(UpdatedForm, element);

    setForm(UpdatedForm);

    const ValidatedForm = validationHelper.isValidForm(UpdatedForm);

    setForm(ValidatedForm);

    setLoading(false);

    return ValidatedForm;
  };

  const onCreateOption = (formElement: IFormElement, option: IOption): void => {
    const editFormHelper = new EditFormHelper();
    const Form = editFormHelper.updateElementDataSource(
      form,
      formElement,
      option
    );
    setForm(Form);
  };

  const handleCloseAlert = (): void => {
    return setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const onSaveBulkEdit = async (forms: IForm): Promise<void> => {
    const FormData = await MapperHelper.validateBulkEditFormData(
      forms,
      statusValue.label
    );
    setForm(FormData);
      if (FormData.isValid) {
        onSave(form);
      }
  };

  const onFormChange = async (element: IFormElementUpdate): Promise<void> => {
    const editFormHelper = new EditFormHelper();
    const validationHelper = new ValidationHelper();
    const ElementValue: IFormElementValue = {
      currentValue: element.value,
      previousValue: element.formElement.value,
    };

    let Form = await editFormHelper.updateForm(form, element, ElementValue);
    setForm(Form);
    const formHelper = new FormHelper();
    if (
      _.isEqual(element.formElement.type, ELEMENT_TYPES.SELECT_INPUT) &&
      !validationHelper.isPreviousAndCurrentValueAreSame(
        element.formElement,
        ElementValue
      ) &&
      formHelper.ifHasChildElements(Form, element.formElement)
    ) {
      Form = await ParentChildElementValidation(Form, element);
      setForm(Form);
    }
    if (bulkEditMode && !_.isEqual(statusValue.label, STATUS_TYPE.UNMAPPED)) {
      const updatedForm = editFormHelper.updateStatusWiseBulkEditForm(
        Form,
        true
      );
      setForm(updatedForm);
    }
  };

  useEffect(() => {
    setForm(formData);
  }, []);

  return (
    <>
      <div
        className={classes.sideBarContainer}
        style={{
          width: width || 424,
        }}
        role="presentation"
      >
        <div className={classes.sidebarHeader}>
          <div className={classes.sideBarTitle}>{formTitle}</div>
          <IconClose onClick={onCancel} title="" />
        </div>
        {bulkEditMode && (
          <div className={classes.toolBoxCountText}>
            {checkedCount} Selected Accounts
          </div>
        )}
        <FormBuilder
          form={form}
          onChange={onFormChange}
          onCreateOption={onCreateOption}
        />
      </div>
      <div
        className={classes.actionContainer}
        style={{
          width: width || 424,
        }}
      >
        {formTitle === "Filters" && (
          <Button
            color="primary"
            className={classes.resetBtn}
            onClick={onReset}
          >
            Reset
          </Button>
        )}

        <UIOutlineButton
          isOutline={true}
          onClick={onCancel}
          btnText={btnCancelText}
          cssStyle={{
            marginRight: "0.75rem",
          }}
        />
        {bulkEditMode ? (
          <Button
            disabled={false}
            variant="contained"
            color="primary"
            onClick={() => {
              onSaveBulkEdit(form);
            }}
          >
            {btnSaveText}
          </Button>
        ) : (
          <Button
            disabled={!form.isValid}
            variant="contained"
            color="primary"
            onClick={() => {
              onSave(form);
            }}
          >
            {btnSaveText}
          </Button>
        )}
      </div>
      {loading && <CircularProgress />}
      <SnackbarAlert
        open={snackbar.open}
        message={snackbar.message}
        variant={snackbar.variant}
        onOpenCloseAlert={handleCloseAlert}
      />
    </>
  );
};
