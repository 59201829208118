import React from "react";

const UP = (
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1">
    <g id="Screens" stroke="none" fill="none">
      <g id="Assets" transform="translate(-160.000000, -91.000000)">
        <g
          id="helper/header-table"
          transform="translate(111.000000, 88.000000)"
        >
          <g id="Group" transform="translate(44.000000, 0.000000)">
            <g id="arrow-down-line" transform="translate(3.000000, 1.000000)">
              <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
              <polygon
                id="Path"
                fill="#6A737C"
                points="8.66666667 10.7813333 12.2426667 7.20533333 13.1853333 8.148 8 13.3333333 2.81466667 8.148 3.75733333 7.20533333 7.33333333 10.7813333 7.33333333 2.66666667 8.66666667 2.66666667"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const DOWN = (
  <svg width="12px" height="12px" viewBox="0 0 12 12">
    <g id="Screens" stroke="none" strokeWidth="1" fill="none">
      <g id="Assets" transform="translate(-239.000000, -91.000000)">
        <g
          id="helper/header-table"
          transform="translate(190.000000, 88.000000)"
        >
          <g id="Group" transform="translate(44.000000, 0.000000)">
            <g id="arrow-up-line" transform="translate(3.000000, 1.000000)">
              <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
              <polygon
                id="Path"
                fill="#6A737C"
                points="8.66666667 5.21866667 8.66666667 13.3333333 7.33333333 13.3333333 7.33333333 5.21866667 3.75733333 8.79466667 2.81466667 7.852 8 2.66666667 13.1853333 7.852 12.2426667 8.79466667"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const DEFAULT = (
  <svg width="14px" height="12px" viewBox="0 0 14 12">
    <g id="Screens" stroke="none" strokeWidth="1" fill="none">
      <g id="Assets" transform="translate(-80.000000, -91.000000)">
        <g id="helper/header-table" transform="translate(32.000000, 88.000000)">
          <g id="Group" transform="translate(44.000000, 0.000000)">
            <g id="Group" transform="translate(3.000000, 1.000000)">
              <polygon id="Path" points="0 0 16 0 16 16 0 16"></polygon>
              <path
                d="M7.96666667,5.3 L7.024,6.24266667 L5.33333333,4.552 L5.33333333,13.3333333 L4,13.3333333 L4,4.552 L2.31,6.24266667 L1.36666667,5.3 L4.66666667,2 L7.96666667,5.3 Z M14.6333333,10.7 L11.3333333,14 L8.03333333,10.7 L8.976,9.75733333 L10.6673333,11.448 L10.6666667,2.66666667 L12,2.66666667 L12,11.448 L13.6906667,9.75733333 L14.6333333,10.7 L14.6333333,10.7 Z"
                id="Shape"
                fill="#D0D5DA"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const TableSortIcons = {
  UP,
  DOWN,
  DEFAULT,
};
