import React from "react";
import * as _ from "lodash";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IFormElement } from "../../../../../interface/IFormElement";
import style from "./style";
import { Tooltip } from "@material-ui/core";
import { IFileUpload } from "../../../../../interface/File/IFileUpload";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  formElement: IFormElement;
  value: any;
};

const getFileName = (fileName: string): JSX.Element => {
  const FileName = (fileName || "").toString().trim();
  if (_.gt(FileName.length, 15)) {
    const Text = `${FileName.substring(0, 5)}...${FileName.substring(
      FileName.length - 10,
      FileName.length
    )}`;
    return (
      <Tooltip title={FileName} placement={"top"}>
        <span>{Text}</span>
      </Tooltip>
    );
  }
  return <>{FileName}</>;
};

export const UIFileTextInputView = (props: props): JSX.Element => {
  const classes = useStyles();
  const { formElement, value } = props;
  if (_.isUndefined(formElement.customConfiguration.bucketUpload)) {
    return <></>;
  }
  if (_.isUndefined(formElement.customConfiguration.bucketUpload.tableHeaders)) {
    return <></>;
  }
  const GridHeaders =
    formElement.customConfiguration.bucketUpload.tableHeaders || [];

  const Files = value.map((file: IFileUpload) => {
    return (
      <div key={file.id} className={classes.cellFileContainer}>
        <div className={classes.cellFileName}>{getFileName(file.name)}</div>
        <div className={classes.cellFileTextbox}>{file.fileInputText}</div>
      </div>
    );
  });

  return (
    <>
      <div className={classes.headerFileContainer}>
        <div className={classes.headerFileName}>{GridHeaders[0]}</div>
        <div className={classes.headerFileTextbox}>{GridHeaders[1]}</div>
      </div>
      {Files}
    </>
  );
};
