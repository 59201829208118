import React, { Fragment, useState } from "react";
import { withStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Box, Button, Grid } from "@material-ui/core";
import styles from "./style";

type props = {
  classes: any;
  bundle: any;
};

const DashboardComponent = (props: props): React.JSX.Element => {
  const { classes, bundle } = props;
  const EmbedURI: any = process.env.REACT_APP_DASHBOARD_URL || "";
  const [random, setRandom] = useState(EmbedURI);

  const ReloadURI = (): any => {
    return setRandom(random + 1);
  };

  return (
    <Fragment>
      <Helmet>
        <title>{bundle.MENU_DASHBOARD_TITLE}</title>
      </Helmet>
      <Box display="flex" className={classes.container}>
        <Box flexGrow={1} className={classes.title}>
          {bundle.MENU_DASHBOARD_TITLE}
        </Box>
        <Box>
          <Button
            variant="outlined"
            size="medium"
            style={{
              border: "1px solid rgb(134, 142, 150)",
            }}
            onClick={() => ReloadURI()}
          >
            {bundle.RELOAD_BUTTON_TITLE}
          </Button>
        </Box>
      </Box>
      <Grid
        container
        justifyContent="center"
        spacing={0}
        className={classes.iframContainer}
      >
        <iframe key={random} src={EmbedURI} className={classes.iframe}></iframe>
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = (state: any): any => ({
  bundle: state.initial.bundle,
});

export default connect(
  mapStateToProps,
  null
)(withStyles(styles)(DashboardComponent));
