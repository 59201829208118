import * as _ from "lodash";
import React, { Fragment } from "react";
import { GoogleAuth } from "./Google";
import { FacebookAuth } from "./Facebook";
import { TiktokAuth} from "./Tiktok";
import { IFormElementAuth } from "../interface/IFormElementAuth";

type props = {
  onGoogleResponse: any;
  onFbResponse: any;
  authGroups: IFormElementAuth[];
};

export const LoginAuthButton = (props: props): JSX.Element => {
  const { onGoogleResponse, onFbResponse, authGroups } = props;
  const AuthGroups: IFormElementAuth[] = authGroups || [];
  const authType = [<span key="span1" />];
  for (let iAuthGroup = 0; iAuthGroup < AuthGroups.length; iAuthGroup++) {
      const TypeName = (AuthGroups[iAuthGroup].type || "")
        .toString()
        .trim()
        .toLowerCase();
      const TypeID = AuthGroups[iAuthGroup].type_id;
      const TokenAPI = AuthGroups[iAuthGroup].token_api;
      const Scope = AuthGroups[iAuthGroup].scope;
      const Fields = AuthGroups[iAuthGroup].fields;
      const AppID = AuthGroups[iAuthGroup].app_id;
      const RID = AuthGroups[iAuthGroup].rid;
      const RedirectURL = AuthGroups[iAuthGroup].redirecturl;

      if (_.isEqual(TypeName, "google")) {
        authType.push(
          <div style={{display:"flex",justifyContent:"center"}}>

         
          <GoogleAuth
            onGoogleResponse={(response: any) =>
              onGoogleResponse(response, TypeID)
            }
            scope={Scope}
            appid={AppID}
            key={iAuthGroup}
            AuthGroup={AuthGroups[iAuthGroup]}
          />
           </div>
        );
      } else if (_.isEqual(TypeName, "facebook")) {
        authType.push(
          <div style={{display:"flex",justifyContent:"center"}}>

          
          <FacebookAuth
            onFbResponse={(response: any) =>
              onFbResponse(response, TypeID, TokenAPI)
            }
            scope={Scope}
            appid={AppID}
            fields={Fields}
            key={iAuthGroup}
            AuthGroup={AuthGroups[iAuthGroup]}
          />
          </div>
        );
      }
      else if (_.isEqual(TypeName, "tiktok")) {
        authType.push(
          <div style={{display:"flex",justifyContent:"center"}}>

         
          <TiktokAuth
            appid={AppID}
            rid={RID}
            redirectURL={RedirectURL}
            key={iAuthGroup}
            AuthGroup={AuthGroups[iAuthGroup]}
          />
           </div>
        );
      }
   
  }
  return <Fragment>{authType}</Fragment>;
};
