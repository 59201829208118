import React from "react";
import classNames from "classnames";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton } from "@material-ui/core";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onClick?: any;
  title: string;
};

// Notificaion Icon
export const IconNotification = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onClick, title } = props;

  return (
    <Tooltip title={title} onClick={onClick}>
      <IconButton
        aria-label={title}
        size={"small"}
        className={classNames(classes.iconButton, classes.disabled)}
        disabled
      >
        <svg width="16.75px" height="19.5px" viewBox="0 0 16 19" version="1.1">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Template" transform="translate(-1178.000000, -23.000000)">
              <g id="Group" transform="translate(1128.000000, 12.000000)">
                <g
                  transform="translate(40.000000, 2.000000)"
                  id="Notifications"
                >
                  <g id="Group" fill="#FFFFFF" fillOpacity="0">
                    <g id="Base">
                      <circle id="Shadow" cx="18" cy="18" r="18"></circle>
                      <circle id="Fill" cx="18" cy="18" r="18"></circle>
                      <circle
                        id="Border"
                        strokeOpacity="0"
                        stroke="#FFFFFF"
                        cx="18"
                        cy="18"
                        r="17.5"
                      ></circle>
                    </g>
                  </g>
                  <g
                    id="notification-4-fill"
                    transform="translate(8.000000, 8.000000)"
                  >
                    <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
                    <path
                      d="M16.6666667,15.5558333 L17,16 C17.094693,16.1262574 17.1099247,16.295179 17.0393447,16.436339 C16.9687647,16.577499 16.8244884,16.6666667 16.6666667,16.6666667 L3.33333333,16.6666667 C3.17551164,16.6666667 3.03123534,16.577499 2.96065534,16.436339 C2.89007533,16.295179 2.90530699,16.1262574 3,16 L3.33333333,15.5558333 L3.33333333,8.33333333 C3.33333333,4.651435 6.31810167,1.66666667 10,1.66666667 C13.6818983,1.66666667 16.6666667,4.651435 16.6666667,8.33333333 L16.6666667,15.5558333 L16.6666667,15.5558333 Z M7.91666667,17.5 L12.0833333,17.5 C12.0833333,18.6505932 11.1505932,19.5833333 10,19.5833333 C8.84940677,19.5833333 7.91666667,18.6505932 7.91666667,17.5 L7.91666667,17.5 Z"
                      id="Shape"
                      fill="#6A737C"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </IconButton>
    </Tooltip>
  );
};
