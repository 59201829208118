import COLORS from "../../UI/Theme/colors";
import { Theme } from "@material-ui/core/styles";

const style = (theme: Theme): any => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			duration: theme.transitions.duration.leavingScreen,
			easing: theme.transitions.easing.sharp,
		}),
		height: 56,
		backgroundColor: COLORS.WHITE,
		padding: "0px 24px",
		width: "100%",
		alignItems: "center",
		display: "flex",
		borderBottom: "1px solid #edf0f2",
		flexDirection: "row" as const,
	},
	loginContainer: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
	},
	footer:{
		display: "flex",
		bottom: 0,
		boxSizing: "border-box",
		flexDirection: "column",
    	padding: "0 40px",
		justifyContent: "center",
    	alignItems: "center",
		zIndex: 10000
	},
	footerLinks:{
		display:"flex"
	},
	listItems: {
		color: COLORS.WHITE,
		fontSize: 12,
		fontWeight: theme.typography.fontWeightBold,
		padding: 0,
		margin: 16,
		cursor: "pointer",
		"&.Mui-selected": {
			fontWeight: theme.typography.fontWeightBold,
			backgroundColor: COLORS.WHITE,
			color: COLORS.DARK_GRAY
		},
		"&.Mui-selected:hover": {
			color: COLORS.DARK_BLUE,
			backgroundColor: "#f7f8fa"
		},
		"&.MuiListItem-button:hover": {
			color: `${COLORS.DARK_BLUE} !important`,
			backgroundColor: "#f7f8fa !important"
		},
		width:"auto"
	},
	bgImage:{
		position: "fixed",
		width: "100%",
		height: "100%",
		top: 0,
		bottom: 0,
	  },
	  loginContainerembed: {
		alignItems: "center",
		display: "flex",
		marginTop:"56px",
		flexDirection: "column",
		justifyContent: "center"
  }

});

export default style;