import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip, IconButton } from "@material-ui/core";
import style from "./style";
import classNames from "classnames";

const useStyles = makeStyles(() => createStyles(style()));

type IProps = {
  onClick?: any;
  title: string;
};

// Help Icon functional component
export const IconHelp = (props: IProps): JSX.Element => {
  const classes = useStyles();
  const { onClick, title } = props;

  return (
    <Tooltip title={title} onClick={onClick} placement="top">
      <IconButton
        aria-label={title}
        size={"small"}
        className={classNames(classes.iconButton)}
      >
        <svg width="17.25px" height="17.25px" viewBox="0 0 18 18" version="1.1">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g id="Template" transform="translate(-1137.000000, -23.000000)">
              <g id="Group" transform="translate(1128.000000, 12.000000)">
                <g transform="translate(0.000000, 2.000000)" id="Help">
                  <g id="Group" fill="#FFFFFF" fillOpacity="0">
                    <g id="Base">
                      <circle id="Shadow" cx="18" cy="18" r="18"></circle>
                      <circle id="Fill" cx="18" cy="18" r="18"></circle>
                      <circle
                        id="Border"
                        strokeOpacity="0"
                        stroke="#FFFFFF"
                        cx="18"
                        cy="18"
                        r="17.5"
                      ></circle>
                    </g>
                  </g>
                  <g
                    id="question-fill"
                    transform="translate(8.000000, 8.000000)"
                  >
                    <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
                    <path
                      d="M10,18.3333333 C5.3975,18.3333333 1.66666667,14.6025 1.66666667,10 C1.66666667,5.3975 5.3975,1.66666667 10,1.66666667 C14.6025,1.66666667 18.3333333,5.3975 18.3333333,10 C18.3333333,14.6025 14.6025,18.3333333 10,18.3333333 Z M9.16666667,12.5 L9.16666667,14.1666667 L10.8333333,14.1666667 L10.8333333,12.5 L9.16666667,12.5 Z M10.8333333,11.1291667 C12.2175123,10.7119798 13.0876309,9.34426283 12.8789451,7.91372214 C12.6702594,6.48318146 11.4456754,5.42103747 10,5.41666667 C8.60964599,5.4165607 7.41239247,6.39759016 7.13916667,7.76083333 L8.77416667,8.08833333 C8.90322473,7.44264539 9.51104488,7.00778872 10.1638197,7.09412496 C10.8165945,7.1804612 11.2904643,7.75838257 11.2472233,8.41542071 C11.2039823,9.07245885 10.6584595,9.583292 10,9.58333333 C9.53976271,9.58333333 9.16666667,9.95642938 9.16666667,10.4166667 L9.16666667,11.6666667 L10.8333333,11.6666667 L10.8333333,11.1291667 Z"
                      id="Shape"
                      fill="#6A737C"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </IconButton>
    </Tooltip>
  );
};
