import COLORS from "../../../../../../../Theme/colors";

const style:any = (): any => ({
	container:{
		
	},
	containerData:{
		display:"flex",
	},
	root: {
    flexGrow: 1,
  },
  card:{
	borderRadius: 2,
	border: "solid 1px #d0d5da",
	backgroundColor: "rgba(255, 255, 255, 0)",
	position:"relative",
	"&:hover":{
		"& $selectionCard":{
			display:"flex"
		},
		"& $selectedCard":{
			display:"none"
		},
		"& $unselectedCard":{
			display:"flex"
		},
	}
  },
	noDataAvailable:{
		color: COLORS.GRAY,
		fontSize: 14,
		fontWeight: 600,
		marginRight: 6,
		textAlign: "center",
		padding: 12,
		height: 94,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width:"100%",
		backgroundColor:COLORS.SHAINCOLOR
	},
  cardContainer:{
	padding:"8px 16px",
	minHeight:60
  },
  cardTitle:{
	  fontSize:14,
	  fontWeight:500,
	  marginBottom: 8
  },
  cardSubTitle:{
	fontSize:10,
	fontWeight:600,
	textTransform:"uppercase",
	color:"#6a737c",
	display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
	width:"100%"
  },
	middleTextEllipsis:{
		width: "100%",
		whiteSpace: "nowrap",
		display:"block",
		minHeight:21,
	},
	
	ellipsisContainer:{
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
		visibility: "hidden",
		height:0,
		maxHeight:0,
		display:"block"
	  },
	  cardImage:{
		  width:"100%",
		  height:180,
		  display:"block"
	  },
	  subtitleText:{
		overflow:"hidden"
	  }
});

export default style;