import * as _ from "lodash";
import {
	IForm
} from "../../../interface/IForm";
import { IFormElement } from "../../../interface/IFormElement";
import {
	FormElementHelper
} from "../../Utils/Element";
import { DateUtil } from "../../Utils/Date";

// Date and Days Element Helper
export class DateDaysElement{
    private elementHelper:FormElementHelper;
    private dateUtil: DateUtil;

    constructor(){
    	this.elementHelper = new FormElementHelper();
    	this.dateUtil = new DateUtil();
    }


    /**
     * Validate days relationsjip
     *
     * @param {formElement} IFormElement.
     * @param {forms} IForm[].
     * @return {Element} IFormElement.
     */
    validateDaysPickers = (formElement: IFormElement, forms: IForm[]): IFormElement =>{
    	const Element = {...formElement};
    	if(!_.isNull(formElement.parent_compare_element_id)){
    		const ParentElement = this.elementHelper.findFormElement(forms, formElement.parent_compare_element_id);
    		if(!_.isNull(ParentElement?.value) && !_.isNull(formElement.value) && !this.dateUtil.compareDays(ParentElement?.value, 
				formElement.value)){
					Element.error = `${formElement.labelName} should be less or equal to ${ParentElement?.labelName}.`;
    				Element.isValid = false;
    		}
    	}
    	else{
    		const ChildElement = this.elementHelper.findDateChildFormElement(forms, formElement.id);
    		if(!_.isNull(ChildElement) && !_.isNull(ChildElement?.value) && !_.isNull(formElement.value)
			&& !this.dateUtil.compareDays(formElement.value, ChildElement?.value)){
    			Element.error = `${formElement.labelName} should be greater or equal to ${ChildElement?.labelName}.`;
				Element.isValid = false;
    		}
    	}
    	return Element;
    }

	/**
     * Validate days time picker
     *
     * @param {formElement} IFormElement.
     * @param {forms} IForm[].
     * @return {Element} IFormElement.
     */
    validateDateTimePickers = (formElement: IFormElement, forms: IForm[]): IFormElement =>{
    	const Element: IFormElement = {...formElement};
    	if(!_.isNull(formElement.parent_compare_element_id)){
    		const ParentElement = this.elementHelper.findFormElement(forms, formElement.parent_compare_element_id);
    		if(!_.isNull(ParentElement?.value) && !_.isNull(formElement.value) && !this.dateUtil.compareDates(formElement, ParentElement?.value, 
				formElement.value)){
					Element.error = `${formElement.labelName} should be greater or equal to ${ParentElement?.labelName}.`;
    				Element.isValid = false;
    		}
    	}
    	else{
    		const ChildElement = this.elementHelper.findDateChildFormElement(forms, formElement.id);
    		if(!_.isNull(ChildElement) && !_.isNull(ChildElement?.value) && !_.isNull(formElement.value)
			&& !this.dateUtil.compareDates(formElement,
				formElement.value, ChildElement?.value)){
					Element.error = `${formElement.labelName} should be less or equal to ${ChildElement?.labelName}.`;
					Element.isValid = false;
    		}
    	}
    	return Element;
    }

	/**
     * Get element of Daterange picker 
     *
     * @param {element} IFormElement.
     * @param {formElement} IFormElement.
     * @return {Element} IFormElement.
     */
	dateRangePicker = (element: IFormElement, formElement: IFormElement): IFormElement =>{
		let Element = {...element};
		const RValue = this.dateUtil.getDateRangeValue(formElement, Element.value);
		const MinDate = this.dateUtil.formatDateMinMaxValue(
			Element.min_range,
			formElement
		  );
		  const MaxDate = this.dateUtil.formatDateMinMaxValue(
			Element.max_range,
			formElement
		  );
		   
		Element = {
			...Element,
			min_range: MinDate,
			max_range: MaxDate,
			value: RValue
		};
		return Element;
	 }
}
