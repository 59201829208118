import * as _ from "lodash";
import { IForm } from "../../../interface/IForm";
import { IFormElement } from "../../../interface/IFormElement";
import { IFormGroup } from "../../../interface/IFormGroup";
import { UtilHelper } from "../../Utils";
import {
	UI_ELEMENT_TYPES
} from "../../../../config";
import { IElementUpdate } from "../../../interface/IElementUpdate";
import { IFormElementValue } from "../../../interface/IFormElementValue";
import { IOption } from "../../../interface/IOption";
import { FileValidation } from "./File";
import { RequiredValidation } from "./Required";
import { LengthValidation } from "./Length";
import { RangeValidation } from "./Range";
import { RegexValidation } from "./Regex";
import { EmailValidation } from "./Email";
import { URLValidation } from "./URL";
import { DateValidation } from "./Date";

// Validation helper for forms
export class ValidationHelper {
	private utilHelper: UtilHelper;
	private fileValidation: FileValidation;
	private requiredValidation: RequiredValidation;
	private lengthValidation: LengthValidation;
	private rangeValidation: RangeValidation;
	private regexValidation: RegexValidation;
	private emailValidation: EmailValidation;
	private urlValidation: URLValidation;
	private dateValidation: DateValidation;

	constructor() {
		this.utilHelper = new UtilHelper();
		this.fileValidation = new FileValidation();
		this.requiredValidation = new RequiredValidation();
		this.lengthValidation = new LengthValidation();
		this.rangeValidation = new RangeValidation();
		this.regexValidation = new RegexValidation();
		this.emailValidation = new EmailValidation();
		this.urlValidation = new URLValidation();
		this.dateValidation = new DateValidation();
	}

	/**
	 * Check if the current and previous element value are same or not
	 *
	 * @param {IFormElement} formElement.
	 * @param {IFormElementValue} element.
	 * @return {boolean}
	 */
	isPreviousAndCurrentValueAreSame = (formElement: IFormElement, element: IFormElementValue): boolean => {
		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)
			&& formElement.is_multi) {
			return false;
		}

		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)) {
			return false;
		}

		let previousValue = element.previousValue;
		let currentValue = element.currentValue;

		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)
			&& !formElement.is_multi) {
			previousValue = _.isNull(previousValue) ? null : previousValue.value;
			currentValue = _.isNull(currentValue) ? null : currentValue.value;
		}
		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.THUMBNAIL)) {
			previousValue = _.isNull(previousValue) ? null : previousValue.id;
			currentValue = _.isNull(currentValue) ? null : currentValue.id;
		}

		previousValue = (previousValue || "").toString().trim();
		currentValue = (currentValue || "").toString().trim();

		return _.isEqual(currentValue, previousValue);
	}

	/**
	 * Validate forms
	 *
	 * @param {IForm[]} forms.
	 * @param {IElementUpdate} element.
	 * @param {boolean} isDelayed.
	 * @return {string} errorMessages.
	 */
	isValidForms = (forms: IForm[]): IForm[] => {
		return forms.map((form: IForm) => {
			let formIsValid = true;
			let elementInProgress = false;
			let formIsVisible = false;
			return ({
				...form,
				groups: form.groups.map((group: IFormGroup) => {
					let groupIsVisible = false;
					const Group: IFormGroup = ({
						...group,
						elements: group.elements.map((formElement: IFormElement) => {
							let isElementValid = formElement.isValid;
							if (!formElement.is_visible) {
								isElementValid = true;
							}
							elementInProgress = elementInProgress || formElement.loading;

							formIsValid = formIsValid && isElementValid;
							groupIsVisible = groupIsVisible || formElement.is_visible;

							return {
								...formElement,
							};
						}),
						isVisible: groupIsVisible
					});
					formIsVisible = formIsVisible || groupIsVisible;

					return Group;
				}),
				isVisible: formIsVisible,
				isValid: formIsValid,
				loading: elementInProgress
			});
		});
	}

	// * Validate element
	// *
	// * @param {IForm[]} forms.
	// *
	validateElements = async (forms: IForm[]): Promise<IForm[]> => {
		const Forms: IForm[] = [];
		for (const form of forms) {
		  const Groups: IFormGroup[] = [];
		  for (const formGroup of form.groups) {
			const Elements: IFormElement[] = [];
			for (const formElement of formGroup.elements) {
			  const FormElement: IFormElement = { ...formElement };
			  const isValid =
				_.isEqual((FormElement.error || "").length, 0) &&
				_.isEqual((FormElement.apiError || "").length, 0) &&
				FormElement.isValid;
	
			  Elements.push({
				...FormElement,
				isValid,
			  });
			}
			Groups.push({
			  ...formGroup,
			  elements: Elements,
			});
		  }
		  Forms.push({
			...form,
			groups: Groups,
		  });
		}
		return Forms;
	  };

	/**
	 * Validate element
	 *
	 * @param {IForm[]} forms.
	 * @param {IElementUpdate} element.
	 * @param {boolean} isDelayed.
	 * @return {string} errorMessages.
	 */
	isValidElement = async (forms: IForm[], element?: IElementUpdate): Promise<IForm[]> => {
		const Forms: IForm[] = [];
		for (const form of forms) {
			const Groups: IFormGroup[] = [];
			for (const formGroup of form.groups) {
				const Elements: IFormElement[] = [];
				for (const formElement of formGroup.elements) {
					const FormElement: IFormElement = { ...formElement };
					if (_.isEqual(FormElement.id, element?.formElement.id)) {
						const error = await this.validate(FormElement, element?.value);
						let isValid = _.isEqual((error || "").length, 0) && _.isEqual((FormElement.highlight_option_error || "").length, 0);
						if(!_.isEqual(FormElement?.allowed_file_type,null) && !_.isEqual(FormElement?.value,null)){
							if(FormElement?.value?.acceptedFiles && _.isEqual(FormElement?.value.acceptedFiles[0]?.status,"pending")){
								isValid= false;
							}
						}
						let touched = true;
						if (_.isEqual(FormElement.type_id, UI_ELEMENT_TYPES.DATERANGE_INPUT)) {
							touched = this.dateValidation.isValidTouchForDateRange(element?.value);
						}
	
    					Elements.push({
    						...FormElement,
    						touched,
    						error,
    						isValid
    					});
    				}else{

						Elements.push({
    						...FormElement
    					});
    				}
    			}
    			Groups.push({
    				...formGroup,
    				elements: Elements
    			});
    		}
    		Forms.push({
    			...form,
    			groups: Groups
    		});
    	}
    	return Forms;
    }

    /**
     * Validate form element
     *
     * @param {IFormElement} formElement.
     * @param {any} elementValue.
     * @return {string} errorMessages.
     */
    validate = async (formElement: IFormElement, elementValue:any): Promise<string> =>{
    	let errorMessage = "";

    	const IsEmptyField = this.utilHelper.isEmptyElementValue(formElement, elementValue);
        
    	// If the field is empty and not required
    	if (IsEmptyField && !formElement.is_required){
    		return errorMessage;
    	}

    	if(!_.isNull(formElement.selection_limit) && !_.isNull(formElement.value)
        && _.isArray(formElement.value)
        && _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.SELECT_INPUT)
        && formElement.is_multi){
    		const isAll = formElement.value.filter((option: IOption) => _.isEqual(option.value, UtilHelper.allOptionValue));
    		const DataSourceLength = formElement.allow_all ? formElement.dataSource.length - 1 : formElement.dataSource.length;
    		if((!_.isEqual(isAll.length, 0)  && _.gt(DataSourceLength, formElement.selection_limit)) || _.gt(formElement.value.length,formElement.selection_limit)){
    			return `Please select only ${formElement.selection_limit} ${formElement.labelName}.`;
    		}
    	}

    	// Date field
    	if(_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DATETIME_INPUT)
        && !formElement.is_number){
    		errorMessage = this.dateValidation.validate(formElement, elementValue);
    		if(!_.isEqual(errorMessage.length, 0)) return errorMessage;
    	}

    	// Required field validation
    	if(formElement.is_required){
    		errorMessage = this.requiredValidation.validate(formElement, 
    			elementValue);

    		if(!_.isEqual(errorMessage.length, 0)) return errorMessage;
    	}

		// Validate date range picker
		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DATERANGE_INPUT)) {
			errorMessage = this.dateValidation.validateDateRangePicker(formElement, elementValue);
			if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
		}

		// Character length validation
		// Applicable for text, number
		if ((_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT) ||
			_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)
			|| (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DATETIME_INPUT) && formElement.is_number))) {
			if (!_.isNull(formElement.min_character) || !_.isNull(formElement.max_character)) {
				errorMessage = this.lengthValidation.validate(formElement,
					elementValue);
				if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
			}
		}


		// Check if is_email field is true and applicable only on text input
		if (formElement.is_email && _.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
			&& !formElement.is_password) {
			errorMessage = this.emailValidation.validate(elementValue);

			if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
		}

		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
			&& formElement.is_url
			&& !formElement.is_email && !formElement.is_number
			&& !formElement.is_password) {
			errorMessage = this.urlValidation.validate(formElement, elementValue);

			if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
		}

		// Decimal number validation
		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
			&& formElement.is_number && formElement.is_decimal) {
			const IsValidDecimalNumber = this.utilHelper.getValidDecimalNumber(elementValue);
			if (_.isNull(IsValidDecimalNumber)) {
				return `${formElement.labelName} is not a valid decimal number. ${this.rangeValidation.getRangeMessage(formElement)}`;
			}
		}

		// Number range validation
		// Applicable for number input
		if ((_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
			&& formElement.is_number && !formElement.is_password
			&& !formElement.is_multi) || (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.DATETIME_INPUT) && formElement.is_number)) {
			if (!_.isNull(formElement.min_range) || !_.isNull(formElement.max_range)) {

				errorMessage = this.rangeValidation.validate(formElement,
					elementValue);
				if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
			}
		}

		// Regular expression
		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.TEXT_INPUT)
			&& !_.isEqual((formElement.regexp_pattern || "").toString().trim().length, 0)) {
			errorMessage = this.regexValidation.validate(formElement, elementValue);
			if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
		}

		// File input validation (Bucket and Grid upload)
		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.FILE_INPUT)) {

			if (!formElement.load_data_to_grid
				&& !_.isNull(formElement.value)) {

				const RejectedFilesLen = formElement.value.rejectedFiles.length;
				if (formElement.value.isFileLimitExceeded) {
					return `Only ${formElement.selection_limit} files are allowed to upload. Please upload the files again.`;
				}
				
				const FileText = _.gt(RejectedFilesLen, 1) ? "s" : "";

				if (!_.isEqual(RejectedFilesLen, 0)) {
					return errorMessage = `Please delete the invalid file${FileText}.`;
				}

				if (!this.fileValidation.isValidFileInputText(formElement.value.acceptedFiles, formElement)
					&& !formElement.loading) {
					errorMessage = `Please fill the required ${formElement.customConfiguration.bucketUpload?.textBox?.placeholder}.`;
				}
			}

			if (formElement.load_data_to_grid) {
				errorMessage = formElement.error || "";
				if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
				const isValid = this.fileValidation.isValidGrid(formElement.dynamic_configuration.headerData, formElement.dynamic_fields_values);
				errorMessage = isValid ? "" : "Edit to review and fill missing data";
			}

			if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
			return errorMessage;
		}

		// Image with Text Field validation
		if (_.isEqual(formElement.type_id, UI_ELEMENT_TYPES.IMAGE_TEXT_FIELD)) {

			if (!_.isNull(formElement.value) && !_.isNull(formElement.value.file)) {
				errorMessage = formElement.value.file.isValidForUpload ? "" : "Invalid file. Please upload file again.";
			}

			if (!_.isEqual(errorMessage.length, 0)) return errorMessage;
			return errorMessage;
		}

		return errorMessage;
	}

	/**
	* Validate imageInput
	*
	* @param {IFormElement} formElement.
	* @param {any} elementValue.
	* @return {object} {errorMessage, errorType}.
	*/
	validateImageInput = (formElement: IFormElement, elementValue: any): any => {
		let errorMessage = "";
		const ErrorTypes: string[] = [];
		// Required field validation
		if (formElement.is_required) {
			const { message, error } = this.requiredValidation.validateImageInput(elementValue, formElement.labelName);
			errorMessage = message;

			if (!_.isEqual(message.length, 0)) {
				ErrorTypes.push(error);
			}
		}

		// Character length validation
		if (!_.isNull(formElement.min_character) || !_.isNull(formElement.max_character)) {
			const Error = this.lengthValidation.validate(formElement,
				elementValue);
			errorMessage = !_.isEqual(Error.length, 0) ? Error : errorMessage;
			if (!_.isEqual(Error.length, 0)) {
				ErrorTypes.push("text");
			}
		}

		// Image with Text Field validation
		if (!_.isNull(formElement.value) && !_.isNull(formElement.value.file)) {
			const Error = formElement.value.file.isValidForUpload ? "" : "Invalid image. Please upload a valid image.";
			errorMessage = !_.isEqual(Error.length, 0) ? Error : errorMessage;
			if (!_.isEqual(Error.length, 0)) {
				ErrorTypes.push("file");
			}
		}

		// Upload error
		if (!_.isNull(formElement.value) && !_.isNull(formElement.value.file)) {
			const Error = !_.isEqual(formElement.value.file.status, "error") ? "" : "Unable to upload the image. Please retry.";
			errorMessage = !_.isEqual(Error.length, 0) ? Error : errorMessage;
			if (!_.isEqual(Error.length, 0)) {
				ErrorTypes.push("file");
			}
		}


		return {
			ErrorTypes,
			error: errorMessage
		};
	}
}