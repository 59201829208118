import React from "react";
import * as _ from "lodash";
import { UITextInput } from "../TextInput";
import { UIPasswordInput } from "../Password";
import { UINumberInput } from "../Number";
import { UIDecimalNumberInput } from "../DecimalNumber";
import DatasetField from "../Dataset";

export const getTextInputElement = (props: any): JSX.Element => {
  const { formId, formGroupId, onChange, onValidateForms, formElement, form } =
    props;
  if (formElement.is_password) {
    return (
      <UIPasswordInput
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        onValidateForms={onValidateForms}
        formElement={formElement}
      />
    );
  } else if (formElement.is_number && formElement.is_decimal) {
    // Decimal Number input
    return (
      <UIDecimalNumberInput
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        onValidateForms={onValidateForms}
        formElement={formElement}
      />
    );
  } else if (formElement.is_number) {
    // Number input
    return (
      <UINumberInput
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        onValidateForms={onValidateForms}
        formElement={formElement}
      />
    );
  } else if (_.isEqual(formElement.is_dataset, 1)) {
    // Update Option
    return (
      <DatasetField
        key={formElement.id}
        formId={formId}
        formGroupId={formGroupId}
        onChange={onChange}
        onValidateForms={onValidateForms}
        formElement={formElement}
        form={form}
      />
    );
  }
  return (
    <UITextInput
      key={formElement.id}
      formId={formId}
      formGroupId={formGroupId}
      onChange={onChange}
      onValidateForms={onValidateForms}
      formElement={formElement}
    />
  );
};
