import React, { Fragment } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import style from "./style";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { UILabel } from "../Child/Label";
import { Chip } from "./Chip";
import { IOption } from "../../Forms/interface/IOption";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Props type
// Props type
type props = {
  formElement: IFormElement;
};

// UIChips Component
export const UIChips = (props: props): JSX.Element => {
  const { formElement } = props;
  const classes = useStyles();

  const ChipData = _.sortBy(
    (_.isArray(formElement.value) ? formElement.value : []).map(
      (option: IOption) => (option.label || "").toString().trim()
    )
  );

  return (
    <Fragment>
      <UILabel formElement={formElement} />
      <div className={classes.container}>
        {ChipData.map((chip: string, index: number) => (
          <Chip key={index} chip={chip}></Chip>
        ))}
      </div>
    </Fragment>
  );
};

export default UIChips;
