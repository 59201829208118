import { ISearchPlaceholder } from "../../Interfaces/Page/ISearchPlaceholder";

export const searchPlaceHolder : ISearchPlaceholder[] =[
    {
        id:"Google Ads",
        value:"Search by Manager Account or Account"
    },
    {
        id:"Facebook Business Manager",
        value:"Search by Business Manager or Ad Account"
    },
    {
        id:"Campaign Manager",
        value:"Search"
    },
    {
        id:"DV360",
        value:"Search"
    }
];