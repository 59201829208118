import * as _ from "lodash";

// Async error handler
export const errorHandlerAsync = (handler: any) => {
  return async function (): Promise<any> {
    try {
      return await handler(...arguments);
    } catch (error) {
      console.log(error);
    }
  };
};

// Sync error handler
export function errorHandlerSync(handler: any) {
  return function (): any {
    try {
      return handler(...arguments);
    } catch (error) {
      console.log(error);
    }
  };
}

export function catchError(error: any): any {
  if (!_.isUndefined(error.response)) {
    if (!_.isUndefined(error.response.data.message)) {
      return error.response.data.message;
    }
    return error.message;
  }

  // Show Error (Error Message)
  return error.message;
}

/**
 * Get status code from response
 *
 * @param {IForm[]} forms.
 * @return IFormElement | null.
 */
export function getErrorStatusCode(responseObj: any): any {
  if (!_.isUndefined(responseObj.response)) {
    if (!_.isUndefined(responseObj.response.status)) {
      return responseObj.response.status;
    }
    return null;
  }
  return null;
}
