import React from "react";
import * as _ from "lodash";
import { IFileUpload } from "../../../../../UI/Elements/Forms/interface/File/IFileUpload";
import { ErrorIcon } from "../../../../../UI/Elements/FormElements/UploadFile/Icons";
import { UILinearProgress } from "../../../../../UI/Progress/LinearProgress/BucketUpload";
import { UIFileComponent } from "../../../../../UI/Progress/LinearProgress/FileComponent";

const renderAcceptedFiles = (
  acceptedFiles: IFileUpload[],
  activeFile: any,
  onOpenDialog: any,
  onRetry: any,
  loading: boolean
): React.JSX.Element[] => {
  return acceptedFiles.map((file: IFileUpload) => {
    let fileStatus = file.status;
    if (!_.isNull(activeFile)) {
      fileStatus = _.isEqual(file.id, activeFile.id)
        ? "uploading"
        : file.status;
    }

    return (
      <UILinearProgress
        key={file.id}
        onDelete={() => onOpenDialog(file, "delete")}
        onRetry={() => onRetry(file)}
        onCancel={() => onOpenDialog(file, "cancel")}
        status={fileStatus}
        loading={loading}
        file={file}
        formElement={null}
        onInputChange={() => { ""; }}
        showTextBox={false}
      />
    );
  });
};

const renderRejectedFiles = (
  files: IFileUpload[],
  onDeleteErrorFile: any
): React.JSX.Element[] => {
  return files.map((file: any) => {
    return (
      <UIFileComponent
        key={file.id}
        onDelete={() => onDeleteErrorFile(file)}
        status={"error"}
        loading={false}
        file={file}
        showRetryOption={false}
        isPending={false}
        isUploading={false}
        fileIcon={ErrorIcon}
        formElement={null}
        onInputChange={() => { ""; }}
        showTextBox={false}
        isRejectFile={true}
      />
    );
  });
};

export default {
  renderAcceptedFiles,
  renderRejectedFiles,
};
