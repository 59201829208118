import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import style from "./style";

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

// Props type
type props = {
  label: string;
  value: any;
};

// UIAccountDataPlaceholder Component
export const UIAccountDataPlaceholder = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const { label, value } = props;

  return (
    <div className={classes.placeholderContainer}>
      {label}&nbsp;:&nbsp;{value}
    </div>
  );
};
