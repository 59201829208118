import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip } from "@material-ui/core";
import style from "./style";
import * as _ from "lodash";

const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
    title?: any;
};

// downloadButton Component
export const IconFile = (props: props): JSX.Element => {
    const classes = useStyles();
    const { title } = props;

    return (
        <Tooltip title={!_.isEmpty(title) ? title : ""} placement="top">
            <span>
                <IconButton
                    size={"small"}
                    className={classes.iconDownload}
                >
                    <svg xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width="18" height="18">
                        <path d="M21 9V20.9925C21 21.5511 20.5552 22 20.0066 22H3.9934C3.44495 22 3 21.556 3 21.0082V2.9918C3 2.45531 3.44694 2 3.99826 2H14V8C14 8.55228 14.4477 9 15 9H21ZM21 7H16V2.00318L21 7Z"
                            fill="rgba(106,115,124,1)">
                        </path>
                    </svg>
                </IconButton>
            </span>
        </Tooltip>
    );
};

export default IconFile;
