import {
  GRID_GOOGLEADS_HEADERS,
  getGoogleAdsFormConfig_review,
} from "./Configuration/GoogleAds";
import { searchPlaceHolder } from "./Configuration/configuration";
import * as _ from "lodash";
import {
  META_GRID_HEADERS,
  getMetaFormConfig,
} from "./Configuration/Meta";
import { PLATFORM_TYPE } from "../configuration";

export const getGridHeader = (tab: any): any => {
  const findPlatform = tab?.platform.data.find((ele: any) => {
    return _.isEqual(ele.value, tab?.platform.value);
  });

  let data: any = {};
  switch (findPlatform?.label) {
    case PLATFORM_TYPE.GOOGLE_ADS:
      data = GRID_GOOGLEADS_HEADERS;
      break;
    case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
      data = META_GRID_HEADERS;
      break;
    default:
      data = GRID_GOOGLEADS_HEADERS;
      break;
  }
  return data;
};

export const getEditFormConfiguration = (tab: any): any => {
  const findPlatform = tab?.platform.data.find((ele: any) => {
    return _.isEqual(ele.value, tab?.platform.value);
  });

  let data: any = {};
  switch (findPlatform?.label) {
    case PLATFORM_TYPE.GOOGLE_ADS:
      data = getGoogleAdsFormConfig_review({});
      break;
    case PLATFORM_TYPE.FACEBOOK_BUSINESS_MANAGER:
      data = getMetaFormConfig({});
      break;
    default:
      data = getGoogleAdsFormConfig_review({});
      break;
  }
  return data;
};

export const getSearchPlaceHolder = (tab: any): any => {
  const findPlatform = tab?.platform.data.find((ele: any) => {
    return _.isEqual(ele.value, tab?.platform.value);
  });

  const findPlaceHolder = searchPlaceHolder.find((ele: any) => {
    return _.isEqual(ele.id, findPlatform?.label);
  });

  if (_.isUndefined(findPlaceHolder)) {
    return "Search";
  } else {
    return findPlaceHolder.value;
  }
};
