import React, { ChangeEvent } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import classNames from "classnames";
import CloseIcon from "@material-ui/icons/Clear";
import { IconButton, Input, InputAdornment } from "@material-ui/core";
import style from "./style";
import { IconSearch } from "../../../../../../Icons";
import { IFormElement } from "../../../../../Forms/interface/IFormElement";
import { IFilter } from "../IFilter";
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  formElement: IFormElement;
  searchHandler: any;
  filter: IFilter;
};

export const DataGridEditorSearch = (props: props): JSX.Element => {
  const classes = useStyles();
  const { searchHandler, filter } = props;

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <IconSearch title={""} />
      </div>
      <Input
        type="input"
        placeholder="Search"
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          searchHandler((event.target.value || "").toString())
        }
        value={filter.search}
        className={classNames(classes.inputInput, classes.inputElement)}
        inputProps={{ "aria-label": "search" }}
        disableUnderline={true}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              style={{
                visibility: _.isEqual(filter.search.length, 0)
                  ? "hidden"
                  : "visible",
              }}
              size={"small"}
              onClick={() => {
                searchHandler("");
              }}
            >
              <CloseIcon fontSize="small" className={classes.closeButton} />
            </IconButton>
          </InputAdornment>
        }
      />
    </div>
  );
};
