import COLORS from "./Theme/colors";

const style: any = (): any => {
	return {
		formControl: {
			marginTop: 0,
			marginBottom: 0
		},
		errorMessage: {
			fontSize: "0.72rem",
			fontStyle: "italic",
			color: COLORS.RED,
			minHeight: 16
		},
		inlineInputContainer: {
			display: "grid" as const,
			alignItems: "center" as const,
			fontSize: "0.75rem"
		},
		inlineInputLabel: {
			boxSizing: "border-box" as const,
			textAlign: "right" as const,
			paddingRight: 5,
			fontWeight: 500,
			fontSize: "0.75rem",
			maxWidth: 140,
			minWidth: 140,
			display: "flex" as const,
			alignItems: "center" as const,
			justifyContent: "flex-end" as const
		},
		inlineInput: {
			width: "100%"
		},
	};
};

export default style;