import React, { MouseEventHandler } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Table, TableBody, TableContainer, TableRow } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import classnames from "classnames";
import GridHead from "./PreviewHeader";
import GridCell from "./PreviewBody";
import { ErrorIcon } from "../../UploadFile/Icons";
import style from "./style";
import { IFormElement } from "../../../Forms/interface/IFormElement";
import { IGridHeader } from "../../../Forms/interface/Grid/IGridHeader";
import { UI_GRID_CELL_EDITTOR_TYPES } from "../../../config";
import { IOption } from "../../../Forms/interface/IOption";
import { ExcelHelper } from "../../../Forms/helper/Utils/FileUtil/Excel";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  formElement: IFormElement;
  enableEdit?: boolean;
  onEditGrid?: MouseEventHandler;
  errorMessage?: string;
};

export default function PreviewGrid(props: props): JSX.Element {
  const classes = useStyles();
  const { formElement, enableEdit, errorMessage } = props;
  const DynamicGridData = formElement.dynamic_fields_values || [];

  const HeaderData: IGridHeader[] =
    formElement.dynamic_configuration.headerData || [];

  const GridData = _.slice(DynamicGridData, 0, 5);

  const TblBody = GridData.map((row: any, index: number) => {
    const TblCells = HeaderData.filter(
      (header: IGridHeader) => !header.hide
    ).map((header: IGridHeader, i: number) => {
      let elementValue = row[header.field];
      if (_.isEqual(header.type, UI_GRID_CELL_EDITTOR_TYPES.DROPDOWN)) {
        const ElementValue = (elementValue || "").toString().trim();
        const d :any= header?.dataSource?.data;
        const DropDownValue: any = (
          _.isArray(d) ? (d) : []
        ).find((option: IOption) =>
          _.isEqual(_.toLower(option.value), _.toLower(ElementValue))
        );

        elementValue = _.isUndefined(DropDownValue) ? "" : elementValue;
      }

      return <GridCell key={i} data={elementValue} />;
    });
    return (
      <TableRow key={index} className={classes.tableRow}>
        {TblCells}
      </TableRow>
    );
  });

  const ExportToExcel = (): void => {
    const excelHelper = new ExcelHelper();
    const UpdatedSheetData = excelHelper.generateExcelData(
      formElement.dynamic_fields_values,
      formElement
    );

    const { fileName, sheetName } =
      formElement.dynamic_configuration.exportOptions;
    const FileName = `${fileName}${moment(new Date()).format(
      "yyyy_MM_DD_HH_mm"
    )}`;
    excelHelper.export(
      UpdatedSheetData,
      FileName || "data",
      sheetName || "datasheet"
    );
  };

  return (
    <TableContainer>
      <div className={classes.previewHeaderContainer}>
        <div className={classes.previewHeader}>
          <div className={classes.previewLabel}>Preview</div>
          {enableEdit && (
            <div
              className={classnames(classes.previewLinks, classes.editLink)}
              onClick={props.onEditGrid}
            >
              Edit
            </div>
          )}
        </div>
        <div className={classes.previewLinks} onClick={() => ExportToExcel()}>
          Download
        </div>
      </div>

      {!_.isEmpty(errorMessage) && (
        <div className={classes.errorMessage}>
          <div className={classes.errorIcon}>{ErrorIcon}</div>
          {errorMessage}
        </div>
      )}
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <GridHead
            headers={formElement.dynamic_configuration.headerData || []}
          />
          <TableBody>{TblBody}</TableBody>
        </Table>
      </div>
    </TableContainer>
  );
}
