import {
	createStore,
	applyMiddleware
} from "redux";
import {
	composeWithDevTools
} from "redux-devtools-extension";
import ReduxThunk from "redux-thunk";
import RootReducer from "./reducers";

const middleware = [ReduxThunk];

const store = createStore(RootReducer,
	composeWithDevTools(applyMiddleware(...middleware)));

export default store;