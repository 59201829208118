import axios, { AxiosResponse } from "axios";
import * as _ from "lodash";
import { GENERATE_GOOGLE_SIGNED_URL, GOOGLE_ACCESS_TOKEN_URL, GOOGLE_BUCKET_PUBLIC_FILE_URL } from "../../../../../../api/paths";
import { Axios } from "../../../../../../api/service/Axios";

// Google Util Helper
export class GoogleUtilHelper {

	/**
	 * Get Service Account Access Token for Google Bucket
	 *
	 * @return string | null.
	 */
	getSAAccessToken = async (): Promise<string | null> => {
		try {
			const APIResponse = await Axios.get(GOOGLE_ACCESS_TOKEN_URL);
			return (APIResponse.data || "")
				.toString()
				.replace(/\.+$/, "");
		}
		catch {
			return null;
		}
	}

	/**
	 * Get Public URL for google bucket file
	 *
	 * @return string | null.
	 */
	getBlobPublicURL = async (blobName: string, bucketName: string): Promise<string | null> => {
		try {
			const PublicURL = await Axios.get(GOOGLE_BUCKET_PUBLIC_FILE_URL(blobName, bucketName));
			return PublicURL.data;
		}
		catch {
			return null;
		}
	}

	/**
	 * Get Signed URL for google bucket file
	 *
	 * @return string | null.
	 */
	getSignedURL = async (blobName: string): Promise<string | null> => {
		try {
			const SignedURL = await Axios.post(GENERATE_GOOGLE_SIGNED_URL, {
				blob_name: blobName,
				bucket_name: process.env.REACT_APP_EXT_TOOL_FILE_UPLOAD_GOOGLE_BUCKET,
			});
			return SignedURL.data;
		}
		catch {
			return null;
		}
	}

	/**
	 * Get File Upload Session URI
	 *
	 * @return string | null.
	 */
	getUploadSessionURI = async (bucketName: string, accessToken: string, fileName: string, fileType: string): Promise<string | null> => {
		try {
			const UploadSession: AxiosResponse = await axios({
				method: "POST",
				url: `https://storage.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=resumable&name=${fileName}`,
				headers: {
					Authorization: `Bearer ${accessToken}`,
					"X-Upload-Content-Type": fileType,
				}
			});
			return UploadSession.headers.location;

		}
		catch {
			return null;
		}
	}

	/**
	 * Cancel File Upload
	 *
	 * @return string | null.
	 */
	cancelFileUpload = async (uploadSessionURI: string): Promise<boolean | null> => {
		try {
			await axios.delete(
				uploadSessionURI,
				{
					headers: {
						"X-Upload-Content-Length": 0,
					},
				}
			);
			return true;
		}
		catch {
			return null;
		}
	}

	/**
	 * Delete file from google bucket
	 *
	 * @return string | null.
	 */
	deleteFileFromBucket = async (fileName: string, bucketName: string): Promise<boolean | null> => {
		try {
			const AccessToken = await this.getSAAccessToken();
			await axios.delete(
				`https://storage.googleapis.com/storage/v1/b/${bucketName}/o/${fileName}`,
				{
					headers: {
						Authorization: `Bearer ${AccessToken}`,
					},
				}
			);
			return true;
		}
		catch {
			return null;
		}
	}

	/**
	 * Check file status of uploaded file on google bucket
	 *
	 * @return string | null.
	 */
	isFileUploadSuccessful = async (uploadSessionURI: string, totalFileSize: number): Promise<any> => {
		try {
			const Response: AxiosResponse = await axios({
				method: "PUT",
				headers: {
					"X-Upload-Content-Length": 0,
					"Content-Range": `bytes */${totalFileSize}`,
				},
				url: uploadSessionURI
			});
			return {
				name: Response.data.name,
				status: 200
			};
		}
		catch (error: any) {
			if (axios.isAxiosError(error)) {
				if (_.isEqual(error.response?.status, 308)) {
					const RangeHeader: any = error?.response?.headers.range;
					if (!_.isUndefined(RangeHeader)) {
						const LastBytes = parseInt(RangeHeader.split("=").join("").split("-")[1]);

						return {
							error: false,
							status: 308,
							contentLength: totalFileSize - LastBytes,
							range: {
								start: LastBytes + 1,
								end: totalFileSize - 1
							}
						};
					}
				}
			}

			return {
				error: true,
				status: 400
			};
		}
	}
}
