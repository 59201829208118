const style: any = (): any => {
	return {
		toggleButton: {
			marginLeft: "0px !important",
			borderRadius: 0,
			border: 0,
			padding: "7px 17px",
			height: 36,
			color: "#242c34 !important",
			backgroundColor: "#FFFF",
			fontWeight: "normal" as const,
			textTransform: "none" as const,
			fontSize: 14,
			

		},
		toggleButtonLifecycle: {
			marginLeft: "0px !important",
			borderRadius: 0,
			padding: "7px 16.4px",
			backgroundColor: "#FFFF",
			height: 36,
			border: 0,
			fontSize: 14,
			fontWeight: "medium" as const,
			textTransform: "none" as const,
			color: "#242c34 !important",
		},
		toggleButtonGroup: {
			border: "1px solid #d0d5da",
			display: "inline-block",
			width:"100%",
			borderRadius: 2,
		},
		selectedButton: {
			borderRadius: 2,
			border: "1px solid #3a91e8 !important",
			backgroundColor: "#f0f8ff !important",
			boxShadow: "0px 1px 4px 0px rgba(21, 118, 214, 0.15)",
		},
		disabledButton: {
			borderRadius: 2,
			border: "1px solid #242c34 !important",
			backgroundColor: "#FFFF",
			boxShadow: "0px 1px 4px 0px rgba(21, 118, 214, 0.15)",
		}
	};
};

export default style;