import * as _ from "lodash";

import { FORM_ELEMENT_TYPES } from "../../Form/ElementTypes";
import { IFormElement } from "../../Interface/FormElement/IFormElement";
import { IForm } from "../../Interface/IForm";
import { IFormGroup } from "../../Interface/IFormGroup";
import { UtilHelper } from "../Utility";
import { FormElementHelper } from "./Element";
import { MultiSelectHelper } from "./SelectInput/MultiSelect";
import { SingleSelectHelper } from "./SelectInput/SingleSelect";

export class NewFormHelper {
  private utilHelper: UtilHelper;
  private elementHelper: FormElementHelper;
  private singleSelect: SingleSelectHelper;
  private multiSelect: MultiSelectHelper;

  constructor(){
    this.utilHelper = new UtilHelper();
    this.elementHelper = new FormElementHelper();
    this.singleSelect = new SingleSelectHelper();
    this.multiSelect = new MultiSelectHelper();
  }

  public buildNewForm(dataSources: any, profileForms: IForm[]): IForm[] {
    let Forms = profileForms.map((form: IForm) => {
      return {
        ...form,
        groups: form.groups.map((formGroup: IFormGroup) => {
          return {
            ...formGroup,
            elements: formGroup.elements.map((formElement) => {
              const Element = this.buildNewFormElement(formElement, dataSources);
              return {
                ...Element,
                isValid: Element.validations.required ? Element.isValid : true,
                touched: false,
              };
            }),
          };
        }),
      };
    });
    Forms = this.validateParentChildRelationship(Forms);
		return Forms;
  }

  public buildNewFormElement = (
    formElement: IFormElement,
    dataSources: any
  ): IFormElement => {
    let element;
    const DefaultValue = formElement.configuration.defaultValue;
    const ElementVisibility = formElement.configuration.visibility;

    switch (formElement.type) {
      case FORM_ELEMENT_TYPES.SELECT_INPUT:
        element = {
          ...formElement,
          configuration: {
            ...formElement.configuration,
            dataSource: dataSources[formElement.id],
          },
          value: DefaultValue || null,
        };
        break;
      case FORM_ELEMENT_TYPES.SWITCH:
        element = {
          ...formElement,
          value: DefaultValue || false,
        };
        break;
      case FORM_ELEMENT_TYPES.RADIO_INPUT:
        element = {
          ...formElement,
          value: DefaultValue || false,
        };
        break;
      default:
        element = {
          ...formElement,
          value: DefaultValue || "",
        };
        break;
    }
    return {
      ...element,
      visible: _.isUndefined(ElementVisibility) ? element.visible : ElementVisibility.newForm
    };
  };

            /**
     * Validate parent and child relationship for date and select
     *
     * @param {forms} IForm[].
     * @return {UpdatedForms} IForm[].
     */
            validateParentChildRelationship = (forms: IForm[]): IForm[] =>{
              const UPDATED_FORMS = [];
              for(let iForm=0;iForm<forms.length;iForm++){
                let UpdatedForm = ({
                  ...forms[iForm],
                  groups: forms[iForm].groups.map((group: IFormGroup)=>({
                    ...group,
                    elements: group.elements.map((element: IFormElement)=>({...element}))
                  }))
                });
               
                const FormGroups = UpdatedForm.groups;
                  for(let iFormGroup=0;iFormGroup<FormGroups.length;iFormGroup++){
                    const FormElements = FormGroups[iFormGroup].elements;
            
                    for(let iFormElement=0;iFormElement<FormElements.length;iFormElement++){
                      const FormElement = FormElements[iFormElement];
            
                      const Element: IFormElement = {...FormElement};
                      const ParentElements = Element.configuration.parentElements;
                      
                      if(!_.isEqual(ParentElements.length , 0)){
                        Element.value = null;
                        Element.touched = false;
                        Element.disabled = true;
                      }
        
                      FormElements[iFormElement] = {
                        ...FormElements[iFormElement],
                        ...Element
                      };
                    } 
                    FormGroups[iFormGroup] = {
                      ...FormGroups[iFormGroup],
                      elements: FormElements
                    };
                  }  
                  UpdatedForm={
                    ...UpdatedForm,
                    groups: FormGroups
                  };
                  UPDATED_FORMS.push(UpdatedForm);
              }
              return UPDATED_FORMS;
            }
          
}
