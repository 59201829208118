import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import style from "./style";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  title: string;
};

// Sort Icon functional component
export const IconArrowDown = (props: props): JSX.Element => {
  const classes = useStyles();
  const { title } = props;

  return (
    <Tooltip title={title}>
      <div aria-label={title} className={classes.sortIcon}>
        <svg xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="16"
          height="16"
        >
          <path fill="none"
            d="M0 0h24v24H0z" />
          <path
            d="M13 16.172l5.364-5.364 1.414 1.414L12 20l-7.778-7.778 1.414-1.414L11 16.172V4h2v12.172z"
            fill="rgba(106,115,124,1)"
          />
        </svg>
      </div>
    </Tooltip>
  );
};

