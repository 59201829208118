import React from "react";
import { MultiStepperForm } from "./Stepper";
import { SinglePageForm } from "./Single";
import * as _ from "lodash";
import { ITool } from "../interface/ITool";
import { ISignout } from "../interface/ISignout";
import { DefaultForm } from "./default";

type props = {
  tool: ITool;
  onChange: any;
  onValidateForms: any;
  onSubmit: any;
  handleNewForm: any;
  backBtnTitle: string;
  handleBackButton: any;
  signoutConfig: ISignout;
  loading: boolean;
  ishavingParent: any;
};

export const FormType = (props: props): JSX.Element => {
  const {
    tool,
    onChange,
    onValidateForms,
    onSubmit,
    handleNewForm,
    backBtnTitle,
    handleBackButton,
    signoutConfig,
    loading,
    ishavingParent,
  } = props;

  let form = <DefaultForm handleBackButton={handleBackButton} tool={tool} />;
  if (!_.isEqual(tool?.forms.length, 0)) {
    if (_.isEqual(tool?.tool_form_type_id, 1)) {
      form = (
        <SinglePageForm
          tool={tool}
          onChange={onChange}
          onValidateForms={onValidateForms}
          onSubmit={onSubmit}
          handleNewForm={handleNewForm}
          backBtnTitle={backBtnTitle}
          handleBackButton={handleBackButton}
          loading={loading}
          signoutConfig={signoutConfig}
          ishavingParent={ishavingParent}
        />
      );
    } else if (_.isEqual(tool?.tool_form_type_id, 2)) {
      form = (
        <MultiStepperForm
          tool={tool}
          onChange={onChange}
          onValidateForms={onValidateForms}
          onSubmit={onSubmit}
          handleNewForm={handleNewForm}
          backBtnTitle={backBtnTitle}
          handleBackButton={handleBackButton}
          loading={loading}
          signoutConfig={signoutConfig}
          ishavingParent={ishavingParent}
        />
      );
    }
  }
  return form;
};
