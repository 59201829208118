import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { makeStyles, createStyles, Theme } from "@material-ui/core";
import style from "./style";
import UITooltip from "../../../Elements/Tooltip";

type props = {
  data: string;
};

// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const TwoLinerTextElement = (props: props): React.JSX.Element => {
  const { data } = props;
  const classes = useStyles();
  const cellRef = useRef<HTMLTableCellElement>(null);
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    const ResizeEL = (): void => {
      const CellElement = cellRef.current;
      const scrollHeight = CellElement?.scrollHeight;
      const clientHeight = CellElement?.clientHeight;

      setOverflow(_.gt(scrollHeight, clientHeight));
    };
    window.addEventListener("resize", ResizeEL);
    ResizeEL();

    return () => {
      window.removeEventListener("resize", ResizeEL);
    };
  }, []);

  if (overflow) {
    return (
      <span className={classes.textContainerEllipse} ref={cellRef}>
        <UITooltip title={data}>
          <span>{data}</span>
        </UITooltip>
      </span>
    );
  }

  return (
    <span className={classes.textContainer} ref={cellRef}>
      {data}
    </span>
  );
};
