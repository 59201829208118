import * as _ from "lodash";
import {
	INITIAL_DATA_REQUEST,
	INITIAL_DATA_SUCCESS,
	INITIAL_DATA_FAILED,
	EMBED_CASE,
} from "../types";
import {
	updateObject
} from "../../helpers/Utility";
import { IReduxInit } from "./interfaces/IReduxInitial";
import { UserPermissionHelper } from "../../Layout/Routes/Permissions/Helper";
import { USER_PERMISSIONS_CODES } from "../../Layout/Routes/Permissions/UserPermissions";

// Initial State for userInfo reducer
const initialState: IReduxInit = {
	userInfo: {},
	isAuthenticated: false,
	bundle: {},
	loading: true,
	error: false,
	isExternalUser: false,
	isEmbed:false
};

// userInfo request to server
const intialDataRequest = (state: any): any => updateObject(state, {
	loading: true,
	error: false
});

// Get server response
const initialDataSuccess = (state: any, action: any): any => {

	const userPermissionHelper = new UserPermissionHelper();
	return updateObject(state, {
		userInfo: {
			...action.payload.user,
			routePermissions: userPermissionHelper.getUserPermissions(action.payload.user)
		},
		bundle: action.payload.bundle,
		loading: false,
		error: false,
		isAuthenticated: true
	});
}
;

// Server error
const intialDataFailed = (state: any, action: any): any => updateObject(state, {
	loading: false,
	error: !_.isEqual(action.payload, 403),
	isAuthenticated: false
});


//Embed url case
const setEmbedonly = (state: any, action: any): any =>
updateObject(state, {
 isEmbed:action.isEmbed
});


// userInfo reducer
export default (state = initialState, action: any = {}): any => {
	let updatedState = { ...state, userInfo: { ...state.userInfo } };
	switch (action.type) {
	  case INITIAL_DATA_REQUEST:
		updatedState = intialDataRequest(state);
		break;
	  case INITIAL_DATA_SUCCESS:
		updatedState = initialDataSuccess(state, action);
		updatedState.isExternalUser = _.gt(
		  updatedState.userInfo.userPermissions.indexOf(
			USER_PERMISSIONS_CODES.AP_EXTERNAL_USERS
		  ),
		  -1
		);
		break;
	  case INITIAL_DATA_FAILED:
		updatedState = intialDataFailed(state, action);
		break;
	  case EMBED_CASE:
		updatedState = setEmbedonly(state, action);
		break;
		  
	}
	return updatedState;
  };