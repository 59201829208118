import * as _ from "lodash";
import { Axios } from "../../../../../../../api/service/Axios";
import { IFormElement } from "../../../../interface/IFormElement";
import { IOption } from "../../../../interface/IOption";
import { UtilHelper } from "../../../Utils";

// Thumbnail Element Helper
export class ThumbnailElement {
  private utilHelper: UtilHelper;

  constructor() {
    this.utilHelper = new UtilHelper();
  }

  /**
   * Validate thumbnail and generate thumbnail element
   *
   * @param {forms} IForm[].
   * @param {formElement} IFormElement.
   * @return {element} IFormElement.
   */
  generateThumbnail = async (
    formElement: IFormElement
  ): Promise<IFormElement> => {
    let element = { ...formElement };
    // API Dropdown
    if (!this.utilHelper.isEmptyValue(formElement.api_url)) {
      element = await this.buildAPIList(formElement);
    }
    return element;
  };

  /**
   * Build API list
   *
   * @param {forms} IForm[].
   * @param {formElement} IFormElement.
   * @return {Element} IFormElement.
   */
  buildAPIList = async (formElement: IFormElement): Promise<IFormElement> => {
    let Element = { ...formElement };
    let dataSource: IOption[] = [];

    if (_.isEqual(Element.parent_elements.length, 0)) {
      try {
        let Data: any;
        if (_.isEqual(formElement.form_element_methodid, 1)) {
          Data = await Axios.get(formElement.api_url);
        } else if (_.isEqual(formElement.form_element_methodid, 2)) {
          Data = await Axios.post(formElement.api_url, {});
        }
        dataSource = !_.isArray(Data.data) ? [] : Data.data;

        Element.apiError = "";
      } catch (error: any) {
        dataSource = [];
        Element.apiError = this.utilHelper.getErrorMessage(error);
      }
      Element = this.getThumbnailValue(Element);
      return {
        ...Element,
        dataSource,
        value: Element.value,
      };
    } else {
      return {
        ...Element,
        is_visible: false,
      };
    }
  };

  /**
   * Get thumbnail value
   *
   * @param {formElement} IFormElement.
   * @return {formElement} IFormElement.
   */
  getThumbnailValue = (formElement: IFormElement): IFormElement => {
    const Element = { ...formElement };
    if (_.isNull(formElement.value)) {
      Element.value = null;
      return Element;
    }

    const FormElementValue = formElement.value.id || null;

    const IsValueExists = formElement.dataSource.find((data: any) =>
      _.isEqual(data.id, FormElementValue)
    );
    if (!_.isEqual(IsValueExists, -1)) {
      Element.value = formElement.value;
      Element.is_visible = true;
    }

    return Element;
  };
}
