import Colors from "../../utilstyle";

export const CustomStyles = (isError: boolean, disabled:boolean): any => ({
	indicatorsContainer: function (provided: any, state: any) {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		let fontColor = MenuIsOpen
		? Colors.ErrorColor
		: Colors.HoverColor;

		if(disabled){
			fontColor = Colors.DisabledFontColor;
		}

		return {
			...provided,
			height: 34,
			"& div:nth-of-type(1):not(.caret-select)" :{
				color:  fontColor,
				padding:0,
				"&:hover":{
					color:  fontColor,
				},
			},
			backgroundColor: isError && !disabled
				? Colors.ErrorBackgroundColor
				: provided.backgroundColor,
		};
	},
	dropdownIndicator: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		let fontColor = MenuIsOpen
		? Colors.FocusColor
		: Colors.HoverColor;

		if(disabled){
			fontColor = Colors.DisabledColor;
		}
		return {
			...provided,
			transform: MenuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
			"&:hover":{
				color:  fontColor,
			},
			color:  fontColor,
		};
	},
	indicatorSeparator: (provided: any) => ({
		...provided,
		visibility: "hidden",
	}),
	multiValue:(provided: any, state: any) => {
		return ({
			...provided,
			"& > div":{
				fontSize:"100%",
				color: state.data.highlightOption ? "#b82533" : "",
				backgroundColor: state.data.highlightOption ? "#fff2f2" : "",
			},
			"& > div:nth-of-type(2n)":{
				"&:hover":{
					backgroundColor: state.data.highlightOption ? "#fff2f2" : provided.backgroundColor,
					color: Colors.ErrorColor
				}
			}
		});
	},
	valueContainer: (provided: any) => ({
		...provided,
		padding: "0 12px",
		backgroundColor: isError && !disabled ? Colors.ErrorBackgroundColor : "",
	}),
	input: (provided: any) => ({
		...provided,
		margin: 0,
		padding: 0,
	}),
	placeholder:(provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		return {
			...provided,
			color:MenuIsOpen ? Colors.FocusColor : provided.color
		};
	},
	control: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		let bgColor = isError
		? Colors.ErrorBackgroundColor
		: Colors.BackgroundColor;

		if(disabled){
			bgColor = Colors.DisabledBackgrounColor;
		}
		let borderColor = "";

		if(disabled){
			borderColor = Colors.DisabledColor;
		} else{
			borderColor = MenuIsOpen? Colors.FocusColor : Colors.BorderColor;
		}

		let hoverBorderColor = "";

		if(isError && !disabled){
			hoverBorderColor = Colors.ErrorColor;
		} else{
			hoverBorderColor = MenuIsOpen ? Colors.FocusColor : Colors.HoverColor;
		}
		return {
			...provided,
			minHeight: 36,
			maxHeight: 36,
			height: 36,
			boxShadow: "none",
			backgroundColor: bgColor,
			border: `1px solid ${borderColor} !important`,
			"&:hover":{
				border:`1px solid ${hoverBorderColor} !important`
			},
			borderRadius: 2,
		};
	},
	option: (provided: any) => {
		return ({
			...provided,
			"&:hover":{
				backgroundColor: `1px solid ${Colors.HoverColor}`
			}
		});
	},
	singleValue: (provided: any, state: any) => {
		const MenuIsOpen: boolean = state.selectProps.menuIsOpen;
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = "opacity 300ms";
		let fontColor = "";

		if(state.data.highlightOption){
			fontColor = "#b82533";
		} else if(MenuIsOpen){
			fontColor = Colors.FocusColor;
		} else{
			fontColor = disabled
			? Colors.DisabledFontColor
			: provided.color;
		}
		return { ...provided,
			color: fontColor, opacity, transition };
	},
	menu: (provided: any) => {
		return {
			...provided,
			margin:0,
			padding:0
		};
	}
});

export const style = (): any => ({
	sortingContainer:{
		width: "50%",
		margin: "0 0 0 1rem",
	},
	width100:{
		width: "100%",
	}
});