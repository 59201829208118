

const style: any = (): any => ({
	switchContainer:{
		height: 38,
		display: "flex",
		alignItems: "center",
		marginRight:"15px",
		marginTop:"-10px"
	},
	cont:{
		display:"flex",
		marginTop:"10px"
	},
	topMargin:{
		marginTop:40
	}
});

export default style;