import * as _ from "lodash";
import { catchError } from "../../../helpers/ErrorHandler.component";
import { FORM_ELEMENT_TYPES } from "../Form/ElementTypes";
import { IFormElement } from "../Interface/FormElement/IFormElement";
import { IOption } from "../Interface/IOption";

// Utility helper
export class UtilHelper{

    /**
     * Check if the element value is empty or not
     *
     * @param {IFormElement} element.
     * @param {any} elementValue.
     * @return boolean.
     */
    isEmptyElementValue = (element: IFormElement, elementValue:any): any=>{
    	let isEmpty = true;
    	switch(element.type){
    	// Text box
    	case FORM_ELEMENT_TYPES.TEXT_INPUT:
    		// Checkbox
    		/* falls through*/
    	case FORM_ELEMENT_TYPES.CHECKBOX_INPUT:
    		// Radio 
    		/* falls through*/
    	case FORM_ELEMENT_TYPES.RADIO_INPUT:
    		isEmpty = _.isEqual((elementValue || "").toString().trim().length, 0);
    		break;
    		// Multiselect
    	case FORM_ELEMENT_TYPES.SELECT_INPUT:
    		if(element.configuration.isMulti){
    			isEmpty = _.isNull(elementValue) || _.isEqual((elementValue || []).length, 0);
    		}else{
				isEmpty = _.isNull(elementValue);
			}
    		break;
		}
    	return isEmpty;
    }

    
    /**
     * Get element value
     *
     * @param {IFormElement} element.
     * @return boolean.
     */
      getElementValue = (element: IFormElement) : any=>{
      	if(this.isEmptyElementValue(element, element.value)){
      		return null;
      	}

		  if(_.isEqual(element.type, FORM_ELEMENT_TYPES.SELECT_INPUT)){
			  //Multi Select
			if(element.configuration.isMulti){
				return element.value.map((option: IOption) => (option.value));
			}
			return element.value.value;
		  }
		  return  element.value;
      }

    /**
     * Find if the value is empty
     *
     * @param {any} value.
     * @return boolean.
     */
    isEmptyValue = (value:any) : any=>{
    	const Value = (value || "").toString().trim();
    	return _.isEqual(Value.length, 0);
    }

    /**
     * Find value in Options datasource
     *
     * @param {IOption[]} dataSource.
     * @param {any} value.
     * @return number | null.
     */
    findValueInDS = (dataSource:IOption[], value: any): any=>{
    	for(const ds of dataSource){
    		if(_.isEqual(ds.value, value)){
    			return ds;
    		}
    	}
    	return null;
    }

    /**
     * Extract error message from response
     *
     * @param {object} error.
     * @return string.
     */
    getErrorMessage(error: any): string{
    	const DefaultErrorMessage = "Server error: Unable to fetch data.";
    	if (!error.response) {
    		return DefaultErrorMessage;
    	}
    	const errorMessage = catchError(error);

    	return _.isEqual(
    		(errorMessage || "").toString().trim().length,
    		0
    	)
    		? DefaultErrorMessage
    		: errorMessage;
    }

}