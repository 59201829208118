import { IUserPermission } from "./IUserPermission";

enum USER_PERMISSIONS_CODES{
	AP_VIEW_BETA_TOOLS = "AP_VIEW_BETA_TOOLS",
	AP_VIEW_REGULAR_TOOLS = "AP_VIEW_REGULAR_TOOLS",
	AP_VIEW_DASHBOARD = "AP_VIEW_DASHBOARD",
	AP_MANAGE_TOOL_CONTENT = "AP_MANAGE_TOOL_CONTENT",
	AP_MANAGE_TOOL_CONFIG = "AP_MANAGE_TOOL_CONFIG",
	AP_ASSIGN_MAPPING_MANAGERS = "AP_ASSIGN_MAPPING_MANAGERS",
	AP_REVIEW_MAPPINGS = "AP_REVIEW_MAPPINGS",
	DB_MAPPING_MAPPERS = "DB_MAPPING_MAPPERS",
	DB_MAPPING_MANAGERS = "DB_MAPPING_MANAGERS",
    AP_DEPLOYMENT_ADMINS = "AP_DEPLOYMENT_ADMINS",
	AP_EXTERNAL_USERS ="AP_EXTERNAL_USERS"
}

const USER_PERMISSIONS: IUserPermission[] = [{
	title:"Toolkit", 
	gaTitle:"Toolkit",
	url: "/",
	permissionCodes: [
		USER_PERMISSIONS_CODES.AP_VIEW_REGULAR_TOOLS,
		USER_PERMISSIONS_CODES.AP_EXTERNAL_USERS
	],
	children:[],
	order: 1
},{
	title:"Dashboard", 
	gaTitle:"Dashboard",
	url: "/dashboard",
	permissionCodes: [USER_PERMISSIONS_CODES.AP_VIEW_DASHBOARD],
	children:[],
	order: 2
},{
	title:"Admin", 
	gaTitle:"Admin",
	url: "/admin",
	permissionCodes: [
		USER_PERMISSIONS_CODES.AP_MANAGE_TOOL_CONTENT, 
		USER_PERMISSIONS_CODES.AP_MANAGE_TOOL_CONFIG,
	],
	children:[],
	order: 3
},{
	title:"Mapping", 
	gaTitle:"",
	url: "",
	permissionCodes: [
		USER_PERMISSIONS_CODES.DB_MAPPING_MANAGERS,
		USER_PERMISSIONS_CODES.AP_ASSIGN_MAPPING_MANAGERS,
		USER_PERMISSIONS_CODES.AP_REVIEW_MAPPINGS,
		USER_PERMISSIONS_CODES.DB_MAPPING_MAPPERS
	],
	children:[
		{
			title:"Admin", 
			gaTitle:"",
			url: "/mapping/admin",
			permissionCodes: [
				USER_PERMISSIONS_CODES.AP_ASSIGN_MAPPING_MANAGERS,
			],
			children:[],
			order: 1
		},
		{
			title:"Manager", 
			gaTitle:"",
			url: "/mapping/manager",
			permissionCodes: [
				USER_PERMISSIONS_CODES.DB_MAPPING_MANAGERS
			],
			children:[],
			order: 2
		},
		{
			title:"Mapper", 
			gaTitle:"",
			url: "/mapping/mapper",
			permissionCodes: [
				USER_PERMISSIONS_CODES.DB_MAPPING_MAPPERS
			],
			children:[],
			order: 3
		},
		{
			title:"Review", 
			gaTitle:"",
			url: "/mapping/review",
			permissionCodes: [
				USER_PERMISSIONS_CODES.AP_REVIEW_MAPPINGS,
				USER_PERMISSIONS_CODES.DB_MAPPING_MANAGERS,
			],
			children:[],
			order: 4
		},
		{
			title:"User Guide", 
			gaTitle:"User Guide",
			url: "https://insidemedia.sharepoint.com/sites/ToolsAutomations/Shared%20Documents/Forms/AllItems.aspx?csf=1&web=1&e=82dwRW&cid=e20ad527%2Db5e1%2D444b%2D999d%2Dab33b8b16efc&FolderCTID=0x01200077DE229C7DD7434D9EE3DB0D26FC58F2&id=%2Fsites%2FToolsAutomations%2FShared%20Documents%2FDocuments%20for%20Portal%2FMapping%20Tool&viewid=94aca56b%2D5516%2D4a1d%2D8b18%2D45460806dc31",
			permissionCodes: [
				USER_PERMISSIONS_CODES.DB_MAPPING_MANAGERS,
				USER_PERMISSIONS_CODES.AP_ASSIGN_MAPPING_MANAGERS,
				USER_PERMISSIONS_CODES.AP_REVIEW_MAPPINGS,
				USER_PERMISSIONS_CODES.DB_MAPPING_MAPPERS
			],
			target:"_blank",
			children:[],
			order: 5
		}
	],
	order: 4
},{
	title:"History", 
	gaTitle:"Tool Execution History",
	url: "/history",
	permissionCodes: [
		USER_PERMISSIONS_CODES.AP_VIEW_REGULAR_TOOLS,
		USER_PERMISSIONS_CODES.AP_EXTERNAL_USERS
	],
	children:[],
	order: 5
}];

export {
	USER_PERMISSIONS,
	USER_PERMISSIONS_CODES
};
