import React, { Component } from "react";
import * as ErrorHandler from "../helpers/ErrorHandler.component";

// Handles component errors
export default (WrappedComponent: any): any => {
  class ErrorHandlerHOC extends Component {
    // Asynchronous function error handler
    errorHandlerAsyn = (func: any): any =>
      ErrorHandler.errorHandlerAsync(func);

    // Synchronous function error handler
    errorHandlerSyn = (func: any): any => ErrorHandler.errorHandlerSync(func);

    render(): React.JSX.Element {
      return (
        <WrappedComponent
          {...this.props}
          errorHandlerAsync={this.errorHandlerAsyn}
          errorHandlerSync={this.errorHandlerSyn}
        />
      );
    }
  }
  return ErrorHandlerHOC;
};
