import { Theme } from "@material-ui/core";
import COLOR from "../Theme/colors";

const style: any = (theme: Theme): any => ({
	new: {
		backgroundColor: "#f0fcf5",
		color: COLOR.GREEN,
	},
	adhoc: {
		color: "#242C34",
		backgroundColor: "#edf0f2"
	},
	preview: {
		backgroundColor: "#edf0f2",
		color: COLOR.DARK_GRAY,
	},
	info: {
		backgroundColor: "#f0f8ff",
		color: "#3a91e8",
	},
	running: {
		backgroundColor: "#f0f8ff",
		color: "#3a91e8",
	},
	warning: {
		backgroundColor: "#fff5e2",
		color: "#e98734",
	},
	deprecated: {
		backgroundColor: "#fff2f2",
		color: "#b82533",
	},
	beta: {
		backgroundColor: "#fff5e2",
		color: COLOR.DARK_RED,
	},
	sunset: {
		backgroundColor: "#f3f5f7",
		color: "#6a737c",
	},
	success: {
		backgroundColor: "#f0fcf5",
		color: "#177e58",
	},
	schedule: {
		backgroundColor: "#f0fcf5",
		color: "#177e58",
	},
	failed: {
		backgroundColor: "#fff2f2",
		color: "#b82533",
	},
	failure: {
		backgroundColor: "#fff2f2",
		color: "#b82533",
	},
	na: {
		backgroundColor: "#fff2f2",
		color: "#b82533",
	},
	scheduled: {
		backgroundColor: "#f0fcf5",
		color: "#177e58"
	},
	notscheduled: {
		backgroundColor: "#fff5e2",
		color: "#9B2B02",
	},
	inactive: {
		backgroundColor: "#F3F5F7",
		color: "#242C34",
	},
	inprogress : {
		color: "#e98734",
		backgroundColor: "#fff5e2",
	},
	maintenance: {
		backgroundColor: "#fff2f2",
		color: "#b82533",
	},
	pending: {
		backgroundColor: "#fff5e2",
		color: "#9B2B02",
	},
	text: {
		display: "inline",
		padding: "3px 8px 2px 8px",
		fontSize: 10,
		fontWeight: theme.typography.fontWeightMedium,
		textTransform: "uppercase" as const,
	},
});

export default style;