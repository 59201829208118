import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Button, Tooltip } from "@material-ui/core";
import { v4 as uuid4 } from "uuid";
import classnames from "classnames";
import style from "../style";

const useStyles = makeStyles(() => createStyles(style()));

type props = {
  onClick: any;
  btnText: string;
  disabled?: boolean;
  isOutline: boolean;
  cssStyle?: any;
  id?: string;
  isSignoutButton?: boolean;
  tooltip?: any;
};

export const UIOutlineButton = (props: props): JSX.Element => {
  const classes = useStyles();
  const { onClick, btnText, cssStyle, disabled, isOutline, id, isSignoutButton, tooltip } = props;

  if (isSignoutButton){
    return (
      <>
      <Tooltip title={tooltip} placement="top">
      <Button
      color="primary"
      onClick={onClick}
      style={cssStyle}
      className={classnames(
        { [classes.outline]: isOutline },
        {
          [classes.disabled]: disabled,
        },
        classes.button,
        classes.outlineButton,    
      )}
      disabled={disabled}
      id={id || `${btnText}-${uuid4()}`}
    >
      <svg xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24" 
              width="18" 
              height="18"
              
          >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5 22C4.44772 22 4 21.5523 4 21V3C4 2.44772 4.44772 2 5 2H19C19.5523 2 20 2.44772 20 3V21C20 21.5523 19.5523 22 19 22H5ZM15 16L20 12L15 8V11H9V13H15V16Z" fill="rgba(134,142,150,1)"></path></svg>
      </svg>
      
      <span className={classes.signoutButton}>{btnText}</span>
          
    </Button>
      </Tooltip>
      </>
    );
  }

  return (
    <Button
      color="primary"
      onClick={onClick}
      style={cssStyle}
      className={classnames(
        { [classes.outline]: isOutline },
        {
          [classes.disabled]: disabled,
        },
        classes.button,
        classes.outlineButton
      )}
      disabled={disabled}
      id={id || `${btnText}-${uuid4()}`}
    >
      {btnText}
    </Button>
  );
};
