import React, { Fragment } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import style from "./style";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  cols: number;
  rows: number;
};

export default function RowLoader(props: props): React.JSX.Element {
  const classes = useStyles();

  const { cols, rows } = props;

  const Columns: number[] = [];
  for (let i = 0; i < cols; i++) {
    Columns.push(i);
  }

  const Rows: number[] = [];
  for (let i = 0; i < rows; i++) {
    Rows.push(i);
  }

  const TableRows = Rows.map((colData: any, colIndex: number) => (
    <TableRow key={colIndex} className={classes.tableRow}>
      {Columns.map((rowData: any, rowIndex: number) => (
        <TableCell align="left" key={rowIndex} className={classes.tableColumn}>
          <span
            className={classNames(classes.gradient, classes.loadingCell)}
          ></span>
        </TableCell>
      ))}
    </TableRow>
  ));
  return <Fragment>{TableRows}</Fragment>;
}
