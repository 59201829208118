import * as _ from "lodash";
import React, { Fragment } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { IFormElement } from "../../Forms/interface/IFormElement";
import { IOption } from "../../Forms/interface/IOption";
import style from "./style";
import { UI_BTN_ACTIONS } from "../../config";
import { getFieldValue } from "./Utility";
import { buildElements } from "./Helper";
const useStyles = makeStyles(() => createStyles(style()));

// Props type
type props = {
  formElement: IFormElement;
  onChange: any;
  formId: number;
  formGroupId: number;
};

// UIDynamicSelect Component
export const UIDynamicSelect = (props: props): JSX.Element => {
  const { formId, formGroupId, onChange, formElement } = props;
  const classes = useStyles();

  const DynamicConfiguration = formElement.dynamic_configuration;

  const FieldValues: any[] = [];
  for (let i = 0; i < formElement.dynamic_fields_values.length; i++) {
    const FieldValue: any = getFieldValue(formElement, i);

    if (!_.isNull(FieldValue) && !_.isUndefined(FieldValue)) {
      FieldValues.push(FieldValue.value);
    }
  }

  const UpdatedDataSource = formElement.dataSource.filter((option: IOption) => {
    return !_.includes(FieldValues, option.value);
  });

  const Elements = buildElements(formElement, classes, props, {
    UpdatedDataSource,
    DynamicConfiguration,
  });

  if (_.isEqual(Elements.length, 0)) {
    return <span />;
  }

  return (
    <Fragment>
      {Elements}
      {_.lt(
        formElement.dynamic_fields_values.length,
        formElement.dynamic_element.dataLength
      ) && (
        <div
          onClick={() => {
            const DynamicElement = {
              formId,
              formGroupId,
              formElement,
              dynamicElement: {
                action: UI_BTN_ACTIONS.ADD,
              },
              value: null,
            };
            return onChange(DynamicElement);
          }}
          className={classes.addSectionBtn}
        >
          {DynamicConfiguration.configuration.addButtonText}
        </div>
      )}
    </Fragment>
  );
};
