import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import COLORS from "../../../../../Theme/colors";
import style from "./style";
import * as _ from "lodash";
import CardMedia from "@material-ui/core/CardMedia";


// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  onClose: any;
  heading: string;
  bodyUrl: any;
  extensionType: string;
};

export const PreviewDialog = (props: props): React.JSX.Element => {
  const {
    heading,
    bodyUrl,
    onClose,
    extensionType
  } = props;

  const classes = useStyles();
  const checkImgUrl = (): boolean => {
    if (_.isEqual(extensionType, "png") || _.isEqual(extensionType, "jpg") || _.isEqual(extensionType, "gif")) {
      return true;
    }
    else {
      return false;
    }
  };
  return (
    <div className={classes.container}>
      <div className={classes.overlay}></div>

      <div className={classes.dialog}>
        <div className={classes.closeButton} onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path color={COLORS.LIGHT_GRAY} fill="none" d="M0 0h24v24H0z" />
            <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </div>
        <div className={classes.dialogContainer}>
          <div className={classes.dialogHeader}>{heading}</div>
          {
            checkImgUrl() ?
              <img
                src={bodyUrl}
                className={classes.image}
                alt="Thumb"
              /> :
              <CardMedia
                component="video"
                image={bodyUrl}
                className={classes.image}
                title="Contemplative Reptile"
                autoPlay
                controls
              />
          }
          <div className={classes.dialogActions}>
          </div>
        </div>
      </div>
    </div>
  );
};
