import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as _ from "lodash";
import StatusIndicator from "../../../../../UI/StatusIndicator";
import AdhocIndicator from "../Layout/AdhocIndicator";
import style from "../style";
import { Container, Theme, Grid, Typography, Tooltip } from "@material-ui/core";
import { ITool } from "../../../../../UI/Elements/Forms/interface/ITool";
import { ISignout } from "../../../../../UI/Elements/Forms/interface/ISignout";
import { useSelector } from "react-redux";
import { BackButton } from "../../../../../UI/Buttons/BackButton/Index";
import { UIOutlineButton } from "../../../../../UI/Buttons";
import { useLocation } from "react-router-dom";


const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  data: ITool;
  stepper?: any;
  backBtnTitle?: any;
  handleBackButton: any;
  signoutConfig?: ISignout;
};

//Tool Header Component
export const ToolHeader = (props: props): React.JSX.Element => { //NOSONAR
  const { data, stepper, backBtnTitle, handleBackButton, signoutConfig } = props;
  const classes = useStyles();
  const bundle = useSelector((state: any) => state.initial.bundle);
  let truncShortDesc = "";
  const showButton = !_.isUndefined(signoutConfig?.user_info?.type) ? signoutConfig?.user_info?.type.includes("Google") || signoutConfig?.user_info?.type.includes("Facebook") : false;//NOSONAR
  if (data.short_descr.length > 190) {//NOSONAR
    truncShortDesc = `${data.short_descr.substring(0, 190)}...`;//NOSONAR
  } else {
    truncShortDesc = data.short_descr;//NOSONAR
  }

  const BACK_BUTTON_TITLE = backBtnTitle || bundle.MENU_TOOLKIT_TITLE;//NOSONAR
  const location = useLocation();
  const Params = new URLSearchParams(location.search);
  const toolembed: any = (Params.get("embedtool") || "").toString();


  if (!_.isUndefined(stepper) && !_.isNull(stepper)) {//NOSONAR
    return (
      <div style={{ backgroundColor: "#FFFF" }}>
        <Container maxWidth="lg">
          <Grid container>
            <Grid item lg={8} md={8} sm={8}>
              <div className={classes.container}>
                <div className={classes.stepperToolHeader}>
                  <div className={classes.backButtonDiv}>
                  {_.isEqual(toolembed,"") && <BackButton
                      btnText={BACK_BUTTON_TITLE}
                      onClick={() => {
                        handleBackButton({
                          action: "Back to toolkit",
                        });
                      }}
                    />}
                  </div>
                  <div className={classes.toolName}>
                    {data.tool_name}
                    <span className={classes.version}> {data.tool_version}</span>
                    <StatusIndicator status={data.tool_label} />
                    {
                      (!data.is_schedule && data.is_grid) ?//NOSONAR
                        (<>
                          <span className={classes.adHocStyle}></span>
                          <AdhocIndicator status="AD HOC" />
                        </>) : null
                    }
                  </div>
                  <Grid container>
                    <Grid item lg={12} md={12} sm={12}>
                      {data.short_descr.length > 190 ? (
                        <Tooltip title={data.short_descr} placement="bottom-start">
                          <Typography
                            className={classes.toolDescr}
                            style={{ cursor: "pointer" }}
                          >
                            {truncShortDesc}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.toolDescr}>
                          {truncShortDesc}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </div>
                {stepper}
              </div>
            </Grid>
            {signoutConfig?.is_signout_button && (showButton && _.isEqual(signoutConfig?.user_info?.type.length,1)) &&//NOSONAR
              <Grid item lg={4} md={4} sm={4}>
                <div className={classes.toolHeader} style={{ position: "relative", height: "100%" }}>
                  <div className={classes.signoutButtonDiv}>
                    <UIOutlineButton
                      disabled={false}
                      isOutline={true}
                      onClick={() => signoutConfig?.signout_action(signoutConfig?.tool_id)}//NOSONAR
                      btnText="Tool Sign Out"
                      isSignoutButton={true}
                      tooltip={signoutConfig?.user_info?.message}
                    />
                  </div>
                </div>
              </Grid>
            }
          </Grid>
        </Container>
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: "#FFFF" }}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item lg={8} md={8} sm={8}>
            <div className={classes.toolHeader}>
              <div className={classes.backButtonDiv}>
              {_.isEqual(toolembed,"") && <BackButton
                  btnText={BACK_BUTTON_TITLE}
                  onClick={() =>
                    handleBackButton({
                      action: "Back to toolkit",
                    })
                  }
                />}
              </div>
              <div className={classes.toolName}>
                {data.tool_name}
                <span className={classes.version}> {data.tool_version}</span>
                <StatusIndicator status={data.tool_label} />
                {
                  (!data.is_schedule && data.is_grid) ?//NOSONAR
                    (<>
                      <span className={classes.adHocStyle}></span>
                      <AdhocIndicator status="AD HOC" />
                    </>) : null
                }
              </div>
              <Grid container spacing={3}>
                <Grid item lg={12} md={12} sm={12}>
                  {data.short_descr.length > 190 ? (
                    <Tooltip title={data.short_descr} placement="bottom-start">
                      <Typography
                        className={classes.toolDescr}
                        style={{ cursor: "pointer" }}
                      >
                        {truncShortDesc}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography className={classes.toolDescr}>
                      {truncShortDesc}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          {signoutConfig?.is_signout_button && (showButton && _.isEqual(signoutConfig?.user_info?.type.length,1)) &&//NOSONAR
            <Grid item lg={4} md={4} sm={4}>
              <div className={classes.toolHeader} style={{ position: "relative", height: "100%" }}>
                <div className={classes.signoutButtonDiv}>
                  <UIOutlineButton
                    disabled={false}
                    isOutline={true}
                    onClick={() => signoutConfig?.signout_action(signoutConfig?.tool_id)}//NOSONAR
                    btnText="Tool Sign Out"
                    isSignoutButton={true}
                    tooltip={signoutConfig?.user_info.message}//NOSONAR
                  />
                </div>
              </div>
            </Grid>
          }
        </Grid>
      </Container>
    </div>
  );
};
