import React, { useEffect, useRef, useState } from "react";
import * as _ from "lodash";
import { Theme } from "@material-ui/core/styles";
import UITooltip from "../../../Elements/Tooltip";
import { makeStyles, createStyles } from "@material-ui/core";
import style from "./style";
import { MiddleTextEllipsis } from "./MidLineEllipsis";

type props = {
  text: string;
  tooltip?: string;
  containerClass: string;
};
// Create styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

export const Placeholder = (props: props): React.JSX.Element => {
  const { text, tooltip, containerClass } = props;
  const classes = useStyles();
  const [hoverStatus, setHover] = useState(false);

  const textElementRef: any = useRef();
  const Text = (text || "").toString().trim();

  const compareSize = (): any => {
    if (_.isNull(textElementRef) || _.isUndefined(textElementRef.current)) {
      return;
    }

    const compare = _.gt(
      textElementRef.current.scrollWidth,
      textElementRef.current.clientWidth
    );
    setHover(compare);
  };
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  if(_.isEmpty(Text)){
    return <span className={classes.emptyPlaceholder}></span>;
  }

  return (
    <span>
      <UITooltip title={!_.isEmpty(tooltip) ? `${Text} - ${tooltip}` : `${Text}`} disableTooltip={!_.isEmpty(tooltip) ? false : !hoverStatus}>
        <span className={classes[containerClass]}>
          <span ref={textElementRef} className={classes.ellipsisContainer}>
            {Text}
          </span>
          <MiddleTextEllipsis text={Text} />
        </span>
      </UITooltip>
    </span>
  );
};
