import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Container, Theme, Grid } from "@material-ui/core";
import { PageHeader } from "../Header";

import style from "./style";
import classNames from "classnames";
// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  enableContentBgColor: boolean;
  pageTitle: string;
  loading?:any;
  recordCount?: any;
  tabComponent: any;
  header: any;
  children?: any;
  footer: any;
  customHeaderStyle?: any;
  hideContent: boolean;
  backButtonLabel?: any;
  mapperHeaderData?: any;
  bulkEditMode?:boolean;
  exitBulkEditMode?:any;
  bulkEditBackButtonLabel?:any;
  platform?: any;
  setAlert?: any;
  errormsg?: any;
  setInitLoader?: any;
};

//Mapping Page Component
export const MappingPage = (props: props): React.JSX.Element => {
  const classes = useStyles();

  return (
    <>
      <PageHeader
        platform={props.platform}
        setAlert={props.setAlert}
        errormsg={props.errormsg}
        setInitLoader={props.setInitLoader}
        mapperHeaderData={props.mapperHeaderData}
        backButtonLabel={props.backButtonLabel}
        pageTitle={props.pageTitle}
        tabComponent={props.tabComponent}
        customStyle={props.customHeaderStyle}
        recordCount={props.recordCount}
        loading={props.loading}
        bulkEditMode={props.bulkEditMode}
        exitBulkEditMode={props.exitBulkEditMode}
        bulkEditBackButtonLabel={props.bulkEditBackButtonLabel}

      />
      <Container maxWidth="lg">
        <Grid container className={classes.toolContentWrapper}>
          <div className={classes.toolContentHeader}>{props.header}</div>
          {!props.hideContent && (
            <Grid
              container
              item
              className={classNames(classes.infoWrapper, {
                [classes.bodybgColor]: props.enableContentBgColor,
              })}
            >
              {props.children}
            </Grid>
          )}
          <div className={classes.toolContentHeader}>{props.footer}</div>
        </Grid>
      </Container>
    </>
  );
};
