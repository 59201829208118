import COLORS from "../../../../../../../Theme/colors";

const style:any = (): any => ({
	
	fileContainer:{
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flex:1,
		marginTop:8
	},
	headerFileContainer:{
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flex:1,
		marginTop:8,
		fontSize: 12,
		fontWeight: 600,
		color: COLORS.GRAY,
		borderBottom:"1px solid rgba(224, 224, 224, 1)",
		"& div":{
			padding:"11px 0px"
		}
	},
	headerFileTextbox:{
		margin:"0 10px",
		width:"100%"
	},
	headerFileName:{
		display:"flex",
		alignItems:"center",
		whiteSpace:"nowrap",
		flex: "0 0 14rem"
	},
	cellFileContainer:{
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		flex:1,
		marginTop:8,
	},
	cellFileTextbox:{
		margin:"0 10px",
		width:"100%"
	},
	cellFileName:{
		display:"flex",
		alignItems:"center",
		whiteSpace:"nowrap",
		flex: "0 0 14rem"
	},
});

export default style;



		