import React from "react";
import { Grid } from "@material-ui/core";
import * as _ from "lodash";
import { IFormElement } from "../../../../../Forms/interface/IFormElement";
import { DataGridHead } from "./GridHeader";
import { IFilter } from "../IFilter";
import { DataGridBody } from "./GridBody";
import { DataGridFooter } from "./GridFooter";
import { DataGridAddForm } from "./Forms/Add";

type props = {
  formElement: IFormElement;
  handleChangePage: any;
  handleChangeRowsPerPage: any;
  sortHandler: any;
  rowData: any;
  handleDeleteRow: any;
  onAddFormChange: any;
  filter: IFilter;
  onClearForm: any;
  form: any;
  onEditForm: any;
};

export const GridEditor = (props: props): React.JSX.Element => {
  const {
    formElement,
    handleChangePage,
    handleChangeRowsPerPage,
    filter,
    rowData,
    handleDeleteRow,
    onAddFormChange,
    onClearForm,
    form,
    sortHandler,
    onEditForm,
  } = props;

  const getRows = formElement.customConfiguration?.grid?.validations;
  const AllowedRows =
  getRows?.allowedRows || null;
  let addForm = (
    <DataGridAddForm
      formElement={formElement}
      onAddFormChange={onAddFormChange}
      form={form}
      onClearForm={onClearForm}
    />
  );

  if (!_.isNull(AllowedRows) && !_.lt(rowData.length, AllowedRows)) {
    addForm = <></>;
  }

  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <DataGridHead
        formElement={formElement}
        sortHandler={sortHandler}
        filter={filter}
      />
      {addForm}
      <DataGridBody
        formElement={formElement}
        handleDeleteRow={handleDeleteRow}
        rowData={rowData}
        filter={filter}
        onEditForm={onEditForm}
      />
      <DataGridFooter
        formElement={formElement}
        rowData={rowData}
        filter={filter}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
};
