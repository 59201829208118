import {
	ADD_ERROR,
	REMOVE_ERROR
} from "../types/Error";
import { IReduxError } from "./interfaces/IReduxError";

const InitialState: IReduxError = {
	error:"",
	show: false
};

// Error Reducer
export default (state = InitialState, action:any={}) : any => {
	let updatedState = {...state};
	switch (action.type) {
	case ADD_ERROR:
		updatedState = {
			...state,
			error: action.payload,
			show: true
		};
		break;
	case REMOVE_ERROR:
		updatedState = {
			...state,
			error: "",
			show: false
		};
		break;
	}
	return updatedState;
};