import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  Button,
} from "@material-ui/core";
import * as _ from "lodash";
import { useSelector } from "react-redux";
import style from "./style";
import { IconFilter } from "../../../../UI/Icons";

// Added styles
const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

type props = {
  recordsCount: number;
  loading: boolean;
  searchPlaceholder: string;
  handleSearchChange: any;
  searchValue: string;
  filterCount: number;
  onOpenFilter: any;
  hideFilter: boolean;
  customComponent?: any;
  showCustomComponent?: boolean;
};

export const SearchFilter = (props: props): React.JSX.Element => {
  const classes = useStyles();
  const {
    loading,
    filterCount,
    onOpenFilter,
    hideFilter,
    recordsCount
  } = props;


  const bundle = useSelector((state: any) => state.initial.bundle);

  return (
    <>
      {!hideFilter && (
        <Button
          className={classes.filterButton}
          onClick={onOpenFilter}
          disabled={loading || _.isEqual(recordsCount, 0)}
        >
          <div style={{ opacity: loading ? 0.5 : 1 }}>
            <IconFilter title="" />
          </div>
          {bundle.FILTER_BUTTON_TITLE}
          {!_.isEqual(filterCount, 0) && (
            <>
              <div className={classes.filterDot}>&middot;</div>
              <div>{filterCount}</div>
            </>
          )}
        </Button >
      )}
    </>
  );
};
