
const style: any = (): any => ({
    optional:{
        minHeight: 18,
        color: "rgb(106, 115, 124)",
        fontSize: 12,
        "font-weight": "normal",
        marginLeft: 5,
        display:"inline-block"
    },
    note: {
        color: "#6a737c",
        fontSize: 12,
        fontWeight: "normal" as const,
        padding: "0px 8px",
    },
    hideLabel:{
        minHeight: "28px !important" 
    },
    titleContainer:{
        minHeight: "12px !important" 
    }
});

export default style;